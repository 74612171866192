import { isNil } from 'ramda';

import formatCurrency from 'utils/formatCurrency';

// eslint-disable-next-line import/prefer-default-export
export function currencyFromTo(min, max) {
  return this.test(
    'currencyFromTo',
    { id: 'yup.string.currencyFromTo', values: { min: formatCurrency(min), max: formatCurrency(max) } },
    (value) => isNil(value) || (value >= min && value <= max),
  );
}
