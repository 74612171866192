import { throttle } from 'lodash';

import { captureException } from 'lib/sentry';

import { showNotification } from 'state/notifications/actions';

const throttledMakeShowNotificationAndSendErrorToSentry = throttle(
  (dispatch, error) => {
    dispatch(showNotification({
      messageObject: { id: 'errorPage.generalNotification' },
      kind: 'error',
    }));
    captureException(error);
  },
  175,
  { trailing: false },
);

const makeShowNotificationAndSendErrorToSentry = (
  dispatch,
  error,
) => () => throttledMakeShowNotificationAndSendErrorToSentry(dispatch, error);

export default makeShowNotificationAndSendErrorToSentry;
