import { includes } from 'ramda';

const formatISOPhoneNumber = phoneNumber => {
  if (phoneNumber && includes('+', phoneNumber)) {
    return phoneNumber.slice(1);
  }
  return phoneNumber;
};

export default formatISOPhoneNumber;
