import { createLogic } from 'redux-logic';

import isPresent from 'utils/isPresent';
import { isRevenueVirtualType } from 'utils/revenue';

import { fetchRevenue, fetchRevenueVirtualClinics } from '../actions';
import { revenueTypeSelector, revenueVirtualClinicIdSelector } from '../selectors';
import { GET_REVENUE } from '../types';

const getRevenueOperation = createLogic({
  type: GET_REVENUE,
  latest: true,

  async process({ getState }, dispatch, done) {
    const state = getState();
    const revenueType = revenueTypeSelector(state);
    const clinicId = revenueVirtualClinicIdSelector(state);

    if (isRevenueVirtualType(revenueType) && !isPresent(clinicId)) {
      dispatch(fetchRevenueVirtualClinics());
    } else {
      dispatch(fetchRevenue());
    }

    done();
  },
});

export default getRevenueOperation;
