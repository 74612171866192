import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'views/shared/Tooltip';
import Button from 'views/shared/Button';

const JoinButton = ({
  onSubmit,
  isLoading,
  canJoin,
}) => (
  <Tooltip
    isShown={!canJoin && !isLoading}
    icon={(
      <div className="d-inline-flex">
        <Button
          text={{ id: 'shared.join' }}
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isLoading || !canJoin}
        />
      </div>
    )}
  >
    <FormattedMessage id="videoConference.joinError" />
  </Tooltip>
);

JoinButton.defaultProps = {
  isLoading: undefined,
};

JoinButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  canJoin: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default JoinButton;
