import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import validationSchema from 'lib/yupLocalised/schemas/generateVouchers';

import { handleSubmit } from 'utils/form/handleSubmit';

import { generateVouchers } from 'state/concepts/userProfile/vouchers/actions';

import GenerateVouchersModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class GenerateVouchersModal extends React.Component {
  render = () => (
    <GenerateVouchersModalComponent
      {...this.props}
    />
  );
}

const mapDispatchToProps = {
  onSubmit: generateVouchers,
};

export { GenerateVouchersModal as GenerateVouchersModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      number: '',
      value: '',
      name: '',
      comment: '',
    }),
    validationSchema,
    handleSubmit,
  }),
)(GenerateVouchersModal);
