import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import profileName from 'utils/profileName';

import Avatar from 'views/shared/Avatar';

const AppointmentCalendarTooltipConsultantDetailsComponent = ({
  userProfile,
  onRedirectToUserProfile,
}) => (
  <div className="modal-appointment__relation">
    <div className="modal-appointment__relation-title">
      <FormattedMessage id="appointments.doctor" />
    </div>
    <div className="modal-appointment__relation-doctor">
      <Avatar
        avatarClassName="modal-appointment__relation-doctor-img color-avatars-blue-2"
        sizeClassName="main-userpic--sm"
        imageSize="small"
        user={userProfile}
      />
      <div className="modal-appointment__relation-doctor-name">
        <a className="pointer" role="button" onClick={onRedirectToUserProfile}>
          {profileName(userProfile)}
        </a>
      </div>
    </div>
  </div>
);

AppointmentCalendarTooltipConsultantDetailsComponent.propTypes = {
  userProfile: PropTypes.shape().isRequired,
  onRedirectToUserProfile: PropTypes.func.isRequired,
};

export default AppointmentCalendarTooltipConsultantDetailsComponent;
