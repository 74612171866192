import { createLogic } from 'redux-logic';

import { setFilterParams, fetchAppointments } from '../actions';
import { FILTER_APPOINTMENTS } from '../types';

const filterAppointmentOperation = createLogic({
  type: FILTER_APPOINTMENTS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(fetchAppointments());
    done();
  },
});

export default filterAppointmentOperation;
