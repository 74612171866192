import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import isPresent from 'utils/isPresent';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { setRevenueVirtualClinicIds, setRevenueBlankState } from '../actions';
import { revenuePaginationSelector, revenueFiltersSelector, revenueSortParamsSelector } from '../selectors';
import { fetchRevenueVirtualClinicsEndpoint } from '../endpoints';
import { FETCH_REVENUE_VIRTUAL_CLINICS } from '../types';

const fetchRevenueVirtualClinicsOperation = createLogic({
  type: FETCH_REVENUE_VIRTUAL_CLINICS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchRevenueVirtualClinicsEndpoint;

    const state = getState();
    const { startDate, endDate, clinicIds, name } = revenueFiltersSelector(state);
    const clinicIdsParam = isPresent(clinicIds) ? { clinic_ids: clinicIds } : {};

    const params = {
      page: revenuePaginationSelector(state),
      sort: revenueSortParamsSelector(state),
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
        name,
        ...clinicIdsParam,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRevenueBlankState(isBlankState(params.filter, data.data)));
      dispatch(setRevenueVirtualClinicIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRevenueVirtualClinicsOperation;
