import * as types from './types';

export const setCommissionsInitialState = roleName => ({
  type: types.SET_COMMISSIONS_INITIAL_STATE,
  roleName,
});

export const setBlankState = isBlankState => ({
  type: types.SET_COMMISSIONS_BLANK_STATE,
  isBlankState,
});

export const setCommissionIds = ids => ({
  type: types.SET_COMMISSION_IDS,
  ids,
});

export const resetCommissionIds = () => ({
  type: types.RESET_COMMISSION_IDS,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_COMMISSIONS_CURRENT_PAGE,
  pageNumber,
});

export const filterCommissions = (filters, roleName) => ({
  type: types.SET_COMMISSIONS_FILTER,
  filters,
  roleName,
});

export const setSortOrder = sortKey => ({
  type: types.SET_COMMISSIONS_SORT,
  sortKey,
});

export const fetchCommissions = roleName => ({
  type: types.FETCH_COMMISSIONS,
  roleName,
});

export const fetchDoctorsCommissions = roleName => ({
  type: types.FETCH_DOCTORS_COMMISSIONS,
  roleName,
});

export const fetchVirtualClinicsCommissions = () => ({
  type: types.FETCH_VIRTUAL_CLINICS_COMMISSIONS,
});
