import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import Avatar from 'views/shared/Avatar';
import SvgIcon from 'views/shared/SvgIcon';

import ToggleButton from '../ToggleButton';
import JoinButton from './JoinButton';

const JoinConsultationModalComponent = forwardRef(({
  onClose,
  isAudioEnabled,
  isVideoEnabled,
  appointmentsPath,
  currentUser,
  onSubmit,
  onToggleAudio,
  onToggleVideo,
  onChangeSettings,
  appointmentId,
  isLoading,
  canJoin,
}, ref) => (
  <Modal className="ant-modal-wrap__4" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="videoConference.joinConsultation" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="video-wrapper mb-16 video-wrapper--camera-off">
        <div className="video-wrapper__wrapper">
          <div className="video-wrapper__content">
            <video
              ref={ref}
              className={classNames('video-wrapper__video', { 'd-none': !isVideoEnabled })}
              autoPlay
              playsInline
              muted
            />
            <div className={classNames('video-wrapper__user', { 'd-none': isVideoEnabled })}>
              <Avatar
                size="x-large"
                user={currentUser}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="video-btns">
        <ToggleButton
          kind="secondary"
          icon="video"
          onClick={onToggleVideo}
          isActive={isVideoEnabled}
        />
        <ToggleButton
          kind="secondary"
          icon="microphone"
          onClick={onToggleAudio}
          isActive={isAudioEnabled}
        />
        <ToggleButton
          kind="secondary"
          icon="settings"
          onClick={onChangeSettings}
        />
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <div>
        <Button
          className="mr-16"
          text={{ id: 'shared.leave' }}
          onClick={onClose}
          kind="tertiary"
        />
        <JoinButton
          appointmentsPath={appointmentsPath}
          appointmentId={appointmentId}
          onSubmit={onSubmit}
          isLoading={isLoading}
          canJoin={canJoin}
        />
      </div>
    </div>
  </Modal>
));

JoinConsultationModalComponent.defaultProps = {
  isLoading: undefined,
};

JoinConsultationModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  isAudioEnabled: PropTypes.bool.isRequired,
  isVideoEnabled: PropTypes.bool.isRequired,
  appointmentsPath: PropTypes.string.isRequired,
  appointmentId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleAudio: PropTypes.func.isRequired,
  onToggleVideo: PropTypes.func.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  canJoin: PropTypes.bool.isRequired,
};

export default JoinConsultationModalComponent;
