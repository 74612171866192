import { createLogic } from 'redux-logic';

import { showModal } from 'state/modal/actions';
import { activateDoctor } from '../actions';
import { UPDATE_DOCTOR_ACCOUNT } from '../types';

const updateDoctorAccountOperation = createLogic({
  type: UPDATE_DOCTOR_ACCOUNT,
  latest: true,

  async process({ action: { values, form, doctorId } }, dispatch, done) {
    if (values.active) {
      dispatch(activateDoctor(doctorId, form));
    } else {
      dispatch(showModal({ modalType: 'DEACTIVATE_DOCTOR_MODAL', modalProps: { doctor: values.doctorProfile } }));
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateDoctorAccountOperation;
