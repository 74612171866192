import fetchPatient from './fetchPatient';
import fetchPatientAppointments from './fetchPatientAppointments';
import filterPatient from './filterPatient';
import fetchPatientNotes from './fetchPatientNotes';
import activatePatient from './activatePatient';
import banPatient from './banPatient';
import submitPatientProfile from './submitPatientProfile';
import submitChildrenProfile from './submitChildrenProfile';

export default [
  fetchPatient,
  fetchPatientAppointments,
  filterPatient,
  fetchPatientNotes,
  activatePatient,
  banPatient,
  submitPatientProfile,
  submitChildrenProfile,
];
