import endpoint from 'utils/endpoint';

import {
  patientsRoute,
  patientsDownloadCsvRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchPatientsEndpoint = endpoint(types.FETCH_PATIENTS, 'GET', patientsRoute);
export const downloadPatientsCsvEndpoint = endpoint(types.DOWNLOAD_PATIENTS_CSV, 'GET', patientsDownloadCsvRoute);
