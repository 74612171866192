const filterCommonDiseases = (commonDiseases, searchQuery) => commonDiseases.filter(
  ({ group }) => group
    .toLocaleLowerCase()
    .includes(searchQuery.name.toLocaleLowerCase()),
  )
  .map(disease => ({
    title: disease.group,
    key: disease.id,
    children: disease.diseases.map(childDisease => ({
      title: (
        <>
          {childDisease.code}
          <span>{childDisease.description}</span>
        </>
      ),
      key: childDisease.id,
      dataRef: childDisease,
    })),
})).sort((a, b) => a.title.localeCompare(b.title));

export default filterCommonDiseases;
