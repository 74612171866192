import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { propEq } from 'ramda';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';
import { TUTORIAL_STEPS } from 'constants/userTutorial';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import isPresent from 'utils/isPresent';
import parseValuesFormToParams from 'utils/clinicSpecialities/parseValuesFormToParams';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { skipStep } from 'state/concepts/userProfile/tutorial/actions';
import { currentUserClinicIdSelector, currentUserTutorialSelector } from 'state/concepts/session/selectors';
import { updateTutorialStepClinicSpecialitiesEndpoint } from 'state/concepts/userProfile/tutorial/endpoints';

import { submitClinicSpecialityEndpoint } from '../endpoints';
import { SUBMIT_CLINIC_SPECIALITY } from '../types';

const submitClinicSpecialityOperation = createLogic({
  type: SUBMIT_CLINIC_SPECIALITY,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const state = getState();
    const clinicId = currentUserClinicIdSelector(state);
    const tutorial = currentUserTutorialSelector(state);
    const { url, endpoint } = submitClinicSpecialityEndpoint;

    try {
      const params = {
        ...parseValuesFormToParams(values),
        symptom_ids: values.symptoms,
        speciality_id: values.specialityId,
        accept_free_visits: values.acceptFreeVisits,
        status: values.status
          ? CLINIC_SPECIALITIES_STATUSES.active
          : CLINIC_SPECIALITIES_STATUSES.inactive,
        price: values.price,
        clinic_id: clinicId,
        include: 'user-profiles-clinic-specialities.user-profile,speciality,symptoms',
      };

      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response }));
      await redirect({ href: '/clinic_specialities' });
      dispatch(showNotification({ messageObject: { id: 'notifications.clinicSpecialityAdded' } }));
      if (
        isPresent(tutorial)
        && propEq(TUTORIAL_STEPS.availability, true, tutorial)
        && propEq(TUTORIAL_STEPS.clinicSpecialities, false, tutorial)
      ) {
        dispatch(skipStep(
          TUTORIAL_STEPS.clinicSpecialities,
          updateTutorialStepClinicSpecialitiesEndpoint,
        ));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default submitClinicSpecialityOperation;
