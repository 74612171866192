import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import filterCommonDiseases from 'utils/filterCommonDiseases';

import { getCommonDiseases as getCommonDiseasesAction } from 'state/concepts/userProfile/diseases/actions';
import { commonDiseasesSelector } from 'state/concepts/userProfile/diseases/selectors';

import CommonDiagnosesComponent from './component';

class CommonDiagnoses extends React.Component {
  static propTypes = {
    getCommonDiseases: PropTypes.func.isRequired,
    onSetDisease: PropTypes.func.isRequired,
    commonDiseases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    searchQuery: { name: '' },
  };

  get commonDiseases() {
    const { searchQuery } = this.state;
    const { commonDiseases } = this.props;

    return filterCommonDiseases(commonDiseases, searchQuery);
  }

  componentDidMount() {
    const { getCommonDiseases } = this.props;

    getCommonDiseases();
  }

  handleSearch = (searchQuery) => this.setState({ searchQuery });

  handleSelect = (_, { node }) => {
    const { props: { dataRef } } = node;
    const { onSetDisease } = this.props;

    if (dataRef?.canBeAssigned) {
      onSetDisease(dataRef);
    }
  };

  render = () => (
    <CommonDiagnosesComponent
      {...this.props}
      onSetDisease={this.handleSelect}
      onSearch={this.handleSearch}
      commonDiseases={this.commonDiseases}
    />
  );
}

const mapStateToProps = state => ({
  commonDiseases: commonDiseasesSelector(state),
});

const mapDispatchToProps = {
  getCommonDiseases: getCommonDiseasesAction,
};

export { CommonDiagnoses as CommonDiagnosesContainer };
export default connect(mapStateToProps, mapDispatchToProps)(CommonDiagnoses);
