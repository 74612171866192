export const FETCH_PUSH_NOTIFICATIONS = 'FETCH_PUSH_NOTIFICATIONS';
export const SET_PUSH_NOTIFICATIONS_IDS = 'SET_PUSH_NOTIFICATIONS_IDS';
export const SET_PUSH_NOTIFICATIONS_BLANK_STATE = 'SET_PUSH_NOTIFICATIONS_BLANK_STATE';
export const SET_PUSH_NOTIFICATIONS_CURRENT_PAGE = 'SET_PUSH_NOTIFICATIONS_CURRENT_PAGE';
export const FETCH_PUSH_NOTIFICATION_CLINICS = 'FETCH_PUSH_NOTIFICATION_CLINICS';
export const SET_PUSH_NOTIFICATION_CLINICS_IDS = 'SET_PUSH_NOTIFICATION_CLINICS_IDS';
export const SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTERS = 'SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTERS';
export const SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS = 'SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS';
export const RESET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS = 'RESET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS';
export const RESET_PUSH_NOTIFICATION_CLINICS_LIST_IDS = 'RESET_PUSH_NOTIFICATION_CLINICS_LIST_IDS';
export const FETCH_PUSH_NOTIFICATION_PATIENTS = 'FETCH_PUSH_NOTIFICATION_PATIENTS';
export const SET_PUSH_NOTIFICATION_PATIENTS_IDS = 'SET_PUSH_NOTIFICATION_PATIENTS_IDS';
export const SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTERS = 'SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTERS';
export const SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS = 'SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS';
export const RESET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS = 'RESET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS';
export const RESET_PUSH_NOTIFICATION_PATIENTS_LIST_IDS = 'RESET_PUSH_NOTIFICATION_PATIENTS_LIST_IDS';
export const VALIDATE_PUSH_NOTIFICATIONS = 'VALIDATE_PUSH_NOTIFICATIONS';
export const SUBMIT_CREATE_PUSH_NOTIFICATION = 'SUBMIT_CREATE_PUSH_NOTIFICATION';
export const TOGGLE_PUSH_NOTIFICATION_CONFIRM_MODAL_VISIBLE = 'TOGGLE_PUSH_NOTIFICATION_CONFIRM_MODAL_VISIBLE';
