import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { rejectJoinRequestEndpoint } from '../endpoints';
import { REJECT_JOIN_REQUEST } from '../types';

const rejectJoinRequestOperation = createLogic({
  type: REJECT_JOIN_REQUEST,
  latest: true,

  async process({ httpClient, getState, action: { id } }, dispatch, done) {
    const { url, endpoint } = rejectJoinRequestEndpoint(id);
    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url);
      const state = getState();

      const response = updateDataHelper(
        state.data,
        'joinRequest',
        id,
        { attributes: { status: 'rejected' } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.joinRequestRejected',
          },
        }),
      );
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default rejectJoinRequestOperation;
