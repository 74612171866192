import { ceil } from 'lodash';
import { divide } from 'ramda';

import { MIME_TYPES, PLAYABLE_VIDEO_MIME_TYPES } from 'constants/file';

export const isPDF = mimeType => MIME_TYPES.pdf.includes(mimeType);
export const isVideo = mimeType => MIME_TYPES.video.includes(mimeType);
export const isPicture = mimeType => MIME_TYPES.image.includes(mimeType);
export const isPlayableVideo = mimeType => PLAYABLE_VIDEO_MIME_TYPES.includes(mimeType);
export const isPreviewable = mimeType => isPicture(mimeType) || isPlayableVideo(mimeType);

export const fileIcon = (mimeType) => {
  if (isPicture(mimeType)) { return 'picture'; }
  if (isVideo(mimeType)) { return 'video-file'; }
  if (isPDF(mimeType)) { return 'pdf'; }

  return 'attachment';
};

export const fileSize = (bytes) => {
  const kilobytes = bytes / 1024;
  const megabytes = bytes / 1024 / 1024;

  if (megabytes >= 1) { return `${ceil(megabytes, 2)} MB`; }
  if (kilobytes >= 1) { return `${ceil(kilobytes, 2)} KB`; }

  return `${ceil(bytes, 2)} B`;
};

// eslint-disable-next-line no-shadow
export const getFileSizeInMb = fileSize => divide(fileSize, 1048576);
