export const DOCTORS_STATUSES = {
  all: '',
  active: 'active',
  invited: 'invited',
  pending: 'pending',
  inactive: 'inactive',
  deactivated: 'deactivated',
  rejected: 'rejected',
};

export const DOCTORS_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: DOCTORS_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: DOCTORS_STATUSES.pending,
    label: { id: 'shared.filters.statuses.pending' },
  },
  {
    value: DOCTORS_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
];

export const DOCTOR_PROFILE_TAB = {
  profile: 'profile',
  account: 'account',
};

export const VIRTUAL_CLINIC_DOCTORS_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: DOCTORS_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: DOCTORS_STATUSES.invited,
    label: { id: 'shared.filters.statuses.invited' },
  },
  {
    value: DOCTORS_STATUSES.pending,
    label: { id: 'shared.filters.statuses.pending' },
  },
  {
    value: DOCTORS_STATUSES.deactivated,
    label: { id: 'shared.filters.statuses.deactivated' },
  },
  {
    value: DOCTORS_STATUSES.rejected,
    label: { id: 'shared.filters.statuses.rejected' },
  },
];

export const VIRTUAL_CLINIC_DOCTOR_PROFILE_TAB = {
  profile: 'profile',
  specialities: 'specialities',
  recurringAvailability: 'recurring_availability',
  dateAvailability: 'date_specific_availability',
  account: 'account',
};

export const VIRTUAL_CLINIC_DOCTOR_TYPES = {
  userProfile: 'user_profile',
  joinRequest: 'join_request',
};

export const MAX_DOCTOR_SPECIALITIES = 2;
