import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setQuestionIds } from '../actions';
import { getSymptomQuestionnaireEndpoint } from '../endpoints';
import { GET_SYMPTOM_QUESTIONNAIRE } from '../types';

const getSymptomQuestionnaireOperation = createLogic({
  type: GET_SYMPTOM_QUESTIONNAIRE,
  latest: true,

  async process({ httpClient, action: { symptomId } }, dispatch, done) {
    const { endpoint, url } = getSymptomQuestionnaireEndpoint(symptomId);
    const params = {
      include: 'symptom,question-options',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setQuestionIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getSymptomQuestionnaireOperation;
