import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { trim } from 'ramda';
import { injectIntl } from 'react-intl';

import SearchListComponent from './component';

class SearchList extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    filterEntities: PropTypes.func.isRequired,
    roleName: PropTypes.string,
  }

  static defaultProps = {
    defaultValue: '',
    roleName: null,
  }

  state = {
    searchQuery: this.props.defaultValue,
  }

  handleSearch = debounce((searchQuery) => {
    const { filterEntities, roleName } = this.props;

    filterEntities({ name: searchQuery }, roleName);
  }, 250)

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchQuery: defaultValue });
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ searchQuery: value });
    this.handleSearch(trim(value));
  }

  handleClear = () => {
    this.setState({ searchQuery: '' });
    this.handleSearch('');
  };

  render() {
    const { searchQuery } = this.state;

    return (
      <SearchListComponent
        {...this.props}
        value={searchQuery}
        onChange={this.handleChange}
        onClear={this.handleClear}
      />
    );
  }
}
export { SearchList as SearchListContainer };
export default injectIntl(SearchList);
