import PropTypes from 'prop-types';

import InputMessage from 'views/shared/InputMessage';

const PasswordStrengthMeterComponent = ({ hideStepper, scaleStep, icon, hint }) => (
  <>
    {scaleStep && !hideStepper && (
      <div className={`main-input__scale main-input__scale--step-${scaleStep}`} />
    )}

    {hint && (
      <InputMessage
        message={hint}
        icon={icon}
      />
    )}
  </>
);

PasswordStrengthMeterComponent.defaultProps = {
  scaleStep: null,
  icon: null,
  hint: null,
};

PasswordStrengthMeterComponent.propTypes = {
  hideStepper: PropTypes.bool.isRequired,
  scaleStep: PropTypes.string,
  icon: PropTypes.string,
  hint: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
};

export default PasswordStrengthMeterComponent;
