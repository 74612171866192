import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import redirect from 'utils/redirect';

import { AUTO_EXIT_OF_CONFERENCE_TIME } from 'constants/timeUnits';

import { hideModal as hideModalAction } from 'state/modal/actions';

import ExitConferenceModalComponent from './component';

class ExitConferenceModal extends React.Component {
  static propTypes = {
    appointmentId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    disconnect: PropTypes.func.isRequired,
  }

  state = {
    second: 0,
  };

  updateTimer = () => {
    this.setState(({ second }) => ({ second: second + 1 }));
  }

  handleSubmit = () => {
    const { appointmentId, hideModal, disconnect } = this.props;

    disconnect();
    hideModal();
    redirect({ href: `/appointments/${appointmentId}` });
  }

  componentDidMount() {
    this.timerHandle = setInterval(this.updateTimer, 1000);
  }

  componentDidUpdate() {
    const { second } = this.state;

    if (second >= AUTO_EXIT_OF_CONFERENCE_TIME) {
      this.handleSubmit();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerHandle);
  }

  render = () => (
    <ExitConferenceModalComponent
      {...this.props}
      onSubmit={this.handleSubmit}
    />
  );
}

const mapDispatchToProps = {
  hideModal: hideModalAction,
};

export { ExitConferenceModal as ExitConferenceModalContainer };
export default connect(null, mapDispatchToProps)(ExitConferenceModal);
