import fetchSymptoms from './fetchSymptoms';
import filterSymptoms from './filterSymptoms';
import activateSymptoms from './activateSymptoms';
import deactivateSymptoms from './deactivateSymptoms';
import submitCreateSymptomDetails from './submitCreateSymptomDetails';
import createSymptomDetails from './createSymptomDetails';
import updateSymptomDetails from './updateSymptomDetails';
import getSymptomDetails from './getSymptomDetails';
import uploadSymptomImage from './uploadSymptomImage';
import removeSymptomImage from './removeSymptomImage';
import submitSymptomQuestionnaire from './submitSymptomQuestionnaire';
import getSymptomQuestionnaire from './getSymptomQuestionnaire';
import submitNewSymptom from './submitNewSymptom';
import getSymptomSpecialities from './getSymptomSpecialities';

export default [
  fetchSymptoms,
  filterSymptoms,
  activateSymptoms,
  deactivateSymptoms,
  submitCreateSymptomDetails,
  createSymptomDetails,
  updateSymptomDetails,
  getSymptomDetails,
  uploadSymptomImage,
  removeSymptomImage,
  submitSymptomQuestionnaire,
  getSymptomQuestionnaire,
  submitNewSymptom,
  getSymptomSpecialities,
];
