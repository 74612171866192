import { combineReducers } from 'redux';

import * as conceptsReducers from '../concepts';
import dataReducer from '../data/reducer';
import intlReducer from '../intl/reducer';
import notificationsReducer from '../notifications/reducer';
import searchResultsReducer from '../searchResults';
import modalReducer from '../modal';
import navigationReducer from '../navigation';
import { RESET_STORE } from './types';

const appReducer = combineReducers({
  ...conceptsReducers,
  data: dataReducer,
  intl: intlReducer,
  notifications: notificationsReducer,
  searchResults: searchResultsReducer,
  modal: modalReducer,
  navigation: navigationReducer,
});

export default (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
