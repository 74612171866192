import endpoint from 'utils/endpoint';

import {
  specialityRoute,
  specialitiesRoute,
  specialitiesBulkActionsActivateRoute,
  specialitiesBulkActionsDeactivateRoute,
  specialitiesBulkActionsRemoveRoute,
  specialitySymptomsRoute,
} from 'lib/apiRoutes';

import {
  FETCH_SPECIALITIES,
  ACTIVATE_SPECIALITIES,
  DEACTIVATE_SPECIALITIES,
  REMOVE_SPECIALITIES,
  CREATE_SPECIALITY,
  UPDATE_SPECIALITY,
  GET_SPECIALITY,
  GET_SPECIALITY_SYMPTOMS,
} from './types';

export const fetchSpecialitiesEndpoint = endpoint(FETCH_SPECIALITIES, 'GET', specialitiesRoute);
export const activateSpecialitiesEndpoint = endpoint(ACTIVATE_SPECIALITIES, 'PUT', specialitiesBulkActionsActivateRoute);
export const deactivateSpecialitiesEndpoint = endpoint(DEACTIVATE_SPECIALITIES, 'PUT', specialitiesBulkActionsDeactivateRoute);
export const removeSpecialitiesEndpoint = endpoint(REMOVE_SPECIALITIES, 'DELETE', specialitiesBulkActionsRemoveRoute);
export const createSpecialityEndpoint = endpoint(CREATE_SPECIALITY, 'POST', specialitiesRoute);
export const updateSpecialityEndpoint = specialityId => endpoint(UPDATE_SPECIALITY, 'PUT', specialityRoute(specialityId));
export const getSpecialityEndpoint = specialityId => endpoint(GET_SPECIALITY, 'GET', specialityRoute(specialityId));
export const getSpecialitySymptomsEndpoint = specialityId => endpoint(GET_SPECIALITY_SYMPTOMS, 'GET', specialitySymptomsRoute(specialityId));
