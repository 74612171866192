import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { selectedSelector } from '../selectors';
import { downloadVouchersCsvEndpoint } from '../endpoints';
import { DOWNLOAD_VOUCHERS_CSV } from '../types';

const downloadVouchersCsvOperation = createLogic({
  type: DOWNLOAD_VOUCHERS_CSV,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();

    const selectedVouchers = selectedSelector(state);
    const { endpoint, url } = downloadVouchersCsvEndpoint;

    const params = {
      voucher_ids: selectedVouchers,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params }, { headers: { 'content-type': 'text/csv' } });

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(data, 'vouchers.csv');
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadVouchersCsvOperation;
