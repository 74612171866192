import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck, propEq } from 'ramda';

import { TUTORIAL_STEPS } from 'constants/userTutorial';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { skipStep } from 'state/concepts/userProfile/tutorial/actions';
import { currentUserTutorialSelector } from 'state/concepts/session/selectors';
import { updateTutorialStepAvailabilityEndpoint } from 'state/concepts/userProfile/tutorial/endpoints';

import { setDateAvailability } from '../actions';
import { updateDateAvailabilityEndpoint } from '../endpoints';
import { UPDATE_DATE_AVAILABILITY } from '../types';

const updateDateAvailabilityOperation = createLogic({
  type: UPDATE_DATE_AVAILABILITY,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const state = getState();
    const tutorial = currentUserTutorialSelector(state);
    const {
      availabilities, startDate, endDate, userId, isDoctorProfilePage,
    } = action;
    const { endpoint, url } = updateDateAvailabilityEndpoint(userId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        date_availabilities: availabilities,
        filter: {
          start_date: startDate,
          end_date: endDate,
        },
      };
      const { data } = await httpClient.put(url, params);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setDateAvailability(pluck('id', data.data)));
      dispatch(showNotification({
        messageObject: {
          id: isDoctorProfilePage
            ? 'notifications.doctorsProfileUpdated'
            : 'notifications.dateAvailabilityUpdated',
        },
      }));
      if (isPresent(tutorial) && propEq(TUTORIAL_STEPS.availability, false, tutorial)) {
        dispatch(skipStep(
          TUTORIAL_STEPS.availability,
          updateTutorialStepAvailabilityEndpoint,
        ));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }
    done();
  },
});

export default updateDateAvailabilityOperation;
