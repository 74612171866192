import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal as hideModalAction } from 'state/modal/actions';
import { removeAttachment as removeAttachmentAction } from 'state/concepts/userProfile/chat/actions';
import { chatIdSelector, removeAttachmentsLoadingSelector } from 'state/concepts/userProfile/chat/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class RemoveFileModal extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    removeAttachment: PropTypes.func.isRequired,
    fileId: PropTypes.string.isRequired,
    chatId: PropTypes.string.isRequired,
  };

  handleSubmit = () => {
    const {
      hideModal,
      removeAttachment,
      fileId,
      chatId,
    } = this.props;

    removeAttachment(chatId, fileId);
    hideModal();
  }

  render() {
    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'chat.attachments.remove.modal.title' }}
        bodyText={[
          { id: 'chat.attachments.remove.modal.body' },
          { id: 'chat.attachments.remove.modal.text' },
        ]}
        submitText={{ id: 'shared.delete' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = (state, { fileId }) => ({
  isLoading: removeAttachmentsLoadingSelector(state, fileId),
  chatId: chatIdSelector(state),
});

const mapDispatchToProps = {
  hideModal: hideModalAction,
  removeAttachment: removeAttachmentAction,
};

export { RemoveFileModal as RemoveFileModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(RemoveFileModal);
