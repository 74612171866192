import { combineReducers } from 'redux';
import { concat, without } from 'ramda';

import { WS_STATUSES } from 'constants/webSockets';

import * as types from './types';

const status = (state = WS_STATUSES.closed, action) => {
  switch (action.type) {
    case types.SET_CONNECTION_STATUS:
      return action.status;
    default:
      return state;
  }
};

const channels = (state = [], action) => {
  switch (action.type) {
    case types.ADD_SUBSCRIPTION_CHANNELS:
      return concat(state, action.channels);
    case types.REMOVE_SUBSCRIPTION_CHANNELS:
      return without(action.channels, state);
    case types.RESET_SUBSCRIPTION_CHANNELS:
      return [];
    default:
      return state;
  }
};

const webSocketsReducer = combineReducers({
  status,
  channels,
});

export default webSocketsReducer;
