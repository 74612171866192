// eslint-disable-next-line import/prefer-default-export
export const IMAGES = {
  logo: {
    en: 'clinix-logo.svg',
    es: 'logo-de-clinix.svg',
    gu: 'clinix-logo.svg',
  },
  logoMobile: {
    en: 'clinix-logo-mobile.svg',
    es: 'logo-de-clinix-movil.svg',
    gu: 'clinix-logo-mobile.svg',
  },
};
