import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setVideoConferenceId, canJoin } from '../actions';
import { joinVideoConferenceEndpoint } from '../endpoints';
import { JOIN_VIDEO_CONFERENCE } from '../types';

const joinVideoConferenceOperation = createLogic({
  type: JOIN_VIDEO_CONFERENCE,
  latest: true,

  async process({ httpClient, action: { appointmentId } }, dispatch, done) {
    const { endpoint, url } = joinVideoConferenceEndpoint(appointmentId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url);
      const normalizedData = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setVideoConferenceId(data.data.id));
      dispatch(canJoin(true));
    } catch (error) {
      dispatch(canJoin(false));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default joinVideoConferenceOperation;
