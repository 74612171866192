import fetchPatientAccountProfile from './fetchPatientAccountProfile';
import submitPatientAccountVoucherTopup from './submitPatientAccountVoucherTopup';
import submitPatientAccountPagoparTopup from './submitPatientAccountPagoparTopup';
import confirmEmail from './confirmEmail';

export default [
  fetchPatientAccountProfile,
  submitPatientAccountVoucherTopup,
  submitPatientAccountPagoparTopup,
  confirmEmail,
];
