import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { activateDoctor } from 'state/concepts/userProfile/doctors/actions';
import { activateDoctorLoadingSelector } from 'state/concepts/userProfile/doctors/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ActivateDoctorModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    doctor: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, doctor: { id } } = this.props;

    onSubmit(id);
  }

  render() {
    const { doctor } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'doctors.activateModal.title' }}
        bodyText={[{
          id: 'doctors.activateModal.body',
          values: { name: <span className="f-700">{profileName(doctor)}</span> },
        }]}
        submitText={{ id: 'shared.activate' }}
      />
    );
  }
}

const mapStateToProps = (state, { doctor: { id } }) => ({
  isLoading: activateDoctorLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: activateDoctor,
};

export { ActivateDoctorModal as ActivateDoctorModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ActivateDoctorModal);
