import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import { dataApiSuccess } from 'state/data/actions';
import { setNotificationIds, incUnreadNotificationsCount } from '../actions';
import { unreadNotificationsCountSelector } from '../selectors';
import { RECEIVE_NOTIFICATION } from '../types';

const receiveNotificationOperation = createLogic({
  type: RECEIVE_NOTIFICATION,
  latest: true,

  async process({ getState, action: { notification } }, dispatch, done) {
    const state = getState();
    const response = normalize(notification);
    const unreadCount = unreadNotificationsCountSelector(state);

    dispatch(dataApiSuccess({ response }));
    dispatch(setNotificationIds([notification.data.id]));
    dispatch(incUnreadNotificationsCount(unreadCount));

    done();
  },
});

export default receiveNotificationOperation;
