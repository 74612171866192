import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  compose, includes, trim, isEmpty, without,
} from 'ramda';

import {
  filterSpecialitiesList as filterSpecialitiesListAction,
  fetchSpecialitiesList as fetchSpecialitiesListAction,
  resetSpecialitiesListIds as resetSpecialitiesListIdsAction,
  resetSpecialitiesListFilterParams as resetSpecialitiesListFilterParamsAction,
} from 'state/concepts/userProfile/specialities/actions';
import {
  specialitiesListMetaSelector,
  specialitiesListLoadingSelector,
  specialitiesListForFilterSelector,
  excludeSpecialitiesListIdsSelector,
} from 'state/concepts/userProfile/specialities/selectors';
import { currentUserSpecialityIdsSelector } from 'state/concepts/session/selectors';

import DropdownSpecialitiesFieldComponent from './component';

class DropdownSpecialitiesField extends React.Component {
  static propTypes = {
    filterSpecialitiesList: PropTypes.func.isRequired,
    resetSpecialitiesListIds: PropTypes.func.isRequired,
    resetSpecialitiesListFilterParams: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    clinicSpecialities: PropTypes.arrayOf(PropTypes.shape()),
    excludeSpecialityIds: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.shape().isRequired,
    currentUserSpecialityIds: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    options: [],
    clinicSpecialities: [],
    excludeSpecialityIds: [],
    currentUserSpecialityIds: [],
  }

  state = {
    searchQuery: '',
  };

  componentDidMount = () => {
    const { filterSpecialitiesList, currentUserSpecialityIds, field: { value } } = this.props;
    const excludeSpecialityIds = without([value], currentUserSpecialityIds);

    filterSpecialitiesList({ excludeSpecialityIds });
  }

  componentWillUnmount = () => {
    const { resetSpecialitiesListIds, resetSpecialitiesListFilterParams } = this.props;

    resetSpecialitiesListIds();
    resetSpecialitiesListFilterParams();
  }

  get isAllSpecialitiesSelected() {
    const { options, field: { value } } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => includes(id, [].concat(value)));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterSpecialitiesList, resetSpecialitiesListIds, excludeSpecialityIds } = this.props;

    this.setState({ searchQuery });
    resetSpecialitiesListIds();
    filterSpecialitiesList({
      name: trim(searchQuery),
      excludeSpecialityIds,
    });
  }, 250)

  handleFocus = () => {
    const { filterSpecialitiesList, excludeSpecialityIds } = this.props;

    this.setState({ searchQuery: '' });
    filterSpecialitiesList({
      name: '',
      excludeSpecialityIds,
    });
  }

  render = () => (
    <DropdownSpecialitiesFieldComponent
      {...this.props}
      isAllSpecialitiesSelected={this.isAllSpecialitiesSelected}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

const mapStateToProps = state => ({
  meta: specialitiesListMetaSelector(state),
  isLoading: specialitiesListLoadingSelector(state),
  options: specialitiesListForFilterSelector(state),
  excludeSpecialityIds: excludeSpecialitiesListIdsSelector(state),
  currentUserSpecialityIds: currentUserSpecialityIdsSelector(state),
});

const mapDispatchToProps = {
  fetchSpecialitiesList: fetchSpecialitiesListAction,
  filterSpecialitiesList: filterSpecialitiesListAction,
  resetSpecialitiesListIds: resetSpecialitiesListIdsAction,
  resetSpecialitiesListFilterParams: resetSpecialitiesListFilterParamsAction,
};

export { DropdownSpecialitiesField as DropdownSpecialitiesFieldContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DropdownSpecialitiesField);
