const namespace = 'patient';

export const FETCH_PATIENT = `${namespace}/FETCH_PATIENT`;
export const FETCH_PATIENT_APPOINTMENTS = `${namespace}/FETCH_PATIENT_APPOINTMENTS`;
export const FETCH_PATIENT_NOTES = `${namespace}/FETCH_PATIENT_NOTES`;
export const SET_PATIENT = `${namespace}/SET_PATIENT`;
export const SET_PATIENT_APPOINTMENTS = `${namespace}/SET_PATIENT_APPOINTMENTS`;
export const SET_PATIENT_NOTES = `${namespace}/SET_PATIENT_NOTES`;
export const SET_PATIENT_STATUSES = `${namespace}/SET_PATIENT_STATUSES`;
export const SET_PATIENT_PAGE = `${namespace}/SET_PATIENT_PAGE`;
export const SET_PATIENT_APPOINTMENTS_SORT_ORDER = `${namespace}/SET_PATIENT_APPOINTMENTS_SORT_ORDER`;
export const SET_PATIENT_NOTES_SORT_ORDER = `${namespace}/SET_PATIENT_NOTES_SORT_ORDER`;
export const SET_PATIENT_FILTER = `${namespace}/SET_PATIENT_FILTER`;
export const SET_PATIENT_FILTER_PARAMS = `${namespace}/SET_PATIENT_FILTER_PARAMS`;
export const SET_PATIENT_BLANK_STATE = `${namespace}/SET_PATIENT_BLANK_STATE`;
export const GET_PATIENT_OWN_PROFILE = `${namespace}/GET_PATIENT_OWN_PROFILE`;
export const ACTIVATE_PATIENT = `${namespace}/ACTIVATE_PATIENT`;
export const BAN_PATIENT = `${namespace}/BAN_PATIENT`;
export const SUBMIT_PATIENT_PROFILE = `${namespace}/SUBMIT_PATIENT_PROFILE`;
export const SUBMIT_CHILDREN_PROFILE = `${namespace}/SUBMIT_CHILDREN_PROFILE`;
export const SET_PATIENT_CHILDREN_IDS = `${namespace}/SET_PATIENT_CHILDREN_IDS`;
export const SET_PATIENT_DETAILS_ACTIVE_TAB = `${namespace}/SET_PATIENT_DETAILS_ACTIVE_TAB`;
