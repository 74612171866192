import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck, path } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setRecoveryCodeIds, setProvisioningUri } from '../actions';
import { fetchRecoveryCodesEndpoint } from '../endpoints';
import { FETCH_RECOVERY_CODES } from '../types';

const fetchRecoveryCodesOperation = createLogic({
  type: FETCH_RECOVERY_CODES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchRecoveryCodesEndpoint;
    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRecoveryCodeIds(pluck('id', data.data)));
      dispatch(setProvisioningUri(path(['meta', 'provisioning_uri'], data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRecoveryCodesOperation;
