import fetchPushNotifications from './fetchPushNotifications';
import filterClinicsList from './filterClinicsList';
import filterPatientsList from './filterPatientsList';
import fetchClinics from './fetchClinics';
import fetchPatients from './fetchPatients';
import validatePushNotifications from './validatePushNotifications';
import submitCreatePushNotification from './submitCreatePushNotification';

export default [
  fetchPushNotifications,
  filterClinicsList,
  filterPatientsList,
  fetchClinics,
  fetchPatients,
  validatePushNotifications,
  submitCreatePushNotification,
];
