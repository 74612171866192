import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { submitUpdateExtraFreeVisitsEndpoint } from '../endpoints';
import { SUBMIT_UPDATE_FREE_VISITS } from '../types';

const submitUpdateFreeVisitsOperation = createLogic({
  type: SUBMIT_UPDATE_FREE_VISITS,
  latest: true,

  async process({ httpClient, getState, action: { freeVisit, values, form } }, dispatch, done) {
    const state = getState();
    const { url, endpoint } = submitUpdateExtraFreeVisitsEndpoint(freeVisit.id);

    const params = {
      free_visits_count: values.freeVisitsCount,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'extraFreeVisit', freeVisit.id,
        { attributes: { freeVisitsCount: values.freeVisitsCount } },
      );

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.freeVisitsHasBeenUpdated' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.freeVisitsHasBeenUpdatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitUpdateFreeVisitsOperation;
