import { createLogic } from 'redux-logic';

import { isRevenueVirtualType } from 'utils/revenue';

import { filterDoctorList } from 'state/concepts/userProfile/doctors/actions';
import { filterRevenueVirtualClinicDoctors } from '../actions';
import { revenueTypeSelector } from '../selectors';
import { SET_REVENUE_DOCTORS_LIST_FILTERS } from '../types';

const filterRevenueDoctorsListOperation = createLogic({
  type: SET_REVENUE_DOCTORS_LIST_FILTERS,
  latest: true,

  async process({ getState, action: { filters } }, dispatch, done) {
    const state = getState();
    const revenueType = revenueTypeSelector(state);

    if (isRevenueVirtualType(revenueType)) {
      dispatch(filterRevenueVirtualClinicDoctors(filters));
    } else {
      dispatch(filterDoctorList(filters));
    }

    done();
  },
});

export default filterRevenueDoctorsListOperation;
