import endpoint from 'utils/endpoint';

import {
  joinVideoConferenceRoute,
  frontendLogsRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const joinVideoConferenceEndpoint = appointmentId => endpoint(types.JOIN_VIDEO_CONFERENCE, 'POST', joinVideoConferenceRoute(appointmentId));
export const loggerVideoConnectionEndpoint = endpoint(types.SEND_LOGGERS, 'POST', frontendLogsRoute);
