import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import {
  getClinicIdsValue,
  getPatientIdsValue,
  getSpecialityIdsValue,
  getGenderValue,
  getChildrenValue,
  getAgeFromValue,
  getAgeToValue,
} from 'utils/pushNotifications';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { VALIDATE_PUSH_NOTIFICATIONS } from '../types';
import { validatePushNotificationsEndpoint } from '../endpoints';

const validatePushNotificationsOperation = createLogic({
  type: VALIDATE_PUSH_NOTIFICATIONS,
  latest: true,

  async process({ httpClient, action: { values } }, dispatch, done) {
    const { endpoint, url } = validatePushNotificationsEndpoint;

    const params = {
      filter: {
        ...getClinicIdsValue(values.clinicIds),
        ...getPatientIdsValue(values.patientIds),
        ...getSpecialityIdsValue(values.specialityIds),
        ...getGenderValue(values.gender),
        ...getChildrenValue(values.children),
        ...getAgeFromValue(values.ageFrom),
        ...getAgeToValue(values.ageTo),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default validatePushNotificationsOperation;
