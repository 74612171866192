import { connect } from 'react-redux';

import { hideModal } from 'state/modal/actions';
import ErrorModalComponent from './component';

const mapDispatchToProps = {
  onClose: hideModal,
};

export default connect(null, mapDispatchToProps)(ErrorModalComponent);
