import React from 'react';
import PropTypes from 'prop-types';
import { DefaultPlayer as Video } from 'react-html5video';

import { isPicture, isPlayableVideo, isPreviewable, fileSize } from 'utils/file';

import SvgIcon from 'views/shared/SvgIcon';

const Slide = ({
  url,
  size,
  filename,
  mimeType,
  intl: {
    formatMessage,
  },
}) => (
  <div className="preview-carousel__slide">
    {isPicture(mimeType) && (
      <img
        src={url}
        className="preview-carousel__slide-img"
        alt={formatMessage({ id: 'shared.attachment' })}
      />
    )}
    {isPlayableVideo(mimeType) && (
      <Video>
        <source src={url} type={mimeType} />
      </Video>
    )}
    {!isPreviewable(mimeType) && (
      <div className="modal-files__file">
        <SvgIcon icon="attachment" className="modal-files__file-icon" />
        <div className="modal-files__file-name">
          {filename}
        </div>
        <div className="modal-files__file-size">
          {fileSize(size)}
        </div>
      </div>
    )}
  </div>
);

Slide.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default Slide;
