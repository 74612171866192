// import { merge, omit, flattenDeep, mergeWith, isArray } from 'lodash';
import { mergeDeepRight, omit, flatten } from 'ramda';
import * as types from './types';

const initialState = {
  meta: {},
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DATA_API_REQUEST:
      return mergeDeepRight(state, { meta: { [action.endpoint]: { loading: true } } });
    case types.DATA_API_SUCCESS:
      return mergeDeepRight(
        state,
        mergeDeepRight(action.response, { meta: { [action.endpoint]: { loading: false } } }),
      );
    case types.DATA_API_FAILURE:
      return mergeDeepRight(state, { meta: { [action.endpoint]: { loading: false } } });
    case types.DATA_DELETE:
      return { ...state, [action.kind]: omit(action.ids, state[action.kind]) };
    // case types.DATA_REMOVE_RELATIONSHIP: {
    //   const {
    //     kind, ownerId, relationship, ids,
    //   } = action;
    //   const targetIds = flattenDeep([ids]);
    //   return {
    //     ...state,
    //     [kind]: {
    //       ...state[kind],
    //       [ownerId]: {
    //         ...state[kind][ownerId],
    //         relationships: {
    //           ...state[kind][ownerId].relationships,
    //           [relationship]: {
    //             data: state[kind][ownerId].relationships[relationship].data.filter(element => (
    //               !targetIds.includes(element.id)
    //             )),
    //           },
    //         },
    //       },
    //     },
    //   };
    // }
    case types.DATA_CLEAR_RELATIONSHIP: {
      const items = {};
      const data = action.isPlural ? [] : {};
      flatten([action.ids]).forEach((id) => {
        items[id] = {
          ...state[action.kind][id],
          relationships: {
            ...state[action.kind][id].relationships,
            [action.relationship]: { data },
          },
        };
      });
      return {
        ...state,
        [action.kind]: {
          ...state[action.kind],
          ...items,
        },
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
