import {
  cond,
  equals,
  T,
  pathOr,
  includes,
  not,
  always,
} from 'ramda';

import { captureException } from 'lib/sentry';

import { dataApiFailure } from 'state/data/actions';

import makeHandleForm from './makeHandleForm';
import makeSetErrorToStore from './makeSetErrorToStore';
import makeShowNotificationAndSendErrorToSentry from './makeShowNotificationAndSendErrorToSentry';

const requestErrorHandler = ({
  error,
  dispatch,
  onlySendErrorToSentry = false,
  endpoint = '',
  form = {},
  formStatusKeys = ['base'],
  excludeErrorArray = [],
  disableCapitalize = false,
}) => {
  const requestMethod = pathOr(null, ['response', 'config', 'method'], error);
  const requestStatus = pathOr(null, ['response', 'status'], error);
  const excludedErrorArray = [...excludeErrorArray, 401];

  const handleGetRequest = cond([
    [equals(403), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(404), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(422), always(undefined)],
    [T, makeShowNotificationAndSendErrorToSentry(dispatch, error)],
  ]);

  const handleOtherRequests = cond([
    [equals(403), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(422), makeHandleForm(form, error, formStatusKeys, disableCapitalize)],
    [T, makeShowNotificationAndSendErrorToSentry(dispatch, error)],
  ]);

  if (endpoint) {
    dispatch(dataApiFailure({ endpoint }));
  }

  if (onlySendErrorToSentry) {
    captureException(error);
  } else if (not(includes(requestStatus, excludedErrorArray))) {
    requestMethod === 'get' ? handleGetRequest(requestStatus) : handleOtherRequests(requestStatus);
  }
};

export default requestErrorHandler;
