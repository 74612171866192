import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'formik';
import { prop } from 'ramda';

import Modal from 'views/shared/Modal';
import PasswordInputField from 'views/shared/PasswordInputField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import SvgIcon from 'views/shared/SvgIcon';
import Alert from 'views/shared/Alert';

const DeactivateAccountModalComponent = ({
  onClose,
  handleSubmit,
  status,
}) => (
  <Modal className="ant-modal" wrapClassName="ant-modal-wrap__6" onClose={onClose}>
    <Form
      className="w-full"
    >
      <div className="ant-modal__header">
        <h2 className="ant-modal__header-txt">
          <FormattedMessage id="deactivateAccountModal.title" />
        </h2>
        <button type="button" onClick={onClose}>
          <SvgIcon icon="cross" className="ant-modal__close" />
        </button>
      </div>
      <div className="ant-modal__body">
        {prop('base', status) && (
          <Alert
            className="auth__alert"
            message={prop('base', status)}
          />
        )}
        <p className="modal-message__txt">
          <FormattedMessage
            id="deactivateAccountModal.areYouSure"
            values={{ breakingLine: <br /> }}
          />
        </p>
        <Field
          id="password"
          name="password"
          component={PasswordInputField}
          label={{ id: 'deactivateAccountModal.label.password' }}
          icon="alert"
          className="w-320"
          visiblePassword
          autoComplete="off"
        />
      </div>
      <div className="ant-modal__footer ant-modal__footer--right">
        <Button
          text={{ id: 'shared.cancel' }}
          onClick={onClose}
          kind="tertiary"
        />
        <SubmitFormButton
          text={{ id: 'deactivateAccountModal.button' }}
          onClick={handleSubmit}
          isEditForm
          overrideKind={['outline-danger', 'danger']}
        />
      </div>
    </Form>
  </Modal>
);

DeactivateAccountModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape(),
};

DeactivateAccountModalComponent.defaultProps = {
  status: undefined,
};

export default DeactivateAccountModalComponent;
