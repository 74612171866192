import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import configureStore from 'state/store';
import withRedux from 'next-redux-wrapper';
import { withRouter } from 'next/router';

import 'assets/styles/style.scss';

import { sentryInit } from 'lib/sentry';
import { mountInterceptors } from 'lib/httpClient';
import WebSocketConnection from 'lib/webSocketConnection';

import Meta from 'views/layouts/Meta';
import ModalRoot from 'views/ModalRoot';
import SvgRootComponent from 'views/shared/SvgRoot';
import ErrorHandler from 'views/errors/ErrorHandler';

sentryInit();

const restrictedPrefixesRoutes = ['/patients/account', '/patient/account', '/patient/login?token'];

class Application extends App {
  static async getInitialProps({ Component, ctx, router }) {
    const { isServer, store } = ctx;

    // preparing axios instance on server
    if (isServer) {
      const isRestricted = restrictedPrefixesRoutes
      .map((item) => router.asPath.includes(item))
      .some((item) => item === true);

      if (isRestricted) {
        ctx.res.writeHead(302, { Location: '/download_app' });
        ctx.res.end();
      }
      mountInterceptors(ctx);
    }
    // run getInitialProps for page component
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    // wait for all logics to finish when doing SSR
    if (isServer) {
      await Promise.all(store.logicMiddlewares.map(async (mw) => {
        await mw.whenComplete();
      }));
    }

    return { pageProps };
  }

  componentDidMount() {
    // preparing axios instance on client (componentDidMount runs only on client)
    mountInterceptors({ store: this.props.store });
  }

  render = () => {
    const { Component, pageProps, store } = this.props;

    return (
      <Provider store={store}>
        <IntlProvider>
          <Meta pageTitle={Component.pageTitle} />
          <ErrorHandler>
            <Component {...pageProps} />
          </ErrorHandler>
          <WebSocketConnection />
          <SvgRootComponent />
          <ModalRoot />
        </IntlProvider>
      </Provider>
    );
  };
}

export default withRedux(configureStore)(withRouter(Application));
