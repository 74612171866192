import { inc } from 'ramda';

import { QUESTION_TYPE } from 'constants/symptoms';

export default (questions) => questions.map(({
  title, questionType, questionOptions, required,
}, position) => ({
  title,
  position: inc(position),
  question_type: questionType,
  required,
  question_options: questionType === QUESTION_TYPE.yesNo ? undefined : questionOptions.map((
    { title: optionTitle }, index,
  ) => ({ title: optionTitle, position: inc(index) })),
}));

