import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import { acceptCvFileTypes } from 'constants/file';

import Modal from 'views/shared/Modal';
import InputField from 'views/shared/InputField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import PhoneInputField from 'views/shared/PhoneInputField';
import CVField from 'views/shared/CVField';

const VCDJoinRequestModalComponent = ({
  isLoading,
  onClose,
  handleSubmit,
}) => (
  <Modal wrapClassName="ant-modal-wrap__8" onClose={onClose}>
    <div className="auth-card w-full">
      <div className="auth-card__content">
        <h2 className="auth-card__title">
          <FormattedMessage id="vcd.joinRequest.inviteDoctor" />
        </h2>
        <div className="auth-card__grid">
          <Field
            name="firstName"
            component={InputField}
            label={{ id: 'label.firstName' }}
            icon="alert"
          />
          <Field
            name="lastName"
            component={InputField}
            label={{ id: 'label.lastName' }}
            icon="alert"
          />
          <Field
            name="email"
            type="email"
            component={InputField}
            label={{ id: 'label.email' }}
            icon="alert"
          />
          <Field
            name="phone"
            component={PhoneInputField}
            label={{ id: 'label.phoneNumber' }}
            defaultCountry="PY"
            international
            icon="alert"
          />
        </div>
        <Field
          name="cv"
          component={CVField}
          accept={acceptCvFileTypes}
          icon="alert"
          className="w-half"
        />
      </div>
      <footer className="auth-card__footer">
        <Button
          text={{ id: 'shared.cancel' }}
          kind="tertiary"
          onClick={onClose}
        />
        <SubmitFormButton
          text={{ id: 'shared.send' }}
          type="submit"
          className="w-160"
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </footer>
    </div>
  </Modal>
);

VCDJoinRequestModalComponent.defaultProps = {
  isLoading: undefined,
};

VCDJoinRequestModalComponent.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default VCDJoinRequestModalComponent;
