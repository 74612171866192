import fetchDashboards from './fetchDashboards';
import filterAvailabilities from './filterAvailabilities';
// import filterClinicsList from './filterClinicsList';
// import filterPatientsList from './filterPatientsList';
// import fetchClinics from './fetchClinics';
// import fetchPatients from './fetchPatients';
import validateDashboards from './validateDashboards';

export default [
  fetchDashboards,
  filterAvailabilities,
  // filterClinicsList,
  // filterPatientsList,
  // fetchClinics,
  // fetchPatients,
  validateDashboards,
];
