import endpoint from 'utils/endpoint';
import {
  usersAccountProfileRoute,
  usersJoinRequestsRoute,
  usersAccountProfilesMyselfRoute,
  usersDeactivationRoute,
  userAccountProfileCommissionSettingsRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const getUsersOwnProfileEndpoint = endpoint(types.GET_USER_OWN_PROFILE, 'GET', usersAccountProfileRoute);
export const updateUserProfileEndpoint = endpoint(types.UPDATE_USER_PROFILE, 'PUT', usersAccountProfileRoute);
export const getUserMyselfEndpoint = endpoint(types.GET_USER_MYSELF, 'GET', usersAccountProfilesMyselfRoute);
export const submitJoinRequestEndpoint = endpoint(types.SUBMIT_JOIN_REQUEST, 'POST', usersJoinRequestsRoute);
export const deactivateUserEndpoint = userId => endpoint(types.DEACTIVATE_USER, 'POST', usersDeactivationRoute(userId));
export const getUserCommissionSettingsEndpoint = endpoint(types.GET_USER_COMMISSION_SETTINGS, 'GET', userAccountProfileCommissionSettingsRoute);
