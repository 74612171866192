import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'ramda';

import { languageSelector } from 'state/intl/selectors';

import ErrorLayoutComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class ErrorLayout extends React.Component {
  render() {
    return (
      <ErrorLayoutComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  language: languageSelector(state),
});

export { ErrorLayout as ErrorLayoutContainer };
export default compose(
  connect(mapStateToProps),
  injectIntl,
)(ErrorLayout);
