import endpoint from 'utils/endpoint';
import {
  usersOnboardingStepperAvailabilityRoute,
  usersOnboardingStepperClinicSpecialitiesRoute,
  usersOnboardingStepperInviteVirtualClinicDoctorRoute,
  usersOnboardingStepperCongratulationsRoute,
} from 'lib/apiRoutes';
import { SKIP_STEP, FINISH_TUTORIAL } from './types';

export const updateTutorialStepAvailabilityEndpoint = endpoint(SKIP_STEP, 'PUT', usersOnboardingStepperAvailabilityRoute);
export const updateTutorialStepClinicSpecialitiesEndpoint = endpoint(SKIP_STEP, 'PUT', usersOnboardingStepperClinicSpecialitiesRoute);
export const updateTutorialStepInviteVirtualClinicDoctorEndpoint = endpoint(SKIP_STEP, 'PUT', usersOnboardingStepperInviteVirtualClinicDoctorRoute);
export const updateTutorialStepCongratulationsEndpoint = endpoint(FINISH_TUTORIAL, 'PUT', usersOnboardingStepperCongratulationsRoute);
