import { createLogic } from 'redux-logic';

import { MILLISECONDS_PER_MINUTE } from 'constants/timeUnits';

import { refreshActivityEndpoint } from '../endpoints';
import { REFRESH_ACTIVITY } from '../types';

const refreshActivityOperation = createLogic({
  type: REFRESH_ACTIVITY,
  throttle: MILLISECONDS_PER_MINUTE,

  async process({ httpClient }, dispatch, done) {
    const { url } = refreshActivityEndpoint;
    try {
      await httpClient.put(url);
    // eslint-disable-next-line no-empty
    } catch {}
    done();
  },
});

export default refreshActivityOperation;
