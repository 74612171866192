import { createLogic } from 'redux-logic';

import isPresent from 'utils/isPresent';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { getDiseases, searchDiseases, destroyDiseasesIds } from '../actions';
import { FILTER_DISEASES } from '../types';

const filterDiseasesOperation = createLogic({
  type: FILTER_DISEASES,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));

    if (isPresent(filters.name)) {
      dispatch(destroyDiseasesIds());
      dispatch(searchDiseases());
    } else {
      dispatch(getDiseases());
    }

    done();
  },
});

export default filterDiseasesOperation;
