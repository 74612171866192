import React from 'react';
import { connect } from 'react-redux';

import {
  filterClinicsList as filterClinicsListAction,
  fetchClinicsList as fetchClinicsListAction,
  resetClinicsListIds as resetClinicsListIdsAction,
  resetClinicsListFilterParams as resetClinicsListFilterParamsAction,
} from 'state/concepts/userProfile/freeVisits/actions';
import {
  clinicsListMetaSelector,
  clinicsListLoadingSelector,
  clinicsListForFilterSelector,
  clinicsListIdsSelector,
} from 'state/concepts/userProfile/freeVisits/selectors';

import DropdownField from 'views/shared/DropdownField';

// eslint-disable-next-line react/prefer-stateless-function
class ClinicsDropdown extends React.Component {
  render = () => (
    <DropdownField
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  meta: clinicsListMetaSelector(state),
  isLoading: clinicsListLoadingSelector(state),
  options: clinicsListForFilterSelector(state),
  optionsIds: clinicsListIdsSelector(state),
});

const mapDispatchToProps = {
  fetchList: fetchClinicsListAction,
  filterList: filterClinicsListAction,
  resetListIds: resetClinicsListIdsAction,
  resetListFilterParams: resetClinicsListFilterParamsAction,
};

export { ClinicsDropdown as ClinicsDropdownContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ClinicsDropdown);
