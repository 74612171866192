import { combineReducers } from 'redux';
import { without } from 'ramda';

import { VOUCHERS_TAB } from 'constants/vouchers';

import * as types from './types';

const voucherIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VOUCHERS:
      return action.voucherIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'created_at', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const selectedVouchers = (state = [], action) => {
  switch (action.type) {
    case types.SELECT_VOUCHER:
      return state.includes(action.voucherId)
        ? without([action.voucherId], state)
        : [...state, action.voucherId];
    case types.SET_VOUCHERS_SELECTED:
      return action.voucherIds;
    default:
      return state;
  }
};

const activeTab = (state = VOUCHERS_TAB.inactive, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_ACTIVE_TAB:
      return action.tabName;
    default:
      return state;
  }
};

export default combineReducers({
  voucherIds,
  isBlankState,
  pagination,
  sort,
  selectedVouchers,
  activeTab,
});
