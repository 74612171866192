import { combineReducers } from 'redux';

import { SET_TIMEZONE } from './types';

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezone;
    default:
      return state;
  }
};

const timezonesReducer = combineReducers({
  timezone,
});

export default timezonesReducer;
