import endpoint from 'utils/endpoint';

import {
  specialitiesRoute,
  clinicSpecialitiesRoute,
  clinicSpecialitiesBulkActionsDeactivateRoute,
  clinicSpecialitiesBulkActionsActivateRoute,
  clinicSpecialitiesBulkActionsRemoveRoute,
  clinicSpecialityRoute,
  virtualClinicsClinicSpecialitiesRoute,
  virtualClinicsClinicSpecialityRoute,
  virtualClinicsClinicSpecialitiesProvidersRoute,
  virtualClinicsClinicSpecialitiesBulkActionsActivateRoute,
  virtualClinicsClinicSpecialitiesBulkActionsDeactivateRoute,
  virtualClinicsClinicSpecialitiesBulkActionsRemoveRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchClinicSpecialitiesEndpoint = endpoint(types.FETCH_CLINIC_SPECIALITIES, 'GET', clinicSpecialitiesRoute);
export const submitClinicSpecialityEndpoint = endpoint(types.SUBMIT_CLINIC_SPECIALITY, 'POST', clinicSpecialitiesRoute);
export const activateClinicSpecialitiesEndpoint = endpoint(types.ACTIVATE_CLINIC_SPECIALITIES, 'PUT', clinicSpecialitiesBulkActionsActivateRoute);
export const deactivateClinicSpecialitiesEndpoint = endpoint(types.DEACTIVATE_CLINIC_SPECIALITIES, 'PUT', clinicSpecialitiesBulkActionsDeactivateRoute);
export const removeClinicSpecialitiesEndpoint = endpoint(types.REMOVE_CLINIC_SPECIALITIES, 'DELETE', clinicSpecialitiesBulkActionsRemoveRoute);
export const fetchAllSpecialitiesEndpoint = endpoint(types.FETCH_ALL_SPECIALITIES, 'GET', specialitiesRoute);
export const showClinicSpecialityDetailsEndpoint = clinicSpecialityId => endpoint(types.SHOW_CLINIC_SPECIALITY_DETAILS, 'GET', clinicSpecialityRoute(clinicSpecialityId));
export const updateClinicSpecialityEndpoint = clinicSpecialityId => endpoint(types.UPDATE_CLINIC_SPECIALITY, 'PUT', clinicSpecialityRoute(clinicSpecialityId));

export const fetchVirtualClinicSpecialitiesEndpoint = clinicId => endpoint(types.FETCH_CLINIC_SPECIALITIES, 'GET', virtualClinicsClinicSpecialitiesRoute(clinicId));
export const submitVirtualClinicSpecialityEndpoint = clinicId => endpoint(types.SUBMIT_VIRTUAL_CLINIC_SPECIALITY, 'POST', virtualClinicsClinicSpecialitiesRoute(clinicId));
export const showVirtualClinicSpecialityDetailsEndpoint = (clinicId, clinicSpecialityId) => endpoint(types.SHOW_CLINIC_SPECIALITY_DETAILS, 'GET', virtualClinicsClinicSpecialityRoute(clinicId, clinicSpecialityId));
export const updateVirtualClinicSpecialityEndpoint = (clinicId, clinicSpecialityId) => endpoint(types.UPDATE_VIRTUAL_CLINIC_SPECIALITY, 'PUT', virtualClinicsClinicSpecialityRoute(clinicId, clinicSpecialityId));
export const virtualClinicSpecialitiesProvidersEndpoint = clinicSpecialityId => endpoint(types.SUBMIT_CLINIC_SPECIALITY_PROVIDERS, 'PUT', virtualClinicsClinicSpecialitiesProvidersRoute(clinicSpecialityId));
export const activateVirtualClinicSpecialitiesEndpoint = clinicId => endpoint(types.ACTIVATE_CLINIC_SPECIALITIES, 'PUT', virtualClinicsClinicSpecialitiesBulkActionsActivateRoute(clinicId));
export const deactivateVirtualClinicSpecialitiesEndpoint = clinicId => endpoint(types.DEACTIVATE_CLINIC_SPECIALITIES, 'PUT', virtualClinicsClinicSpecialitiesBulkActionsDeactivateRoute(clinicId));
export const removeVirtualClinicSpecialitiesEndpoint = clinicId => endpoint(types.REMOVE_CLINIC_SPECIALITIES, 'DELETE', virtualClinicsClinicSpecialitiesBulkActionsRemoveRoute(clinicId));
