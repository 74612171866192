import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-number-input';

import { handleInputEvent } from 'utils/inputHelpers';
import formatPhoneNumber from 'utils/formatPhoneNumber';

import InputMessage from '../InputMessage';

const PhoneInputField = ({
  id,
  disabled,
  label,
  icon,
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="input-phone">
    {label && (
      <div className="select__label">
        <FormattedMessage {...label} />
      </div>
    )}
    <PhoneInput
      value={formatPhoneNumber(field.value)}
      onChange={handleInputEvent(field, field.onChange)}
      onBlur={handleInputEvent(field, field.onBlur)}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <InputMessage
        message={errors[field.name]}
        icon={icon}
      />
    )}
  </div>
  );

PhoneInputField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
};

PhoneInputField.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
};

export default PhoneInputField;
