export const MAX_UNREAD_NOTIFICATIONS_COUNT = 99;
export const REMOVE_NOTIFICATION_TIMEOUT = 5000;

export const NOTIFICATION_PANEL = {
  close: 'closed',
  open: 'opened',
};

export const NOTIFICATION_TYPES = {
  appointment: 'appointment',
  reminder: 'reminder',
  communication: 'communication',
};

export const NOTIFICATION_THEMES = {
  appointment: {
    new_appointment_for_doctor: 'newAppointmentForDoctor',
    patient_cancel_appointment_for_doctor: 'patientCancelAppointmentForDoctor',
    patient_cancel_appointment_without_refund: 'patientCancelAppointmentWithoutRefund',
    doctor_missed_appointment: 'doctorMissedAppointment',
    doctor_cancelled_appointment: 'doctorCancelledAppointment',
    cancelled_appointment_due_to_patient_ban: 'cancelledAppointmentDueToPatientBan',
    completed_appointment_due_to_patient_ban: 'completedAppointmentDueToPatientBan',
    patient_rescheduled_appointment: 'patientRescheduledAppointment',
    create_note_after_appointment: 'createNoteAfterAppointment',
    doctor_failed_to_add_note: 'doctorFailedToAddNote',
  },
  reminder: {
    reminder_for_doctor: 'reminderForDoctor',
  },
  communication: {
    patient_create_message: 'patientCreateMessage',
    patient_assign_attachment: 'patientAssignAttachment',
  },
  system: {
    speciality_assigned: 'specialityAssigned',
    speciality_unassigned: 'specialityUnassigned',
    speciality_removed: 'specialityRemoved',
    clinic_speciality_removed: 'clinicSpecialityRemoved',
    clinic_speciality_deactivated: 'clinicSpecialityDeactivated',
    clinic_speciality_activated: 'clinicSpecialityActivated',
    speciality_deactivated_platform: 'specialityDeactivatedPlatform',
    speciality_activated_platform: 'specialityActivatedPlatform',
    speciality_added_platform: 'specialityAddedPlatform',
    speciality_deleted_platform: 'specialityDeletedPlatform',
  },
};
