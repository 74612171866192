import { createLogic } from 'redux-logic';
import { pathOr } from 'ramda';

import { MAX_MESSAGE_SIZE } from 'constants/chat';

import requestErrorHandler from 'lib/requestErrorHandler';

import formatJsonApiErrors from 'utils/formatJsonApiErrors';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { updateChatMessageEndpoint } from '../endpoints';
import { UPDATE_MESSAGE } from '../types';

const updateMessageOperation = createLogic({
  type: UPDATE_MESSAGE,
  latest: true,

  async process({ httpClient, action: { messageId, text } }, dispatch, done) {
    const { url, endpoint } = updateChatMessageEndpoint(messageId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url, { text });

      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      const errors = pathOr(null, ['response', 'data', 'errors'], error);
      const formattedErrors = formatJsonApiErrors(errors);

      if (formattedErrors.text) {
        dispatch(showNotification({
          messageObject: { id: 'yup.string.max', values: { max: MAX_MESSAGE_SIZE } },
          kind: 'error',
        }));
      }
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default updateMessageOperation;
