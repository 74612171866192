const namespace = 'revenue';

export const GET_REVENUE = `${namespace}/GET_REVENUE`;
export const FETCH_REVENUE = `${namespace}/FETCH_REVENUE`;
export const FETCH_REVENUE_VIRTUAL_CLINICS = `${namespace}/FETCH_REVENUE_VIRTUAL_CLINICS`;
export const SET_REVENUE_FILTERS = `${namespace}/SET_REVENUE_FILTERS`;
export const SET_REVENUE_INITIAL_STATE = `${namespace}/SET_REVENUE_INITIAL_STATE`;
export const RESET_REVENUE = `${namespace}/RESET_REVENUE`;
export const FETCH_REVENUE_EXTRA_STATISTIC = `${namespace}/FETCH_REVENUE_EXTRA_STATISTIC`;
export const SET_REVENUE_EXTRA_STATISTIC_ID = `${namespace}/SET_REVENUE_EXTRA_STATISTIC_ID`;
export const SET_REVENUE_APPOINTMENT_IDS = `${namespace}/SET_REVENUE_APPOINTMENT_IDS`;
export const SET_REVENUE_VIRTUAL_CLINIC_IDS = `${namespace}/SET_REVENUE_VIRTUAL_CLINIC_IDS`;
export const SET_REVENUE_VIRTUAL_CLINIC_ID = `${namespace}/SET_REVENUE_VIRTUAL_CLINIC_ID`;
export const SET_REVENUE_TYPE = `${namespace}/SET_REVENUE_TYPE`;
export const SET_REVENUE_BLANK_STATE = `${namespace}/SET_REVENUE_BLANK_STATE`;
export const SET_REVENUE_PAGE = `${namespace}/SET_REVENUE_PAGE`;
export const SET_REVENUE_FILTER_PARAMS = `${namespace}/SET_REVENUE_FILTER_PARAMS`;
export const RESET_REVENUE_FILTER_PARAMS = `${namespace}/RESET_REVENUE_FILTER_PARAMS`;
export const SET_REVENUE_SORT_ORDER = `${namespace}/SET_REVENUE_SORT_ORDER`;
export const FETCH_REVENUE_DOCTORS_LIST = `${namespace}/FETCH_REVENUE_DOCTORS_LIST`;
export const SET_REVENUE_DOCTORS_LIST_FILTERS = `${namespace}/SET_REVENUE_DOCTORS_LIST_FILTERS`;
export const RESET_REVENUE_DOCTORS_LIST = `${namespace}/RESET_REVENUE_DOCTORS_LIST`;
export const SET_REVENUE_VIRTUAL_CLINIC_DOCTORS_FILTERS = `${namespace}/SET_REVENUE_VIRTUAL_CLINIC_DOCTORS_FILTERS`;
export const GET_DOWNLOAD_REVENUE = `${namespace}/GET_DOWNLOAD_REVENUE`;
export const DOWNLOAD_REVENUE = `${namespace}/DOWNLOAD_REVENUE`;
export const DOWNLOAD_REVENUE_DOCTORS = `${namespace}/DOWNLOAD_REVENUE_DOCTORS`;
export const DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS = `${namespace}/DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS`;
export const DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS = `${namespace}/DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS`;
export const FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS = `${namespace}/FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS`;
export const SET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS = `${namespace}/SET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS`;
export const RESET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS = `${namespace}/RESET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS`;
