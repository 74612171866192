import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import { isPlatformOwner } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { setAppointments, setBlankState } from '../actions';
import { filtersSelector, selectedTimezoneSelector, datesFilterSelector } from '../selectors';
import { fetchAppointmentsEndpoint } from '../endpoints';
import { FETCH_APPOINTMENTS } from '../types';

const fetchAppointmentsOperation = createLogic({
  type: FETCH_APPOINTMENTS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchAppointmentsEndpoint;
    const { startDate, endDate } = datesFilterSelector(state);
    const currentUser = currentUserSelector(state);
    const timezone = selectedTimezoneSelector(state);
    const {
      statuses,
      patients,
      specialities,
      symptoms,
      doctors,
      name,
    } = filtersSelector(state);

    const specialityIds = isPlatformOwner(currentUser.roleName)
      ? { speciality_ids: specialities }
      : { clinic_speciality_ids: specialities };

    const params = {
      include: 'clinic_speciality,patient_profile,child_profile,user_profile,speciality,symptom,transactions',
      filter: {
        ...specialityIds,
        start_date: startDate.toISODate(),
        end_date: endDate.toISODate(),
        timezone,
        user_profile_ids: doctors,
        patient_profile_ids: patients,
        symptom_ids: symptoms,
        statuses,
        speciality_or_symptom_name: name,
      },
      page: { size: 100, number: 1 },
      sort: 'start_time',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });

      dispatch(setBlankState(isBlankState(data.data)));
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(setAppointments(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchAppointmentsOperation;
