import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DropdownNotFoundContent = ({
  isLoading,
  isAllOptionsSelected,
  description,
}) => isLoading === false && (
  <span className="multiselect-field__empty">
    <span className="multiselect-field__empty-text">
      <FormattedMessage
        id={isAllOptionsSelected ? description : 'shared.noResults'}
      />
    </span>
  </span>
);

DropdownNotFoundContent.defaultProps = {
  isLoading: undefined,
};

DropdownNotFoundContent.propTypes = {
  isLoading: PropTypes.bool,
  isAllOptionsSelected: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
};

export default DropdownNotFoundContent;
