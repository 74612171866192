import { combineReducers } from 'redux';

import * as types from './types';

const currentUserId = (state = null, action) => {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      return action.currentUserId;
    default:
      return state;
  }
};

const currentUserRole = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER_ROLE:
      return action.currentUserRole;
    default:
      return state;
  }
};

const visibleAuthFlow = (state = null, action) => {
  switch (action.type) {
    case types.SET_VISIBLE_AUTH_FLOW:
      return action.flow;
    default:
      return state;
  }
};

const userType = (state = null, action) => {
  switch (action.type) {
    case types.SET_USER_TYPE:
      return action.userType;
    default:
      return state;
  }
};

export default combineReducers({
  currentUserId,
  currentUserRole,
  visibleAuthFlow,
  userType,
});
