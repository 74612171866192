import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import redirect from 'utils/redirect';
import hasScroll from 'utils/hasScroll';
import { isDoctor } from 'utils/roles';
import { isScheduled, isRescheduled } from 'utils/appointments';

import { currentUserSelector } from 'state/concepts/session/selectors';

import AppointmentCalendarTooltipContentComponent from './component';

class AppointmentCalendarTooltipContent extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    currentUser: PropTypes.shape().isRequired,
  };

  scrollContainerRef = React.createRef();

  state = {
    hasScroll: false,
  }

  componentDidMount() {
    this.setState({
      hasScroll: hasScroll(this.scrollContainerRef.current),
    });
  }

  handleViewMeetingPage = () => {
    const { onClose, event } = this.props;

    onClose();
    redirect({
      href: '/appointments/[appointmentId]',
      as: `/appointments/${event.id}`,
    });
  }

  get canCancel() {
    const { currentUser, event } = this.props;

    return isDoctor(currentUser.roleName) && (
      isScheduled(event) || isRescheduled(event)
    );
  }

  render = () => (
    <AppointmentCalendarTooltipContentComponent
      {...this.props}
      {...this.state}
      ref={this.scrollContainerRef}
      onViewMeetingPage={this.handleViewMeetingPage}
      canCancel={this.canCancel}
    />
  )
}

const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
});

export { AppointmentCalendarTooltipContent as AppointmentCalendarTooltipContentContainer };
export default connect(mapStateToProps)(AppointmentCalendarTooltipContent);
