import { createSelector } from 'reselect';
import build from 'redux-object';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, pageCountSelector } from 'state/data/selectors';
import {
  fetchPatientEndpoint,
  fetchPatientAppointmentsEndpoint,
  fetchPatientNotesEndpoint,
  activatePatientEndpoint,
  banPatientEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const patientIdSelector = path(['patient', 'patientId']);
export const patientAppointmentsIdsSelector = path(['patient', 'patientAppointmentsIds']);
export const patientNotesIdsSelector = path(['patient', 'patientNotesIds']);
export const statusesSelector = path(['patient', 'statuses']);
export const sortSelector = (state, key) => path(['patient', key])(state);
export const filtersSelector = path(['patient', 'filters']);
export const filtersNotesSelector = path(['patient', 'filtersNotes']);
export const paginationSelector = path(['patient', 'pagination']);
export const isBlankStateSelector = path(['patient', 'isBlankState']);
export const patientChildrenIdsSelector = path(['patient', 'childrenIds']);
export const patientDetailsActiveTabSelector = path(['patient', 'activeTab']);

export const patientSelector = createSelector(
  (_, patientId) => patientId,
  dataSelector,
  (patientId, data) => build(data, 'patientProfile', patientId),
);

export const patientAppointmentsSelector = createSelector(
  patientAppointmentsIdsSelector,
  dataSelector,
  buildCollection('appointment'),
);

export const patientNotesSelector = createSelector(
  patientNotesIdsSelector,
  dataSelector,
  buildCollection('note'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const patientAppointmentsPageCountSelector = (state, id) => (
  pageCountSelector(state, fetchPatientAppointmentsEndpoint(id).endpoint)
);

export const patientNotesPageCountSelector = (state, id) => (
  pageCountSelector(state, fetchPatientNotesEndpoint(id).endpoint)
);

export const patientLoadingSelector = (state, id) => (
  loadingSelector(state, fetchPatientEndpoint(id).endpoint)
);

export const patientAppointmentsLoadingSelector = (state, id) => (
  loadingSelector(state, fetchPatientAppointmentsEndpoint(id).endpoint)
);

export const patientNotesLoadingSelector = (state, id) => (
  loadingSelector(state, fetchPatientNotesEndpoint(id).endpoint)
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const searchQuerySelector = createSelector(
  filtersSelector,
  prop('name'),
);

export const activatePatientLoadingSelector = (state, id) => (
  loadingSelector(state, activatePatientEndpoint(id).endpoint)
);

export const banPatientLoadingSelector = (state, id) => (
  loadingSelector(state, banPatientEndpoint(id).endpoint)
);
