import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';
import { isEmpty } from 'ramda';

import { handleInputEvent } from 'utils/inputHelpers';

import SelectFieldComponent from './component';

class SelectField extends React.Component {
  static propTypes = {
    mountToElement: PropTypes.bool,
    mode: PropTypes.string,
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape(),
      ]),
    }).isRequired,
    form: PropTypes.shape({
      setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
  }

  static defaultProps = {
    mountToElement: false,
    mode: 'default',
    options: undefined,
  }

  get value() {
    const { options, mode, field: { value } } = this.props;
    return !isEmpty(options) || mode === 'multiple' ? value : undefined;
  }

  selectRef = React.createRef();

  getPopupContainer = node => (
    this.props.mountToElement ? node : document.body
  );

  dropdownRender = (node, { visible }) => (
    this.props.mountToElement ? node : (
      <RemoveScroll enabled={visible}>
        {node}
      </RemoveScroll>
    )
  );

  handleChange = (event) => {
    const { field, mode, form: { setFieldTouched } } = this.props;

    if (mode === 'default') {
      this.selectRef.current.blur();
    }

    if (mode === 'multiple') {
      setFieldTouched(field.name, true);
    }

    handleInputEvent(field, field.onChange)(event);
  };

  render = () => (
    <SelectFieldComponent
      {...this.props}
      ref={this.selectRef}
      getPopupContainer={this.getPopupContainer}
      dropdownRender={this.dropdownRender}
      onChange={this.handleChange}
      value={this.value}
    />
  );
}

export { SelectField as SelectFieldContainer };
export default injectIntl(SelectField);
