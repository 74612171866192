import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const validateInvitationToken = token => ({
  type: types.VALIDATE_INVITATION_TOKEN,
  token,
});

export const setUserInvitationId = id => ({
  type: types.SET_USER_INVITATION_ID,
  id,
});

export const submitVerificationCode = makeFormSubmitAction(types.SUBMIT_TWO_FACTOR_AUTH);
export const signupFromInvitation = makeFormSubmitAction(types.SIGNUP_FROM_INVITATION);

