import React from 'react';
import PropTypes from 'prop-types';

import SignatureModalComponent from './component';

// eslint-disable-next-line no-unused-expressions
import('canvas-toBlob');

class SignatureModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    uploadAction: PropTypes.func.isRequired,
  }

  state = {
    hasSignature: false,
  };

  signatureRef = React.createRef();

  get signature() {
    return this.signatureRef.current;
  }

  handleSignatureUndo = () => {
    const signatureData = this.signature.toData();

    if (!this.signature.isEmpty()) {
      signatureData.pop();

      this.signature.fromData(signatureData);
      this.setState({ hasSignature: !this.signature.isEmpty() });
    }
  }

  handleSignatureClear = () => {
    this.signature.clear();
    this.setState({ hasSignature: false });
  }

  getSignature = () => {
    const canvas = this.signature.getCanvas();

    return new Promise((resolve) => canvas.toBlob(resolve));
  };

  handleSignatureSave = async () => {
    const { onClose, uploadAction } = this.props;

    const signatureUrl = await this.getSignature();

    uploadAction(signatureUrl);
    onClose();
  }

  handleClick = () => {
    this.setState({ hasSignature: true });
  }

  render = () => (
    <SignatureModalComponent
      {...this.props}
      {...this.state}
      signatureRef={this.signatureRef}
      onClick={this.handleClick}
      onSignatureUndo={this.handleSignatureUndo}
      onSignatureClear={this.handleSignatureClear}
      onSignatureSave={this.handleSignatureSave}
    />
  );
}

export default SignatureModal;
