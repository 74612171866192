import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { path, prop } from 'ramda';

import profileName from 'utils/profileName';
import { formattedShortDate } from 'utils/dateTime';
import { isPlatformOwner } from 'utils/roles';

import Avatar from 'views/shared/Avatar';
import SvgIcon from 'views/shared/SvgIcon';

const DoctorComponent = ({
  note,
  onRedirect,
  currentUser,
}) => (
  <div className="modal-view-note__col">
    <div className="modal-view-note-doctor">
      <div className="modal-view-note-doctor__title">
        <FormattedMessage id="note.modal.doctor" />
      </div>
      <div className="modal-view-note-doctor__profile">
        <Avatar
          avatarClassName="modal-view-note-doctor__img"
          imageSize="small"
          user={path(['appointment', 'userProfile'], note)}
        />
        <div className="modal-view-note-doctor__content">
          {isPlatformOwner(currentUser.roleName) ? (
            <button className="modal-view-note-doctor__name" onClick={onRedirect}>
              {profileName(path(['appointment', 'userProfile'], note))}
            </button>
          ) : (
            <div className="modal-view-note-doctor__name">
              {profileName(path(['appointment', 'userProfile'], note))}
            </div>
          )}
          <div className="modal-view-note-doctor__speciality">
            {path(['appointment', 'speciality', 'name'], note)}
          </div>
        </div>
      </div>
    </div>
    <div className="modal-view-note__date">
      <SvgIcon icon="calendar-o" className="modal-view-note__date-icon" />
      <div className="modal-view-note__date-txt">
        {formattedShortDate(prop('createdAt', note))}
      </div>
    </div>
  </div>
);

DoctorComponent.propTypes = {
  note: PropTypes.shape().isRequired,
  onRedirect: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({ roleName: PropTypes.string.isRequired }).isRequired,
};

export default DoctorComponent;
