import React from 'react';

import AccessErrorComponent from './component';

class AccessErrorContainer extends React.Component {
  static pageTitle = { id: 'accessErrorPage.title' };

  render = () => (
    <AccessErrorComponent {...this.props} />
  )
}

export default AccessErrorContainer;
