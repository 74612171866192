import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { prop, path } from 'ramda';

import profileName from 'utils/profileName';

const SickNoteComponent = ({
  note,
}) => (
  <div className="view-note-table">
    <div className="view-note-table__header">
      <div className="view-note-table__col">
        <FormattedMessage id="note.modal.sickNote" />
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patient" />
        </span>
        <span className="view-note-table__col-value">
          {profileName(path(['appointment', 'childProfile'], note) || prop('patientProfile', note))}
        </span>
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patientCitizenId" />
        </span>
        <span className="view-note-table__col-value">
          {prop('patientCitizenId', note)}
        </span>
      </div>
    </div>
    <div className="view-note-table__content">
      {prop('sickNote', note)}
    </div>
  </div>
);

SickNoteComponent.propTypes = {
  note: PropTypes.shape().isRequired,
};

export default SickNoteComponent;
