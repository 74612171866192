import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { getNoteEndpoint } from '../endpoints';
import { GET_NOTE } from '../types';

const getNoteOperation = createLogic({
  type: GET_NOTE,
  latest: true,

  async process({ httpClient, action: { noteId } }, dispatch, done) {
    const { endpoint, url } = getNoteEndpoint(noteId);

    const params = {
      include:
        'patient-profile,appointment,appointment.speciality,appointment.userProfile,appointment.child-profile,prescriptions,diseases,primary_disease,auxiliary_studies',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });

      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getNoteOperation;
