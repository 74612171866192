import { createLogic } from 'redux-logic';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';
import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import {
  activateClinicSpecialitiesEndpoint,
  activateVirtualClinicSpecialitiesEndpoint,
} from '../endpoints';
import { ACTIVATE_CLINIC_SPECIALITIES } from '../types';

const activateClinicSpecialitiesOperation = createLogic({
  type: ACTIVATE_CLINIC_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState, action: { clinicSpecialitiesIds } }, dispatch, done) {
    const state = getState();
    const { roleName, clinic } = currentUserSelector(state);
    const { url, endpoint } = isVirtualClinicAdmin(roleName)
      ? activateVirtualClinicSpecialitiesEndpoint(clinic.id)
      : activateClinicSpecialitiesEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, { clinic_speciality_ids: clinicSpecialitiesIds });

      const response = updateDataHelper(
        state.data, 'clinicSpeciality', clinicSpecialitiesIds,
        { attributes: { status: CLINIC_SPECIALITIES_STATUSES.active } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.clinicSpecialitiesActivated',
            values: { count: clinicSpecialitiesIds.length },
          },
        }),
      );
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default activateClinicSpecialitiesOperation;
