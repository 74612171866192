import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import {
  MIN_EDIT_FREE_VISITS_COUNT,
  MAX_FREE_VISITS_COUNT,
  FREE_VISITS_STEP,
} from 'constants/freeVisits';

import { isPlatformOwner, isPlatformOwnerOrAdmin } from 'utils/roles';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';
import InputField from 'views/shared/InputField';
import NumericField from 'views/shared/NumericField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';

const EditExtraFreeVisitsModalComponent = ({
  currentUser,
  isPatientProfile,
  isLoading,
  dirty,
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <header className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="patients.freeVisits.editFreeVisits" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </header>
    <div className="ant-modal__body">
      {isPlatformOwner(currentUser.roleName) && (
        <Field
          id="clinicName"
          name="clinicName"
          component={InputField}
          label={{ id: 'label.clinic' }}
          placeholder={{ id: 'placeholder.addClinic' }}
          className="mb-16"
          fullWidth
          disabled
        />
      )}
      {(isPlatformOwnerOrAdmin(currentUser.roleName) && !isPatientProfile) && (
        <Field
          id="patientName"
          name="patientName"
          component={InputField}
          label={{ id: 'label.patients' }}
          placeholder={{ id: 'placeholder.addPatients' }}
          className="mb-16"
          fullWidth
          disabled
        />
      )}
      <Field
        id="freeVisitsCount"
        name="freeVisitsCount"
        component={NumericField}
        label={{ id: 'label.freeVisits' }}
        placeholder="0"
        minValue={MIN_EDIT_FREE_VISITS_COUNT}
        min={MIN_EDIT_FREE_VISITS_COUNT}
        max={MAX_FREE_VISITS_COUNT}
        step={FREE_VISITS_STEP}
        icon="alert"
        isInteger
        fullWidth
      />
    </div>
    <footer className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.edit' }}
        overrideKind={[null, 'primary']}
        onClick={handleSubmit}
        disabled={isLoading || !dirty}
        isLoading={isLoading}
      />
    </footer>
  </Modal>
);

EditExtraFreeVisitsModalComponent.defaultProps = {
  isLoading: undefined,
  isPatientProfile: false,
};

EditExtraFreeVisitsModalComponent.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  isPatientProfile: PropTypes.bool,
  isLoading: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditExtraFreeVisitsModalComponent;
