import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from 'state/modal/actions';

import FileUploadErrorModalComponent from './component';

class FileUploadErrorModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
  }

  fileInput = React.createRef();

  handleSubmit = () => {
    this.fileInput.current.click();
  }

  handleUpload = (event) => {
    const { onClose, onUpload } = this.props;

    onClose();
    onUpload(event);
    this.fileInput.current.value = '';
  }

  render = () => (
    <FileUploadErrorModalComponent
      {...this.props}
      ref={this.fileInput}
      onSubmit={this.handleSubmit}
      onUpload={this.handleUpload}
    />
  )
}

const mapDispatchToProps = {
  onClose: hideModal,
};

export { FileUploadErrorModal as FileUploadErrorModalContainer };
export default connect(null, mapDispatchToProps)(FileUploadErrorModal);
