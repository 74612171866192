import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'ramda';

import AttachedFile from 'views/shared/AttachedFile';
import SvgIcon from 'views/shared/SvgIcon';

import InputMessage from '../InputMessage';

const CVFieldComponent = ({
  field: { name, value },
  form: { touched, errors },
  icon,
  accept,
  fileSize,
  className,
  onChange,
  onClearCV,
}) => (
  <div className={className}>
    {isEmpty(value) ? (
      <label className="file-uploader file-uploader__label file-uploader--start">
        <input
          id={name}
          name={name}
          type="file"
          value={value}
          onChange={onChange}
          accept={accept}
          className="file-uploader__input"
        />
        <SvgIcon icon="attachment" className="file-uploader__icon" />
        <FormattedMessage id="label.cv" />
      </label>
    ) : (
      <div className="file-uploader">
        <AttachedFile fileName={value.name} fileSize={fileSize} />
        <button className="ml-16" type="button" onClick={onClearCV}>
          <SvgIcon icon="cross" className="file-uploader__close" />
        </button>
      </div>
    )}
    {touched[name] && errors[name] && (
      <InputMessage
        message={errors[name]}
        icon={icon}
      />
    )}
  </div>
);

CVFieldComponent.defaultProps = {
  icon: null,
  accept: null,
  fileSize: null,
  className: undefined,
};

CVFieldComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string,
  accept: PropTypes.string,
  fileSize: PropTypes.string,
  onClearCV: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CVFieldComponent;
