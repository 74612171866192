import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { updateVouchersBatchDetailsEndpoint } from '../endpoints';
import { UPDATE_VOUCHERS_BATCH_DETAILS } from '../types';

const updateVouchersBatchDetailsOperation = createLogic({
  type: UPDATE_VOUCHERS_BATCH_DETAILS,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const state = getState();
    const id = values.batchId;
    const { url, endpoint } = updateVouchersBatchDetailsEndpoint(id);

    const params = {
      ...values,
    };

    try {
      await httpClient.put(url, params);

      const attributes = {
        ...values,
      };

      const response = updateDataHelper(
        state.data, 'voucherBatch', id,
        { attributes },
      );

      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.batchUpdated',
          values: { count: values.number },
        },
      }));

      dispatch(dataApiSuccess({ response }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateVouchersBatchDetailsOperation;
