import {
  pluck, reduce, indexOf, filter, isEmpty,
} from 'ramda';
import debounce from 'debounce-promise';

import isEmail from 'utils/isEmail';

export default debounce(async ({ invitations }, { getHttpClient, url }) => {
  const emails = pluck('email', invitations);
  const validEmails = filter(isEmail, emails);

  if (isEmpty(validEmails)) { return {}; }

  const httpClient = getHttpClient();

  try {
    const { data: { data } } = await httpClient.get(url, { params: { emails: validEmails } });

    const errors = reduce((acc, { attributes: { email, status } }) => {
      if (status !== 'not_found') {
        acc[indexOf(email, emails)] = { email: { id: 'independentDoctors.doctors.invitations.errors.alreadyMember' } };
      }
      return acc;
    }, [], data);

    if (isEmpty(errors)) { return {}; }

    return Promise.resolve({ invitations: errors });
  } catch {
    return {};
  }
}, 400);
