import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { getUserCommissionSettingsEndpoint } from '../endpoints';
import { GET_USER_COMMISSION_SETTINGS } from '../types';

const getUserCommissionSettingsOperation = createLogic({
  type: GET_USER_COMMISSION_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getUserCommissionSettingsEndpoint;

    const params = {
      include: 'current_clinic_commission,clinic.current_platform_commission',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getUserCommissionSettingsOperation;
