import { capitalize } from 'lodash';
import { isNil, toLower } from 'ramda';

import { SYMPTOM_STATUS, SYMPTOM_NAMES } from 'constants/symptoms';

export default ({ status, name }) => (
  status !== SYMPTOM_STATUS.fictional ? capitalize(name) : 'N/A'
);

export const symptomNameInMessage = ({ symptomName }) => (
  !isNil(symptomName) && toLower(symptomName) !== SYMPTOM_NAMES.asymptomatic ? capitalize(symptomName) : 'N/A'
);
