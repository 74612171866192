import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { FETCH_NOTIFICATION_SETTINGS } from 'state/concepts/userProfile/notifications/types';

import { fetchNotificationSettingEndpoint } from '../endpoints';

const fetchNotificationSettings = createLogic({
  type: FETCH_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ httpClient, action: { token } }, dispatch, done) {
    const { endpoint, url } = fetchNotificationSettingEndpoint;

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params: { token } });

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }
    done();
  },
});

export default fetchNotificationSettings;
