import endpoint from 'utils/endpoint';

import { transactionsRoute } from 'lib/apiRoutes';

import {
  FETCH_TRANSACTIONS,
  CREATE_TRANSACTION,
} from './types';

export const fetchTransactionsEndpoint = endpoint(FETCH_TRANSACTIONS, 'GET', transactionsRoute);
export const createTransactionEndpoint = endpoint(CREATE_TRANSACTION, 'POST', transactionsRoute);
