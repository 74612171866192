import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';
import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';

import { resendDoctorInvitationEndpoint, resendVirtualDoctorInvitationEndpoint } from '../endpoints';
import { RESEND_DOCTOR_INVITATION } from '../types';

const resendDoctorInvitationOperation = createLogic({
  type: RESEND_DOCTOR_INVITATION,
  latest: true,

  async process({ httpClient, action: { id }, getState }, dispatch, done) {
    const { roleName } = currentUserSelector(getState());
    const { endpoint, url } = isVirtualClinicAdmin(roleName)
    ? resendVirtualDoctorInvitationEndpoint(id)
    : resendDoctorInvitationEndpoint(id);
    const params = {
      redirect_to: '/invitation_signup',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.post(url, params);

      dispatch(showNotification({ messageObject: { id: 'notifications.doctorsInvitationResent' } }));
      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorsInvitationResentError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default resendDoctorInvitationOperation;
