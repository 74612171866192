import { createLogic } from 'redux-logic';

import { SPECIALITIES_STATUSES } from 'constants/specialities';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { updateSpecialityEndpoint } from '../endpoints';
import { UPDATE_SPECIALITY } from '../types';

const updateSpecialityOperation = createLogic({
  type: UPDATE_SPECIALITY,
  latest: true,

  async process({ httpClient, getState, action: { form, values, specialityId } },
    dispatch, done) {
    const { url, endpoint } = updateSpecialityEndpoint(specialityId);
    const state = getState();
    const params = {
      ...values,
      status: values.status ? SPECIALITIES_STATUSES.active : SPECIALITIES_STATUSES.inactive,
      children: values.children,
    };

    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'speciality', specialityId,
        {
          attributes: {
            name: params.name,
            status: params.status,
            children: params.children,
          },
        },
      );

      dispatch(dataApiSuccess({ response }));
      dispatch(showNotification({ messageObject: { id: 'notifications.specialityUpdated' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateSpecialityOperation;
