import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserIdSelector, currentUserRoleSelector } from 'state/concepts/session/selectors';
import {
  showClinicSpecialityDetailsEndpoint,
  showVirtualClinicSpecialityDetailsEndpoint,
} from '../endpoints';
import { SHOW_CLINIC_SPECIALITY_DETAILS } from '../types';

const showClinicSpecialityDetailsOperation = createLogic({
  type: SHOW_CLINIC_SPECIALITY_DETAILS,
  latest: true,

  async process({ httpClient, getState, action: { clinicSpecialityId } }, dispatch, done) {
    const state = getState();
    const currentUserId = currentUserIdSelector(state);
    const currentUserRole = currentUserRoleSelector(state);
    const { url, endpoint } = isVirtualClinicAdmin(currentUserRole)
      ? showVirtualClinicSpecialityDetailsEndpoint(currentUserId, clinicSpecialityId)
      : showClinicSpecialityDetailsEndpoint(clinicSpecialityId);

    const params = {
      include: 'speciality,user-profiles-clinic-specialities,user-profiles-clinic-specialities.user-profile,symptoms',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default showClinicSpecialityDetailsOperation;
