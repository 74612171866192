import { combineReducers } from 'redux';

import {
  SET_PATIENTS,
  SET_PATIENTS_PAGE,
  SET_PATIENTS_SORT_ORDER,
  SET_PATIENTS_BLANK_STATE,
} from './types';

const patientIds = (state = [], action) => {
  switch (action.type) {
    case SET_PATIENTS:
      return action.patientIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_PATIENTS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_PATIENTS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'patient_profiles.first_name', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_PATIENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  patientIds,
  isBlankState,
  pagination,
  sort,
});
