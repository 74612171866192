import {
  pipe, map, forEach, replace, split, assocPath, mergeDeepWith, is, isNil,
} from 'ramda';
import { camelCase, capitalize } from 'lodash';

const formatErrorObject = ({ source: { pointer }, detail = '' }, disableCapitalize = false) => {
  const attributes = pipe(
    replace('/data/attributes/', ''),
    split('/'),
    map(camelCase),
  )(pointer);
  const capitalizedDetail = detail.split('.').map(
    (item) => (disableCapitalize ? item.trim() : capitalize(item.trim())),
  ).join('. ');

  return assocPath(attributes, capitalizedDetail, {});
};

const formatJsonApiErrors = (errors, disableCapitalize) => {
  if (isNil(errors)) {
    return { base: 'Unknown error' };
  }

  let result = {};

  forEach((error) => {
    const formattedError = formatErrorObject(error, disableCapitalize);
    result = mergeDeepWith((left, right) => (is(String)(left) ? `${left}. ${right}` : left), result, formattedError);
  }, errors);

  return result;
};

export default formatJsonApiErrors;
