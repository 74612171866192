import { isNil, equals } from 'ramda';

import { voucherCodeLength } from 'utils/vouchers';

// eslint-disable-next-line import/prefer-default-export
export function voucherCode(length) {
  return this.test(
    'voucherCode',
    { id: 'yup.string.voucherCode', values: { length } },
    (value) => isNil(value) || equals(voucherCodeLength(value), length),
  );
}
