import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import { USER_TYPE } from 'constants/users';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess, setUserType } from 'state/concepts/session/actions';
import { fetchPatientsAccountProfileEndpoint } from '../endpoints';
import { FETCH_PATIENT_ACCOUNT_PROFILE } from '../types';

const fetchPatientAccountProfileOperation = createLogic({
  type: FETCH_PATIENT_ACCOUNT_PROFILE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchPatientsAccountProfileEndpoint;
    const params = {
      include: 'health-profile,health-profile.allergies,child_profiles,patient_balance',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(userLoginSuccess(data.data.id));
      dispatch(setUserType(USER_TYPE.patient));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPatientAccountProfileOperation;
