import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { removeMessageSuccess } from '../actions';
import { removeChatMessageEndpoint } from '../endpoints';
import { REMOVE_MESSAGE } from '../types';

const removeMessageOperation = createLogic({
  type: REMOVE_MESSAGE,
  latest: true,

  async process({ httpClient, action: { messageId } }, dispatch, done) {
    const { url, endpoint } = removeChatMessageEndpoint(messageId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(removeMessageSuccess(messageId));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeMessageOperation;
