import * as yup from 'yup';

import yupLocale from 'locales/yup';

import { isValidFileFormat, isValidFileSize } from './validations/file';
import {
  isDurationString,
  durationMin,
  durationMax,
  durationInUnitMin,
  durationInUnitMax,
  secondsPerTimeUnitMin,
  secondsPerTimeUnitMax,
} from './validations/duration';
import { isDiseasePresent } from './validations/note';
import { currencyFromTo } from './validations/currencyFromTo';
import { voucherCode } from './validations/voucherCode';
import { isValidPercent } from './validations/percent';
import { maxCommission } from './validations/maxCommission';
import { maxAgeTo } from './validations/maxAgeTo';
import { licenseNumber } from './validations/licenseNumber';
import { isValidEmail } from './validations/isValidEmail';

yup.setLocale(yupLocale);

yup.addMethod(yup.mixed, 'isValidFileFormat', isValidFileFormat);
yup.addMethod(yup.mixed, 'isValidFileSize', isValidFileSize);
yup.addMethod(yup.string, 'isDurationString', isDurationString);
yup.addMethod(yup.string, 'durationMin', durationMin);
yup.addMethod(yup.string, 'durationMax', durationMax);
yup.addMethod(yup.number, 'durationInUnitMin', durationInUnitMin);
yup.addMethod(yup.number, 'durationInUnitMax', durationInUnitMax);
yup.addMethod(yup.string, 'secondsPerTimeUnitMin', secondsPerTimeUnitMin);
yup.addMethod(yup.string, 'secondsPerTimeUnitMax', secondsPerTimeUnitMax);
yup.addMethod(yup.object, 'isDiseasePresent', isDiseasePresent);
yup.addMethod(yup.string, 'currencyFromTo', currencyFromTo);
yup.addMethod(yup.string, 'voucherCode', voucherCode);
yup.addMethod(yup.number, 'isValidPercent', isValidPercent);
yup.addMethod(yup.number, 'maxCommission', maxCommission);
yup.addMethod(yup.number, 'maxAgeTo', maxAgeTo);
yup.addMethod(yup.string, 'licenseNumber', licenseNumber);
yup.addMethod(yup.string, 'isValidEmail', isValidEmail);

export default yup;
