import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { allowedImageTypes } from 'constants/file';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const UploadImageErrorModal = ({ onClose, onSelectImage }) => (
  <Modal className="main-modal" wrapClassName="ant-modal-wrap__4" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="uploadImage.uploadError" />
      </h1>
      <button
        type="button"
        onClick={onClose}
      >
        <SvgIcon
          className="ant-modal__close"
          icon="cross"
        />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="modal-message modal-message--centered">
        <SvgIcon icon="alert-o" className="mb-24 modal-message__icon" />
        <p className="modal-message__txt">
          <FormattedMessage id="uploadImage.formatSizeError" />
        </p>
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        extraClasses="main-modal__footer-action"
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <input
        id="avatar"
        className="d-none"
        type="file"
        name="avatar"
        accept={allowedImageTypes}
        onChange={onSelectImage}
      />
      <Button
        extraClasses="main-modal__footer-action"
        htmlFor="avatar"
        text={{ id: 'uploadImage.selectImage' }}
        isLabel
      />
    </div>
  </Modal>
);

UploadImageErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
};

export default UploadImageErrorModal;
