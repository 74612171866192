import { createLogic } from 'redux-logic';
import cookies from 'component-cookie';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';

import { hideModal } from 'state/modal/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { setTwoFactorAuthPasswordEntered } from '../actions';
import { submitSetupTwoFactorAuthEndpoint } from '../endpoints';
import { SUBMIT_SETUP_TWO_FACTOR_AUTH } from '../types';

const submitSetupTwoFactorAuthOperation = createLogic({
  type: SUBMIT_SETUP_TWO_FACTOR_AUTH,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { endpoint, url } = submitSetupTwoFactorAuthEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        password: values.password,
      };

      const { data } = await httpClient.post(url, params);
      const tokens = data.meta.jwt;

      cookies('tokens', JSON.stringify(tokens), { path: '/' });

      dispatch(dataApiSuccess({ endpoint }));

      dispatch(hideModal());
      dispatch(setTwoFactorAuthPasswordEntered(true));

      redirect({ href: '/account_settings/two_factor_auth' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitSetupTwoFactorAuthOperation;
