import { createSelector } from 'reselect';
import { prop, path } from 'ramda';

import buildCollection from 'utils/buildCollection';
import { isDoctor } from 'utils/roles';

import { totalCountSelector, loadingSelector, pageCountSelector } from 'state/data/selectors';
import { fetchDoctorsCommissionsEndpoint, fetchVirtualClinicsCommissionsEndpoint } from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['commissions', 'isBlankState']);
export const paginationSelector = path(['commissions', 'pagination']);
export const commissionIdsSelector = path(['commissions', 'commissionIds']);
export const sortSelector = path(['commissions', 'sort']);

export const doctorsCommissionsSelector = createSelector(
  commissionIdsSelector,
  dataSelector,
  buildCollection('userProfile'),
);

export const virtualClinicsCommissionsSelector = createSelector(
  commissionIdsSelector,
  dataSelector,
  buildCollection('clinic'),
);

export const commissionsSelector = (state, roleName) => (isDoctor(roleName)
  ? doctorsCommissionsSelector(state)
  : virtualClinicsCommissionsSelector(state)
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const commissionsTotalCountSelector = (state, roleName) => {
  let endpoint;

  if (isDoctor(roleName)) {
    endpoint = fetchDoctorsCommissionsEndpoint.endpoint;
  } else {
    endpoint = fetchVirtualClinicsCommissionsEndpoint.endpoint;
  }

  return totalCountSelector(state, endpoint);
};

export const commissionsLoadingSelector = (state, roleName) => {
  let endpoint;

  if (isDoctor(roleName)) {
    endpoint = fetchDoctorsCommissionsEndpoint.endpoint;
  } else {
    endpoint = fetchVirtualClinicsCommissionsEndpoint.endpoint;
  }

  return loadingSelector(state, endpoint);
};

export const commissionsPageCountSelector = (state, roleName) => {
  let endpoint;

  if (isDoctor(roleName)) {
    endpoint = fetchDoctorsCommissionsEndpoint.endpoint;
  } else {
    endpoint = fetchVirtualClinicsCommissionsEndpoint.endpoint;
  }

  return pageCountSelector(state, endpoint);
};
