import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import SelectField from 'views/shared/SelectField';
import SvgIcon from 'views/shared/SvgIcon';

const VideoConferenceSettingsModalComponent = ({
  audioDevices,
  videoDevices,
  speakerDevices,
  onSubmit,
  onClose,
}) => (
  <Modal className="ant-modal-wrap__6" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="videoConference.settings" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <Field
        className="mb-16"
        id="videoDevice"
        name="videoDevice"
        label={{ id: 'videoConference.settingsModal.camera' }}
        options={videoDevices}
        component={SelectField}
        labelInValue
      />
      <Field
        className="mb-16"
        name="audioDevice"
        id="audioDevice"
        label={{ id: 'videoConference.settingsModal.microphone' }}
        options={audioDevices}
        component={SelectField}
        labelInValue
      />
      <Field
        name="speakerDevice"
        id="speakerDevice"
        label={{ id: 'videoConference.settingsModal.speakers' }}
        options={speakerDevices}
        component={SelectField}
        labelInValue
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        className="mr-16"
        kind="tertiary"
        text={{ id: 'shared.cancel' }}
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.save' }}
        onClick={onSubmit}
      />
    </div>
  </Modal>
);

VideoConferenceSettingsModalComponent.propTypes = {
  audioDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  videoDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  speakerDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VideoConferenceSettingsModalComponent;
