import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { USER_TYPE } from 'constants/users';
import { IS_LOGGED_IN } from 'constants/auth';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';
import removeUserCookies from 'utils/removeUserCookies';
import isOnboardingCompleted from 'utils/users/isOnboardingCompleted';
import { isDoctor, isPlatformOwner } from 'utils/roles';
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { userLoginSuccess } from 'state/concepts/session/actions';
import { submitTwoFactorAuthenticationEndpoint } from '../endpoints';
import { SUBMIT_TWO_FACTOR_AUTH } from '../types';

const submitTwoFactorAuthenticationOperation = createLogic({
  type: SUBMIT_TWO_FACTOR_AUTH,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { endpoint, url } = submitTwoFactorAuthenticationEndpoint;
    const params = {
      verification_code: values.verificationCode,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);

      const response = normalize(data);
      const currentUser = build(response, 'userProfile')[0];
      const tokens = data.meta.jwt;
      const isRedirectProfile = isPlatformOwner(currentUser.roleName)
        || (isOnboardingCompleted(data) && isDoctor(currentUser.roleName));

      setUserCookies(currentUser, tokens, USER_TYPE.user, IS_LOGGED_IN);

      dispatch(userLoginSuccess(currentUser.id));
      dispatch(dataApiSuccess({ response, endpoint }));

      if (isRedirectProfile) {
        redirect({ href: '/profile' });
      } else {
        redirect({ href: '/onboarding' });
      }
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        removeUserCookies();
        await redirect({ href: '/login' });
        dispatch(showNotification({
          messageObject: {
            id: 'notifications.yourCurrentSessionHasBeenExpired',
          },
          kind: 'error',
        }));
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitTwoFactorAuthenticationOperation;
