import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path, prop } from 'ramda';

import redirect from 'utils/redirect';

import { hideModal as hideModalAction } from 'state/modal/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';

import DoctorComponent from './component';

class Doctor extends React.Component {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired,
    note: PropTypes.shape().isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  handleRedirect = () => {
    const { note, hideModal } = this.props;
    const href = `/independent_doctors/doctors/${prop('id', path(['appointment', 'userProfile'], note))}/view`;

    hideModal();
    redirect({ href });
  };

  render = () => (
    <DoctorComponent
      {...this.props}
      onRedirect={this.handleRedirect}
    />
  )
}

const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = {
  hideModal: hideModalAction,
};

export { Doctor as DoctorContainer };
export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
