import { createLogic } from 'redux-logic';
import { dec } from 'ramda';

import { VIRTUAL_CLINIC_DOCTOR_TYPES } from 'constants/doctors';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';
import { isErrorConflict } from 'utils/getErrorStatus';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchVirtualClinicDoctors, setCurrentPage } from '../actions';
import { virtualClinicDoctorsTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import {
  removeVirtualClinicDoctorInvitationEndpoint,
  removeVirtualClinicDoctorJoinRequestEndpoint,
} from '../endpoints';
import { REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION } from '../types';

const removeVirtualClinicDoctorInvitationOperation = createLogic({
  type: REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION,
  latest: true,

  async process({ httpClient, getState, action: { vcdId, vcdType } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = vcdType === VIRTUAL_CLINIC_DOCTOR_TYPES.joinRequest
      ? removeVirtualClinicDoctorJoinRequestEndpoint(vcdId)
      : removeVirtualClinicDoctorInvitationEndpoint(vcdId);
    const totalCount = virtualClinicDoctorsTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, 1);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.doctorsInvitationRemove' } }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchVirtualClinicDoctors());
    } catch (error) {
      dispatch(hideModal());
      dispatch(fetchVirtualClinicDoctors());

      if (isErrorConflict(error)) {
        dispatch(showNotification({
          messageObject: { id: 'notifications.doctorsInvitationRemoveAcceptedUserError' },
          kind: 'error',
        }));
      } else {
        dispatch(showNotification({
          messageObject: { id: 'notifications.doctorsInvitationRemoveError' },
          kind: 'error',
        }));
        requestErrorHandler({ error, dispatch, endpoint });
      }
    }

    done();
  },
});

export default removeVirtualClinicDoctorInvitationOperation;
