import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'views/shared/SvgIcon';

const AttachedFile = ({ fileSize, fileName }) => (
  <>
    <SvgIcon icon="attachment" className="file-uploader__attachment" />
    <span className="file-uploader__content">
      <span className="file-uploader__name">{fileName}</span>
      <span className="file-uploader__size">{fileSize}</span>
    </span>
  </>
);

AttachedFile.propTypes = {
  fileSize: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default AttachedFile;
