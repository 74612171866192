import { createLogic } from 'redux-logic';
import { length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { setCurrentPage, fetchDoctors } from '../actions';
import { usersInvitationsEndpoint } from '../endpoints';
import { INVITE_DOCTORS } from '../types';

const inviteDoctorsOperation = createLogic({
  type: INVITE_DOCTORS,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = usersInvitationsEndpoint;
    const params = {
      ...values,
      redirect_to: '/invitation_signup',
    };

    try {
      await httpClient.post(url, params);

      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.doctorsInvited',
          values: { count: length(values.invitations) },
        },
      }));
      dispatch(setCurrentPage(1));
      dispatch(fetchDoctors());
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default inviteDoctorsOperation;
