import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import { getDevices } from 'utils/videoConference';

import { settingsSelector } from 'state/concepts/userProfile/videoConference/selectors';
import { setSettings as setSettingsAction } from 'state/concepts/userProfile/videoConference/actions';

import VideoConferenceSettingsModalComponent from './component';

class VideoConferenceSettingsModal extends React.Component {
  static propTypes = {
    setSettings: PropTypes.func.isRequired,
    values: PropTypes.shape().isRequired,
    changeDevices: PropTypes.func,
    initialValues: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    changeDevices: () => Promise.resolve(),
  };

  state = {
    audioDevices: [],
    videoDevices: [],
    speakerDevices: [],
  };

  componentDidMount = async () => {
    const { audioDevices, videoDevices, speakerDevices } = await getDevices();

    this.setState({ audioDevices, videoDevices, speakerDevices });
  };

  handleSubmit = async () => {
    const {
      values: { audioDevice, videoDevice, speakerDevice },
      changeDevices,
      initialValues,
      setSettings,
      onClose,
    } = this.props;

    await changeDevices({
      audioDeviceId: audioDevice.key,
      videoDeviceId: videoDevice.key,
      speakerDeviceId: speakerDevice.key,
      replaceAudio: audioDevice.key !== initialValues.audioDevice.key,
      replaceVideo: videoDevice.key !== initialValues.videoDevice.key,
    });
    setSettings({ audioDevice, videoDevice, speakerDevice });
    onClose();
  };

  render() {
    return (
      <VideoConferenceSettingsModalComponent
        {...this.props}
        {...this.state}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  settings: settingsSelector(state),
});

const mapDispatchToProps = {
  setSettings: setSettingsAction,
};

export { VideoConferenceSettingsModal as VideoConferenceSettingsModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ settings: { audioDevice, videoDevice, speakerDevice } }) => ({
      audioDevice,
      videoDevice,
      speakerDevice,
    }),
    enableReinitialize: true,
  }),
)(VideoConferenceSettingsModal);
