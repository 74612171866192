import fetchAppointments from './fetchAppointments';
import filterAppointment from './filterAppointment';
import cancelAppointment from './cancelAppointment';
import downloadAppointmentsCsv from './downloadAppointmentsCsv';

export default [
  fetchAppointments,
  filterAppointment,
  cancelAppointment,
  downloadAppointmentsCsv,
];
