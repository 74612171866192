import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import formatCurrency from 'utils/formatCurrency';
import { isVirtualClinicAdmin } from 'utils/roles';
import symptomName from 'utils/symptoms/symptomName';

import Button from 'views/shared/Button';
import StatusBadge from 'views/shared/StatusBadge';
import SvgIcon from 'views/shared/SvgIcon';

import Actions from './Actions';
import PatientDetails from './PatientDetails';
import ConsultantDetails from './ConsultantDetails';
import AppointmentDateInfo from './AppointmentDateInfo';
import CancellationReason from './CancellationReason';
import Transactions from './Transactions';

const AppointmentCalendarTooltipContentComponent = forwardRef(({
  onClose,
  onCancel,
  onViewMeetingPage,
  currentUser,
  event,
  hasScroll,
  canCancel,
}, ref) => (
  <section className={classNames('modal-appointment__wrap', { 'appointment-tooltip--has-scroll': hasScroll })}>
    <div className="modal-appointment__content">
      <div className="modal-appointment__header">
        <StatusBadge event={event} />
        <div className="modal-appointment__header-actions">
          {canCancel && (
            <Actions event={event} onCancel={onCancel} />
          )}
          <button type="button" onClick={onClose}>
            <SvgIcon icon="cross" className="modal-appointment__icon" />
          </button>
        </div>
      </div>
      <div className="modal-appointment__info">
        <div className="modal-appointment__info-container">
          <h3 className="modal-appointment__title">
            {event.speciality.name}
          </h3>
          <p className="modal-appointment__title-cost">
            {event.freeVisit ? <FormattedMessage id="shared.freeVisit" /> : formatCurrency(event.price)}
          </p>
        </div>
        <p className="modal-appointment__speciality">
          {symptomName(event.symptom)}
        </p>
      </div>
      <div ref={ref}>
        <CancellationReason event={event} />
        {event.transactions && (
          <Transactions
            event={event}
            currentUser={currentUser}
            onClose={onClose}
          />
        )}
        <AppointmentDateInfo event={event} />
        <div className="modal-appointment__section">
          <div className="modal-appointment__relations">
            <ConsultantDetails
              userProfile={event.userProfile}
              currentUser={currentUser}
              onClose={onClose}
            />
            {!isVirtualClinicAdmin(currentUser.roleName) && (
              <PatientDetails
                patientProfile={event.patientProfile}
                childProfile={event.childProfile}
                onClose={onClose}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    {!isVirtualClinicAdmin(currentUser.roleName) && (
      <div className="modal-appointment__footer">
        <Button
          text={{ id: 'appointments.viewMeetingPage' }}
          size="small"
          onClick={onViewMeetingPage}
        />
      </div>
    )}
  </section>
));

AppointmentCalendarTooltipContentComponent.displayName = 'AppointmentCalendarTooltipContentComponent';

AppointmentCalendarTooltipContentComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onViewMeetingPage: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  event: PropTypes.shape().isRequired,
  hasScroll: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
};

export default AppointmentCalendarTooltipContentComponent;
