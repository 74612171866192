import getNumbers from './getNumbers';

export default (val) => {
  const indexOfPoint = val.indexOf('.');

  if (indexOfPoint !== -1) {
    const result = val.substring(indexOfPoint);
    const reg = getNumbers(result);

    return val.substring(0, indexOfPoint + 1) + reg;
  }
  return val;
};
