export const FETCH_DASHBOARDS = 'FETCH_DASHBOARDS';
export const SET_DASHBOARDS_IDS = 'SET_DASHBOARDS_IDS';
export const SET_DASHBOARDS_BLANK_STATE = 'SET_DASHBOARDS_BLANK_STATE';
export const SET_DASHBOARDS_CURRENT_PAGE = 'SET_DASHBOARDS_CURRENT_PAGE';
export const FETCH_DASHBOARD_CLINICS = 'FETCH_DASHBOARD_CLINICS';
export const SET_DASHBOARD_CLINICS_IDS = 'SET_DASHBOARD_CLINICS_IDS';
export const SET_DASHBOARD_CLINICS_LIST_FILTERS = 'SET_DASHBOARD_CLINICS_LIST_FILTERS';
export const SET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS = 'SET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS';
export const RESET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS = 'RESET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS';
export const RESET_DASHBOARD_CLINICS_LIST_IDS = 'RESET_DASHBOARD_CLINICS_LIST_IDS';
export const FETCH_DASHBOARD_PATIENTS = 'FETCH_DASHBOARD_PATIENTS';
export const SET_DASHBOARD_PATIENTS_IDS = 'SET_DASHBOARD_PATIENTS_IDS';
export const SET_DASHBOARD_PATIENTS_LIST_FILTERS = 'SET_DASHBOARD_PATIENTS_LIST_FILTERS';
export const SET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS = 'SET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS';
export const RESET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS = 'RESET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS';
export const RESET_DASHBOARD_PATIENTS_LIST_IDS = 'RESET_DASHBOARD_PATIENTS_LIST_IDS';
export const VALIDATE_DASHBOARDS = 'VALIDATE_DASHBOARDS';
export const SUBMIT_CREATE_DASHBOARD = 'SUBMIT_CREATE_DASHBOARD';
export const TOGGLE_DASHBOARD_CONFIRM_MODAL_VISIBLE = 'TOGGLE_DASHBOARD_CONFIRM_MODAL_VISIBLE';
export const SET_AVAILABILITIES_FILTER = 'SET_AVAILABILITIES_FILTER';
