import React from 'react';

const SvgRootComponent = () => (
  // eslint-disable-next-line react/style-prop-object
  <svg
    aria-hidden="true"
    style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="icon-percentage" viewBox="0 0 32 32">
        <path d="M22 17.333c2.577 0 4.667 2.089 4.667 4.667s-2.089 4.667-4.667 4.667c-2.577 0-4.667-2.089-4.667-4.667s2.089-4.667 4.667-4.667zM22 20c-1.105 0-2 0.895-2 2s0.895 2 2 2c1.105 0 2-0.895 2-2s-0.895-2-2-2zM21.582 5.892l2.17 1.55-13.333 18.667-2.17-1.55 13.333-18.667zM10 5.333c2.577 0 4.667 2.089 4.667 4.667s-2.089 4.667-4.667 4.667c-2.577 0-4.667-2.089-4.667-4.667s2.089-4.667 4.667-4.667zM10 8c-1.105 0-2 0.895-2 2s0.895 2 2 2c1.105 0 2-0.895 2-2s-0.895-2-2-2z" />
      </symbol>
      <symbol id="icon-star_rate" viewBox="0 0 32 32">
        <path d="M16 21.422l6.596 4.8-2.524-7.751 6.596-4.693h-8.089l-2.578-8-2.578 8h-8.089l6.596 4.693-2.524 7.751z" />
      </symbol>
      <symbol id="icon-credit-card" viewBox="0 0 32 32">
        <path d="M24.581 5.333c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v0 11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514v0h-17.163c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v0-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514v0zM26.665 14.667h-21.332l0.001 7.474c0.009 0.705 0.064 0.95 0.197 1.2 0.108 0.202 0.259 0.353 0.461 0.461 0.274 0.147 0.543 0.199 1.425 0.199v0l17.389-0.001c0.705-0.009 0.95-0.064 1.2-0.197 0.202-0.108 0.353-0.259 0.461-0.461 0.147-0.274 0.199-0.543 0.199-1.425v0l-0.001-7.248zM9.333 18.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM20 18.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-5.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h5.333zM24.807 8.001h-17.615c-0.705 0.009-0.95 0.064-1.2 0.197-0.202 0.108-0.353 0.259-0.461 0.461-0.147 0.274-0.199 0.543-0.199 1.425v0.582h21.332v-0.807c-0.008-0.627-0.052-0.89-0.156-1.116l-0.042-0.084c-0.108-0.202-0.259-0.353-0.461-0.461-0.274-0.147-0.543-0.199-1.425-0.199z" />
      </symbol>
      <symbol id="icon-icon-payments" viewBox="0 0 32 32">
        <path d="M25.333 18.667v-10.667c0-1.467-1.2-2.667-2.667-2.667h-18.667c-1.467 0-2.667 1.2-2.667 2.667v10.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667zM22.667 18.667h-18.667v-10.667h18.667v10.667zM13.333 9.333c-2.213 0-4 1.787-4 4s1.787 4 4 4c2.213 0 4-1.787 4-4s-1.787-4-4-4zM30.667 9.333v14.667c0 1.467-1.2 2.667-2.667 2.667h-22.667c0-1.333 0-1.2 0-2.667h22.667v-14.667c1.467 0 1.333 0 2.667 0z" />
      </symbol>
      <symbol id="icon-icon-list" viewBox="0 0 32 32">
        <path d="M26.667 29.333h-21.333c-0.736 0-1.333-0.597-1.333-1.333v-24c0-0.736 0.597-1.333 1.333-1.333h21.333c0.736 0 1.333 0.597 1.333 1.333v24c0 0.736-0.597 1.333-1.333 1.333zM25.333 26.667v-21.333h-18.667v21.333h18.667zM10.667 9.333h10.667v2.667h-10.667v-2.667zM10.667 14.667h10.667v2.667h-10.667v-2.667zM10.667 20h6.667v2.667h-6.667v-2.667z" />
      </symbol>
      <symbol id="icon-icon-balance" viewBox="0 0 32 32">
        <path d="M10 14.667h-2.667v9.333h2.667v-9.333zM18 14.667h-2.667v9.333h2.667v-9.333zM29.333 26.667h-25.333v2.667h25.333v-2.667zM26 14.667h-2.667v9.333h2.667v-9.333zM16.667 5.68l6.947 3.653h-13.893l6.947-3.653zM16.667 2.667l-12.667 6.667v2.667h25.333v-2.667l-12.667-6.667z" />
      </symbol>
      <symbol id="icon-twitter" viewBox="0 0 32 32">
        <path d="M29.333 7.889c-1 0.444-2 0.778-3.111 0.889 1.111-0.667 2-1.778 2.444-3-1.111 0.667-2.222 1.111-3.444 1.333-1-1.111-2.444-1.778-4-1.778-3 0-5.444 2.444-5.444 5.444 0 0.444 0 0.889 0.111 1.222-4.667-0.222-8.667-2.444-11.333-5.778-0.556 0.889-0.778 1.778-0.778 2.778 0 1.889 1 3.556 2.444 4.556-0.889 0-1.778-0.222-2.444-0.667 0 0 0 0 0 0.111 0 2.667 1.889 4.889 4.333 5.333-0.444 0.111-0.889 0.222-1.444 0.222-0.333 0-0.667 0-1-0.111 0.667 2.222 2.667 3.778 5.111 3.778-1.889 1.444-4.222 2.333-6.778 2.333-0.444 0-0.889 0-1.333-0.111 2.444 1.556 5.333 2.444 8.333 2.444 10.111 0 15.556-8.333 15.556-15.556v-0.667c1.111-0.778 2-1.778 2.778-2.778z" />
      </symbol>
      <symbol id="icon-instagram" viewBox="0 0 32 32">
        <path d="M16 5.069v0c-3.56 0-3.982 0.013-5.388 0.078-1.518 0.069-2.926 0.373-4.009 1.457s-1.388 2.491-1.457 4.009c-0.064 1.406-0.078 1.828-0.078 5.388s0.013 3.982 0.078 5.388c0.069 1.518 0.373 2.926 1.457 4.009s2.491 1.388 4.009 1.457c1.406 0.064 1.828 0.078 5.388 0.078s3.982-0.013 5.388-0.078c1.518-0.069 2.926-0.373 4.009-1.457s1.388-2.491 1.457-4.009c0.064-1.406 0.078-1.828 0.078-5.388s-0.013-3.982-0.078-5.388c-0.069-1.518-0.373-2.926-1.457-4.009s-2.491-1.388-4.009-1.457c-1.406-0.064-1.828-0.078-5.388-0.078zM17.37 2.668c2.401 0.004 2.909 0.024 4.128 0.079 2.167 0.099 4.070 0.63 5.598 2.158s2.059 3.431 2.158 5.598c0.055 1.219 0.075 1.727 0.079 4.128l0.001 0.994 0 0.376c0 0.129-0 0.255-0 0.376l-0.001 0.994c-0.004 2.401-0.024 2.909-0.079 4.128-0.099 2.167-0.63 4.070-2.158 5.598s-3.431 2.059-5.598 2.158c-1.219 0.055-1.727 0.075-4.128 0.079l-1.37 0.001c-0.129 0-0.255-0-0.376-0l-0.994-0.001c-2.401-0.004-2.909-0.024-4.128-0.079-2.167-0.099-4.070-0.63-5.598-2.158s-2.059-3.431-2.158-5.598c-0.055-1.219-0.075-1.727-0.079-4.128v-2.74c0.004-2.401 0.024-2.909 0.079-4.128 0.099-2.167 0.63-4.070 2.158-5.598s3.431-2.059 5.598-2.158c1.219-0.055 1.727-0.075 4.128-0.079zM16 9.153c-3.781 0-6.847 3.066-6.847 6.847s3.066 6.847 6.847 6.847c3.781 0 6.847-3.066 6.847-6.847s-3.066-6.847-6.847-6.847zM16 11.556c2.454 0 4.444 1.99 4.444 4.444s-1.99 4.444-4.444 4.444c-2.454 0-4.444-1.99-4.444-4.444s1.99-4.444 4.444-4.444zM23.118 7.282c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6z" />
      </symbol>
      <symbol id="icon-facebook" viewBox="0 0 32 32">
        <path d="M12.398 28l-0.034-10.909h-4.364v-4.364h4.364v-2.727c0-4.049 2.507-6 6.119-6 1.73 0 3.217 0.129 3.65 0.186v4.231l-2.505 0.001c-1.964 0-2.345 0.933-2.345 2.303v2.005h5.717l-2.182 4.364h-3.535v10.909h-4.885z" />
      </symbol>
      <symbol id="icon-clinic" viewBox="0 0 32 32">
        <path d="M16 2.667l-12 7.951v14.982c0 1.457 1.209 2.667 2.667 2.667h18.667c1.457 0 2.667-1.209 2.667-2.667v-14.982l-12-7.951zM16 5.865l9.333 6.185v13.549h-18.667v-13.549l9.333-6.185zM14.667 12.266v4h-4v2.667h4v4h2.667v-4h4v-2.667h-4v-4h-2.667z" />
      </symbol>
      <symbol id="icon-clinics" viewBox="0 0 32 32">
        <path d="M6.667 4c-1.473 0-2.667 1.193-2.667 2.667v18.667c0 1.473 1.193 2.667 2.667 2.667h18.667c1.473 0 2.667-1.193 2.667-2.667v-18.667c0-1.473-1.193-2.667-2.667-2.667h-18.667zM6.667 6.667h18.667v18.667h-18.667v-18.667zM14.667 10.667v4h-4v2.667h4v4h2.667v-4h4v-2.667h-4v-4h-2.667z" />
      </symbol>
      <symbol id="icon-clinic-speciality" viewBox="0 0 32 32">
        <path d="M18.667 2.667c1.473 0 2.667 1.194 2.667 2.667v2.667l4.299 0.001c1.522 0.014 1.928 0.136 2.337 0.355 0.435 0.232 0.776 0.573 1.008 1.008s0.356 0.866 0.356 2.636v12c0 1.77-0.124 2.201-0.356 2.636s-0.573 0.776-1.008 1.008c-0.435 0.232-0.866 0.356-2.636 0.356h-18.667c-1.77 0-2.201-0.124-2.636-0.356s-0.776-0.573-1.008-1.008c-0.219-0.409-0.341-0.815-0.355-2.337l-0.001-12.299c0-1.77 0.124-2.201 0.356-2.636s0.573-0.776 1.008-1.008c0.409-0.219 0.815-0.341 2.337-0.355l4.299-0.001v-2.667c0-1.473 1.194-2.667 2.667-2.667h5.333zM25.333 10.667h-18.667c-0.736 0-1.333 0.597-1.333 1.333v12c0 0.736 0.597 1.333 1.333 1.333h18.667c0.736 0 1.333-0.597 1.333-1.333l-0.001-6.668-21.332 0.001v-2.667l21.332-0.001 0.001-2.665c0-0.736-0.597-1.333-1.333-1.333zM12 17.333v1.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-1.333h2.667zM22.667 17.333v1.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-1.333h2.667zM17.333 5.333h-2.667c-0.684 0-1.247 0.515-1.324 1.178l-0.009 0.155v1.333h5.333v-1.333c0-0.736-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-doctor" viewBox="0 0 32 32">
        <path d="M11.333 2.667c0 0-0.667 5.333-0.667 8 0 2.941 2.392 5.333 5.333 5.333s5.333-2.392 5.333-5.333c0-2.667-0.667-8-0.667-8h-9.333zM13.714 5.333h4.573c0.085 0.816 0.177 1.756 0.247 2.667h-5.068c0.072-0.911 0.162-1.851 0.247-2.667zM13.333 10.667h5.333c0 1.471-1.196 2.667-2.667 2.667s-2.667-1.196-2.667-2.667zM16 20c-4.188 0-12 2.061-12 6v3.333h24v-3.333c0-3.939-7.812-6-12-6zM13.716 22.862l2.284 3.805 2.284-3.805c3.657 0.542 7.049 2.179 7.049 3.138v0.667h-18.667v-0.667c0-0.959 3.393-2.596 7.049-3.138z" />
      </symbol>
      <symbol id="icon-pill" viewBox="0 0 32 32">
        <path d="M21.325 2.667c-2.050 0-4.1 0.777-5.656 2.333l-10.667 10.667c-3.114 3.112-3.113 8.199 0 11.313v0.003c1.557 1.555 3.611 2.341 5.656 2.341s4.1-0.788 5.656-2.344l10.667-10.667c3.112-3.112 3.113-8.2 0-11.313-1.557-1.556-3.606-2.333-5.656-2.333zM21.325 5.315c1.362 0 2.724 0.524 3.771 1.57 2.095 2.095 2.094 5.449 0 7.542l-4.391 4.391-7.542-7.542 4.391-4.391c1.047-1.047 2.409-1.57 3.771-1.57zM11.278 13.161l7.542 7.542-4.391 4.391c-1.047 1.047-2.403 1.563-3.771 1.563s-2.723-0.516-3.771-1.563c-2.095-2.095-2.094-5.449 0-7.542l4.391-4.391z" />
      </symbol>
      <symbol id="icon-queue" viewBox="0 0 32 32">
        <path d="M8 5.333v5.333h0.013l-0.013 0.013 5.333 5.32-5.333 5.333 0.013 0.013h-0.013v5.32c0 1.473 1.194 2.667 2.667 2.667h10.667c1.473 0 2.667-1.194 2.667-2.667v-5.32h-0.013l0.013-0.013-5.333-5.333 5.333-5.32-0.013-0.013h0.013v-5.333c0-1.473-1.194-2.667-2.667-2.667h-10.667c-1.473 0-2.667 1.194-2.667 2.667zM21.333 22v3.333c0 0.736-0.597 1.333-1.333 1.333h-8c-0.736 0-1.333-0.597-1.333-1.333v-3.333l5.333-5.333 5.333 5.333zM16 15.333l-5.333-5.333v-3.333c0-0.736 0.597-1.333 1.333-1.333h8c0.736 0 1.333 0.597 1.333 1.333v3.333l-5.333 5.333z" />
      </symbol>
      <symbol id="icon-revenue" viewBox="0 0 32 32">
        <path d="M8 4c-2.205 0-4 1.795-4 4v17.333c0 1.471 1.196 2.667 2.667 2.667h18.667c1.471 0 2.667-1.196 2.667-2.667v-13.333c0-1.471-1.196-2.667-2.667-2.667h-17.333c-0.736 0-1.333-0.599-1.333-1.333s0.597-1.333 1.333-1.333h18.667v-2.667h-18.667zM6.667 11.755c0.419 0.15 0.863 0.245 1.333 0.245h17.333v13.333h-18.667v-13.578zM21.333 17.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-speciality" viewBox="0 0 32 32">
        <path d="M12 4v1.333h-6.667c-1.457 0-2.667 1.209-2.667 2.667v16c0 1.457 1.209 2.667 2.667 2.667h21.333c1.457 0 2.667-1.209 2.667-2.667v-16c0-1.457-1.209-2.667-2.667-2.667h-6.667v-1.333h-8zM5.333 8h21.333v16h-21.333v-16zM14.667 10.667v4h-4v2.667h4v4h2.667v-4h4v-2.667h-4v-4h-2.667z" />
      </symbol>
      <symbol id="icon-syringe" viewBox="0 0 32 32">
        <path d="M21 3.609c-0.521 0.521-0.521 1.365 0 1.885 0 0 0 0 0 0l1.846 1.846-2.865 2.865-4.008-4.005-10.565 10.549-0.039 8.068-1.76 1.76c-0.521 0.521-0.521 1.365 0 1.885 0 0 0 0 0 0 0.521 0.521 1.365 0.521 1.885 0l1.773-1.773h7.984l10.607-10.604-3.992-3.992 2.865-2.867 1.831 1.831c0.521 0.521 1.365 0.521 1.885 0 0 0 0 0 0 0 0.521-0.521 0.521-1.365 0-1.885l-5.563-5.563c-0.521-0.521-1.365-0.521-1.885 0zM15.971 9.969l6.115 6.117-2.073 2.073-2.997-3-1.885 1.885 2.997 3-2.094 2.094-3.057-3.057-1.885 1.885 3.055 3.057h-6.107l0.031-6.161 7.901-7.893z" />
      </symbol>
      <symbol id="icon-voucher" viewBox="0 0 32 32">
        <path d="M5.333 5.333c-1.467 0-2.667 1.2-2.667 2.667v5.333c1.467 0 2.667 1.2 2.667 2.667s-1.2 2.667-2.667 2.667v5.333c0 1.467 1.2 2.667 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667v-5.333c-1.467 0-2.667-1.2-2.667-2.667s1.2-2.667 2.667-2.667v-5.333c0-1.467-1.2-2.667-2.667-2.667h-21.333zM5.333 8h21.333v3.383c-1.593 0.924-2.667 2.648-2.667 4.617s1.073 3.695 2.667 4.617v3.383h-21.333v-3.383c1.593-0.923 2.667-2.647 2.667-4.617s-1.073-3.695-2.667-4.617v-3.383zM16 9.068l-1.867 4.398-4.799 0.401 3.599 3.201-1.065 4.667 4.133-2.534 4.133 2.398-1.065-4.531 3.599-3.201-4.799-0.401-1.867-4.398z" />
      </symbol>
      <symbol id="icon-minus" viewBox="0 0 32 32">
        <path d="M7.771 17.6c-0.757 0-1.371-0.716-1.371-1.6s0.614-1.6 1.371-1.6h16.457c0.757 0 1.371 0.716 1.371 1.6s-0.614 1.6-1.371 1.6h-16.457z" />
      </symbol>
      <symbol id="icon-eye-o" viewBox="0 0 32 32">
        <path d="M29.227 15.467c-2.693-6.253-7.76-10.133-13.227-10.133s-10.533 3.88-13.227 10.133c-0.148 0.34-0.148 0.727 0 1.067 2.693 6.253 7.76 10.133 13.227 10.133s10.533-3.88 13.227-10.133c0.148-0.34 0.148-0.727 0-1.067zM16 24c-4.227 0-8.227-3.053-10.533-8 2.307-4.947 6.307-8 10.533-8s8.227 3.053 10.533 8c-2.307 4.947-6.307 8-10.533 8zM16 10.667c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333c2.946 0 5.333-2.388 5.333-5.333 0-1.415-0.562-2.771-1.562-3.771s-2.357-1.562-3.771-1.562zM16 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667z" />
      </symbol>
      <symbol id="icon-alert-o" viewBox="0 0 32 32">
        <path d="M18.341 4.292l11.508 21.098c0.705 1.293 0.229 2.913-1.064 3.618-0.392 0.214-0.831 0.326-1.277 0.326h-23.016c-1.473 0-2.667-1.194-2.667-2.667 0-0.446 0.112-0.885 0.326-1.277l11.508-21.098c0.705-1.293 2.325-1.769 3.618-1.064 0.45 0.245 0.819 0.615 1.064 1.064zM16 5.569l-11.508 21.098h23.016l-11.508-21.098zM16 22c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM16 12c0.736 0 1.333 0.597 1.333 1.333v5.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-5.333c0-0.736 0.597-1.333 1.333-1.333z" />
      </symbol>
      <symbol id="icon-close-o" viewBox="0 0 32 32">
        <path d="M16 26.667c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667zM17.886 16l3.057-3.057c0.521-0.521 0.521-1.365 0-1.886s-1.365-0.521-1.886 0l-3.057 3.057-3.057-3.057c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l3.057 3.057-3.057 3.057c-0.521 0.521-0.521 1.365 0 1.886s1.365 0.521 1.886 0l3.057-3.057 3.057 3.057c0.521 0.521 1.365 0.521 1.886 0s0.521-1.365 0-1.886l-3.057-3.057zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333z" />
      </symbol>
      <symbol id="icon-alert" viewBox="0 0 32 32">
        <path d="M18.341 4.292l11.508 21.098c0.705 1.293 0.229 2.913-1.064 3.618-0.392 0.214-0.831 0.326-1.277 0.326h-23.016c-1.473 0-2.667-1.194-2.667-2.667 0-0.446 0.112-0.885 0.326-1.277l11.508-21.098c0.705-1.293 2.325-1.769 3.618-1.064 0.45 0.245 0.819 0.615 1.064 1.064zM16.127 22c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333zM16.127 12c-0.736 0-1.333 0.597-1.333 1.333v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-close" viewBox="0 0 32 32">
        <path d="M17.886 16l3.057-3.057c0.521-0.521 0.521-1.365 0-1.886s-1.365-0.521-1.886 0l-3.057 3.057-3.057-3.057c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l3.057 3.057-3.057 3.057c-0.521 0.521-0.521 1.365 0 1.886s1.365 0.521 1.886 0l3.057-3.057 3.057 3.057c0.521 0.521 1.365 0.521 1.886 0s0.521-1.365 0-1.886l-3.057-3.057zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333z" />
      </symbol>
      <symbol id="icon-eye-slash-o" viewBox="0 0 32 32">
        <path d="M15.999 8.003c4.238 0 8.223 3.052 10.541 7.996-0.355 0.752-0.755 1.482-1.199 2.186-0.141 0.218-0.215 0.473-0.213 0.733 0.006 0.598 0.409 1.118 0.987 1.273s1.187-0.093 1.492-0.607c0.621-0.976 1.16-2.001 1.613-3.065 0.145-0.336 0.145-0.717 0-1.053-2.692-6.25-7.756-10.128-13.22-10.128-0.625-0.003-1.25 0.050-1.866 0.16-0.476 0.081-0.873 0.41-1.041 0.862s-0.082 0.961 0.227 1.333c0.308 0.372 0.791 0.498 1.267 0.417 0.467-0.072 0.94-0.107 1.413-0.107zM4.951 3.059c-0.338-0.338-0.831-0.47-1.292-0.346s-0.823 0.484-0.946 0.946c-0.124 0.462 0.008 0.955 0.346 1.293l4.131 4.118c-1.885 1.814-3.385 3.99-4.411 6.397-0.148 0.34-0.148 0.726 0 1.066 2.692 6.25 7.756 10.128 13.22 10.128 2.395-0.017 4.733-0.73 6.73-2.052l4.318 4.331c0.25 0.252 0.591 0.394 0.946 0.394s0.696-0.142 0.946-0.394c0.252-0.25 0.394-0.591 0.394-0.946s-0.142-0.696-0.394-0.946l-23.988-23.988zM16.692 18.571c-0.225 0.065-0.459 0.096-0.693 0.093-1.472 0-2.665-1.193-2.665-2.665-0.003-0.234 0.029-0.468 0.093-0.693l3.265 3.265zM15.999 23.995c-4.238 0-8.223-3.052-10.528-7.996 0.861-1.901 2.083-3.616 3.598-5.051l2.359 2.386c-1.138 2.077-0.769 4.656 0.906 6.331s4.254 2.044 6.331 0.906l2.119 2.092c-1.451 0.854-3.101 1.314-4.784 1.333z" />
      </symbol>
      <symbol id="icon-menu" viewBox="0 0 32 32">
        <path d="M5.333 17.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h21.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-21.333zM5.333 9.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h21.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-21.333zM5.333 25.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h21.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-21.333z" />
      </symbol>
      <symbol id="icon-arrow-left" viewBox="0 0 32 32">
        <path d="M13.886 16l5.724 5.724c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-6.667-6.667c-0.521-0.521-0.521-1.365 0-1.886l6.667-6.667c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-5.724 5.724z" />
      </symbol>
      <symbol id="icon-info" viewBox="0 0 32 32">
        <path d="M16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333zM16 14c-0.736 0-1.333 0.597-1.333 1.333v6c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-6c0-0.736-0.597-1.333-1.333-1.333zM16 9.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-tick-o" viewBox="0 0 32 32">
        <path d="M16 2.667c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333zM16 5.333c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667z" />
        <path d="M10.421 15.614c0.561-0.583 1.47-0.583 2.030 0l2.029 2.112 5.069-5.288c0.561-0.583 1.47-0.583 2.030 0s0.561 1.529 0 2.113l-6.083 6.346c-0.561 0.583-1.47 0.583-2.030 0l-3.046-3.169c-0.561-0.583-0.561-1.529 0-2.113z" />
      </symbol>
      <symbol id="icon-tick" viewBox="0 0 32 32">
        <path d="M16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333zM20.419 11.724l-7.057 7.057-2.419-2.419c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l3.362 3.362c0.521 0.521 1.365 0.521 1.886 0l8-8c0.521-0.521 0.521-1.365 0-1.886s-1.365-0.521-1.886 0z" />
      </symbol>
      <symbol id="icon-cross" viewBox="0 0 32 32">
        <path d="M17.886 16l4.39 4.391c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-4.391-4.39-4.391 4.391c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l4.391-4.39-4.391-4.391c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l4.391 4.391 4.391-4.391c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-4.391 4.39z" />
      </symbol>
      <symbol id="icon-notification-o" viewBox="0 0 32 32">
        <path d="M24 18.667v-6.667c0-4.418-3.582-8-8-8s-8 3.582-8 8v6.667c0 0.971-0.26 1.882-0.713 2.667h17.427c-0.454-0.784-0.713-1.695-0.713-2.667zM29.333 24h-26.667c-1.778 0-1.778-2.667 0-2.667 1.473 0 2.667-1.194 2.667-2.667v-6.667c0-5.891 4.776-10.667 10.667-10.667s10.667 4.776 10.667 10.667v6.667c0 1.473 1.194 2.667 2.667 2.667 1.778 0 1.778 2.667 0 2.667zM19.46 28.669c-0.716 1.234-2.034 1.993-3.46 1.993s-2.744-0.759-3.46-1.993c-0.516-0.889 0.126-2.002 1.153-2.002h4.613c1.028 0 1.669 1.113 1.153 2.002z" />
      </symbol>
      <symbol id="icon-arrow-down" viewBox="0 0 32 32">
        <path d="M16 18.114l5.724-5.724c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-6.667 6.667c-0.521 0.521-1.365 0.521-1.886 0l-6.667-6.667c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l5.724 5.724z" />
      </symbol>
      <symbol id="icon-calendar-o" viewBox="0 0 32 32">
        <path d="M25.333 2.667h-1.333v-1.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v1.333h-10.667v-1.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v1.333h-1.333c-2.209 0-4 1.791-4 4v18.667c0 2.209 1.791 4 4 4h18.667c2.209 0 4-1.791 4-4v-18.667c0-2.209-1.791-4-4-4zM26.667 25.333c0 0.736-0.597 1.333-1.333 1.333h-18.667c-0.736 0-1.333-0.597-1.333-1.333v-12h21.333v12zM26.667 10.667h-21.333v-4c0-0.736 0.597-1.333 1.333-1.333h1.333v1.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-1.333h10.667v1.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-1.333h1.333c0.736 0 1.333 0.597 1.333 1.333v4zM20 18.667h2.667c0.736 0 1.333 0.597 1.333 1.333v2.667c0 0.736-0.597 1.333-1.333 1.333h-2.667c-0.736 0-1.333-0.597-1.333-1.333v-2.667c0-0.736 0.597-1.333 1.333-1.333z" />
      </symbol>
      <symbol id="icon-group-o" viewBox="0 0 32 32">
        <path d="M16.4 16.293c1.44-1.246 2.267-3.056 2.267-4.96 0-3.682-2.985-6.667-6.667-6.667s-6.667 2.985-6.667 6.667c-0 1.904 0.827 3.714 2.267 4.96-3.812 1.726-6.263 5.522-6.267 9.707 0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333c0-4.418 3.582-8 8-8s8 3.582 8 8c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333c-0.004-4.185-2.455-7.98-6.267-9.707v0zM12 15.333c-2.209 0-4-1.791-4-4s1.791-4 4-4c2.209 0 4 1.791 4 4 0 1.061-0.421 2.078-1.172 2.828s-1.768 1.172-2.828 1.172zM24.987 15.76c1.743-1.963 2.172-4.766 1.096-7.16s-3.457-3.934-6.082-3.933c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c2.209 0 4 1.791 4 4-0.004 1.425-0.766 2.741-2 3.453-0.402 0.232-0.654 0.656-0.667 1.12-0.011 0.46 0.215 0.894 0.6 1.147l0.52 0.347 0.173 0.093c3.281 1.556 5.362 4.875 5.333 8.507 0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333c0.022-4.16-2.112-8.035-5.64-10.24v0z" />
      </symbol>
      <symbol id="icon-service" viewBox="0 0 32 32">
        <path d="M21.333 10.667h-14.667c-0.736 0-1.333 0.597-1.333 1.333v12c0 0.736 0.597 1.333 1.333 1.333h18.667c0.736 0 1.333-0.597 1.333-1.333v-12c0-0.736-0.597-1.333-1.333-1.333h-4zM10.667 8v-2.667c0-1.473 1.194-2.667 2.667-2.667h5.333c1.473 0 2.667 1.194 2.667 2.667v2.667h4c1.77 0 2.201 0.124 2.636 0.356s0.776 0.573 1.008 1.008c0.232 0.435 0.356 0.866 0.356 2.636v12c0 1.77-0.124 2.201-0.356 2.636s-0.573 0.776-1.008 1.008c-0.435 0.232-0.866 0.356-2.636 0.356h-18.667c-1.77 0-2.201-0.124-2.636-0.356s-0.776-0.573-1.008-1.008c-0.232-0.435-0.356-0.866-0.356-2.636v-12c0-1.77 0.124-2.201 0.356-2.636s0.573-0.776 1.008-1.008c0.435-0.232 0.866-0.356 2.636-0.356h4zM13.333 8h5.333v-1.333c0-0.736-0.597-1.333-1.333-1.333h-2.667c-0.736 0-1.333 0.597-1.333 1.333v1.333zM20 17.333h-8v1.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-1.333h-4v-2.667h21.333v2.667h-4v1.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-1.333z" />
      </symbol>
      <symbol id="icon-time" viewBox="0 0 32 32">
        <path d="M16 2.667c-7.364 0-13.333 5.97-13.333 13.333s5.97 13.333 13.333 13.333c7.364 0 13.333-5.97 13.333-13.333 0-3.536-1.405-6.928-3.905-9.428s-5.892-3.905-9.428-3.905zM16 26.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667c5.891 0 10.667 4.776 10.667 10.667 0 2.829-1.124 5.542-3.124 7.542s-4.713 3.124-7.543 3.124zM20.133 16.84l-2.8-1.613v-5.893c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v6.667c0 0 0 0.107 0 0.16 0.008 0.092 0.030 0.182 0.067 0.267 0.027 0.079 0.063 0.155 0.107 0.227 0.037 0.076 0.081 0.147 0.133 0.213l0.213 0.173 0.12 0.12 3.467 2c0.203 0.115 0.433 0.175 0.667 0.173 0.607 0.004 1.141-0.402 1.297-0.989s-0.102-1.205-0.631-1.504h0.027z" />
      </symbol>
      <symbol id="icon-logout" viewBox="0 0 32 32">
        <path d="M24.781 14.667l-4.39-4.391c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l6.667 6.667c0.521 0.521 0.521 1.365 0 1.886l-6.667 6.667c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l4.391-4.39h-12.781c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h12.781zM16 26.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-10c-1.841 0-3.333-1.492-3.333-3.333v-20c0-1.841 1.492-3.333 3.333-3.333h10c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-10c-0.368 0-0.667 0.298-0.667 0.667v20c0 0.368 0.298 0.667 0.667 0.667h10z" />
      </symbol>
      <symbol id="icon-arrow-up" viewBox="0 0 32 32">
        <path d="M16 13.886l-5.724 5.724c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l6.667-6.667c0.521-0.521 1.365-0.521 1.886 0l6.667 6.667c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-5.724-5.724z" />
      </symbol>
      <symbol id="icon-upload" viewBox="0 0 32 32">
        <path d="M14.667 7.219l-4.391 4.391c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l6.667-6.667c0.521-0.521 1.365-0.521 1.886 0l6.667 6.667c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-4.39-4.391v12.781c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v-12.781zM26.667 21.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333v4.667c0 1.841-1.492 3.333-3.333 3.333h-20c-1.841 0-3.333-1.492-3.333-3.333v-4.667c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333v4.667c0 0.368 0.298 0.667 0.667 0.667h20c0.368 0 0.667-0.299 0.667-0.667v-4.667z" />
      </symbol>
      <symbol id="icon-delete-o" viewBox="0 0 32 32">
        <path d="M5.333 6.667h5.333v-2.667c0-0.736 0.597-1.333 1.333-1.333h8c0.736 0 1.333 0.597 1.333 1.333v2.667h5.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-1.333v18c0 1.105-0.895 2-2 2h-14.667c-1.105 0-2-0.895-2-2v-18h-1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM14.667 22.667v-8c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v8c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333zM20 22.667v-8c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v8c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333zM13.333 5.333v1.333h5.333v-1.333h-5.333zM22.667 9.333h-13.333v17.333h13.333v-17.333z" />
      </symbol>
      <symbol id="icon-edit-pencil" viewBox="0 0 32 32">
        <path d="M21.333 7.771l-12 12v2.895h2.895l12-12-2.895-2.895zM27.057 11.609l-13.333 13.333c-0.25 0.25-0.589 0.39-0.943 0.39h-4.781c-0.736 0-1.333-0.597-1.333-1.333v-4.781c0-0.354 0.141-0.693 0.391-0.943l13.333-13.333c0.521-0.521 1.365-0.521 1.886 0l4.781 4.781c0.521 0.521 0.521 1.365 0 1.886z" />
      </symbol>
      <symbol id="icon-arrow-down-long" viewBox="0 0 32 32">
        <path d="M14.667 20.781v-12.781c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333v12.781l4.39-4.39c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-6.667 6.667c-0.521 0.521-1.365 0.521-1.886 0l-6.667-6.667c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l4.391 4.391z" />
      </symbol>
      <symbol id="icon-options" viewBox="0 0 32 32">
        <path d="M16 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667zM25.333 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667zM6.667 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667z" />
      </symbol>
      <symbol id="icon-arrow-right" viewBox="0 0 32 32">
        <path d="M18.114 16l-5.724 5.724c-0.521 0.521-0.521 1.365 0 1.886s1.365 0.521 1.886 0l6.667-6.667c0.521-0.521 0.521-1.365 0-1.886l-6.667-6.667c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l5.724 5.724z" />
      </symbol>
      <symbol id="icon-plus" viewBox="0 0 32 32">
        <path d="M14.678 14.678v-6.611c0-0.73 0.592-1.322 1.322-1.322s1.322 0.592 1.322 1.322v6.611h6.611c0.73 0 1.322 0.592 1.322 1.322s-0.592 1.322-1.322 1.322h-6.611v6.611c0 0.73-0.592 1.322-1.322 1.322s-1.322-0.592-1.322-1.322v-6.611h-6.611c-0.73 0-1.322-0.592-1.322-1.322s0.592-1.322 1.322-1.322h6.611z" />
      </symbol>
      <symbol id="icon-search" viewBox="0 0 32 32">
        <path d="M14.667 21.333c3.682 0 6.667-2.985 6.667-6.667s-2.985-6.667-6.667-6.667c-3.682 0-6.667 2.985-6.667 6.667s2.985 6.667 6.667 6.667zM22.142 20.256l2.801 2.801c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-2.801-2.801c-1.558 1.167-3.493 1.858-5.59 1.858-5.155 0-9.333-4.179-9.333-9.333s4.179-9.333 9.333-9.333c5.155 0 9.333 4.179 9.333 9.333 0 2.096-0.691 4.031-1.858 5.59z" />
      </symbol>
      <symbol id="icon-filter" viewBox="0 0 32 32">
        <path d="M6.667 6.667h18.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-18.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM14.667 22.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-2.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM10.667 14.667h10.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-10.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333z" />
      </symbol>
      <symbol id="icon-square-o" viewBox="0 0 32 32">
        <path d="M8 6.667c-0.736 0-1.333 0.597-1.333 1.333v16c0 0.736 0.597 1.333 1.333 1.333h16c0.736 0 1.333-0.597 1.333-1.333v-16c0-0.736-0.597-1.333-1.333-1.333h-16zM8 4h16c2.209 0 4 1.791 4 4v16c0 2.209-1.791 4-4 4h-16c-2.209 0-4-1.791-4-4v-16c0-2.209 1.791-4 4-4z" />
      </symbol>
      <symbol id="icon-square-checked" viewBox="0 0 32 32">
        <path d="M8 4h16c2.209 0 4 1.791 4 4v16c0 2.209-1.791 4-4 4h-16c-2.209 0-4-1.791-4-4v-16c0-2.209 1.791-4 4-4zM20.419 11.724l-7.057 7.057-2.419-2.419c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l3.362 3.362c0.521 0.521 1.365 0.521 1.886 0l8-8c0.521-0.521 0.521-1.365 0-1.886s-1.365-0.521-1.886 0z" />
      </symbol>
      <symbol id="icon-user" viewBox="0 0 32 32">
        <path d="M28 28h-24c0-6.3 4.647-9.333 12-9.333s12 3.033 12 9.333zM16 16c-2.933 0-5.333-2.4-5.333-5.333s2.4-5.333 5.333-5.333c2.933 0 5.333 2.4 5.333 5.333s-2.4 5.333-5.333 5.333z" />
      </symbol>
      <symbol id="icon-checkbox-mixed" viewBox="0 0 32 32">
        <path d="M8 4h16c2.209 0 4 1.791 4 4v16c0 2.209-1.791 4-4 4h-16c-2.209 0-4-1.791-4-4v-16c0-2.209 1.791-4 4-4zM21.333 17.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-10.667c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h10.667z" />
      </symbol>
      <symbol id="icon-circle-checked" viewBox="0 0 32 32">
        <path d="M16 25.333c5.155 0 9.333-4.179 9.333-9.333s-4.179-9.333-9.333-9.333c-5.155 0-9.333 4.179-9.333 9.333s4.179 9.333 9.333 9.333zM16 28c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12zM16 21.333c2.946 0 5.333-2.388 5.333-5.333s-2.388-5.333-5.333-5.333c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333z" />
      </symbol>
      <symbol id="icon-circle-o" viewBox="0 0 32 32">
        <path d="M16 26.37c5.727 0 10.37-4.643 10.37-10.37s-4.643-10.37-10.37-10.37c-5.727 0-10.37 4.643-10.37 10.37s4.643 10.37 10.37 10.37zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333z" />
      </symbol>
      <symbol id="icon-stepper-active" viewBox="0 0 32 32">
        <path d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333s-5.97-13.333-13.333-13.333c-7.364 0-13.333 5.97-13.333 13.333s5.97 13.333 13.333 13.333zM16 28c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12zM24 16c0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.418 3.582-8 8-8s8 3.582 8 8z" />
      </symbol>
      <symbol id="icon-stepper-normal" viewBox="0 0 32 32">
        <path d="M21.333 16c0 2.946-2.388 5.333-5.333 5.333s-5.333-2.388-5.333-5.333c0-2.946 2.388-5.333 5.333-5.333s5.333 2.388 5.333 5.333z" />
      </symbol>
      <symbol id="icon-info-o" viewBox="0 0 32 32">
        <path d="M16 26.667c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333zM16 14c-0.736 0-1.333 0.597-1.333 1.333v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333zM16 9.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-up-round" viewBox="0 0 30 32">
        <path d="M13.736 14.457l-4.679 4.679c-0.555 0.555-1.455 0.555-2.010 0s-0.555-1.455 0-2.010l7.105-7.105c0.555-0.555 1.455-0.555 2.010 0l7.105 7.105c0.555 0.555 0.555 1.455 0 2.010s-1.455 0.555-2.010 0l-4.679-4.679v13.622c0 0.785-0.636 1.421-1.421 1.421s-1.421-0.636-1.421-1.421v-13.622zM3.789 2.5h22.737c0.785 0 1.421 0.636 1.421 1.421s-0.636 1.421-1.421 1.421h-22.737c-0.785 0-1.421-0.636-1.421-1.421s0.636-1.421 1.421-1.421z" />
      </symbol>
      <symbol id="icon-timezone" viewBox="0 0 32 32">
        <path d="M9.781 18.667c-0.152-1.756-0.152-3.577 0-5.333h-4.111c-0.219 0.852-0.336 1.746-0.336 2.667s0.117 1.814 0.336 2.667h4.111zM12.437 18.667h7.126c0.138-1.744 0.138-3.589 0-5.333h-7.126c-0.138 1.744-0.138 3.589 0 5.333zM22.219 18.667h4.111c0.219-0.852 0.336-1.746 0.336-2.667s-0.117-1.814-0.336-2.667h-4.111c0.152 1.756 0.152 3.577 0 5.333zM11.47 25.66c-0.586-1.241-1.030-2.722-1.33-4.327h-3.38c1.088 1.88 2.733 3.398 4.71 4.327zM20.53 25.66c1.977-0.929 3.622-2.446 4.71-4.327h-3.38c-0.3 1.605-0.744 3.086-1.33 4.327zM19.224 21.333h-6.449c0.554 3.061 1.629 5.333 3.224 5.333s2.67-2.272 3.224-5.333zM10.14 10.667c0.3-1.605 0.744-3.086 1.33-4.327-1.977 0.929-3.622 2.446-4.71 4.327h3.38zM12.776 10.667h6.449c-0.554-3.061-1.629-5.333-3.224-5.333s-2.67 2.272-3.224 5.333zM21.86 10.667h3.38c-1.088-1.88-2.733-3.398-4.71-4.327 0.586 1.24 1.030 2.722 1.33 4.327zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333z" />
      </symbol>
      <symbol id="icon-arrow-up-long" viewBox="0 0 32 32">
        <path d="M14.667 11.219v12.781c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-12.781l4.39 4.39c0.521 0.521 1.365 0.521 1.886 0s0.521-1.365 0-1.886l-6.667-6.667c-0.521-0.521-1.365-0.521-1.886 0l-6.667 6.667c-0.521 0.521-0.521 1.365 0 1.886s1.365 0.521 1.886 0l4.391-4.391z" />
      </symbol>
      <symbol id="icon-spinner" viewBox="0 0 32 32">
        <path d="M4 16c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333c0 2.51 0.994 4.86 2.734 6.6s4.089 2.734 6.6 2.734c5.155 0 9.333-4.179 9.333-9.333s-4.179-9.333-9.333-9.333c-1.243 0-2.45 0.242-3.572 0.708-0.68 0.282-1.46-0.041-1.742-0.721s0.041-1.46 0.721-1.742c1.444-0.599 2.998-0.911 4.594-0.911 6.627 0 12 5.373 12 12s-5.373 12-12 12c-3.225 0-6.25-1.28-8.485-3.515s-3.515-5.261-3.515-8.485z" />
      </symbol>
      <symbol id="icon-grid" viewBox="0 0 32 32">
        <path d="M13.333 17.333c0.736 0 1.333 0.597 1.333 1.333v6.667c0 0.736-0.597 1.333-1.333 1.333h-6.667c-0.736 0-1.333-0.597-1.333-1.333v-6.667c0-0.736 0.597-1.333 1.333-1.333h6.667zM25.333 17.333c0.736 0 1.333 0.597 1.333 1.333v6.667c0 0.736-0.597 1.333-1.333 1.333h-6.667c-0.736 0-1.333-0.597-1.333-1.333v-6.667c0-0.736 0.597-1.333 1.333-1.333h6.667zM13.333 5.333c0.736 0 1.333 0.597 1.333 1.333v6.667c0 0.736-0.597 1.333-1.333 1.333h-6.667c-0.736 0-1.333-0.597-1.333-1.333v-6.667c0-0.736 0.597-1.333 1.333-1.333h6.667zM25.333 5.333c0.736 0 1.333 0.597 1.333 1.333v6.667c0 0.736-0.597 1.333-1.333 1.333h-6.667c-0.736 0-1.333-0.597-1.333-1.333v-6.667c0-0.736 0.597-1.333 1.333-1.333h6.667z" />
      </symbol>
      <symbol id="icon-back-arrow" viewBox="0 0 32 32">
        <path d="M6.69 9.333h15.976c4.602 0 8.333 3.731 8.333 8.333v11.667c0 0.92-0.746 1.667-1.667 1.667s-1.667-0.746-1.667-1.667v-11.667c0-2.761-2.239-5-5-5h-15.976l5.488 5.488c0.651 0.651 0.651 1.706 0 2.357s-1.706 0.651-2.357 0l-8.333-8.333c-0.651-0.651-0.651-1.706 0-2.357l8.333-8.333c0.651-0.651 1.706-0.651 2.357 0s0.651 1.706 0 2.357l-5.488 5.488z" />
      </symbol>
      <symbol id="icon-download" viewBox="0 0 32 32">
        <path d="M28 20c0.736 0 1.333 0.597 1.333 1.333v4.667c0 1.841-1.492 3.333-3.333 3.333h-20c-1.841 0-3.333-1.492-3.333-3.333v-4.667c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333v4.667c0 0.368 0.298 0.667 0.667 0.667h20c0.368 0 0.667-0.298 0.667-0.667v-4.667c0-0.736 0.597-1.333 1.333-1.333zM16.869 21.009l0.004-0.002 0.032-0.030-0.036 0.032zM16.743 21.108c-0.019 0.013-0.039 0.025-0.059 0.037l-0.112 0.060c-0.034 0.016-0.070 0.031-0.107 0.045l-0.067 0.023c-0.126 0.040-0.26 0.061-0.398 0.061s-0.27-0.021-0.395-0.060c-0.026-0.008-0.052-0.017-0.078-0.027-0.035-0.013-0.070-0.028-0.103-0.044-0.031-0.015-0.062-0.031-0.091-0.048-0.025-0.015-0.050-0.030-0.075-0.047-0.032-0.021-0.063-0.044-0.093-0.068-0.024-0.020-0.048-0.040-0.070-0.061l-0.018-0.017-6.685-6.685c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l4.391 4.39v-12.781c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333l-0.001 12.779 4.391-4.388c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-6.764 6.755c-0.033 0.028-0.068 0.053-0.103 0.077z" />
      </symbol>
      <symbol id="icon-right-arrow" viewBox="0 0 32 32">
        <path d="M20.781 17.333h-12.781c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h12.781l-4.391-4.391c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l6.667 6.667c0.521 0.521 0.521 1.365 0 1.886l-6.667 6.667c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l4.391-4.391z" />
      </symbol>
      <symbol id="icon-video-off" viewBox="0 0 32 32">
        <path d="M28.257 3.743c0.521 0.521 0.521 1.365 0 1.886l-4.306 4.308c0.033 0.239 0.050 0.482 0.050 0.73v10.667c0 2.946-2.388 5.333-5.333 5.333l-11.449-0-2.531 2.533c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l23.57-23.57c0.521-0.521 1.365-0.521 1.886 0zM31.733 8.533c0.173 0.231 0.267 0.511 0.267 0.8v13.333c0 0.736-0.597 1.333-1.333 1.333-0.288 0-0.569-0.094-0.8-0.267l-3.2-2.4v-10.667l3.2-2.4c0.589-0.442 1.425-0.322 1.867 0.267zM18.667 5.333c0.16 0 0.318 0.007 0.475 0.021l-18.506 18.506c-0.405-0.752-0.636-1.613-0.636-2.527v-10.667c0-2.946 2.388-5.333 5.333-5.333h13.333z" />
      </symbol>
      <symbol id="icon-video" viewBox="0 0 32 32">
        <path d="M18.667 5.333c2.946 0 5.333 2.388 5.333 5.333v10.667c0 2.946-2.388 5.333-5.333 5.333h-13.333c-2.946 0-5.333-2.388-5.333-5.333v-10.667c0-2.946 2.388-5.333 5.333-5.333h13.333zM31.733 8.533c0.173 0.231 0.267 0.511 0.267 0.8v13.333c0 0.736-0.597 1.333-1.333 1.333-0.288 0-0.569-0.094-0.8-0.267l-3.2-2.4v-10.667l3.2-2.4c0.589-0.442 1.425-0.322 1.867 0.267z" />
      </symbol>
      <symbol id="icon-stop" viewBox="0 0 32 32">
        <path d="M8.752 5.333h14.496c1.189 0 1.62 0.124 2.054 0.356s0.776 0.573 1.008 1.008c0.232 0.435 0.356 0.866 0.356 2.054v14.496c0 1.189-0.124 1.62-0.356 2.054s-0.573 0.776-1.008 1.008c-0.435 0.232-0.866 0.356-2.054 0.356h-14.496c-1.189 0-1.62-0.124-2.054-0.356s-0.776-0.573-1.008-1.008c-0.232-0.435-0.356-0.866-0.356-2.054v-14.496c0-1.189 0.124-1.62 0.356-2.054s0.573-0.776 1.008-1.008c0.435-0.232 0.866-0.356 2.054-0.356z" />
      </symbol>
      <symbol id="icon-settings" viewBox="0 0 32 32">
        <path d="M13.037 2.667h5.926l0.79 3.878 2.732 1.571 3.885-1.282 2.963 5-2.996 2.564v3.205l2.996 2.564-2.963 5-3.885-1.282-2.732 1.57-0.79 3.878h-5.926l-0.79-3.878-2.732-1.57-3.885 1.282-2.963-5 2.996-2.564v-3.205l-2.996-2.564 2.963-5 3.885 1.282 2.732-1.571 0.79-3.878zM16 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4z" />
      </symbol>
      <symbol id="icon-screen-swap" viewBox="0 0 32 32">
        <path d="M9.333 12v2.667l-0.807 0.001-0.407 0.013c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v6.948l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.249 0.133 0.494 0.188 1.2 0.197h6.948l0.407-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-0.807h2.668v0.581c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-6.496c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v-6.496c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514h0.582zM24.581 4c1.189 0 1.62 0.124 2.054 0.356s0.776 0.573 1.008 1.008c0.232 0.435 0.356 0.866 0.356 2.054v9.163c0 1.189-0.124 1.62-0.356 2.054s-0.573 0.776-1.008 1.008c-0.435 0.232-0.866 0.356-2.054 0.356h-9.163c-1.189 0-1.62-0.124-2.054-0.356s-0.776-0.573-1.008-1.008c-0.232-0.435-0.356-0.866-0.356-2.054v-9.163c0-1.189 0.124-1.62 0.356-2.054s0.573-0.776 1.008-1.008c0.435-0.232 0.866-0.356 2.054-0.356h9.163z" />
      </symbol>
      <symbol id="icon-screen-split-off" viewBox="0 0 32 32">
        <path d="M24.581 2.667c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-4.581v2.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h2.667v-2.667h-4.582c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514h17.163zM24.807 5.335l-18.022 0.013c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v12.281l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.249 0.133 0.494 0.188 1.2 0.197l18.022-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-12.281l-0.013-0.407c-0.024-0.407-0.080-0.598-0.184-0.792-0.108-0.202-0.259-0.353-0.461-0.461-0.249-0.133-0.494-0.188-1.2-0.197zM22.291 8c0.594 0 0.81 0.062 1.027 0.178s0.388 0.287 0.504 0.504c0.116 0.217 0.178 0.433 0.178 1.027v3.248c0 0.594-0.062 0.81-0.178 1.027s-0.287 0.388-0.504 0.504c-0.217 0.116-0.433 0.178-1.027 0.178h-5.915c-0.594 0-0.81-0.062-1.027-0.178s-0.388-0.287-0.504-0.504c-0.116-0.217-0.178-0.433-0.178-1.027v-3.248c0-0.594 0.062-0.81 0.178-1.027s0.287-0.388 0.504-0.504c0.217-0.116 0.433-0.178 1.027-0.178h5.915z" />
      </symbol>
      <symbol id="icon-screen-split" viewBox="0 0 32 32">
        <path d="M24.581 2.667c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-4.581v2.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h2.667v-2.667h-4.582c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514h17.163zM17.333 17.333v3.999h7.474l0.407-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-12.281l-0.013-0.407c-0.024-0.407-0.080-0.598-0.184-0.792-0.108-0.202-0.259-0.353-0.461-0.461-0.249-0.133-0.494-0.188-1.2-0.197l-7.474-0.001v12zM14.667 9.333v-4l-7.474 0.001-0.407 0.013c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v12.281l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.249 0.133 0.494 0.188 1.2 0.197h7.474v-11.999z" />
      </symbol>
      <symbol id="icon-screen-share-off" viewBox="0 0 32 32">
        <path d="M29.198 7.089c0.097 0.444 0.136 0.965 0.136 1.663v11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-4.582v2.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h2.667v-2.667h-1.049l2.668-2.668h11.189l0.407-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-11.187l2.532-2.531zM29.199 1.467c0.521 0.521 0.521 1.365 0 1.886l-24.513 24.513c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l1.459-1.461c-0.447-0.331-0.812-0.755-1.079-1.254-0.382-0.713-0.514-1.398-0.514-2.683v-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514l17.36 0.001 2.536-2.533c0.521-0.521 1.365-0.521 1.886 0zM22.111 6.667l-15.326 0.014c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v12.281l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.071 0.038 0.142 0.070 0.223 0.096l15.895-15.896z" />
      </symbol>
      <symbol id="icon-screen-share" viewBox="0 0 32 32">
        <path d="M7.418 2.667h0.582c0.736 0 1.333 0.597 1.333 1.333 0 0.684-0.515 1.247-1.178 1.324l-0.155 0.009-0.807 0.001-0.407 0.013c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v12.281l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.249 0.133 0.494 0.188 1.2 0.197l18.022-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-12.281l-0.013-0.407c-0.024-0.407-0.080-0.598-0.184-0.792-0.108-0.202-0.259-0.353-0.461-0.461-0.249-0.133-0.494-0.188-1.2-0.197l-0.807-0.001-0.155-0.009c-0.663-0.077-1.178-0.641-1.178-1.324s0.515-1.247 1.178-1.324l0.155-0.009h0.581c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-4.581v2.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h2.667v-2.667h-4.582c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514h0.582zM16.080 2.67l0.164 0.022 0.17 0.043 0.139 0.054 0.153 0.082c0.072 0.044 0.139 0.095 0.202 0.152l4.034 4.036c0.521 0.521 0.521 1.365 0 1.886-0.481 0.481-1.237 0.518-1.76 0.111l-0.126-0.111-1.724-1.723v6.113c0 0.736-0.597 1.333-1.333 1.333-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-6.114l-1.724 1.724c-0.521 0.521-1.365 0.521-1.886 0-0.481-0.481-0.518-1.237-0.111-1.76l0.111-0.126 4-4c0.297-0.297 0.668-0.414 1.023-0.39z" />
      </symbol>
      <symbol id="icon-play" viewBox="0 0 32 32">
        <path d="M26.064 17.050l-16.258 9.454c-0.576 0.335-1.313 0.136-1.645-0.444-0.106-0.184-0.161-0.394-0.161-0.606v-18.907c0-0.67 0.539-1.213 1.204-1.213 0.211 0 0.419 0.056 0.602 0.162l16.258 9.454c0.576 0.335 0.773 1.077 0.441 1.657-0.106 0.184-0.258 0.337-0.441 0.444z" />
      </symbol>
      <symbol id="icon-microphone-off" viewBox="0 0 32 32">
        <path d="M28.452 3.272c0.521 0.521 0.521 1.365 0 1.886l-5.786 5.785 0.001 5.057c0 3.682-2.985 6.667-6.667 6.667-1.43 0-2.755-0.45-3.841-1.217l-0.955 0.954c1.336 1.002 2.996 1.596 4.795 1.596 4.318 0 7.837-3.421 7.994-7.7l0.006-0.3v-2.667h1.333v2.667c0 4.701-3.476 8.591-7.999 9.239l-0.001 2.761h5.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h5.333v-2.761c-1.649-0.236-3.159-0.903-4.412-1.883l-4.431 4.429c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l22.627-22.627c0.521-0.521 1.365-0.521 1.886 0zM8 13.333v2.667c0 0.654 0.078 1.289 0.226 1.897l-1.076 1.076c-0.314-0.934-0.484-1.934-0.484-2.973v-2.667h1.333zM16 1.333c2.385 0 4.477 1.252 5.655 3.134l-12.28 12.281c-0.027-0.246-0.042-0.496-0.042-0.749v-8c0-3.682 2.985-6.667 6.667-6.667z" />
      </symbol>
      <symbol id="icon-microphone" viewBox="0 0 32 32">
        <path d="M8 13.333v2.667c0 4.418 3.582 8 8 8 4.318 0 7.837-3.421 7.994-7.7l0.006-0.3v-2.667h1.333v2.667c0 4.701-3.476 8.591-7.999 9.239l-0.001 2.761h5.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h5.333v-2.761c-4.523-0.647-8-4.537-8-9.239v-2.667h1.333zM16 1.333c3.682 0 6.667 2.985 6.667 6.667v8c0 3.682-2.985 6.667-6.667 6.667s-6.667-2.985-6.667-6.667v-8c0-3.682 2.985-6.667 6.667-6.667z" />
      </symbol>
      <symbol id="icon-call-end" viewBox="0 0 32 32">
        <path d="M8.676 21.326c1.128-0.007 1.836-0.723 1.84-1.86 0.004-0.866 0.007-1.732-0.004-2.598-0.004-0.238 0.069-0.362 0.289-0.424 0.43-0.128 0.849-0.303 1.283-0.406 2.031-0.468 4.084-0.504 6.144-0.292 1.023 0.106 2.035 0.303 3 0.702 0.195 0.080 0.275 0.183 0.275 0.409-0.014 0.855-0.007 1.71-0.004 2.569 0.004 1.195 0.698 1.896 1.887 1.9l2.020 0.004c0.694 0 1.388 0.007 2.082 0 1.12-0.015 1.829-0.731 1.836-1.863l-0.003-1.734c0-0.289 0.003-0.578 0.010-0.868 0.022-0.804-0.246-1.491-0.755-2.094-0.831-0.979-1.901-1.615-3.040-2.137-2.349-1.089-4.84-1.648-7.409-1.871-3.473-0.303-6.86 0.102-10.153 1.253-1.482 0.519-2.888 1.187-4.073 2.262-0.817 0.738-1.294 1.615-1.229 2.77 0.043 0.789 0.004 1.589 0.007 2.382 0.007 1.177 0.712 1.896 1.865 1.9 1.377 0.004 2.754 0 4.131-0.004z" />
      </symbol>
      <symbol id="icon-broken-connection" viewBox="0 0 32 32">
        <path d="M23.542 23.542c0.242-0.242 0.623-0.259 0.885-0.052l0.058 0.052 1.886 1.886c0.26 0.26 0.26 0.682 0 0.943-0.242 0.242-0.623 0.259-0.885 0.052l-0.058-0.052-1.886-1.886c-0.26-0.26-0.26-0.682 0-0.943zM25.651 21.123l0.076 0.016 2.576 0.69c0.356 0.095 0.567 0.461 0.471 0.817-0.088 0.33-0.41 0.536-0.74 0.487l-0.076-0.016-2.576-0.69c-0.356-0.095-0.567-0.461-0.471-0.817 0.088-0.33 0.41-0.536 0.74-0.487zM21.611 24.91c0.356-0.095 0.721 0.116 0.817 0.471l0.69 2.576c0.095 0.356-0.116 0.721-0.471 0.817s-0.721-0.116-0.817-0.471l-0.69-2.576c-0.095-0.356 0.116-0.721 0.471-0.817zM15.057 5.629c3.124-3.124 8.19-3.124 11.314 0s3.124 8.19 0 11.314l-2.828 2.828c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l2.828-2.828c2.083-2.083 2.083-5.46 0-7.542s-5.46-2.083-7.542 0l-2.828 2.828c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l2.828-2.828zM8.458 12.229c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-2.828 2.828c-2.083 2.083-2.083 5.46 0 7.542s5.46 2.083 7.542 0l2.828-2.828c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-2.828 2.828c-3.124 3.124-8.19 3.124-11.314 0s-3.124-8.189 0-11.314l2.828-2.828zM18.828 11.286c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-7.542 7.542c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l7.542-7.542zM9.354 3.226c0.356-0.095 0.721 0.116 0.816 0.471l0.69 2.576c0.095 0.356-0.116 0.721-0.471 0.816s-0.721-0.116-0.816-0.471l-0.69-2.576c-0.095-0.356 0.116-0.721 0.471-0.817zM3.966 8.867l0.076 0.016 2.576 0.69c0.356 0.095 0.567 0.461 0.471 0.816-0.088 0.33-0.41 0.536-0.74 0.487l-0.076-0.016-2.576-0.69c-0.356-0.095-0.567-0.461-0.471-0.816 0.088-0.33 0.41-0.536 0.74-0.487zM5.629 5.629c0.26-0.26 0.682-0.26 0.943 0l1.886 1.886c0.26 0.26 0.26 0.682 0 0.943s-0.682 0.26-0.943 0l-1.886-1.886c-0.26-0.26-0.26-0.682 0-0.943z" />
      </symbol>
      <symbol id="icon-link" viewBox="0 0 32 32">
        <path d="M18.667 10.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h4c4.418 0 8 3.582 8 8s-3.582 8-8 8h-4c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h4c2.946 0 5.333-2.388 5.333-5.333s-2.388-5.333-5.333-5.333h-4zM13.333 21.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-4c-4.418 0-8-3.582-8-8s3.582-8 8-8h4c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-4c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333h4zM10.667 17.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h10.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-10.667z" />
      </symbol>
      <symbol id="icon-record" viewBox="0 0 32 32">
        <path d="M16 2.667c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333zM16 5.333c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667zM16 8c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8z" />
      </symbol>
      <symbol id="icon-chat" viewBox="0 0 32 32">
        <path d="M25.333 2.667h-18.667c-2.13 0-3.872 1.665-3.993 3.765l-0.007 0.235v21.333c0.003 0.538 0.329 1.022 0.827 1.227 0.158 0.075 0.332 0.111 0.507 0.107 0.295 0.002 0.581-0.095 0.814-0.271l0.133-0.115 4.933-4.947h15.453c2.13 0 3.872-1.665 3.993-3.765l0.007-0.235v-13.333c0-2.13-1.665-3.872-3.765-3.993l-0.235-0.007h-18.667zM9.333 14.667h13.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM9.333 9.333h13.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333z" />
      </symbol>
      <symbol id="icon-video-file" viewBox="0 0 32 32">
        <path d="M29.318 13.813c-0.010-0.525-0.052-1.189-0.126-1.991s-0.181-1.519-0.32-2.151c-0.159-0.71-0.504-1.308-1.034-1.794s-1.148-0.768-1.853-0.846c-2.203-0.243-5.531-0.364-9.985-0.364s-7.783 0.122-9.985 0.364c-0.704 0.078-1.32 0.36-1.845 0.846s-0.868 1.084-1.027 1.794c-0.149 0.632-0.26 1.349-0.335 2.151s-0.116 1.465-0.126 1.991c-0.010 0.525-0.015 1.254-0.015 2.188s0.005 1.663 0.015 2.188c0.010 0.525 0.052 1.189 0.126 1.99s0.181 1.519 0.32 2.151c0.159 0.71 0.503 1.308 1.034 1.794s1.149 0.768 1.853 0.846c2.202 0.243 5.531 0.365 9.985 0.365s7.783-0.122 9.985-0.365c0.705-0.078 1.32-0.36 1.845-0.846s0.868-1.084 1.027-1.794c0.149-0.632 0.26-1.349 0.335-2.151s0.116-1.465 0.126-1.99c0.010-0.525 0.015-1.254 0.015-2.188s-0.005-1.662-0.015-2.188zM19.688 16.565l-5.333 3.331c-0.097 0.069-0.215 0.104-0.354 0.104-0.104 0-0.212-0.028-0.323-0.083-0.229-0.125-0.344-0.319-0.344-0.583v-6.662c0-0.264 0.115-0.458 0.344-0.583 0.236-0.125 0.462-0.118 0.677 0.021l5.333 3.331c0.208 0.118 0.312 0.305 0.312 0.562s-0.104 0.444-0.312 0.562z" />
      </symbol>
      <symbol id="icon-picture" viewBox="0 0 32 32">
        <path d="M26.319 2.667h-20.636c-1.663 0-3.016 1.37-3.016 3.058v19.221c0 1.686 1.353 3.055 3.016 3.055h20.636c1.663 0 3.015-1.369 3.015-3.055v-19.221c0-1.688-1.351-3.058-3.015-3.058zM21.333 6.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667zM24.55 24h-17.009c-0.747 0-1.079-0.593-0.743-1.324l4.64-10.082c0.336-0.731 0.977-0.796 1.431-0.146l4.665 6.689c0.454 0.651 1.247 0.706 1.772 0.123l1.141-1.268c0.524-0.583 1.297-0.511 1.725 0.16l2.955 4.631c0.427 0.672 0.169 1.216-0.577 1.216z" />
      </symbol>
      <symbol id="icon-pdf" viewBox="0 0 32 32">
        <path d="M27.93 16c0.72 0 1.313 0.643 1.394 1.472l0.009 0.194v10c0 0.855-0.542 1.559-1.24 1.655l-0.164 0.011h-23.86c-0.72 0-1.313-0.643-1.394-1.472l-0.009-0.194v-10c0-0.855 0.542-1.559 1.24-1.655l0.164-0.011h23.86zM9.323 18.639l-2.656 0.027v8h1.333v-2.667h1.333c1.471 0 2.654-0.884 2.667-2.667 0.012-1.701-1.098-2.609-2.479-2.688l-0.199-0.006zM16 18.667h-1.333v8h1.333c1.94 0 4-1.333 4-4 0-2.571-1.915-3.903-3.792-3.995l-0.208-0.005zM26.667 18.667h-4v8h1.333v-2.667h2.667v-1.333h-2.667v-2.667h2.667v-1.333zM16 20c1.471 0 2.667 1.333 2.667 2.667 0 1.273-1.090 2.545-2.468 2.659l-0.199 0.008v-5.333zM9.289 20c0.76 0 1.378 0.598 1.378 1.333 0 0.686-0.538 1.253-1.228 1.326l-0.15 0.008h-1.289v-2.667h1.289zM20.893 2.666l5.773 5.773v4.894h-21.333v-9.333c0-0.684 0.515-1.247 1.178-1.324l0.155-0.009 14.227-0.001zM18.667 4v5.333c0 0.684 0.515 1.247 1.178 1.324l0.155 0.009h5.333l-6.667-6.667z" />
      </symbol>
      <symbol id="icon-expand" viewBox="0 0 32 32">
        <path d="M26.56 6.16c-0.135-0.326-0.394-0.585-0.719-0.72-0.16-0.068-0.332-0.105-0.506-0.107h-5.329c-0.736 0-1.332 0.597-1.332 1.333s0.596 1.333 1.332 1.333h2.118l-4.396 4.379c-0.252 0.25-0.394 0.591-0.394 0.947s0.142 0.696 0.394 0.947c0.25 0.252 0.591 0.394 0.946 0.394s0.696-0.142 0.946-0.394l4.383-4.392v2.12c0 0.736 0.596 1.333 1.332 1.333s1.332-0.597 1.332-1.333v-5.333c-0.002-0.174-0.038-0.346-0.107-0.507zM5.44 25.84c0.135 0.326 0.394 0.585 0.719 0.72 0.16 0.068 0.332 0.105 0.506 0.107h5.329c0.736 0 1.332-0.597 1.332-1.333s-0.596-1.333-1.332-1.333h-2.118l4.396-4.379c0.252-0.25 0.394-0.591 0.394-0.947s-0.142-0.696-0.394-0.947c-0.25-0.252-0.591-0.394-0.946-0.394s-0.696 0.142-0.946 0.394l-4.383 4.392v-2.12c0-0.736-0.596-1.333-1.332-1.333s-1.332 0.597-1.332 1.333v5.333c0.002 0.174 0.038 0.346 0.107 0.507z" />
      </symbol>
      <symbol id="icon-attachment" viewBox="0 0 32 32">
        <path d="M22.218 18.448c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-5.735 5.735c-2.982 2.982-7.817 2.982-10.799 0s-2.982-7.817 0-10.799l9.648-9.648c2.162-2.162 5.666-2.162 7.828 0s2.162 5.666-0 7.828l-9.658 9.647c-1.341 1.341-3.516 1.341-4.857 0s-1.341-3.516 0.001-4.857l5.806-5.799c0.521-0.52 1.365-0.52 1.886 0.001s0.52 1.365-0.001 1.886c-2.258 2.255-2.258 2.255-5.806 5.799-0.3 0.3-0.3 0.786 0 1.085s0.786 0.3 1.086-0l9.658-9.647c1.12-1.12 1.12-2.936 0-4.056s-2.936-1.12-4.056 0l-9.648 9.648c-1.941 1.941-1.941 5.087 0 7.027s5.087 1.941 7.027 0l5.735-5.735z" />
      </symbol>
      <symbol id="icon-shrink" viewBox="0 0 32 32">
        <path d="M17.44 13.84c0.135 0.326 0.394 0.585 0.719 0.72 0.16 0.068 0.332 0.105 0.506 0.107h5.329c0.736 0 1.332-0.597 1.332-1.333s-0.596-1.333-1.332-1.333h-2.118l4.396-4.379c0.252-0.25 0.394-0.591 0.394-0.947s-0.142-0.696-0.394-0.947c-0.25-0.252-0.591-0.394-0.946-0.394s-0.696 0.142-0.946 0.394l-4.383 4.392v-2.12c0-0.736-0.596-1.333-1.332-1.333s-1.332 0.597-1.332 1.333v5.333c0.002 0.174 0.038 0.346 0.107 0.507zM14.56 18.16c-0.135-0.326-0.394-0.585-0.719-0.72-0.16-0.068-0.332-0.105-0.506-0.107h-5.329c-0.736 0-1.332 0.597-1.332 1.333s0.596 1.333 1.332 1.333h2.118l-4.396 4.379c-0.252 0.25-0.394 0.591-0.394 0.947s0.142 0.696 0.394 0.947c0.25 0.252 0.591 0.394 0.946 0.394s0.696-0.142 0.946-0.394l4.383-4.392v2.12c0 0.736 0.596 1.333 1.332 1.333s1.332-0.597 1.332-1.333v-5.333c-0.002-0.174-0.038-0.346-0.107-0.507z" />
      </symbol>
      <symbol id="icon-checked" viewBox="0 0 32 32">
        <path d="M23.057 9.724c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-12 12c-0.521 0.521-1.365 0.521-1.886 0l-5.333-5.333c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l4.391 4.391 11.057-11.057z" />
      </symbol>
      <symbol id="icon-location" viewBox="0 0 32 32">
        <path d="M16 1.333c6.627 0 12 5.373 12 12 0 5.988-3.705 11.565-11.115 16.73l-0.511 0.352c-0.226 0.154-0.523 0.154-0.749 0-7.75-5.263-11.625-10.957-11.625-17.081 0-6.627 5.373-12 12-12zM16 9.333c-2.209 0-4 1.791-4 4s1.791 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4z" />
      </symbol>
      <symbol id="icon-live-preview" viewBox="0 0 32 32">
        <path d="M7.418 24c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514h17.163c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514h-4.581v2.667h2.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-13.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h2.667v-2.667h-4.582zM24.807 5.335l-18.022 0.013c-0.407 0.024-0.598 0.080-0.792 0.184-0.202 0.108-0.353 0.259-0.461 0.461-0.133 0.249-0.188 0.494-0.197 1.2v12.281l0.013 0.407c0.024 0.407 0.080 0.598 0.184 0.792 0.108 0.202 0.259 0.353 0.461 0.461 0.249 0.133 0.494 0.188 1.2 0.197l18.022-0.013c0.407-0.024 0.598-0.080 0.792-0.184 0.202-0.108 0.353-0.259 0.461-0.461 0.133-0.249 0.188-0.494 0.197-1.2v-12.281l-0.013-0.407c-0.024-0.407-0.080-0.598-0.184-0.792-0.108-0.202-0.259-0.353-0.461-0.461-0.249-0.133-0.494-0.188-1.2-0.197z" />
      </symbol>
      <symbol id="icon-copy" viewBox="0 0 32 32">
        <path d="M6.667 8c0.736 0 1.333 0.597 1.333 1.333l-0.001 17.332 13.334 0.001c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-14.4c-0.884 0-1.6-0.716-1.6-1.6v-18.4c0-0.736 0.597-1.333 1.333-1.333zM21.056 2.718c0.158 0.037 0.299 0.095 0.436 0.181 0.115 0.072 0.212 0.147 0.41 0.339l4.063 4.061c0.292 0.292 0.383 0.404 0.469 0.542s0.144 0.278 0.181 0.436c0.037 0.158 0.051 0.301 0.051 0.714v10.906c0 1.426-0.149 1.944-0.427 2.465s-0.688 0.931-1.21 1.21c-0.521 0.279-1.039 0.427-2.465 0.427h-7.796c-1.426 0-1.944-0.149-2.465-0.427s-0.931-0.688-1.21-1.21c-0.279-0.521-0.427-1.039-0.427-2.465v-13.129c0-1.426 0.149-1.944 0.427-2.465s0.688-0.931 1.21-1.21c0.521-0.279 1.039-0.427 2.465-0.427h5.573c0.413 0 0.556 0.015 0.714 0.051zM18.667 5.333h-3.282c-0.713 0-0.972 0.074-1.233 0.214s-0.465 0.344-0.605 0.605c-0.122 0.228-0.194 0.455-0.21 0.986l-0.003 0.247v11.898c0 0.713 0.074 0.972 0.214 1.233s0.344 0.465 0.605 0.605c0.228 0.122 0.455 0.194 0.986 0.21l0.247 0.003h6.564c0.713 0 0.972-0.074 1.233-0.214s0.465-0.344 0.605-0.605c0.122-0.228 0.194-0.455 0.21-0.986l0.003-0.247v-8.616h-2.133c-1.767 0-3.2-1.433-3.2-3.2v-2.133z" />
      </symbol>
      <symbol id="icon-arrow-left-long" viewBox="0 0 32 32">
        <path d="M15.609 8.391c0.521 0.521 0.521 1.365 0 1.886l-4.391 4.389 12.781 0.001c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333l-12.783-0.001 4.392 4.391c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-6.686-6.686c-0.028-0.029-0.054-0.059-0.079-0.090l0.098 0.109c-0.049-0.049-0.093-0.1-0.132-0.154-0.022-0.030-0.042-0.060-0.061-0.091-0.011-0.018-0.021-0.035-0.031-0.054-0.105-0.191-0.166-0.411-0.166-0.644s0.060-0.454 0.166-0.645c0.010-0.018 0.020-0.035 0.031-0.053 0.019-0.031 0.040-0.061 0.061-0.090 0.012-0.016 0.023-0.031 0.035-0.046 0.025-0.031 0.051-0.061 0.079-0.089 0.006-0.006 0.012-0.013 0.019-0.019l6.667-6.667c0.521-0.521 1.365-0.521 1.886 0z" />
      </symbol>
      <symbol id="icon-time-fill" viewBox="0 0 32 32">
        <path d="M16 2.667c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333zM16 8c-0.736 0-1.333 0.597-1.333 1.333v0 6.667c0 0.736 0.597 1.333 1.333 1.333v0h6.667c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333v0h-5.333v-5.333c0-0.736-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-list-checked" viewBox="0 0 32 32">
        <path d="M30.989 9.216c0.801 0.801 0.801 2.1 0 2.901l-18.462 18.462c-0.801 0.801-2.1 0.801-2.901 0l-8.205-8.205c-0.801-0.801-0.801-2.1 0-2.901s2.1-0.801 2.901 0l6.755 6.755 17.011-17.011c0.801-0.801 2.1-0.801 2.901 0zM15.18 8.615c1.133 0 2.051 0.918 2.051 2.051s-0.918 2.051-2.051 2.051h-12.308c-1.133 0-2.051-0.918-2.051-2.051s0.918-2.051 2.051-2.051h12.308zM25.436 0.41c1.133 0 2.051 0.918 2.051 2.051s-0.918 2.051-2.051 2.051h-22.564c-1.133 0-2.051-0.918-2.051-2.051s0.918-2.051 2.051-2.051h22.564z" />
      </symbol>
      <symbol id="icon-options-vertical" viewBox="0 0 32 32">
        <path d="M16 22.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667zM16 13.333c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667zM16 4c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667z" />
      </symbol>
      <symbol id="icon-template" viewBox="0 0 32 32">
        <path d="M18.667 1.333c0.057 0 0.113 0.004 0.168 0.010l-0.168-0.010c0.070 0 0.14 0.006 0.209 0.016 0.042 0.006 0.083 0.015 0.124 0.025 0.014 0.004 0.027 0.008 0.040 0.011 0.041 0.012 0.082 0.026 0.122 0.042 0.012 0.005 0.024 0.010 0.036 0.015 0.038 0.016 0.074 0.034 0.11 0.053 0.014 0.008 0.028 0.016 0.042 0.024 0.038 0.022 0.074 0.046 0.109 0.072 0.009 0.006 0.018 0.013 0.027 0.020 0.006 0.005 0.011 0.009 0.016 0.013l0.109 0.098 8 8c0.039 0.039 0.076 0.081 0.11 0.124l-0.11-0.124c0.048 0.048 0.092 0.099 0.131 0.153 0.025 0.034 0.049 0.070 0.072 0.108 0.008 0.014 0.016 0.028 0.024 0.043 0.020 0.035 0.037 0.072 0.054 0.109 0.005 0.012 0.010 0.024 0.015 0.036 0.016 0.039 0.030 0.080 0.042 0.121 0.004 0.013 0.007 0.027 0.011 0.040 0.011 0.040 0.019 0.082 0.026 0.124 0.002 0.014 0.004 0.028 0.006 0.042 0.007 0.055 0.011 0.111 0.011 0.167v0 16c0 2.209-1.791 4-4 4h-16c-2.209 0-4-1.791-4-4v-21.333c0-2.209 1.791-4 4-4zM17.333 4h-9.333c-0.736 0-1.333 0.597-1.333 1.333v21.333c0 0.736 0.597 1.333 1.333 1.333h16c0.736 0 1.333-0.597 1.333-1.333v-14.667h-6.667c-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-6.667zM21.333 21.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333v0h-10.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333v0zM21.333 16c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333v0h-10.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333v0zM13.333 10.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333v0h-2.667c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333v0zM20 5.887v3.447h3.448l-3.448-3.447z" />
      </symbol>
      <symbol id="icon-card-custom" viewBox="0 0 32 32">
        <path d="M24.581 5.333c1.285 0 1.97 0.132 2.683 0.514 0.667 0.357 1.198 0.888 1.555 1.555 0.382 0.713 0.514 1.398 0.514 2.683v0 11.83c0 1.285-0.132 1.97-0.514 2.683-0.357 0.667-0.888 1.198-1.555 1.555-0.713 0.382-1.398 0.514-2.683 0.514v0h-17.163c-1.285 0-1.97-0.132-2.683-0.514-0.667-0.357-1.198-0.888-1.555-1.555-0.382-0.713-0.514-1.398-0.514-2.683v0-11.83c0-1.285 0.132-1.97 0.514-2.683 0.357-0.667 0.888-1.198 1.555-1.555 0.713-0.382 1.398-0.514 2.683-0.514v0zM26.665 14.667h-21.332l0.001 7.474c0.009 0.705 0.064 0.95 0.197 1.2 0.108 0.202 0.259 0.353 0.461 0.461 0.274 0.147 0.543 0.199 1.425 0.199v0l17.389-0.001c0.705-0.009 0.95-0.064 1.2-0.197 0.202-0.108 0.353-0.259 0.461-0.461 0.147-0.274 0.199-0.543 0.199-1.425v0l-0.001-7.248zM9.333 18.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM18.667 18.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333h-4c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333h4zM24.807 8.001h-17.615c-0.705 0.009-0.95 0.064-1.2 0.197-0.202 0.108-0.353 0.259-0.461 0.461-0.147 0.274-0.199 0.543-0.199 1.425v1.915h21.332v-2.141c-0.008-0.627-0.052-0.89-0.156-1.116l-0.042-0.084c-0.108-0.202-0.259-0.353-0.461-0.461-0.274-0.147-0.543-0.199-1.425-0.199z" />
      </symbol>
      <symbol id="icon-user-o" viewBox="0 0 32 32">
        <path d="M20.927 16.946c2.68-2.108 3.729-5.683 2.613-8.905s-4.15-5.383-7.56-5.383c-3.409 0-6.444 2.161-7.56 5.383s-0.066 6.797 2.613 8.905c-4.564 1.828-7.751 6.020-8.293 10.907-0.081 0.736 0.45 1.399 1.187 1.48s1.399-0.45 1.48-1.187c0.606-5.396 5.17-9.476 10.6-9.476s9.994 4.080 10.6 9.476c0.075 0.679 0.651 1.191 1.333 1.187h0.147c0.728-0.084 1.251-0.738 1.173-1.467-0.545-4.901-3.75-9.101-8.333-10.92v0zM15.98 16c-2.946 0-5.333-2.388-5.333-5.333s2.388-5.333 5.333-5.333c2.946 0 5.333 2.388 5.333 5.333 0 1.415-0.562 2.771-1.562 3.771s-2.357 1.562-3.771 1.562z" />
      </symbol>
      <symbol id="icon-right-arrow-round" viewBox="0 0 32 32">
        <path d="M22.276 15.057l-5.333-5.333c-0.521-0.521-1.365-0.521-1.886 0s-0.521 1.365 0 1.886l3.057 3.057h-7.448c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h7.448l-3.057 3.057c-0.521 0.521-0.521 1.365 0 1.886s1.365 0.521 1.886 0l5.333-5.333c0.241-0.241 0.391-0.575 0.391-0.943s-0.149-0.702-0.391-0.943zM16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333z" />
      </symbol>
      <symbol id="icon-chat-fill" viewBox="0 0 32 32">
        <path d="M26.667 0c2.946 0 5.333 2.388 5.333 5.333v13.333c0 2.946-2.388 5.333-5.333 5.333h-12.121l-6.545 6c-0.216 0.196-0.575 0.57-1.12 0.651l-0.213 0.016c-0.736 0-1.333-0.618-1.333-1.38v0-5.286c-2.946 0-5.333-2.388-5.333-5.333v-13.333c0-2.946 2.388-5.333 5.333-5.333h21.333z" />
      </symbol>
      <symbol id="icon-chat-o" viewBox="0 0 32 32">
        <path d="M25.333 8h-18.667v15.123l3.173-2.598c0.419-0.343 0.948-0.531 1.494-0.531h14v-11.993zM26.2 22.665h-15l-5.232 4.36c-0.509 0.424-1.266 0.355-1.69-0.154-0.18-0.216-0.278-0.488-0.278-0.768v-18.969c0-0.994 0.806-1.8 1.8-1.8h20.4c0.994 0 1.8 0.806 1.8 1.8v13.731c0 0.994-0.806 1.8-1.8 1.8z" />
      </symbol>
      <symbol id="icon-lock-alt" viewBox="0 0 32 32">
        <path d="M16 2.667c3.682 0 6.667 2.985 6.667 6.667v2.667c2.209 0 4 1.791 4 4v0 9.333c0 2.209-1.791 4-4 4v0h-13.333c-2.209 0-4-1.791-4-4v0-9.333c0-2.209 1.791-4 4-4v0-2.667c0-3.682 2.985-6.667 6.667-6.667zM22.667 14.667h-13.333c-0.736 0-1.333 0.597-1.333 1.333v0 9.333c0 0.736 0.597 1.333 1.333 1.333v0h13.333c0.736 0 1.333-0.597 1.333-1.333v0-9.333c0-0.736-0.597-1.333-1.333-1.333v0zM16 17.333c0.831-0.010 1.581 0.499 1.878 1.276s0.080 1.656-0.545 2.204v0 1.853c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333v0-1.853c-0.625-0.548-0.843-1.428-0.545-2.204s1.047-1.285 1.878-1.276zM16 5.333c-2.13 0-3.872 1.665-3.993 3.765l-0.007 0.235v2.667h8v-2.667c0-2.13-1.665-3.872-3.765-3.993l-0.235-0.007z" />
      </symbol>
      <symbol id="icon-drag" viewBox="0 0 32 32">
        <path d="M13.333 22.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM18.667 22.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM13.333 17.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM18.667 17.333c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM13.333 12c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM18.667 12c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM13.333 6.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333zM18.667 6.667c0.736 0 1.333 0.597 1.333 1.333s-0.597 1.333-1.333 1.333c-0.736 0-1.333-0.597-1.333-1.333s0.597-1.333 1.333-1.333z" />
      </symbol>
      <symbol id="icon-photo" viewBox="0 0 32 32">
        <path d="M25.333 14.114v-6.114c0-0.736-0.597-1.333-1.333-1.333h-16c-0.736 0-1.333 0.597-1.333 1.333v11.448l3.057-3.057c0.449-0.449 1.154-0.519 1.682-0.167l3.003 2.002 5.9-7.080c0.502-0.602 1.413-0.644 1.967-0.089l3.057 3.057zM25.333 17.886l-3.91-3.91-5.732 6.878c-0.436 0.523-1.197 0.634-1.764 0.256l-3.091-2.060-4.17 4.17v0.781c0 0.736 0.597 1.333 1.333 1.333h16c0.736 0 1.333-0.597 1.333-1.333v-6.114zM8 4h16c2.209 0 4 1.791 4 4v16c0 2.209-1.791 4-4 4h-16c-2.209 0-4-1.791-4-4v-16c0-2.209 1.791-4 4-4zM12.667 13.333c-1.105 0-2-0.895-2-2s0.895-2 2-2c1.105 0 2 0.895 2 2s-0.895 2-2 2z" />
      </symbol>
      <symbol id="icon-attention" viewBox="0 0 32 32">
        <path d="M16 5.333c5.891 0 10.667 4.776 10.667 10.667s-4.776 10.667-10.667 10.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667zM16 20c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333zM16 9.333c-0.736 0-1.333 0.597-1.333 1.333v0 6c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-6c0-0.736-0.597-1.333-1.333-1.333z" />
      </symbol>
      <symbol id="icon-hat-o" viewBox="0 0 40 40">
        <path d="M28.8 0c1.767 0 3.2 1.433 3.2 3.2v19.2c0 1.767-1.433 3.2-3.2 3.2h-17.175c-0.278 0-0.552 0.073-0.794 0.211l-8.437 4.821c-0.767 0.438-1.745 0.172-2.183-0.595-0.138-0.242-0.211-0.515-0.211-0.794v-26.043c0-1.767 1.433-3.2 3.2-3.2h25.6zM28.8 3.2h-25.6v23.285l6.043-3.452c0.622-0.355 1.314-0.566 2.025-0.619l0.357-0.013h17.175v-19.2zM22.4 14.4c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6h-12.8c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6h12.8zM22.4 8c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6h-12.8c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6h12.8z" />
      </symbol>
      <symbol id="icon-settings-o" viewBox="0 0 32 32">
        <path d="M28.427 12.733l-2.52-0.84 1.187-2.373c0.247-0.509 0.145-1.118-0.253-1.52l-2.84-2.84c-0.404-0.404-1.021-0.506-1.533-0.253l-2.373 1.187-0.84-2.52c-0.182-0.538-0.685-0.902-1.253-0.907h-4c-0.573-0.001-1.083 0.364-1.267 0.907l-0.84 2.52-2.373-1.187c-0.509-0.247-1.118-0.145-1.52 0.253l-2.84 2.84c-0.404 0.404-0.506 1.021-0.253 1.533l1.187 2.373-2.52 0.84c-0.538 0.182-0.902 0.685-0.907 1.253v4c-0.001 0.573 0.364 1.083 0.907 1.267l2.52 0.84-1.187 2.373c-0.247 0.509-0.145 1.118 0.253 1.52l2.84 2.84c0.404 0.404 1.021 0.506 1.533 0.253l2.373-1.187 0.84 2.52c0.183 0.543 0.693 0.908 1.267 0.907h4c0.573 0.001 1.083-0.364 1.267-0.907l0.84-2.52 2.373 1.187c0.506 0.24 1.107 0.139 1.507-0.253l2.84-2.84c0.404-0.404 0.506-1.021 0.253-1.533l-1.187-2.373 2.52-0.84c0.538-0.182 0.902-0.685 0.907-1.253v-4c0.001-0.573-0.364-1.083-0.907-1.267zM26.667 17.040l-1.6 0.533c-0.745 0.242-1.346 0.798-1.643 1.522s-0.262 1.542 0.097 2.238l0.76 1.52-1.467 1.467-1.48-0.8c-0.692-0.345-1.499-0.372-2.213-0.075s-1.264 0.889-1.507 1.622l-0.533 1.6h-2.12l-0.533-1.6c-0.242-0.745-0.798-1.346-1.522-1.643s-1.542-0.262-2.238 0.097l-1.52 0.76-1.467-1.467 0.8-1.48c0.359-0.696 0.395-1.514 0.097-2.238s-0.899-1.281-1.643-1.522l-1.6-0.533v-2.080l1.6-0.533c0.745-0.242 1.346-0.798 1.643-1.522s0.262-1.542-0.097-2.238l-0.76-1.48 1.467-1.467 1.48 0.76c0.696 0.359 1.514 0.395 2.238 0.097s1.281-0.899 1.522-1.643l0.533-1.6h2.080l0.533 1.6c0.242 0.745 0.798 1.346 1.522 1.643s1.542 0.262 2.238-0.097l1.52-0.76 1.467 1.467-0.8 1.48c-0.345 0.692-0.372 1.499-0.075 2.213s0.889 1.264 1.622 1.507l1.6 0.533v2.12zM16 10.667c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333c2.946 0 5.333-2.388 5.333-5.333 0-1.415-0.562-2.771-1.562-3.771s-2.357-1.562-3.771-1.562zM16 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667z" />
      </symbol>
      <symbol id="icon-embedded" viewBox="0 0 32 32">
        <path d="M18.365 6.714c0.696 0.196 1.11 0.949 0.923 1.682l-4.055 15.919c-0.187 0.733-0.902 1.167-1.599 0.971s-1.11-0.949-0.923-1.682l4.055-15.919c0.187-0.733 0.902-1.167 1.599-0.971zM10.291 9.706c0.5 0.497 0.5 1.302 0 1.798l-4.531 4.496 4.531 4.496c0.5 0.497 0.5 1.302 0 1.798s-1.312 0.497-1.812 0l-5.437-5.395c-0.5-0.497-0.5-1.302 0-1.798l5.437-5.395c0.5-0.497 1.312-0.497 1.812 0zM21.709 9.706c0.5-0.497 1.312-0.497 1.812 0v0l5.437 5.395c0.5 0.497 0.5 1.302 0 1.798v0l-5.437 5.395c-0.5 0.497-1.312 0.497-1.812 0s-0.5-1.302 0-1.798v0l4.531-4.496-4.531-4.496c-0.5-0.497-0.5-1.302 0-1.798z" />
      </symbol>
      <symbol id="icon-client-start" viewBox="0 0 32 32">
        <path d="M15.915 2.667c3.409 0 6.444 2.161 7.56 5.383s0.066 6.797-2.613 8.905c0.707 0.281 1.381 0.618 2.018 1.006-0.565 0.686-1.059 1.432-1.47 2.227-1.603-0.961-3.477-1.509-5.468-1.509-5.43 0-9.994 4.080-10.6 9.476-0.081 0.736-0.744 1.268-1.48 1.187s-1.268-0.744-1.187-1.48c0.542-4.887 3.729-9.078 8.293-10.907-2.68-2.108-3.729-5.683-2.613-8.905s4.15-5.383 7.56-5.383zM25.607 18.724c0.158 0.069 0.283 0.196 0.352 0.354l1.195 2.748 2.913 0.361c0.375 0.046 0.641 0.39 0.595 0.766-0.019 0.156-0.091 0.3-0.202 0.409l-2.179 2.126 0.572 3.028c0.070 0.373-0.173 0.733-0.544 0.804-0.163 0.031-0.332 0.002-0.475-0.083l-2.5-1.484-2.5 1.484c-0.325 0.193-0.744 0.084-0.936-0.243-0.084-0.144-0.114-0.314-0.083-0.478l0.572-3.028-2.179-2.126c-0.271-0.264-0.277-0.699-0.015-0.972 0.109-0.113 0.252-0.184 0.407-0.204l2.913-0.361 1.195-2.748c0.151-0.348 0.554-0.506 0.9-0.354zM15.915 5.341c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333c1.415 0 2.771-0.562 3.771-1.562s1.562-2.357 1.562-3.771c0-2.946-2.388-5.333-5.333-5.333z" />
      </symbol>
      <symbol id="icon-whatsapp" viewBox="0 0 32 32">
        <path d="M22.133 18.667c-0.267-0.133-2-0.933-2.267-1.067s-0.533-0.133-0.8 0.133c-0.267 0.267-0.8 1.067-1.067 1.333-0.133 0.267-0.4 0.267-0.667 0.133-0.933-0.4-1.867-0.933-2.667-1.6-0.667-0.667-1.333-1.467-1.867-2.267-0.133-0.267 0-0.533 0.133-0.667s0.267-0.4 0.533-0.533c0.133-0.133 0.267-0.4 0.267-0.533 0.133-0.133 0.133-0.4 0-0.533s-0.8-1.733-1.067-2.4c-0.133-0.933-0.4-0.933-0.667-0.933-0.133 0-0.4 0-0.667 0s-0.667 0.267-0.8 0.4c-0.8 0.8-1.2 1.733-1.2 2.8 0.133 1.2 0.533 2.4 1.333 3.467 1.467 2.133 3.333 3.867 5.6 4.933 0.667 0.267 1.2 0.533 1.867 0.667 0.667 0.267 1.333 0.267 2.133 0.133 0.933-0.133 1.733-0.8 2.267-1.6 0.267-0.533 0.267-1.067 0.133-1.6 0 0-0.267-0.133-0.533-0.267zM25.467 6.533c-5.2-5.2-13.6-5.2-18.8 0-4.267 4.267-5.067 10.8-2.133 16l-1.867 6.8 7.067-1.867c2 1.067 4.133 1.6 6.267 1.6v0c7.333 0 13.2-5.867 13.2-13.2 0.133-3.467-1.333-6.8-3.733-9.333zM21.867 25.2c-1.733 1.067-3.733 1.733-5.867 1.733v0c-2 0-3.867-0.533-5.6-1.467l-0.4-0.267-4.133 1.067 1.067-4-0.267-0.4c-3.2-5.333-1.6-12 3.6-15.333s11.867-1.6 15.067 3.467c3.2 5.2 1.733 12-3.467 15.2z" />
      </symbol>
    </defs>
  </svg>
);

export default SvgRootComponent;
