import endpoint from 'utils/endpoint';
import {
  usersInvitationsTwoFactorAuthenticationRoute,
  usersInvitationsRegistrationRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const submitTwoFactorAuthenticationEndpoint = endpoint(types.SUBMIT_TWO_FACTOR_AUTH, 'POST', usersInvitationsTwoFactorAuthenticationRoute);
export const validateInvitationTokenEndpoint = endpoint(types.VALIDATE_INVITATION_TOKEN, 'GET', usersInvitationsRegistrationRoute);
export const usersInvitationsRegistrationEndpoint = endpoint(types.SIGNUP_FROM_INVITATION, 'POST', usersInvitationsRegistrationRoute);

