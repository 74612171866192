import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { fetchSymptoms, setCurrentPage } from '../actions';
import { SET_SYMPTOMS_FILTER } from '../types';

const filterSymptomsOperation = createLogic({
  type: SET_SYMPTOMS_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    dispatch(fetchSymptoms());
    done();
  },
});

export default filterSymptomsOperation;
