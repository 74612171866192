import yup from 'lib/yupLocalised';
import {
  MIN_CURRENCY_LENGTH,
  MAX_CURRENCY_LENGTH,
  MAX_INPUT_CONTENT_LENGTH,
  MAX_INPUT_LENGTH,
} from 'constants';

export default yup.object().shape({
  number: yup.number().max(99).required(),
  value: yup.number().min(MIN_CURRENCY_LENGTH).max(MAX_CURRENCY_LENGTH).required(),
  name: yup.string().max(MAX_INPUT_LENGTH).required(),
  comment: yup.string().max(MAX_INPUT_CONTENT_LENGTH),
});
