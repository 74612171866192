import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { submitUpdateUserEmailEndpoint } from '../endpoints';
import { SUBMIT_UPDATE_USER_EMAIL } from '../types';

const submitUpdateUserEmailOperation = createLogic({
  type: SUBMIT_UPDATE_USER_EMAIL,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = submitUpdateUserEmailEndpoint;

    try {
      const state = getState();
      const params = {
        new_email: values.newEmail,
        password: values.password,
        redirect_to: '/account_settings',
      };
      const response = updateDataHelper(
        state.data,
        'userProfile',
        currentUserIdSelector(state),
        {
          attributes: {
            newEmail: values.newEmail,
          },
        },
      );

      await httpClient.post(url, params);

      dispatch(showNotification({ messageObject: { id: 'notifications.yourEmailHasBeenChanged' } }));
      dispatch(dataApiSuccess({ response }));
      form.resetForm({ newEmail: values.newEmail, password: '' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitUpdateUserEmailOperation;
