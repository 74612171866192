import { createLogic } from 'redux-logic';
import { isEmpty } from 'ramda';

import { createSymptomDetails, updateSymptomDetails } from '../actions';
import { symptomDetailsIdSelector } from '../selectors';
import { SUBMIT_CREATE_SYMPTOM_DETAILS } from '../types';

const submitCreateSymptomDetailsOperation = createLogic({
  type: SUBMIT_CREATE_SYMPTOM_DETAILS,
  latest: true,

  async process({ action: { values, form }, getState }, dispatch, done) {
    const state = getState();
    const symptomDetailsId = symptomDetailsIdSelector(state);

    if (isEmpty(symptomDetailsId)) {
      dispatch(createSymptomDetails(values, form));
    } else {
      dispatch(updateSymptomDetails(values, form));
    }

    done();
  },
});

export default submitCreateSymptomDetailsOperation;
