import * as types from './types';

export const fetchPatientAppointments = patientId => ({
  type: types.FETCH_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS,
  patientId,
});

export const setPatientAppointments = patientAppointmentsIds => ({
  type: types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS,
  patientAppointmentsIds,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS_SORT_ORDER,
  sortKey,
});
