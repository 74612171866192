import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setRevenueExtraStatisticId } from '../actions';
import { fetchRevenueExtraStatisticEndpoint } from '../endpoints';
import { FETCH_REVENUE_EXTRA_STATISTIC } from '../types';

const fetchRevenueExtraStatisticOperation = createLogic({
  type: FETCH_REVENUE_EXTRA_STATISTIC,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchRevenueExtraStatisticEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRevenueExtraStatisticId(data.data.id));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRevenueExtraStatisticOperation;
