import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import isSubmitDisabledFunc from 'utils/form/isSubmitDisabled';
import isPresent from 'utils/isPresent';

import SubmitFormButtonComponent from './component';

class SubmitFormButton extends React.Component {
  static propTypes = {
    formik: PropTypes.shape().isRequired,
    isDisabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isEditForm: PropTypes.bool,
    overrideKind: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    isDisabled: null,
    isSubmitting: null,
    isEditForm: false,
    overrideKind: [],
  };

  get isFormSubmitting() {
    const { isSubmitting, formik } = this.props;

    return !isNil(isSubmitting) ? isSubmitting : formik.isSubmitting;
  }

  get isSubmitDisabled() {
    const { isDisabled, formik, isEditForm } = this.props;

    if (!isEditForm) {
      return false;
    }

    return !isNil(isDisabled) ? isDisabled : isSubmitDisabledFunc(formik);
  }

  get kindButton() {
    const { isEditForm, formik, overrideKind } = this.props;

    if (isPresent(overrideKind)) {
      return !isEditForm && isSubmitDisabledFunc(formik) ? overrideKind[0] : overrideKind[1];
    }

    return !isEditForm && isSubmitDisabledFunc(formik) ? 'secondary' : null;
  }

  render = () => (
    <SubmitFormButtonComponent
      {...this.props}
      isSubmitDisabled={this.isSubmitDisabled}
      isFormSubmitting={this.isFormSubmitting}
      kind={this.kindButton}
    />
  );
}

export default connect(SubmitFormButton);
