import PropTypes from 'prop-types';

import {
  isCancelled,
  isCompleted,
} from 'utils/appointments';

import DropdownMenu from 'views/shared/DropdownMenu';
import DropdownItem from 'views/shared/DropdownItem';
import SvgIcon from 'views/shared/SvgIcon';

const AppointmentCalendarTooltipActionsComponent = ({
  event,
  onCancel,
  getPopupContainer,
  popupContainerRef,
}) => (
  <>
    <DropdownMenu
      className="modal-appointment__dropdown mr-16"
      menuClassName="main-dropdown"
      disabled={isCancelled(event) || isCompleted(event)}
      placement="bottomRight"
      getPopupContainer={getPopupContainer}
      icon={(
        <button type="button">
          <SvgIcon icon="options" className="modal-appointment__icon" />
        </button>
      )}
    >
      <DropdownItem
        text={{ id: 'appointments.cancelAppointment' }}
        onClick={onCancel}
      />
    </DropdownMenu>
    <div ref={popupContainerRef} id="dropdown-appointment" />
  </>
);

AppointmentCalendarTooltipActionsComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  event: PropTypes.shape().isRequired,
  getPopupContainer: PropTypes.func.isRequired,
  popupContainerRef: PropTypes.shape().isRequired,
};

export default AppointmentCalendarTooltipActionsComponent;
