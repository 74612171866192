import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchVirtualClinics = () => ({
  type: types.FETCH_VIRTUAL_CLINICS,
});

export const setVirtualClinicIds = ids => ({
  type: types.SET_VIRTUAL_CLINICS_IDS,
  ids,
});

export const setBlankState = isBlankState => ({
  type: types.SET_VIRTUAL_CLINICS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_VIRTUAL_CLINICS_CURRENT_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_VIRTUAL_CLINICS_SORT,
  sortKey,
});

export const filterVirtualClinics = filters => ({
  type: types.SET_VIRTUAL_CLINICS_FILTER,
  filters,
});

export const inviteVirtualClinics = makeFormSubmitAction(types.INVITE_VIRTUAL_CLINICS);

export const resendVirtualClinicInvitation = virtualClinicId => ({
  type: types.RESEND_VIRTUAL_CLINIC_INVITATION,
  virtualClinicId,
});

export const removeVirtualClinicInvitation = virtualClinicId => ({
  type: types.REMOVE_VIRTUAL_CLINIC_INVITATION,
  virtualClinicId,
});

export const deactivateVirtualClinic = makeFormSubmitAction(types.DEACTIVATE_VIRTUAL_CLINIC);

export const activateVirtualClinic = (virtualClinicId, clinicId) => ({
  type: types.ACTIVATE_VIRTUAL_CLINIC,
  virtualClinicId,
  clinicId,
});

export const resetAuthenticationVirtualClinic = virtualClinicId => ({
  type: types.RESET_AUTHENTICATION_VIRTUAL_CLINIC,
  virtualClinicId,
});

export const fetchVirtualClinic = userId => ({
  type: types.FETCH_VIRTUAL_CLINIC,
  userId,
});

export const setVirtualClinicProfileId = userId => ({
  type: types.SET_VIRTUAL_CLINIC_PROFILE_ID,
  userId,
});

export const resetVirtualClinicProfileId = () => ({
  type: types.RESET_VIRTUAL_CLINIC_PROFILE_ID,
});

export const submitVirtualClinicProfile = makeFormSubmitAction(types.SUBMIT_VIRTUAL_CLINIC_PROFILE);

export const uploadVirtualClinicLogo = (logo, isImageUrl) => {
  const formData = new FormData();
  formData.append('logo', logo, logo.name);

  return {
    type: types.UPLOAD_VIRTUAL_CLINIC_LOGO,
    data: formData,
    isImageUrl,
  };
};

export const removeVirtualClinicLogo = () => ({
  type: types.REMOVE_VIRTUAL_CLINIC_LOGO,
});

export const updateVirtualClinicAccount = makeFormSubmitAction(types.UPDATE_VIRTUAL_CLINIC_ACCOUNT);

export const updateVirtualClinicFreeVisits = makeFormSubmitAction(
  types.UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS,
);
