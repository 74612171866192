import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchPatientAccountProfile = () => ({
  type: types.FETCH_PATIENT_ACCOUNT_PROFILE,
});

export const submitPatientAccountVoucherTopup = makeFormSubmitAction(
  types.SUBMIT_PATIENT_ACCOUNT_VOUCHER_TOPUP,
);

export const setVoucherCodeStatus = (voucherCodeStatus) => ({
  type: types.SET_VOUCHER_CODE_STATUS,
  voucherCodeStatus,
});

export const resetVoucherCodeStatus = () => ({
  type: types.RESET_VOUCHER_CODE_STATUS,
});

export const submitPatientAccountPagoparTopup = makeFormSubmitAction(
  types.SUBMIT_PATIENT_ACCOUNT_PAGOPAR_TOPUP,
);

export const confirmEmail = (token, response) => ({
  type: types.CONFIRM_EMAIL,
  token,
  response,
});
