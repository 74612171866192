import isEmail from 'utils/isEmail';

// eslint-disable-next-line import/prefer-default-export
export function isValidEmail() {
  return this.test(
    'isEmail',
    { id: 'yup.string.email' },
    (email) => isEmail(email),
  );
}
