import React from 'react';
import { ReactReduxContext } from 'react-redux';

import getDisplayName from 'utils/getDisplayName';
import { mountInterceptors } from 'lib/httpClient';

const withHttpClient = (WrappedComponent) => {
  class WithHttpClient extends React.Component {
    static getInitialProps = ctx => (
      WrappedComponent.getInitialProps ? WrappedComponent.getInitialProps(ctx) : {}
    );

    static displayName = `withHttpClient(${getDisplayName(WrappedComponent)})`;

    static contextType = ReactReduxContext

    getHttpClient = () => {
      const { store } = this.context;

      mountInterceptors({ store });
      return store.httpClient;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getHttpClient={this.getHttpClient}
        />
      );
    }
  }

  return WithHttpClient;
};

export default withHttpClient;
