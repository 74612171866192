import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { fetchExtraFreeVisitsPatientsEndpoint } from '../endpoints';
import { FETCH_FREE_VISITS_PATIENTS } from '../types';
import { setPatientsListIds } from '../actions';
import { patientsListFilterParamsSelector } from '../selectors';

const fetchPatientsOperation = createLogic({
  type: FETCH_FREE_VISITS_PATIENTS,
  latest: true,

  async process({ httpClient, getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchExtraFreeVisitsPatientsEndpoint;
    const { name } = patientsListFilterParamsSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size: 20,
        is_cursor: true,
      },
      filter: {
        name,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setPatientsListIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPatientsOperation;
