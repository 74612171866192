import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select as AntdSelect } from 'antd';
import { useIntl } from 'react-intl';
import { is, equals } from 'ramda';

import SvgIcon from 'views/shared/SvgIcon';

import NotFoundContent from './NotFoundContent';

const Select = forwardRef(({
  className,
  options,
  children,
  value: selectedValue,
  notFoundContent,
  ...props
}, ref) => {
  const { formatMessage } = useIntl();

  return (
    <AntdSelect
      ref={ref}
      className={className}
      optionFilterProp="label"
      choiceTransitionName={null}
      suffixIcon={<SvgIcon icon="arrow-down" />}
      value={selectedValue}
      notFoundContent={notFoundContent}
      {...props}
    >
      {children || options.map(({ label, value, key, content }) => {
        const formattedLabel = is(Object, label) ? formatMessage(label) : label;
        const isSelected = equals(selectedValue, key);

        return (
          <AntdSelect.Option
            title={formattedLabel}
            label={formattedLabel}
            value={value}
            key={key}
            disabled={isSelected}
            className={classNames({ 'd-none': isSelected })}
          >
            {content || formattedLabel}
          </AntdSelect.Option>
        );
      })}
    </AntdSelect>
  );
});

Select.displayName = 'Select';

Select.defaultProps = {
  options: [],
  children: null,
  className: '',
  value: undefined,
  notFoundContent: <NotFoundContent />,
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
      ]),
    }),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape(),
  ]),
  notFoundContent: PropTypes.node,
};

export default Select;
