import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  removeVouchers,
  setSelectedVouchers as setSelectedVouchersAction,
} from 'state/concepts/userProfile/vouchers/actions';
import { removeVouchersLoadingSelector } from 'state/concepts/userProfile/vouchers/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class VouchersBulkRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    voucherIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedVouchers: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, voucherIds, setSelectedVouchers } = this.props;

    onSubmit(voucherIds);
    setSelectedVouchers([]);
  }

  render() {
    const { voucherIds } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'vouchers.bulkRemoveModal.title' }}
        bodyText={[
          [{
            id: 'vouchers.bulkRemoveModal.body.start',
          },
          {
            id: 'vouchers.bulkRemoveModal.body.end',
            values: { count: voucherIds.length },
            isBold: true,
          }],
        ]}
        submitText={{ id: 'shared.remove' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: removeVouchersLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: removeVouchers,
  setSelectedVouchers: setSelectedVouchersAction,
};

export { VouchersBulkRemoveModal as VouchersBulkRemoveModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(VouchersBulkRemoveModal);
