import { createLogic } from 'redux-logic';
import { pick } from 'ramda';

import { ONBOARDING_STATUSES } from 'constants/onboarding';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { submitLocationEndpoint } from '../endpoints';
import { SUBMIT_ONBOARDING_LOCATION } from '../types';

const submitOnboardingLocationOperation = createLogic({
  type: SUBMIT_ONBOARDING_LOCATION,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const { url, endpoint } = submitLocationEndpoint;
    const state = getState();
    const currentUserId = currentUserIdSelector(state);

    const params = {
      timezone: values.timezone,
      country: values.country,
      city: values.city,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'userProfile', currentUserId,
        {
          attributes: {
            ...pick(['timezone', 'country', 'city'], values),
            onboardingStatus: ONBOARDING_STATUSES.completed,
          },
        },
      );
      dispatch(dataApiSuccess({ endpoint, response }));

      redirect({ href: '/appointments' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitOnboardingLocationOperation;
