import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDiseases as getDiseasesAction, getDiseasesNodes } from 'state/concepts/userProfile/diseases/actions';
import { searchQuerySelector } from 'state/concepts/userProfile/filters/selectors';
import { diseasesSelector, searchDiseasesLoadingSelector } from 'state/concepts/userProfile/diseases/selectors';

import OtherDiagnosesComponent from './component';

class OtherDiagnoses extends React.Component {
  static propTypes = {
    getDiseases: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getDiseases } = this.props;

    getDiseases();
  }

  render = () => (
    <OtherDiagnosesComponent
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  diseases: diseasesSelector(state),
  searchQuery: searchQuerySelector(state),
  isLoading: searchDiseasesLoadingSelector(state),
});

const mapDispatchToProps = {
  getDiseases: getDiseasesAction,
  getDiseasesNodes,
};

export { OtherDiagnoses as OtherDiagnosesContainer };
export default connect(mapStateToProps, mapDispatchToProps)(OtherDiagnoses);
