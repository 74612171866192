import build from 'redux-object';
import { createSelector } from 'reselect';
import {
  prop, propOr, path, pathOr, isEmpty,
} from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, totalCountSelector, pageCountSelector } from 'state/data/selectors';
import {
  fetchVirtualClinicsEndpoint,
  deactivateVirtualClinicEndpoint,
  activateVirtualClinicEndpoint,
  resetAuthenticationVirtualClinicEndpoint,
  fetchVirtualClinicEndpoint,
  submitVirtualClinicEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['virtualClinics', 'isBlankState']);
export const paginationSelector = path(['virtualClinics', 'pagination']);
export const virtualClinicIdsSelector = path(['virtualClinics', 'virtualClinicIds']);
export const sortSelector = path(['virtualClinics', 'sort']);
export const virtualClinicProfileIdSelector = path(['virtualClinics', 'virtualClinicProfileId']);

export const virtualClinicProfileSelector = createSelector(
  virtualClinicProfileIdSelector,
  dataSelector,
  (id, data) => (id && !isEmpty(id) ? build(data, 'clinic', id) : null),
);

export const virtualClinicProfileClinicSpecialitySelector = createSelector(
  virtualClinicProfileSelector,
  propOr([], 'userProfilesClinicSpecialities'),
);

export const virtualClinicsLoadingSelector = state => (
  loadingSelector(state, fetchVirtualClinicsEndpoint.endpoint)
);

export const virtualClinicsTotalCountSelector = state => (
  totalCountSelector(state, fetchVirtualClinicsEndpoint.endpoint)
);

export const virtualClinicsPageCountSelector = state => (
  pageCountSelector(state, fetchVirtualClinicsEndpoint.endpoint)
);

export const virtualClinicsSelector = createSelector(
  virtualClinicIdsSelector,
  dataSelector,
  buildCollection('userProfile'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const deactivateVirtualClinicLoadingSelector = (state, clinicId) => (
  loadingSelector(state, deactivateVirtualClinicEndpoint(clinicId).endpoint)
);

export const activateVirtualClinicLoadingSelector = (state, clinicId) => (
  loadingSelector(state, activateVirtualClinicEndpoint(clinicId).endpoint)
);

export const resetAuthVirtualClinicLoadingSelector = (state, clinicId) => (
  loadingSelector(state, resetAuthenticationVirtualClinicEndpoint(clinicId).endpoint)
);

export const virtualClinicProfileLoadingSelector = state => {
  const clinicId = virtualClinicProfileIdSelector(state);

  return loadingSelector(state, fetchVirtualClinicEndpoint(clinicId).endpoint);
};

export const updateVirtualClinicLoadingSelector = state => {
  const clinicId = virtualClinicProfileIdSelector(state);

  return loadingSelector(state, submitVirtualClinicEndpoint(clinicId).endpoint);
};

export const virtualClinicLogoSelector = createSelector(
  virtualClinicProfileSelector,
  pathOr(null, ['logoUrls', 'original']),
);
