import endpoint from 'utils/endpoint';
import {
  revenueRoute,
  revenueExtraStatisticRoute,
  revenueDownloadRoute,
  revenueIndependentDoctorsAppointmentsDownloadRoute,
  revenueIndependentDoctorsUsersDownloadRoute,
  revenueIndependentDoctorsUsersAppointmentsDownloadRoute,
  revenueVirtualClinicsRoute,
  revenueVirtualClinicsUsersRoute,
  revenueVirtualClinicsDownloadRoute,
  revenueVirtualClinicsUsersDownloadRoute,
  revenueVirtualClinicsAppointmentsDownloadRoute,
  revenueVirtualClinicsUsersAppointmentsDownloadRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchRevenueEndpoint = endpoint(types.FETCH_REVENUE, 'GET', revenueRoute);
export const fetchRevenueExtraStatisticEndpoint = endpoint(types.FETCH_REVENUE_EXTRA_STATISTIC, 'GET', revenueExtraStatisticRoute);
export const fetchRevenueVirtualClinicsEndpoint = endpoint(types.FETCH_REVENUE_VIRTUAL_CLINICS, 'GET', revenueVirtualClinicsRoute);
export const fetchRevenueVirtualClinicDoctorsEndpoint = clinicId => endpoint(types.FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS, 'GET', revenueVirtualClinicsUsersRoute(clinicId));
export const downloadRevenueEndpoint = endpoint(types.DOWNLOAD_REVENUE, 'GET', revenueDownloadRoute);
export const downloadRevenueVirtualClinicsEndpoint = endpoint(types.DOWNLOAD_REVENUE, 'GET', revenueVirtualClinicsDownloadRoute);
export const downloadRevenueIndependentDoctorsEndpoint = endpoint(types.DOWNLOAD_REVENUE_DOCTORS, 'GET', revenueIndependentDoctorsUsersDownloadRoute);
export const downloadRevenueIndependentDoctorsAppointmentsEndpoint = endpoint(types.DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS, 'GET', revenueIndependentDoctorsAppointmentsDownloadRoute);
export const downloadRevenueIndependentDoctorAppointmentsEndpoint = userId => endpoint(types.DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS, 'GET', revenueIndependentDoctorsUsersAppointmentsDownloadRoute(userId));
export const downloadRevenueVirtualClinicDoctorsEndpoint = clinicId => endpoint(types.DOWNLOAD_REVENUE_DOCTORS, 'GET', revenueVirtualClinicsUsersDownloadRoute(clinicId));
export const downloadRevenueVirtualClinicAppointmentsEndpoint = clinicId => endpoint(types.DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS, 'GET', revenueVirtualClinicsAppointmentsDownloadRoute(clinicId));
export const downloadRevenueVirtualClinicUserAppointmentsEndpoint = userId => endpoint(types.DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS, 'GET', revenueVirtualClinicsUsersAppointmentsDownloadRoute(userId));
