import { createLogic } from 'redux-logic';
import build from 'redux-object';
import normalize from 'json-api-normalizer';
import { head, path } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setChat } from 'state/concepts/userProfile/chat/actions';
import { getQuestionnaireResponses } from '../actions';
import { getAppointmentEndpoint } from '../endpoints';
import { GET_USER_APPOINTMENT } from '../types';

const getUserAppointmentOperation = createLogic({
  type: GET_USER_APPOINTMENT,
  latest: true,

  async process({ action: { appointmentId }, httpClient }, dispatch, done) {
    const { endpoint, url } = getAppointmentEndpoint(appointmentId);
    const params = {
      include: ['clinic-speciality', 'patient-profile', 'child-profile', 'speciality', 'timezone', 'chat', 'symptom'],
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);
      const { chat: { id: chatId } } = head(build(response, 'appointment'));

      dispatch(setChat(chatId));
      dispatch(getQuestionnaireResponses(path(['attributes', 'questionnaire-response-id'], data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getUserAppointmentOperation;
