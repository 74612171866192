import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import isBlankState from 'utils/isBlankState';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { paginationSelector } from '../selectors';
import { FETCH_PUSH_NOTIFICATIONS } from '../types';
import { fetchPushNotificationsEndpoint } from '../endpoints';
import { setPushNotificationsIds, setBlankState } from '../actions';

const fetchPushNotificationsOperation = createLogic({
  type: FETCH_PUSH_NOTIFICATIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchPushNotificationsEndpoint;
    const params = {
      page: paginationSelector(state),
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setBlankState(isBlankState(undefined, data.data)));
      dispatch(setPushNotificationsIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPushNotificationsOperation;
