import endpoint from 'utils/endpoint';

import {
  patientsResetPasswordRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const validatePatientResetPasswordTokenEndpoint = endpoint(types.VALIDATE_PATIENT_RESET_PASSWORD_TOKEN, 'GET', patientsResetPasswordRoute);
export const submitPatientResetPasswordEndpoint = endpoint(types.SUBMIT_PATIENT_RESET_PASSWORD, 'POST', patientsResetPasswordRoute);
export const createPatientNewPasswordEndpoint = endpoint(types.SUBMIT_PATIENT_NEW_PASSWORD, 'PUT', patientsResetPasswordRoute);
