import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setDiseasesIds } from '../actions';
import { getDiseasesEndpoint } from '../endpoints';
import { GET_DISEASES } from '../types';

const getDiseasesOperation = createLogic({
  type: GET_DISEASES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getDiseasesEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);
      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setDiseasesIds(data.data.map(item => ({ id: item.id }))));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getDiseasesOperation;
