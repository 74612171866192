import {
  pipe, groupBy, prop, values, map, pluck, flatten,
} from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export const getDoctorsByClinicSpeciality = specialitiesList => {
  const doctor = [{
    userProfileIds: [],
    freeVisit: false,
    price: '',
  }];

  const doctors = pipe(
    groupBy(prop('price')),
    map(specialitiesGroupByPrice => groupBy(prop('acceptFreeVisits'), specialitiesGroupByPrice)),
    map(specialitiesGroup => pipe(
      map(speciality => ({
        userProfileIds: pluck('id', pluck('userProfile', speciality)),
        price: speciality[0].price,
        freeVisit: speciality[0].acceptFreeVisits,
      })),
      values,
    )(specialitiesGroup)),
    values,
    flatten,
  )(specialitiesList);

  return doctors.length ? doctors : doctor;
};
