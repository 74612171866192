export const allowedCvFileTypes = ['application/pdf', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
export const acceptCvFileTypes = '.jpeg,.jpg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
export const allowedImageTypes = 'image/jpeg,image/png';
export const allowedAvatarTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export const MIME_TYPES = {
  image: [
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/svg+xml',
    'image/tiff',
    'image/vnd.microsoft.icon',
    'image/vnd.wap.wbmp',
    'image/webp',
    'image/png',
  ],
  video: [
    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/quicktime',
    'video/webm',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/3gpp',
    'video/3gpp2',
  ],
  pdf: 'application/pdf',
};

export const PLAYABLE_VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/ogg',
  'video/webm',
];

export const NON_ALPHANUMERIC_CHARACTERS_REGEXP = /[^a-zA-Z0-9]/g;

export const NUMBER_OF_FILES = 3;
