import { createLogic } from 'redux-logic';

import { DOCTORS_STATUSES } from 'constants/doctors';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { deactivateVirtualClinicDoctorEndpoint } from '../endpoints';
import { DEACTIVATE_VIRTUAL_CLINIC_DOCTOR } from '../types';

const deactivateVirtualClinicDoctorOperation = createLogic({
  type: DEACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  latest: true,

  async process({ httpClient, getState, action: { doctorId, vcdId, resetForm } }, dispatch, done) {
    const { url, endpoint } = deactivateVirtualClinicDoctorEndpoint(vcdId);

    try {
      dispatch(dataApiRequest({ endpoint }));
      await httpClient.put(url);
      const state = getState();
      let response;

      if (isPresent(doctorId)) {
        response = updateDataHelper(
          state.data, 'virtualClinicDoctor', doctorId,
          { attributes: { status: DOCTORS_STATUSES.deactivated } },
        );
      } else {
        response = updateDataHelper(
          state.data, 'userProfile', vcdId,
          { attributes: { active: false } },
        );
      }

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorDeactivated' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorDeactivatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
      resetForm();
    }

    done();
  },
});

export default deactivateVirtualClinicDoctorOperation;
