import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import Spinner from 'views/shared/Spinner';

const SearchDiseasesLoaderComponent = ({
  isLoading,
  loadMore,
}) => {
  if (isLoading !== false) { return <Spinner isBig />; }

  return (
    <Waypoint
      onEnter={loadMore}
    />
  );
};

SearchDiseasesLoaderComponent.defaultProps = {
  isLoading: undefined,
};

SearchDiseasesLoaderComponent.propTypes = {
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
};

export default SearchDiseasesLoaderComponent;
