import React from 'react';
import PropTypes from 'prop-types';
import { last, prop } from 'ramda';

import LoadMoreLoaderComponent from './component';

class LoadMoreLoader extends React.Component {
  static propTypes = {
    loadMore: PropTypes.func.isRequired,
    meta: PropTypes.shape({ hasMore: PropTypes.bool }),
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  static defaultProps = {
    meta: {},
  }

  handleLoadMore = () => {
    const { loadMore, meta: { hasMore }, options } = this.props;

    if (hasMore) {
      loadMore(prop('id', last(options)));
    }
  }

  render = () => (
    <LoadMoreLoaderComponent
      {...this.props}
      onLoadMore={this.handleLoadMore}
    />
  );
}

export default LoadMoreLoader;
