export const VIRTUAL_CLINICS_STATUSES = {
  all: '',
  active: 'active',
  pending: 'pending',
  inactive: 'inactive',
  invited: 'invited',
};

export const VIRTUAL_CLINICS_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: VIRTUAL_CLINICS_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: VIRTUAL_CLINICS_STATUSES.pending,
    label: { id: 'shared.filters.statuses.pending' },
  },
  {
    value: VIRTUAL_CLINICS_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
];

export const VIRTUAL_CLINIC_PROFILE_TAB = {
  profile: 'profile',
  account: 'account',
  freeVisits: 'free_visits',
};
