export const SYSTEM_MESSAGES = {
  clinix: 'clinix',
  appointmentCreated: 'appointment_status',
  appointmentFinished: 'appointment_status.finished',
  appointmentNoteCreated: 'appointment_status.please_add_a_note',
  videoConferenceParticipantJoined: 'video_conference.participant_joined',
  videoConferenceParticipantLeft: 'video_conference.participant_left',
  videoConferenceDoctorDidNotJoined: 'video_conference.doctor_did_not_joined',
};

export const CHAT_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};

export const MARK_AS_READ_DELAY = 1000;

export const ATTACHMENTS_TABS = {
  files: 'files',
  records: 'records',
};

export const MAX_MESSAGE_SIZE = 10000;
export const MAX_FILE_SIZE = 100 * 1024 * 1024;

export const USER_NETWORK_STATUSES = {
  online: 'online',
  offline: 'offline',
};

export const NEW_MESSAGES_INDICATOR_TIMEOUT = 5000;

export const MAX_UPLOAD_FILE_LENGTH = 60;

export const SYSTEM_CHAT_USER = {
  firstName: 'Clinix',
  lastName: '',
  avatarUrls: {
    small: '/static/images/system_user_logo.svg',
  },
};
