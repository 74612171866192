import { pluck } from 'ramda';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { setUnreadIndicatorVisibility } from '../actions';
import { unreadMessagesSelector } from '../selectors';
import { markChatMessagesAsReadEndpoint } from '../endpoints';
import { MARK_ALL_MESSAGES_AS_READ } from '../types';

const markAllMessagesAsReadOperation = createLogic({
  type: MARK_ALL_MESSAGES_AS_READ,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const state = getState();
    const { chatId } = action;
    const unreadMessages = unreadMessagesSelector(state);
    const messageIds = pluck('id', unreadMessages);
    const { endpoint, url } = markChatMessagesAsReadEndpoint(chatId);

    try {
      if (!isEmpty(messageIds)) {
        const params = {
          message_ids: messageIds,
        };

        await httpClient.put(url, params);

        const response = updateDataHelper(
          state.data, 'message', messageIds,
          {
            attributes: {
              readAt: DateTime.local().toUTC(),
            },
          },
        );

        dispatch(dataApiSuccess({ response, endpoint }));
      }

      dispatch(setUnreadIndicatorVisibility(false));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default markAllMessagesAsReadOperation;
