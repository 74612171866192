import { combineReducers } from 'redux';

import { PATIENT_ACTIONS } from 'constants/patient';

import * as types from './types';

const transactionsSelectedTab = (state = PATIENT_ACTIONS.balanceTopUp, action) => {
  switch (action.type) {
    case types.SET_TRANSACTIONS_SELECTED_TAB:
      return action.selectedTab;
    default:
      return state;
  }
};

const transactionsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_TRANSACTIONS_IDS:
      return action.ids;
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'created_at', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_TRANSACTIONS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_TRANSACTIONS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

export default combineReducers({
  transactionsSelectedTab,
  sort,
  transactionsIds,
  pagination,
});
