import * as types from './types';

export const createMessage = (chatId, text) => ({
  type: types.CREATE_MESSAGE,
  chatId,
  text,
});

export const uploadFile = (chatId, file) => ({
  type: types.UPLOAD_FILE,
  chatId,
  file,
});

export const cancelUploadFile = () => ({
  type: types.CANCEL_UPLOAD_FILE,
});

export const setUploadProgress = (progress) => ({
  type: types.SET_UPLOAD_PROGRESS,
  progress,
});

export const updateMessage = (messageId, text) => ({
  type: types.UPDATE_MESSAGE,
  messageId,
  text,
});

export const setEditingMessage = message => ({
  type: types.SET_EDITING_MESSAGE,
  message,
});

export const resetEditingMessage = () => ({
  type: types.RESET_EDITING_MESSAGE,
});

export const removeMessage = messageId => ({
  type: types.REMOVE_MESSAGE,
  messageId,
});

export const removeMessageSuccess = messageId => ({
  type: types.REMOVE_MESSAGE_SUCCESS,
  messageId,
});

export const receiveMessages = (messages) => ({
  type: types.RECEIVE_MESSAGES,
  messages,
});

export const setMessages = messageIds => ({
  type: types.SET_MESSAGES,
  messageIds,
});

export const flushMessages = () => ({
  type: types.FLUSH_MESSAGES,
});

export const fetchMessages = (chatId, pageParams = {}, isScrollIntoView = false) => ({
  type: types.FETCH_MESSAGES,
  chatId,
  pageParams,
  isScrollIntoView,
});

export const markMessagesAsRead = (chatId, messageIds) => ({
  type: types.MARK_MESSAGES_AS_READ,
  chatId,
  messageIds,
});

export const fetchAttachments = chatId => ({
  type: types.FETCH_ATTACHMENTS,
  chatId,
});

export const setAttachments = attachmentsIds => ({
  type: types.SET_ATTACHMENTS,
  attachmentsIds,
});

export const destroyAttachments = () => ({
  type: types.DESTROY_ATTACHMENTS,
});

export const removeAttachment = (chatId, attachmentId) => ({
  type: types.REMOVE_ATTACHMENT,
  chatId,
  attachmentId,
});

export const removeAttachmentSuccess = attachmentId => ({
  type: types.REMOVE_ATTACHMENT_SUCCESS,
  attachmentId,
});

export const receiveUsersStatuses = data => ({
  type: types.RECEIVE_USERS_STATUSES,
  data,
});

export const setOnline = () => ({
  type: types.SET_ONLINE,
});

export const setOffline = () => ({
  type: types.SET_OFFLINE,
});

export const setUnreadIndicatorVisibility = isUnreadIndicatorVisible => ({
  type: types.SET_UNREAD_INDICATOR_VISIBILITY,
  isUnreadIndicatorVisible,
});

export const markAllMessagesAsRead = chatId => ({
  type: types.MARK_ALL_MESSAGES_AS_READ,
  chatId,
});

export const setChat = chatId => ({
  type: types.SET_CHAT,
  chatId,
});
