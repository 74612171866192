import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import validationSchema from 'lib/yupLocalised/schemas/virtualClinicAssignDoctorsToClinicSpecialityProvider';

import { handleSubmitWithProps } from 'utils/form/handleSubmit';
import { getDoctorsByClinicSpeciality } from 'utils/clinicSpecialities/getDoctorsByClinicSpeciality';

import {
  submitClinicSpecialityProviders,
  clinicSpecialityAssignDoctorsInitialState as clinicSpecialityAssignDoctorsInitialStateAction,
  clinicSpecialityAssignDoctorsResetState as clinicSpecialityAssignDoctorsResetStateAction,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import {
  userProfileClinicSpecialitiesListSelector,
  clinicSpecialitiesProvidersLoadingSelector,
} from 'state/concepts/userProfile/clinicSpecialities/selectors';

import AssignDoctorsModalComponent from './component';

class AssignDoctorsModal extends React.Component {
  static propTypes = {
    clinicSpecialityAssignDoctorsInitialState: PropTypes.func.isRequired,
    clinicSpecialityAssignDoctorsResetState: PropTypes.func.isRequired,
    clinicSpeciality: PropTypes.shape().isRequired,
  }

  componentDidMount() {
    const { clinicSpecialityAssignDoctorsInitialState, clinicSpeciality: { id } } = this.props;

    clinicSpecialityAssignDoctorsInitialState(id);
  }

  componentWillUnmount() {
    const { clinicSpecialityAssignDoctorsResetState } = this.props;

    clinicSpecialityAssignDoctorsResetState();
  }

  render() {
    return (
      <AssignDoctorsModalComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, { clinicSpeciality: { id } }) => ({
  isLoading: clinicSpecialitiesProvidersLoadingSelector(state, id),
  clinicSpecialitiesList: userProfileClinicSpecialitiesListSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: submitClinicSpecialityProviders,
  clinicSpecialityAssignDoctorsInitialState: clinicSpecialityAssignDoctorsInitialStateAction,
  clinicSpecialityAssignDoctorsResetState: clinicSpecialityAssignDoctorsResetStateAction,
};

export { AssignDoctorsModal as AssignDoctorsModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ clinicSpecialitiesList }) => ({
      doctors: getDoctorsByClinicSpeciality(clinicSpecialitiesList),
      isAssignDoctors: true,
    }),
    validationSchema,
    enableReinitialize: true,
    handleSubmit: handleSubmitWithProps(['clinicSpeciality']),
  }),
)(AssignDoctorsModal);
