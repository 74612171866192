import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Settings } from 'luxon';

import buildHttpClient from 'lib/httpClient';

import conceptsOperations from '../concepts/operationsRoot';

import rootReducer from './reducer';

const operations = [
  ...conceptsOperations,
];

const configureStore = (preloadedState) => {
  const operationsDependencies = {
    httpClient: buildHttpClient(),
    rootHttpClient: buildHttpClient(),
  };

  // https://github.com/jeffbski/redux-logic/issues/83#issuecomment-623204978
  const mwBatchOne = createLogicMiddleware(operations.slice(0, 150), operationsDependencies);
  const mwBatchTwo = createLogicMiddleware(operations.slice(150), operationsDependencies);
  const middlewares = applyMiddleware(mwBatchOne, mwBatchTwo);

  const enhancer = composeWithDevTools(middlewares);
  const store = createStore(rootReducer, preloadedState, enhancer);

  store.logicMiddlewares = [mwBatchOne, mwBatchTwo];
  store.httpClient = operationsDependencies.httpClient;

  Settings.defaultLocale = store.getState().intl.locale;

  return store;
};

export default configureStore;
