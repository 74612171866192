import { dissoc } from 'ramda';

import { SET_SEARCH_RESULTS, RESET_SEARCH_RESULTS } from './types';

const searchResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      return { ...state, ...action.searchParams };
    case RESET_SEARCH_RESULTS:
      return dissoc(action.resetPath, state);
    default:
      return state;
  }
};

export default searchResultsReducer;
