import * as types from './types';

export const setIsExpanded = isExpanded => ({
  type: types.SET_IS_EXPANDED,
  isExpanded,
});

export const skipStep = (step, stepEndpoint) => ({
  type: types.SKIP_STEP,
  step,
  stepEndpoint,
});

export const finishTutorial = () => ({
  type: types.FINISH_TUTORIAL,
});
