import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSearchResults } from 'state/searchResults/actions';
import { fetchAllSpecialitiesEndpoint } from '../endpoints';
import { FETCH_ALL_SPECIALITIES } from '../types';

const fetchAllSpecialitiesOperation = createLogic({
  type: FETCH_ALL_SPECIALITIES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const params = {
      page: { size: 100, number: 1 },
    };
    const { url, endpoint } = fetchAllSpecialitiesEndpoint;
    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      const ids = pluck('id', data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSearchResults({ specialities: ids }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchAllSpecialitiesOperation;
