import PropTypes from 'prop-types';

import SearchList from 'views/shared/SearchList';

const SearchComponent = ({ searchQuery, filterDiseases }) => (
  <SearchList
    defaultValue={searchQuery}
    filterEntities={filterDiseases}
  />
);

SearchComponent.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  filterDiseases: PropTypes.func.isRequired,
};

export default SearchComponent;
