import { FormattedMessage } from 'react-intl';

import ErrorLayout from 'views/layouts/Error';

const ServerError = () => (
  <ErrorLayout>
    <div className="row align-items-md-center justify-content-center">
      <div className="col-sm-8 col-md-5">
        <div className="error-page__info">
          <div className="error-page-title">
            <FormattedMessage id="serverErrorPage.title" />
          </div>
          <p className="error-page-txt text-subheader mb-0">
            <FormattedMessage id="serverErrorPage.body" />
          </p>
        </div>
      </div>
      <div className="col-sm-8 col-md-5">
        <div className="error-page-img">
          <img src="/static/images/errors/ill-500-error.svg" alt="" />
        </div>
      </div>
    </div>
  </ErrorLayout>
);

export default ServerError;
