import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { AUTH_FLOW } from 'constants/auth';
import { USER_TYPE } from 'constants/users';
import { ONBOARDING_STATUSES } from 'constants/onboarding';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';
import assignFormErrors from 'utils/form/assignFormErrors';
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { userLoginSuccess, setVisibleAuthFlow } from '../actions';
import { userLoginEndpoint } from '../endpoints';
import { SUBMIT_USER_LOGIN } from '../types';

const submitUserLoginOperation = createLogic({
  type: SUBMIT_USER_LOGIN,
  latest: true,

  async process({ rootHttpClient, action: { values, form } }, dispatch, done) {
    const { endpoint, url } = userLoginEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        email: values.email,
        password: values.password,
      };

      const { data } = await rootHttpClient.post(url, params);
      const response = normalize(data);
      const currentUser = build(response, 'userProfile')[0];
      const { id, reset2Fa, onboardingStatus } = currentUser;
      const tokens = data.meta.jwt;

      dispatch(userLoginSuccess(id));
      dispatch(dataApiSuccess({ response, endpoint }));

      if (reset2Fa || onboardingStatus === ONBOARDING_STATUSES.auth) {
        setUserCookies(currentUser, tokens, USER_TYPE.userOnboarding);
        redirect({ href: '/onboarding' });
      } else {
        setUserCookies(currentUser, tokens);
        dispatch(setVisibleAuthFlow(AUTH_FLOW.verification));
      }
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error);
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitUserLoginOperation;
