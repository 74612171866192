import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { searchDiseases as searchDiseasesAction } from 'state/concepts/userProfile/diseases/actions';
import { searchDiseasesLoadingSelector, searchDiseasesMetaSelector } from 'state/concepts/userProfile/diseases/selectors';

import SearchDiseasesLoaderComponent from './component';

class SearchDiseasesLoader extends React.Component {
  static propTypes = {
    searchDiseases: PropTypes.func.isRequired,
    meta: PropTypes.shape({ hasMore: PropTypes.bool }),
  };

  static defaultProps = {
    meta: {},
  };

  componentDidMount() {
    const { searchDiseases } = this.props;

    searchDiseases();
  }

  loadMore = () => {
    const { searchDiseases, meta: { hasMore } } = this.props;

    if (hasMore) {
      searchDiseases();
    }
  };

  render = () => (
    <SearchDiseasesLoaderComponent
      {...this.props}
      loadMore={this.loadMore}
    />
  )
}

const mapStateToProps = state => ({
  isLoading: searchDiseasesLoadingSelector(state),
  meta: searchDiseasesMetaSelector(state),
});

const mapDispatchToProps = {
  searchDiseases: searchDiseasesAction,
};

export { SearchDiseasesLoader as SearchDiseasesLoaderContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SearchDiseasesLoader);
