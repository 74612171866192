import { createSelector } from 'reselect';
import build from 'redux-object';
import { path, prop, isEmpty } from 'ramda';
import { DateTime } from 'luxon';

import isPresent from 'utils/isPresent';
import { isVirtualClinicAdmin } from 'utils/roles';
import { isRevenueVirtualType } from 'utils/revenue';
import buildCollection from 'utils/buildCollection';

import { currentUserSelector } from 'state/concepts/session/selectors';
import { doctorListSelector } from 'state/concepts/userProfile/doctors/selectors';
import { fetchAllDoctorsEndpoint } from 'state/concepts/userProfile/doctors/endpoints';
import { loadingSelector, endpointMetaSelector, pageCountSelector } from 'state/data/selectors';
import { virtualClinicDoctorListSelector, virtualClinicDoctorIdSelector } from 'state/concepts/userProfile/virtualClinicDoctors/selectors';
import { fetchVirtualClinicDoctorsEndpoint } from 'state/concepts/userProfile/virtualClinicDoctors/endpoints';

import {
  fetchRevenueEndpoint,
  fetchRevenueVirtualClinicsEndpoint,
  fetchRevenueExtraStatisticEndpoint,
  fetchRevenueVirtualClinicDoctorsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const revenueExtraStatisticIdSelector = path(['revenue', 'revenueExtraStatisticId']);
export const revenueAppointmentIdsSelector = path(['revenue', 'revenueAppointmentIds']);
export const revenueTypeSelector = path(['revenue', 'revenueType']);
export const revenueVirtualClinicIdSelector = path(['revenue', 'revenueVirtualClinicId']);
export const revenueVirtualClinicIdsSelector = path(['revenue', 'revenueVirtualClinicIds']);
export const revenueVirtualClinicDoctorIdsSelector = path(['revenue', 'revenueVirtualClinicDoctorIds']);
export const revenueBlankStateSelector = path(['revenue', 'revenueBlankState']);
export const revenuePaginationSelector = path(['revenue', 'revenuePagination']);
export const revenueFiltersSelector = path(['revenue', 'revenueFilters']);
export const revenueUserProfileIdSelector = path(['revenue', 'revenueFilters', 'userProfileId']);
export const revenueSortSelector = path(['revenue', 'revenueSort']);

export const revenueExtraStatisticSelector = createSelector(
  revenueExtraStatisticIdSelector,
  dataSelector,
  (id, data) => (id && !isEmpty(id) ? build(data, 'extraStatistic', id) : null),
);

export const revenueExtraStatisticLoadingSelector = state => (
  loadingSelector(state, fetchRevenueExtraStatisticEndpoint.endpoint)
);

export const revenueAppointmentsSelector = createSelector(
  revenueAppointmentIdsSelector,
  dataSelector,
  buildCollection('appointment'),
);

export const revenueVirtualClinicsSelector = createSelector(
  revenueVirtualClinicIdsSelector,
  dataSelector,
  buildCollection('clinic'),
);

export const revenueVirtualClinicSelector = createSelector(
  revenueVirtualClinicIdSelector,
  dataSelector,
  (id, data) => (id && !isEmpty(id) ? build(data, 'clinic', id) : null),
);

export const revenueStatisticsMetaSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const clinicId = revenueVirtualClinicIdSelector(state);
  let endpoint;

  if (isRevenueVirtualType(revenueType) && !isPresent(clinicId)) {
    endpoint = fetchRevenueVirtualClinicsEndpoint.endpoint;
  } else {
    endpoint = fetchRevenueEndpoint.endpoint;
  }

  return endpointMetaSelector(state, endpoint);
};

export const revenueLoadingSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const clinicId = revenueVirtualClinicIdSelector(state);
  let endpoint;

  if (isRevenueVirtualType(revenueType) && !isPresent(clinicId)) {
    endpoint = fetchRevenueVirtualClinicsEndpoint.endpoint;
  } else {
    endpoint = fetchRevenueEndpoint.endpoint;
  }

  return loadingSelector(state, endpoint);
};

export const revenuePageCountSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const clinicId = revenueVirtualClinicIdSelector(state);
  let endpoint;

  if (isRevenueVirtualType(revenueType) && !isPresent(clinicId)) {
    endpoint = fetchRevenueVirtualClinicsEndpoint.endpoint;
  } else {
    endpoint = fetchRevenueEndpoint.endpoint;
  }

  return pageCountSelector(state, endpoint);
};

export const revenueCurrentPageSelector = createSelector(
  revenuePaginationSelector,
  prop('number'),
);

export const revenueSortParamsSelector = createSelector(
  revenueSortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const selectedRevenueUserProfileSelector = createSelector(
  revenueUserProfileIdSelector,
  dataSelector,
  (id, data) => (data.userProfile && data.userProfile[id] ? build(data, 'userProfile', id) : null),
);

export const selectedRevenueVirtualClinicDoctorProfileSelector = createSelector(
  virtualClinicDoctorIdSelector,
  dataSelector,
  (id, data) => (data.virtualClinicDoctor && data.virtualClinicDoctor[id] ? build(data, 'virtualClinicDoctor', id) : null),
);

export const selectedRevenueDoctorProfileSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const currentUser = currentUserSelector(state);

  return isRevenueVirtualType(revenueType) && isVirtualClinicAdmin(currentUser.roleName)
    ? selectedRevenueVirtualClinicDoctorProfileSelector(state)
    : selectedRevenueUserProfileSelector(state);
};

export const revenueDoctorsListSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const currentUser = currentUserSelector(state);

  return isRevenueVirtualType(revenueType) && isVirtualClinicAdmin(currentUser.roleName)
    ? virtualClinicDoctorListSelector(state)
    : doctorListSelector(state);
};

export const revenueDoctorsListLoadingSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const currentUser = currentUserSelector(state);
  const endpoint = isRevenueVirtualType(revenueType) && isVirtualClinicAdmin(currentUser.roleName)
    ? fetchVirtualClinicDoctorsEndpoint.endpoint
    : fetchAllDoctorsEndpoint.endpoint;

  return loadingSelector(state, endpoint);
};

export const revenueDoctorsListMetaSelector = state => {
  const revenueType = revenueTypeSelector(state);
  const currentUser = currentUserSelector(state);
  const endpoint = isRevenueVirtualType(revenueType) && isVirtualClinicAdmin(currentUser.roleName)
    ? fetchVirtualClinicDoctorsEndpoint.endpoint
    : fetchAllDoctorsEndpoint.endpoint;

  return endpointMetaSelector(state, endpoint);
};

export const filterDateFromISOSelector = createSelector(
  revenueFiltersSelector,
  ({ startDate, endDate }) => [DateTime.fromISO(startDate), DateTime.fromISO(endDate)],
);

export const revenueVirtualClinicDoctorsSelector = createSelector(
  revenueVirtualClinicDoctorIdsSelector,
  dataSelector,
  buildCollection('userProfile'),
);

export const revenueVirtualClinicDoctorsLoadingSelector = state => {
  const clinicId = revenueVirtualClinicIdSelector(state);
  const { endpoint } = fetchRevenueVirtualClinicDoctorsEndpoint(clinicId);

  return loadingSelector(state, endpoint);
};

export const revenueVirtualClinicDoctorsMetaSelector = state => {
  const clinicId = revenueVirtualClinicIdSelector(state);
  const { endpoint } = fetchRevenueVirtualClinicDoctorsEndpoint(clinicId);

  return endpointMetaSelector(state, endpoint);
};
