import * as types from './types';

export const getRevenue = () => ({
  type: types.GET_REVENUE,
});

export const fetchRevenue = () => ({
  type: types.FETCH_REVENUE,
});

export const fetchRevenueVirtualClinics = () => ({
  type: types.FETCH_REVENUE_VIRTUAL_CLINICS,
});

export const setRevenueInitialState = (revenueType, virtualClinicId) => ({
  type: types.SET_REVENUE_INITIAL_STATE,
  revenueType,
  virtualClinicId,
});

export const resetRevenue = () => ({
  type: types.RESET_REVENUE,
});

export const fetchRevenueExtraStatistic = () => ({
  type: types.FETCH_REVENUE_EXTRA_STATISTIC,
});

export const setRevenueExtraStatisticId = revenueExtraStatisticId => ({
  type: types.SET_REVENUE_EXTRA_STATISTIC_ID,
  revenueExtraStatisticId,
});

export const setRevenueAppointmentIds = revenueAppointmentIds => ({
  type: types.SET_REVENUE_APPOINTMENT_IDS,
  revenueAppointmentIds,
});

export const setRevenueType = revenueType => ({
  type: types.SET_REVENUE_TYPE,
  revenueType,
});

export const setRevenueVirtualClinicIds = revenueVirtualClinicIds => ({
  type: types.SET_REVENUE_VIRTUAL_CLINIC_IDS,
  revenueVirtualClinicIds,
});

export const setRevenueVirtualClinicId = revenueVirtualClinicId => ({
  type: types.SET_REVENUE_VIRTUAL_CLINIC_ID,
  revenueVirtualClinicId,
});

export const fetchRevenueDoctorsList = lastId => ({
  type: types.FETCH_REVENUE_DOCTORS_LIST,
  lastId,
});

export const resetRevenueDoctorsList = () => ({
  type: types.RESET_REVENUE_DOCTORS_LIST,
});

export const filterRevenueDoctorsList = filters => ({
  type: types.SET_REVENUE_DOCTORS_LIST_FILTERS,
  filters,
});

export const filterRevenueVirtualClinicDoctors = filters => ({
  type: types.SET_REVENUE_VIRTUAL_CLINIC_DOCTORS_FILTERS,
  filters,
});

export const setRevenueBlankState = isBlankState => ({
  type: types.SET_REVENUE_BLANK_STATE,
  isBlankState,
});

export const setRevenueCurrentPage = pageNumber => ({
  type: types.SET_REVENUE_PAGE,
  pageNumber,
});

export const filterRevenue = (filters, endpoint) => ({
  type: types.SET_REVENUE_FILTERS,
  filters,
  endpoint,
});

export const setRevenueFilterParams = filterParams => ({
  type: types.SET_REVENUE_FILTER_PARAMS,
  filterParams,
});

export const resetRevenueFilterParams = () => ({
  type: types.RESET_REVENUE_FILTER_PARAMS,
});

export const setRevenueSortOrder = sortKey => ({
  type: types.SET_REVENUE_SORT_ORDER,
  sortKey,
});

export const getDownloadRevenue = intlFormatMessage => ({
  type: types.GET_DOWNLOAD_REVENUE,
  intlFormatMessage,
});

export const downloadRevenue = intlFormatMessage => ({
  type: types.DOWNLOAD_REVENUE,
  intlFormatMessage,
});

export const downloadRevenueDoctors = intlFormatMessage => ({
  type: types.DOWNLOAD_REVENUE_DOCTORS,
  intlFormatMessage,
});

export const downloadRevenueVirtualClinicAppointments = intlFormatMessage => ({
  type: types.DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS,
  intlFormatMessage,
});

export const downloadRevenueIndependentDoctorAppointments = intlFormatMessage => ({
  type: types.DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS,
  intlFormatMessage,
});

export const fetchRevenueVirtualClinicDoctors = lastId => ({
  type: types.FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS,
  lastId,
});

export const setRevenueVirtualClinicDoctorIds = revenueVirtualClinicDoctorIds => ({
  type: types.SET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS,
  revenueVirtualClinicDoctorIds,
});

export const resetRevenueVirtualClinicDoctorIds = () => ({
  type: types.RESET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS,
});
