import fetchVirtualClinicDoctor from './fetchVirtualClinicDoctor';
import fetchVirtualClinicDoctors from './fetchVirtualClinicDoctors';
import fetchVirtualClinicDoctorList from './fetchVirtualClinicDoctorList';
import filterVirtualClinicDoctors from './filterVirtualClinicDoctors';
import submitVCDJoinRequest from './submitVCDJoinRequest';
import deactivateVirtualClinicDoctor from './deactivateVirtualClinicDoctor';
import activateVirtualClinicDoctor from './activateVirtualClinicDoctor';
import updateVirtualClinicDoctor from './updateVirtualClinicDoctor';
import removeVirtualClinicDoctorInvitation from './removeVirtualClinicDoctorInvitation';
import submitManageClinicSpeciality from './submitManageClinicSpeciality';

export default [
  fetchVirtualClinicDoctor,
  fetchVirtualClinicDoctors,
  fetchVirtualClinicDoctorList,
  filterVirtualClinicDoctors,
  submitVCDJoinRequest,
  deactivateVirtualClinicDoctor,
  activateVirtualClinicDoctor,
  updateVirtualClinicDoctor,
  removeVirtualClinicDoctorInvitation,
  submitManageClinicSpeciality,
];
