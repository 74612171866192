import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';

import { submitPatientProfileEndpoint } from '../endpoints';
import { SUBMIT_PATIENT_PROFILE } from '../types';

const submitPatientProfileOperation = createLogic({
  type: SUBMIT_PATIENT_PROFILE,
  latest: true,

  async process({ httpClient, getState, action: { patientId, values, form } }, dispatch, done) {
    const state = getState();
    const { url, endpoint } = submitPatientProfileEndpoint(patientId);

    const params = {
      first_name: values.firstName,
      last_name: values.lastName,
      citizen_id: values.citizenId,
      wellness: values.wellness,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'patientProfile', patientId,
        {
          attributes: {
            firstName: values.firstName,
            lastName: values.lastName,
            citizenId: values.citizenId,
            wellness: values.wellness,
           },
        },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({
        messageObject: { id: 'notifications.patientProfileUpdated' },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPatientProfileOperation;
