import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { getIn } from 'formik';
import { is } from 'ramda';

import passwordStrengthData from 'utils/passwordStrengthData';

import PasswordInputFieldComponent from './component';

class PasswordInputField extends React.Component {
  static propTypes = {
    visiblePassword: PropTypes.bool,
    checkPasswordStrength: PropTypes.bool,
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape({
      touched: PropTypes.shape().isRequired,
      errors: PropTypes.shape().isRequired,
    }).isRequired,
  }

  static defaultProps = {
    visiblePassword: false,
    checkPasswordStrength: false,
  }

  state = {
    strengthData: {},
    visible: false,
  }

  componentDidMount() {
    const { checkPasswordStrength } = this.props;

    if (checkPasswordStrength) { this.checkPasswordStrength(); }
  }

  componentDidUpdate(prevProps) {
    const { checkPasswordStrength, field: { value } } = this.props;

    if (checkPasswordStrength && prevProps.field.value !== value) {
      this.checkPasswordStrength();
    }
  }

  checkPasswordStrength = debounce(async () => {
    const { field: { value } } = this.props;

    const strengthData = await passwordStrengthData(value, this.isTouchedWithErrors);
    this.setState({ strengthData });
  }, 250);

  get inputType() {
    const { visiblePassword } = this.props;
    const { visible } = this.state;

    return visiblePassword && visible ? 'text' : 'password';
  }

  get isTouchedWithErrors() {
    const { field: { name }, form: { touched, errors } } = this.props;
    const fieldTouched = getIn(touched, name);
    const fieldErrors = getIn(errors, name);

    return fieldTouched && (is(Object, fieldErrors) || is(String, fieldErrors));
  }

  handleChange = (event) => {
    const { field } = this.props;

    field.onChange(event);
  };

  handleVisibilityChange = () => {
    this.setState(({ visible }) => ({ visible: !visible }));
  }

  render = () => (
    <PasswordInputFieldComponent
      {...this.state}
      {...this.props}
      inputType={this.inputType}
      isTouchedWithErrors={this.isTouchedWithErrors}
      onChange={this.handleChange}
      onFocus={this.handleFocus}
      onBlur={this.handleBlur}
      onVisibilityChange={this.handleVisibilityChange}
    />
  );
}

export default PasswordInputField;
