import { createSelector } from 'reselect';
import build from 'redux-object';
import { prop, path, propOr, pathOr } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, endpointMetaSelector, totalCountSelector, pageCountSelector } from 'state/data/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import {
  fetchDoctorProfileEndpoint,
  fetchAllDoctorsEndpoint,
  activateDoctorEndpoint,
  deactivateDoctorEndpoint,
  resetAuthenticationEndpoint,
  removeDoctorInvitationEndpoint,
  updateDoctorProfileEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['doctors', 'isBlankState']);
export const paginationSelector = path(['doctors', 'pagination']);
export const doctorsIdsSelector = path(['doctors', 'doctorsIds']);
export const doctorListIdsSelector = path(['doctors', 'doctorListIds']);
export const doctorListStatusesSelector = path(['doctors', 'doctorListStatuses']);
export const doctorListFilterParamsSelector = path(['doctors', 'doctorListFilterParams']);
export const doctorListClinicSpecialityIdSelector = path(['doctors', 'doctorListClinicSpecialityId']);
export const doctorProfileIdSelector = path(['doctors', 'doctorProfileId']);
export const sortSelector = path(['doctors', 'sort']);
export const rolesSelector = path(['doctors', 'roles']);

export const doctorProfileSelector = createSelector(
  doctorProfileIdSelector,
  dataSelector,
  (id, data) => (data.userProfile && data.userProfile[id] ? build(data, 'userProfile', id) : null),
);

export const doctorProfileClinicSpecialitySelector = createSelector(
  doctorProfileSelector,
  propOr([], 'userProfilesClinicSpecialities'),
);

export const doctorAvatarSelector = createSelector(
  doctorProfileSelector,
  pathOr(null, ['avatarUrls', 'original']),
);

export const doctorSignatureSelector = createSelector(
  doctorProfileSelector,
  pathOr(null, ['signatureUrls', 'original']),
);

export const userSignatureSelector = createSelector(
  currentUserSelector,
  pathOr(null, ['signatureUrls', 'original']),
);

export const doctorsSelector = createSelector(
  doctorsIdsSelector,
  dataSelector,
  buildCollection('userProfile'),
);

export const doctorListSelector = createSelector(
  doctorListIdsSelector,
  dataSelector,
  buildCollection('userProfile'),
);

export const userProfileClinicSpecialitiesSelector = createSelector(
  (_, specialityIds) => specialityIds,
  dataSelector,
  buildCollection('userProfilesClinicSpeciality'),
);

export const userProfileClinicSpecialitiesListSelector = createSelector(
  doctorListIdsSelector,
  dataSelector,
  buildCollection('userProfilesClinicSpeciality'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const doctorsTotalCountSelector = state => (
  totalCountSelector(state, fetchAllDoctorsEndpoint.endpoint)
);

export const doctorsPageCountSelector = state => (
  pageCountSelector(state, fetchAllDoctorsEndpoint.endpoint)
);

export const doctorsMetaSelector = state => (
  endpointMetaSelector(state, fetchAllDoctorsEndpoint.endpoint)
);

export const doctorsLoadingSelector = state => (
  loadingSelector(state, fetchAllDoctorsEndpoint.endpoint)
);

export const activateDoctorLoadingSelector = (state, doctorId) => (
  loadingSelector(state, activateDoctorEndpoint(doctorId).endpoint)
);

export const deactivateDoctorLoadingSelector = (state, doctorId) => (
  loadingSelector(state, deactivateDoctorEndpoint(doctorId).endpoint)
);

export const resetAuthDoctorLoadingSelector = (state, doctorId) => (
  loadingSelector(state, resetAuthenticationEndpoint(doctorId).endpoint)
);

export const removeDoctorInvitationLoadingSelector = (state, doctorId) => (
  loadingSelector(state, removeDoctorInvitationEndpoint(doctorId).endpoint)
);

export const doctorProfileLoadingSelector = state => {
  const doctorId = doctorProfileIdSelector(state);

  return loadingSelector(state, fetchDoctorProfileEndpoint(doctorId).endpoint);
};

export const updateDoctorProfileLoadingSelector = state => {
  const doctorId = doctorProfileIdSelector(state);

  return loadingSelector(state, updateDoctorProfileEndpoint(doctorId).endpoint);
};
