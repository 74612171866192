import { combineReducers } from 'redux';
import { uniq, without } from 'ramda';

import { USER_NETWORK_STATUSES } from 'constants/chat';

import * as types from './types';

const messageIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_MESSAGES:
      return uniq([...state, ...action.messageIds]);
    case types.FLUSH_MESSAGES:
      return [];
    case types.REMOVE_MESSAGE_SUCCESS:
      return without(action.messageId, state);
    default:
      return state;
  }
};

const attachmentsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_ATTACHMENTS:
      return uniq([...action.attachmentsIds, ...state]);
    case types.REMOVE_ATTACHMENT_SUCCESS:
        return without(action.attachmentId, state);
    case types.DESTROY_ATTACHMENTS:
        return [];
    default:
      return state;
  }
};

const editingMessage = (state = null, action) => {
  switch (action.type) {
    case types.SET_EDITING_MESSAGE:
      return action.message;
    case types.RESET_EDITING_MESSAGE:
      return null;
    default:
      return state;
  }
};

const patientStatus = (state = USER_NETWORK_STATUSES.offline, action) => {
  switch (action.type) {
    case types.RECEIVE_USERS_STATUSES:
      return action.data.patientStatus || state;
    default:
      return state;
  }
};

const isOnline = (state = true, action) => {
  switch (action.type) {
    case types.SET_ONLINE:
      return true;
    case types.SET_OFFLINE:
      return false;
    default:
      return state;
  }
};

const isUnreadIndicatorVisible = (state = true, action) => {
  switch (action.type) {
    case types.SET_UNREAD_INDICATOR_VISIBILITY:
      return action.isUnreadIndicatorVisible;
    default:
      return state;
  }
};

const uploadFileName = (state = null, action) => {
  switch (action.type) {
    case types.UPLOAD_FILE:
      return action.file.name;
    default:
      return state;
  }
};

const uploadProgress = (state = null, action) => {
  switch (action.type) {
    case types.SET_UPLOAD_PROGRESS:
      return action.progress;
    default:
      return state;
  }
};

const chatId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CHAT:
      return action.chatId;
    default:
      return state;
  }
};

export default combineReducers({
  messageIds,
  attachmentsIds,
  editingMessage,
  patientStatus,
  isOnline,
  isUnreadIndicatorVisible,
  uploadFileName,
  uploadProgress,
  chatId,
});
