import PropTypes from 'prop-types';

import isPresent from 'utils/isPresent';

import Button from 'views/shared/Button';
import NoFetchResult from 'views/shared/NoFetchResult';

import Tree from '../Tree';
import Search from './Search';
import SearchDiseasesLoader from './SearchDiseasesLoader';

const OtherDiagnosesComponent = ({
  onChangeOtherDiagnosesVisible,
  diseases,
  getDiseasesNodes,
  onSetDisease,
  searchQuery,
  isLoading,
}) => (
  <>
    <Button
      className="mb-16"
      text={{ id: 'note.topDiagnoses' }}
      icon="arrow-left"
      kind="back"
      onClick={onChangeOtherDiagnosesVisible}
    />
    <Search />
    {!isPresent(diseases) && isLoading === false
      ? <NoFetchResult src="/static/images/auth/empty_search.svg" />
      : (
        <div className="modal-diagnose__other-diagnoses">
          <Tree
            options={diseases}
            expandAction={getDiseasesNodes}
            selectAction={onSetDisease}
            searchQuery={searchQuery}
          />
        </div>
      )
    }
    {isPresent(searchQuery) && <SearchDiseasesLoader />}
  </>
);

OtherDiagnosesComponent.defaultProps = {
  isLoading: undefined,
};

OtherDiagnosesComponent.propTypes = {
  onChangeOtherDiagnosesVisible: PropTypes.func.isRequired,
  getDiseasesNodes: PropTypes.func.isRequired,
  onSetDisease: PropTypes.func.isRequired,
  diseases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchQuery: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default OtherDiagnosesComponent;
