import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNil, path, prop } from 'ramda';

import { AVATARS_COLORS_MATRIX } from 'constants/colors';

import profileInitials from 'utils/profileInitials';
import colorsGenerator from 'utils/colorsGenerator';
import { isInactive, isRejected } from 'utils/doctors';

import SvgIcon from 'views/shared/SvgIcon';

const AvatarComponent = ({
  avatarClassName,
  size,
  user,
  imageSize,
  online,
}) => {
  if (!user) { return null; }

  const { invited } = user;
  const avatar = path(['avatarUrls', imageSize], user);
  const initials = profileInitials(user);
  const avatarClassNames = classNames(avatarClassName, 'avatar', {
    'avatar--lg': size === 'large',
    'avatar--invited': invited || isInactive(user),
    'avatar--x-large': size === 'x-large',
  });

  if (!isNil(avatar)) {
    return (
      <div className={avatarClassNames}>
        <img className="avatar__img" src={avatar} alt="" />
        {online && <div className="avatar__online-indicator" />}
      </div>
    );
  }

  return (
    <div className={classNames(
      avatarClassNames,
      colorsGenerator(prop('firstName', user), AVATARS_COLORS_MATRIX),
    )}
    >
      {(invited || isInactive(user)) ? (
        <>
          <SvgIcon icon="user" className="avatar__icon-invite" />
          {!isRejected(user) && <SvgIcon icon="time" className="avatar__icon" />}
        </>
      ) : (
        <>{initials}</>
      )}
      {online && <div className="avatar__online-indicator" />}
    </div>
  );
};

AvatarComponent.defaultProps = {
  avatarClassName: null,
  size: 'medium',
  imageSize: 'original',
  user: null,
  online: false,
};

AvatarComponent.propTypes = {
  user: PropTypes.shape(),
  avatarClassName: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large', 'x-large']),
  imageSize: PropTypes.string,
  online: PropTypes.bool,
};

export default AvatarComponent;
