import submitNewPassword from './submitNewPassword';
import submitResetPassword from './submitResetPassword';
import validateResetPasswordToken from './validateResetPasswordToken';
import resetPasswordInAccount from './resetPasswordInAccount';

export default [
  submitNewPassword,
  submitResetPassword,
  validateResetPasswordToken,
  resetPasswordInAccount,
];
