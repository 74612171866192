import endpoint from 'utils/endpoint';
import {
  usersRoute,
  usersInvitationsRoute,
  userInvitationsResendRoute,
  userInvitationsRoute,
  usersResetAuthenticationRoute,
  virtualClinicsDeactivateRoute,
  virtualClinicsActivateRoute,
  clinicRoute,
  clinicUpdateLogoRoute,
  clinicDestroyLogoRoute,
  clinicAcceptExtraFreeVisitsRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchVirtualClinicsEndpoint = endpoint(types.FETCH_VIRTUAL_CLINICS, 'GET', usersRoute);
export const virtualClinicsInvitationsEndpoint = endpoint(types.FETCH_VIRTUAL_CLINICS, 'POST', usersInvitationsRoute);
export const resendVirtualClinicInvitationEndpoint = virtualClinicId => endpoint(types.RESEND_VIRTUAL_CLINIC_INVITATION, 'POST', userInvitationsResendRoute(virtualClinicId));
export const removeVirtualClinicInvitationEndpoint = virtualClinicId => endpoint(types.REMOVE_VIRTUAL_CLINIC_INVITATION, 'DELETE', userInvitationsRoute(virtualClinicId));
export const resetAuthenticationVirtualClinicEndpoint = virtualClinicId => endpoint(types.RESET_AUTHENTICATION_VIRTUAL_CLINIC, 'PUT', usersResetAuthenticationRoute(virtualClinicId));
export const deactivateVirtualClinicEndpoint = clinicId => endpoint(types.DEACTIVATE_VIRTUAL_CLINIC, 'PUT', virtualClinicsDeactivateRoute(clinicId));
export const activateVirtualClinicEndpoint = clinicId => endpoint(types.ACTIVATE_VIRTUAL_CLINIC, 'PUT', virtualClinicsActivateRoute(clinicId));
export const fetchVirtualClinicEndpoint = clinicId => endpoint(types.FETCH_VIRTUAL_CLINIC, 'GET', clinicRoute(clinicId));
export const submitVirtualClinicEndpoint = clinicId => endpoint(types.SUBMIT_VIRTUAL_CLINIC_PROFILE, 'PUT', clinicRoute(clinicId));
export const uploadVirtualClinicLogoEndpoint = clinicId => endpoint(types.UPLOAD_VIRTUAL_CLINIC_LOGO, 'PUT', clinicUpdateLogoRoute(clinicId));
export const removeVirtualClinicLogoEndpoint = clinicId => endpoint(types.REMOVE_VIRTUAL_CLINIC_LOGO, 'DELETE', clinicDestroyLogoRoute(clinicId));
export const updateVirtualClinicExtraFreeVisitsEndpoint = clinicId => endpoint(types.UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS, 'PUT', clinicAcceptExtraFreeVisitsRoute(clinicId));
