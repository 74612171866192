import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const setTwoFactorAuthPasswordEntered = isPasswordEntered => ({
  type: types.SET_TWO_FACTOR_AUTH_PASSWORD_ENTERED,
  isPasswordEntered,
});

export const submitSetupTwoFactorAuth = makeFormSubmitAction(types.SUBMIT_SETUP_TWO_FACTOR_AUTH);
export const submitAccountVerification = makeFormSubmitAction(types.SUBMIT_ACCOUNT_TWO_FACTOR_AUTH);
export const submitUpdateUserPassword = makeFormSubmitAction(types.SUBMIT_UPDATE_USER_PASSWORD);
export const submitUpdateUserEmail = makeFormSubmitAction(types.SUBMIT_UPDATE_USER_EMAIL);

export const confirmUserEmail = token => ({
  type: types.CONFIRM_USER_EMAIL,
  token,
});

export const updateNotificationSetting = (values, form) => ({
  type: types.UPDATE_NOTIFICATION_SETTING,
  values,
  form,
});
