import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cond, equals, T, always } from 'ramda';

import { errorStatusCodeSelector } from 'state/concepts/errors/selectors';

import ServerError from 'views/errors/ServerError';
import ClientError from 'views/errors/ClientError';
import AccessError from 'views/errors/AccessError';

class ErrorHandler extends React.Component {
  static propTypes = {
    errorStatus: PropTypes.number,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    errorStatus: null,
  }

  render() {
    const { errorStatus, children } = this.props;

    const component = cond([
      [equals(403), always(<AccessError />)],
      [equals(404), always(<ClientError />)],
      [equals(500), always(<ServerError />)],
      [T, always(children)],
    ]);

    return component(errorStatus);
  }
}

const mapStateToProps = state => ({
  errorStatus: errorStatusCodeSelector(state),
});

export { ErrorHandler as ErrorHandlerContainer };
export default connect(mapStateToProps)(ErrorHandler);
