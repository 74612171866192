import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';

const ExitConferenceModalComponent = ({
  onSubmit,
  title,
  bodyText,
  submitText,
}) => (
  <Modal wrapClassName="ant-modal-wrap__6">
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage {...title} />
      </h1>
    </div>
    <div className="ant-modal__body">
      <p className="manage-account__modal-txt mb-24">
        <FormattedMessage {...bodyText} />
      </p>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        className="w-160"
        text={submitText}
        onClick={onSubmit}
      />
    </div>
  </Modal>
);

ExitConferenceModalComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.shape().isRequired,
  bodyText: PropTypes.shape().isRequired,
  submitText: PropTypes.shape().isRequired,
};

export default ExitConferenceModalComponent;
