import { createLogic } from 'redux-logic';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { UPDATE_ONBOARDING_STEP } from '../types';

const updateOnboardingStepOperation = createLogic({
  type: UPDATE_ONBOARDING_STEP,
  latest: true,

  process({ getState, action: { step } }, dispatch, done) {
    const state = getState();

    const response = updateDataHelper(
      state.data,
      'userProfile',
      currentUserIdSelector(state),
      {
        attributes: {
          onboardingStatus: step,
        },
      },
    );

    dispatch(dataApiSuccess({ response }));

    done();
  },
});

export default updateOnboardingStepOperation;
