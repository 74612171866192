import { createLogic } from 'redux-logic';

import redirect from 'utils/redirect';

import { confirmEmailEndpoint } from '../endpoints';
import { CONFIRM_EMAIL } from '../types';

const confirmEmailOperation = createLogic({
  type: CONFIRM_EMAIL,
  latest: true,

  async process({ action, rootHttpClient }, _, done) {
    const { token, response } = action;

    const { url } = confirmEmailEndpoint;

    const params = {
      token,
    };

    try {
      await rootHttpClient.post(url, params);

      redirect({ href: '/patient/confirm_email_patient_success', response });
    } catch {
      redirect({ href: '/patient/confirm_email_patient_error', response });
    }

    done();
  },
});

export default confirmEmailOperation;
