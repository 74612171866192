const namespace = 'joinRequests';

export const FETCH_JOIN_REQUESTS = `${namespace}/FETCH_JOIN_REQUESTS`;
export const SET_JOIN_REQUEST_IDS = `${namespace}/SET_JOIN_REQUEST_IDS`;
export const SET_JOIN_REQUESTS_BLANK_STATE = `${namespace}/SET_JOIN_REQUESTS_BLANK_STATE`;
export const SET_JOIN_REQUESTS_CURRENT_PAGE = `${namespace}/SET_JOIN_REQUESTS_CURRENT_PAGE`;
export const ACCEPT_JOIN_REQUEST = `${namespace}/ACCEPT_JOIN_REQUEST`;
export const REJECT_JOIN_REQUEST = `${namespace}/REJECT_JOIN_REQUEST`;
export const REMOVE_JOIN_REQUEST = `${namespace}/REMOVE_JOIN_REQUEST`;
export const SET_JOIN_REQUESTS_FILTERS = `${namespace}/SET_JOIN_REQUESTS_FILTERS`;
