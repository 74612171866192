import { createLogic } from 'redux-logic';
import { map } from 'ramda';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';

import {
  fetchClinicSpecialitiesList,
  setClinicSpecialitiesListIds,
  setClinicSpecialitiesListStatuses,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import { DOCTOR_ASSIGN_CLINIC_SPECIALITIES_INITIAL_STATE } from '../types';

const assignClinicSpecialitiesInitialStateOperation = createLogic({
  type: DOCTOR_ASSIGN_CLINIC_SPECIALITIES_INITIAL_STATE,
  latest: true,

  async process({ action: { doctor: { userProfilesClinicSpecialities } } }, dispatch, done) {
    const ids = map(item => item.clinicSpeciality.id, userProfilesClinicSpecialities);
    const statuses = [
      CLINIC_SPECIALITIES_STATUSES.active,
      CLINIC_SPECIALITIES_STATUSES.inactive,
    ];

    dispatch(setClinicSpecialitiesListIds(ids));
    dispatch(setClinicSpecialitiesListStatuses(statuses));
    dispatch(fetchClinicSpecialitiesList());

    done();
  },
});

export default assignClinicSpecialitiesInitialStateOperation;
