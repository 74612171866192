import build from 'redux-object';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import {
  prop, path, equals, compose, head, sortBy, pipe, not,
} from 'ramda';

import { NOTIFICATION_PANEL } from 'constants/notifications';

import buildCollection from 'utils/buildCollection';
import isPresent from 'utils/isPresent';

import { endpointMetaSelector, loadingSelector } from 'state/data/selectors';
import { fetchNotificationsEndpoint, fetchNotificationSettingEndpoint } from './endpoints';

const dataSelector = prop('data');

export const notificationIdsSelector = path(['notificationPanel', 'notificationIds']);
export const unreadNotificationsCountSelector = path(['notificationPanel', 'unreadNotificationsCount']);
export const notificationPanelStatusSelector = path(['notificationPanel', 'notificationPanelStatus']);

export const isNotificationPanelOpenedSelector = createSelector(
  notificationPanelStatusSelector,
  equals(NOTIFICATION_PANEL.open),
);

export const notificationsSelector = createSelector(
  notificationIdsSelector,
  dataSelector,
  buildCollection('notification'),
);

export const notificationSelector = createSelector(
  dataSelector,
  (_, notificationId) => notificationId,
  (data, notificationId) => build(data, 'notification', notificationId),
);

export const lastNotificationIdSelector = createSelector(
  notificationsSelector,
  compose(
    prop('id'),
    head,
    sortBy(compose(DateTime.fromISO, prop('createdAt'))),
  ),
);

export const isUnreadNotificationsSelector = createSelector(
  unreadNotificationsCountSelector,
  pipe(
    equals(0),
    not,
  ),
);

export const fetchNotificationsLoadingSelector = (state) => (
  loadingSelector(state, fetchNotificationsEndpoint.endpoint)
);

export const fetchNotificationsMetaSelector = state => (
  endpointMetaSelector(state, fetchNotificationsEndpoint.endpoint)
);

export const userNotificationSettingMetaSelector = createSelector(
  (state) => endpointMetaSelector(state, fetchNotificationSettingEndpoint.endpoint),
  (meta) => (meta.role === 'UserAccount'),
);

export const notificationSettingsSelector = createSelector(
  dataSelector,
  (data) => (
    isPresent(data) && isPresent(data.notificationSetting) ? head(build(data, 'notificationSetting')) : null
  ),
);
