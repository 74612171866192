export const NOTE_TABS = {
  general: 'general',
  plan: 'plan',
  prescription: 'prescription',
  secretNote: 'secretNote',
  auxiliaryStudies: 'auxiliaryStudies',
  sickNote: 'sickNote',
};

export const DOSE_MEASURE = {
  tablet: 'tablet',
  capsule: 'capsule',
  ampule: 'ampule',
  application: 'application',
  spoonful_small: 'spoonful_small',
  spray: 'spray',
  suppository: 'suppository',
  dropsgrams: 'dropsgrams',
  prefilled_syringe: 'prefilled_syringe',
  vaginal_ovule: 'vaginal_ovule',
  milligram: 'milligram',
  millilitre: 'millilitre',
  microgram: 'microgram',
  gram: 'gram',
  dose: 'dose',
  puff: 'puff',
};

export const FREQUENCY_VALUE = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
  eight: '8',
};

export const FREQUENCY_RECURRENCE = {
  once: 'once',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
};

export const PACKAGE_QUANTITY = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
  eight: '8',
  nine: '9',
  ten: '10',
};

export const PACKAGE_TYPE = {
  box: 'box',
  jar: 'jar',
  bottle: 'bottle',
  tube: 'tube',
  spray_bottle: 'spray_bottle',
};

export const DOSE_MEASURE_OPTIONS = [
  { label: { id: `notePrescription.${DOSE_MEASURE.tablet}` }, value: DOSE_MEASURE.tablet, key: DOSE_MEASURE.tablet },
  { label: { id: `notePrescription.${DOSE_MEASURE.capsule}` }, value: DOSE_MEASURE.capsule, key: DOSE_MEASURE.capsule },
  { label: { id: `notePrescription.${DOSE_MEASURE.ampule}` }, value: DOSE_MEASURE.ampule, key: DOSE_MEASURE.ampule },
  { label: { id: `notePrescription.${DOSE_MEASURE.application}` }, value: DOSE_MEASURE.application, key: DOSE_MEASURE.application },
  { label: { id: `notePrescription.${DOSE_MEASURE.spoonful_small}` }, value: DOSE_MEASURE.spoonful_small, key: DOSE_MEASURE.spoonful_small },
  { label: { id: `notePrescription.${DOSE_MEASURE.spray}` }, value: DOSE_MEASURE.spray, key: DOSE_MEASURE.spray },
  { label: { id: `notePrescription.${DOSE_MEASURE.suppository}` }, value: DOSE_MEASURE.suppository, key: DOSE_MEASURE.suppository },
  { label: { id: `notePrescription.${DOSE_MEASURE.dropsgrams}` }, value: DOSE_MEASURE.dropsgrams, key: DOSE_MEASURE.dropsgrams },
  { label: { id: `notePrescription.${DOSE_MEASURE.prefilled_syringe}` }, value: DOSE_MEASURE.prefilled_syringe, key: DOSE_MEASURE.prefilled_syringe },
  { label: { id: `notePrescription.${DOSE_MEASURE.vaginal_ovule}` }, value: DOSE_MEASURE.vaginal_ovule, key: DOSE_MEASURE.vaginal_ovule },
  { label: { id: `notePrescription.${DOSE_MEASURE.milligram}` }, value: DOSE_MEASURE.milligram, key: DOSE_MEASURE.milligram },
  { label: { id: `notePrescription.${DOSE_MEASURE.millilitre}` }, value: DOSE_MEASURE.millilitre, key: DOSE_MEASURE.millilitre },
  { label: { id: `notePrescription.${DOSE_MEASURE.microgram}` }, value: DOSE_MEASURE.microgram, key: DOSE_MEASURE.microgram },
  { label: { id: `notePrescription.${DOSE_MEASURE.gram}` }, value: DOSE_MEASURE.gram, key: DOSE_MEASURE.gram },
  { label: { id: `notePrescription.${DOSE_MEASURE.dose}` }, value: DOSE_MEASURE.dose, key: DOSE_MEASURE.dose },
  { label: { id: `notePrescription.${DOSE_MEASURE.puff}` }, value: DOSE_MEASURE.puff, key: DOSE_MEASURE.puff },
];

export const FREQUENCY_VALUE_OPTIONS = [
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.one } }, value: FREQUENCY_VALUE.one, key: FREQUENCY_VALUE.one },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.two } }, value: FREQUENCY_VALUE.two, key: FREQUENCY_VALUE.two },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.three } }, value: FREQUENCY_VALUE.three, key: FREQUENCY_VALUE.three },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.four } }, value: FREQUENCY_VALUE.four, key: FREQUENCY_VALUE.four },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.five } }, value: FREQUENCY_VALUE.five, key: FREQUENCY_VALUE.five },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.six } }, value: FREQUENCY_VALUE.six, key: FREQUENCY_VALUE.six },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.seven } }, value: FREQUENCY_VALUE.seven, key: FREQUENCY_VALUE.seven },
  { label: { id: 'notePrescription.times', plural: { count: FREQUENCY_VALUE.eight } }, value: FREQUENCY_VALUE.eight, key: FREQUENCY_VALUE.eight },
];

export const FREQUENCY_RECURRENCE_OPTIONS = [
  { label: { id: `notePrescription.${FREQUENCY_RECURRENCE.once}` }, value: FREQUENCY_RECURRENCE.once, key: FREQUENCY_RECURRENCE.once },
  { label: { id: `notePrescription.${FREQUENCY_RECURRENCE.daily}` }, value: FREQUENCY_RECURRENCE.daily, key: FREQUENCY_RECURRENCE.daily },
  { label: { id: `notePrescription.${FREQUENCY_RECURRENCE.weekly}` }, value: FREQUENCY_RECURRENCE.weekly, key: FREQUENCY_RECURRENCE.weekly },
  { label: { id: `notePrescription.${FREQUENCY_RECURRENCE.monthly}` }, value: FREQUENCY_RECURRENCE.monthly, key: FREQUENCY_RECURRENCE.monthly },
  { label: { id: `notePrescription.${FREQUENCY_RECURRENCE.yearly}` }, value: FREQUENCY_RECURRENCE.yearly, key: FREQUENCY_RECURRENCE.yearly },
];

export const PACKAGE_QUANTITY_OPTIONS = [
  { label: PACKAGE_QUANTITY.one, value: PACKAGE_QUANTITY.one, key: PACKAGE_QUANTITY.one },
  { label: PACKAGE_QUANTITY.two, value: PACKAGE_QUANTITY.two, key: PACKAGE_QUANTITY.two },
  { label: PACKAGE_QUANTITY.three, value: PACKAGE_QUANTITY.three, key: PACKAGE_QUANTITY.three },
  { label: PACKAGE_QUANTITY.four, value: PACKAGE_QUANTITY.four, key: PACKAGE_QUANTITY.four },
  { label: PACKAGE_QUANTITY.five, value: PACKAGE_QUANTITY.five, key: PACKAGE_QUANTITY.five },
  { label: PACKAGE_QUANTITY.six, value: PACKAGE_QUANTITY.six, key: PACKAGE_QUANTITY.six },
  { label: PACKAGE_QUANTITY.seven, value: PACKAGE_QUANTITY.seven, key: PACKAGE_QUANTITY.seven },
  { label: PACKAGE_QUANTITY.eight, value: PACKAGE_QUANTITY.eight, key: PACKAGE_QUANTITY.eight },
  { label: PACKAGE_QUANTITY.nine, value: PACKAGE_QUANTITY.nine, key: PACKAGE_QUANTITY.nine },
  { label: PACKAGE_QUANTITY.ten, value: PACKAGE_QUANTITY.ten, key: PACKAGE_QUANTITY.ten },
];

export const PACKAGE_TYPE_OPTIONS = [
  { label: { id: `notePrescription.${PACKAGE_TYPE.box}` }, value: PACKAGE_TYPE.box, key: PACKAGE_TYPE.box },
  { label: { id: `notePrescription.${PACKAGE_TYPE.jar}` }, value: PACKAGE_TYPE.jar, key: PACKAGE_TYPE.jar },
  { label: { id: `notePrescription.${PACKAGE_TYPE.bottle}` }, value: PACKAGE_TYPE.bottle, key: PACKAGE_TYPE.bottle },
  { label: { id: `notePrescription.${PACKAGE_TYPE.tube}` }, value: PACKAGE_TYPE.tube, key: PACKAGE_TYPE.tube },
  { label: { id: `notePrescription.${PACKAGE_TYPE.spray_bottle}` }, value: PACKAGE_TYPE.spray_bottle, key: PACKAGE_TYPE.spray_bottle },
];

export const NOTE_CODE_FOR_CANCEL_APPOINTMENT = {
  'Z53.09': 'Z53.09',
  'Z53.9': 'Z53.9',
  'Z53.20': 'Z53.20',
};

