import { propEq, includes } from 'ramda';

import { DOCTORS_STATUSES } from 'constants/doctors';

export const isActive = propEq('status', DOCTORS_STATUSES.active);
export const isDeactivated = propEq('status', DOCTORS_STATUSES.deactivated);
export const isPending = propEq('status', DOCTORS_STATUSES.pending);
export const isRejected = propEq('status', DOCTORS_STATUSES.rejected);

export const isAccepted = ({ status }) => (
  includes(status, [
    DOCTORS_STATUSES.active,
    DOCTORS_STATUSES.deactivated,
  ])
);

export const isInactive = ({ status }) => (
  includes(status, [
    DOCTORS_STATUSES.inactive,
    DOCTORS_STATUSES.invited,
    DOCTORS_STATUSES.pending,
    DOCTORS_STATUSES.rejected,
  ])
);
