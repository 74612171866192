import { createLogic } from 'redux-logic';
import { dec } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchDoctors, setCurrentPage } from '../actions';
import { doctorsTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeDoctorInvitationEndpoint } from '../endpoints';
import { REMOVE_DOCTOR_INVITATION } from '../types';

const removeDoctorInvitationOperation = createLogic({
  type: REMOVE_DOCTOR_INVITATION,
  latest: true,

  async process({ httpClient, getState, action: { doctorId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = removeDoctorInvitationEndpoint(doctorId);
    const totalCount = doctorsTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, 1);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.doctorsInvitationRemove' } }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchDoctors());
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorsInvitationRemoveError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeDoctorInvitationOperation;
