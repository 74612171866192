import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { fetchVoucherDetailsEndpoint } from '../endpoints';
import { FETCH_VOUCHER_DETAILS } from '../types';

const fetchVoucherDetailsOperation = createLogic({
  type: FETCH_VOUCHER_DETAILS,
  latest: true,

  async process({ httpClient, action: { voucherId } }, dispatch, done) {
    const { endpoint, url } = fetchVoucherDetailsEndpoint(voucherId);
    const params = {
      include: 'patient_profile,pharmacist_profile,voucher_batch',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVoucherDetailsOperation;
