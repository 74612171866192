import { isNil } from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export function maxAgeTo(maxAgeFromRef) {
  return this.test({
    name: 'maxAgeTo',
    message: { id: 'yup.number.maxAgeTo' },
    params: { maxAgeFromRef },
    test(value) {
      const ageFrom = isNil(this.resolve(maxAgeFromRef)) ? 0 : this.resolve(maxAgeFromRef);
      return isNil(value) || value >= ageFrom;
    },
  });
}
