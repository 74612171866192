import React from 'react';
import PropTypes from 'prop-types';
import { Tree as TreeAntd } from 'antd';

import TreeComponent from './component';

class Tree extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape()),
    expandAction: PropTypes.func,
    selectAction: PropTypes.func,
    isSingleNode: PropTypes.bool,
  };

  static defaultProps = {
    expandAction: undefined,
    selectAction: undefined,
    isSingleNode: false,
    options: [],
  };

  handleExpand = (_, { node }) => {
    const { props: { dataRef } } = node;
    const { expandAction } = this.props;

    expandAction(dataRef);
  };

  handleSelect = (_, { node }) => {
    const { props: { dataRef } } = node;
    const { selectAction } = this.props;

    if (dataRef.canBeAssigned) {
      selectAction(dataRef);
    }
  };

  renderTreeNodes = options => options.map(option => {
    const { isSingleNode } = this.props;

    if (isSingleNode) {
      return (
        <TreeAntd.TreeNode
          title={(
            <>
              {option.code}
              <span>{option.description}</span>
            </>
          )}
          key={option.id}
          isLeaf
          dataRef={option}
        />
      );
    }

    if (option.nodes) {
      return (
        <TreeAntd.TreeNode
          title={(
            <>
              {option.code}
              <span>{option.description}</span>
            </>
          )}
          key={option.id}
          dataRef={option}
        >
          {this.renderTreeNodes(option.nodes)}
        </TreeAntd.TreeNode>
      );
    }

    return (
      <TreeAntd.TreeNode
        title={(
          <>
            {option.code}
            <span>{option.description}</span>
          </>
        )}
        key={option.id}
        isLeaf={option.finalNode}
        dataRef={option}
      />
    );
  });

  render = () => (
    <TreeComponent
      {...this.props}
      onExpand={this.handleExpand}
      renderTreeNodes={this.renderTreeNodes}
      onSelect={this.handleSelect}
    />
  );
}

export default Tree;
