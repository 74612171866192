import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { getUsersOwnProfile } from 'state/concepts/userProfile/users/actions';
import { setRecurringAvailability } from '../actions';
import { updateRecurringAvailabilityEndpoint } from '../endpoints';
import { UPDATE_RECURRING_AVAILABILITY } from '../types';

const updateRecurringAvailabilityOperation = createLogic({
  type: UPDATE_RECURRING_AVAILABILITY,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { availabilities, userId, isDoctorProfilePage } = action;
    const { endpoint, url } = updateRecurringAvailabilityEndpoint(userId);
    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = { recurring_availabilities: availabilities };
      const { data } = await httpClient.put(url, params);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRecurringAvailability(pluck('id', data.data)));
      dispatch(getUsersOwnProfile());
      dispatch(showNotification({
        messageObject: {
          id: isDoctorProfilePage
            ? 'notifications.doctorsProfileUpdated'
            : 'notifications.regularAvailabilityUpdated',
        },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default updateRecurringAvailabilityOperation;
