import { isNil, equals } from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export function licenseNumber(length) {
  return this.test(
    'licenseNumber',
    { id: 'yup.string.digitsNumber', values: { length } },
    (value) => isNil(value) || equals(value.length, length),
  );
}
