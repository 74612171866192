import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { doctorProfileIdSelector } from '../selectors';
import { removeDoctorAvatarEndpoint } from '../endpoints';
import { REMOVE_DOCTOR_AVATAR } from '../types';

const removeDoctorAvatarOperation = createLogic({
  type: REMOVE_DOCTOR_AVATAR,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const id = doctorProfileIdSelector(state);
    const { url, endpoint } = removeDoctorAvatarEndpoint(id);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      const response = updateDataHelper(
        state.data,
        'userProfile', id,
        {
          attributes: {
            avatarUrls: null,
          },
        },
      );

      dispatch(showNotification({
        messageObject: { id: 'notifications.profileImageRemoved' },
      }));
      dispatch(dataApiSuccess({ response }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.profileImageRemovedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeDoctorAvatarOperation;
