import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetPasswordInAccount as resetPasswordInAccountAction } from 'state/concepts/userProfile/resetPassword/actions';
import { submitResetPasswordLoadingSelector } from 'state/concepts/userProfile/resetPassword/selectors';

import ForgotPasswordModalComponent from './component';

class ForgotPasswordModal extends React.Component {
  static propTypes = {
    resetPasswordInAccount: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
  }

  handleSubmit = () => {
    const { resetPasswordInAccount, email } = this.props;

    resetPasswordInAccount(email);
  }

  render = () => (
    <ForgotPasswordModalComponent
      {...this.props}
      onSubmit={this.handleSubmit}
    />
  );
}

const mapStateToProps = (state) => ({
  isLoading: submitResetPasswordLoadingSelector(state),
});

const mapDispatchToProps = {
  resetPasswordInAccount: resetPasswordInAccountAction,
};

export { ForgotPasswordModal as ForgotPasswordModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
