import React from 'react';
import PropTypes from 'prop-types';

import ErrorModal from 'views/shared/ErrorModal';

import CancelFormModal from './CancelFormModal';

const CancelModalComponent = ({
  cancellationError,
  ...props
}) => (
    cancellationError ? (
      <ErrorModal
        title={{ id: 'cancelAppointmentModal.cancelAppointment' }}
        error={cancellationError}
      />
    ) : (
      <CancelFormModal {...props} />
    )
);

CancelModalComponent.defaultProps = {
  isLoading: undefined,
  cancellationError: undefined,
};

CancelModalComponent.propTypes = {
  isLoading: PropTypes.bool,
  cancellationError: PropTypes.string,
};

export default CancelModalComponent;
