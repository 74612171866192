import PropTypes from 'prop-types';

import Button from 'views/shared/Button';

const SubmitFormButton = ({
  isSubmitDisabled,
  isFormSubmitting,
  ...restProps
}) => (
  <Button
    type="submit"
    isLoading={isFormSubmitting}
    disabled={isSubmitDisabled}
    {...restProps}
  />
);

SubmitFormButton.propTypes = {
  isSubmitDisabled: PropTypes.bool.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
};

export default SubmitFormButton;
