import Axios from 'axios';
import cookies from 'next-cookies';
import { mergeRight } from 'ramda';

import { API_HOST, BASIC_AUTH_LOGIN, BASIC_AUTH_PASSWORD } from 'constants';
import { USER_TYPE } from 'constants/users';

import { usersSessionRefreshRoute, patientsSessionRefreshRoute } from 'lib/apiRoutes';

import setCookieIsomorphic from 'utils/setCookieIsomorphic';
import removeCookieIsomorphic from 'utils/removeCookieIsomorphic';
import redirect from 'utils/redirect';

const refreshInterceptor = (ctx) => {
  let requestsPool = [];
  let isRefreshing = false;
  const subscribeToRefresh = listener => requestsPool.push(listener);
  const finishRefresh = (accessToken) => {
    requestsPool.map(listener => listener(accessToken));
    isRefreshing = false;
    requestsPool = [];
  };
  const { tokens, userType } = cookies(ctx);
  const patient = userType && userType === USER_TYPE.patient;

  return async (error) => {
    const retryConfig = error.response && error.response.config;

    if (error.response && retryConfig && error.response.status === 401) {
      const defferedOriginalRequest = new Promise((resolve, reject) => {
        // eslint-disable-next-line consistent-return
        subscribeToRefresh((accessToken) => {
          if (!accessToken) { return reject(error); }

          retryConfig.headers['X-Authorization'] = accessToken;
          resolve(Axios.request(retryConfig));
        });
      });

      if (isRefreshing) { return defferedOriginalRequest; }

      try {
        isRefreshing = true;
        const refreshPath = patient ? patientsSessionRefreshRoute : usersSessionRefreshRoute;
        const response = await Axios.post(`${API_HOST}/api/v1${refreshPath}`, {}, {
          headers: { 'X-Refresh-Token': tokens.refresh, 'X-Client-Device': 'web' },
          auth: { username: BASIC_AUTH_LOGIN, password: BASIC_AUTH_PASSWORD },
        });

        const cookieOptions = { path: '/' };

        setCookieIsomorphic(ctx, 'tokens', JSON.stringify(mergeRight(tokens, response.data.meta.jwt)), cookieOptions);
        const newAuthHeader = `Bearer ${response.data.meta.jwt.access}`;

        ctx.store.httpClient.defaults.headers.common['X-Authorization'] = newAuthHeader;
        finishRefresh(newAuthHeader);
      } catch {
        ctx.store.httpClient.defaults.headers.common['X-Authorization'] = null;
        removeCookieIsomorphic(ctx, 'tokens');
        removeCookieIsomorphic(ctx, 'userType');
        removeCookieIsomorphic(ctx, 'currentUserId');
        removeCookieIsomorphic(ctx, 'currentUserRole');
        removeCookieIsomorphic(ctx, 'isLoggedIn');

        redirect({ href: patient ? '/patient/login' : '/login', response: ctx.res });

        finishRefresh(null);
      }

      return defferedOriginalRequest;
    }

    return Promise.reject(error);
  };
};

export default refreshInterceptor;
