import { createSelector } from 'reselect';
import build from 'redux-object';
import { DateTime } from 'luxon';
import {
  path, prop, propEq, equals, any, intersection, length,
} from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, pageCountSelector, totalCountSelector } from 'state/data/selectors';
import { filtersSelector } from 'state/concepts/userProfile/filters/selectors';
import {
  fetchVoucherDetailsEndpoint,
  fetchVouchersEndpoint,
  removeVouchersEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const voucherIdsSelector = path(['vouchers', 'voucherIds']);
export const isBlankStateSelector = path(['vouchers', 'isBlankState']);
export const paginationSelector = path(['vouchers', 'pagination']);
export const sortSelector = path(['vouchers', 'sort']);
export const selectedSelector = path(['vouchers', 'selectedVouchers']);
export const activeTabSelector = path(['vouchers', 'activeTab']);

export const vouchersSelector = createSelector(
  voucherIdsSelector,
  dataSelector,
  buildCollection('voucher'),
);

export const voucherDetailsSelector = createSelector(
  (_, voucherId) => voucherId,
  dataSelector,
  (id, data) => (id ? build(data, 'voucher', id) : null),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const isSelectedSelector = createSelector(
  selectedSelector,
  (_, voucherId) => voucherId,
  (selectedVouchers, voucherId) => selectedVouchers.includes(voucherId),
);

const selectedOnPageSelector = createSelector(
  selectedSelector,
  voucherIdsSelector,
  intersection,
);

export const isAllSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  voucherIdsSelector,
  (selectedOnPage, voucherIds) => equals(length(selectedOnPage), length(voucherIds)),
);

export const hasSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  selectedOnPage => selectedOnPage.length > 0,
);

export const selectedVouchersSelector = createSelector(
  selectedSelector,
  dataSelector,
  buildCollection('voucher'),
);

export const isSpecificVouchersSelected = createSelector(
  selectedVouchersSelector,
  (_, key) => key,
  (vouchers, key) => any(propEq('status', key), vouchers),
);

export const vouchersLoadingSelector = state => (
  loadingSelector(state, fetchVouchersEndpoint.endpoint)
);

export const voucherDetailsLoadingSelector = (state, id) => (
  loadingSelector(state, fetchVoucherDetailsEndpoint(id).endpoint)
);

export const vouchersPageCountSelector = state => (
  pageCountSelector(state, fetchVouchersEndpoint.endpoint)
);

export const vouchersTotalCountSelector = state => (
  totalCountSelector(state, fetchVouchersEndpoint.endpoint)
);

export const removeVouchersLoadingSelector = state => (
  loadingSelector(state, removeVouchersEndpoint.endpoint)
);

export const filterDateFromISOSelector = createSelector(
  filtersSelector,
  ({ startDate, endDate }) => (startDate && endDate
    ? [DateTime.fromISO(startDate), DateTime.fromISO(endDate)]
    : []
  ),
);

export const filterUsedAtDateFromISOSelector = createSelector(
  filtersSelector,
  ({ usedAtStartDate, usedAtEndDate }) => (usedAtStartDate && usedAtEndDate
    ? [DateTime.fromISO(usedAtStartDate), DateTime.fromISO(usedAtEndDate)]
    : []
  ),
);
