import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';

import { SET_REVENUE_VIRTUAL_CLINIC_DOCTORS_FILTERS } from '../types';
import { fetchRevenueDoctorsList } from '../actions';

const filterRevenueVirtualClinicDoctorsOperation = createLogic({
  type: SET_REVENUE_VIRTUAL_CLINIC_DOCTORS_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(fetchRevenueDoctorsList());
    done();
  },
});

export default filterRevenueVirtualClinicDoctorsOperation;
