import endpoint from 'utils/endpoint';
import {
  usersInvitationsRecoveryCodesRoute,
  usersAccountRecoveryCodesRoute,
  usersInvitationsRecoveryCodesDownloadRoute,
  usersAccountRecoveryCodesDownloadRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchRecoveryCodesEndpoint = endpoint(types.FETCH_RECOVERY_CODES, 'GET', usersInvitationsRecoveryCodesRoute);
export const fetchAccountRecoveryCodesEndpoint = endpoint(types.FETCH_ACCOUNT_RECOVERY_CODES, 'GET', usersAccountRecoveryCodesRoute);
export const downloadRecoveryCodesEndpoint = endpoint(types.DOWNLOAD_RECOVERY_CODES, 'GET', usersInvitationsRecoveryCodesDownloadRoute);
export const downloadAccountRecoveryCodesEndpoint = endpoint(types.DOWNLOAD_ACCOUNT_RECOVERY_CODES, 'GET', usersAccountRecoveryCodesDownloadRoute);
