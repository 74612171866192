import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import requestErrorHandler from 'lib/requestErrorHandler';

import { USER_TYPE } from 'constants/users';
import { IS_LOGGED_IN } from 'constants/auth';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { userLoginSuccess } from 'state/concepts/session/actions';
import { submitAccountTwoFactorAuthenticationEndpoint } from '../endpoints';
import { SUBMIT_ACCOUNT_TWO_FACTOR_AUTH } from '../types';

const submitTwoFactorAuthenticationOperation = createLogic({
  type: SUBMIT_ACCOUNT_TWO_FACTOR_AUTH,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { endpoint, url } = submitAccountTwoFactorAuthenticationEndpoint;
    const params = {
      verification_code: values.verificationCode,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);

      const response = normalize(data);
      const currentUser = build(response, 'userProfile')[0];
      const tokens = data.meta.jwt;

      setUserCookies(currentUser, tokens, USER_TYPE.user, IS_LOGGED_IN);

      dispatch(userLoginSuccess(currentUser.id));
      dispatch(dataApiSuccess({ response, endpoint }));
      await redirect({ href: '/account_settings' });
      dispatch(showNotification({ messageObject: { id: 'notifications.successSubmitAccountTwoFactorAuth' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitTwoFactorAuthenticationOperation;
