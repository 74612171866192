import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import { CREATE_SYMPTOM_STEPS } from 'constants/symptoms';

import requestErrorHandler from 'lib/requestErrorHandler';
import assignFormErrors from 'utils/form/assignFormErrors';
import parseValuesFormToParams from 'utils/symptoms/parseValuesFormToParams';
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { setCreateSymptomStep, setQuestionIds } from '../actions';
import { symptomDetailsIdSelector } from '../selectors';
import { createSymptomQuestionnaireEndpoint } from '../endpoints';
import { SUBMIT_SYMPTOM_QUESTIONNAIRE } from '../types';

const createSymptomQuestionnaireOperation = createLogic({
  type: SUBMIT_SYMPTOM_QUESTIONNAIRE,
  latest: true,

  async process({
    action: {
      values, form,
    },
    httpClient, getState,
  }, dispatch, done) {
    const state = getState();
    const symptomId = symptomDetailsIdSelector(state);

    const { url, endpoint } = createSymptomQuestionnaireEndpoint(symptomId);

    const params = {
      questions: parseValuesFormToParams(values.questions),
      symptom_id: symptomId,
      include: 'symptom,question-options',
    };
    try {
      const { data } = await httpClient.put(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setQuestionIds(pluck('id', data.data)));
      dispatch(showNotification({ messageObject: { id: 'notifications.questionnaireUpdated' } }));
      dispatch(setCreateSymptomStep(CREATE_SYMPTOM_STEPS.specialities));
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error);
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default createSymptomQuestionnaireOperation;
