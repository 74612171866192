import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  compose, includes, trim, isEmpty, without,
} from 'ramda';

import {
  filterClinicsList as filterClinicsListAction,
  fetchClinicsList as fetchClinicsListAction,
  resetClinicsListIds as resetClinicsListIdsAction,
  resetClinicsListFilterParams as resetClinicsListFilterParamsAction,
} from 'state/concepts/userProfile/pushNotifications/actions';
import {
  clinicsListMetaSelector,
  clinicsListLoadingSelector,
  clinicsListForFilterSelector,
  clinicsIdsSelector,
} from 'state/concepts/userProfile/pushNotifications/selectors';

import DropdownClinicsFieldComponent from './component';

class DropdownClinicsField extends React.Component {
  static propTypes = {
    filterClinicsList: PropTypes.func.isRequired,
    resetClinicsListIds: PropTypes.func.isRequired,
    resetClinicsListFilterParams: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    clinics: PropTypes.arrayOf(PropTypes.shape()),
    optionsIds: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.shape().isRequired,
  }

  static defaultProps = {
    options: [],
    optionsIds: [],
    clinics: [],
  }

  state = {
    searchQuery: '',
  };

  componentDidMount = () => {
    const { filterClinicsList } = this.props;

    filterClinicsList({ excludeClinicsIds: this.excludedClinicsIds });
  }

  componentWillUnmount = () => {
    const { resetClinicsListIds, resetClinicsListFilterParams } = this.props;

    resetClinicsListIds();
    resetClinicsListFilterParams();
  }

  get excludedClinicsIds() {
    const { optionsIds, field: { value } } = this.props;

    return without([value], optionsIds);
  }

  get isAllClinicsSelected() {
    const { options, field: { value } } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => includes(id, [].concat(value)));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterClinicsList, resetClinicsListIds } = this.props;

    this.setState({ searchQuery });
    resetClinicsListIds();
    filterClinicsList({
      name: trim(searchQuery),
      excludeClinicsIds: this.excludedClinicsIds,
    });
  }, 250)

  handleFocus = () => {
    const { filterClinicsList } = this.props;

    this.setState({ searchQuery: '' });
    filterClinicsList({
      name: '',
      excludeClinicsIds: this.excludedClinicsIds,
    });
  }

  render = () => (
    <DropdownClinicsFieldComponent
      {...this.props}
      isAllClinicsSelected={this.isAllClinicsSelected}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

const mapStateToProps = state => ({
  meta: clinicsListMetaSelector(state),
  isLoading: clinicsListLoadingSelector(state),
  options: clinicsListForFilterSelector(state),
  optionsIds: clinicsIdsSelector(state),
});

const mapDispatchToProps = {
  fetchClinicsList: fetchClinicsListAction,
  filterClinicsList: filterClinicsListAction,
  resetClinicsListIds: resetClinicsListIdsAction,
  resetClinicsListFilterParams: resetClinicsListFilterParamsAction,
};

export { DropdownClinicsField as DropdownClinicsFieldContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DropdownClinicsField);
