import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { USER_TYPE } from 'constants/users';
import { IS_LOGGED_IN } from 'constants/auth';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';
import assignFormErrors from 'utils/form/assignFormErrors';
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess, setUserType } from '../actions';
import { patientsLoginEndpoint } from '../endpoints';
import { PATIENT_LOGIN } from '../types';

const submitPatientLoginOperation = createLogic({
  type: PATIENT_LOGIN,
  latest: true,

  async process({ rootHttpClient, action }, dispatch, done) {
    const { values: { email, password }, form } = action;
    const { url, endpoint } = patientsLoginEndpoint;

    const params = {
      email,
      password,
      include: 'health-profile,health-profile.allergies,child_profiles,patient_balance',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await rootHttpClient.post(url, params);
      const response = normalize(data);
      const currentPatient = build(response, 'patientProfile')[0];
      const tokens = data.meta.jwt;

      setUserCookies(currentPatient, tokens, USER_TYPE.patient, IS_LOGGED_IN);

      dispatch(userLoginSuccess(currentPatient.id));
      dispatch(setUserType(USER_TYPE.patient));
      dispatch(dataApiSuccess({ response, endpoint }));
      redirect({ href: '/patient' });
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error);
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPatientLoginOperation;
