import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { path } from 'ramda';

import { HTTP_STATUSES } from 'constants';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setDoctorProfileId } from '../actions';
import { fetchDoctorProfileEndpoint } from '../endpoints';
import { FETCH_DOCTOR_PROFILE } from '../types';

const fetchDoctorProfile = createLogic({
  type: FETCH_DOCTOR_PROFILE,
  latest: true,

  async process({ httpClient, action: { slug, response } }, dispatch, done) {
    const { endpoint, url } = fetchDoctorProfileEndpoint(slug);
    const params = {
      include: 'user-profiles-clinic-specialities,user-profiles-clinic-specialities.speciality,user-profiles-clinic-specialities.clinic-speciality',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(setDoctorProfileId(data.data.id));
    } catch (error) {
      if (path(['response', 'status'], error) === HTTP_STATUSES.notFound) {
        redirect({ href: '/doctor/error', response });
      } else {
        requestErrorHandler({ error, dispatch, endpoint });
      }
    }
    done();
  },
});

export default fetchDoctorProfile;
