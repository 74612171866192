import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';

import formatCurrency from 'utils/formatCurrency';

import Badge from 'views/shared/Badge';
import Button from 'views/shared/Button';

const TransactionComponent = ({
  amount,
  topupSource,
  patientProfile,
  smallView,
  freeVisit,
  onClose,
}) => (
  <div className="mr-16">
    {smallView
      ? (
        <div className="modal-appointment__description-title">
          <FormattedMessage id={`status.${topupSource || 'paid'}`} />
        </div>
      ) : (
        <>
          <Badge status={topupSource || 'paid'} />
          <div className="modal-appointment__description-title">
            <FormattedMessage id="appointments.amount" />
          </div>
        </>
    )}
    <div className="modal-appointment__description-txt">
      {freeVisit ? <FormattedMessage id="shared.freeVisit" /> : formatCurrency(amount)}
    </div>
    {!smallView && patientProfile?.id && (
      <div className="modal-appointment__description-txt">
        <Link
          href={`/patients/${patientProfile.id}/balance`}
        >
          <Button
            text={{ id: 'appointments.viewDetails' }}
            kind="link"
            onClick={onClose}
          />
        </Link>
      </div>
    )}
  </div>
);

TransactionComponent.defaultProps = {
  amount: '0',
  topupSource: null,
  smallView: false,
  freeVisit: false,
};

TransactionComponent.propTypes = {
  amount: PropTypes.string,
  topupSource: PropTypes.string,
  patientProfile: PropTypes.shape().isRequired,
  smallView: PropTypes.bool,
  freeVisit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default TransactionComponent;
