const namespace = 'diseases';

export const GET_DISEASES = `${namespace}/GET_DISEASES`;
export const SEARCH_DISEASES = `${namespace}/SEARCH_DISEASES`;
export const GET_DISEASES_NODES = `${namespace}/GET_DISEASES_NODES`;
export const SET_DISEASES_IDS = `${namespace}/SET_DISEASES_IDS`;
export const UPDATE_DISEASES_IDS = `${namespace}/UPDATE_DISEASES_IDS`;
export const DESTROY_DISEASES_IDS = `${namespace}/DESTROY_DISEASES_IDS`;
export const FILTER_DISEASES = `${namespace}/FILTER_DISEASES`;
export const GET_COMMON_DISEASES = `${namespace}/GET_COMMON_DISEASES`;
export const SET_COMMON_DISEASES_IDS = `${namespace}/SET_COMMON_DISEASES_IDS`;
export const GET_COMPLETE_CANCEL_DISEASES = `${namespace}/GET_COMPLETE_CANCEL_DISEASES`;
export const SET_COMPLETE_CANCEL_DISEASES_IDS = `${namespace}/SET_COMPLETE_CANCEL_DISEASES_IDS`;
