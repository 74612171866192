import { createSelector } from 'reselect';
import { path, prop, propOr } from 'ramda';
import build from 'redux-object';
import { DateTime } from 'luxon';

import { endpointMetaSelector, loadingSelector } from 'state/data/selectors';

import { joinVideoConferenceEndpoint } from './endpoints';

const dataSelector = prop('data');

export const videoConferenceIdSelector = path(['videoConference', 'videoConferenceId']);
export const settingsSelector = path(['videoConference', 'settings']);
export const viewSelector = path(['videoConference', 'view']);
export const chatPanelVisibleSelector = path(['videoConference', 'chatPanelVisible']);
export const canJoinSelector = path(['videoConference', 'canJoin']);

export const videoConferenceSelector = createSelector(
  videoConferenceIdSelector,
  dataSelector,
  (id, data) => build(data, 'videoConference', id),
);

export const joinedAtSelector = createSelector(
  videoConferenceSelector,
  (videoConference) => propOr(DateTime.local().toISO(), 'userJoinedAt', videoConference),
);

export const videoConferenceMetaSelector = (state, id) => (
  endpointMetaSelector(state, joinVideoConferenceEndpoint(id).endpoint)
);

export const videoConferenceLoadingSelector = (state, id) => (
  loadingSelector(state, joinVideoConferenceEndpoint(id).endpoint)
);
