import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setAttachments, destroyAttachments } from '../actions';
import { fetchChatAttachmentsEndpoint } from '../endpoints';
import { FETCH_ATTACHMENTS } from '../types';

const fetchAttachmentsOperation = createLogic({
  type: FETCH_ATTACHMENTS,
  latest: true,

  async process({ action: { chatId }, httpClient }, dispatch, done) {
    const { endpoint, url } = fetchChatAttachmentsEndpoint(chatId);

    dispatch(dataApiRequest({ endpoint }));
    dispatch(destroyAttachments());

    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });

      dispatch(setAttachments(pluck('id', data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchAttachmentsOperation;
