import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchClinicSpecialities = () => ({
  type: types.FETCH_CLINIC_SPECIALITIES,
});

export const showClinicSpecialityDetails = clinicSpecialityId => ({
  type: types.SHOW_CLINIC_SPECIALITY_DETAILS,
  clinicSpecialityId,
});

export const fetchAllSpecialities = () => ({
  type: types.FETCH_ALL_SPECIALITIES,
});

export const setBlankState = isBlankState => ({
  type: types.SET_CLINIC_SPECIALITIES_BLANK_STATE,
  isBlankState,
});

export const setClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.SET_CLINIC_SPECIALITIES,
  clinicSpecialitiesIds,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_CLINIC_SPECIALITIES_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_CLINIC_SPECIALITIES_SORT_ORDER,
  sortKey,
});

export const filterClinicSpecialities = filters => ({
  type: types.SET_CLINIC_SPECIALITIES_FILTER,
  filters,
});

export const deactivateClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.DEACTIVATE_CLINIC_SPECIALITIES,
  clinicSpecialitiesIds,
});

export const activateClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.ACTIVATE_CLINIC_SPECIALITIES,
  clinicSpecialitiesIds,
});

export const removeClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.REMOVE_CLINIC_SPECIALITIES,
  clinicSpecialitiesIds,
});

export const selectClinicSpeciality = clinicSpecialitiesId => ({
  type: types.SELECT_CLINIC_SPECIALITIES,
  clinicSpecialitiesId,
});

export const setSelectedClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.SET_CLINIC_SPECIALITIES_SELECTED,
  clinicSpecialitiesIds,
});

export const removeSelectedClinicSpecialities = clinicSpecialitiesIds => ({
  type: types.REMOVE_SELECTED_CLINIC_SPECIALITIES,
  clinicSpecialitiesIds,
});

export const clinicSpecialityAssignDoctorsInitialState = clinicSpecialityId => ({
  type: types.CLINIC_SPECIALITY_ASSIGN_DOCTORS_INITIAL_STATE,
  clinicSpecialityId,
});

export const clinicSpecialityAssignDoctorsResetState = () => ({
  type: types.CLINIC_SPECIALITY_ASSIGN_DOCTORS_RESET_STATE,
});

export const submitClinicSpeciality = makeFormSubmitAction(types.SUBMIT_CLINIC_SPECIALITY);
export const updateClinicSpeciality = makeFormSubmitAction(types.UPDATE_CLINIC_SPECIALITY);

export const submitVirtualClinicSpeciality = makeFormSubmitAction(
  types.SUBMIT_VIRTUAL_CLINIC_SPECIALITY,
);
export const updateVirtualClinicSpeciality = makeFormSubmitAction(
  types.UPDATE_VIRTUAL_CLINIC_SPECIALITY,
);
export const submitClinicSpecialityProviders = makeFormSubmitAction(
  types.SUBMIT_CLINIC_SPECIALITY_PROVIDERS,
);

export const fetchClinicSpecialitiesList = lastId => ({
  type: types.FETCH_CLINIC_SPECIALITIES_LIST,
  lastId,
});

export const filterClinicSpecialitiesList = filters => ({
  type: types.SET_CLINIC_SPECIALITIES_LIST_FILTERS,
  filters,
});

export const setClinicSpecialitiesListIds = ids => ({
  type: types.SET_CLINIC_SPECIALITIES_LIST_IDS,
  ids,
});

export const resetClinicSpecialitiesListIds = () => ({
  type: types.RESET_CLINIC_SPECIALITIES_LIST_IDS,
});

export const setClinicSpecialitiesListStatuses = statuses => ({
  type: types.SET_CLINIC_SPECIALITIES_LIST_STATUSES,
  statuses,
});

export const resetClinicSpecialitiesListStatuses = () => ({
  type: types.RESET_CLINIC_SPECIALITIES_LIST_STATUSES,
});

export const setClinicSpecialitiesListFilterParams = filterParams => ({
  type: types.SET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetClinicSpecialitiesListFilterParams = () => ({
  type: types.RESET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS,
});
