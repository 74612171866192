import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'views/shared/SvgIcon';

const PrevArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="modal-files__arrow modal-files__arrow--left"
  >
    <SvgIcon icon="arrow-left" />
  </button>
);

PrevArrow.defaultProps = {
  onClick: undefined,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

export default PrevArrow;
