import { createLogic } from 'redux-logic';
import { dec, length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchVouchers, setCurrentPage } from '../actions';
import { vouchersTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeVouchersEndpoint } from '../endpoints';
import { REMOVE_VOUCHERS } from '../types';

const removeVouchersOperation = createLogic({
  type: REMOVE_VOUCHERS,
  latest: true,

  async process({ httpClient, getState, action: { voucherIds } }, dispatch, done) {
    const { url, endpoint } = removeVouchersEndpoint;
    const state = getState();
    const count = length(voucherIds);
    const totalCount = vouchersTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, count);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.delete(url, { params: { voucher_ids: voucherIds } });

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.vouchersDeleted',
          values: { count },
        },
      }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchVouchers());
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.vouchersDeletedError',
          values: { count },
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeVouchersOperation;
