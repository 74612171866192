const namespace = 'session';

export const USER_LOGIN_SUCCESS = `${namespace}/USER_LOGIN_SUCCESS`;
export const USER_LOGOUT = `${namespace}/USER_LOGOUT`;
export const SUBMIT_USER_LOGIN = `${namespace}/SUBMIT_USER_LOGIN`;
export const SET_VISIBLE_AUTH_FLOW = `${namespace}/SET_VISIBLE_AUTH_FLOW`;
export const SET_CURRENT_USER_ROLE = `${namespace}/SET_CURRENT_USER_ROLE`;
export const SET_USER_TYPE = `${namespace}/SET_USER_TYPE`;
export const SUBMIT_USER_TWO_FACTOR_AUTH_CODE = `${namespace}/SUBMIT_USER_TWO_FACTOR_AUTH_CODE`;
export const SUBMIT_USER_RECOVERY_CODE = `${namespace}/SUBMIT_USER_RECOVERY_CODE`;
export const REFRESH_ACTIVITY = `${namespace}/REFRESH_ACTIVITY`;

export const SET_PATIENT_ID = `${namespace}/SET_PATIENT_ID`;
export const PATIENT_LOGIN = `${namespace}/PATIENT_LOGIN`;
export const PATIENT_LOGIN_BY_ONE_TIME_TOKEN = `${namespace}/PATIENT_LOGIN_BY_ONE_TIME_TOKEN`;
export const PATIENT_LOGOUT = `${namespace}/PATIENT_LOGOUT`;
