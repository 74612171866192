import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { flatten } from 'ramda';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const ConfirmModal = ({
  onClose,
  onSubmit,
  title,
  bodyText,
  submitText,
  submitClassName,
  dismissText,
  isLoading,
  kind,
  modalClassName,
  isCloseable,
}) => (
  <Modal
    className={classNames('ant-modal', modalClassName)}
    wrapClassName="ant-modal-wrap__4"
    onClose={isCloseable ? onClose : undefined}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage {...title} />
      </h1>
      {isCloseable && (
        <button
          type="button"
          onClick={onClose}
        >
          <SvgIcon icon="cross" className="ant-modal__close" />
        </button>
      )}
    </div>
    <div className="ant-modal__body">
      <div className="modal-message">
        {bodyText.map((paragraph, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <p className={classNames('modal-message__txt', { 'mb-0': bodyText.length === index + 1 })} key={index}>
            {
              flatten([paragraph]).map((text) => (
                text.isBold
                  ? <span className="f-700" key={text.id}><FormattedMessage {...text} /></span>
                  : <FormattedMessage key={text.id} {...text} />
              ))
            }
          </p>
        ))}
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      {isCloseable && (
        <Button
          text={dismissText}
          onClick={onClose}
          kind="tertiary"
        />
      )}
      {onSubmit && (
        <Button
          className={submitClassName}
          type="submit"
          text={submitText}
          onClick={onSubmit}
          disabled={isLoading}
          kind={kind}
        />
      )}
    </div>
  </Modal>
);

ConfirmModal.defaultProps = {
  isLoading: undefined,
  kind: null,
  dismissText: { id: 'shared.cancel' },
  submitText: { id: 'shared.ok' },
  submitClassName: null,
  modalClassName: null,
  isCloseable: true,
  onSubmit: undefined,
};

ConfirmModal.propTypes = {
  kind: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
  bodyText: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
  ).isRequired,
  submitText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  dismissText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  submitClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  isCloseable: PropTypes.bool,
};

export default ConfirmModal;
