import { FormattedMessage } from 'react-intl';

import ErrorLayout from 'views/layouts/Error';
import Button from 'views/shared/Button';
import image from 'public/static/images/errors/ill-404-error.svg';

const AccessError = () => (
  <ErrorLayout>
    <div className="row align-items-md-center justify-content-center">
      <div className="col-sm-8 col-md-5">
        <div className="error-page__info">
          <div className="error-page-title">
            <FormattedMessage id="accessErrorPage.title" />
          </div>
          <p className="error-page-txt text-subheader">
            <FormattedMessage id="accessErrorPage.body" />
          </p>
          <Button
            isLink
            href="/appointments"
            className="main-btn--sm-100"
            text={{ id: 'accessErrorPage.button' }}
          />
        </div>
      </div>
      <div className="col-sm-8 col-md-5">
        <div className="error-page-img">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  </ErrorLayout>
);

export default AccessError;
