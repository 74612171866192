import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { showNotification } from 'state/notifications/actions';
import { setResentPasswordStatus } from '../actions';
import { isResentPasswordSelector } from '../selectors';
import { submitPatientResetPasswordEndpoint } from '../endpoints';
import { SUBMIT_PATIENT_RESET_PASSWORD } from '../types';

const submitPatientResetPasswordOperation = createLogic({
  type: SUBMIT_PATIENT_RESET_PASSWORD,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = submitPatientResetPasswordEndpoint;
    const state = getState();
    const isResentPassword = isResentPasswordSelector(state);

    try {
      const params = {
        ...values,
        redirect_to: '/patient/reset_password/confirm',
      };

      await httpClient.post(url, params);

      if (isResentPassword) {
        dispatch(showNotification({ messageObject: { id: 'notifications.resentRecoveryLink' } }));
      } else {
        dispatch(setResentPasswordStatus(true));
      }

      form.setStatus({});
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPatientResetPasswordOperation;
