import { createLogic } from 'redux-logic';
import { length, flatten, pluck, isEmpty } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchVirtualClinicDoctors } from 'state/concepts/userProfile/virtualClinicDoctors/actions';

import { fetchClinicSpecialities } from '../actions';
import { userProfileClinicSpecialitiesListSelector } from '../selectors';
import { virtualClinicSpecialitiesProvidersEndpoint } from '../endpoints';
import { SUBMIT_CLINIC_SPECIALITY_PROVIDERS } from '../types';

const submitVirtualClinicSpecialityProvidersOperation = createLogic({
  type: SUBMIT_CLINIC_SPECIALITY_PROVIDERS,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const state = getState();
    const { values, form, clinicSpeciality } = action;
    const { doctors, isAssignDoctors, isNewClinicSpeciality, isEditClinicSpeciality } = values;
    const { id } = clinicSpeciality;
    const { url, endpoint } = virtualClinicSpecialitiesProvidersEndpoint(id);
    const clinicSpecialities = userProfileClinicSpecialitiesListSelector(state, id);
    const count = length(flatten(pluck('userProfileIds', doctors)));

    const providers = doctors.map(doctor => ({
      user_profile_ids: doctor.userProfileIds.map(userId => Number(userId)),
      price: doctor.price,
      free_visit: doctor.freeVisit,
    }));

    const params = {
      providers,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);

      dispatch(dataApiSuccess({ endpoint }));
      if (isAssignDoctors) {
        dispatch(hideModal());
        dispatch(showNotification({
          messageObject: {
            id: isEmpty(clinicSpecialities)
              ? 'notifications.clinicSpecialityAssignDoctors'
              : 'notifications.clinicSpecialityUpdated',
            values: { count },
          },
        }));
      }
      if (isNewClinicSpeciality) {
        await redirect({ href: '/clinic_specialities' });
        dispatch(showNotification({
          messageObject: {
            id: 'notifications.clinicSpecialityAssignDoctors',
            values: { count },
          },
        }));
      }
      if (isEditClinicSpeciality) {
        dispatch(showNotification({
          messageObject: { id: 'notifications.clinicSpecialityUpdated' },
        }));
      }
      dispatch(fetchClinicSpecialities());
      dispatch(fetchVirtualClinicDoctors());
    } catch (error) {
      requestErrorHandler({
        error,
        dispatch,
        endpoint,
        form,
        disableCapitalize: true,
      });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitVirtualClinicSpecialityProvidersOperation;
