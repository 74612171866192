import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import Button from 'views/shared/Button';
import CurrencyField from 'views/shared/CurrencyField';
import SwitchField from 'views/shared/SwitchField';
import SvgIcon from 'views/shared/SvgIcon';
import DropdownClinicSpecialitiesField from 'views/shared/DropdownClinicSpecialitiesField';

const ClinicSpecialitiesProviderComponent = ({
  form: { values: { clinicSpecialities }, errors, touched },
  onAddProvider,
  onRemoveProvider,
}) => (
  <>
    {clinicSpecialities.map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`assign-doctor-${index}`} className="assign-doctor">
        <div className="flex-grow-1">
          <Field
            id={`clinicSpecialities.${index}.clinicSpecialityIds`}
            name={`clinicSpecialities.${index}.clinicSpecialityIds`}
            component={DropdownClinicSpecialitiesField}
            label={{ id: 'label.specialities' }}
            optionGroupLabel={{ id: 'label.specialities' }}
            placeholder={{ id: 'placeholder.specialities' }}
            selectClassName={classNames({
              'multiselect--has-message-error':
                errors.clinicSpecialities && errors.clinicSpecialities[index]
                && touched.clinicSpecialities && touched.clinicSpecialities[index]
                && errors.clinicSpecialities[index].clinicSpecialityIds,
            })}
            className={classNames(
              'multiselect-field multiselect-field--assign-clinic-specialities',
              { 'multiselect-field--short-dropdown-menu': clinicSpecialities.length < 2 },
            )}
            dropdownClassName="multiselect-field__dropdown"
            mode="multiple"
            icon="alert"
          />
        </div>
        <div className="flex-shrink-0 w-160">
          <div className="mb-8">
            <Field
              name={`clinicSpecialities.${index}.price`}
              component={CurrencyField}
              id={`clinicSpecialities.${index}.price`}
              label={{ id: 'label.pricePerSession' }}
              placeholder={{ id: 'placeholder.pricePerSession' }}
              icon="alert"
              min={0}
            />
          </div>
          <div>
            <Field
              name={`clinicSpecialities.${index}.freeVisit`}
              id={`clinicSpecialities.${index}.freeVisit`}
              label={{ id: 'label.acceptFreeVisits' }}
              component={SwitchField}
              className="mb-0"
            />
          </div>
        </div>
        {clinicSpecialities.length > 1 && (
          <button
            className="assign-doctor__providers-btn"
            onClick={onRemoveProvider(index)}
            type="button"
          >
            <SvgIcon icon="delete-o" />
          </button>
        )}
      </div>
    ))}
    {clinicSpecialities.length < 2 && (
      <Button
        kind="empty"
        icon="plus"
        text={{ id: 'doctors.assignClinicSpecialities.modal.addSpecialitiesWithAnotherPrice' }}
        onClick={onAddProvider}
      />
    )}
  </>
);

ClinicSpecialitiesProviderComponent.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      clinicSpecialities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    errors: PropTypes.shape({
      clinicSpecialities: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    touched: PropTypes.shape({
      clinicSpecialities: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
  }).isRequired,
  onAddProvider: PropTypes.func.isRequired,
  onRemoveProvider: PropTypes.func.isRequired,
};

export default ClinicSpecialitiesProviderComponent;
