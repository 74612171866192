import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { capitalize } from 'lodash';
import { path, prop } from 'ramda';

import profileName from 'utils/profileName';

const PrescriptionComponent = ({
  note,
}) => (
  <div className="view-note-table">
    <div className="view-note-table__header">
      <div className="view-note-table__col">
        <FormattedMessage id="note.modal.prescription" />
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patient" />
        </span>
        <span className="view-note-table__col-value">
          {profileName(path(['appointment', 'childProfile'], note) || prop('patientProfile', note))}
        </span>
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patientCitizenId" />
        </span>
        <span className="view-note-table__col-value">
          {prop('patientCitizenId', note)}
        </span>
      </div>
    </div>
    {prop('prescriptions', note).map((prescription) => (
      <div className="view-note-table__content" key={prop('id', prescription)}>
        <table className="modal-view-note__diagnose">
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="note.modal.drug" />
              </td>
              <td>
                {prop('drug', prescription)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.commercialName" />
              </td>
              <td>
                {prop('commercialName', prescription)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.dose" />
              </td>
              <td>
                {`${prop('doseValue', prescription)} `}
                <FormattedMessage id={`notePrescription.${prop('doseMeasure', prescription)}`} />
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.frequency" />
              </td>
              <td>
                {`${prop('frequencyValue', prescription)} ${capitalize(prop('frequencyRecurrence', prescription))}`}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.quantity" />
              </td>
              <td>
                {`${prop('packageQuantity', prescription)} `}
                <FormattedMessage id={`notePrescription.${prop('packageType', prescription)}`} />
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.comment" />
              </td>
              <td>
                {prop('comment', prescription)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))}
  </div>
);

PrescriptionComponent.propTypes = {
  note: PropTypes.shape().isRequired,
};

export default PrescriptionComponent;
