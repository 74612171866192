import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import { Views } from 'react-big-calendar';
import {
  prop, isEmpty, path, compose, not, any, values, omit,
} from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector } from 'state/data/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { fetchAppointmentsEndpoint } from './endpoints';

const dataSelector = prop('data');

export const appointmentIdsSelector = path(['appointments', 'appointmentIds']);

export const isBlankStateSelector = path(['appointments', 'isBlankState']);

export const appointmentsSelector = createSelector(
  appointmentIdsSelector,
  dataSelector,
  buildCollection('appointment'),
);

export const filtersSelector = path(['appointments', 'filters']);

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(
    any(compose(not, isEmpty)),
    values,
    omit(['name']),
  ),
);

export const appliedFiltersSelector = (state, name) => path(['appointments', 'filters', name], state);

export const timezoneSelector = path(['appointments', 'timezone']);

export const selectedTimezoneSelector = createSelector(
  timezoneSelector,
  currentUserSelector,
  (timezone, currentUser) => timezone || prop('timezone', currentUser),
);

export const timezoneOffsetSelector = createSelector(
  selectedTimezoneSelector,
  dataSelector,
  (timezone, data) => path(['timezone', timezone, 'attributes', 'offset'], data),
);

export const appointmentsViewSelector = path(['appointments', 'view']);

export const appointmentsDateSelector = path(['appointments', 'date']);

export const datesFilterSelector = createSelector(
  appointmentsViewSelector,
  appointmentsDateSelector,
  (view, date) => {
    const startDate = view === Views.DAY ? (DateTime.fromISO(date, { zone: 'UTC' })) : DateTime.fromISO(date, { zone: 'UTC' }).startOf('month');
    const endDate = view === Views.DAY ? startDate : DateTime.fromISO(date, { zone: 'UTC' }).endOf('month');

    return { startDate, endDate };
  },
);

export const firstAppointmentTimeSelector = createSelector(
  appointmentIdsSelector,
  dataSelector,
  (appointmentIds, data) => path(['appointments', appointmentIds[0], 'attributes', 'startTime'], data),
);

export const appointmentsLoadingSelector = state => (
  loadingSelector(state, fetchAppointmentsEndpoint.endpoint)
);

export const cancellationRulesSelector = path(['appointments', 'cancellationRules']);

export const cancellationErrorSelector = path(['appointments', 'cancellationError']);

export const lastOpenedAppointmentTooltipIdSelector = path(['appointments', 'lastOpenedAppointmentTooltipId']);
