import yup from 'lib/yupLocalised';
import { MAX_INPUT_LENGTH } from 'constants';

export default yup.object().shape({
  invitations: yup.array().of(
    yup.object().shape({
      email: yup.string().isValidEmail().max(MAX_INPUT_LENGTH).required(),
      role: yup.string().required(),
    }),
  ),
});
