import endpoint from 'utils/endpoint';

import {
  diseasesRoute,
  diseasesSearchRoute,
  commonDiseasesRoute,
  completeCancelDiseasesRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const getDiseasesEndpoint = endpoint(types.GET_DISEASES, 'GET', diseasesRoute);
export const getCommonDiseasesEndpoint = endpoint(types.GET_COMMON_DISEASES, 'GET', commonDiseasesRoute);
export const getCompleteCancelDiseasesEndpoint = endpoint(types.GET_COMPLETE_CANCEL_DISEASES, 'GET', completeCancelDiseasesRoute);
export const searchDiseasesEndpoint = endpoint(types.SEARCH_DISEASES, 'GET', diseasesSearchRoute);
