export const SPECIALITIES_STATUSES = {
  all: '',
  active: 'active',
  inactive: 'inactive',
};

export const SPECIALITIES_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: SPECIALITIES_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: SPECIALITIES_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
];

export const MAX_SPECIALITIES_NAME = 50;
