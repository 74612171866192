import { createLogic } from 'redux-logic';
import { map } from 'ramda';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';
import parseValuesFormToParams from 'utils/clinicSpecialities/parseValuesFormToParams';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { clinicIdSelector } from '../selectors';
import { updateVirtualClinicSpecialityEndpoint } from '../endpoints';
import { UPDATE_VIRTUAL_CLINIC_SPECIALITY } from '../types';

const updateVirtualClinicSpecialityOperation = createLogic({
  type: UPDATE_VIRTUAL_CLINIC_SPECIALITY,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const state = getState();
    const clinicId = clinicIdSelector(state);
    const { values, form, clinicSpecialityId } = action;
    const { url, endpoint } = updateVirtualClinicSpecialityEndpoint(clinicId, clinicSpecialityId);

    try {
      const params = {
        ...parseValuesFormToParams(values),
        speciality_id: values.specialityId,
        symptom_ids: values.symptoms,
        status: values.status
          ? CLINIC_SPECIALITIES_STATUSES.active
          : CLINIC_SPECIALITIES_STATUSES.inactive,
      };

      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'clinicSpeciality', clinicSpecialityId,
        {
          attributes: {
            status: params.status,
            duration: params.duration,
            bufferAfter: params.buffer_after,
            cancellableOption: params.cancellable_option,
            cancellableUntil: params.cancellable_until,
            minLeadTime: params.min_lead_time,
            maxFutureTime: params.max_future_time,
            reschedulableOption: params.reschedulable_option,
            reschedulableUntil: params.reschedulable_until,
          },
          relationships: {
            speciality: {
              data: {
                id: values.specialityId,
              },
            },
            symptoms: {
              data: map(id => ({ id, type: 'symptom' }), values.symptoms),
            },
          },
        },
      );

      dispatch(dataApiSuccess({ response }));
      dispatch(showNotification({ messageObject: { id: 'notifications.clinicSpecialityUpdated' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateVirtualClinicSpecialityOperation;
