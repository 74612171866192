const namespace = 'filters';

export const GET_PATIENTS = `${namespace}/GET_PATIENTS`;
export const GET_EXTRA_FREE_VISITS = `${namespace}/GET_EXTRA_FREE_VISITS`;
export const GET_SPECIALITIES = `${namespace}/GET_SPECIALITIES`;
export const GET_CLINIC_SPECIALITIES = `${namespace}/GET_CLINIC_SPECIALITIES`;
export const GET_SYMPTOMS = `${namespace}/GET_SYMPTOMS`;
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`;
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`;
export const GET_DOCTORS = `${namespace}/GET_DOCTORS`;
