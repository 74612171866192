import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { doctorProfileIdSelector } from '../selectors';
import { uploadDoctorAvatarEndpoint } from '../endpoints';
import { UPLOAD_DOCTOR_AVATAR } from '../types';

const uploadDoctorAvatarOperation = createLogic({
  type: UPLOAD_DOCTOR_AVATAR,
  latest: true,

  async process({ action: { data, isImageUrl }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const id = doctorProfileIdSelector(state);
    const { url, endpoint } = uploadDoctorAvatarEndpoint(id);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data: responseData } = await httpClient.put(url, data, { headers: { 'content-type': 'multipart/form-data' } });
      const response = normalize(responseData);

      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'profileImageUpdated' : 'profileImageAdded'}` },
      }));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'profileImageUpdatedError' : 'profileImageAddedError'}` },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default uploadDoctorAvatarOperation;
