import { combineReducers } from 'redux';

import {
  SET_APPOINTMENT,
  SET_QUESTIONNAIRE_RESPONSES,
  TOGGLE_SYMPTOM_DETAILS_PANEL,
  SET_NOTE_PANEL_VISIBLE,
  SET_NOTIFICATION_CANCEL_CONFERENCE,
} from './types';

const appointmentId = (state = '', action) => {
  switch (action.type) {
    case SET_APPOINTMENT:
      return action.appointmentId;
    default:
      return state;
  }
};

const questionnaireResponsesId = (state = '', action) => {
  switch (action.type) {
    case SET_QUESTIONNAIRE_RESPONSES:
      return action.questionnaireResponsesId;
    default:
      return state;
  }
};

const symptomDetailsPanelVisible = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SYMPTOM_DETAILS_PANEL:
      return action.togglePanel;
    default:
      return state;
  }
};

const notePanelVisible = (state = false, action) => {
  switch (action.type) {
    case SET_NOTE_PANEL_VISIBLE:
      return action.panelVisible;
    default:
      return state;
  }
};

const notificationCancelConference = (state = false, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_CANCEL_CONFERENCE:
      return action.show;
    default:
      return state;
  }
};

export default combineReducers({
  appointmentId,
  questionnaireResponsesId,
  symptomDetailsPanelVisible,
  notePanelVisible,
  notificationCancelConference,
});
