import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import { USER_ROLE } from 'constants/users';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { setVirtualClinicIds, setBlankState } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import { FETCH_VIRTUAL_CLINICS } from '../types';
import { fetchVirtualClinicsEndpoint } from '../endpoints';

const fetchVirtualClinicsOperation = createLogic({
  type: FETCH_VIRTUAL_CLINICS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchVirtualClinicsEndpoint;
    const state = getState();
    const params = {
      include: 'clinic',
      page: paginationSelector(state),
      filter: {
        ...commonFiltersSelector(state),
        roles: [USER_ROLE.virtualClinicAdmin],
      },
      sort: sortParamsSelector(state),
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setBlankState(isBlankState(params.filter, data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setVirtualClinicIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVirtualClinicsOperation;
