import endpoint from 'utils/endpoint';
import {
  patientsRoute,
  pushNotificationsRoute,
  pushNotificationClinicsRoute,
  validatePushNotificationsRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchPushNotificationsEndpoint = endpoint(types.FETCH_PUSH_NOTIFICATIONS, 'GET', pushNotificationsRoute);
export const fetchClinicsEndpoint = endpoint(types.FETCH_PUSH_NOTIFICATION_CLINICS, 'GET', pushNotificationClinicsRoute);
export const fetchPatientsEndpoint = endpoint(types.FETCH_PUSH_NOTIFICATION_PATIENTS, 'GET', patientsRoute);
export const validatePushNotificationsEndpoint = endpoint(types.VALIDATE_PUSH_NOTIFICATIONS, 'GET', validatePushNotificationsRoute);
export const submitCreatePushNotificationEndpoint = endpoint(types.SUBMIT_CREATE_PUSH_NOTIFICATION, 'POST', pushNotificationsRoute);
