import React from 'react';

import AppointmentCalendarTooltipActionsComponent from './component';

class AppointmentCalendarTooltipActions extends React.Component {
  popupContainerRef = React.createRef();

  getPopupContainer = () => this.popupContainerRef.current;

  render() {
    return (
      <AppointmentCalendarTooltipActionsComponent
        {...this.props}
        getPopupContainer={this.getPopupContainer}
        popupContainerRef={this.popupContainerRef}
      />
    );
  }
}

export default AppointmentCalendarTooltipActions;
