import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import {
  getClinicIdsValue,
  getPatientIdsValue,
  getSpecialityIdsValue,
  getGenderValue,
  getChildrenValue,
  getAgeFromValue,
  getAgeToValue,
} from 'utils/pushNotifications';
import removeHtmlTags from 'utils/removeHtmlTags';

import { hideModal } from 'state/modal/actions';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';

import { fetchPushNotifications, toggleConfirmModalVisible } from '../actions';
import { submitCreatePushNotificationEndpoint } from '../endpoints';
import { SUBMIT_CREATE_PUSH_NOTIFICATION } from '../types';

const submitCreatePushNotificationOperation = createLogic({
  type: SUBMIT_CREATE_PUSH_NOTIFICATION,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = submitCreatePushNotificationEndpoint;

    const params = {
      filter: {
        ...getClinicIdsValue(values.clinicIds),
        ...getPatientIdsValue(values.patientIds),
        ...getSpecialityIdsValue(values.specialityIds),
        ...getGenderValue(values.gender),
        ...getChildrenValue(values.children),
        ...getAgeFromValue(values.ageFrom),
        ...getAgeToValue(values.ageTo),
      },
      message: removeHtmlTags(values.message),
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(fetchPushNotifications());
      dispatch(showNotification({ messageObject: { id: 'notifications.pushNotificationHasBeenSend' } }));
      dispatch(toggleConfirmModalVisible());
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitCreatePushNotificationOperation;
