import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'antd';
import { isEmpty } from 'ramda';

import profileName from 'utils/profileName';

import SelectField from 'views/shared/SelectField';
import LoadMoreLoader from 'views/shared/LoadMoreLoader';
import DropdownNotFoundContent from 'views/shared/DropdownNotFoundContent';

const DropdownDoctorsFieldComponent = ({
  fetchDoctorList,
  isLoading,
  isOpen,
  isAllDoctorsSelected,
  meta,
  options,
  optionGroupLabel,
  onDropdownVisibleChange,
  onCheckSelectedOption,
  onSearch,
  onFocus,
  intl,
  ...rest
}) => (
  <SelectField
    {...rest}
    onDropdownVisibleChange={onDropdownVisibleChange}
    onSearch={onSearch}
    onFocus={onFocus}
    optionLabelProp="label"
    open={isOpen}
    filterOption={false}
    showSearch
    mountToElement
    notFoundContent={(
      <DropdownNotFoundContent
        isLoading={isLoading}
        isAllOptionsSelected={isAllDoctorsSelected}
        description="clinicSpecialities.assignDoctors.allDoctorsAdded"
      />
    )}
  >
    {(isLoading && (isEmpty(options) || isAllDoctorsSelected)) ? [] : (
      <Select.OptGroup label={intl.formatMessage(optionGroupLabel)}>
        {options.map(doctor => {
          const doctorName = profileName(doctor);
          const isHidden = onCheckSelectedOption(doctor.id);
          return (
            <Select.Option
              label={(
                <span className={classNames('multiselect-field__choice-item', {
                    'multiselect-field__choice-item--active': doctor.active,
                    'multiselect-field__choice-item--inactive': !doctor.active,
                  })}
                >
                  {doctorName}
                </span>
              )}
              title={doctorName}
              value={doctor.id}
              key={doctor.id}
              disabled={isHidden}
              className={classNames({ 'd-none': isHidden })}
            >
              {doctorName}
            </Select.Option>
          );
        })}
        <Select.Option value="" disabled className="multiselect-field__dropdown-item--loader">
          <LoadMoreLoader
            isLoading={isLoading}
            meta={meta}
            options={options}
            loadMore={fetchDoctorList}
          />
        </Select.Option>
      </Select.OptGroup>
    )}
  </SelectField>
);

DropdownDoctorsFieldComponent.defaultProps = {
  isLoading: undefined,
  meta: {},
};

DropdownDoctorsFieldComponent.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isAllDoctorsSelected: PropTypes.bool.isRequired,
  meta: PropTypes.shape({ hasMore: PropTypes.bool }),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  optionGroupLabel: PropTypes.shape().isRequired,
  onDropdownVisibleChange: PropTypes.func.isRequired,
  onCheckSelectedOption: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  fetchDoctorList: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
};

export default DropdownDoctorsFieldComponent;
