import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { activateClinicSpecialities } from 'state/concepts/userProfile/clinicSpecialities/actions';
import { activateClinicSpecialitiesLoadingSelector } from 'state/concepts/userProfile/clinicSpecialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ClinicSpecialitiesActivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clinicSpeciality: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, clinicSpeciality: { id } } = this.props;
    onSubmit([id]);
  }

  render() {
    const { clinicSpeciality: { name } } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        submitClassName="w-136"
        onSubmit={this.handleSubmit}
        title={{ id: 'clinicSpecialities.activate.modal.title' }}
        bodyText={[{
          id: 'clinicSpecialities.activate.modal.body',
          values: { name: <span className="f-700">{name}</span> },
        }]}
        submitText={{ id: 'shared.activate' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: activateClinicSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: activateClinicSpecialities,
};

export { ClinicSpecialitiesActivateModal as ClinicSpecialitiesActivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ClinicSpecialitiesActivateModal);
