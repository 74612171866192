import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetAuthenticationVirtualClinic } from 'state/concepts/userProfile/virtualClinics/actions';
import { resetAuthVirtualClinicLoadingSelector } from 'state/concepts/userProfile/virtualClinics/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ResetAuthSettingsVirtualClinicModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    virtualClinic: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, virtualClinic: { id } } = this.props;

    onSubmit(id);
  }

  render() {
    const { virtualClinic } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'virtualClinics.clinics.resetAuthSettings.title' }}
        bodyText={[{
          id: 'virtualClinics.clinics.resetAuthSettings.body',
          values: { clinicName: <span className="f-700">{virtualClinic.clinic.name}</span> },
        }]}
        submitText={{ id: 'shared.confirm' }}
        submitClassName="w-136"
      />
    );
  }
}

const mapStateToProps = (state, { virtualClinic: { clinic: { id } } }) => ({
  isLoading: resetAuthVirtualClinicLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: resetAuthenticationVirtualClinic,
};

export { ResetAuthSettingsVirtualClinicModal as ResetAuthSettingsVirtualClinicModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ResetAuthSettingsVirtualClinicModal);
