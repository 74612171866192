import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { removeAttachmentSuccess } from '../actions';
import { removeChatsAttachmentsEndpoint } from '../endpoints';
import { REMOVE_ATTACHMENT } from '../types';

const removeAttachmentOperation = createLogic({
  type: REMOVE_ATTACHMENT,
  latest: true,

  async process({ httpClient, action: { chatId, attachmentId } }, dispatch, done) {
    const { url, endpoint } = removeChatsAttachmentsEndpoint(chatId, attachmentId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(removeAttachmentSuccess(attachmentId));
      dispatch(showNotification({ messageObject: { id: 'notifications.fileHasBeenDeleted' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.fileHasBeenDeletedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeAttachmentOperation;
