import { DateTime } from 'luxon';
import { find, pathEq } from 'ramda';

export default (timezones, timezoneName) => {
  let deviceTimezone = find(pathEq(['attributes', 'tzinfoName'], timezoneName))(timezones);

  if (!deviceTimezone) {
    const localDateTime = DateTime.local();
    const offset = localDateTime.zone.formatOffset(localDateTime.toMillis(), 'short');
    deviceTimezone = find(pathEq(['attributes', 'offset'], offset))(timezones);
  }

  return deviceTimezone;
};
