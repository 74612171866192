import nodeCookie from 'cookie';
import browserCookie from 'component-cookie';

export default (ctx, name, value, options) => {
  if (ctx && ctx.isServer) {
    ctx.res.setHeader('Set-Cookie', nodeCookie.serialize(name, value, options));
  } else {
    browserCookie(name, value, options);
  }
};
