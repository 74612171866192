const namespace = 'freeVisits';

export const FETCH_FREE_VISITS = `${namespace}/FETCH_FREE_VISITS`;
export const SET_FREE_VISITS_IDS = `${namespace}/SET_FREE_VISITS_IDS`;
export const SET_FREE_VISITS_PAGE = `${namespace}/SET_FREE_VISITS_PAGE`;
export const SET_FREE_VISITS_SORT_ORDER = `${namespace}/SET_FREE_VISITS_SORT_ORDER`;
export const SET_FREE_VISITS_FILTER = `${namespace}/SET_FREE_VISITS_FILTER`;
export const SET_FREE_VISITS_BLANK_STATE = `${namespace}/SET_FREE_VISITS_BLANK_STATE`;
export const FETCH_FREE_VISITS_CLINICS = `${namespace}/FETCH_FREE_VISITS_CLINICS`;
export const SET_FREE_VISITS_CLINICS_LIST_IDS = `${namespace}/SET_FREE_VISITS_CLINICS_LIST_IDS`;
export const RESET_FREE_VISITS_CLINICS_LIST_IDS = `${namespace}/RESET_FREE_VISITS_CLINICS_LIST_IDS`;
export const SET_FREE_VISITS_CLINICS_LIST_FILTERS = `${namespace}/SET_FREE_VISITS_CLINICS_LIST_FILTERS`;
export const SET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS = `${namespace}/SET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS`;
export const RESET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS = `${namespace}/RESET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS`;
export const FETCH_FREE_VISITS_PATIENTS = `${namespace}/FETCH_FREE_VISITS_PATIENTS`;
export const SET_FREE_VISITS_PATIENTS_LIST_IDS = `${namespace}/SET_FREE_VISITS_PATIENTS_LIST_IDS`;
export const RESET_FREE_VISITS_PATIENTS_LIST_IDS = `${namespace}/RESET_FREE_VISITS_PATIENTS_LIST_IDS`;
export const SET_FREE_VISITS_PATIENTS_LIST_FILTERS = `${namespace}/SET_FREE_VISITS_PATIENTS_LIST_FILTERS`;
export const SET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS = `${namespace}/SET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS`;
export const RESET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS = `${namespace}/RESET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS`;
export const SUBMIT_CREATE_FREE_VISITS = `${namespace}/SUBMIT_CREATE_FREE_VISITS`;
export const SUBMIT_UPDATE_FREE_VISITS = `${namespace}/SUBMIT_UPDATE_FREE_VISITS`;
