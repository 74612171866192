import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from 'views/shared/ConfirmModal';

class UploadImageRemoveModalContainer extends React.Component {
  static propTypes = {
    removeAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    removeBodyText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  handleRemoveImage = () => {
    this.props.removeAction();
    this.props.onClose();
  };

  render = () => (
    <ConfirmModal
      {...this.props}
      onSubmit={this.handleRemoveImage}
      title={{ id: 'uploadImage.deleteImage' }}
      bodyText={this.props.removeBodyText}
      submitText={{ id: 'shared.remove' }}
      kind="danger"
    />
  );
}

export default UploadImageRemoveModalContainer;
