import * as types from './types';

export const getDiseases = () => ({
  type: types.GET_DISEASES,
});

export const searchDiseases = () => ({
  type: types.SEARCH_DISEASES,
});

export const filterDiseases = filters => ({
  type: types.FILTER_DISEASES,
  filters,
});

export const getDiseasesNodes = node => ({
  type: types.GET_DISEASES_NODES,
  node,
});

export const setDiseasesIds = ids => ({
  type: types.SET_DISEASES_IDS,
  ids,
});

export const updateDiseasesIds = ids => ({
  type: types.UPDATE_DISEASES_IDS,
  ids,
});

export const destroyDiseasesIds = () => ({
  type: types.DESTROY_DISEASES_IDS,
});

export const getCommonDiseases = () => ({
  type: types.GET_COMMON_DISEASES,
});

export const setCommonDiseasesIds = ids => ({
  type: types.SET_COMMON_DISEASES_IDS,
  ids,
});

export const getCompleteCancelDiseases = () => ({
  type: types.GET_COMPLETE_CANCEL_DISEASES,
});

export const setCompleteCancelDiseasesIds = ids => ({
  type: types.SET_COMPLETE_CANCEL_DISEASES_IDS,
  ids,
});
