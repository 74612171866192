import { createLogic } from 'redux-logic';
import { trim } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { formattedTimezoneSelector } from 'state/concepts/timezones/selectors';
import { virtualClinicDoctorIdSelector } from '../selectors';
import { updateVirtualClinicDoctorEndpoint } from '../endpoints';
import { UPDATE_VIRTUAL_CLINIC_DOCTOR } from '../types';

const updateVirtualClinicDoctorOperation = createLogic({
  type: UPDATE_VIRTUAL_CLINIC_DOCTOR,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const state = getState();
    const vcdId = virtualClinicDoctorIdSelector(state);
    const { url, endpoint } = updateVirtualClinicDoctorEndpoint(vcdId);

    const params = {
      first_name: trim(values.firstName),
      last_name: trim(values.lastName),
      about: values.about,
      license_number: values.licenseNumber,
      timezone: values.timezone,
      country: values.country,
      city: values.city,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);

      const attributes = {
        ...values,
        firstName: trim(values.firstName),
        lastName: trim(values.lastName),
        licenseNumber: values.licenseNumber,
        formattedTimezone: formattedTimezoneSelector(values.timezone)(state),
      };

      const response = updateDataHelper(
        state.data, 'userProfile', vcdId,
        { attributes },
      );

      form.setValues(attributes);

      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorsProfileUpdated' },
      }));

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorsProfileUpdatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateVirtualClinicDoctorOperation;
