import { isEmpty, not } from 'ramda';

import assignFormErrors from 'utils/form/assignFormErrors';

const makeHandleForm = (form, error, formStatusKeys, disableCapitalize) => () => {
  if (not(isEmpty(form))) {
    assignFormErrors(form, error, formStatusKeys, disableCapitalize);
  }
};

export default makeHandleForm;
