import React from 'react';
import { connect } from 'react-redux';
import { compose, prop } from 'ramda';
import { withFormik } from 'formik';

import validationSchema from 'lib/yupLocalised/schemas/editFreeVisits';

import profileName from 'utils/profileName';
import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { submitUpdateFreeVisits } from 'state/concepts/userProfile/freeVisits/actions';
import { submitUpdateFreeVisitsLoadingSelector } from 'state/concepts/userProfile/freeVisits/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';

import EditExtraFreeVisitsModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class EditExtraFreeVisitsModal extends React.Component {
  render = () => (
    <EditExtraFreeVisitsModalComponent
      {...this.props}
    />
  );
}

const mapStateToProps = (state, { freeVisit: { id } }) => ({
  isLoading: submitUpdateFreeVisitsLoadingSelector(state, id),
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitUpdateFreeVisits,
};

export { EditExtraFreeVisitsModal as EditExtraFreeVisitsModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ freeVisit }) => ({
      clinicName: prop('clinicName', freeVisit),
      patientName: profileName(freeVisit),
      freeVisitsCount: prop('freeVisitsCount', freeVisit),
    }),
    validationSchema,
    enableReinitialize: true,
    handleSubmit: handleSubmitWithProps(['freeVisit']),
  }),
)(EditExtraFreeVisitsModal);
