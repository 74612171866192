import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';
import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { virtualClinicProfileIdSelector } from '../selectors';
import { submitVirtualClinicEndpoint } from '../endpoints';
import { SUBMIT_VIRTUAL_CLINIC_PROFILE } from '../types';

const submitVirtualClinicProfileOperation = createLogic({
  type: SUBMIT_VIRTUAL_CLINIC_PROFILE,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const state = getState();
    const clinicId = virtualClinicProfileIdSelector(state);
    const { url, endpoint } = submitVirtualClinicEndpoint(clinicId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        name: values.name,
        url: values.companyUrl,
        city: values.city,
        phone: formatISOPhoneNumber(values.phoneNumber),
        address: values.address,
        description: values.about,
      };

      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'clinic', clinicId,
        {
          attributes: {
            ...params,
          },
        },
      );

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(showNotification({ messageObject: { id: 'notifications.virtualClinicProfileHasBeenUpdated' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitVirtualClinicProfileOperation;
