import { includes } from 'ramda';

const formatPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    return includes('+', phoneNumber) ? `${phoneNumber}` : `+${phoneNumber}`;
  }
  return phoneNumber;
};

export default formatPhoneNumber;
