import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setQuestionnaireResponses } from '../actions';
import { getQuestionnaireResponsesEndpoint } from '../endpoints';
import { GET_QUESTIONNAIRE_RESPONSES } from '../types';

const getQuestionnaireResponsesOperation = createLogic({
  type: GET_QUESTIONNAIRE_RESPONSES,
  latest: true,

  async process({ action: { questionnaireResponsesId }, httpClient }, dispatch, done) {
    const { endpoint, url } = getQuestionnaireResponsesEndpoint(questionnaireResponsesId);
    const params = {
      include: ['answers', 'questionnaire-files'],
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setQuestionnaireResponses(questionnaireResponsesId));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getQuestionnaireResponsesOperation;
