/* eslint-disable react/no-danger */
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import icon57 from 'public/static/images/favicon/apple-touch-icon-57x57.png';
import icon114 from 'public/static/images/favicon/apple-touch-icon-114x114.png';
import icon72 from 'public/static/images/favicon/apple-touch-icon-72x72.png';
import icon144 from 'public/static/images/favicon/apple-touch-icon-144x144.png';
import icon60 from 'public/static/images/favicon/apple-touch-icon-60x60.png';
import icon120 from 'public/static/images/favicon/apple-touch-icon-120x120.png';
import icon76 from 'public/static/images/favicon/apple-touch-icon-76x76.png';
import icon152 from 'public/static/images/favicon/apple-touch-icon-152x152.png';
import icon196 from 'public/static/images/favicon/favicon-196x196.png';
import icon96 from 'public/static/images/favicon/favicon-96x96.png';
import icon32 from 'public/static/images/favicon/favicon-32x32.png';
import icon16 from 'public/static/images/favicon/favicon-16x16.png';
import icon128 from 'public/static/images/favicon/favicon-128.png';
import logo from 'public/static/images/logo.svg';

const MetaComponent = ({ pageTitle }) => {
  const { formatMessage } = useIntl();
  const title = pageTitle ? formatMessage(pageTitle) : '';

  return (
    <Head>
      <meta charSet="utf-8" />
      {pageTitle && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={icon57} />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href={icon114} />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href={icon72} />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href={icon144} />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href={icon60} />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href={icon120} />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href={icon76} />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href={icon152} />
      <link rel="icon" type="image/png" href={icon196} sizes="196x196" />
      <link rel="icon" type="image/png" href={icon96} sizes="96x96" />
      <link rel="icon" type="image/png" href={icon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={icon16} sizes="16x16" />
      <link rel="icon" type="image/png" href={icon128} sizes="128x128" />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta content="ie=edge" httpEquiv="x-ua-compatible" />
      <link rel="manifest" href="/manifest.json" />

      <meta property="og:type" content="website" />
      <meta property="og:image" content={logo} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={logo} />
      <meta name="twitter:image:alt" content="Clinix" />
    </Head>
  );
};

MetaComponent.defaultProps = {
  pageTitle: undefined,
};

MetaComponent.propTypes = {
  pageTitle: PropTypes.shape(),
};

export default MetaComponent;
