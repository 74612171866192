import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { parseInt } from 'lodash';
import { isNil } from 'ramda';

import { handleInputEvent } from 'utils/inputHelpers';
import parseIntegerNumber from 'utils/numbers/parseIntegerNumber';
import parseFloatNumber from 'utils/numbers/parseFloatNumber';

import NumericFieldComponent from './component';

class NumericField extends React.PureComponent {
  static propTypes = {
    isInteger: PropTypes.bool,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    minValue: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    minValue: 0,
    onChange: null,
  };

  static defaultProps = {
    isInteger: false,
  };

  formatter = value => (parseInt(value) >= 0 ? value.toString() : '');

  parser = value => {
    const { isInteger } = this.props;

    return isInteger ? parseIntegerNumber(value) : parseFloatNumber(value);
  }

  handleBlur = () => {
    const { form, field, minValue } = this.props;

    form.setFieldValue(field.name, field.value || minValue);
  };

  handleChange = async (value) => {
    const { field, onChange, minValue } = this.props;

    await handleInputEvent(field, field.onChange)(value || minValue);
    if (!isNil(onChange)) {
      onChange();
    }
  }

  render = () => (
    <NumericFieldComponent
      {...this.props}
      formatter={this.formatter}
      parser={this.parser}
      onBlur={this.handleBlur}
      onChange={this.handleChange}
    />
  );
}

export { NumericField as NumericFieldContainer };
export default injectIntl(NumericField);
