import { createSelector } from 'reselect';
import build from 'redux-object';
import {
  path, prop, intersection, equals, any, propEq,
} from 'ramda';

import buildCollection from 'utils/buildCollection';

import { totalCountSelector, pageCountSelector, loadingSelector } from 'state/data/selectors';
import {
  fetchSymptomsEndpoint,
  activateSymptomsEndpoint,
  deactivateSymptomsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['symptoms', 'isBlankState']);
export const selectedQuestionPositionSelector = path(['symptoms', 'selectedQuestionPosition']);
export const symptomIdsSelector = path(['symptoms', 'symptomIds']);
export const questionIdsSelector = path(['symptoms', 'questionIds']);
export const paginationSelector = path(['symptoms', 'pagination']);
export const sortSelector = path(['symptoms', 'sort']);
export const selectedSelector = path(['symptoms', 'selectedSymptoms']);
export const createSymptomStepSelector = path(['symptoms', 'createSymptomStep']);
export const symptomDetailsIdSelector = path(['symptoms', 'symptomDetailsId']);
export const symptomSpecialityIdsSelector = path(['symptoms', 'specialityIds']);

export const symptomDetailsSelector = createSelector(
  symptomDetailsIdSelector,
  dataSelector,
  (id, data) => (id ? build(data, 'symptom', id) : {}),
);

export const symptomsSelector = createSelector(
  symptomIdsSelector,
  dataSelector,
  buildCollection('symptom'),
);

export const questionsSelector = createSelector(
  questionIdsSelector,
  dataSelector,
  buildCollection('question'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const isSelectedSelector = createSelector(
  selectedSelector,
  (_, symptomId) => symptomId,
  (selectedSymptoms, symptomId) => selectedSymptoms.includes(symptomId),
);

const selectedOnPageSelector = createSelector(
  selectedSelector,
  symptomIdsSelector,
  intersection,
);

export const isAllSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  symptomIdsSelector,
  (selectedOnPage, symptomIds) => equals(selectedOnPage.length, symptomIds.length),
);

export const hasSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  selectedOnPage => selectedOnPage.length > 0,
);

export const selectedSymptomsSelector = createSelector(
  selectedSelector,
  dataSelector,
  buildCollection('symptom'),
);

export const isSpecificSymptomsSelected = createSelector(
  selectedSymptomsSelector,
  (_, key) => key,
  (symptoms, key) => any(propEq('status', key), symptoms),
);

export const symptomsTotalCountSelector = state => (
  totalCountSelector(state, fetchSymptomsEndpoint.endpoint)
);

export const symptomsPageCountSelector = state => (
  pageCountSelector(state, fetchSymptomsEndpoint.endpoint)
);

export const symptomsLoadingSelector = state => (
  loadingSelector(state, fetchSymptomsEndpoint.endpoint)
);

export const activateSymptomsLoadingSelector = state => (
  loadingSelector(state, activateSymptomsEndpoint.endpoint)
);

export const deactivateSymptomsLoadingSelector = state => (
  loadingSelector(state, deactivateSymptomsEndpoint.endpoint)
);
