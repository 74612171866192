import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import checkDuplicates from 'utils/invitations/checkDuplicates';

import InvitationInputFieldsComponent from './component';

class InvitationInputFields extends React.Component {
  static propTypes = {
    roleName: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
  };

  addRow = () => {
    const { push, roleName } = this.props;

    push({
      email: '',
      role: roleName,
      key: Math.random(),
    });
  };

  removeRow = index => () => {
    const { remove, form } = this.props;

    checkDuplicates(form, { without: index });
    remove(index);
  };

  handleBlur = (event) => {
    const { form } = this.props;

    form.handleBlur(event);
    checkDuplicates(form);
  };

  render = () => (
    <InvitationInputFieldsComponent
      {...this.props}
      addRow={this.addRow}
      removeRow={this.removeRow}
      handleBlur={this.handleBlur}
    />
  );
}

export { InvitationInputFields as InvitationInputFieldsContainer };
export default injectIntl(InvitationInputFields);
