import React from 'react';
import PropTypes from 'prop-types';

import SwitchComponent from 'views/shared/Switch';

class SwitchField extends React.Component {
  static propTypes = {
    checkedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    uncheckedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    checkedValue: true,
    uncheckedValue: false,
  }

  isChecked = value => value === this.props.checkedValue;

  handleChange = () => {
    const { field, checkedValue, uncheckedValue, form: { setFieldValue } } = this.props;
    const value = field.value === checkedValue ? uncheckedValue : checkedValue;
    setFieldValue(field.name, value);
  }

  render() {
    const { field: { value } } = this.props;

    return (
      <SwitchComponent
        {...this.props}
        isChecked={this.isChecked(value)}
        onChange={this.handleChange}
      />
    );
  }
}

export default SwitchField;
