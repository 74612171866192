import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as modalActions from 'state/modal/actions';

import ConfirmModal from 'views/shared/ConfirmModal';

class DiscardModal extends React.Component {
  static propTypes = {
    onReset: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  handleSubmitDiscard = () => {
    const { onReset, hideModal } = this.props;

    onReset();
    hideModal();
  }

  render() {
    return (
      <ConfirmModal
        {...this.props}
        submitClassName="main-btn--danger"
        title={{ id: 'shared.discardChanges' }}
        bodyText={[{ id: 'shared.cancelText' }]}
        dismissText={{ id: 'shared.cancel' }}
        submitText={{ id: 'shared.discard' }}
        onSubmit={this.handleSubmitDiscard}
      />
    );
  }
}

const mapDispatchToProps = {
  ...modalActions,
};

export { DiscardModal as DiscardModalContainer };
export default connect(null, mapDispatchToProps)(DiscardModal);
