import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchFreeVisits = () => ({
  type: types.FETCH_FREE_VISITS,
});

export const setFreeVisitsIds = freeVisitsIds => ({
  type: types.SET_FREE_VISITS_IDS,
  freeVisitsIds,
});

export const setFreeVisitsBlankState = isBlankState => ({
  type: types.SET_FREE_VISITS_BLANK_STATE,
  isBlankState,
});

export const setFreeVisitsCurrentPage = pageNumber => ({
  type: types.SET_FREE_VISITS_PAGE,
  pageNumber,
});

export const setFreeVisitsSortOrder = sortKey => ({
  type: types.SET_FREE_VISITS_SORT_ORDER,
  sortKey,
});

export const filterFreeVisits = filters => ({
  type: types.SET_FREE_VISITS_FILTER,
  filters,
});

export const fetchClinicsList = lastId => ({
  type: types.FETCH_FREE_VISITS_CLINICS,
  lastId,
});

export const filterClinicsList = filters => ({
  type: types.SET_FREE_VISITS_CLINICS_LIST_FILTERS,
  filters,
});

export const setClinicsListIds = clinicsListIds => ({
  type: types.SET_FREE_VISITS_CLINICS_LIST_IDS,
  clinicsListIds,
});

export const setClinicsListFilterParams = filterParams => ({
  type: types.SET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetClinicsListIds = () => ({
  type: types.RESET_FREE_VISITS_CLINICS_LIST_IDS,
});

export const resetClinicsListFilterParams = () => ({
  type: types.RESET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS,
});

export const fetchPatientsList = lastId => ({
  type: types.FETCH_FREE_VISITS_PATIENTS,
  lastId,
});

export const filterPatientsList = filters => ({
  type: types.SET_FREE_VISITS_PATIENTS_LIST_FILTERS,
  filters,
});

export const setPatientsListIds = patientsListIds => ({
  type: types.SET_FREE_VISITS_PATIENTS_LIST_IDS,
  patientsListIds,
});

export const setPatientsListFilterParams = filterParams => ({
  type: types.SET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetPatientsListIds = () => ({
  type: types.RESET_FREE_VISITS_PATIENTS_LIST_IDS,
});

export const resetPatientsListFilterParams = () => ({
  type: types.RESET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS,
});

export const submitCreateFreeVisits = makeFormSubmitAction(types.SUBMIT_CREATE_FREE_VISITS);
export const submitUpdateFreeVisits = makeFormSubmitAction(types.SUBMIT_UPDATE_FREE_VISITS);
