import React from 'react';

import ServerErrorComponent from './component';

class ServerErrorContainer extends React.Component {
  static pageTitle = { id: 'serverErrorPage.title' };

  render = () => (
    <ServerErrorComponent {...this.props} />
  )
}

export default ServerErrorContainer;
