const namespace = 'chat';

export const CREATE_MESSAGE = `${namespace}/CREATE_MESSAGE`;
export const UPDATE_MESSAGE = `${namespace}/UPDATE_MESSAGE`;
export const SET_EDITING_MESSAGE = `${namespace}/SET_EDITING_MESSAGE`;
export const RESET_EDITING_MESSAGE = `${namespace}/RESET_EDITING_MESSAGE`;
export const REMOVE_MESSAGE = `${namespace}/REMOVE_MESSAGE`;
export const REMOVE_MESSAGE_SUCCESS = `${namespace}/REMOVE_MESSAGE_SUCCESS`;
export const RECEIVE_MESSAGES = `${namespace}/RECEIVE_MESSAGES`;
export const SET_MESSAGES = `${namespace}/SET_MESSAGES`;
export const FLUSH_MESSAGES = `${namespace}/FLUSH_MESSAGES`;
export const FETCH_MESSAGES = `${namespace}/FETCH_MESSAGES`;
export const MARK_MESSAGES_AS_READ = `${namespace}/MARK_MESSAGES_AS_READ`;
export const MARK_ALL_MESSAGES_AS_READ = `${namespace}/MARK_ALL_MESSAGES_AS_READ`;
export const FETCH_ATTACHMENTS = `${namespace}/FETCH_ATTACHMENTS`;
export const SET_ATTACHMENTS = `${namespace}/SET_ATTACHMENTS`;
export const REMOVE_ATTACHMENT = `${namespace}/REMOVE_ATTACHMENT`;
export const REMOVE_ATTACHMENT_SUCCESS = `${namespace}/REMOVE_ATTACHMENT_SUCCESS`;
export const RECEIVE_USERS_STATUSES = `${namespace}/RECEIVE_USERS_STATUSES`;
export const SET_ONLINE = `${namespace}/SET_ONLINE`;
export const SET_OFFLINE = `${namespace}/SET_OFFLINE`;
export const CLOSE_UNREAD_INDICATOR = `${namespace}/CLOSE_UNREAD_INDICATOR`;
export const SET_UNREAD_INDICATOR_VISIBILITY = `${namespace}/SET_UNREAD_INDICATOR_VISIBILITY`;
export const UPLOAD_FILE = `${namespace}/UPLOAD_FILE`;
export const CANCEL_UPLOAD_FILE = `${namespace}/CANCEL_UPLOAD_FILE`;
export const SET_UPLOAD_PROGRESS = `${namespace}/SET_UPLOAD_PROGRESS`;
export const SET_CHAT = `${namespace}/SET_CHAT`;
export const DESTROY_ATTACHMENTS = `${namespace}/DESTROY_ATTACHMENTS`;
