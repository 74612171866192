import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Modal from 'views/shared/Modal';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import InputField from 'views/shared/InputField';
import TextareaField from 'views/shared/TextareaField';
import CurrencyField from 'views/shared/CurrencyField';
import SvgIcon from 'views/shared/SvgIcon';
import Button from 'views/shared/Button';

const GenerateVouchersModalComponent = ({
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="vouchers.generateVouchers" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="d-flex">
        <Field
          name="number"
          component={InputField}
          className="mb-16 mr-16 w-192"
          id="number"
          type="number"
          label={{ id: 'label.numberOfVouchers' }}
          icon="alert"
        />
        <Field
          name="value"
          component={CurrencyField}
          className="mb-16 w-160"
          id="value"
          label={{ id: 'label.value' }}
          placeholder={{ id: 'placeholder.pricePerSession' }}
          icon="alert"
          min={0}
        />
      </div>
      <Field
        name="name"
        component={InputField}
        className="mb-16"
        id="name"
        label={{ id: 'label.batchName' }}
        icon="alert"
        fullWidth
      />
      <Field
        name="comment"
        component={TextareaField}
        id="comment"
        label={{ id: 'label.commentAddress' }}
        icon="alert"
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.generate' }}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

GenerateVouchersModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default GenerateVouchersModalComponent;
