import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { setUserInvitationId } from '../actions';
import { validateInvitationTokenEndpoint } from '../endpoints';
import { VALIDATE_INVITATION_TOKEN } from '../types';

const validateInvitationTokenOperation = createLogic({
  type: VALIDATE_INVITATION_TOKEN,
  latest: true,

  async process({ httpClient, action: { token } }, dispatch, done) {
    const { endpoint, url } = validateInvitationTokenEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));
      const { data } = await httpClient.get(url, { params: { token, include: 'clinic' } });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setUserInvitationId(data.data.id));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default validateInvitationTokenOperation;
