import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setCompleteCancelDiseasesIds } from '../actions';
import { getCompleteCancelDiseasesEndpoint } from '../endpoints';
import { GET_COMPLETE_CANCEL_DISEASES } from '../types';

const getCompleteCancelDiseasesOperation = createLogic({
  type: GET_COMPLETE_CANCEL_DISEASES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getCompleteCancelDiseasesEndpoint;

    const params = {
      include: 'diseases',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setCompleteCancelDiseasesIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getCompleteCancelDiseasesOperation;
