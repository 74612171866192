import endpoint from 'utils/endpoint';
import {
  usersJoinRequestApproveRoute,
  usersJoinRequestRejectRoute,
  usersJoinRequestRoute,
  usersJoinRequestsRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchJoinRequestsEndpoint = endpoint(types.FETCH_JOIN_REQUESTS, 'GET', usersJoinRequestsRoute);
export const acceptJoinRequestEndpoint = id => endpoint(types.ACCEPT_JOIN_REQUEST, 'PUT', usersJoinRequestApproveRoute(id));
export const rejectJoinRequestEndpoint = id => endpoint(types.REJECT_JOIN_REQUEST, 'PUT', usersJoinRequestRejectRoute(id));
export const removeJoinRequestEndpoint = id => endpoint(types.REMOVE_JOIN_REQUEST, 'DELETE', usersJoinRequestRoute(id));
