const namespace = 'commissions';

export const FETCH_COMMISSIONS = `${namespace}/FETCH_COMMISSIONS`;
export const FETCH_DOCTORS_COMMISSIONS = `${namespace}/FETCH_DOCTORS_COMMISSIONS`;
export const FETCH_VIRTUAL_CLINICS_COMMISSIONS = `${namespace}/FETCH_VIRTUAL_CLINICS_COMMISSIONS`;
export const RESET_COMMISSION_IDS = `${namespace}/RESET_COMMISSION_IDS`;
export const SET_COMMISSION_IDS = `${namespace}/SET_COMMISSION_IDS`;
export const SET_COMMISSIONS_BLANK_STATE = `${namespace}/SET_COMMISSIONS_BLANK_STATE`;
export const SET_COMMISSIONS_CURRENT_PAGE = `${namespace}/SET_COMMISSIONS_CURRENT_PAGE`;
export const SET_COMMISSIONS_FILTER = `${namespace}/SET_COMMISSIONS_FILTER`;
export const SET_COMMISSIONS_SORT = `${namespace}/SET_COMMISSIONS_SORT`;
export const SET_COMMISSIONS_INITIAL_STATE = `${namespace}/SET_COMMISSIONS_INITIAL_STATE`;
