import React from 'react';

import ClientErrorComponent from './component';

class ClientErrorContainer extends React.Component {
  static pageTitle = { id: 'clientErrorPage.title' };

  render = () => (
    <ClientErrorComponent
      {...this.props}
    />
  );
}

export default ClientErrorContainer;
