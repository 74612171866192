import { includes } from 'ramda';

import { USER_ROLE } from 'constants/users';

export const isPlatformOwner = (roleName) => roleName === USER_ROLE.owner;
export const isIndependentDoctor = (roleName) => roleName === USER_ROLE.doctor;
export const isVirtualClinicAdmin = (roleName) => roleName === USER_ROLE.virtualClinicAdmin;
export const isVirtualClinicDoctor = (roleName) => roleName === USER_ROLE.virtualClinicDoctor;

export const isPlatformOwnerOrAdmin = (roleName) => (
  includes(roleName, [
    USER_ROLE.owner,
    USER_ROLE.virtualClinicAdmin,
  ])
);

export const isDoctor = (roleName) => (
  includes(roleName, [
    USER_ROLE.doctor,
    USER_ROLE.virtualClinicDoctor,
  ])
);

export const isUser = (roleName) => (
  includes(roleName, [
    USER_ROLE.doctor,
    USER_ROLE.virtualClinicDoctor,
    USER_ROLE.virtualClinicAdmin,
  ])
);
