export const APPOINTMENTS_COLORS = {
  'gray-200': 'color-booking-grey-200',
  'green-1-200': 'color-booking-green-1-200',
  'green-2-200': 'color-booking-green-2-200',
  'green-3-200': 'color-booking-green-3-200',
  'green-4-200': 'color-booking-green-4-200',
  'yellow-1-200': 'color-booking-yellow-1-200',
  'yellow-2-200': 'color-booking-yellow-2-200',
  'orange-1-200': 'color-booking-orange-1-200',
  'orange-2-200': 'color-booking-orange-2-200',
  'blue-1-200': 'color-booking-blue-1-200',
  'blue-2-200': 'color-booking-blue-2-200',
  'blue-3-200': 'color-booking-blue-3-200',
  'blue-4-200': 'color-booking-blue-4-200',
  'blue-5-200': 'color-booking-blue-5-200',
  'fuchsia-1-200': 'color-booking-fuchsia-1-200',
  'fuchsia-2-200': 'color-booking-fuchsia-2-200',
  'red-1-200': 'color-booking-red-1-200',
  'red-2-200': 'color-booking-red-2-200',
  'violet-1-200': 'color-booking-violet-1-200',
  'violet-2-200': 'color-booking-violet-2-200',
};

export const AVATARS_COLORS = {
  'orange-1': 'color-avatars-orange-1',
  'orange-2': 'color-avatars-orange-2',
  'green-1': 'color-avatars-green-1',
  'green-3': 'color-avatars-green-3',
  'green-4': 'color-avatars-green-4',
  'blue-2': 'color-avatars-blue-2',
  'blue-3': 'color-avatars-blue-3',
  'blue-4': 'color-avatars-blue-4',
  'blue-5': 'color-avatars-blue-5',
  'fuchsia-2': 'color-avatars-fuchsia-2',
  'violet-1': 'color-avatars-violet-1',
  'violet-2': 'color-avatars-violet-2',
};

export const APPOINTMENTS_COLORS_MATRIX = {
   a: APPOINTMENTS_COLORS['gray-200'],
   b: APPOINTMENTS_COLORS['green-1-200'],
   c: APPOINTMENTS_COLORS['green-2-200'],
   d: APPOINTMENTS_COLORS['green-3-200'],
   e: APPOINTMENTS_COLORS['green-4-200'],
   f: APPOINTMENTS_COLORS['yellow-1-200'],
   g: APPOINTMENTS_COLORS['yellow-2-200'],
   h: APPOINTMENTS_COLORS['orange-1-200'],
   i: APPOINTMENTS_COLORS['orange-2-200'],
   j: APPOINTMENTS_COLORS['blue-1-200'],
   k: APPOINTMENTS_COLORS['blue-2-200'],
   l: APPOINTMENTS_COLORS['blue-3-200'],
   m: APPOINTMENTS_COLORS['blue-4-200'],
   n: APPOINTMENTS_COLORS['blue-5-200'],
   ñ: APPOINTMENTS_COLORS['fuchsia-1-200'],
   o: APPOINTMENTS_COLORS['fuchsia-2-200'],
   p: APPOINTMENTS_COLORS['red-1-200'],
   q: APPOINTMENTS_COLORS['red-2-200'],
   r: APPOINTMENTS_COLORS['violet-1-200'],
   s: APPOINTMENTS_COLORS['violet-1-200'],
   t: APPOINTMENTS_COLORS['green-1-200'],
   u: APPOINTMENTS_COLORS['green-2-200'],
   v: APPOINTMENTS_COLORS['green-3-200'],
   w: APPOINTMENTS_COLORS['green-4-200'],
   x: APPOINTMENTS_COLORS['yellow-1-200'],
   y: APPOINTMENTS_COLORS['yellow-2-200'],
   z: APPOINTMENTS_COLORS['orange-1-200'],
};

export const AVATARS_COLORS_MATRIX = {
  a: AVATARS_COLORS['orange-1'],
  b: AVATARS_COLORS['orange-2'],
  c: AVATARS_COLORS['green-1'],
  d: AVATARS_COLORS['green-3'],
  e: AVATARS_COLORS['green-4'],
  f: AVATARS_COLORS['blue-2'],
  g: AVATARS_COLORS['blue-3'],
  h: AVATARS_COLORS['blue-4'],
  i: AVATARS_COLORS['blue-5'],
  j: AVATARS_COLORS['fuchsia-2'],
  k: AVATARS_COLORS['violet-1'],
  l: AVATARS_COLORS['violet-2'],
  m: AVATARS_COLORS['orange-1'],
  n: AVATARS_COLORS['orange-2'],
  ñ: AVATARS_COLORS['green-1'],
  o: AVATARS_COLORS['green-3'],
  p: AVATARS_COLORS['green-4'],
  q: AVATARS_COLORS['blue-2'],
  r: AVATARS_COLORS['blue-3'],
  s: AVATARS_COLORS['blue-4'],
  t: AVATARS_COLORS['blue-5'],
  u: AVATARS_COLORS['fuchsia-2'],
  v: AVATARS_COLORS['violet-1'],
  w: AVATARS_COLORS['violet-2'],
  x: AVATARS_COLORS['orange-1'],
  y: AVATARS_COLORS['orange-2'],
  z: AVATARS_COLORS['green-1'],
};
