import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { FormattedMessage } from 'react-intl';

import { allowedImageTypes } from 'constants/file';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import UploadInput from 'views/shared/UploadInput';
import SvgIcon from 'views/shared/SvgIcon';

const UploadImageCropModal = ({
  src,
  crop,
  onClose,
  onImageSave,
  onCropChange,
  onSelectImage,
  onImageLoaded,
  onCropComplete,
  isCircularCrop,
}) => (
  <Modal wrapClassName="ant-modal-wrap ant-modal-wrap__6" onClose={onClose}>
    <div className="ant-modal__header">
      <h2 className="ant-modal__header-txt">
        <FormattedMessage id="uploadImage.editImage" />
      </h2>
      <button
        type="button"
        onClick={onClose}
      >
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body ant-modal-body--crop">
      <div className="main-img-crop-field">
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
            circularCrop={isCircularCrop}
          />
        )}
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--between">
      <div className="main-modal__footer-extra-action">
        <UploadInput
          id="avatar"
          className="d-none"
          name="avatar"
          accept={allowedImageTypes}
          onChange={onSelectImage}
        />
        <Button className="w-136" htmlFor="avatar" text={{ id: 'uploadImage.changeImage' }} kind="secondary" isLabel />
      </div>
      <div>
        <Button
          extraClasses="main-modal__footer-action"
          text={{ id: 'shared.cancel' }}
          kind="tertiary"
          onClick={onClose}
        />
        <Button
          className="w-136"
          type="submit"
          text={{ id: 'shared.save' }}
          onClick={onImageSave}
        />
      </div>
    </div>
  </Modal>
);

UploadImageCropModal.defaultProps = {
  src: null,
  isCircularCrop: false,
};

UploadImageCropModal.propTypes = {
  src: PropTypes.string,
  isCircularCrop: PropTypes.bool,
  crop: PropTypes.shape().isRequired,
  onImageSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
};

export default UploadImageCropModal;
