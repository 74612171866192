import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { symptomDetailsIdSelector } from '../selectors';
import { removeSymptomImageEndpoint } from '../endpoints';
import { REMOVE_SYMPTOM_IMAGE } from '../types';

const removeSymptomImageOperation = createLogic({
  type: REMOVE_SYMPTOM_IMAGE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const id = symptomDetailsIdSelector(state);
    const { url, endpoint } = removeSymptomImageEndpoint(id);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(showNotification({
        messageObject: { id: 'notifications.yourSymptomImageRemoved' },
      }));

      const response = updateDataHelper(
        state.data,
        'symptom', id,
        {
          attributes: {
            imageUrls: null,
          },
        },
      );

      dispatch(dataApiSuccess({ response }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.yourSymptomImageRemovedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeSymptomImageOperation;
