import { createLogic } from 'redux-logic';
import { dec } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchJoinRequests, setCurrentPage } from '../actions';
import { joinRequestsTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeJoinRequestEndpoint } from '../endpoints';
import { REMOVE_JOIN_REQUEST } from '../types';

const removeJoinRequestOperation = createLogic({
  type: REMOVE_JOIN_REQUEST,
  latest: true,

  async process({ httpClient, getState, action: { id, roleName } }, dispatch, done) {
    const { url, endpoint } = removeJoinRequestEndpoint(id);
    const state = getState();
    const totalCount = joinRequestsTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, 1);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.joinRequestRemoved',
        },
      }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchJoinRequests(roleName));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeJoinRequestOperation;
