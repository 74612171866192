import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';

import { UPDATE_NOTIFICATION_SETTING } from '../types';
import { updateAccountNotificationSetting } from '../endpoints';

const updateNotificationSetting = createLogic({
  type: UPDATE_NOTIFICATION_SETTING,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const { values: { isNotificationOn, isWhatsAppOn, notificationSettingId }, form } = action;
    const { endpoint, url } = updateAccountNotificationSetting;

    const params = {
      whatsapp_appointment: isWhatsAppOn,
      whatsapp_reminder: isWhatsAppOn,
      email_appointment: isNotificationOn,
      email_communication: isNotificationOn,
      email_reminder: isNotificationOn,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);
      const response = updateDataHelper(
        getState().data,
        'notificationSetting',
        notificationSettingId, {
          attributes: {
            whatsappAppointment: isWhatsAppOn,
            whatsappReminder: isWhatsAppOn,
            emailAppointment: isNotificationOn,
            emailCommunication: isNotificationOn,
            emailReminder: isNotificationOn,
        },
       },
      );
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({ messageObject: { id: 'notifications.preferencesUpdated' } }));
    } catch (error) {
      dispatch(showNotification({ kind: 'error', messageObject: { id: 'notifications.preferencesError' } }));
      form.resetForm();
      requestErrorHandler({ error, dispatch, endpoint, form });
    }
    done();
  },
});

export default updateNotificationSetting;
