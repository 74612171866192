import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { resetAuthenticationVirtualClinicEndpoint } from '../endpoints';
import { RESET_AUTHENTICATION_VIRTUAL_CLINIC } from '../types';

const resetAuthenticationOperation = createLogic({
  type: RESET_AUTHENTICATION_VIRTUAL_CLINIC,
  latest: true,

  async process({ httpClient, action: { virtualClinicId } }, dispatch, done) {
    const { endpoint, url } = resetAuthenticationVirtualClinicEndpoint(virtualClinicId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.virtualClinicResetAuthentication' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.virtualClinicResetAuthenticationError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default resetAuthenticationOperation;
