import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchPushNotifications = () => ({
  type: types.FETCH_PUSH_NOTIFICATIONS,
});

export const setPushNotificationsIds = pushNotificationsIds => ({
  type: types.SET_PUSH_NOTIFICATIONS_IDS,
  pushNotificationsIds,
});

export const setBlankState = isBlankState => ({
  type: types.SET_PUSH_NOTIFICATIONS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_PUSH_NOTIFICATIONS_CURRENT_PAGE,
  pageNumber,
});

export const fetchClinicsList = lastId => ({
  type: types.FETCH_PUSH_NOTIFICATION_CLINICS,
  lastId,
});

export const filterClinicsList = filters => ({
  type: types.SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTERS,
  filters,
});

export const setClinicsListIds = clinicsListIds => ({
  type: types.SET_PUSH_NOTIFICATION_CLINICS_IDS,
  clinicsListIds,
});

export const setClinicsListFilterParams = filterParams => ({
  type: types.SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetClinicsListIds = () => ({
  type: types.RESET_PUSH_NOTIFICATION_CLINICS_LIST_IDS,
});

export const resetClinicsListFilterParams = () => ({
  type: types.RESET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS,
});

export const fetchPatientsList = lastId => ({
  type: types.FETCH_PUSH_NOTIFICATION_PATIENTS,
  lastId,
});

export const filterPatientsList = filters => ({
  type: types.SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTERS,
  filters,
});

export const setPatientsListIds = patientsListIds => ({
  type: types.SET_PUSH_NOTIFICATION_PATIENTS_IDS,
  patientsListIds,
});

export const setPatientsListFilterParams = filterParams => ({
  type: types.SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetPatientsListIds = () => ({
  type: types.RESET_PUSH_NOTIFICATION_PATIENTS_LIST_IDS,
});

export const resetPatientsListFilterParams = () => ({
  type: types.RESET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS,
});

export const validatePushNotifications = values => ({
  type: types.VALIDATE_PUSH_NOTIFICATIONS,
  values,
});

export const submitCreatePushNotification = makeFormSubmitAction(
  types.SUBMIT_CREATE_PUSH_NOTIFICATION,
);

export const toggleConfirmModalVisible = () => ({
  type: types.TOGGLE_PUSH_NOTIFICATION_CONFIRM_MODAL_VISIBLE,
});
