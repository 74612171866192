import Video from 'twilio-video';
import {
  map, compose, filter, propEq, equals,
} from 'ramda';

import { TRACK_KIND, VIDEO_CONSTRAINTS } from 'constants/videoConference';
import isPresent from 'utils/isPresent';
import getMediaDevices from 'lib/webrtcClient/getMediaDevices';

const DEFAULT_SPEAKER_DEVICE = { key: 'default', label: { id: 'videoConference.settingsModal.defaultSpeaker' }, value: 'default' };

const buildDevicesOptions = map(({ label, deviceId }) => ({
  key: deviceId,
  label: label || deviceId,
  value: deviceId,
}));

const getAudioDevices = compose(buildDevicesOptions, filter(propEq('kind', 'audioinput')));
const getVideoDevices = compose(buildDevicesOptions, filter(propEq('kind', 'videoinput')));
const getSpeakerDevices = compose(buildDevicesOptions, filter(propEq('kind', 'audiooutput')));

export const getDevices = async () => {
  const devices = await getMediaDevices();
  const speakerDevices = getSpeakerDevices(devices);

  return {
    audioDevices: getAudioDevices(devices),
    videoDevices: getVideoDevices(devices),
    // Needed for browsers where the speaker selection not supported
    speakerDevices: isPresent(speakerDevices) ? speakerDevices : [DEFAULT_SPEAKER_DEVICE],
  };
};

export const trackPubsToTracks = trackMap => Array.from(trackMap.values())
  .map(publication => publication.track)
  .filter(track => track !== null);

export const detachTrack = tracks => {
  const track = tracks[0];
  if (track && track.kind !== TRACK_KIND.data) {
    track.detach();
  }
};

export const attachTrack = (tracks, mediaElement) => {
  const track = tracks[0];
  if (track && track.kind !== TRACK_KIND.data) {
    track.attach(mediaElement);
  }
};

export const stopTracks = tracks => {
  tracks.forEach(publication => {
    publication.track.stop();
    publication.unpublish();
  });
};

export const updateTracks = (participant, unpublishedTracksKind, newTrack) => {
  const selectedTracks = equals(TRACK_KIND.video, unpublishedTracksKind)
    ? participant.videoTracks
    : participant.audioTracks;

  stopTracks(selectedTracks);

  participant.publishTrack(newTrack);
};

export const updateVideoDevice = async (participant, videoDevice) => {
  const localTrack = await Video.createLocalVideoTrack({ ...videoDevice, ...VIDEO_CONSTRAINTS });

  updateTracks(participant, TRACK_KIND.video, localTrack);
};

export const updateAudioDevice = async (participant, audioDevice) => {
  const localTrack = await Video.createLocalAudioTrack(audioDevice);

  updateTracks(participant, TRACK_KIND.audio, localTrack);
};
