import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage';
import SvgIcon from 'views/shared/SvgIcon';

const InputMessage = ({
  icon, message,
}) => (
  <div className="input-text__message">
    {
      icon && <SvgIcon icon={icon} className="input-text__message-icon" />
    }
    <span className={classNames({
          'input-text__message-txt': icon !== 'tick',
          'input-text__message-txt--success': icon === 'tick',
        })}
    >
      <FormattedOrRawMessage message={message} />
    </span>
  </div>
  );

InputMessage.defaultProps = {
  icon: null,
};

InputMessage.propTypes = {
  icon: PropTypes.oneOf(['close', 'alert', 'info', 'tick', null]),
  message: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
    PropTypes.string,
  ]).isRequired,
};

export default InputMessage;
