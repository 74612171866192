import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  includes, pluck, flatten, compose, path, trim, isEmpty,
 } from 'ramda';

import {
  fetchClinicSpecialitiesList as fetchClinicSpecialitiesListAction,
  filterClinicSpecialitiesList as filterClinicSpecialitiesListAction,
  resetClinicSpecialitiesListIds as resetClinicSpecialitiesListIdsAction,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import {
  clinicSpecialitiesListSelector,
  clinicSpecialitiesListMetaSelector,
  clinicSpecialitiesListLoadingSelector,
} from 'state/concepts/userProfile/clinicSpecialities/selectors';

import DropdownClinicSpecialitiesFieldComponent from './component';

class DropdownClinicSpecialitiesField extends React.Component {
  static propTypes = {
    fetchClinicSpecialitiesList: PropTypes.func.isRequired,
    filterClinicSpecialitiesList: PropTypes.func.isRequired,
    resetClinicSpecialitiesListIds: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
  }

  static defaultProps = {
    options: [],
  }

  state = {
    isOpen: false,
    searchQuery: '',
  };

  get selectedClinicSpecialities() {
    return flatten(pluck('clinicSpecialityIds', path(['form', 'values', 'clinicSpecialities'], this.props)));
  }

  get isAllClinicSpecialitiesSelected() {
    const { options } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => this.handleCheckSelectedOption(id));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterClinicSpecialitiesList, resetClinicSpecialitiesListIds } = this.props;

    this.setState({ searchQuery });
    resetClinicSpecialitiesListIds();
    filterClinicSpecialitiesList({
      name: trim(searchQuery),
      excludeClinicSpecialities: this.selectedClinicSpecialities,
    });
  }, 250)

  handleFocus = () => {
    const { filterClinicSpecialitiesList } = this.props;

    this.setState({ searchQuery: '' });
    filterClinicSpecialitiesList({
      name: '',
      excludeClinicSpecialities: this.selectedClinicSpecialities,
    });
  }

  handleCheckSelectedOption = key => includes(key, this.selectedClinicSpecialities);

  handleDropdownVisibleChange = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render = () => (
    <DropdownClinicSpecialitiesFieldComponent
      {...this.props}
      {...this.state}
      isAllClinicSpecialitiesSelected={this.isAllClinicSpecialitiesSelected}
      onDropdownVisibleChange={this.handleDropdownVisibleChange}
      onCheckSelectedOption={this.handleCheckSelectedOption}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

const mapStateToProps = state => ({
  meta: clinicSpecialitiesListMetaSelector(state),
  isLoading: clinicSpecialitiesListLoadingSelector(state),
  options: clinicSpecialitiesListSelector(state),
});

const mapDispatchToProps = {
  fetchClinicSpecialitiesList: fetchClinicSpecialitiesListAction,
  filterClinicSpecialitiesList: filterClinicSpecialitiesListAction,
  resetClinicSpecialitiesListIds: resetClinicSpecialitiesListIdsAction,
};

export { DropdownClinicSpecialitiesField as DropdownClinicSpecialitiesFieldContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DropdownClinicSpecialitiesField);
