import * as types from './types';

export const setRecoveryCodeIds = ids => ({
  type: types.SET_RECOVERY_CODE_IDS,
  ids,
});

export const setIsRecoveryCodesSaved = isSaved => ({
  type: types.SET_RECOVERY_CODES_SAVED,
  isSaved,
});

export const setProvisioningUri = uri => ({
  type: types.SET_PROVISIONING_URI,
  uri,
});

export const fetchAccountRecoveryCodes = () => ({
  type: types.FETCH_ACCOUNT_RECOVERY_CODES,
});

export const fetchRecoveryCodes = () => ({
  type: types.FETCH_RECOVERY_CODES,
});

export const downloadAccountRecoveryCodes = () => ({
  type: types.DOWNLOAD_ACCOUNT_RECOVERY_CODES,
});

export const downloadRecoveryCodes = () => ({
  type: types.DOWNLOAD_RECOVERY_CODES,
});

