import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { activateSpecialities } from 'state/concepts/userProfile/specialities/actions';
import { activateSpecialitiesLoadingSelector } from 'state/concepts/userProfile/specialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class SpecialitiesActivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    speciality: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, speciality: { id } } = this.props;

    onSubmit([id]);
  }

  render() {
    const { speciality } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'specialities.activateModal.title' }}
        bodyText={[{
          id: 'specialities.activateModal.body',
          values: { name: <span className="f-700">{speciality.name}</span> },
        }]}
        submitText={{ id: 'shared.activate' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: activateSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: activateSpecialities,
};

export { SpecialitiesActivateModal as SpecialitiesActivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SpecialitiesActivateModal);
