import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import isPresent from 'utils/isPresent';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { setSymptomSpecialityIds } from '../actions';
import { symptomDetailsIdSelector, symptomSpecialityIdsSelector } from '../selectors';
import { submitNewSymptomEndpoint } from '../endpoints';
import { SUBMIT_NEW_SYMPTOM } from '../types';

const submitNewSymptomOperation = createLogic({
  type: SUBMIT_NEW_SYMPTOM,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const params = {
      speciality_ids: values.specialityIds,
    };
    const state = getState();
    const symptomId = symptomDetailsIdSelector(state);
    const specialityIds = symptomSpecialityIdsSelector(state);

    const { url, endpoint } = submitNewSymptomEndpoint(symptomId);

    try {
      await httpClient.put(url, params);

      dispatch(dataApiSuccess({ endpoint }));

      if (isPresent(specialityIds)) {
        dispatch(setSymptomSpecialityIds(values.specialityIds));
        dispatch(showNotification({ messageObject: { id: 'notifications.specialitiesUpdated' } }));
      } else {
        await redirect({ href: '/symptoms' });
        dispatch(showNotification({ messageObject: { id: 'notifications.addedNewSymptom' } }));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default submitNewSymptomOperation;
