import PropTypes from 'prop-types';

import { IMAGES } from 'constants/platform';

import getLangImage from 'utils/getLangImage';

const ErrorLayout = ({
  children,
  language,
  intl,
}) => (
  <div className="account-layout">
    <header className="main-header">
      <a className="main-header__logo" href="/">
        <picture className="landing-header__logo-img">
          <source media="(max-width: 1023.98px)" srcSet={getLangImage(language, `logo/${language}`, IMAGES.logoMobile)} />
          <source srcSet={getLangImage(language, `logo/${language}`, IMAGES.logo)} />
          <img src={getLangImage(language, `logo/${language}`, IMAGES.logo)} alt={intl.formatMessage({ id: 'shared.alt.logo' })} />
        </picture>
      </a>
    </header>
    <div className="account-layout__content">
      <div className="account-layout__main">
        <div className="error-page">
          <div className="container">
            { children }
          </div>
        </div>
      </div>
    </div>
  </div>
);

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
};

export default ErrorLayout;
