import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showModal as showModalAction } from 'state/modal/actions';

import PatientAppointmentModalComponent from './component';

class PatientAppointmentModal extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
    appointment: PropTypes.shape().isRequired,
  }

  handleReschedule = () => {
    const { showModal, patientId, appointment } = this.props;

    showModal({
      modalType: 'RESCHEDULE_APPOINTMENT_MODAL',
      modalProps: {
        patientId,
        appointment,
      },
    });
  };

  handleCancel = () => {
    const { showModal, patientId, appointment } = this.props;

    showModal({
      modalType: 'CANCEL_APPOINTMENT_MODAL',
      modalProps: {
        patientId,
        appointmentId: appointment.id,
      },
    });
  };

  render() {
    return (
      <PatientAppointmentModalComponent
        {...this.props}
        onReschedule={this.handleReschedule}
        onCancel={this.handleCancel}
      />
    );
  }
}

const mapDispatchToProps = {
  showModal: showModalAction,
};

export { PatientAppointmentModal as PatientAppointmentModalContainer };
export default connect(null, mapDispatchToProps)(PatientAppointmentModal);
