import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { prop } from 'ramda';

import isPresent from 'utils/isPresent';
import profileName from 'utils/profileName';
import formatCurrency from 'utils/formatCurrency';

import Modal from 'views/shared/Modal';
import Avatar from 'views/shared/Avatar';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

import Loader from './Loader';

const DoctorRevenueModalComponent = ({
  onClose,
  doctors,
  onDownloadCsv,
}) => (
  <Modal className="ant-modal-wrap__10" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="revenue.doctorRevenue" />
      </h1>
      <div>
        <Button
          className="mr-20"
          text={{ id: 'shared.downloadCsv' }}
          onClick={onDownloadCsv}
          kind="empty"
        />
        <button onClick={onClose}>
          <SvgIcon icon="cross" className="ant-modal__close" />
        </button>
      </div>
    </div>
    <div className="ant-modal__body ant-modal__body--with-list">
      <table className="table-search__table table-search__table--revenue-modal">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="revenue.doctor" />
            </th>
            <th>
              <FormattedMessage id="revenue.doctorRevenue" />
            </th>
          </tr>
        </thead>
        <tbody>
          {isPresent(doctors) && doctors.map(doctor => (
            <tr key={prop('id', doctor)}>
              <td>
                <div className="cell-with-avatar">
                  <Avatar user={doctor} />
                  <div className="cell-with-avatar__content">
                    <p className="cell-with-avatar__name">{profileName(doctor)}</p>
                    <p className="cell-with-avatar__caption">{prop('email', doctor)}</p>
                  </div>
                </div>
              </td>
              <td>{formatCurrency(prop('doctorRevenue', doctor))}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Loader />
    </div>
  </Modal>
);

DoctorRevenueModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default DoctorRevenueModalComponent;
