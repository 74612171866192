import PropTypes from 'prop-types';

import { isUser } from 'utils/roles';

import Transaction from './Transaction';

const TransactionsComponent = ({
  onClose,
  event: {
    transactions,
    patientProfile,
    freeVisit,
  },
  currentUser,
  showFreeVisitRefundZero,
  showDefaultRefundZero,
  showTransactionsForOwner,
  showTransactionsForUser,
  showFreeVisit,
}) => (showTransactionsForOwner || showTransactionsForUser) && (
  <div className="modal-appointment__section d-flex">
    {showFreeVisit && (
      <Transaction
        patientProfile={patientProfile}
        freeVisit={freeVisit}
        onClose={onClose}
      />
    )}
    {transactions.map(({ id, amount, topupSource }) => (
      <div key={id}>
        {showTransactionsForUser && topupSource === 'refund' && (
          <Transaction
            onClose={onClose}
            amount={amount}
            topupSource={topupSource}
            patientProfile={patientProfile}
            freeVisit={freeVisit}
            smallView
          />
        )}
        {showTransactionsForOwner && (
          <Transaction
            onClose={onClose}
            amount={amount}
            topupSource={topupSource}
            patientProfile={patientProfile}
            freeVisit={freeVisit}
          />
        )}
      </div>
    ))}
    {(showFreeVisitRefundZero || showDefaultRefundZero) && (
      <Transaction
        topupSource="refund"
        patientProfile={patientProfile}
        smallView={isUser(currentUser.roleName)}
        onClose={onClose}
      />
    )}
  </div>
);

TransactionsComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  event: PropTypes.shape({
    transactions: PropTypes.arrayOf(PropTypes.shape()),
    patientProfile: PropTypes.shape(),
    freeVisit: PropTypes.bool,
  }).isRequired,
  currentUser: PropTypes.shape().isRequired,
  showFreeVisitRefundZero: PropTypes.bool.isRequired,
  showDefaultRefundZero: PropTypes.bool.isRequired,
  showTransactionsForOwner: PropTypes.bool.isRequired,
  showTransactionsForUser: PropTypes.bool.isRequired,
  showFreeVisit: PropTypes.bool.isRequired,
};

export default TransactionsComponent;
