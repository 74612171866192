import normalize from 'json-api-normalizer';
import { createLogic } from 'redux-logic';
import { isEmpty } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { submitPatientsAccountPagoparTopupEndpoint } from '../endpoints';
import { SUBMIT_PATIENT_ACCOUNT_PAGOPAR_TOPUP } from '../types';

const submitPatientAccountVoucherTopupOperation = createLogic({
  type: SUBMIT_PATIENT_ACCOUNT_PAGOPAR_TOPUP,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = submitPatientsAccountPagoparTopupEndpoint;
    const { amount, otherAmount } = values;

    const params = {
      amount: !isEmpty(otherAmount) ? otherAmount : amount,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      redirect({ href: data.meta.payment_page_link });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPatientAccountVoucherTopupOperation;
