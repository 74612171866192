/* eslint-disable import/prefer-default-export */
import { allowedCvFileTypes } from 'constants/file';
import { getFileSizeInMb } from 'utils/file';

const FILE_SIZE = 5;

export function isValidFileSize() {
  return this.test(
    'isValidFileSize',
    { id: 'shared.cvField.pleaseUploadValidSizeFile' },
    value => value && getFileSizeInMb(value.size) <= FILE_SIZE,
  );
}

export function isValidFileFormat() {
  return this.test(
    'isValidFileFormat',
    { id: 'shared.cvField.nofollowFormat' },
    value => value && allowedCvFileTypes.includes(value.type),
  );
}
