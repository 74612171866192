import endpoint from 'utils/endpoint';

import {
  patientsAccountProfileRoute,
  patientsAccountVoucherTopupRoute,
  patientsAccountPagoparTopupsPrepareRoute,
  patientsAccountConfirmationRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchPatientsAccountProfileEndpoint = endpoint(types.FETCH_PATIENT_ACCOUNT_PROFILE, 'GET', patientsAccountProfileRoute);
export const submitPatientsAccountVoucherTopupEndpoint = endpoint(types.SUBMIT_PATIENT_ACCOUNT_VOUCHER_TOPUP, 'POST', patientsAccountVoucherTopupRoute);
export const submitPatientsAccountPagoparTopupEndpoint = endpoint(types.SUBMIT_PATIENT_ACCOUNT_PAGOPAR_TOPUP, 'POST', patientsAccountPagoparTopupsPrepareRoute);
export const confirmEmailEndpoint = endpoint(types.CONFIRM_EMAIL, 'POST', patientsAccountConfirmationRoute);
