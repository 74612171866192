import fetchVirtualClinics from './fetchVirtualClinics';
import filterVirtualClinics from './filterVirtualClinics';
import inviteVirtualClinics from './inviteVirtualClinics';
import resendVirtualClinicInvitation from './resendVirtualClinicInvitation';
import removeVirtualClinicInvitation from './removeVirtualClinicInvitation';
import deactivateVirtualClinic from './deactivateVirtualClinic';
import activateVirtualClinic from './activateVirtualClinic';
import resetAuthenticationVirtualClinic from './resetAuthenticationVirtualClinic';
import fetchVirtualClinic from './fetchVirtualClinic';
import submitVirtualClinicProfile from './submitVirtualClinicProfile';
import removeVirtualClinicLogo from './removeVirtualClinicLogo';
import uploadVirtualClinicLogo from './uploadVirtualClinicLogo';
import updateVirtualClinicAccount from './updateVirtualClinicAccount';
import updateVirtualClinicExtraFreeVisits from './updateVirtualClinicExtraFreeVisits';

export default [
  fetchVirtualClinics,
  filterVirtualClinics,
  inviteVirtualClinics,
  resendVirtualClinicInvitation,
  removeVirtualClinicInvitation,
  deactivateVirtualClinic,
  activateVirtualClinic,
  resetAuthenticationVirtualClinic,
  fetchVirtualClinic,
  submitVirtualClinicProfile,
  removeVirtualClinicLogo,
  uploadVirtualClinicLogo,
  updateVirtualClinicAccount,
  updateVirtualClinicExtraFreeVisits,
];
