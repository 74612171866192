import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { is } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { getIn } from 'formik';

import { handleInputEvent } from 'utils/inputHelpers';

import Select from 'views/shared/Select';
import InputMessage from 'views/shared/InputMessage';

const SelectField = forwardRef(({
  id,
  disabled,
  label,
  icon,
  field,
  placeholder,
  intl,
  form: { touched, errors },
  getPopupContainer,
  dropdownRender,
  className,
  selectClassName,
  onChange,
  ...props
}, ref) => {
  const fieldTouched = getIn(touched, field.name);
  const fieldErrors = getIn(errors, field.name);

  const wrapperClassNames = classNames('select', className, {
    'select--has-message-error': fieldTouched && fieldErrors,
  });

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="select__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <Select
        ref={ref}
        id={id}
        name={field.name}
        className={selectClassName}
        placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
        value={field.value}
        disabled={disabled}
        onChange={onChange}
        onBlur={handleInputEvent(field, field.onBlur)}
        getPopupContainer={getPopupContainer}
        optionFilterProp="label"
        dropdownRender={dropdownRender}
        {...props}
      />
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
    </div>
  );
});

SelectField.displayName = 'SelectField';

SelectField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  placeholder: null,
  className: '',
  selectClassName: '',
};

SelectField.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  getPopupContainer: PropTypes.func.isRequired,
  dropdownRender: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SelectField;
