import { combineReducers } from 'redux';
import { union } from 'ramda';

import { USER_ROLE } from 'constants/users';
import { DOCTORS_STATUSES } from 'constants/doctors';

import * as types from './types';

const doctorProfileId = (state = '', action) => {
  switch (action.type) {
    case types.SET_DOCTOR_PROFILE_ID:
      return action.doctorId;
    case types.RESET_DOCTOR_PROFILE_ID:
      return '';
    default:
      return state;
  }
};

const doctorsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_DOCTORS_IDS:
      return action.ids;
    default:
      return state;
  }
};

const doctorListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_DOCTOR_LIST_IDS:
      return action.ids;
    case types.ADD_DOCTORS_TO_IDS_LIST:
      return union(state, action.ids);
    case types.RESET_DOCTOR_LIST_IDS:
      return [];
    default:
      return state;
  }
};

const doctorListStatuses = (state = [DOCTORS_STATUSES.active], action) => {
  switch (action.type) {
    case types.SET_DOCTOR_LIST_STATUSES:
      return action.statuses;
    case types.RESET_DOCTOR_LIST_STATUSES:
      return [DOCTORS_STATUSES.active];
    default:
      return state;
  }
};

const initialState = {
  name: '',
  excludeUsers: [],
};

const doctorListFilterParams = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DOCTOR_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_DOCTOR_LIST_FILTER_PARAMS:
      return initialState;
    default:
      return state;
  }
};

const doctorListClinicSpecialityId = (state = null, action) => {
  switch (action.type) {
    case types.SET_DOCTOR_LIST_CLINIC_SPECIALITY_ID:
      return action.clinicSpecialityId;
    case types.RESET_DOCTOR_LIST_CLINIC_SPECIALITY_ID:
      return null;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_DOCTORS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_DOCTORS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_DOCTORS_SORT:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const rolesInitialState = [USER_ROLE.doctor, USER_ROLE.virtualClinicDoctor];

const roles = (state = rolesInitialState, action) => {
  switch (action.type) {
    case types.SET_DOCTORS_ROLES:
      return [action.roles];
    case types.RESET_DOCTORS_ROLES:
      return rolesInitialState;
    default:
      return state;
  }
};

export default combineReducers({
  doctorProfileId,
  doctorsIds,
  doctorListIds,
  doctorListStatuses,
  doctorListFilterParams,
  doctorListClinicSpecialityId,
  isBlankState,
  pagination,
  sort,
  roles,
});
