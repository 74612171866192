import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Button from 'views/shared/Button';

const NoFetchResult = ({
  src,
  alt,
  captions,
  button,
  intl: { formatMessage },
  className,
}) => (
  <div className="account-empty-state">
    <div className={classNames('account-empty-state__wrapper', className)}>
      <img
        className="account-empty-state__img"
        src={src}
        alt={formatMessage(alt)}
      />
    </div>
    <div className="account-empty-state__txt-wrap">
      {captions.map(caption => (
        <p key={caption.id} className="account-empty-state__caption f-700">
          <FormattedMessage {...caption} />
        </p>
      ))}
      {button && (
        <Button className="mt-24" {...button} />
      )}
    </div>
  </div>
);

NoFetchResult.defaultProps = {
  src: '/static/images/auth/user.svg',
  alt: { id: 'shared.alt.noResults' },
  captions: [{ id: 'shared.noResults' }],
  button: null,
  className: null,
};

NoFetchResult.propTypes = {
  intl: PropTypes.shape().isRequired,
  src: PropTypes.string,
  alt: PropTypes.shape(),
  captions: PropTypes.arrayOf(PropTypes.shape()),
  button: PropTypes.shape(),
  className: PropTypes.string,
};

export default NoFetchResult;
