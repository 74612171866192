import { createLogic } from 'redux-logic';

import { SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTERS } from '../types';
import { fetchPatientsList, setPatientsListFilterParams } from '../actions';

const filterPatientsListOperation = createLogic({
  type: SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setPatientsListFilterParams(filters));
    dispatch(fetchPatientsList());
    done();
  },
});

export default filterPatientsListOperation;
