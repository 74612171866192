export const attachMediaStream = (element, stream) => {
  if (!element) { return; }
  // eslint-disable-next-line no-param-reassign
  element.srcObject = stream;
};

export const detachMediaStream = (element) => {
  if (!element) { return; }

  const stream = element.srcObject;
  if (stream) {
    stream.getTracks().forEach(track => track.stop());
    // eslint-disable-next-line no-param-reassign
    element.srcObject = null;
  }
};

export const setSinkId = (mediaElement, speakerId) => {
  if (!mediaElement || !speakerId) { return; }
  if (typeof mediaElement.setSinkId !== 'function' || speakerId === 'default') { return; }

  mediaElement.setSinkId(speakerId);
};

export const stopTracks = (stream) => {
  if (!stream) { return; }
  stream.getTracks().forEach(track => track.stop());
};
