import { createLogic } from 'redux-logic';
import { isEmpty } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { parseValuesFormToParams } from 'utils/note';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { appointmentSelector } from 'state/concepts/userProfile/appointment/selectors';
import { updateDraftNoteEndpoint } from 'state/concepts/userProfile/appointment/endpoints';
import { UPDATE_DRAFT_NOTE } from 'state/concepts/userProfile/appointment/types';

const updateDraftNoteOperation = createLogic({
  type: UPDATE_DRAFT_NOTE,
  latest: true,
  debounce: 300,

  async process({ action: { values }, getState, httpClient }, dispatch, done) {
    const state = getState();
    const {
      id: appointmentId,
      patientProfile: { id: patientProfileId },
    } = appointmentSelector(state);
    const { url, endpoint } = updateDraftNoteEndpoint(appointmentId);

    const params = {
      chief_compliant: values.chiefCompliant,
      subjective: values.subjective,
      objective: values.objective,
      assessment: values.assessment,
      primary_disease_id: values.primaryDisease.id,
      disease_ids: values.diseases.map(({ id }) => (!isEmpty(id) ? id : null)),
      plan: values.plan,
      prescriptions: parseValuesFormToParams(values.prescriptions),
      auxiliary_studies: values.auxiliaryStudies,
      secret_note: values.secretNote,
      appointment_id: appointmentId,
      patient_profile_id: patientProfileId,
      sick_note: values.sickNote,
      include:
        'patient-profile,appointment,prescriptions,primary_disease,diseases,auxiliary_studies',
    };

    try {
      await httpClient.put(url, params);

      const attributes = {
        noteDraft: {
          ...values,
        },
      };

      const response = updateDataHelper(state.data, 'appointment', appointmentId, { attributes });

      dispatch(dataApiSuccess({ response }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default updateDraftNoteOperation;
