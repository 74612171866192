import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { appointmentSelector } from '../selectors';
import { getDraftNoteEndpoint } from '../endpoints';
import { GET_DRAFT_NOTE } from '../types';

const getDraftNoteOperation = createLogic({
  type: GET_DRAFT_NOTE,
  latest: true,

  async process({ getState, httpClient }, dispatch, done) {
    const state = getState();
    const { id: appointmentId } = appointmentSelector(state);
    const { endpoint, url } = getDraftNoteEndpoint(appointmentId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);

      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getDraftNoteOperation;
