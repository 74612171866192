import { combineReducers } from 'redux';
import { uniq, inc, dec, without } from 'ramda';

import { NOTIFICATION_PANEL } from 'constants/notifications';

import * as types from './types';

const notificationIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_IDS:
      return uniq([...state, ...action.notificationIds]);
    case types.REMOVE_NOTIFICATION_FROM_PANEL:
      return without([action.notificationId], state);
    case types.UNDO_REMOVING_NOTIFICATION_FROM_PANEL:
      return [
        ...state,
        action.notificationId,
      ];
    default:
      return state;
  }
};

const unreadNotificationsCount = (state = 0, action) => {
  switch (action.type) {
    case types.SET_UNREAD_NOTIFICATIONS_COUNT:
      return action.unreadCount;
    case types.DEC_UNREAD_NOTIFICATIONS_COUNT:
      return dec(action.unreadCount);
    case types.INC_UNREAD_NOTIFICATIONS_COUNT:
      return inc(action.unreadCount);
    default:
      return state;
  }
};

const notificationPanelStatus = (state = NOTIFICATION_PANEL.close, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_PANEL_STATUS:
      return action.status;
    default:
      return state;
  }
};

const notificationsReducer = combineReducers({
  notificationIds,
  unreadNotificationsCount,
  notificationPanelStatus,
});

export default notificationsReducer;
