import submitUserLogin from './submitUserLogin';
import userLogout from './userLogout';
import submitUserRecoveryCode from './submitUserRecoveryCode';
import submitUserTwoFactorAuthentication from './submitUserTwoFactorAuthentication';
import refreshActivity from './refreshActivity';
import submitPatientLogin from './submitPatientLogin';
import patientLogout from './patientLogout';
import loginByOneTimeToken from './loginByOneTimeToken';

export default [
  submitUserLogin,
  submitUserRecoveryCode,
  submitUserTwoFactorAuthentication,
  userLogout,
  refreshActivity,
  submitPatientLogin,
  patientLogout,
  loginByOneTimeToken,
];
