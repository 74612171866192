import { createLogic } from 'redux-logic';

import { SPECIALITIES_STATUSES } from 'constants/specialities';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { deactivateSpecialitiesEndpoint } from '../endpoints';
import { DEACTIVATE_SPECIALITIES } from '../types';

const deactivateSpecialitiesOperation = createLogic({
  type: DEACTIVATE_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState, action: { specialityIds } }, dispatch, done) {
    const { url, endpoint } = deactivateSpecialitiesEndpoint;

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url, { speciality_ids: specialityIds });
      const state = getState();

      const response = updateDataHelper(
        state.data, 'speciality', specialityIds,
        { attributes: { status: SPECIALITIES_STATUSES.inactive } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.specialitiesDeactivated',
          values: { count: specialityIds.length },
        },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.specialitiesDeactivatedError',
          values: { count: specialityIds.length },
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default deactivateSpecialitiesOperation;
