import yup from 'lib/yupLocalised';

import {
  MIN_EDIT_FREE_VISITS_COUNT,
  MAX_FREE_VISITS_COUNT,
} from 'constants/freeVisits';

export default yup.object().shape({
  clinicName: yup.string(),
  patientName: yup.string(),
  freeVisitsCount: yup.number()
    .min(MIN_EDIT_FREE_VISITS_COUNT)
    .max(MAX_FREE_VISITS_COUNT)
    .required(),
});
