import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchPatient = patientId => ({
  type: types.FETCH_PATIENT,
  patientId,
});

export const fetchPatientAppointments = patientId => ({
  type: types.FETCH_PATIENT_APPOINTMENTS,
  patientId,
});

export const fetchPatientNotes = patientId => ({
  type: types.FETCH_PATIENT_NOTES,
  patientId,
});

export const setBlankState = isBlankState => ({
  type: types.SET_PATIENT_BLANK_STATE,
  isBlankState,
});

export const setPatient = patientId => ({
  type: types.SET_PATIENT,
  patientId,
});

export const setPatientAppointments = patientAppointmentsIds => ({
  type: types.SET_PATIENT_APPOINTMENTS,
  patientAppointmentsIds,
});

export const setPatientNotes = patientNotesIds => ({
  type: types.SET_PATIENT_NOTES,
  patientNotesIds,
});

export const setPatientStatuses = statuses => ({
  type: types.SET_PATIENT_STATUSES,
  statuses,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_PATIENT_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_PATIENT_APPOINTMENTS_SORT_ORDER,
  sortKey,
});

export const setSortOrderNotes = sortKey => ({
  type: types.SET_PATIENT_NOTES_SORT_ORDER,
  sortKey,
});

export const filterPatient = (filters, patientId, isNotes) => ({
  type: types.SET_PATIENT_FILTER,
  filters,
  patientId,
  isNotes,
});

export const setFilterParams = filterParams => ({
  type: types.SET_PATIENT_FILTER_PARAMS,
  filterParams,
});

export const activatePatient = patientId => ({
  type: types.ACTIVATE_PATIENT,
  patientId,
});

export const banPatient = patientId => ({
  type: types.BAN_PATIENT,
  patientId,
});

export const setPatientChildrenIds = patientChildrenIds => ({
  type: types.SET_PATIENT_CHILDREN_IDS,
  patientChildrenIds,
});

export const setPatientDetailsActiveTabId = activeTab => ({
  type: types.SET_PATIENT_DETAILS_ACTIVE_TAB,
  activeTab,
});

export const submitPatientProfile = makeFormSubmitAction(types.SUBMIT_PATIENT_PROFILE);
export const submitChildrenProfile = makeFormSubmitAction(types.SUBMIT_CHILDREN_PROFILE);
