import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck, path } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setNotificationIds, setUnreadNotificationsCount } from 'state/concepts/userProfile/notifications/actions';
import { fetchNotificationsEndpoint } from '../endpoints';
import { FETCH_NOTIFICATIONS } from '../types';

const fetchNotificationsOperation = createLogic({
  type: FETCH_NOTIFICATIONS,
  latest: true,

  async process({ httpClient, action: { pageParams } }, dispatch, done) {
    const { endpoint, url } = fetchNotificationsEndpoint;
    const params = {
      include: 'recipient',
      page: {
        ...pageParams,
      },
      filter: {
        type: 'notification_panel',
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setNotificationIds(pluck('id', data.data)));
      dispatch(setUnreadNotificationsCount(path(['meta', 'unread_count'], data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchNotificationsOperation;
