import PropTypes from 'prop-types';
import { Field } from 'formik';
import { camelCase, lowerCase } from 'lodash';

import SvgIcon from 'views/shared/SvgIcon';
import InputField from 'views/shared/InputField';
import Button from 'views/shared/Button';

const InvitationInputFieldsComponent = ({
  intl,
  roleName,
  addRow,
  removeRow,
  handleBlur,
  form: {
    values: { invitations },
  },
}) => (
  <>
    <div className="modal-invite__forms">
      {invitations.map(({ key, role }, index) => (
        <div className="modal-invite__form" key={key}>
          <Field
            name={`invitations.${index}.email`}
            component={InputField}
            id={`invitations.${index}.email`}
            type="email"
            label={{ id: 'label.email' }}
            icon="alert"
            onBlur={handleBlur}
          />
          <Field
            name={`invitations.${index}.role`}
            component={InputField}
            id={`invitations.${index}.role`}
            label={{ id: 'label.role' }}
            value={intl.formatMessage({ id: `role.${camelCase(role)}` })}
            disabled
          />
          {invitations.length > 1 && (
            <button onClick={removeRow(index)}>
              <SvgIcon icon="delete-o" className="modal-invite__remove" />
            </button>
          )}
        </div>
      ))}
    </div>
    <Button
      onClick={addRow}
      kind="empty"
      icon="plus"
      text={{
        id: 'invitations.modal.inviteOneMoreUsers',
        values: { roleName: lowerCase(intl.formatMessage({ id: `role.${camelCase(roleName)}` })) },
      }}
    />
  </>
);

InvitationInputFieldsComponent.propTypes = {
  intl: PropTypes.shape().isRequired,
  roleName: PropTypes.string.isRequired,
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      invitations: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          role: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default InvitationInputFieldsComponent;
