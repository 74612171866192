import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { last, prop } from 'ramda';

import {
  fetchRevenueVirtualClinicDoctors as fetchRevenueVirtualClinicDoctorsAction,
} from 'state/concepts/userProfile/revenue/actions';
import {
  revenueVirtualClinicDoctorsSelector,
  revenueVirtualClinicDoctorsLoadingSelector,
  revenueVirtualClinicDoctorsMetaSelector,
} from 'state/concepts/userProfile/revenue/selectors';

import Spinner from 'views/shared/Spinner';

class DoctorRevenueModalLoader extends React.Component {
  static propTypes = {
    fetchRevenueVirtualClinicDoctors: PropTypes.func.isRequired,
    meta: PropTypes.shape({ hasMore: PropTypes.bool }),
    isLoading: PropTypes.bool,
    doctors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  static defaultProps = {
    isLoading: undefined,
    meta: {},
  }

  loadMore = () => {
    const { fetchRevenueVirtualClinicDoctors, meta: { hasMore }, doctors } = this.props;

    if (hasMore) {
      fetchRevenueVirtualClinicDoctors(prop('id', last(doctors)));
    }
  }

  render() {
    const { isLoading } = this.props;

    if (isLoading !== false) { return <Spinner isBig />; }

    return (
      <Waypoint
        onEnter={this.loadMore}
        topOffset="-100px"
      />
    );
  }
}

const mapStateToProps = state => ({
  meta: revenueVirtualClinicDoctorsMetaSelector(state),
  isLoading: revenueVirtualClinicDoctorsLoadingSelector(state),
  doctors: revenueVirtualClinicDoctorsSelector(state),
});

const mapDispatchToProps = {
  fetchRevenueVirtualClinicDoctors: fetchRevenueVirtualClinicDoctorsAction,
};

export { DoctorRevenueModalLoader as DoctorRevenueModalLoaderContainer };
export default connect(mapStateToProps, mapDispatchToProps)(DoctorRevenueModalLoader);
