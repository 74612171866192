import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { removeClinicSpecialities } from 'state/concepts/userProfile/clinicSpecialities/actions';
import { removeClinicSpecialitiesLoadingSelector } from 'state/concepts/userProfile/clinicSpecialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ClinicSpecialitiesRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clinicSpeciality: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, clinicSpeciality: { id } } = this.props;
    onSubmit([id]);
  }

  render() {
    const { clinicSpeciality: { name } } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        submitClassName="w-136"
        onSubmit={this.handleSubmit}
        title={{ id: 'clinicSpecialities.remove.modal.title' }}
        bodyText={[
          {
            id: 'clinicSpecialities.remove.modal.body',
            values: { name: <span className="f-700">{name}</span> },
          },
          {
           id: 'clinicSpecialities.remove.modal.activeBookings',
          },
        ]}
        submitText={{ id: 'shared.remove' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: removeClinicSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: removeClinicSpecialities,
};

export { ClinicSpecialitiesRemoveModal as ClinicSpecialitiesRemoveModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ClinicSpecialitiesRemoveModal);
