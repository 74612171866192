import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'antd';
import { isEmpty } from 'ramda';

import SelectField from 'views/shared/SelectField';
import LoadMoreLoader from 'views/shared/LoadMoreLoader';
import DropdownNotFoundContent from 'views/shared/DropdownNotFoundContent';

const DropdownSpecialitiesFieldComponent = ({
  isLoading,
  isAllSpecialitiesSelected,
  field,
  options,
  onSearch,
  onFocus,
  meta,
  intl,
  fetchSpecialitiesList,
  ...rest
}) => (
  <SelectField
    {...rest}
    field={field}
    onSearch={onSearch}
    onFocus={onFocus}
  >
    {isEmpty(options) ? [] : options.map(({ key, value, label }) => (
      <Select.Option
        label={label}
        title={label}
        value={value}
        key={key}
        className={classNames({ 'd-none': [].concat(field.value).includes(key) })}
      >
        {label}
      </Select.Option>
    ))}
    <Select.Option value="#" disabled className="multiselect-field__dropdown-item--loader">
      <LoadMoreLoader
        isLoading={isLoading}
        meta={meta}
        options={options}
        loadMore={fetchSpecialitiesList}
      />
    </Select.Option>
    {isAllSpecialitiesSelected && (
      <Select.Option value="" disabled>
        <DropdownNotFoundContent
          isLoading={isLoading}
          isAllOptionsSelected={isAllSpecialitiesSelected}
          description="speciality.allSpecialitiesAdded"
        />
      </Select.Option>
    )}
  </SelectField>
);

DropdownSpecialitiesFieldComponent.defaultProps = {
  isLoading: undefined,
  meta: {},
};

DropdownSpecialitiesFieldComponent.propTypes = {
  isLoading: PropTypes.bool,
  isAllSpecialitiesSelected: PropTypes.bool.isRequired,
  field: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFocus: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  fetchSpecialitiesList: PropTypes.func.isRequired,
  meta: PropTypes.shape({ hasMore: PropTypes.bool }),
  intl: PropTypes.shape().isRequired,
};

export default DropdownSpecialitiesFieldComponent;
