import { loadingSelector } from 'state/data/selectors';

import {
  deactivateUserEndpoint,
  updateUserProfileEndpoint,
  getUserCommissionSettingsEndpoint,
} from './endpoints';

export const deactivateUserLoadingSelector = state => (
  loadingSelector(state, deactivateUserEndpoint.endpoint)
);

export const updateUserProfileLoadingSelector = state => (
  loadingSelector(state, updateUserProfileEndpoint.endpoint)
);

export const userCommissionSettingsLoadingSelector = state => (
  loadingSelector(state, getUserCommissionSettingsEndpoint.endpoint)
);
