import { toLower, has } from 'ramda';

const colorsGenerator = (key, colorMatrix) => {
  const firstLetter = key && toLower(key[0]);

  if (!has(firstLetter, colorMatrix)) {
    return colorMatrix[Object.keys(colorMatrix)[0]];
  }

  return colorMatrix[firstLetter];
};

export default colorsGenerator;
