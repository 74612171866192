import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setVirtualClinicProfileId } from '../actions';
import { FETCH_VIRTUAL_CLINIC } from '../types';
import { fetchVirtualClinicEndpoint } from '../endpoints';

const fetchVirtualClinicOperation = createLogic({
  type: FETCH_VIRTUAL_CLINIC,
  latest: true,

  async process({ httpClient, action: { userId } }, dispatch, done) {
    const { endpoint, url } = fetchVirtualClinicEndpoint(userId);

    const params = {
      include: 'clinic-specialities,clinic-specialities.speciality',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setVirtualClinicProfileId(data.data.id));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVirtualClinicOperation;
