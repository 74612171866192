import { createLogic } from 'redux-logic';
import { length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { setCurrentPage, fetchVirtualClinics } from '../actions';
import { virtualClinicsInvitationsEndpoint } from '../endpoints';
import { INVITE_VIRTUAL_CLINICS } from '../types';

const inviteVirtualClinicsOperation = createLogic({
  type: INVITE_VIRTUAL_CLINICS,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = virtualClinicsInvitationsEndpoint;
    const params = {
      ...values,
      redirect_to: '/invitation_signup',
    };

    try {
      await httpClient.post(url, params);

      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.virtualClinicAdminInvited',
          values: { count: length(values.invitations) },
        },
      }));
      dispatch(setCurrentPage(1));
      dispatch(fetchVirtualClinics());
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.virtualClinicAdminInvitedError',
          values: { count: length(values.invitations) },
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default inviteVirtualClinicsOperation;
