import React from 'react';
import { withFormik } from 'formik';
import { compose, propOr } from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import validationSchema from 'lib/yupLocalised/schemas/editBatchDetails';

import { handleSubmit } from 'utils/form/handleSubmit';

import { updateVouchersBatchDetails } from 'state/concepts/userProfile/vouchersBatches/actions';
import { vouchersBatchDetailsSelector } from 'state/concepts/userProfile/vouchersBatches/selectors';

import EditBatchDetailsModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class EditBatchDetailsModal extends React.Component {
  static propTypes = {
    batchId: PropTypes.string.isRequired,
  };

  render = () => (
    <EditBatchDetailsModalComponent
      {...this.props}
    />
  );
}

const mapStateToProps = (state, { batchId }) => ({
  vouchersBatchDetails: vouchersBatchDetailsSelector(state, batchId),
});

const mapDispatchToProps = {
  onSubmit: updateVouchersBatchDetails,
};

export { EditBatchDetailsModal as EditBatchDetailsModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ vouchersBatchDetails, batchId }) => ({
      name: propOr('', 'name', vouchersBatchDetails),
      comment: propOr('', 'comment', vouchersBatchDetails),
      batchId,
    }),
    validationSchema,
    handleSubmit,
  }),
)(EditBatchDetailsModal);
