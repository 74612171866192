import React from 'react';
import { connect } from 'react-redux';

import { cancellationErrorSelector } from 'state/concepts/userProfile/appointments/selectors';

import CancelModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class CancelModal extends React.Component {
  render() {
    return (
      <CancelModalComponent {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  cancellationError: cancellationErrorSelector(state),
});

export { CancelModal as CancelModalContainer };
export default connect(mapStateToProps)(CancelModal);
