import endpoint from 'utils/endpoint';
import {
  appointmentRoute,
  questionnaireResponsesRoute,
  appointmentNotesRoute,
  appointmentNoteDraftRoute,
  noteRoute,
  appointmentNoteRoute,
} from 'lib/apiRoutes';
import {
  GET_QUESTIONNAIRE_RESPONSES,
  GET_USER_APPOINTMENT,
  SUBMIT_ADD_NOTE,
  GET_DRAFT_NOTE,
  UPDATE_DRAFT_NOTE,
  GET_NOTE,
} from './types';

export const getAppointmentEndpoint = appointmentId => (
  endpoint(GET_USER_APPOINTMENT, 'GET', appointmentRoute(appointmentId))
);

export const getQuestionnaireResponsesEndpoint = questionnaireResponsesId => (
  endpoint(GET_QUESTIONNAIRE_RESPONSES, 'GET', questionnaireResponsesRoute(questionnaireResponsesId))
);

export const submitAddNoteEndpoint = appointmentId => endpoint(SUBMIT_ADD_NOTE, 'POST', appointmentNotesRoute(appointmentId));
export const updateNoteEndpoint = noteId => endpoint(SUBMIT_ADD_NOTE, 'PUT', appointmentNoteRoute(noteId));
export const getDraftNoteEndpoint = appointmentId => endpoint(GET_DRAFT_NOTE, 'GET', appointmentNoteDraftRoute(appointmentId));
export const updateDraftNoteEndpoint = appointmentId => endpoint(UPDATE_DRAFT_NOTE, 'PUT', appointmentNoteDraftRoute(appointmentId));
export const getNoteEndpoint = noteId => endpoint(GET_NOTE, 'GET', noteRoute(noteId));
