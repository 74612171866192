import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { deactivateSymptoms } from 'state/concepts/userProfile/symptoms/actions';
import { deactivateSymptomsLoadingSelector } from 'state/concepts/userProfile/symptoms/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class SymptomsDeactivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    symptom: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, symptom: { id } } = this.props;

    onSubmit([id]);
  }

  render() {
    const { symptom } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'symptoms.deactivateModal.title' }}
        bodyText={[{
          id: 'symptoms.deactivateModal.body',
          values: { name: <span className="f-700">{symptom.name}</span> },
        }]}
        submitText={{ id: 'shared.deactivate' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: deactivateSymptomsLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: deactivateSymptoms,
};

export { SymptomsDeactivateModal as SymptomsDeactivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SymptomsDeactivateModal);
