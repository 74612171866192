import { createLogic } from 'redux-logic';

import { USER_ROLE } from 'constants/users';
import { REVENUE_TYPE } from 'constants/revenue';

import isPresent from 'utils/isPresent';

import { setDoctorsRoles, resetDoctorsRoles } from 'state/concepts/userProfile/doctors/actions';
import {
  resetRevenue,
  setRevenueFilterParams,
  setRevenueType,
  setRevenueVirtualClinicId,
  fetchRevenueExtraStatistic,
} from '../actions';
import { SET_REVENUE_INITIAL_STATE } from '../types';

const initialStateRevenueOperation = createLogic({
  type: SET_REVENUE_INITIAL_STATE,
  latest: true,

  async process({ action: { revenueType, virtualClinicId } }, dispatch, done) {
    const clinicTypes = isPresent(revenueType) ? [revenueType] : [];
    const clinicIds = isPresent(virtualClinicId) ? [virtualClinicId] : [];
    const clinicId = isPresent(virtualClinicId) ? virtualClinicId : null;

    await dispatch(resetRevenue());

    if (revenueType === REVENUE_TYPE.independent) {
      dispatch(setDoctorsRoles(USER_ROLE.doctor));
    } else if (revenueType === REVENUE_TYPE.virtual) {
      dispatch(setDoctorsRoles(USER_ROLE.virtualClinicDoctor));
    } else {
      dispatch(resetDoctorsRoles());
    }

    dispatch(setRevenueType(revenueType));
    dispatch(setRevenueFilterParams({ clinicTypes, clinicIds }));
    dispatch(setRevenueVirtualClinicId(clinicId));
    if (!isPresent(revenueType)) {
      dispatch(fetchRevenueExtraStatistic());
    }

    done();
  },
});

export default initialStateRevenueOperation;
