import { createSelector } from 'reselect';
import { prop, path, map } from 'ramda';

import profileName from 'utils/profileName';
import buildCollection from 'utils/buildCollection';

import { totalCountSelector, pageCountSelector, loadingSelector, endpointMetaSelector } from 'state/data/selectors';
import {
  fetchExtraFreeVisitsEndpoint,
  fetchExtraFreeVisitsClinicsEndpoint,
  fetchExtraFreeVisitsPatientsEndpoint,
  submitExtraFreeVisitsEndpoint,
  submitUpdateExtraFreeVisitsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const freeVisitsBlankStateSelector = path(['freeVisits', 'freeVisitsBlankState']);
export const freeVisitsIdsSelector = path(['freeVisits', 'freeVisitsIds']);
export const freeVisitsPaginationSelector = path(['freeVisits', 'freeVisitsPagination']);
export const freeVisitsSortSelector = path(['freeVisits', 'freeVisitsSort']);
export const clinicsListFilterParamsSelector = path(['freeVisits', 'clinicsListFilterParams']);
export const clinicsListIdsSelector = path(['freeVisits', 'clinicsListIds']);
export const patientsListFilterParamsSelector = path(['freeVisits', 'patientsListFilterParams']);
export const patientsListIdsSelector = path(['freeVisits', 'patientsListIds']);

export const freeVisitsSelector = createSelector(
  freeVisitsIdsSelector,
  dataSelector,
  buildCollection('extraFreeVisit'),
);

export const freeVisitsCurrentPageSelector = createSelector(
  freeVisitsPaginationSelector,
  prop('number'),
);

export const freeVisitsSortParamsSelector = createSelector(
  freeVisitsSortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const freeVisitsTotalCountSelector = state => (
  totalCountSelector(state, fetchExtraFreeVisitsEndpoint.endpoint)
);

export const freeVisitsPageCountSelector = state => (
  pageCountSelector(state, fetchExtraFreeVisitsEndpoint.endpoint)
);

export const freeVisitsLoadingSelector = state => (
  loadingSelector(state, fetchExtraFreeVisitsEndpoint.endpoint)
);

export const clinicsListMetaSelector = state => (
  endpointMetaSelector(state, fetchExtraFreeVisitsClinicsEndpoint.endpoint)
);

export const clinicsListLoadingSelector = state => (
  loadingSelector(state, fetchExtraFreeVisitsClinicsEndpoint.endpoint)
);

export const clinicsListSelector = createSelector(
  clinicsListIdsSelector,
  dataSelector,
  buildCollection('clinic'),
);

export const clinicsListForFilterSelector = createSelector(
  clinicsListSelector,
  map(({ id, name }) => ({ id, key: id, value: id, label: name })),
);

export const patientsListMetaSelector = state => (
  endpointMetaSelector(state, fetchExtraFreeVisitsPatientsEndpoint.endpoint)
);

export const patientsListLoadingSelector = state => (
  loadingSelector(state, fetchExtraFreeVisitsPatientsEndpoint.endpoint)
);

export const patientsListSelector = createSelector(
  patientsListIdsSelector,
  dataSelector,
  buildCollection('patientProfile'),
);

export const patientsListForFilterSelector = createSelector(
  patientsListSelector,
  map((patient) => ({
    id: patient.id,
    key: patient.id,
    value: patient.id,
    label: profileName(patient),
  })),
);

export const submitCreateFreeVisitsLoadingSelector = state => (
  loadingSelector(state, submitExtraFreeVisitsEndpoint.endpoint)
);

export const submitUpdateFreeVisitsLoadingSelector = (state, freeVisitId) => (
  loadingSelector(state, submitUpdateExtraFreeVisitsEndpoint(freeVisitId).endpoint)
);
