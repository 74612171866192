import { combineReducers } from 'redux';

import * as types from './types';

const dateAvailabilityIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_DATE_AVAILABILITY:
      return action.dateAvailabilityIds;
    case types.RESET_DATE_AVAILABILITY:
      return [];
    default:
      return state;
  }
};

const dateAvailabilityReducer = combineReducers({
  dateAvailabilityIds,
});

export default dateAvailabilityReducer;
