import fetchFreeVisits from './fetchFreeVisits';
import filterFreeVisits from './filterFreeVisits';
import fetchClinics from './fetchClinics';
import filterClinicsList from './filterClinicsList';
import fetchPatients from './fetchPatients';
import filterPatientsList from './filterPatientsList';
import submitCreateFreeVisits from './submitCreateFreeVisits';
import submitUpdateFreeVisits from './submitUpdateFreeVisits';

export default [
  fetchFreeVisits,
  filterFreeVisits,
  fetchClinics,
  filterClinicsList,
  fetchPatients,
  filterPatientsList,
  submitCreateFreeVisits,
  submitUpdateFreeVisits,
];
