import endpoint from 'utils/endpoint';

import {
  usersDateAvailabilityRoute,
} from 'lib/apiRoutes';
import {
  FETCH_DATE_AVAILABILITY,
  UPDATE_DATE_AVAILABILITY,
} from './types';

export const fetchDateAvailabilityEndpoint = userId => endpoint(FETCH_DATE_AVAILABILITY, 'GET', usersDateAvailabilityRoute(userId));
export const updateDateAvailabilityEndpoint = userId => endpoint(UPDATE_DATE_AVAILABILITY, 'PUT', usersDateAvailabilityRoute(userId));
