import { createLogic } from 'redux-logic';
import { pick } from 'ramda';

import { ONBOARDING_STATUSES } from 'constants/onboarding';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { submitGeneralInfoEndpoint } from '../endpoints';
import { SUBMIT_ONBOARDING_VIRTUAL_CLINIC_GENERAL } from '../types';

const submitOnboardingVirtualClinicGeneralOperation = createLogic({
  type: SUBMIT_ONBOARDING_VIRTUAL_CLINIC_GENERAL,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = submitGeneralInfoEndpoint;
    const state = getState();
    const currentUserId = currentUserIdSelector(state);

    const params = {
      first_name: values.firstName,
      last_name: values.lastName,
      timezone: values.timezone,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'userProfile', currentUserId,
        {
          attributes: {
            ...pick(['firstName', 'lastName', 'timezone'], values),
            onboardingStatus: ONBOARDING_STATUSES.location,
          },
        },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitOnboardingVirtualClinicGeneralOperation;
