import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { fetchJoinRequests, setCurrentPage } from '../actions';
import { SET_JOIN_REQUESTS_FILTERS } from '../types';

const filterJoinRequestsOperation = createLogic({
  type: SET_JOIN_REQUESTS_FILTERS,
  latest: true,

  async process({ action: { filters, roleName } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    dispatch(fetchJoinRequests(roleName));
    done();
  },
});

export default filterJoinRequestsOperation;
