import { createLogic } from 'redux-logic';

import { SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTERS } from '../types';
import { fetchClinicsList, setClinicsListFilterParams } from '../actions';

const filterClinicsListOperation = createLogic({
  type: SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setClinicsListFilterParams(filters));
    dispatch(fetchClinicsList());
    done();
  },
});

export default filterClinicsListOperation;
