import { createLogic } from 'redux-logic';
import cookies from 'component-cookie';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { submitUpdateUserPasswordEndpoint } from '../endpoints';
import { SUBMIT_UPDATE_USER_PASSWORD } from '../types';

const updateUserPasswordOperation = createLogic({
  type: SUBMIT_UPDATE_USER_PASSWORD,
  latest: true,

  async process({
    action: {
      values, form,
    },
    httpClient,
  }, dispatch, done) {
    const { url, endpoint } = submitUpdateUserPasswordEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        old_password: values.oldPassword,
        password: values.password,
        password_confirmation: values.password,
        redirect_to: '/account_settings',
      };

      const { data } = await httpClient.put(url, params);

      dispatch(dataApiSuccess({ endpoint }));
      const tokens = data.meta.jwt;

      cookies('tokens', JSON.stringify(tokens));

      dispatch(showNotification({ messageObject: { id: 'notifications.resetPasswordSuccess' } }));
      form.resetForm();
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateUserPasswordOperation;
