import React from 'react';
import { is } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getIn } from 'formik';
import { FormattedMessage } from 'react-intl';

import InputMessage from '../InputMessage';

const InputField = ({
  id,
  type,
  disabled,
  label,
  icon,
  fullWidth,
  field,
  placeholder,
  autoComplete,
  intl,
  className,
  form: { touched, errors, status },
  suffix,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name);
  const fieldErrors = getIn(errors, field.name);
  const fieldStatus = getIn(status, field.name);

  const inputClassNames = classNames(
    'input-text',
    className,
    {
      'w-full': fullWidth,
    },
  );
  const wrapperClassNames = classNames(
    'input-text__wrapper',
    {
      'input-text__wrapper--disabled': disabled,
      'input-text__wrapper--error': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
      'main-input--has-message-alert': fieldStatus,
    },
  );

  return (
    <div className={inputClassNames}>
      {
        label && (
          <label
            className="input-text__label"
            htmlFor={id}
          >
            <FormattedMessage {...label} />
          </label>
        )
      }
      <div className={wrapperClassNames}>
        <input
          className={classNames('input-text__field', suffix ? 'mr-8' : '')}
          type={type}
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          autoComplete={autoComplete}
          {...{ disabled, id, ...field, ...props }}
        />
        {suffix}
      </div>
      {
        fieldTouched && fieldErrors && (
          <InputMessage
            message={fieldErrors}
            icon={icon}
          />
        )
      }
    </div>
  );
};

InputField.defaultProps = {
  id: null,
  type: 'text',
  disabled: false,
  label: null,
  icon: null,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  suffix: null,
  fullWidth: false,
};

InputField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  suffix: PropTypes.node,
  fullWidth: PropTypes.bool,
};

export default InputField;
