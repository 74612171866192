import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import { SPECIALITIES_STATUSES } from 'constants/specialities';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { createSpecialityEndpoint } from '../endpoints';
import { CREATE_SPECIALITY } from '../types';

const submitCreateSpecialityOperation = createLogic({
  type: CREATE_SPECIALITY,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url, endpoint } = createSpecialityEndpoint;
    const params = {
      ...values,
      status: values.status ? SPECIALITIES_STATUSES.active : SPECIALITIES_STATUSES.inactive,
      children: values.children,
    };

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response }));
      await redirect({ href: '/specialities' });
      dispatch(showNotification({ messageObject: { id: 'notifications.specialityCreated' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default submitCreateSpecialityOperation;
