import { combineReducers } from 'redux';
import { without, union } from 'ramda';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';

import * as types from './types';

const clinicSpecialitiesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES:
      return action.clinicSpecialitiesIds;
    default:
      return state;
  }
};

const selectedClinicSpecialities = (state = [], action) => {
  switch (action.type) {
    case types.SELECT_CLINIC_SPECIALITIES:
      return state.includes(action.clinicSpecialitiesId)
        ? without([action.clinicSpecialitiesId], state)
        : [...state, action.clinicSpecialitiesId];
    case types.SET_CLINIC_SPECIALITIES_SELECTED:
      return action.clinicSpecialitiesIds;
    case types.REMOVE_SELECTED_CLINIC_SPECIALITIES:
      return without(action.clinicSpecialitiesIds, state);
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'specialities.name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const clinicSpecialitiesListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_LIST_IDS:
      return union(state, action.ids);
    case types.RESET_CLINIC_SPECIALITIES_LIST_IDS:
      return [];
    default:
      return state;
  }
};

const clinicSpecialitiesListStatuses = (state = [CLINIC_SPECIALITIES_STATUSES.active], action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_LIST_STATUSES:
      return action.statuses;
    case types.RESET_CLINIC_SPECIALITIES_LIST_STATUSES:
      return [CLINIC_SPECIALITIES_STATUSES.active];
    default:
      return state;
  }
};

const initialState = {
  name: '',
  excludeClinicSpecialities: [],
};

const clinicSpecialitiesListFilterParams = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  clinicSpecialitiesIds,
  selectedClinicSpecialities,
  pagination,
  sort,
  isBlankState,
  clinicSpecialitiesListIds,
  clinicSpecialitiesListStatuses,
  clinicSpecialitiesListFilterParams,
});
