import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import removeUserCookies from 'utils/removeUserCookies';

import { showNotification } from 'state/notifications/actions';
import { hideModal } from 'state/modal/actions';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { submitResetPasswordEndpoint } from '../endpoints';
import { RESENT_PASSWORD_IN_ACCOUNT } from '../types';

const resetPasswordInAccount = createLogic({
  type: RESENT_PASSWORD_IN_ACCOUNT,
  latest: true,

  async process({ httpClient, action: { email } }, dispatch, done) {
    const { url, endpoint } = submitResetPasswordEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const body = {
        email,
        redirect_to: '/reset_password/confirm',
      };

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());

      removeUserCookies();

      await redirect({ href: '/login' });

      dispatch(showNotification({
        messageObject: { id: 'notifications.resetPasswordInstructions' },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default resetPasswordInAccount;
