import { createLogic } from 'redux-logic';
import { prop } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { removeNotificationFromPanel, decUnreadNotificationsCount } from '../actions';
import { notificationSelector, unreadNotificationsCountSelector } from '../selectors';
import { removeNotificationEndpoint } from '../endpoints';
import { REMOVE_NOTIFICATION } from '../types';

const removeNotificationOperation = createLogic({
  type: REMOVE_NOTIFICATION,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const { notificationId } = action;
    const { endpoint, url } = removeNotificationEndpoint(notificationId);
    const state = getState();
    const readAt = prop('readAt', notificationSelector(state, notificationId));
    const unreadCount = unreadNotificationsCountSelector(state);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(removeNotificationFromPanel(notificationId));
      if (readAt === null) {
        dispatch(decUnreadNotificationsCount(unreadCount));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeNotificationOperation;
