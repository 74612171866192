import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  removeClinicSpecialities,
  setSelectedClinicSpecialities as setSelectedClinicSpecialitiesAction,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import { removeClinicSpecialitiesLoadingSelector } from 'state/concepts/userProfile/clinicSpecialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ClinicSpecialitiesBulkRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clinicSpecialitiesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedClinicSpecialities: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, clinicSpecialitiesIds, setSelectedClinicSpecialities } = this.props;
    onSubmit(clinicSpecialitiesIds);
    setSelectedClinicSpecialities([]);
  }

  render() {
    const { clinicSpecialitiesIds } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'clinicSpecialities.bulkRemove.modal.title' }}
        bodyText={[
          [{
            id: 'clinicSpecialities.bulkRemove.modal.body.start',
          },
          {
            id: 'clinicSpecialities.bulkRemove.modal.body.boldText',
            values: { count: clinicSpecialitiesIds.length },
            isBold: true,
          },
          {
            id: 'clinicSpecialities.bulkRemove.modal.body.final',
          }],
          [{
            id: 'clinicSpecialities.bulkRemove.modal.activeBookings',
          }],
        ]}
        submitText={{ id: 'shared.remove' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: removeClinicSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: removeClinicSpecialities,
  setSelectedClinicSpecialities: setSelectedClinicSpecialitiesAction,
};

export { ClinicSpecialitiesBulkRemoveModal as ClinicSpecialitiesBulkRemoveModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ClinicSpecialitiesBulkRemoveModal);
