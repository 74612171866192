import { combineReducers } from 'redux';

import { PATIENT_PROFILE_TAB } from 'constants/patient';
import { ACTIVE_APPOINTMENTS } from 'constants/appointments';

import {
  SET_PATIENT,
  SET_PATIENT_APPOINTMENTS,
  SET_PATIENT_APPOINTMENTS_SORT_ORDER,
  SET_PATIENT_BLANK_STATE,
  SET_PATIENT_FILTER_PARAMS,
  SET_PATIENT_NOTES,
  SET_PATIENT_NOTES_SORT_ORDER,
  SET_PATIENT_PAGE,
  SET_PATIENT_STATUSES,
  SET_PATIENT_CHILDREN_IDS,
  SET_PATIENT_DETAILS_ACTIVE_TAB,
} from './types';

const patientId = (state = '', action) => {
  switch (action.type) {
    case SET_PATIENT:
      return action.patientId;
    default:
      return state;
  }
};

const isBlankState = (state = false, action) => {
  switch (action.type) {
    case SET_PATIENT_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const patientAppointmentsIds = (state = [], action) => {
  switch (action.type) {
    case SET_PATIENT_APPOINTMENTS:
      return action.patientAppointmentsIds;
    default:
      return state;
  }
};

const patientNotesIds = (state = [], action) => {
  switch (action.type) {
    case SET_PATIENT_NOTES:
      return action.patientNotesIds;
    default:
      return state;
  }
};

const statuses = (state = ACTIVE_APPOINTMENTS, action) => {
  switch (action.type) {
    case SET_PATIENT_STATUSES:
      return action.statuses;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_PATIENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'start_time', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_PATIENT_APPOINTMENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const sortNotes = (state = { sortKey: 'created_at', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_PATIENT_NOTES_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const filtersInitialState = {
  name: '',
  timezone: null,
  statuses: ACTIVE_APPOINTMENTS,
};

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_PATIENT_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    default:
      return state;
  }
};

const childrenIds = (state = [], action) => {
  switch (action.type) {
    case SET_PATIENT_CHILDREN_IDS:
      return action.patientChildrenIds;
    default:
      return state;
  }
};

const activeTab = (state = PATIENT_PROFILE_TAB.profile, action) => {
  switch (action.type) {
    case SET_PATIENT_DETAILS_ACTIVE_TAB:
      return action.activeTab;
    default:
      return state;
  }
};

export default combineReducers({
  patientId,
  patientAppointmentsIds,
  statuses,
  pagination,
  sort,
  filters,
  isBlankState,
  patientNotesIds,
  sortNotes,
  childrenIds,
  activeTab,
});
