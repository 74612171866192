import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSearchResults } from 'state/searchResults/actions';
import { getPatientsEndpoint } from '../endpoints';
import { GET_PATIENTS } from '../types';

const getPatientsOperation = createLogic({
  type: GET_PATIENTS,
  latest: true,

  async process({ httpClient, action: { searchQuery } }, dispatch, done) {
    const { endpoint, url } = getPatientsEndpoint;

    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      filter: {
        name: searchQuery,
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);
      const ids = pluck('id', data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSearchResults({ patients: ids }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getPatientsOperation;
