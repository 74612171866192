import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setPatientAppointments } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import { fetchVirtualClinicPatientAppointmentsEndpoint } from '../endpoints';
import { FETCH_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS } from '../types';

const fetchPatientAppointmentsOperation = createLogic({
  type: FETCH_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS,
  latest: true,

  async process({ httpClient, getState, action: { patientId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchVirtualClinicPatientAppointmentsEndpoint(patientId);

    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state, 'sort'),
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(setPatientAppointments(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPatientAppointmentsOperation;
