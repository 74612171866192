export const NOTES = ['notes'];
export const FREE_VISITS = ['free_visits'];

export const PATIENT_PROFILE_TAB = {
  profile: 'patient_profile',
  health: 'health_profile',
  children: 'children',
  freeVisits: 'free_visits',
};

export const CLINIC_PATIENT_PROFILE_TAB = {
  appointments: 'appointments',
  freeVisits: 'free_visits',
};

export const PATIENT_STATUSES = {
  all: '',
  active: 'active',
  banned: 'banned',
  deactivated: 'deactivated',
  invited: 'invited',
  confirmed: 'confirmed',
};

export const PATIENT_ACTIONS = {
  appointmentPayment: 'appointment_payment',
  balanceTopUp: 'balance_topup',
};

export const PATIENTS_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: PATIENT_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: PATIENT_STATUSES.banned,
    label: { id: 'shared.filters.statuses.banned' },
  },
  {
    value: PATIENT_STATUSES.deactivated,
    label: { id: 'shared.filters.statuses.deactivated' },
  },
  {
    value: PATIENT_STATUSES.confirmed,
    label: { id: 'shared.filters.statuses.confirmed' },
  },
];

export const PATIENT_WELLNESS = {
  all: '',
  active: 'true',
  inactive: 'false',
};

export const PATIENTS_WELLNESS_FILTER = [
  {
    value: 'all',
    label: { id: 'shared.filters.wellness.all' },
  },
  {
    value: PATIENT_WELLNESS.active,
    label: { id: 'shared.filters.wellness.active' },
  },
  {
    value: PATIENT_WELLNESS.inactive,
    label: { id: 'shared.filters.wellness.inactive' },
  },
];

export const PATIENTS_TAB = {
  patients: 'patients',
  freeVisits: 'free_visits',
};
