import yup from 'lib/yupLocalised';

import {
  MIN_PAGOPAR_AMOUNT,
  MAX_PAGOPAR_AMOUNT,
  OPTION_OTHER,
} from 'constants/vouchers';

export default yup.object().shape({
  amount: yup.string().required(),
  otherAmount: yup.string().when('amount', {
    is: amount => amount === OPTION_OTHER,
    then: yup.string().currencyFromTo(MIN_PAGOPAR_AMOUNT, MAX_PAGOPAR_AMOUNT).required(),
  }),
});
