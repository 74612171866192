import { createLogic } from 'redux-logic';
import { equals } from 'ramda';

import { VOUCHERS_TAB } from 'constants/vouchers';

import requestErrorHandler from 'lib/requestErrorHandler';

import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { resetFiltersParams } from 'state/concepts/userProfile/filters/actions';
import { setCurrentPage as vouchersBatchesSetCurrentPage, filterVouchersBatches } from 'state/concepts/userProfile/vouchersBatches/actions';
import { setCurrentPage as vouchersSetCurrentPage, filterVouchers } from '../actions';
import { activeTabSelector } from '../selectors';
import { generateVouchersEndpoint } from '../endpoints';
import { GENERATE_VOUCHERS } from '../types';

const generateVouchersOperation = createLogic({
  type: GENERATE_VOUCHERS,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const { url, endpoint } = generateVouchersEndpoint;
    const state = getState();
    const activeTab = activeTabSelector(state);

    const params = {
      ...values,
    };

    try {
      await httpClient.post(url, params);

      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.vouchersGenerated',
          values: { count: values.number },
        },
      }));

      if (equals(activeTab, VOUCHERS_TAB.batches)) {
        dispatch(resetFiltersParams());
        dispatch(vouchersBatchesSetCurrentPage(1));
        dispatch(filterVouchersBatches({ name: '' }));
      } else {
        dispatch(vouchersSetCurrentPage(1));
        dispatch(filterVouchers({ name: '' }));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default generateVouchersOperation;
