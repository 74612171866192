import { createLogic } from 'redux-logic';

import isPresent from 'utils/isPresent';
import { isRevenueVirtualType, isRevenueIndependentType } from 'utils/revenue';

import {
  downloadRevenue,
  downloadRevenueVirtualClinicAppointments,
  downloadRevenueIndependentDoctorAppointments,
} from '../actions';
import {
  revenueTypeSelector,
  revenueVirtualClinicIdSelector,
} from '../selectors';
import { GET_DOWNLOAD_REVENUE } from '../types';

const getDownloadRevenueOperation = createLogic({
  type: GET_DOWNLOAD_REVENUE,
  latest: true,

  async process({ action: { intlFormatMessage }, getState }, dispatch, done) {
    const state = getState();
    const revenueType = revenueTypeSelector(state);
    const clinicId = revenueVirtualClinicIdSelector(state);

    if (isRevenueVirtualType(revenueType) && isPresent(clinicId)) {
      dispatch(downloadRevenueVirtualClinicAppointments(intlFormatMessage));
    } else if (isRevenueIndependentType(revenueType)) {
      dispatch(downloadRevenueIndependentDoctorAppointments(intlFormatMessage));
    } else {
      dispatch(downloadRevenue(intlFormatMessage));
    }

    done();
  },
});

export default getDownloadRevenueOperation;
