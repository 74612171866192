import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { is } from 'ramda';

import SvgIcon from 'views/shared/SvgIcon';

const RadioFieldComponent = ({
  className,
  disabled,
  options,
  field,
  onChange,
}) => {
  const labelClassNames = classNames(
    className,
    'main-radio',
    {
      'main-radio--disabled': disabled,
    },
  );

  return (
    <>
      {
        options.map(({ label, value, hint }) => (
          <li className="dropdown-list__item" key={value}>
            <label htmlFor={value} className={labelClassNames}>
              <input
                className="main-radio__input"
                type="radio"
                id={value}
                value={value}
                checked={value === field.value}
                onChange={onChange}
                disabled={disabled}
                name={field.name}
              />
              <SvgIcon icon={value === field.value ? 'circle-checked' : 'circle-o'} className="main-radio__icon" />
              {
                label && (
                  <span className="main-radio__text">
                    {is(Object, label) ? <FormattedMessage {...label} /> : label}
                  </span>
                )
              }
            </label>
            {hint && (
              <p className="ml-32 mb-20">
                <FormattedMessage {...hint} />
              </p>
            )}
          </li>
        ))
      }
    </>
  );
};

RadioFieldComponent.defaultProps = {
  className: null,
  disabled: false,
};

RadioFieldComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    value: PropTypes.string,
    hint: PropTypes.shape(),
  })).isRequired,
  field: PropTypes.shape().isRequired,
};

export default RadioFieldComponent;
