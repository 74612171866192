export const SYMPTOM_STATUSES = {
  all: '',
  active: 'active',
  inactive: 'inactive',
};

export const SYMPTOM_NAMES = {
  asymptomatic: 'asymptomatic',
};

export const SYMPTOM_STATUS = {
  fictional: 'fictional',
};

export const SYMPTOM_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: SYMPTOM_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: SYMPTOM_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
];

export const CREATE_SYMPTOM_STEPS = {
  details: 1,
  questionnaire: 2,
  specialities: 3,
};

export const SYMPTOM_DETAILS_TAB = {
  questionnaire: 'questionnaire',
  files: 'files',
};

export const SYMPTOM_TABS = {
  details: 'details',
  questionnaire: 'questionnaire',
  specialities: 'specialities',
};

export const QUESTION_TYPE = {
  radio: 'radio_button',
  checkbox: 'checkbox',
  yesNo: 'yes_no',
};

export const QUESTION_OPTIONS = [
  {
    value: QUESTION_TYPE.radio,
    key: QUESTION_TYPE.radio,
    label: { id: 'symptom.create.label.radio' },
  },
  {
    value: QUESTION_TYPE.checkbox,
    key: QUESTION_TYPE.checkbox,
    label: { id: 'symptom.create.label.checkbox' },
  },
  {
    value: QUESTION_TYPE.yesNo,
    key: QUESTION_TYPE.checkbox,
    label: { id: 'symptom.create.label.yesNo' },
  },
];

export const symptomDragTypes = {
  QUESTION: 'question',
  QUESTION_OPTION: 'questionOption',
};
