import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  deactivateClinicSpecialities,
  setSelectedClinicSpecialities as setSelectedClinicSpecialitiesAction,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import { deactivateClinicSpecialitiesLoadingSelector } from 'state/concepts/userProfile/clinicSpecialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ClinicSpecialitiesBulkDeactivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clinicSpecialitiesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedClinicSpecialities: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, clinicSpecialitiesIds, setSelectedClinicSpecialities } = this.props;
    onSubmit(clinicSpecialitiesIds);
    setSelectedClinicSpecialities([]);
  }

  render() {
    const { clinicSpecialitiesIds } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'clinicSpecialities.bulkDeactivate.modal.title' }}
        bodyText={[
          [{
            id: 'clinicSpecialities.bulkDeactivate.modal.body.start',
          },
          {
            id: 'clinicSpecialities.bulkDeactivate.modal.body.boldText',
            values: { count: clinicSpecialitiesIds.length },
            isBold: true,
          },
          {
            id: 'clinicSpecialities.bulkDeactivate.modal.body.final',
          }],
          {
            id: 'clinicSpecialities.bulkDeactivate.modal.activeBookings',
          },
        ]}
        submitText={{ id: 'shared.deactivate' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: deactivateClinicSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: deactivateClinicSpecialities,
  setSelectedClinicSpecialities: setSelectedClinicSpecialitiesAction,
};

export { ClinicSpecialitiesBulkDeactivateModal as ClinicSpecialitiesBulkDeactivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ClinicSpecialitiesBulkDeactivateModal);
