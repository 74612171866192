import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { path } from 'ramda';

import profileName from 'utils/profileName';

const DoctorInfoComponent = ({
  note,
}) => (
  <div className="view-note-table__footer">
    <div className="view-note-table__sign">
      <ul className="view-note-table__sign-list">
        <li className="view-note-table__sign-key">
          <FormattedMessage id="note.modal.label.doctor" />
        </li>
        <li className="view-note-table__sign-value">
          {profileName(path(['appointment', 'userProfile'], note))}
        </li>
      </ul>
      <ul className="view-note-table__sign-list">
        <li className="view-note-table__sign-key">
          <FormattedMessage id="note.modal.label.licenseNumber" />
        </li>
        <li className="view-note-table__sign-value">
          {path(['appointment', 'userProfile', 'licenseNumber'], note)}
        </li>
      </ul>
      {path(['appointment', 'userProfile', 'signatureUrls', 'small'], note) && (
        <ul className="view-note-table__sign-list">
          <li className="view-note-table__sign-key">
            <FormattedMessage id="note.modal.label.signature" />
          </li>
          <li className="view-note-table__sign-value">
            <img
              className="view-note-table__sign-img"
              src={path(['appointment', 'userProfile', 'signatureUrls', 'small'], note)}
              alt=""
            />
          </li>
        </ul>
      )}
    </div>
  </div>
);

DoctorInfoComponent.propTypes = {
  note: PropTypes.shape().isRequired,
};

export default DoctorInfoComponent;
