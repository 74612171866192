import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';

import Attention from './Attention';
import CommonDiagnoses from './CommonDiagnoses';
import OtherDiagnoses from './OtherDiagnoses';

const DiseasesModalComponent = ({
  onClose,
  isAttentionVisible,
  isOtherDiagnosesVisible,
  onChangeOtherDiagnosesVisible,
  onSetDisease,
}) => (
  <Modal
    className="ant-modal-wrap__full ant-modal-transparent"
    onClose={onClose}
  >
    <div className="modal-diagnose-row">
      <div className="modal-diagnose">
        <div className="ant-modal__header">
          <h1 className="ant-modal__header-txt">
            <FormattedMessage id="note.diagnose" />
          </h1>
          <button onClick={onClose}>
            <SvgIcon icon="cross" className="ant-modal__close" />
          </button>
        </div>
        <div className="ant-modal__body">
          {!isOtherDiagnosesVisible
        ? (
          <CommonDiagnoses
            onChangeOtherDiagnosesVisible={onChangeOtherDiagnosesVisible}
            onSetDisease={onSetDisease}
          />
        )
        : (
          <OtherDiagnoses
            onChangeOtherDiagnosesVisible={onChangeOtherDiagnosesVisible}
            onSetDisease={onSetDisease}
          />
        )
      }
        </div>
      </div>
      {isAttentionVisible && <Attention onSetDisease={onSetDisease} />}
    </div>
  </Modal>
);

DiseasesModalComponent.defaultProps = {
  isAttentionVisible: false,
  isOtherDiagnosesVisible: false,
};

DiseasesModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSetDisease: PropTypes.func.isRequired,
  isAttentionVisible: PropTypes.bool,
  isOtherDiagnosesVisible: PropTypes.bool,
  onChangeOtherDiagnosesVisible: PropTypes.func.isRequired,
};

export default DiseasesModalComponent;
