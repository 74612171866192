import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';
import { propOr } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { revenueVirtualClinicIdSelector, revenueVirtualClinicSelector, revenueFiltersSelector } from '../selectors';
import {
  downloadRevenueVirtualClinicDoctorsEndpoint,
  downloadRevenueIndependentDoctorsEndpoint,
} from '../endpoints';
import { DOWNLOAD_REVENUE_DOCTORS } from '../types';

const downloadRevenueDoctorsOperation = createLogic({
  type: DOWNLOAD_REVENUE_DOCTORS,
  latest: true,

  async process({ action: { intlFormatMessage }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const clinicId = revenueVirtualClinicIdSelector(state);
    const { startDate, endDate } = revenueFiltersSelector(state);
    const { endpoint, url } = isPresent(clinicId)
      ? downloadRevenueVirtualClinicDoctorsEndpoint(clinicId)
      : downloadRevenueIndependentDoctorsEndpoint;

    const params = {
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const virtualClinic = revenueVirtualClinicSelector(state);
      const fileName = isPresent(clinicId)
        ? intlFormatMessage(
            { id: 'download.fileName.revenuesForVirtualClinic' },
            {
              clinicName: propOr('', 'name', virtualClinic),
              startDate,
              endDate,
            },
          )
        : intlFormatMessage({ id: 'download.fileName.revenueForIndependentDoctors' }, { startDate, endDate });

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(data, fileName);
      dispatch(showNotification({
        messageObject: { id: 'notifications.fileHasBeenDownloaded' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadRevenueDoctorsOperation;
