import React from 'react';
import PropTypes from 'prop-types';

import redirect from 'utils/redirect';
import { isDoctor, isVirtualClinicAdmin, isVirtualClinicDoctor } from 'utils/roles';

import AppointmentCalendarTooltipConsultantDetailsComponent from './component';

class AppointmentCalendarTooltipConsultantDetails extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      roleName: PropTypes.string.isRequired,
    }).isRequired,
    userProfile: PropTypes.shape({
      id: PropTypes.string.isRequired,
      roleName: PropTypes.string.isRequired,
    }).isRequired,
  }

  get profileLink() {
    const { currentUser, userProfile } = this.props;

    if (isDoctor(currentUser.roleName)) {
      return '/profile';
    }

    if (isVirtualClinicAdmin(currentUser.roleName)) {
      return `/doctors_and_availability/doctors/${userProfile.id}/view`;
    }

    return isVirtualClinicDoctor(userProfile.roleName)
      ? `/virtual_clinics/doctors/${userProfile.id}/view`
      : `/independent_doctors/doctors/${userProfile.id}/view`;
  }

  handleRedirectToUserProfile = () => {
    const { onClose } = this.props;

    onClose();
    redirect({ href: this.profileLink });
  }

  render = () => (
    <AppointmentCalendarTooltipConsultantDetailsComponent
      {...this.props}
      onRedirectToUserProfile={this.handleRedirectToUserProfile}
    />
  );
}

export default AppointmentCalendarTooltipConsultantDetails;
