import {
  updateTutorialStepAvailabilityEndpoint,
  updateTutorialStepClinicSpecialitiesEndpoint,
  updateTutorialStepInviteVirtualClinicDoctorEndpoint,
} from 'state/concepts/userProfile/tutorial/endpoints';

export const TUTORIAL_STEPS = {
  availability: 'availability',
  clinicSpecialities: 'clinicSpecialities',
  inviteVirtualClinicDoctor: 'inviteVirtualClinicDoctor',
};

const availability = {
  name: TUTORIAL_STEPS.availability,
  route: '/availability/date_availability',
  endpoint: updateTutorialStepAvailabilityEndpoint,
};

const clinicSpecialities = {
  name: TUTORIAL_STEPS.clinicSpecialities,
  route: '/clinic_specialities',
  endpoint: updateTutorialStepClinicSpecialitiesEndpoint,
};

const virtualClinicDoctors = {
  name: TUTORIAL_STEPS.inviteVirtualClinicDoctor,
  route: '/doctors_and_availability/doctors',
  endpoint: updateTutorialStepInviteVirtualClinicDoctorEndpoint,
};

export const INDEPENDENT_DOCTOR_TUTORIAL_STEPS = [availability, clinicSpecialities];
export const VIRTUAL_CLINIC_ADMIN_TUTORIAL_STEPS = [clinicSpecialities, virtualClinicDoctors];

export const EXCLUDED_PATHS = [
  [/clinic_specialities\/create$/, /\/clinic_specialities$/],
];
