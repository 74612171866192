import endpoint from 'utils/endpoint';
import {
  appointmentsRoute,
  appointmentCancelRoute,
  downloadAppointmentsRoute,
} from 'lib/apiRoutes';
import {
  FETCH_APPOINTMENTS,
  CANCEL_APPOINTMENTS,
  DOWNLOAD_APPOINTMENTS_CSV,
} from './types';

export const fetchAppointmentsEndpoint = endpoint(FETCH_APPOINTMENTS, 'GET', appointmentsRoute);
export const cancelAppointmentEndpoint = appointmentId => endpoint(CANCEL_APPOINTMENTS, 'POST', appointmentCancelRoute(appointmentId));
export const downloadAppointmentsCsvEndpoint = endpoint(DOWNLOAD_APPOINTMENTS_CSV, 'GET', downloadAppointmentsRoute);
