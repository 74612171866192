import fetchNotifications from './fetchNotifications';
import receiveNotification from './receiveNotification';
import markNotificationAsRead from './markNotificationAsRead';
import markNotificationAsUnread from './markNotificationAsUnread';
import markNotificationsAsRead from './markNotificationsAsRead';
import removeNotification from './removeNotification';
import fetchNotificationSettings from './fetchNotificationSettings';
import updateNotificationSettings from './updateNotificationSettings';

export default [
  fetchNotifications,
  receiveNotification,
  markNotificationAsRead,
  markNotificationAsUnread,
  markNotificationsAsRead,
  removeNotification,
  fetchNotificationSettings,
  updateNotificationSettings,
];
