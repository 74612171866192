import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import Spinner from 'views/shared/Spinner';

const LoadMoreLoaderComponent = ({
  isLoading,
  topOffset,
  onLoadMore,
}) => (isLoading
  ? <Spinner isBig />
  : (
    <Waypoint
      onEnter={onLoadMore}
      topOffset={topOffset}
    />
  )
);

LoadMoreLoaderComponent.defaultProps = {
  isLoading: undefined,
  topOffset: '-100px',
};

LoadMoreLoaderComponent.propTypes = {
  isLoading: PropTypes.bool,
  topOffset: PropTypes.string,
  onLoadMore: PropTypes.func.isRequired,
};

export default LoadMoreLoaderComponent;
