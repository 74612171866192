import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchDoctorProfile = doctorId => ({
  type: types.FETCH_DOCTOR_PROFILE,
  doctorId,
});

export const setDoctorProfile = doctorId => ({
  type: types.SET_DOCTOR_PROFILE_ID,
  doctorId,
});

export const resetDoctorProfile = () => ({
  type: types.RESET_DOCTOR_PROFILE_ID,
});

export const setDoctorListIds = ids => ({
  type: types.SET_DOCTOR_LIST_IDS,
  ids,
});

export const setDoctorListStatuses = statuses => ({
  type: types.SET_DOCTOR_LIST_STATUSES,
  statuses,
});

export const resetDoctorListStatuses = () => ({
  type: types.RESET_DOCTOR_LIST_STATUSES,
});

export const setDoctorListFilterParams = filterParams => ({
  type: types.SET_DOCTOR_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetDoctorListFilterParams = () => ({
  type: types.RESET_DOCTOR_LIST_FILTER_PARAMS,
});

export const setDoctorListClinicSpecialityId = clinicSpecialityId => ({
  type: types.SET_DOCTOR_LIST_CLINIC_SPECIALITY_ID,
  clinicSpecialityId,
});

export const resetDoctorListClinicSpecialityId = () => ({
  type: types.RESET_DOCTOR_LIST_CLINIC_SPECIALITY_ID,
});

export const updateDoctorProfile = (values, form) => ({
  type: types.UPDATE_DOCTOR_PROFILE,
  values,
  form,
});

export const updateDoctorAccount = makeFormSubmitAction(types.UPDATE_DOCTOR_ACCOUNT);
export const inviteDoctors = makeFormSubmitAction(types.INVITE_DOCTORS);
export const submitPlatformCommission = makeFormSubmitAction(types.SUBMIT_PLATFORM_COMMISSION);

export const uploadDoctorAvatar = (avatar, isImageUrl) => {
  const formData = new FormData();
  formData.append('avatar', avatar, avatar.name);

  return {
    type: types.UPLOAD_DOCTOR_AVATAR,
    data: formData,
    isImageUrl,
  };
};

export const removeDoctorAvatar = () => ({
  type: types.REMOVE_DOCTOR_AVATAR,
});

export const uploadDoctorSignature = (signature, isImageUrl) => {
  const formData = new FormData();
  formData.append('signature', signature, 'signature.png');

  return {
    type: types.UPLOAD_DOCTOR_SIGNATURE,
    data: formData,
    isImageUrl,
  };
};

export const removeDoctorSignature = () => ({
  type: types.REMOVE_DOCTOR_SIGNATURE,
});

export const setBlankState = isBlankState => ({
  type: types.SET_DOCTORS_BLANK_STATE,
  isBlankState,
});

export const setDoctorsIds = ids => ({
  type: types.SET_DOCTORS_IDS,
  ids,
});

export const addDoctorsToIdsList = ids => ({
  type: types.ADD_DOCTORS_TO_IDS_LIST,
  ids,
});

export const setDoctorsRoles = (roles) => ({
  type: types.SET_DOCTORS_ROLES,
  roles,
});

export const resetDoctorsRoles = () => ({
  type: types.RESET_DOCTORS_ROLES,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_DOCTORS_CURRENT_PAGE,
  pageNumber,
});

export const fetchDoctors = () => ({
  type: types.FETCH_ALL_DOCTORS,
});

export const resetDoctorListIds = () => ({
  type: types.RESET_DOCTOR_LIST_IDS,
});

export const fetchDoctorList = (lastId) => ({
  type: types.FETCH_DOCTORS_LIST,
  lastId,
});

export const filterDoctors = filters => ({
  type: types.SET_DOCTORS_FILTERS,
  filters,
});

export const filterDoctorList = filters => ({
  type: types.SET_DOCTOR_LIST_FILTERS,
  filters,
});

export const setSortOrder = sortKey => ({
  type: types.SET_DOCTORS_SORT,
  sortKey,
});

export const resendDoctorInvitation = id => ({
  type: types.RESEND_DOCTOR_INVITATION,
  id,
});

export const resetAuthentication = doctorId => ({
  type: types.RESET_AUTHENTICATION,
  doctorId,
});

export const removeDoctorInvitation = doctorId => ({
  type: types.REMOVE_DOCTOR_INVITATION,
  doctorId,
});

export const activateDoctor = (doctorId, form = null) => ({
  type: types.ACTIVATE_DOCTOR,
  doctorId,
  form,
});

export const deactivateDoctor = doctorId => ({
  type: types.DEACTIVATE_DOCTOR,
  doctorId,
});

export const fetchAssignedDoctors = clinicSpecialityId => ({
  type: types.FETCH_ASSIGNED_DOCTORS,
  clinicSpecialityId,
});

export const doctorAssignClinicSpecialitiesInitialState = doctor => ({
  type: types.DOCTOR_ASSIGN_CLINIC_SPECIALITIES_INITIAL_STATE,
  doctor,
});

export const doctorAssignClinicSpecialitiesResetState = () => ({
  type: types.DOCTOR_ASSIGN_CLINIC_SPECIALITIES_RESET_STATE,
});
