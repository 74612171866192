import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';
import { propOr } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import profileName from 'utils/profileName';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';

import {
  revenueVirtualClinicIdSelector,
  revenueVirtualClinicSelector,
  revenueFiltersSelector,
  selectedRevenueUserProfileSelector,
} from '../selectors';
import {
  downloadRevenueVirtualClinicAppointmentsEndpoint,
  downloadRevenueVirtualClinicUserAppointmentsEndpoint,
} from '../endpoints';
import { DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS } from '../types';

const downloadRevenueVirtualClinicAppointmentsOperation = createLogic({
  type: DOWNLOAD_REVENUE_VIRTUAL_CLINIC_APPOINTMENTS,
  latest: true,

  async process({ action: { intlFormatMessage }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const clinicId = revenueVirtualClinicIdSelector(state);
    const { startDate, endDate, userProfileId } = revenueFiltersSelector(state);

    const { endpoint, url } = !isPresent(userProfileId)
      ? downloadRevenueVirtualClinicAppointmentsEndpoint(clinicId)
      : downloadRevenueVirtualClinicUserAppointmentsEndpoint(userProfileId);

    const params = {
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const userProfile = selectedRevenueUserProfileSelector(state);
      const virtualClinic = revenueVirtualClinicSelector(state);
      const fileName = isPresent(clinicId)
        ? intlFormatMessage(
            { id: 'download.fileName.revenueVirtualClinicAppointments' },
            {
              userProfileId: userProfileId ?? 0,
              userName: profileName(userProfile),
              licenseNumber: userProfile?.licenseNumber ?? 0,
              clinicName: propOr('', 'name', virtualClinic),
              startDate,
              endDate,
            },
          )
        : intlFormatMessage({ id: 'download.fileName.revenueForIndependentDoctors' }, { startDate, endDate });

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(data, fileName);
      dispatch(showNotification({
        messageObject: { id: 'notifications.fileHasBeenDownloaded' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadRevenueVirtualClinicAppointmentsOperation;
