import { combineReducers } from 'redux';
import { without } from 'ramda';

import { CREATE_SYMPTOM_STEPS } from 'constants/symptoms';

import * as types from './types';

const symptomIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SYMPTOMS:
      return action.symptomIds;
    default:
      return state;
  }
};

const questionIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_QUESTION_IDS:
      return action.questionIds;
    default:
      return state;
  }
};

const specialityIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SYMPTOM_SPECIALITY_IDS:
      return action.specialityIds;
    default:
      return state;
  }
};

const selectedQuestionPosition = (state = 0, action) => {
  switch (action.type) {
    case types.SET_SELECTED_QUESTION_POSITION:
      return action.selectedPosition;
    default:
      return state;
  }
};

const selectedSymptoms = (state = [], action) => {
  switch (action.type) {
    case types.SELECT_SYMPTOM:
      return state.includes(action.symptomId)
        ? without([action.symptomId], state)
        : [...state, action.symptomId];
    case types.SET_SYMPTOMS_SELECTED:
      return action.symptomIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_SYMPTOMS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_SYMPTOMS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_SYMPTOMS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const createSymptomStep = (state = CREATE_SYMPTOM_STEPS.details, action) => {
  switch (action.type) {
    case types.SET_CREATE_SYMPTOM_STEP:
      return action.step;
    default:
      return state;
  }
};

const symptomDetailsId = (state = '', action) => {
  switch (action.type) {
    case types.SET_SYMPTOM_DETAILS:
      return action.symptomId;
    default:
      return state;
  }
};

export default combineReducers({
  questionIds,
  symptomIds,
  specialityIds,
  selectedSymptoms,
  isBlankState,
  pagination,
  sort,
  createSymptomStep,
  symptomDetailsId,
  selectedQuestionPosition,
});
