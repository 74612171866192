import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'antd';
import { isEmpty } from 'ramda';

import { CLINIC_SPECIALITIES_STATUSES } from 'constants/clinicSpecialities';

import SelectField from 'views/shared/SelectField';
import LoadMoreLoader from 'views/shared/LoadMoreLoader';

import DropdownNotFoundContent from 'views/shared/DropdownNotFoundContent';

const DropdownClinicSpecialitiesFieldComponent = ({
  fetchClinicSpecialitiesList,
  isLoading,
  isOpen,
  isAllClinicSpecialitiesSelected,
  meta,
  options,
  optionGroupLabel,
  onDropdownVisibleChange,
  onCheckSelectedOption,
  onSearch,
  onFocus,
  intl,
  ...rest
}) => (
  <SelectField
    {...rest}
    onDropdownVisibleChange={onDropdownVisibleChange}
    onSearch={onSearch}
    onFocus={onFocus}
    optionLabelProp="label"
    open={isOpen}
    filterOption={false}
    showSearch
    mountToElement
  >
    {isEmpty(options) ? [] : (
      <Select.OptGroup label={intl.formatMessage(optionGroupLabel)}>
        {options.map(({ id, name, status }) => {
          const isHidden = onCheckSelectedOption(id);
          return (
            <Select.Option
              label={(
                <span className={classNames('multiselect-field__choice-item', {
                    'multiselect-field__choice-item--active': status === CLINIC_SPECIALITIES_STATUSES.active,
                    'multiselect-field__choice-item--inactive': status === CLINIC_SPECIALITIES_STATUSES.inactive,
                  })}
                >
                  {name}
                </span>
              )}
              title={name}
              value={id}
              key={id}
              disabled={isHidden}
              className={classNames({ 'd-none': isHidden })}
            >
              {name}
            </Select.Option>
          );
        })}
        <Select.Option value="" disabled className="multiselect-field__dropdown-item--loader">
          <LoadMoreLoader
            isLoading={isLoading}
            meta={meta}
            options={options}
            loadMore={fetchClinicSpecialitiesList}
          />
        </Select.Option>
      </Select.OptGroup>
    )}
    {isAllClinicSpecialitiesSelected && (
      <Select.Option value="" disabled>
        <DropdownNotFoundContent
          isLoading={isLoading}
          isAllOptionsSelected={isAllClinicSpecialitiesSelected}
          description="doctors.assignClinicSpecialities.modal.allSpecialitiesAdded"
        />
      </Select.Option>
    )}
  </SelectField>
);

DropdownClinicSpecialitiesFieldComponent.defaultProps = {
  isLoading: undefined,
  meta: {},
};

DropdownClinicSpecialitiesFieldComponent.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isAllClinicSpecialitiesSelected: PropTypes.bool.isRequired,
  meta: PropTypes.shape({ hasMore: PropTypes.bool }),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  optionGroupLabel: PropTypes.shape().isRequired,
  onDropdownVisibleChange: PropTypes.func.isRequired,
  onCheckSelectedOption: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  fetchClinicSpecialitiesList: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
};

export default DropdownClinicSpecialitiesFieldComponent;
