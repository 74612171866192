import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import updateDataHelper from 'utils/updateDataHelper';
import removeUserCookies from 'utils/removeUserCookies';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { deactivateUserEndpoint } from '../endpoints';
import { DEACTIVATE_USER } from '../types';

const deactivateUserOperation = createLogic({
  type: DEACTIVATE_USER,
  latest: true,

  async process({ httpClient, getState, action: { userId, form, values } }, dispatch, done) {
    const { url, endpoint } = deactivateUserEndpoint(userId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const state = getState();
      const params = { password: values.password };
      const response = updateDataHelper(
        state.data, 'userProfile', userId,
        { attributes: { active: false } },
      );

      await httpClient.post(url, params);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      removeUserCookies();

      await redirect({ href: '/login' });

      dispatch(showNotification({ messageObject: { id: 'notifications.accountDeactivated' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default deactivateUserOperation;
