import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { USER_TYPE } from 'constants/users';
import { IS_LOGGED_IN } from 'constants/auth';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess, setUserType } from '../actions';
import { patientsLoginByOneTimeTokenEndpoint } from '../endpoints';
import { PATIENT_LOGIN_BY_ONE_TIME_TOKEN } from '../types';

const loginByOneTimeTokenOperation = createLogic({
  type: PATIENT_LOGIN_BY_ONE_TIME_TOKEN,
  latest: true,

  async process({ rootHttpClient, action: { token } }, dispatch, done) {
    const { url, endpoint } = patientsLoginByOneTimeTokenEndpoint;

    const params = {
      token,
      include: 'health-profile,health-profile.allergies,child_profiles,patient_balance',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await rootHttpClient.post(url, params);

      const response = normalize(data);
      const currentPatient = build(response, 'patientProfile')[0];
      const tokens = data.meta.jwt;

      setUserCookies(currentPatient, tokens, USER_TYPE.patient, IS_LOGGED_IN);

      dispatch(userLoginSuccess(currentPatient.id));
      dispatch(setUserType(USER_TYPE.patient));
      dispatch(dataApiSuccess({ response, endpoint }));

      redirect({ href: '/patient' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default loginByOneTimeTokenOperation;
