import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { setRevenueVirtualClinicDoctorIds } from '../actions';
import { revenueVirtualClinicIdSelector, revenueFiltersSelector } from '../selectors';
import { fetchRevenueVirtualClinicDoctorsEndpoint } from '../endpoints';
import { FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS } from '../types';

const fetchRevenueVirtualClinicDoctorsOperation = createLogic({
  type: FETCH_REVENUE_VIRTUAL_CLINIC_DOCTORS,
  latest: true,

  async process({ httpClient, getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const clinicId = revenueVirtualClinicIdSelector(state);
    const { url, endpoint } = fetchRevenueVirtualClinicDoctorsEndpoint(clinicId);
    const { startDate, endDate } = revenueFiltersSelector(state);

    const params = {
      page: {
        after: lastId,
        size: 20,
      },
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRevenueVirtualClinicDoctorIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRevenueVirtualClinicDoctorsOperation;
