import PropTypes from 'prop-types';
import { Tree } from 'antd';

const TreeComponent = ({
  options,
  onExpand,
  onSelect,
  renderTreeNodes,
}) => (
  <Tree
    onExpand={onExpand}
    onSelect={onSelect}
  >
    {renderTreeNodes(options)}
  </Tree>
);

TreeComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderTreeNodes: PropTypes.func.isRequired,
};

export default TreeComponent;
