import { createLogic } from 'redux-logic';
import { DateTime } from 'luxon';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { decUnreadNotificationsCount } from '../actions';
import { unreadNotificationsCountSelector } from '../selectors';
import { MARK_NOTIFICATION_AS_READ } from '../types';
import { markNotificationAsReadEndpoint } from '../endpoints';

const markNotificationAsReadOperation = createLogic({
  type: MARK_NOTIFICATION_AS_READ,
  latest: true,

  async process({ httpClient, getState, action: { notificationId } }, dispatch, done) {
    const { endpoint, url } = markNotificationAsReadEndpoint(notificationId);
    const state = getState();
    const unreadCount = unreadNotificationsCountSelector(state);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url);

      const response = updateDataHelper(
        state.data, 'notification', notificationId,
        { attributes: { readAt: DateTime.utc() } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(decUnreadNotificationsCount(unreadCount));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default markNotificationAsReadOperation;
