import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  downloadRevenueDoctors as downloadRevenueDoctorsAction,
  fetchRevenueVirtualClinicDoctors as fetchRevenueVirtualClinicDoctorsAction,
  resetRevenueVirtualClinicDoctorIds as resetRevenueVirtualClinicDoctorIdsAction,
} from 'state/concepts/userProfile/revenue/actions';
import {
  revenueVirtualClinicDoctorsSelector,
} from 'state/concepts/userProfile/revenue/selectors';

import DoctorRevenueModalComponent from './component';

class DoctorRevenueModal extends React.Component {
  static propTypes = {
    intl: PropTypes.shape().isRequired,
    fetchRevenueVirtualClinicDoctors: PropTypes.func.isRequired,
    resetRevenueVirtualClinicDoctorIds: PropTypes.func.isRequired,
    downloadRevenueDoctors: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const { fetchRevenueVirtualClinicDoctors } = this.props;

    fetchRevenueVirtualClinicDoctors();
  };

  componentWillUnmount = () => {
    const { resetRevenueVirtualClinicDoctorIds } = this.props;

    resetRevenueVirtualClinicDoctorIds();
  };

  handleDownloadCsv = () => {
    const { downloadRevenueDoctors, intl } = this.props;

    downloadRevenueDoctors(intl.formatMessage);
  }

  render = () => (
    <DoctorRevenueModalComponent
      {...this.props}
      onDownloadCsv={this.handleDownloadCsv}
    />
  )
}

const mapStateToProps = state => ({
  doctors: revenueVirtualClinicDoctorsSelector(state),
});

const mapDispatchToProps = {
  downloadRevenueDoctors: downloadRevenueDoctorsAction,
  fetchRevenueVirtualClinicDoctors: fetchRevenueVirtualClinicDoctorsAction,
  resetRevenueVirtualClinicDoctorIds: resetRevenueVirtualClinicDoctorIdsAction,
};

export { DoctorRevenueModal as DoctorRevenueModalContainer };
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DoctorRevenueModal));
