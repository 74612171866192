import { createSelector } from 'reselect';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, totalCountSelector, pageCountSelector } from 'state/data/selectors';
import { fetchTransactionsEndpoint } from './endpoints';

const dataSelector = prop('data');

export const transactionsIdsSelector = path(['transactions', 'transactionsIds']);
export const transactionsSelectedTabSelector = path(['transactions', 'transactionsSelectedTab']);
export const sortSelector = path(['transactions', 'sort']);
export const paginationSelector = path(['transactions', 'pagination']);

export const transactionsSelector = createSelector(
  transactionsIdsSelector,
  dataSelector,
  buildCollection('transaction'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const transactionsLoadingSelector = (state) => (
  loadingSelector(state, fetchTransactionsEndpoint.endpoint)
);

export const transactionsTotalCountSelector = state => (
  totalCountSelector(state, fetchTransactionsEndpoint.endpoint)
);

export const transactionsPageCountSelector = state => (
  pageCountSelector(state, fetchTransactionsEndpoint.endpoint)
);
