import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import yup from 'lib/yupLocalised';

import { MAX_TEXTAREA_CONTENT_SHORT_LENGTH } from 'constants';

import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { cancelAppointment } from 'state/concepts/userProfile/appointments/actions';

import CancelFormModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class CancelFormModal extends React.Component {
  static propTypes = {
    appointmentId: PropTypes.string.isRequired,
  };

  render = () => (
    <CancelFormModalComponent
      {...this.props}
    />
  );
}

const mapDispatchToProps = {
  onSubmit: cancelAppointment,
};

export { CancelFormModal as CancelFormModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ appointmentId }) => ({
      id: appointmentId,
      cancellationReason: '',
    }),
    validationSchema: yup.object().shape({
      cancellationReason: yup.string().max(MAX_TEXTAREA_CONTENT_SHORT_LENGTH).required(),
    }),
    handleSubmit: handleSubmitWithProps(['notificationShown']),
  }),
)(CancelFormModal);
