import PropTypes from 'prop-types';

import Modal from 'views/shared/Modal';
import PatientAppointment from 'views/shared/AppointmentCalendar/Tooltip/TooltipContent';

const PatientAppointmentModalComponent = ({
  onClose,
  onReschedule,
  onCancel,
  appointment,
}) => (
  <Modal className="main-modal modal-appointment" onClose={onClose}>
    <PatientAppointment
      onClose={onClose}
      onReschedule={onReschedule}
      onCancel={onCancel}
      event={appointment}
    />
  </Modal>
);

PatientAppointmentModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appointment: PropTypes.shape().isRequired,
};

export default PatientAppointmentModalComponent;
