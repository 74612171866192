import { createLogic } from 'redux-logic';
import { dec, length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';

import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { fetchSpecialities, setCurrentPage } from '../actions';
import { specialitiesTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeSpecialitiesEndpoint } from '../endpoints';
import { REMOVE_SPECIALITIES } from '../types';

const removeSpecialitiesOperation = createLogic({
  type: REMOVE_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState, action: { specialityIds } }, dispatch, done) {
    const { url, endpoint } = removeSpecialitiesEndpoint;
    const state = getState();
    const count = length(specialityIds);
    const totalCount = specialitiesTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, count);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.delete(url, { params: { speciality_ids: specialityIds } });

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.specialitiesDeleted',
          values: { count },
        },
      }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchSpecialities());
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.specialitiesDeletedError',
          values: { count },
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }
    done();
  },
});

export default removeSpecialitiesOperation;
