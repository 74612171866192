import { combineReducers } from 'redux';
import { union } from 'ramda';

import * as types from './types';

const pushNotificationsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATIONS_IDS:
      return action.pushNotificationsIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATIONS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATIONS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const clinicsListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATION_CLINICS_IDS:
      return union(state, action.clinicsListIds);
    default:
      return state;
  }
};

const clinicsInitialState = { name: '', excludeClinicsIds: [] };
const clinicsListFilterParams = (state = clinicsInitialState, action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_PUSH_NOTIFICATION_CLINICS_LIST_FILTER_PARAMS:
      return clinicsInitialState;
    default:
      return state;
  }
};

const patientsListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATION_PATIENTS_IDS:
      return union(state, action.patientsListIds);
    default:
      return state;
  }
};

const patientsInitialState = { name: '', excludePatientsIds: [] };
const patientsListFilterParams = (state = patientsInitialState, action) => {
  switch (action.type) {
    case types.SET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_PUSH_NOTIFICATION_PATIENTS_LIST_FILTER_PARAMS:
      return patientsInitialState;
    default:
      return state;
  }
};

const confirmModalVisible = (state = false, action) => {
  switch (action.type) {
    case types.TOGGLE_PUSH_NOTIFICATION_CONFIRM_MODAL_VISIBLE:
      return !state;
    default:
      return state;
  }
};

const pushNotificationsReducer = combineReducers({
  pushNotificationsIds,
  isBlankState,
  pagination,
  clinicsListIds,
  clinicsListFilterParams,
  patientsListIds,
  patientsListFilterParams,
  confirmModalVisible,
});

export default pushNotificationsReducer;
