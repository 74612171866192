import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { resetAuthentication } from 'state/concepts/userProfile/doctors/actions';
import { resetAuthDoctorLoadingSelector } from 'state/concepts/userProfile/doctors/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ResetAuthSettingsDoctorModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    doctor: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, doctor: { id } } = this.props;

    onSubmit(id);
  }

  render() {
    const { doctor } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'doctors.resetAuthSettings.title' }}
        bodyText={[{
          id: 'doctors.resetAuthSettings.subTitle',
          values: { name: <span className="f-700">{profileName(doctor)}</span> },
        }]}
        submitText={{ id: 'shared.confirm' }}
        submitClassName="w-136"
      />
    );
  }
}

const mapStateToProps = (state, { doctor: { id } }) => ({
  isLoading: resetAuthDoctorLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: resetAuthentication,
};

export { ResetAuthSettingsDoctorModal as ResetAuthSettingsDoctorModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ResetAuthSettingsDoctorModal);
