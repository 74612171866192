import yup from 'lib/yupLocalised';

import {
  MIN_FREE_VISITS_COUNT,
  MAX_FREE_VISITS_COUNT,
} from 'constants/freeVisits';

export default yup.object().shape({
  clinicId: yup.string().required(),
  patientProfileId: yup.string().required(),
  freeVisitsCount: yup.number().min(MIN_FREE_VISITS_COUNT).max(MAX_FREE_VISITS_COUNT).required(),
});
