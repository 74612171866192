import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchSpecialities = () => ({
  type: types.FETCH_SPECIALITIES,
});

export const setSpecialities = specialityIds => ({
  type: types.SET_SPECIALITIES,
  specialityIds,
});

export const setBlankState = isBlankState => ({
  type: types.SET_SPECIALITIES_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_SPECIALITIES_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_SPECIALITIES_SORT_ORDER,
  sortKey,
});

export const selectSpeciality = specialityId => ({
  type: types.SELECT_SPECIALITY,
  specialityId,
});

export const setSelectedSpecialities = specialityIds => ({
  type: types.SET_SPECIALITIES_SELECTED,
  specialityIds,
});

export const filterSpecialities = filters => ({
  type: types.SET_SPECIALITIES_FILTER,
  filters,
});

export const activateSpecialities = specialityIds => ({
  type: types.ACTIVATE_SPECIALITIES,
  specialityIds,
});

export const deactivateSpecialities = specialityIds => ({
  type: types.DEACTIVATE_SPECIALITIES,
  specialityIds,
});

export const removeSpecialities = specialityIds => ({
  type: types.REMOVE_SPECIALITIES,
  specialityIds,
});

export const getSpeciality = specialityId => ({
  type: types.GET_SPECIALITY,
  specialityId,
});

export const getSpecialitySymptoms = specialityId => ({
  type: types.GET_SPECIALITY_SYMPTOMS,
  specialityId,
});

export const setSpecialitySymptomsIds = symptomsIds => ({
  type: types.SET_SPECIALITY_SYMPTOMS_IDS,
  symptomsIds,
});

export const resetSpecialitySymptomsIds = () => ({
  type: types.RESET_SPECIALITY_SYMPTOMS_IDS,
});

export const createSpeciality = makeFormSubmitAction(types.CREATE_SPECIALITY);
export const updateSpeciality = makeFormSubmitAction(types.UPDATE_SPECIALITY);

export const fetchSpecialitiesList = lastId => ({
  type: types.FETCH_SPECIALITIES_LIST,
  lastId,
});

export const filterSpecialitiesList = filters => ({
  type: types.SET_SPECIALITIES_LIST_FILTERS,
  filters,
});

export const setSpecialitiesListIds = ids => ({
  type: types.SET_SPECIALITIES_LIST_IDS,
  ids,
});

export const addSpecialitiesListIds = ids => ({
  type: types.ADD_SPECIALITIES_LIST_IDS,
  ids,
});

export const resetSpecialitiesListIds = () => ({
  type: types.RESET_SPECIALITIES_LIST_IDS,
});

export const setSpecialitiesListStatuses = statuses => ({
  type: types.SET_SPECIALITIES_LIST_STATUSES,
  statuses,
});

export const resetSpecialitiesListStatuses = () => ({
  type: types.RESET_SPECIALITIES_LIST_STATUSES,
});

export const setSpecialitiesListFilterParams = filterParams => ({
  type: types.SET_SPECIALITIES_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetSpecialitiesListFilterParams = () => ({
  type: types.RESET_SPECIALITIES_LIST_FILTER_PARAMS,
});
