import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const ForgotPasswordModalComponent = ({
  onClose,
  onSubmit,
  title,
  bodyText,
  submitText,
  dismissText,
  isLoading,
}) => (
  <Modal onClose={onClose} wrapClassName="ant-modal-wrap__6">
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage {...title} />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <p className="manage-account__modal-txt mb-24">
        <FormattedMessage {...bodyText} />
      </p>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={dismissText}
        kind="tertiary"
        onClick={onClose}
      />
      <Button
        className="w-160"
        text={submitText}
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </div>
  </Modal>
);

ForgotPasswordModalComponent.defaultProps = {
  isLoading: undefined,
};

ForgotPasswordModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.shape().isRequired,
  bodyText: PropTypes.shape().isRequired,
  submitText: PropTypes.shape().isRequired,
  dismissText: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
};

export default ForgotPasswordModalComponent;
