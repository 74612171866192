import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';

const NextArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="modal-files__arrow modal-files__arrow--right"
  >
    <SvgIcon icon="arrow-right" />
  </button>
);

NextArrow.defaultProps = {
  onClick: undefined,
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

export default NextArrow;
