const getHorizontalCrop = (image, offset) => {
  const cropHeight = image.height - offset * 2;
  const cropX = (image.width - cropHeight) / 2;

  return {
    width: cropHeight,
    height: cropHeight,
    x: cropX,
    y: offset,
    aspect: 1,
    unit: 'px',
  };
};

const getVerticalCrop = (image, offset) => {
  const cropWidth = image.width - offset * 2;
  const cropY = (image.height - cropWidth) / 2;

  return {
    width: cropWidth,
    height: cropWidth,
    x: offset,
    y: cropY,
    aspect: 1,
    unit: 'px',
  };
};

export const getSquareCrop = (image, offset = 10) => (
  image.width > image.height ? getHorizontalCrop(image, offset) : getVerticalCrop(image, offset)
);

export const getRectangleCrop = (image) => {
  const aspect = 16 / 9;
  const width = image.width / aspect < image.height * aspect
    ? image.width : ((image.height * aspect) / image.width) * image.width;
  const height = image.width / aspect > image.height * aspect
    ? image.height : (image.width / aspect / image.height) * image.height;
  const y = (image.height - height) / 2;
  const x = (image.width - width) / 2;

  return {
    width,
    height,
    x,
    y,
    aspect,
    unit: 'px',
  };
};
