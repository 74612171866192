import { createSelector } from 'reselect';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, pageCountSelector } from 'state/data/selectors';
import { fetchVirtualClinicPatientAppointmentsEndpoint } from './endpoints';

const dataSelector = prop('data');

export const patientAppointmentsIdsSelector = path(['clinicPatient', 'patientAppointmentsIds']);
export const sortSelector = (state, key) => path(['clinicPatient', key])(state);
export const paginationSelector = path(['clinicPatient', 'pagination']);

export const patientAppointmentsSelector = createSelector(
  patientAppointmentsIdsSelector,
  dataSelector,
  buildCollection('appointment'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const patientAppointmentsLoadingSelector = (state, id) => (
  loadingSelector(state, fetchVirtualClinicPatientAppointmentsEndpoint(id).endpoint)
);

export const patientAppointmentsPageCountSelector = (state, id) => (
  pageCountSelector(state, fetchVirtualClinicPatientAppointmentsEndpoint(id).endpoint)
);
