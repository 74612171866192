import { combineReducers } from 'redux';

import * as types from './types';

const recoveryCodeIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_RECOVERY_CODE_IDS:
      return action.ids;
    default:
      return state;
  }
};

const isRecoveryCodesSaved = (state = false, action) => {
  switch (action.type) {
    case types.SET_RECOVERY_CODES_SAVED:
      return action.isSaved;
    default:
      return state;
  }
};

const provisioningUri = (state = '', action) => {
  switch (action.type) {
    case types.SET_PROVISIONING_URI:
      return action.uri;
    default:
      return state;
  }
};

export default combineReducers({
  recoveryCodeIds,
  isRecoveryCodesSaved,
  provisioningUri,
});
