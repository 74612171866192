import endpoint from 'utils/endpoint';

import {
  patientRoute,
  patientsAppointmentsRoute,
  patientNotesRoute,
  patientsActivateRoute,
  patientsBanRoute,
  patientChildProfileRoute,
} from 'lib/apiRoutes';

import {
  FETCH_PATIENT,
  FETCH_PATIENT_APPOINTMENTS,
  FETCH_PATIENT_NOTES,
  ACTIVATE_PATIENT,
  BAN_PATIENT,
  SUBMIT_PATIENT_PROFILE,
  SUBMIT_CHILDREN_PROFILE,
} from './types';

export const fetchPatientEndpoint = patientId => endpoint(FETCH_PATIENT, 'GET', patientRoute(patientId));
export const fetchPatientAppointmentsEndpoint = patientId => endpoint(FETCH_PATIENT_APPOINTMENTS, 'GET', patientsAppointmentsRoute(patientId));
export const fetchPatientNotesEndpoint = patientId => endpoint(FETCH_PATIENT_NOTES, 'GET', patientNotesRoute(patientId));
export const activatePatientEndpoint = patientId => endpoint(ACTIVATE_PATIENT, 'PUT', patientsActivateRoute(patientId));
export const banPatientEndpoint = patientId => endpoint(BAN_PATIENT, 'PUT', patientsBanRoute(patientId));
export const submitPatientProfileEndpoint = patientId => endpoint(SUBMIT_PATIENT_PROFILE, 'PUT', patientRoute(patientId));
export const submitChildrenProfileEndpoint = patientId => endpoint(SUBMIT_CHILDREN_PROFILE, 'PUT', patientChildProfileRoute(patientId));
