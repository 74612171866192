import endpoint from 'utils/endpoint';
import {
  dashboardsRoute,
  validateDashboardsRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchDashboardsEndpoint = endpoint(types.FETCH_DASHBOARDS, 'GET', dashboardsRoute);
export const validateDashboardsEndpoint = endpoint(types.VALIDATE_DASHBOARDS, 'GET', validateDashboardsRoute);
