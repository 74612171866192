import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchVouchers = () => ({
  type: types.FETCH_VOUCHERS,
});

export const setVouchers = voucherIds => ({
  type: types.SET_VOUCHERS,
  voucherIds,
});

export const fetchVoucherDetails = voucherId => ({
  type: types.FETCH_VOUCHER_DETAILS,
  voucherId,
});

export const setBlankState = isBlankState => ({
  type: types.SET_VOUCHERS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_VOUCHERS_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_VOUCHERS_SORT_ORDER,
  sortKey,
});

export const selectVoucher = voucherId => ({
  type: types.SELECT_VOUCHER,
  voucherId,
});

export const setSelectedVouchers = voucherIds => ({
  type: types.SET_VOUCHERS_SELECTED,
  voucherIds,
});

export const filterVouchers = filters => ({
  type: types.SET_VOUCHERS_FILTER,
  filters,
});

export const removeVouchers = voucherIds => ({
  type: types.REMOVE_VOUCHERS,
  voucherIds,
});

export const downloadVouchersCsv = () => ({
  type: types.DOWNLOAD_VOUCHERS_CSV,
});

export const setActiveTab = tabName => ({
  type: types.SET_VOUCHERS_ACTIVE_TAB,
  tabName,
});

export const generateVouchers = makeFormSubmitAction(types.GENERATE_VOUCHERS);
