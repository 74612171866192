const namespace = 'appointments';

export const FETCH_APPOINTMENTS = `${namespace}/FETCH_APPOINTMENTS`;
export const SET_APPOINTMENTS = `${namespace}/SET_APPOINTMENTS`;
export const FILTER_APPOINTMENTS = `${namespace}/FILTER_APPOINTMENTS`;
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`;
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`;
export const SET_APPOINTMENTS_DATE = `${namespace}/SET_APPOINTMENTS_DATE`;
export const SET_TIMEZONE = `${namespace}/SET_TIMEZONE`;
export const SET_APPOINTMENTS_VIEW = `${namespace}/SET_APPOINTMENTS_VIEW`;
export const REQUEST_RESCHEDULE = `${namespace}/REQUEST_RESCHEDULE`;
export const CANCEL_APPOINTMENTS = `${namespace}/CANCEL_APPOINTMENTS`;
export const SET_CANCELLATION_RULES = `${namespace}/SET_CANCELLATION_RULES`;
export const SET_CANCELLATION_ERROR = `${namespace}/SET_CANCELLATION_ERROR`;
export const RECEIVE_APPOINTMENT = `${namespace}/RECEIVE_APPOINTMENT`;
export const SET_LAST_OPENED_APPOINTMENT_TOOLTIP_ID = `${namespace}/SET_LAST_OPENED_APPOINTMENT_TOOLTIP_ID`;
export const CLEAR_LAST_OPENED_APPOINTMENT_TOOLTIP_ID = `${namespace}/CLEAR_LAST_OPENED_APPOINTMENT_TOOLTIP_ID`;
export const SET_APPOINTMENTS_BLANK_STATE = `${namespace}/SET_APPOINTMENTS_BLANK_STATE`;
export const DOWNLOAD_APPOINTMENTS_CSV = `${namespace}/DOWNLOAD_APPOINTMENTS_CSV`;
