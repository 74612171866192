import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Alert from 'views/shared/Alert';
import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import PasswordInputField from 'views/shared/PasswordInputField';
import SvgIcon from 'views/shared/SvgIcon';

const ConfirmSetupTwoFactorAuthModalComponent = ({
  onClose,
  handleSubmit,
  title,
  bodyText,
  submitText,
  dismissText,
  dirty,
  status,
}) => (
  <Modal onClose={onClose} wrapClassName="ant-modal-wrap__6">
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage {...title} />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <p className="manage-account__modal-txt mb-24">
        <FormattedMessage {...bodyText} />
      </p>
      {status && status.base && (
        <div className="main-card__fields main-card__fields--short">
          <div className="main-card__field-col">
            <Alert
              className="auth__alert"
              message={status.base}
            />
          </div>
        </div>
      )}
      <Field
        name="password"
        id="password"
        className="w-320"
        component={PasswordInputField}
        label={{ id: 'shared.password' }}
        icon="alert"
        visiblePassword
        autoComplete="new-password"
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={dismissText}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        className="w-160"
        text={submitText}
        onClick={handleSubmit}
        overrideKind={[null, 'primary']}
        disabled={!dirty}
      />
    </div>
  </Modal>
);

ConfirmSetupTwoFactorAuthModalComponent.defaultProps = {
  status: undefined,
};

ConfirmSetupTwoFactorAuthModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.shape().isRequired,
  bodyText: PropTypes.shape().isRequired,
  submitText: PropTypes.shape().isRequired,
  dismissText: PropTypes.shape().isRequired,
  dirty: PropTypes.bool.isRequired,
  status: PropTypes.shape(),
};

export default ConfirmSetupTwoFactorAuthModalComponent;
