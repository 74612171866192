import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { fetchVouchersBatchDetailsEndpoint } from '../endpoints';
import { FETCH_VOUCHERS_BATCH_DETAILS } from '../types';

const fetchVouchersBatchDetailsOperation = createLogic({
  type: FETCH_VOUCHERS_BATCH_DETAILS,
  latest: true,

  async process({ httpClient, action: { vouchersBatchId } }, dispatch, done) {
    const { endpoint, url } = fetchVouchersBatchDetailsEndpoint(vouchersBatchId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVouchersBatchDetailsOperation;
