import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const SwitchComponent = ({
  className,
  label,
  disabled,
  isChecked,
  onChange,
  name,
  id,
}) => (
  <label
    className={classNames('main-switch', className, {
      'main-switch--disabled': disabled,
    })}
  >
    {label && (
      <span className="main-switch__text">
        <FormattedMessage {...label} />
      </span>
    )}
    <input
      className="main-switch__input"
      type="checkbox"
      checked={isChecked}
      {...{ id, name, disabled, onChange }}
    />

    <span className="main-switch__icon" />
  </label>
);

SwitchComponent.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  id: null,
  name: null,
};

SwitchComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default SwitchComponent;
