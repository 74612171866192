import React from 'react';
import { connect } from 'react-redux';

import { completeDiseasesSelector, cancelDiseasesSelector } from 'state/concepts/userProfile/diseases/selectors';

import AttentionComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class Attention extends React.Component {
  render = () => (
    <AttentionComponent
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  completeDiseases: completeDiseasesSelector(state),
  cancelDiseases: cancelDiseasesSelector(state),
});

export { Attention as AttentionContainer };
export default connect(mapStateToProps)(Attention);
