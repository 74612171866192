import fetchVouchersBatches from './fetchVouchersBatches';
import fetchVouchersBatchDetails from './fetchVouchersBatchDetails';
import filterVouchersBatches from './filterVouchersBatches';
import updateVouchersBatchDetails from './updateVouchersBatchDetails';

export default [
  fetchVouchersBatches,
  fetchVouchersBatchDetails,
  filterVouchersBatches,
  updateVouchersBatchDetails,
];
