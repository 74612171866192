import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { symptomDetailsIdSelector } from '../selectors';
import { uploadSymptomImageEndpoint } from '../endpoints';
import { UPLOAD_SYMPTOM_IMAGE } from '../types';

const uploadSymptomImageOperation = createLogic({
  type: UPLOAD_SYMPTOM_IMAGE,
  latest: true,

  async process({
    action: {
      data,
    },
    httpClient, getState,
  }, dispatch, done) {
    const state = getState();
    const id = symptomDetailsIdSelector(state);
    const { url, endpoint } = uploadSymptomImageEndpoint(id);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data: responseData } = await httpClient.put(url, data, { headers: { 'content-type': 'multipart/form-data' } });
      const response = normalize(responseData);

      dispatch(showNotification({
        messageObject: { id: 'notifications.yourSymptomImageUpdated' },
      }));

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.yourSymptomImageUpdatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default uploadSymptomImageOperation;
