import { createLogic } from 'redux-logic';
import build from 'redux-object';
import normalize from 'json-api-normalizer';
import {
 filter, head, keys, length, pluck, propEq,
} from 'ramda';

import { dataApiSuccess } from 'state/data/actions';
import { removeMessageSuccess, setAttachments, setMessages } from '../actions';
import { RECEIVE_MESSAGES } from '../types';

const receiveMessagesOperation = createLogic({
  type: RECEIVE_MESSAGES,
  latest: true,

  async process({ action }, dispatch, done) {
    const { messages: messagesResponse } = action;
    const response = normalize(messagesResponse);
    const messages = build(response, 'message');
    const messageIds = pluck('id', messages);
    const attachmentsIds = keys(response.messageAttachment);
    const isDestroyed = propEq('destroyed', true, head(messages));

    dispatch(dataApiSuccess({ response }));
    dispatch(setMessages(messageIds));

    if (length(messages) === 1 && !isDestroyed) {
      const element = document.getElementById('chat-scroll');

      dispatch(setAttachments(attachmentsIds));

      element.scrollTop = element.scrollHeight;
    }

    const destroyedMessageIds = pluck('id', filter(propEq('destroyed', true), messages));
    if (length(destroyedMessageIds)) {
      dispatch(removeMessageSuccess(destroyedMessageIds));
    }

    done();
  },
});

export default receiveMessagesOperation;
