import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchSymptoms = () => ({
  type: types.FETCH_SYMPTOMS,
});

export const getSymptomSpecialities = symptomId => ({
  type: types.GET_SYMPTOM_SPECIALITIES,
  symptomId,
});

export const getSymptomDetails = symptomId => ({
  type: types.GET_SYMPTOM_DETAILS,
  symptomId,
});

export const getSymptomQuestionnaire = symptomId => ({
  type: types.GET_SYMPTOM_QUESTIONNAIRE,
  symptomId,
});

export const setSymptoms = symptomIds => ({
  type: types.SET_SYMPTOMS,
  symptomIds,
});

export const setSymptomSpecialityIds = specialityIds => ({
  type: types.SET_SYMPTOM_SPECIALITY_IDS,
  specialityIds,
});

export const setQuestionIds = questionIds => ({
  type: types.SET_QUESTION_IDS,
  questionIds,
});

export const setBlankState = isBlankState => ({
  type: types.SET_SYMPTOMS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_SYMPTOMS_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_SYMPTOMS_SORT_ORDER,
  sortKey,
});

export const selectSymptom = symptomId => ({
  type: types.SELECT_SYMPTOM,
  symptomId,
});

export const setSelectedSymptoms = symptomIds => ({
  type: types.SET_SYMPTOMS_SELECTED,
  symptomIds,
});

export const filterSymptoms = filters => ({
  type: types.SET_SYMPTOMS_FILTER,
  filters,
});

export const activateSymptoms = symptomIds => ({
  type: types.ACTIVATE_SYMPTOMS,
  symptomIds,
});

export const deactivateSymptoms = symptomIds => ({
  type: types.DEACTIVATE_SYMPTOMS,
  symptomIds,
});

export const setCreateSymptomStep = step => ({
  type: types.SET_CREATE_SYMPTOM_STEP,
  step,
});

export const setSymptomDetails = symptomId => ({
  type: types.SET_SYMPTOM_DETAILS,
  symptomId,
});

export const submitCreateSymptomDetails = makeFormSubmitAction(types.SUBMIT_CREATE_SYMPTOM_DETAILS);

export const createSymptomDetails = (values, form) => ({
  type: types.CREATE_SYMPTOM_DETAILS,
  values,
  form,
});

export const updateSymptomDetails = (values, form) => ({
  type: types.UPDATE_SYMPTOM_DETAILS,
  values,
  form,
});

export const uploadSymptomImage = image => {
  const formData = new FormData();
  formData.append('image', image, 'new_file.png');

  return {
    type: types.UPLOAD_SYMPTOM_IMAGE,
    data: formData,
  };
};

export const removeSymptomImage = () => ({
  type: types.REMOVE_SYMPTOM_IMAGE,
});

export const setSelectedQuestionPosition = (selectedPosition) => ({
  type: types.SET_SELECTED_QUESTION_POSITION,
  selectedPosition,
});

export const submitSymptomQuestionnaire = makeFormSubmitAction(types.SUBMIT_SYMPTOM_QUESTIONNAIRE);
export const submitNewSymptom = makeFormSubmitAction(types.SUBMIT_NEW_SYMPTOM);
