import { sessionOperations } from './session';
import { transactionsOperations } from './transactions';
import { patientAccountOperations } from './patientProfile/account';
import { patientResetPasswordOperations } from './patientProfile/resetPassword';
import { userOperations } from './userProfile/users';
import { doctorsOperations } from './userProfile/doctors';
import { onboardingOperations } from './userProfile/onboarding';
import { timezonesOperations } from './timezones';
import { signupOperations } from './userProfile/signup';
import { resetPasswordOperations } from './userProfile/resetPassword';
import { accountOperations } from './userProfile/account';
import { recoveryCodesOperations } from './userProfile/recoveryCodes';
import { clinicSpecialitiesOperations } from './userProfile/clinicSpecialities';
import { recurringAvailabilityOperations } from './userProfile/recurringAvailability';
import { dateAvailabilityOperations } from './userProfile/dateAvailability';
import { appointmentsOperations } from './userProfile/appointments';
import { appointmentOperations } from './userProfile/appointment';
import { filtersOperations } from './userProfile/filters';
import { patientsOperations } from './userProfile/patients';
import { patientOperations } from './userProfile/patient';
import { freeVisitsOperations } from './userProfile/freeVisits';
import { clinicPatientOperations } from './userProfile/clinicPatient';
import { joinRequestOperations } from './userProfile/joinRequests';
import { tutorialOperations } from './userProfile/tutorial';
import { chatOperations } from './userProfile/chat';
import { webSocketsOperations } from './userProfile/webSockets';
import { symptomsOperations } from './userProfile/symptoms';
import { specialitiesOperations } from './userProfile/specialities';
import { vouchersOperations } from './userProfile/vouchers';
import { vouchersBatchesOperations } from './userProfile/vouchersBatches';
import { diseasesOperations } from './userProfile/diseases';
import { notificationsOperations } from './userProfile/notifications';
import { videoConferenceOperations } from './userProfile/videoConference';
import { virtualClinicsOperations } from './userProfile/virtualClinics';
import { commissionOperations } from './userProfile/commissions';
import { virtualClinicDoctorsOperations } from './userProfile/virtualClinicDoctors';
import { revenueOperations } from './userProfile/revenue';
import { doctorProfileOperations } from './doctorProfile';
import { pushNotificationsOperations } from './userProfile/pushNotifications';
import { dashboardsOperations } from './userProfile/dashboards';

export default [
  ...sessionOperations,
  ...transactionsOperations,
  ...patientAccountOperations,
  ...patientResetPasswordOperations,
  ...userOperations,
  ...doctorsOperations,
  ...onboardingOperations,
  ...timezonesOperations,
  ...signupOperations,
  ...resetPasswordOperations,
  ...accountOperations,
  ...recoveryCodesOperations,
  ...clinicSpecialitiesOperations,
  ...recurringAvailabilityOperations,
  ...dateAvailabilityOperations,
  ...appointmentsOperations,
  ...appointmentOperations,
  ...filtersOperations,
  ...patientsOperations,
  ...patientOperations,
  ...freeVisitsOperations,
  ...clinicPatientOperations,
  ...joinRequestOperations,
  ...tutorialOperations,
  ...chatOperations,
  ...webSocketsOperations,
  ...symptomsOperations,
  ...specialitiesOperations,
  ...vouchersOperations,
  ...vouchersBatchesOperations,
  ...diseasesOperations,
  ...notificationsOperations,
  ...videoConferenceOperations,
  ...virtualClinicsOperations,
  ...commissionOperations,
  ...virtualClinicDoctorsOperations,
  ...doctorProfileOperations,
  ...revenueOperations,
  ...pushNotificationsOperations,
  ...dashboardsOperations,
];
