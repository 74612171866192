import { createLogic } from 'redux-logic';
import cookies from 'component-cookie';

import { CABLE_HOST } from 'constants';
import { WS_STATUSES } from 'constants/webSockets';

import { webSocketsAuthenticateRoute } from 'lib/apiRoutes';

import { parseChannel, parseData, toCommand } from 'utils/webSockets';

import { SET_OFFLINE } from 'state/concepts/userProfile/chat/types';
import { resetSubscriptionChannels, setConnectionStatus } from '../actions';
import { CLOSE_CONNECTION, OPEN_CONNECTION, SEND_COMMAND } from '../types';

const openConnection = createLogic({
  type: OPEN_CONNECTION,
  cancelType: CLOSE_CONNECTION,
  latest: true,
  warnTimeout: 0,

  // eslint-disable-next-line consistent-return
  async process({ httpClient, action$, cancelled$ }, dispatch, done) {
    try {
      if (!cookies('tokens')) {
        return done();
      }

      const {
        data: { meta: { token } },
      } = await httpClient.post(webSocketsAuthenticateRoute, {});
      const socket = new WebSocket(`${CABLE_HOST}?token=${token}`);
      const responseHandlers = {};

      socket.onopen = () => {
        dispatch(setConnectionStatus(WS_STATUSES.opened));
      };

      socket.onclose = () => {
        dispatch(setConnectionStatus(WS_STATUSES.closed));
        dispatch(resetSubscriptionChannels());
        done();
      };

      socket.onmessage = ({ data }) => {
        const channel = parseChannel(data);
        const parsedData = parseData(data);
        const onReceived = responseHandlers[channel];

        if (onReceived && parsedData) {
          onReceived(parsedData);
        }
      };

      action$.subscribe((action) => {
        if (action.type === SEND_COMMAND && socket.readyState === 1) {
          const command = toCommand(action);

          if (action.onReceived) {
            responseHandlers[action.channel] = action.onReceived;
          }

          socket.send(command);
        }

        if (action.type === SET_OFFLINE) {
          dispatch(setConnectionStatus(WS_STATUSES.closed));
          dispatch(resetSubscriptionChannels());
          done();
        }
      });

      cancelled$.subscribe(() => {
        socket.close();
        dispatch(setConnectionStatus(WS_STATUSES.closed));
        dispatch(resetSubscriptionChannels());
      });
    } catch {
      dispatch(setConnectionStatus(WS_STATUSES.closed));
      dispatch(resetSubscriptionChannels());
      done();
    }
  },
});

export default openConnection;
