import Axios from 'axios';
import qs from 'qs';

import { API_HOST, BASIC_AUTH_LOGIN, BASIC_AUTH_PASSWORD } from 'constants';

const httpClientParams = {
  // workaround for axios bug https://github.com/axios/axios/issues/1664#issuecomment-415492981
  headers: {
    common: {},
    'X-Client-Device': 'web',
  },
  auth: {
    username: BASIC_AUTH_LOGIN,
    password: BASIC_AUTH_PASSWORD,
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
};

const buildHttpClient = (baseURL = `${API_HOST}/api/v1`) => Axios.create({
  baseURL,
  ...httpClientParams,
});

export { default as mountInterceptors } from './mountInterceptors';
export default buildHttpClient;
