export const CLINIC_SPECIALITIES_STATUSES = {
  all: '',
  active: 'active',
  inactive: 'inactive',
};

export const CLINIC_SPECIALITIES_STATUSES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: CLINIC_SPECIALITIES_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: CLINIC_SPECIALITIES_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
];

export const CLINIC_SPECIALITIES_CANCELLATION_OPTIONS = {
  anytime: 'anytime',
  upTo: 'up_to_x_before_consultation',
};

export const CLINIC_SPECIALITIES_TAB = {
  details: 'details',
  doctors: 'doctors',
};

export const CLINIC_SPECIALITIES_STEPS = {
  details: 1,
  doctors: 2,
};
