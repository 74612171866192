import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import removeUserCookies from 'utils/removeUserCookies';

import { showNotification } from 'state/notifications/actions';
import { createNewPasswordEndpoint } from '../endpoints';
import { SUBMIT_NEW_PASSWORD } from '../types';

const submitNewPasswordOperation = createLogic({
  type: SUBMIT_NEW_PASSWORD,
  latest: true,

  async process({
    httpClient,
    action: {
      values, form, token,
    },
  }, dispatch, done) {
    const { url, endpoint } = createNewPasswordEndpoint;

    try {
      const params = {
        ...values,
        password_confirmation: values.password,
        token,
        redirect_to: '/account_settings',
      };

      await httpClient.put(url, params);

      removeUserCookies();

      await redirect({ href: '/login' });

      dispatch(showNotification({ messageObject: { id: 'notifications.resetPasswordSuccess' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default submitNewPasswordOperation;
