import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSymptomSpecialityIds } from '../actions';
import { getSymptomSpecialitiesEndpoint } from '../endpoints';
import { GET_SYMPTOM_SPECIALITIES } from '../types';

const getSymptomSpecialitiesOperation = createLogic({
  type: GET_SYMPTOM_SPECIALITIES,
  latest: true,

  async process({ httpClient, action: { symptomId } }, dispatch, done) {
    const { endpoint, url } = getSymptomSpecialitiesEndpoint(symptomId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSymptomSpecialityIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getSymptomSpecialitiesOperation;
