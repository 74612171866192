import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import profileName from 'utils/profileName';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';

import { revenueFiltersSelector, selectedRevenueUserProfileSelector } from '../selectors';
import {
  downloadRevenueIndependentDoctorsAppointmentsEndpoint,
  downloadRevenueIndependentDoctorAppointmentsEndpoint,
} from '../endpoints';
import { DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS } from '../types';

const downloadRevenueIndependentDoctorAppointmentsOperation = createLogic({
  type: DOWNLOAD_REVENUE_INDEPENDENT_DOCTOR_APPOINTMENTS,
  latest: true,

  async process({ action: { intlFormatMessage }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const { startDate, endDate, userProfileId } = revenueFiltersSelector(state);

    const { endpoint, url } = !isPresent(userProfileId)
      ? downloadRevenueIndependentDoctorsAppointmentsEndpoint
      : downloadRevenueIndependentDoctorAppointmentsEndpoint(userProfileId);

    const params = {
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const userProfile = selectedRevenueUserProfileSelector(state);
      const fileName = !isPresent(userProfileId)
        ? intlFormatMessage({ id: 'download.fileName.revenueAppointmentsForIndividualDoctors' }, { startDate, endDate })
        : intlFormatMessage(
            { id: 'download.fileName.revenueAppointmentsForIndividualDoctor' },
            {
              startDate,
              endDate,
              userName: profileName(userProfile),
              licenseNumber: userProfile?.licenseNumber ?? 0,
            },
          );

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(data, fileName);
      dispatch(showNotification({
        messageObject: { id: 'notifications.fileHasBeenDownloaded' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadRevenueIndependentDoctorAppointmentsOperation;
