import {
  equals, prop, anyPass, any, propEq,
} from 'ramda';

import { NOTE_TABS } from 'constants/note';

export const parseValuesFormToParams = (prescriptions) => prescriptions.map(({
  drug,
  commercialName,
  doseValue,
  doseMeasure,
  frequencyValue,
  frequencyRecurrenceKey,
  packageQuantity,
  packageType,
  comment,
}) => ({
  drug,
  commercial_name: commercialName,
  dose_value: doseValue,
  dose_measure: doseMeasure,
  frequency_value: frequencyValue,
  frequency_recurrence: frequencyRecurrenceKey,
  frequency_recurrence_key: frequencyRecurrenceKey,
  package_quantity: packageQuantity,
  package_type: packageType,
  comment,
}));

export const highlightedTabWithErrors = (errors, tabName) => {
  if (equals(tabName, NOTE_TABS.general)) {
    return anyPass([
      prop('chiefCompliant'),
      prop('subjective'),
      prop('objective'),
      prop('assessment'),
      prop('primaryDisease'),
    ])(errors);
  }

  if (equals(tabName, NOTE_TABS.plan)) {
    return anyPass([
      prop('plan'),
    ])(errors);
  }

  if (equals(tabName, NOTE_TABS.prescription)) {
    return anyPass([
      prop('prescriptions'),
    ])(errors);
  }

  if (equals(tabName, NOTE_TABS.auxiliaryStudies)) {
    return anyPass([
      prop('auxiliaryStudies'),
    ])(errors);
  }

  if (equals(tabName, NOTE_TABS.sickNote)) {
    return anyPass([
      prop('sickNote'),
    ])(errors);
  }

  return false;
};

export const isDiseaseUniq = (diseaseId, values) => {
  const { primaryDisease, diseases } = values;

  return !equals(diseaseId, primaryDisease.id) && !any(propEq('id', diseaseId), diseases);
};
