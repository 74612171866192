import React from 'react';
import { connect } from 'react-redux';

import { filterDiseases } from 'state/concepts/userProfile/diseases/actions';
import { searchQuerySelector } from 'state/concepts/userProfile/filters/selectors';

import SearchComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class Search extends React.Component {
  render = () => (
    <SearchComponent
      {...this.props}
    />
  )
}

const mapStateToProps = state => ({
  searchQuery: searchQuerySelector(state),
});

const mapDispatchToProps = {
  filterDiseases,
};

export { Search as SearchContainer };
export default connect(mapStateToProps, mapDispatchToProps)(Search);
