const namespace = 'symptoms';

export const FETCH_SYMPTOMS = `${namespace}/FETCH_SYMPTOMS`;
export const SET_SYMPTOMS = `${namespace}/SET_SYMPTOMS`;
export const SET_SYMPTOMS_PAGE = `${namespace}/SET_SYMPTOMS_PAGE`;
export const SET_SYMPTOMS_SORT_ORDER = `${namespace}/SET_SYMPTOMS_SORT_ORDER`;
export const SET_SYMPTOMS_BLANK_STATE = `${namespace}/SET_SYMPTOMS_BLANK_STATE`;
export const SELECT_SYMPTOM = `${namespace}/SELECT_SYMPTOM`;
export const SET_SYMPTOMS_SELECTED = `${namespace}/SET_SYMPTOMS_SELECTED`;
export const SET_SYMPTOMS_FILTER = `${namespace}/SET_SYMPTOMS_FILTER`;
export const ACTIVATE_SYMPTOMS = `${namespace}/ACTIVATE_SYMPTOMS`;
export const DEACTIVATE_SYMPTOMS = `${namespace}/DEACTIVATE_SYMPTOMS`;
export const SET_CREATE_SYMPTOM_STEP = `${namespace}/SET_CREATE_SYMPTOM_STEP`;
export const SUBMIT_CREATE_SYMPTOM_DETAILS = `${namespace}/SUBMIT_CREATE_SYMPTOM_DETAILS`;
export const CREATE_SYMPTOM_DETAILS = `${namespace}/CREATE_SYMPTOM_DETAILS`;
export const UPDATE_SYMPTOM_DETAILS = `${namespace}/UPDATE_SYMPTOM_DETAILS`;
export const GET_SYMPTOM_DETAILS = `${namespace}/GET_SYMPTOM_DETAILS`;
export const SET_SYMPTOM_DETAILS = `${namespace}/SET_SYMPTOM_DETAILS`;
export const UPLOAD_SYMPTOM_IMAGE = `${namespace}/UPLOAD_SYMPTOM_IMAGE`;
export const REMOVE_SYMPTOM_IMAGE = `${namespace}/REMOVE_SYMPTOM_IMAGE`;
export const SUBMIT_SYMPTOM_QUESTIONNAIRE = `${namespace}/SUBMIT_SYMPTOM_QUESTIONNAIRE`;
export const GET_SYMPTOM_QUESTIONNAIRE = `${namespace}/GET_SYMPTOM_QUESTIONNAIRE`;
export const SET_SELECTED_QUESTION_POSITION = `${namespace}/SET_SELECTED_QUESTION_POSITION`;
export const SET_QUESTION_IDS = `${namespace}/SET_QUESTION_IDS`;
export const SUBMIT_NEW_SYMPTOM = `${namespace}/SUBMIT_NEW_SYMPTOM`;
export const GET_SYMPTOM_SPECIALITIES = `${namespace}/GET_SYMPTOM_SPECIALITIES`;
export const SET_SYMPTOM_SPECIALITY_IDS = `${namespace}/SET_SYMPTOM_SPECIALITY_IDS`;
