import formatCurrency from 'utils/formatCurrency';

export const VOUCHERS_TAB = {
  inactive: 'inactive',
  used: 'used',
  batches: 'batches',
};

export const VOUCHER_STATUSES = {
  all: '',
  inactive: 'inactive',
  used: 'used',
};

export const VOUCHERS_BATCHES_STATUSES = {
  current: 'current',
  finished: 'finished',
};

export const VOUCHERS_BATCHES_FILTERS = [
  {
    value: 'all',
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: VOUCHERS_BATCHES_STATUSES.current,
    label: { id: 'shared.filters.statuses.current' },
  },
  {
    value: VOUCHERS_BATCHES_STATUSES.finished,
    label: { id: 'shared.filters.statuses.finished' },
  },
];

export const VOUCHER_CODE_STATUS = {
  success: 'success',
  failure: 'failure',
};

export const VOUCHER_CODE_LENGTH = 16;
export const MIN_PAGOPAR_AMOUNT = 1000;
export const MAX_PAGOPAR_AMOUNT = 999999;

export const OPTION_OTHER = 'other';

export const TOP_UP_AMOUNT_OPTIONS = [
  {
    label: formatCurrency('50000'),
    value: '50000',
  },
  {
    label: formatCurrency('100000'),
    value: '100000',
  },
  {
    label: formatCurrency('200000'),
    value: '200000',
  },
  {
    label: { id: 'label.other' },
    value: OPTION_OTHER,
  },
];

export const TRANSACTION_STATUSES = {
  success: 'success',
  failure: 'failure',
  pending: 'pending',
};
