import { combineReducers } from 'redux';
import { DateTime } from 'luxon';
import { Views } from 'react-big-calendar';

import {
  SET_APPOINTMENTS,
  SET_FILTER_PARAMS,
  RESET_FILTERS,
  SET_TIMEZONE,
  SET_APPOINTMENTS_DATE,
  SET_APPOINTMENTS_VIEW,
  SET_CANCELLATION_RULES,
  SET_CANCELLATION_ERROR,
  SET_LAST_OPENED_APPOINTMENT_TOOLTIP_ID,
  CLEAR_LAST_OPENED_APPOINTMENT_TOOLTIP_ID,
  SET_APPOINTMENTS_BLANK_STATE,
} from './types';

const appointmentIds = (state = [], action) => {
  switch (action.type) {
    case SET_APPOINTMENTS:
      return action.appointmentIds;
    default:
      return state;
  }
};

const filtersInitialState = {
  statuses: [],
  patients: [],
  specialities: [],
  symptoms: [],
  doctors: [],
  name: '',
};
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case RESET_FILTERS:
      return filtersInitialState;
    default:
      return state;
  }
};

const date = (state = DateTime.local().toISODate(), action) => {
  switch (action.type) {
    case SET_APPOINTMENTS_DATE:
      return action.date;
    default:
      return state;
  }
};

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezone;
    default:
      return state;
  }
};

const view = (state = Views.DAY, action) => {
  switch (action.type) {
    case SET_APPOINTMENTS_VIEW:
      return action.view;
    default:
      return state;
  }
};

const cancellationRules = (state = null, action) => {
  switch (action.type) {
    case SET_CANCELLATION_RULES:
      return {
        time: action.time,
        description: action.description,
      };
    default:
      return state;
  }
};

const cancellationError = (state = null, action) => {
  switch (action.type) {
    case SET_CANCELLATION_ERROR:
      return action.error;
    default:
      return state;
  }
};

const lastOpenedAppointmentTooltipId = (state = null, action) => {
  switch (action.type) {
    case SET_LAST_OPENED_APPOINTMENT_TOOLTIP_ID:
      return action.id;
    case CLEAR_LAST_OPENED_APPOINTMENT_TOOLTIP_ID:
      return null;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_APPOINTMENTS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

export default combineReducers({
  appointmentIds,
  filters,
  date,
  timezone,
  view,
  cancellationRules,
  cancellationError,
  lastOpenedAppointmentTooltipId,
  isBlankState,
});
