import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { fetchExtraFreeVisitsClinicsEndpoint } from '../endpoints';
import { FETCH_FREE_VISITS_CLINICS } from '../types';
import { setClinicsListIds } from '../actions';
import { clinicsListFilterParamsSelector } from '../selectors';

const fetchClinicsOperation = createLogic({
  type: FETCH_FREE_VISITS_CLINICS,
  latest: true,

  async process({ httpClient, getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchExtraFreeVisitsClinicsEndpoint;
    const { name } = clinicsListFilterParamsSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size: 20,
      },
      filter: {
        name,
        accept_extra_free_visits: true,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setClinicsListIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchClinicsOperation;
