import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  includes, pluck, flatten, compose, path, trim, isEmpty,
 } from 'ramda';

import {
  fetchDoctorList as fetchDoctorListAction,
  filterDoctorList as filterDoctorListAction,
  resetDoctorListIds as resetDoctorListIdsAction,
} from 'state/concepts/userProfile/doctors/actions';
import {
  doctorListSelector,
  doctorsMetaSelector,
  doctorsLoadingSelector,
} from 'state/concepts/userProfile/doctors/selectors';

import DropdownDoctorsFieldComponent from './component';

class DropdownDoctorsField extends React.Component {
  static propTypes = {
    fetchDoctorList: PropTypes.func.isRequired,
    filterDoctorList: PropTypes.func.isRequired,
    resetDoctorListIds: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
  }

  static defaultProps = {
    options: [],
  }

  state = {
    isOpen: false,
    searchQuery: '',
  }

  get selectedDoctors() {
    return flatten(pluck('userProfileIds', path(['form', 'values', 'doctors'], this.props)));
  }

  get isAllDoctorsSelected() {
    const { options } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => this.handleCheckSelectedOption(id));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterDoctorList, resetDoctorListIds } = this.props;

    this.setState({ searchQuery });
    resetDoctorListIds();
    filterDoctorList({ name: trim(searchQuery), excludeUsers: this.selectedDoctors });
  }, 250)

  handleFocus = () => {
    const { filterDoctorList } = this.props;

    this.setState({ searchQuery: '' });
    filterDoctorList({ name: '', excludeUsers: this.selectedDoctors });
  }

  handleCheckSelectedOption = key => includes(key, this.selectedDoctors);

  handleDropdownVisibleChange = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render = () => (
    <DropdownDoctorsFieldComponent
      {...this.props}
      {...this.state}
      isAllDoctorsSelected={this.isAllDoctorsSelected}
      onDropdownVisibleChange={this.handleDropdownVisibleChange}
      onCheckSelectedOption={this.handleCheckSelectedOption}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

const mapStateToProps = state => ({
  options: doctorListSelector(state),
  meta: doctorsMetaSelector(state),
  isLoading: doctorsLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchDoctorList: fetchDoctorListAction,
  filterDoctorList: filterDoctorListAction,
  resetDoctorListIds: resetDoctorListIdsAction,
};

export { DropdownDoctorsField as DropdownDoctorsFieldContainer };
export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(DropdownDoctorsField);
