import { createLogic } from 'redux-logic';
import { map } from 'ramda';

import { USER_ROLE } from 'constants/users';
import { DOCTORS_STATUSES } from 'constants/doctors';

import {
  setDoctorListIds,
  setDoctorListStatuses,
  setDoctorListClinicSpecialityId,
  setDoctorsRoles,
} from 'state/concepts/userProfile/doctors/actions';
import { userProfileClinicSpecialitiesListSelector } from '../selectors';
import { CLINIC_SPECIALITY_ASSIGN_DOCTORS_INITIAL_STATE } from '../types';

const assignDoctorsInitialStateOperation = createLogic({
  type: CLINIC_SPECIALITY_ASSIGN_DOCTORS_INITIAL_STATE,
  latest: true,

  async process({ getState, action: { clinicSpecialityId } }, dispatch, done) {
    const state = getState();
    const doctorsList = userProfileClinicSpecialitiesListSelector(state, clinicSpecialityId);
    const doctorIds = map(item => item.userProfile.id, doctorsList);
    const statuses = [DOCTORS_STATUSES.active, DOCTORS_STATUSES.inactive];

    dispatch(setDoctorListIds(doctorIds));
    dispatch(setDoctorListStatuses(statuses));
    dispatch(setDoctorListClinicSpecialityId(clinicSpecialityId));
    dispatch(setDoctorsRoles(USER_ROLE.virtualClinicDoctor));

    done();
  },
});

export default assignDoctorsInitialStateOperation;
