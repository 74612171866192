import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { fileSize } from 'utils/file';
import { handleInputEvent } from 'utils/inputHelpers';

import CVFieldComponent from './component';

class CVFieldContainer extends React.Component {
  static propTypes = {
    form: PropTypes.shape().isRequired,
    field: PropTypes.shape().isRequired,
  };

  get getFileSize() {
    const { field: { value } } = this.props;

    if (!isEmpty(value)) {
      return fileSize(value.size);
    }

    return null;
  }

  handleClearCV = () => {
    const { field } = this.props;

    handleInputEvent(field, field.onChange)('');
  };

  handleChange = (evt) => {
    const { field } = this.props;
    const { files } = evt.target;

    if (files.length) {
      handleInputEvent(field, field.onChange)(files[0]);
    }
  };

  render() {
    return (
      <CVFieldComponent
        {...this.props}
        fileSize={this.getFileSize}
        onClearCV={this.handleClearCV}
        onChange={this.handleChange}
      />
    );
  }
}

export default CVFieldContainer;
