import { combineReducers } from 'redux';
import { uniq } from 'ramda';

import * as types from './types';

const diseasesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_DISEASES_IDS:
      return action.ids;
    case types.UPDATE_DISEASES_IDS:
      return uniq([...state, ...action.ids]);
    case types.DESTROY_DISEASES_IDS:
      return [];
    default:
      return state;
  }
};

const commonDiseasesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_COMMON_DISEASES_IDS:
      return action.ids;
    default:
      return state;
  }
};

const completeCancelDiseasesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_COMPLETE_CANCEL_DISEASES_IDS:
      return action.ids;
    default:
      return state;
  }
};

export default combineReducers({
  diseasesIds,
  commonDiseasesIds,
  completeCancelDiseasesIds,
});
