import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import Slide from './Slide';

const FilesPreviewModal = React.forwardRef(({
  onClose,
  initialSlide,
  onSlideChange,
  currentFiles,
  currentSlide: {
    url,
    filename,
  },
}, ref) => (
  <Modal className="modal-files" onClose={onClose}>
    <div className="modal-files__wrap">
      <div className="modal-files__header">
        <h1 className="modal-files__title">
          {filename}
        </h1>
        <div className="modal-files__buttons">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            download
          >
            <SvgIcon icon="download" className="modal-files__button" />
          </a>
          <button onClick={onClose}>
            <SvgIcon icon="cross" className="modal-files__button" />
          </button>
        </div>
      </div>
      <div className="modal-files__body">
        <div className="modal-files__content">
          <Slider
            ref={ref}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            initialSlide={initialSlide}
            beforeChange={onSlideChange}
            className="preview-carousel__content"
          >
            {currentFiles.map(file => <Slide key={file.id} {...file} />)}
          </Slider>
        </div>
      </div>
    </div>
  </Modal>
));

FilesPreviewModal.defaultProps = {
  currentFiles: [],
};

FilesPreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSlideChange: PropTypes.func.isRequired,
  initialSlide: PropTypes.number.isRequired,
  currentFiles: PropTypes.arrayOf(PropTypes.shape()),
  currentSlide: PropTypes.shape({
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }).isRequired,
};

FilesPreviewModal.displayName = 'FilesPreviewModal';

export default FilesPreviewModal;
