import { createLogic } from 'redux-logic';
import { pathOr, head } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { activateDoctorEndpoint } from '../endpoints';
import { ACTIVATE_DOCTOR } from '../types';

const activateDoctorOperation = createLogic({
  type: ACTIVATE_DOCTOR,
  latest: true,

  async process({ httpClient, getState, action: { doctorId, form } }, dispatch, done) {
    const { url, endpoint } = activateDoctorEndpoint(doctorId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url);
      const state = getState();

      const response = updateDataHelper(
        state.data, 'userProfile', doctorId,
        { attributes: { active: true } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorActivated' },
      }));
    } catch (error) {
      const errorMessage = head(pathOr([], ['response', 'data', 'errors'], error));

      dispatch(showNotification({
        messageObject: { id: pathOr(null, ['detail'], errorMessage) ?? 'notifications.doctorActivatedError' },
        kind: 'error',
      }));

      requestErrorHandler({ error, dispatch, endpoint });
      form?.resetForm();
    }

    dispatch(hideModal());
    done();
  },
});

export default activateDoctorOperation;
