import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { keys } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setPatient, setPatientChildrenIds } from '../actions';
import { fetchPatientEndpoint } from '../endpoints';
import { FETCH_PATIENT } from '../types';

const fetchPatientOperation = createLogic({
  type: FETCH_PATIENT,
  latest: true,

  async process({ httpClient, action: { patientId } }, dispatch, done) {
    const { endpoint, url } = fetchPatientEndpoint(patientId);
    const params = {
      include: [
        'health-profile',
        'health-profile.allergies',
        'child_profiles',
        'patient-balance',
        'extra-free-visits',
      ],
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const normalizedData = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setPatient(patientId));
      dispatch(setPatientChildrenIds(keys(normalizedData.childProfile)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }
    done();
  },
});

export default fetchPatientOperation;
