import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { prop } from 'ramda';

import isPresent from 'utils/isPresent';

import { getNote as getNoteAction } from 'state/concepts/userProfile/appointment/actions';
import { noteSelector } from 'state/concepts/userProfile/appointment/selectors';

import NoteModalComponent from './component';

class NoteModal extends React.Component {
  static propTypes = {
    getNote: PropTypes.func.isRequired,
    noteId: PropTypes.string.isRequired,
    note: PropTypes.shape().isRequired,
  };

  state = {
    opened: false,
  };

  componentDidMount() {
    const { noteId, getNote } = this.props;

    getNote(noteId);
  }

  get isPresentPrescriptions() {
    const { note } = this.props;

    return isPresent(prop('prescriptions', note)) || isPresent(prop('auxiliaryStudies', note));
  }

  handleChangeOpened = () => {
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));
  };

  render = () => (
    <NoteModalComponent
      {...this.props}
      {...this.state}
      isPresentPrescriptions={this.isPresentPrescriptions}
      onChangeOpened={this.handleChangeOpened}
    />
  )
}

const mapStateToProps = (state, { noteId }) => ({
  note: noteSelector(state, noteId),
});

const mapDispatchToProps = {
  getNote: getNoteAction,
};

export { NoteModal as NoteModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
