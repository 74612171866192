import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';

import Button from 'views/shared/Button';
import DropdownDoctorsField from 'views/shared/DropdownDoctorsField';
import CurrencyField from 'views/shared/CurrencyField';
import SwitchField from 'views/shared/SwitchField';
import SvgIcon from 'views/shared/SvgIcon';

const DoctorsProviderComponent = ({
  form: { values: { doctors }, errors },
  onAddProvider,
  onRemoveProvider,
}) => (
  <>
    {doctors.map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`assign-doctor-${index}`} className="assign-doctor">
        <div className="flex-grow-1">
          <Field
            id={`doctors.${index}.userProfileIds`}
            name={`doctors.${index}.userProfileIds`}
            component={DropdownDoctorsField}
            label={{ id: 'label.doctors' }}
            optionGroupLabel={{ id: 'label.doctors' }}
            placeholder={{ id: 'placeholder.doctors' }}
            className="multiselect-field multiselect-field--assign-doctors"
            selectClassName={classNames({
              'multiselect--has-message-error':
                errors.doctors
                && errors.doctors[index]
                && errors.doctors[index].userProfileIds,
            })}
            dropdownClassName="multiselect-field__dropdown"
            mode="multiple"
            icon="alert"
          />
        </div>
        <div className="flex-shrink-0 w-160">
          <div className="mb-8">
            <Field
              name={`doctors.${index}.price`}
              component={CurrencyField}
              id={`doctors.${index}.price`}
              label={{ id: 'label.pricePerSession' }}
              placeholder={{ id: 'placeholder.pricePerSession' }}
              icon="alert"
              min={0}
            />
          </div>
          <div>
            <Field
              name={`doctors.${index}.freeVisit`}
              id={`doctors.${index}.freeVisit`}
              label={{ id: 'label.acceptFreeVisits' }}
              component={SwitchField}
              className="mb-0"
            />
          </div>
        </div>
        {doctors.length > 1 && (
          <button
            className="assign-doctor__providers-btn"
            onClick={onRemoveProvider(index)}
            type="button"
          >
            <SvgIcon icon="delete-o" />
          </button>
        )}
      </div>
    ))}
    <Button
      kind="empty"
      icon="plus"
      text={{ id: 'clinicSpecialities.assignDoctors.addDoctorsWithAnotherPrice' }}
      onClick={onAddProvider}
    />
  </>
);

DoctorsProviderComponent.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      doctors: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    errors: PropTypes.shape({
      doctors: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
  }).isRequired,
  onAddProvider: PropTypes.func.isRequired,
  onRemoveProvider: PropTypes.func.isRequired,
};

export default DoctorsProviderComponent;
