import getFloatNumber from '../numbers/getFloatNumber';

export default (val) => {
  if (typeof val === 'string') {
    const result = val.replace(/[^0-9^.]/g, '');

    return getFloatNumber(result);
  }
  if (typeof val === 'number') {
    return val;
  }
  return 0;
};
