import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchAppointments = () => ({
  type: types.FETCH_APPOINTMENTS,
});

export const setAppointments = appointmentIds => ({
  type: types.SET_APPOINTMENTS,
  appointmentIds,
});

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
});

export const filterAppointment = filters => ({
  type: types.FILTER_APPOINTMENTS,
  filters,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});

export const setAppointmentsDate = date => ({
  type: types.SET_APPOINTMENTS_DATE,
  date,
});

export const setTimezone = timezone => ({
  type: types.SET_TIMEZONE,
  timezone,
});

export const setAppointmentsView = view => ({
  type: types.SET_APPOINTMENTS_VIEW,
  view,
});

export const downloadAppointmentsCsv = intlFormatMessage => ({
  type: types.DOWNLOAD_APPOINTMENTS_CSV,
  intlFormatMessage,
});

export const cancelAppointment = makeFormSubmitAction(types.CANCEL_APPOINTMENTS);

export const setCancellationRules = ({ time, description }) => ({
  type: types.SET_CANCELLATION_RULES,
  description,
  time,
});

export const setCancellationError = error => ({
  type: types.SET_CANCELLATION_ERROR,
  error,
});

export const receiveAppointment = data => ({
  type: types.RECEIVE_APPOINTMENT,
  data,
});

export const setLastOpenedAppointmentTooltipId = id => ({
  type: types.SET_LAST_OPENED_APPOINTMENT_TOOLTIP_ID,
  id,
});

export const clearLastOpenedAppointmentTooltipId = () => ({
  type: types.CLEAR_LAST_OPENED_APPOINTMENT_TOOLTIP_ID,
});

export const setBlankState = isBlankState => ({
  type: types.SET_APPOINTMENTS_BLANK_STATE,
  isBlankState,
});
