import { createSelector } from 'reselect';
import build from 'redux-object';
import { DateTime } from 'luxon';
import {
  compose,
  filter,
  findLastIndex,
  flatten,
  head,
  identity,
  map,
  path,
  prop,
  sortBy,
} from 'ramda';

import { WS_STATUSES } from 'constants/webSockets';

import buildCollection from 'utils/buildCollection';
import { isUnreadMessage } from 'utils/chat';

import { loadingSelector } from 'state/data/selectors';
import { connectionStatusSelector } from 'state/concepts/userProfile/webSockets/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import {
  fetchChatAttachmentsEndpoint,
  fetchChatMessageEndpoint,
  removeChatsAttachmentsEndpoint,
  removeChatMessageEndpoint,
} from './endpoints';

const dataSelector = prop('data');
const metaSelector = state => state.data.meta;

export const chatIdSelector = path(['chat', 'chatId']);
export const messageIdsSelector = path(['chat', 'messageIds']);
export const patientStatusSelector = path(['chat', 'patientStatus']);
export const attachmentsIdsSelector = path(['chat', 'attachmentsIds']);
export const editingMessageSelector = path(['chat', 'editingMessage']);
export const uploadFileNameSelector = path(['chat', 'uploadFileName']);
export const uploadProgressSelector = path(['chat', 'uploadProgress']);

export const messagesSelector = createSelector(
  messageIdsSelector,
  dataSelector,
  buildCollection('message'),
);

export const lastMessageIdSelector = createSelector(
  messagesSelector,
  compose(
    prop('id'),
    head,
    sortBy(compose(DateTime.fromISO, prop('createdAt'))),
  ),
);

export const chatSelector = createSelector(
  chatIdSelector,
  dataSelector,
  (chatId, data) => (chatId ? build(data, 'chat', chatId) : {}),
);

export const chatAttachmentsSelector = createSelector(
  messagesSelector,
  compose(
    filter(identity),
    flatten,
    map(prop('attachment')),
  ),
);

export const attachmentsSelector = createSelector(
  attachmentsIdsSelector,
  dataSelector,
  compose(
    filter(identity),
    buildCollection('messageAttachment'),
  ),
);

export const attachmentsLoadingSelector = (state, chatId) => (
  loadingSelector(state, fetchChatAttachmentsEndpoint(chatId).endpoint)
);

export const removeAttachmentsLoadingSelector = (state, fileId) => (
  loadingSelector(state, removeChatsAttachmentsEndpoint(fileId).endpoint)
);

export const unreadMessagesSelector = createSelector(
  messagesSelector,
  currentUserSelector,
  (messages, currentUserProfile) => (
    messages.filter(message => isUnreadMessage(message, currentUserProfile.chatUserId))
  ),
);

export const unreadMessagesCountSelector = createSelector(
  metaSelector,
  chatSelector,
  (meta, chat) => {
    const { endpoint } = fetchChatMessageEndpoint(chat.id);

    return path([endpoint, 'meta', 'unreadCount'], meta);
  },
);

export const lastReadMessageSelector = createSelector(
  messagesSelector,
  currentUserSelector,
  (messages, currentUserProfile) => {
    const firstUnreadMessageIndex = findLastIndex(message => (
      isUnreadMessage(message, currentUserProfile.chatUserId)
    ), messages);

    if (firstUnreadMessageIndex === -1) {
      return undefined;
    }

    const lastReadMessageIndex = firstUnreadMessageIndex + 1;

    return messages[lastReadMessageIndex];
  },
);

export const firstUnreadMessageSelector = createSelector(
  messagesSelector,
  currentUserSelector,
  (messages, currentUserProfile) => {
    const firstUnreadMessageIndex = findLastIndex(message => (
      isUnreadMessage(message, currentUserProfile.chatUserId)
    ), messages);

    return messages[firstUnreadMessageIndex];
  },
);

export const isOfflineSelector = createSelector(
  path(['chat', 'isOnline']),
  connectionStatusSelector,
  (isOnline, connectionStatus) => !isOnline && connectionStatus === WS_STATUSES.closed,
);

export const isUnreadIndicatorVisibleSelector = createSelector(
  path(['chat', 'isUnreadIndicatorVisible']),
  unreadMessagesCountSelector,
  (isUnreadIndicatorVisible, unreadMessagesCount) => (
    isUnreadIndicatorVisible && unreadMessagesCount > 0
  ),
);

export const removeMessageLoadingSelector = (state, messageId) => (
  loadingSelector(state, removeChatMessageEndpoint(messageId).endpoint)
);
