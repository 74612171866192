import endpoint from 'utils/endpoint';

import {
  usersSessionRoute,
  usersTwoFactorAuthenticationRoute,
  usersTwoFactorAuthenticationRecoveryRoute,
  userAccountProfileRefreshActivityRoute,
  patientsSessionRoute,
  patientsSessionCreateByOneTimeTokenRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const userLoginEndpoint = endpoint(types.SUBMIT_USER_LOGIN, 'POST', usersSessionRoute);
export const refreshActivityEndpoint = endpoint(types.REFRESH_ACTIVITY, 'PUT', userAccountProfileRefreshActivityRoute);
export const userLogoutEndpoint = endpoint(types.USER_LOGOUT, 'DELETE', usersSessionRoute);
export const submitTwoFactorAuthEndpoint = endpoint(types.SUBMIT_USER_TWO_FACTOR_AUTH_CODE, 'POST', usersTwoFactorAuthenticationRoute);
export const submitRecoveryCodeEndpoint = endpoint(types.SUBMIT_USER_RECOVERY_CODE, 'POST', usersTwoFactorAuthenticationRecoveryRoute);
export const patientsLoginEndpoint = endpoint(types.PATIENT_LOGIN, 'POST', patientsSessionRoute);
export const patientsLogoutEndpoint = endpoint(types.PATIENT_LOGOUT, 'DELETE', patientsSessionRoute);
export const patientsLoginByOneTimeTokenEndpoint = endpoint(types.PATIENT_LOGIN_BY_ONE_TIME_TOKEN, 'POST', patientsSessionCreateByOneTimeTokenRoute);
