import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { updateDiseasesIds } from '../actions';
import { lastDiseaseIdSelector } from '../selectors';
import { searchDiseasesEndpoint } from '../endpoints';
import { SEARCH_DISEASES } from '../types';

const searchDiseasesOperation = createLogic({
  type: SEARCH_DISEASES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = searchDiseasesEndpoint;
    const { name } = commonFiltersSelector(state);

    const params = {
      page: {
        size: 50,
        after: lastDiseaseIdSelector(state),
      },
      q: name,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const normalizedData = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(updateDiseasesIds(data.data.map(item => ({ id: item.id }))));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default searchDiseasesOperation;
