import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { getIn } from 'formik';

import isPresent from 'utils/isPresent';
import removeHtmlTags from 'utils/removeHtmlTags';

import { languageSelector } from 'state/intl/selectors';

import TextEditorFieldComponent from './component';

class TextEditorField extends React.Component {
  static propTypes = {
    form: PropTypes.shape().isRequired,
    field: PropTypes.shape().isRequired,
  };

  state = {
    editorState: EditorState.createEmpty(),
    isFocused: false,
  }

  get isErrorField() {
    const { form: { touched, errors }, field: { name } } = this.props;
    const fieldTouched = getIn(touched, name);
    const fieldErrors = getIn(errors, name);

    return isPresent(fieldTouched) && isPresent(fieldErrors);
  }

  get fieldErrors() {
    const { form: { errors }, field: { name } } = this.props;

    return getIn(errors, name);
  }

  handleBlur = () => {
    this.setState({ isFocused: false });
  }

  handleFocus = () => {
    const { form, field: { name } } = this.props;

    this.setState({ isFocused: true });
    form.setFieldTouched(name, true);
  }

  handleEditorStateChange = (editorState) => {
    const { form, field: { name } } = this.props;
    const value = removeHtmlTags(convertToHTML(editorState.getCurrentContent()));

    form.setFieldValue(name, removeHtmlTags(value));
    this.setState({ editorState });
  };

  render() {
    return (
      <TextEditorFieldComponent
        {...this.props}
        {...this.state}
        isErrorField={this.isErrorField}
        fieldErrors={this.fieldErrors}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onEditorStateChange={this.handleEditorStateChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  language: languageSelector(state),
});

export { TextEditorField as TextEditorFieldContainer };
export default compose(
  injectIntl,
  connect(mapStateToProps),
)(TextEditorField);
