import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { rejectJoinRequest } from 'state/concepts/userProfile/joinRequests/actions';
import { rejectJoinRequestLoadingSelector } from 'state/concepts/userProfile/joinRequests/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class RejectJoinRequestModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    joinRequest: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, joinRequest: { id } } = this.props;

    onSubmit(id);
  }

  render() {
    const { joinRequest } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'joinRequests.rejectModal.title' }}
        bodyText={[{
          id: 'joinRequests.rejectModal.body',
          values: { name: <span className="f-700">{profileName(joinRequest)}</span> },
        }]}
        submitText={{ id: 'shared.reject' }}
        submitClassName="w-136"
        kind="danger"
      />
    );
  }
}

const mapStateToProps = (state, { joinRequest: { id } }) => ({
  isLoading: rejectJoinRequestLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: rejectJoinRequest,
};

export { RejectJoinRequestModal as RejectJoinRequestModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(RejectJoinRequestModal);
