import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { submitJoinRequestEndpoint } from '../endpoints';
import { SUBMIT_JOIN_REQUEST } from '../types';

const submitJoinRequestOperation = createLogic({
  type: SUBMIT_JOIN_REQUEST,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const params = new FormData();
    params.append('first_name', values.firstName);
    params.append('last_name', values.lastName);
    params.append('email', values.email);
    params.append('phone', formatISOPhoneNumber(values.phone));
    params.append('cv', values.cv);

    const { url, endpoint } = submitJoinRequestEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.post(url, params);

      dispatch(dataApiSuccess({ endpoint }));
      await redirect({ href: '/work_with_us' });
      dispatch(showNotification({ messageObject: { id: 'users.signUp.userJoinRequest.successJoinRequest' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitJoinRequestOperation;
