import { createSelector } from 'reselect';
import build from 'redux-object';
import { prop, propOr, path, reduce } from 'ramda';

import { DOCTORS_STATUSES, VIRTUAL_CLINIC_DOCTOR_TYPES } from 'constants/doctors';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, totalCountSelector, pageCountSelector } from 'state/data/selectors';
import {
  fetchVirtualClinicDoctorEndpoint,
  fetchVirtualClinicDoctorsEndpoint,
  deactivateVirtualClinicDoctorEndpoint,
  activateVirtualClinicDoctorEndpoint,
  updateVirtualClinicDoctorEndpoint,
  virtualClinicsSpecialitiesManageEndpoint,
  submitVirtualClinicDoctorJoinRequestEndpoint,
  removeVirtualClinicDoctorJoinRequestEndpoint,
  removeVirtualClinicDoctorInvitationEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const virtualClinicDoctorIdSelector = path(['virtualClinicDoctors', 'virtualClinicDoctorId']);
export const virtualClinicDoctorIdsSelector = path(['virtualClinicDoctors', 'virtualClinicDoctorIds']);
export const virtualClinicDoctorListIdsSelector = path(['virtualClinicDoctors', 'virtualClinicDoctorListIds']);
export const isBlankStateSelector = path(['virtualClinicDoctors', 'isBlankState']);
export const paginationSelector = path(['virtualClinicDoctors', 'pagination']);
export const sortSelector = path(['virtualClinicDoctors', 'sort']);

export const virtualClinicDoctorProfileSelector = createSelector(
  virtualClinicDoctorIdSelector,
  dataSelector,
  (id, data) => (data.userProfile && data.userProfile[id] ? build(data, 'userProfile', id) : null),
);

export const virtualClinicDoctorProfileClinicSpecialitySelector = createSelector(
  virtualClinicDoctorProfileSelector,
  propOr([], 'userProfilesClinicSpecialities'),
);

export const virtualClinicDoctorsSelector = createSelector(
  virtualClinicDoctorIdsSelector,
  dataSelector,
  buildCollection('virtualClinicDoctor'),
);

export const virtualClinicDoctorListSelector = createSelector(
  virtualClinicDoctorListIdsSelector,
  dataSelector,
  buildCollection('virtualClinicDoctor'),
);

export const virtualClinicDoctorsForNewSpecialitySelector = createSelector(
  virtualClinicDoctorIdsSelector,
  dataSelector,
  (ids, data) => reduce((acc, id) => {
    if (data.virtualClinicDoctor
      && data.virtualClinicDoctor[id].attributes.clinicSpecialitiesCount === 0
      && data.virtualClinicDoctor[id].attributes.status === DOCTORS_STATUSES.active
    ) {
      return [...acc, build(data, 'virtualClinicDoctor', id)];
    }
    return acc;
  }, [], ids),
);

export const userProfileClinicSpecialitiesSelector = createSelector(
  (_, specialityIds) => specialityIds,
  dataSelector,
  buildCollection('userProfilesClinicSpeciality'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const virtualClinicDoctorsTotalCountSelector = state => (
  totalCountSelector(state, fetchVirtualClinicDoctorsEndpoint.endpoint)
);

export const virtualClinicDoctorsPageCountSelector = state => (
  pageCountSelector(state, fetchVirtualClinicDoctorsEndpoint.endpoint)
);

export const virtualClinicDoctorsLoadingSelector = state => (
  loadingSelector(state, fetchVirtualClinicDoctorsEndpoint.endpoint)
);

export const submitVCDJoinRequestLoadingSelector = state => (
  loadingSelector(state, submitVirtualClinicDoctorJoinRequestEndpoint.endpoint)
);

export const deactivateVirtualClinicDoctorLoadingSelector = (state, vcdId) => (
  loadingSelector(state, deactivateVirtualClinicDoctorEndpoint(vcdId).endpoint)
);

export const activateVirtualClinicDoctorLoadingSelector = (state, vcdId) => (
  loadingSelector(state, activateVirtualClinicDoctorEndpoint(vcdId).endpoint)
);

export const removeVirtualClinicDoctorLoadingSelector = (state, vcdId, vcdType) => {
  const { endpoint } = vcdType === VIRTUAL_CLINIC_DOCTOR_TYPES.joinRequest
    ? removeVirtualClinicDoctorJoinRequestEndpoint(vcdId)
    : removeVirtualClinicDoctorInvitationEndpoint(vcdId);

  return loadingSelector(state, endpoint);
};

export const virtualClinicDoctorProfileLoadingSelector = state => {
  const vcdId = virtualClinicDoctorIdSelector(state);

  return loadingSelector(state, fetchVirtualClinicDoctorEndpoint(vcdId).endpoint);
};

export const updateVirtualClinicDoctorProfileLoadingSelector = state => {
  const vcdId = virtualClinicDoctorIdSelector(state);

  return loadingSelector(state, updateVirtualClinicDoctorEndpoint(vcdId).endpoint);
};

export const clinicSpecialitiesLoadingSelector = state => (
  loadingSelector(state, virtualClinicsSpecialitiesManageEndpoint.endpoint)
);
