import { createLogic } from 'redux-logic';

import { CREATE_SYMPTOM_STEPS } from 'constants/symptoms';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { setCreateSymptomStep } from '../actions';
import { symptomDetailsIdSelector } from '../selectors';
import { updateSymptomDetailsEndpoint } from '../endpoints';
import { UPDATE_SYMPTOM_DETAILS } from '../types';

const updateSymptomDetailsOperation = createLogic({
  type: UPDATE_SYMPTOM_DETAILS,
  latest: true,

  async process({
    action: {
      values, form,
    },
    httpClient, getState,
  }, dispatch, done) {
    const state = getState();
    const symptomDetailsId = symptomDetailsIdSelector(state);
    const { url, endpoint } = updateSymptomDetailsEndpoint(symptomDetailsId);
    const params = new FormData();
    params.append('name', values.name);
    params.append('description', values.description);

    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'symptom', symptomDetailsId,
        { attributes: { name: values.name, description: values.description } },
      );

      dispatch(dataApiSuccess({ response }));
      dispatch(showNotification({ messageObject: { id: 'notifications.symptomDetailsUpdated' } }));
      dispatch(setCreateSymptomStep(CREATE_SYMPTOM_STEPS.questionnaire));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateSymptomDetailsOperation;
