import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import { CREATE_SYMPTOM_STEPS } from 'constants/symptoms';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess } from 'state/data/actions';
import { setSymptomDetails, setCreateSymptomStep } from '../actions';
import { createSymptomDetailsEndpoint } from '../endpoints';
import { CREATE_SYMPTOM_DETAILS } from '../types';

const createSymptomDetailsOperation = createLogic({
  type: CREATE_SYMPTOM_DETAILS,
  latest: true,

  async process({
    action: {
      values, form,
    },
    httpClient,
  }, dispatch, done) {
    const { url, endpoint } = createSymptomDetailsEndpoint;
    const params = new FormData();
    params.append('name', values.name);
    params.append('description', values.description);
    params.append('image', values.image, values.image.name);

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response }));
      dispatch(setSymptomDetails(data.data.id));
      dispatch(setCreateSymptomStep(CREATE_SYMPTOM_STEPS.questionnaire));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default createSymptomDetailsOperation;
