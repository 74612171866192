import React from 'react';
import PropTypes from 'prop-types';

import DoctorsProviderComponent from './component';

class DoctorsProvider extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }

  addProviderHandler = () => this.props.push({
    userProfileIds: [],
    price: '',
    freeVisit: false,
  })

  removeProviderHandler = (index) => () => this.props.remove(index)

  render = () => (
    <DoctorsProviderComponent
      {...this.props}
      onAddProvider={this.addProviderHandler}
      onRemoveProvider={this.removeProviderHandler}
    />
  )
}

export default DoctorsProvider;
