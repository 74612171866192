import { createSelector } from 'reselect';
import build from 'redux-object';
import {
 any, intersection, map, path, prop, propEq, pluck,
} from 'ramda';

import buildCollection from 'utils/buildCollection';
import { isVirtualClinicAdmin } from 'utils/roles';

import { totalCountSelector, pageCountSelector, loadingSelector, endpointMetaSelector } from 'state/data/selectors';
import { makeSelectSearchResults } from 'state/searchResults/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import {
  fetchClinicSpecialitiesEndpoint,
  fetchVirtualClinicSpecialitiesEndpoint,
  activateClinicSpecialitiesEndpoint,
  activateVirtualClinicSpecialitiesEndpoint,
  deactivateClinicSpecialitiesEndpoint,
  deactivateVirtualClinicSpecialitiesEndpoint,
  removeClinicSpecialitiesEndpoint,
  removeVirtualClinicSpecialitiesEndpoint,
  virtualClinicSpecialitiesProvidersEndpoint,
  showVirtualClinicSpecialityDetailsEndpoint,
  showClinicSpecialityDetailsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['clinicSpecialities', 'isBlankState']);
export const clinicSpecialitiesIdsSelector = path(['clinicSpecialities', 'clinicSpecialitiesIds']);
export const paginationSelector = path(['clinicSpecialities', 'pagination']);
export const sortSelector = path(['clinicSpecialities', 'sort']);
export const selectedSelector = path(['clinicSpecialities', 'selectedClinicSpecialities']);
export const clinicSpecialitiesListIdsSelector = path(['clinicSpecialities', 'clinicSpecialitiesListIds']);
export const clinicSpecialitiesListStatusesSelector = path(['clinicSpecialities', 'clinicSpecialitiesListStatuses']);
export const clinicSpecialitiesListFilterParamsSelector = path(['clinicSpecialities', 'clinicSpecialitiesListFilterParams']);

export const clinicSpecialitiesSelector = createSelector(
  clinicSpecialitiesIdsSelector,
  dataSelector,
  buildCollection('clinicSpeciality'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const isSelectedSelector = createSelector(
  selectedSelector,
  (_, clinicSpecialitiesId) => clinicSpecialitiesId,
  (selectedClinicSpecialities, clinicSpecialitiesId) => selectedClinicSpecialities
    .includes(clinicSpecialitiesId),
);

const selectedOnPageSelector = createSelector(
  selectedSelector,
  clinicSpecialitiesIdsSelector,
  intersection,
);

export const isAllSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  clinicSpecialitiesIdsSelector,
  (selectedOnPage, clinicSpecialitiesIds) => selectedOnPage.length === clinicSpecialitiesIds.length,
);

export const hasSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  selectedOnPage => selectedOnPage.length > 0,
);

export const selectedClinicSpecialitiesSelector = createSelector(
  selectedSelector,
  dataSelector,
  buildCollection('clinicSpeciality'),
);

export const isSpecificClinicSpecialitiesSelected = createSelector(
  selectedClinicSpecialitiesSelector,
  (_, key) => key,
  (clinicSpecialities, key) => any(propEq('status', key), clinicSpecialities),
);

export const clinicIdSelector = createSelector(
  currentUserSelector,
  path(['clinic', 'id']),
);

export const specialitiesForFilterSelector = createSelector(
  makeSelectSearchResults('specialities', 'speciality'),
  map(({ id, name }) => ({ key: id, value: id, label: name })),
);

export const clinicSpecialitySelector = createSelector(
  dataSelector,
  (_, clinicSpecialityId) => clinicSpecialityId,
  (data, clinicSpecialityId) => build(data, 'clinicSpeciality', clinicSpecialityId),
);

export const specialitySelector = createSelector(
  clinicSpecialitySelector,
  prop('speciality'),
);

export const userProfileClinicSpecialitiesIdsSelector = createSelector(
  clinicSpecialitySelector,
  clinicSpeciality => pluck('id', clinicSpeciality.userProfilesClinicSpecialities),
);

export const userProfileClinicSpecialitiesListSelector = createSelector(
  userProfileClinicSpecialitiesIdsSelector,
  dataSelector,
  buildCollection('userProfilesClinicSpeciality'),
);

export const clinicSpecialitiesTotalCountSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? fetchVirtualClinicSpecialitiesEndpoint(id).endpoint
    : fetchClinicSpecialitiesEndpoint.endpoint;

  return totalCountSelector(state, endpoint);
};

export const clinicSpecialitiesPageCountSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? fetchVirtualClinicSpecialitiesEndpoint(id).endpoint
    : fetchClinicSpecialitiesEndpoint.endpoint;

  return pageCountSelector(state, endpoint);
};

export const clinicSpecialitiesLoadingSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? fetchVirtualClinicSpecialitiesEndpoint(id).endpoint
    : fetchClinicSpecialitiesEndpoint.endpoint;

  return loadingSelector(state, endpoint);
};

export const activateClinicSpecialitiesLoadingSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? activateVirtualClinicSpecialitiesEndpoint(id).endpoint
    : activateClinicSpecialitiesEndpoint.endpoint;

  return loadingSelector(state, endpoint);
};

export const deactivateClinicSpecialitiesLoadingSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? deactivateVirtualClinicSpecialitiesEndpoint(id).endpoint
    : deactivateClinicSpecialitiesEndpoint.endpoint;

  return loadingSelector(state, endpoint);
};

export const removeClinicSpecialitiesLoadingSelector = state => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? removeVirtualClinicSpecialitiesEndpoint(id).endpoint
    : removeClinicSpecialitiesEndpoint.endpoint;

  return loadingSelector(state, endpoint);
};

export const clinicSpecialitiesProvidersLoadingSelector = (state, id) => (
  loadingSelector(state, virtualClinicSpecialitiesProvidersEndpoint(id).endpoint)
);

export const clinicSpecialitiesListSelector = createSelector(
  clinicSpecialitiesListIdsSelector,
  dataSelector,
  buildCollection('clinicSpeciality'),
);

export const clinicSpecialitiesListMetaSelector = state => {
  const { id } = currentUserSelector(state);

  return endpointMetaSelector(state, fetchVirtualClinicSpecialitiesEndpoint(id).endpoint);
};

export const clinicSpecialitiesListLoadingSelector = state => {
  const { id } = currentUserSelector(state);

  return loadingSelector(state, fetchVirtualClinicSpecialitiesEndpoint(id).endpoint);
};

export const clinicSpecialityLoadingSelector = (state, clinicSpecialityId) => {
  const { id, roleName } = currentUserSelector(state);
  const endpoint = isVirtualClinicAdmin(roleName)
    ? showVirtualClinicSpecialityDetailsEndpoint(id, clinicSpecialityId).endpoint
    : showClinicSpecialityDetailsEndpoint(clinicSpecialityId).endpoint;

  return loadingSelector(state, endpoint);
};
