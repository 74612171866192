import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck, omit } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';
import isBlankState from 'utils/isBlankState';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';

import { setCommissionIds, setBlankState } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import { FETCH_DOCTORS_COMMISSIONS } from '../types';
import { fetchDoctorsCommissionsEndpoint } from '../endpoints';

const fetchDoctorsCommissionsOperation = createLogic({
  type: FETCH_DOCTORS_COMMISSIONS,
  latest: true,

  async process({ httpClient, getState, action: { roleName } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchDoctorsCommissionsEndpoint;

    const params = {
      include: 'clinic,current_clinic_commission,clinic.current_platform_commission',
      page: paginationSelector(state),
      filter: {
        ...commonFiltersSelector(state),
        roles: [roleName],
      },
      sort: sortParamsSelector(state),
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setBlankState(isBlankState(omit(['roles'], params.filter), data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setCommissionIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchDoctorsCommissionsOperation;
