import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal as hideModalAction } from 'state/modal/actions';

import NoteHeadsUpModalComponent from './component';

class NoteHeadsUpModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    const { onSubmit, hideModal } = this.props;

    onSubmit();
    hideModal();
  }

  render = () => (
    <NoteHeadsUpModalComponent
      {...this.props}
      handleSubmit={this.handleSubmit}
    />
  );
}

const mapDispatchToProps = {
  hideModal: hideModalAction,
};

export { NoteHeadsUpModal as NoteHeadsUpModalContainer };
export default connect(null, mapDispatchToProps)(NoteHeadsUpModal);
