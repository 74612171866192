import PropTypes from 'prop-types';
import { Dropdown as AntdDropdown } from 'antd';

const Dropdown = ({
  children,
  on,
  icon,
  className,
  disabled,
  placement,
  transitionName,
  ...props
}) => (
  <AntdDropdown
    overlayClassName={className}
    overlay={children}
    trigger={on}
    placement={placement}
    transitionName={transitionName}
    disabled={disabled}
    {...props}
  >
    {icon}
  </AntdDropdown>
);

Dropdown.defaultProps = {
  on: ['click'],
  className: null,
  disabled: false,
  placement: 'bottomRight',
  transitionName: null,
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.node,
  ]).isRequired,
  on: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover', 'contextMenu'])),
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  transitionName: PropTypes.string,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
};
export default Dropdown;
