import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setIsRecoveryCodesSaved } from '../actions';
import { downloadAccountRecoveryCodesEndpoint } from '../endpoints';
import { DOWNLOAD_ACCOUNT_RECOVERY_CODES } from '../types';

const downloadAccountRecoveryCodesOperation = createLogic({
  type: DOWNLOAD_ACCOUNT_RECOVERY_CODES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = downloadAccountRecoveryCodesEndpoint;
    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { responseType: 'text' });

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(data, 'recovery-codes.txt', 'text/plain');
      dispatch(setIsRecoveryCodesSaved(true));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadAccountRecoveryCodesOperation;
