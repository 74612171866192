const namespace = 'onboarding';

export const UPDATE_ONBOARDING_STEP = `${namespace}/UPDATE_ONBOARDING_STEP`;
export const SUBMIT_ONBOARDING_GENERAL = `${namespace}/SUBMIT_ONBOARDING_GENERAL`;
export const SUBMIT_ONBOARDING_LOCATION = `${namespace}/SUBMIT_ONBOARDING_LOCATION`;
export const GET_ONBOARDING_USER_MYSELF = `${namespace}/GET_ONBOARDING_USER_MYSELF`;
export const SUBMIT_ONBOARDING_VIRTUAL_CLINIC_GENERAL = `${namespace}/SUBMIT_ONBOARDING_VIRTUAL_CLINIC_GENERAL`;
export const SUBMIT_ONBOARDING_VIRTUAL_CLINIC_COMPANY = `${namespace}/SUBMIT_ONBOARDING_VIRTUAL_CLINIC_COMPANY`;
export const UPLOAD_ONBOARDING_VIRTUAL_CLINIC_LOGO = `${namespace}/UPLOAD_ONBOARDING_VIRTUAL_CLINIC_LOGO`;
export const REMOVE_ONBOARDING_VIRTUAL_CLINIC_LOGO = `${namespace}/REMOVE_ONBOARDING_VIRTUAL_CLINIC_LOGO`;
