import { Tree } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/Button';
import SearchList from 'views/shared/SearchList';

const { DirectoryTree } = Tree;

const CommonDiagnosesComponent = ({
  searchQuery,
  commonDiseases,
  onChangeOtherDiagnosesVisible,
  onSearch,
  onSetDisease,
}) => (
  <>
    <div className="modal-diagnose__title">
      <FormattedMessage id="note.commonDiagnoses" />
    </div>
    <SearchList
      defaultValue={searchQuery}
      filterEntities={onSearch}
    />
    <div className="modal-diagnose__other-diagnoses">
      <DirectoryTree onSelect={onSetDisease} treeData={commonDiseases} />
    </div>
    <Button
      text={{ id: 'shared.otherDiagnose' }}
      kind="empty"
      onClick={onChangeOtherDiagnosesVisible}
    />
  </>
);

CommonDiagnosesComponent.defaultProps = {
  searchQuery: null,
};

CommonDiagnosesComponent.propTypes = {
  onChangeOtherDiagnosesVisible: PropTypes.func.isRequired,
  commonDiseases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSearch: PropTypes.func.isRequired,
  onSetDisease: PropTypes.func.isRequired,
  searchQuery: PropTypes.shape(),
};

export default CommonDiagnosesComponent;
