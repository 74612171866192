import { createSelector } from 'reselect';
import { prop, path, map } from 'ramda';

import profileName from 'utils/profileName';
import buildCollection from 'utils/buildCollection';

import { totalCountSelector, pageCountSelector, loadingSelector, endpointMetaSelector } from 'state/data/selectors';

import {
  fetchPushNotificationsEndpoint,
  fetchClinicsEndpoint,
  fetchPatientsEndpoint,
  validatePushNotificationsEndpoint,
  submitCreatePushNotificationEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const pushNotificationsIdsSelector = path(['pushNotifications', 'pushNotificationsIds']);
export const clinicsIdsSelector = path(['pushNotifications', 'clinicsIds']);
export const patientsIdsSelector = path(['pushNotifications', 'patientsIds']);
export const isBlankStateSelector = path(['pushNotifications', 'isBlankState']);
export const paginationSelector = path(['pushNotifications', 'pagination']);
export const clinicsListFilterParamsSelector = path(['pushNotifications', 'clinicsListFilterParams']);
export const clinicsListIdsSelector = path(['pushNotifications', 'clinicsListIds']);
export const patientsListFilterParamsSelector = path(['pushNotifications', 'patientsListFilterParams']);
export const patientsListIdsSelector = path(['pushNotifications', 'patientsListIds']);
export const confirmModalVisibleSelector = path(['pushNotifications', 'confirmModalVisible']);

export const pushNotificationsTotalCountSelector = state => (
  totalCountSelector(state, fetchPushNotificationsEndpoint.endpoint)
);

export const pushNotificationsPageCountSelector = state => (
  pageCountSelector(state, fetchPushNotificationsEndpoint.endpoint)
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const pushNotificationsSelector = createSelector(
  pushNotificationsIdsSelector,
  dataSelector,
  buildCollection('pushNotification'),
);

export const pushNotificationsLoadingSelector = (state) => loadingSelector(
  state,
  fetchPushNotificationsEndpoint.endpoint,
);

export const clinicsListMetaSelector = state => (
  endpointMetaSelector(state, fetchClinicsEndpoint.endpoint)
);

export const clinicsListLoadingSelector = state => (
  loadingSelector(state, fetchClinicsEndpoint.endpoint)
);

export const clinicsListSelector = createSelector(
  clinicsListIdsSelector,
  dataSelector,
  buildCollection('clinic'),
);

export const clinicsListForFilterSelector = createSelector(
  clinicsListSelector,
  map(({ id, name }) => ({ id, key: id, value: id, label: name })),
);

export const patientsListMetaSelector = state => (
  endpointMetaSelector(state, fetchPatientsEndpoint.endpoint)
);

export const patientsListLoadingSelector = state => (
  loadingSelector(state, fetchPatientsEndpoint.endpoint)
);

export const patientsListSelector = createSelector(
  patientsListIdsSelector,
  dataSelector,
  buildCollection('patientProfile'),
);

export const patientsListForFilterSelector = createSelector(
  patientsListSelector,
  map((patient) => ({
    id: patient.id,
    key: patient.id,
    value: patient.id,
    label: profileName(patient),
  })),
);

export const validatePushNotificationsTotalCountSelector = state => {
  const { total } = endpointMetaSelector(state, validatePushNotificationsEndpoint.endpoint);

  return total ?? 0;
};

export const validatePushNotificationsLoadingSelector = state => (
  loadingSelector(state, validatePushNotificationsEndpoint.endpoint)
);

export const submitPushNotificationsLoadingSelector = state => (
  loadingSelector(state, submitCreatePushNotificationEndpoint.endpoint)
);
