import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from 'constants';

import yup from 'lib/yupLocalised';

import { handleSubmit } from 'utils/form/handleSubmit';

import { submitSetupTwoFactorAuth } from 'state/concepts/userProfile/account/actions';

import ConfirmSetupTwoFactorAuthModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmSetupTwoFactorAuthModal extends React.Component {
  render = () => (
    <ConfirmSetupTwoFactorAuthModalComponent {...this.props} />
  );
}

const mapDispatchToProps = {
  onSubmit: submitSetupTwoFactorAuth,
};

export { ConfirmSetupTwoFactorAuthModal as ConfirmSetupTwoFactorAuthModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      password: '',
    }),
    validationSchema: yup.object().shape({
      password: yup.string().min(MIN_PASSWORD_LENGTH).max(MAX_PASSWORD_LENGTH).required(),
    }),
    handleSubmit,
  }),
)(ConfirmSetupTwoFactorAuthModal);
