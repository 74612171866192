import fetchAccountRecoveryCodes from './fetchAccountRecoveryCodes';
import fetchRecoveryCodes from './fetchRecoveryCodes';
import downloadRecoveryCodes from './downloadRecoveryCodes';
import downloadAccountRecoveryCodes from './downloadAccountRecoveryCodes';

export default [
  fetchAccountRecoveryCodes,
  fetchRecoveryCodes,
  downloadRecoveryCodes,
  downloadAccountRecoveryCodes,
];
