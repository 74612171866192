import platformLocaleEn from 'locales/platform';
import platformLocaleEs from 'locales/platform.es';

import { LOCALE } from 'constants';
import { LANGUAGES } from 'constants/intl';

const platformLocale = LOCALE === LANGUAGES.es ? platformLocaleEs : platformLocaleEn;

const initialState = {
  ...platformLocale,
};

const intlReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default intlReducer;
