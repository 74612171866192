import endpoint from 'utils/endpoint';

import { usersRecurringAvailabilityRoute } from 'lib/apiRoutes';
import {
  FETCH_RECURRING_AVAILABILITY,
  UPDATE_RECURRING_AVAILABILITY,
} from './types';

export const fetchRecurringAvailabilityEndpoint = userId => endpoint(FETCH_RECURRING_AVAILABILITY, 'GET', usersRecurringAvailabilityRoute(userId));
export const updateRecurringAvailabilityEndpoint = userId => endpoint(UPDATE_RECURRING_AVAILABILITY, 'PUT', usersRecurringAvailabilityRoute(userId));
