const namespace = 'vouchers';

export const FETCH_VOUCHERS = `${namespace}/FETCH_VOUCHERS`;
export const SET_VOUCHERS = `${namespace}/SET_VOUCHERS`;
export const FETCH_VOUCHER_DETAILS = `${namespace}/FETCH_VOUCHER_DETAILS`;
export const SET_VOUCHERS_BLANK_STATE = `${namespace}/SET_VOUCHERS_BLANK_STATE`;
export const SET_VOUCHERS_SORT_ORDER = `${namespace}/SET_VOUCHERS_SORT_ORDER`;
export const SET_VOUCHERS_PAGE = `${namespace}/SET_VOUCHERS_PAGE`;
export const SELECT_VOUCHER = `${namespace}/SELECT_VOUCHER`;
export const SET_VOUCHERS_SELECTED = `${namespace}/SET_VOUCHERS_SELECTED`;
export const SET_VOUCHERS_FILTER = `${namespace}/SET_VOUCHERS_FILTER`;
export const REMOVE_VOUCHERS = `${namespace}/REMOVE_VOUCHERS`;
export const DOWNLOAD_VOUCHERS_CSV = `${namespace}/DOWNLOAD_VOUCHERS_CSV`;
export const GENERATE_VOUCHERS = `${namespace}/GENERATE_VOUCHERS`;
export const SET_VOUCHERS_ACTIVE_TAB = `${namespace}/SET_VOUCHERS_ACTIVE_TAB`;
