import getRevenue from './getRevenue';
import fetchRevenue from './fetchRevenue';
import filterRevenue from './filterRevenue';
import filterRevenueVirtualClinicDoctors from './filterRevenueVirtualClinicDoctors';
import initialStateRevenue from './initialStateRevenue';
import resetRevenue from './resetRevenue';
import fetchRevenueVirtualClinics from './fetchRevenueVirtualClinics';
import fetchRevenueExtraStatistic from './fetchRevenueExtraStatistic';
import fetchRevenueDoctorsList from './fetchRevenueDoctorsList';
import filterRevenueDoctorsList from './filterRevenueDoctorsList';
import resetRevenueDoctorsList from './resetRevenueDoctorsList';
import fetchRevenueVirtualClinicDoctors from './fetchRevenueVirtualClinicDoctors';
import getDownloadRevenue from './getDownloadRevenue';
import downloadRevenue from './downloadRevenue';
import downloadRevenueDoctors from './downloadRevenueDoctors';
import downloadRevenueIndependentDoctorAppointments from './downloadRevenueIndependentDoctorAppointments';
import downloadRevenueVirtualClinicAppointments from './downloadRevenueVirtualClinicAppointments';

export default [
  getRevenue,
  fetchRevenue,
  filterRevenue,
  filterRevenueVirtualClinicDoctors,
  initialStateRevenue,
  resetRevenue,
  fetchRevenueVirtualClinics,
  fetchRevenueExtraStatistic,
  fetchRevenueDoctorsList,
  filterRevenueDoctorsList,
  resetRevenueDoctorsList,
  fetchRevenueVirtualClinicDoctors,
  getDownloadRevenue,
  downloadRevenue,
  downloadRevenueDoctors,
  downloadRevenueIndependentDoctorAppointments,
  downloadRevenueVirtualClinicAppointments,
];
