import { FormattedMessage } from 'react-intl';

const NotFoundContent = () => (
  <span className="multiselect-field__empty">
    <span className="multiselect-field__empty-text">
      <FormattedMessage id="shared.noResults" />
    </span>
  </span>
);

export default NotFoundContent;
