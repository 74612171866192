import * as types from './types';

export const fetchPatients = () => ({
  type: types.FETCH_PATIENTS,
});

export const setPatients = patientIds => ({
  type: types.SET_PATIENTS,
  patientIds,
});

export const setBlankState = isBlankState => ({
  type: types.SET_PATIENTS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_PATIENTS_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_PATIENTS_SORT_ORDER,
  sortKey,
});

export const filterPatients = filters => ({
  type: types.SET_PATIENTS_FILTER,
  filters,
});

export const downloadPatientsCsv = () => ({
  type: types.DOWNLOAD_PATIENTS_CSV,
});
