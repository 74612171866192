import endpoint from 'utils/endpoint';
import {
  virtualClinicUserRoute,
  virtualClinicUsersRoute,
  virtualClinicDoctorsJoinRequestRoute,
  virtualClinicUsersInvitationsRoute,
  userDeactivateRoute,
  userActivateRoute,
  usersJoinRequestRoute,
  virtualClinicsSpecialitiesManageRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchVirtualClinicDoctorsEndpoint = endpoint(types.FETCH_VIRTUAL_CLINIC_DOCTORS, 'GET', virtualClinicUsersRoute);
export const submitVirtualClinicDoctorJoinRequestEndpoint = endpoint(types.SUBMIT_VCD_JOIN_REQUEST, 'POST', virtualClinicDoctorsJoinRequestRoute);
export const deactivateVirtualClinicDoctorEndpoint = vcdId => endpoint(types.DEACTIVATE_VIRTUAL_CLINIC_DOCTOR, 'PUT', userDeactivateRoute(vcdId));
export const activateVirtualClinicDoctorEndpoint = vcdId => endpoint(types.ACTIVATE_VIRTUAL_CLINIC_DOCTOR, 'PUT', userActivateRoute(vcdId));
export const fetchVirtualClinicDoctorEndpoint = vcdId => endpoint(types.FETCH_VIRTUAL_CLINIC_DOCTOR, 'GET', virtualClinicUserRoute(vcdId));
export const updateVirtualClinicDoctorEndpoint = vcdId => endpoint(types.UPDATE_VIRTUAL_CLINIC_DOCTOR, 'PUT', virtualClinicUserRoute(vcdId));
export const removeVirtualClinicDoctorJoinRequestEndpoint = vcdId => endpoint(types.REMOVE_VIRTUAL_CLINIC_DOCTOR_JOIN_REQUEST, 'DELETE', usersJoinRequestRoute(vcdId));
export const removeVirtualClinicDoctorInvitationEndpoint = vcdId => endpoint(types.REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION, 'DELETE', virtualClinicUsersInvitationsRoute(vcdId));
export const virtualClinicsSpecialitiesManageEndpoint = endpoint(types.SUBMIT_MANAGE_SPECIALITY, 'PUT', virtualClinicsSpecialitiesManageRoute);
