import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import { USER_ROLE } from 'constants/users';
import { DOCTORS_STATUSES } from 'constants/doctors';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSearchResults } from 'state/searchResults/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { getDoctorsEndpoint } from '../endpoints';
import { GET_DOCTORS } from '../types';

const getDoctorsOperation = createLogic({
  type: GET_DOCTORS,
  latest: true,

  async process({ httpClient, getState, action: { searchQuery } }, dispatch, done) {
    const { endpoint, url } = getDoctorsEndpoint;
    const state = getState();
    const currentUser = currentUserSelector(state);
    const roles = isVirtualClinicAdmin(currentUser.roleName)
      ? [USER_ROLE.virtualClinicDoctor]
      : [USER_ROLE.virtualClinicDoctor, USER_ROLE.doctor];
    const clinicIds = isVirtualClinicAdmin(currentUser.roleName)
      ? [currentUser.clinic.id] : null;

    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      filter: {
        statuses: [DOCTORS_STATUSES.active, DOCTORS_STATUSES.inactive],
        name: searchQuery,
        roles,
        clinic_ids: clinicIds,
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);
      const ids = pluck('id', data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSearchResults({ doctors: ids }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getDoctorsOperation;
