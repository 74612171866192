import { createLogic } from 'redux-logic';

import {
  fetchClinicSpecialitiesList,
  setClinicSpecialitiesListFilterParams,
} from '../actions';
import { SET_CLINIC_SPECIALITIES_LIST_FILTERS } from '../types';

const filterClinicSpecialitiesListOperation = createLogic({
  type: SET_CLINIC_SPECIALITIES_LIST_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setClinicSpecialitiesListFilterParams(filters));
    dispatch(fetchClinicSpecialitiesList());
    done();
  },
});

export default filterClinicSpecialitiesListOperation;
