import cookies from 'component-cookie';
import { propOr } from 'ramda';

import { COOKIES_EXPIRED } from 'constants';

export default (currentUser, jwt, userType, isLoggedIn, cookieOptions = { path: '/', maxage: COOKIES_EXPIRED }) => {
  cookies('currentUserId', currentUser.id, cookieOptions);
  cookies('currentUserRole', propOr('patient', 'roleName', currentUser), cookieOptions);
  cookies('tokens', JSON.stringify(jwt), cookieOptions);
  cookies('userType', userType, cookieOptions);
  cookies('isLoggedIn', isLoggedIn, cookieOptions);
};
