import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Tree from '../Tree';

const AttentionComponent = ({
  completeDiseases,
  cancelDiseases,
  onSetDisease,
}) => (
  <div className="modal-attention">
    <div className="ant-modal__body">
      <div className="modal-attention__title">
        <FormattedMessage id="note.attention.title" />
      </div>
      <div className="modal-attention__txt">
        <FormattedMessage id="note.attention.useFollowingCodes" />
      </div>
      <div className="modal-diagnose__row mb-16">
        <Tree
          options={cancelDiseases}
          selectAction={onSetDisease}
          isSingleNode
        />
      </div>
      <div className="modal-attention__txt">
        <FormattedMessage id="note.attention.refundCost" />
      </div>
      <div className="modal-attention__txt">
        <FormattedMessage id="note.attention.applyingCodes" />
      </div>
      <div className="modal-diagnose__row mb-16">
        <Tree
          options={completeDiseases}
          selectAction={onSetDisease}
          isSingleNode
        />
      </div>
      <div className="modal-attention__txt">
        <FormattedMessage id="note.attention.notApplicable" />
      </div>
    </div>
  </div>
);

AttentionComponent.propTypes = {
  completeDiseases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cancelDiseases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSetDisease: PropTypes.func.isRequired,
};

export default AttentionComponent;
