export const TRACK_KIND = {
  audio: 'audio',
  video: 'video',
  data: 'data',
  screen: 'screen',
};

export const VIDEO_CONSTRAINTS = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
};

export const VIEWS = {
  self: 'self',
  collocutor: 'collocutor',
  both: 'both',
};

export const CONNECTION_STATES = {
  connected: 'connected',
  reconnecting: 'reconnecting',
  disconnected: 'disconnected',
};

export const MAX_IDLE_TIME = 10 * 1000;
