import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { fetchVouchersBatches, setCurrentPage } from '../actions';
import { SET_VOUCHERS_BATCHES_FILTER } from '../types';

const filterVouchersBatchesOperation = createLogic({
  type: SET_VOUCHERS_BATCHES_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    dispatch(fetchVouchersBatches());
    done();
  },
});

export default filterVouchersBatchesOperation;
