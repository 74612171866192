import { createLogic } from 'redux-logic';

import {
  resetRevenueFilterParams,
  setRevenueType,
  setRevenueCurrentPage,
  setRevenueVirtualClinicId,
  setRevenueAppointmentIds,
  setRevenueVirtualClinicIds,
} from '../actions';
import { RESET_REVENUE } from '../types';

const resetRevenueOperation = createLogic({
  type: RESET_REVENUE,
  latest: true,

  async process(_, dispatch, done) {
    dispatch(resetRevenueFilterParams());
    dispatch(setRevenueType(null));
    dispatch(setRevenueCurrentPage(1));
    dispatch(setRevenueVirtualClinicId(null));
    dispatch(setRevenueAppointmentIds([]));
    dispatch(setRevenueVirtualClinicIds([]));

    done();
  },
});

export default resetRevenueOperation;
