import { createLogic } from 'redux-logic';
import { length, flatten, pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchVirtualClinicDoctors, fetchVirtualClinicDoctor } from 'state/concepts/userProfile/virtualClinicDoctors/actions';
import { virtualClinicsSpecialitiesManageEndpoint } from '../endpoints';
import { SUBMIT_MANAGE_SPECIALITY } from '../types';

const submitManageClinicSpecialityOperation = createLogic({
  type: SUBMIT_MANAGE_SPECIALITY,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { url, endpoint } = virtualClinicsSpecialitiesManageEndpoint;
    const { userId, clinicSpecialities } = values;
    const count = length(flatten(pluck('clinicSpecialityIds', clinicSpecialities)));

    const params = {
      user_id: userId,
      clinic_specialities: clinicSpecialities.map(clinicSpeciality => ({
        clinic_speciality_ids: clinicSpeciality.clinicSpecialityIds.map(id => Number(id)),
        price: clinicSpeciality.price,
        free_visit: clinicSpeciality.freeVisit,
      })),
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url, params);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(fetchVirtualClinicDoctor(userId));
      dispatch(fetchVirtualClinicDoctors());
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.doctorAssignClinicSpecialities',
          values: { count },
        },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitManageClinicSpecialityOperation;
