const namespace = 'clinicSpecialities';

export const FETCH_CLINIC_SPECIALITIES = `${namespace}/FETCH_CLINIC_SPECIALITIES`;
export const SET_CLINIC_SPECIALITIES = `${namespace}/SET_CLINIC_SPECIALITIES`;
export const SET_CLINIC_SPECIALITIES_PAGE = `${namespace}/SET_CLINIC_SPECIALITIES_PAGE`;
export const SET_CLINIC_SPECIALITIES_SORT_ORDER = `${namespace}/SET_CLINIC_SPECIALITIES_SORT_ORDER`;
export const SET_CLINIC_SPECIALITIES_FILTER = `${namespace}/SET_CLINIC_SPECIALITIES_FILTER`;
export const SET_CLINIC_SPECIALITIES_SELECTED = `${namespace}/SET_CLINIC_SPECIALITIES_SELECTED`;
export const SET_CLINIC_SPECIALITIES_BLANK_STATE = `${namespace}/SET_CLINIC_SPECIALITIES_BLANK_STATE`;
export const SELECT_CLINIC_SPECIALITIES = `${namespace}/SELECT_CLINIC_SPECIALITIES`;
export const ACTIVATE_CLINIC_SPECIALITIES = `${namespace}/ACTIVATE_CLINIC_SPECIALITIES`;
export const DEACTIVATE_CLINIC_SPECIALITIES = `${namespace}/DEACTIVATE_CLINIC_SPECIALITIES`;
export const REMOVE_CLINIC_SPECIALITIES = `${namespace}/REMOVE_CLINIC_SPECIALITIES`;
export const REMOVE_SELECTED_CLINIC_SPECIALITIES = `${namespace}/REMOVE_SELECTED_CLINIC_SPECIALITIES`;
export const FETCH_ALL_SPECIALITIES = `${namespace}/FETCH_ALL_SPECIALITIES`;
export const SUBMIT_CLINIC_SPECIALITY = `${namespace}/SUBMIT_CLINIC_SPECIALITY`;
export const UPDATE_CLINIC_SPECIALITY = `${namespace}/UPDATE_CLINIC_SPECIALITY`;
export const SHOW_CLINIC_SPECIALITY_DETAILS = `${namespace}/SHOW_CLINIC_SPECIALITY_DETAILS`;
export const SUBMIT_VIRTUAL_CLINIC_SPECIALITY = `${namespace}/SUBMIT_VIRTUAL_CLINIC_SPECIALITY`;
export const UPDATE_VIRTUAL_CLINIC_SPECIALITY = `${namespace}/UPDATE_VIRTUAL_CLINIC_SPECIALITY`;
export const SUBMIT_CLINIC_SPECIALITY_PROVIDERS = `${namespace}/SUBMIT_CLINIC_SPECIALITY_PROVIDERS`;
export const CLINIC_SPECIALITY_ASSIGN_DOCTORS_INITIAL_STATE = `${namespace}/CLINIC_SPECIALITY_ASSIGN_DOCTORS_INITIAL_STATE`;
export const CLINIC_SPECIALITY_ASSIGN_DOCTORS_RESET_STATE = `${namespace}/CLINIC_SPECIALITY_ASSIGN_DOCTORS_RESET_STATE`;

export const FETCH_CLINIC_SPECIALITIES_LIST = `${namespace}/FETCH_CLINIC_SPECIALITIES_LIST`;
export const SET_CLINIC_SPECIALITIES_LIST_FILTERS = `${namespace}/SET_CLINIC_SPECIALITIES_LIST_FILTERS`;
export const SET_CLINIC_SPECIALITIES_LIST_IDS = `${namespace}/SET_CLINIC_SPECIALITIES_LIST_IDS`;
export const RESET_CLINIC_SPECIALITIES_LIST_IDS = `${namespace}/RESET_CLINIC_SPECIALITIES_LIST_IDS`;
export const SET_CLINIC_SPECIALITIES_LIST_STATUSES = `${namespace}/SET_CLINIC_SPECIALITIES_LIST_STATUSES`;
export const RESET_CLINIC_SPECIALITIES_LIST_STATUSES = `${namespace}/RESET_CLINIC_SPECIALITIES_LIST_STATUSES`;
export const SET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS = `${namespace}/SET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS`;
export const RESET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS = `${namespace}/RESET_CLINIC_SPECIALITIES_LIST_FILTER_PARAMS`;
export const SET_CLINIC_SPECIALITIES_LIST_SORT = `${namespace}/SET_CLINIC_SPECIALITIES_LIST_SORT`;
export const RESET_CLINIC_SPECIALITIES_LIST_SORT = `${namespace}/RESET_CLINIC_SPECIALITIES_LIST_SORT`;
