import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { prop, propOr } from 'ramda';

import isPresent from 'utils/isPresent';

const GeneralComponent = ({
  note,
}) => (
  <table className="modal-view-note__diagnose modal-view-note__diagnose--top mb-32">
    <tbody>
      <tr>
        <td>
          <FormattedMessage id="note.modal.chiefComplaint" />
        </td>
        <td colSpan="2">
          {prop('chiefCompliant', note)}
        </td>
      </tr>
      <tr>
        <td>
          <FormattedMessage id="note.modal.subjective" />
        </td>
        <td colSpan="2">
          {prop('subjective', note)}
        </td>
      </tr>
      <tr>
        <td>
          <FormattedMessage id="note.modal.objective" />
        </td>
        <td colSpan="2">
          {prop('objective', note)}
        </td>
      </tr>
      <tr>
        <td>
          <FormattedMessage id="note.modal.assessment" />
        </td>
        <td colSpan="2">
          {prop('assessment', note)}
        </td>
      </tr>
      <tr>
        <td>
          <FormattedMessage id="note.modal.primaryDiagnose" />
        </td>
        <td>
          {prop('primaryDiagnoseCode', note)}
        </td>
        <td className="f-700">
          {prop('primaryDiagnoseTitle', note)}
        </td>
      </tr>
      {propOr([], 'diseases', note).map(({ id, code, description }, index) => (
        <tr key={id}>
          <td>
            <FormattedMessage id="note.modal.secondaryDiagnose" values={{ count: index + 1 }} />
          </td>
          <td>{code}</td>
          <td>{description}</td>
        </tr>
      ))}
      {isPresent(prop('secretNote', note)) && (
        <tr>
          <td className="text-left" colSpan="2">
            <FormattedMessage id="note.modal.secretNote" />
          </td>
          <td>
            {prop('secretNote', note)}
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

GeneralComponent.propTypes = {
  note: PropTypes.shape().isRequired,
};

export default GeneralComponent;
