import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import { GENDERS, CHILDREN } from 'constants/pushNotifications';

import { handleSubmit } from 'utils/form/handleSubmit';

import validationSchema from 'lib/yupLocalised/schemas/pushNotification';

import {
  toggleConfirmModalVisible as toggleConfirmModalVisibleAction,
  submitCreatePushNotification as submitCreatePushNotificationAction,
  validatePushNotifications as validatePushNotificationsAction,
} from 'state/concepts/userProfile/pushNotifications/actions';
import {
  confirmModalVisibleSelector,
  validatePushNotificationsTotalCountSelector,
  validatePushNotificationsLoadingSelector,
} from 'state/concepts/userProfile/pushNotifications/selectors';

import CreatePushNotificationModalComponent from './component';

class CreatePushNotificationModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    validatePushNotifications: PropTypes.func.isRequired,
    toggleConfirmModalVisible: PropTypes.func.isRequired,
    submitCreatePushNotification: PropTypes.func.isRequired,
    values: PropTypes.shape().isRequired,
  };

  componentDidMount() {
    const { validatePushNotifications, values } = this.props;

    validatePushNotifications(values);
  }

  handleBlur = () => {
    const { validatePushNotifications, values } = this.props;

    validatePushNotifications(values);
  };

  handleChange = () => {
    const { validatePushNotifications, values } = this.props;

    validatePushNotifications(values);
  };

  handleConfirmModalSubmit = () => {
    const {
      submitCreatePushNotification,
      values,
      setErrors,
      setSubmitting,
      setStatus,
      resetForm,
      setValues,
    } = this.props;

    submitCreatePushNotification(values, setErrors, setSubmitting, setStatus, resetForm, setValues);
  };

  render = () => {
    const { toggleConfirmModalVisible } = this.props;

    return (
      <CreatePushNotificationModalComponent
        {...this.props}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onConfirmModalClose={toggleConfirmModalVisible}
        onConfirmModalSubmit={this.handleConfirmModalSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  confirmModalVisible: confirmModalVisibleSelector(state),
  recepientsCountForFilters: validatePushNotificationsTotalCountSelector(state),
  isLoadingSubmitButton: validatePushNotificationsLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: toggleConfirmModalVisibleAction,
  toggleConfirmModalVisible: toggleConfirmModalVisibleAction,
  validatePushNotifications: validatePushNotificationsAction,
  submitCreatePushNotification: submitCreatePushNotificationAction,
};

export { CreatePushNotificationModal as CreatePushNotificationModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      clinicIds: [],
      patientIds: [],
      specialityIds: [],
      gender: GENDERS.all,
      children: CHILDREN.all,
      ageFrom: '',
      ageTo: '',
      message: '',
    }),
    validationSchema,
    handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
  }),
)(injectIntl(CreatePushNotificationModal));
