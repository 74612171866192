import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchDashboards = () => ({
  type: types.FETCH_DASHBOARDS,
});

export const setDashboardsIds = dashboardsIds => ({
  type: types.SET_DASHBOARDS_IDS,
  dashboardsIds,
});

export const setBlankState = isBlankState => ({
  type: types.SET_DASHBOARDS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_DASHBOARDS_CURRENT_PAGE,
  pageNumber,
});

export const fetchClinicsList = lastId => ({
  type: types.FETCH_DASHBOARD_CLINICS,
  lastId,
});

export const filterClinicsList = filters => ({
  type: types.SET_DASHBOARD_CLINICS_LIST_FILTERS,
  filters,
});

export const setClinicsListIds = clinicsListIds => ({
  type: types.SET_DASHBOARD_CLINICS_IDS,
  clinicsListIds,
});

export const setClinicsListFilterParams = filterParams => ({
  type: types.SET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetClinicsListIds = () => ({
  type: types.RESET_DASHBOARD_CLINICS_LIST_IDS,
});

export const resetClinicsListFilterParams = () => ({
  type: types.RESET_DASHBOARD_CLINICS_LIST_FILTER_PARAMS,
});

export const fetchPatientsList = lastId => ({
  type: types.FETCH_DASHBOARD_PATIENTS,
  lastId,
});

export const filterPatientsList = filters => ({
  type: types.SET_DASHBOARD_PATIENTS_LIST_FILTERS,
  filters,
});

export const setPatientsListIds = patientsListIds => ({
  type: types.SET_DASHBOARD_PATIENTS_IDS,
  patientsListIds,
});

export const setPatientsListFilterParams = filterParams => ({
  type: types.SET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS,
  filterParams,
});

export const resetPatientsListIds = () => ({
  type: types.RESET_DASHBOARD_PATIENTS_LIST_IDS,
});

export const resetPatientsListFilterParams = () => ({
  type: types.RESET_DASHBOARD_PATIENTS_LIST_FILTER_PARAMS,
});

export const validateDashboards = values => ({
  type: types.VALIDATE_DASHBOARDS,
  values,
});

export const filterAvailabilities = filters => ({
  type: types.SET_AVAILABILITIES_FILTER,
  filters,
});

export const submitCreatePushNotification = makeFormSubmitAction(
  types.SUBMIT_CREATE_DASHBOARD,
);

export const toggleConfirmModalVisible = () => ({
  type: types.TOGGLE_DASHBOARD_CONFIRM_MODAL_VISIBLE,
});
