import submitSetupTwoFactorAuth from './submitSetupTwoFactorAuth';
import submitUpdateUserPassword from './submitUpdateUserPassword';
import submitAccountTwoFactorAuthentication from './submitAccountTwoFactorAuthentication';
import submitUpdateUserEmail from './submitUpdateUserEmail';
import confirmUserEmail from './confirmUserEmail';
import updateNotificationSetting from './updateNotificationSetting';

export default [
  submitSetupTwoFactorAuth,
  submitUpdateUserPassword,
  submitAccountTwoFactorAuthentication,
  submitUpdateUserEmail,
  confirmUserEmail,
  updateNotificationSetting,
];
