import { combineReducers } from 'redux';
import { without, union } from 'ramda';

import { SPECIALITIES_STATUSES } from 'constants/specialities';

import * as types from './types';

const specialityIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES:
      return action.specialityIds;
    default:
      return state;
  }
};

const symptomsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SPECIALITY_SYMPTOMS_IDS:
      return action.symptomsIds;
    case types.RESET_SPECIALITY_SYMPTOMS_IDS:
      return [];
    default:
      return state;
  }
};

const selectedSpecialities = (state = [], action) => {
  switch (action.type) {
    case types.SELECT_SPECIALITY:
      return state.includes(action.specialityId)
        ? without([action.specialityId], state)
        : [...state, action.specialityId];
    case types.SET_SPECIALITIES_SELECTED:
      return action.specialityIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const specialitiesListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_LIST_IDS:
      return action.ids;
    case types.ADD_SPECIALITIES_LIST_IDS:
      return union(state, action.ids);
    case types.RESET_SPECIALITIES_LIST_IDS:
      return [];
    default:
      return state;
  }
};

const specialitiesListStatuses = (state = [SPECIALITIES_STATUSES.active], action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_LIST_STATUSES:
      return action.statuses;
    case types.RESET_SPECIALITIES_LIST_STATUSES:
      return [SPECIALITIES_STATUSES.active];
    default:
      return state;
  }
};

const initialState = {
  name: '',
  excludeSpecialityIds: [],
};

const specialitiesListFilterParams = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SPECIALITIES_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_SPECIALITIES_LIST_FILTER_PARAMS:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  specialityIds,
  symptomsIds,
  selectedSpecialities,
  isBlankState,
  pagination,
  sort,
  specialitiesListIds,
  specialitiesListStatuses,
  specialitiesListFilterParams,
});
