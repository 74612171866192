import * as types from './types';

export const setDoctorProfileId = (profileId) => ({
  type: types.SET_DOCTOR_PROFILE_ID,
  profileId,
});

export const fetchDoctorProfile = (slug, response) => ({
  type: types.FETCH_DOCTOR_PROFILE,
  slug,
  response,
});
