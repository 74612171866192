import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import { isErrorStatusGone } from 'utils/getErrorStatus';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { validateResetPasswordTokenEndpoint } from '../endpoints';
import { VALIDATE_RESET_PASSWORD_TOKEN } from '../types';

const validateResetPasswordTokenOperation = createLogic({
  type: VALIDATE_RESET_PASSWORD_TOKEN,
  latest: true,

  async process({
    httpClient,
    action: {
      token,
    },
  }, dispatch, done) {
    const { endpoint, url } = validateResetPasswordTokenEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.get(url, { params: { token } });

      dispatch(dataApiSuccess({ response: {}, endpoint }));
    } catch (error) {
      if (isErrorStatusGone(error)) {
        redirect({ href: '/reset_password/link_expired' });
      } else {
        requestErrorHandler({ error, dispatch, endpoint });
      }
    }

    done();
  },
});

export default validateResetPasswordTokenOperation;
