import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { resendVirtualClinicInvitationEndpoint } from '../endpoints';
import { RESEND_VIRTUAL_CLINIC_INVITATION } from '../types';

const resendVirtualClinicInvitationOperation = createLogic({
  type: RESEND_VIRTUAL_CLINIC_INVITATION,
  latest: true,

  async process({ httpClient, action: { virtualClinicId } }, dispatch, done) {
    const { endpoint, url } = resendVirtualClinicInvitationEndpoint(virtualClinicId);
    const params = {
      redirect_to: '/invitation_signup',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.post(url, params);

      dispatch(showNotification({ messageObject: { id: 'notifications.virtualClinicInvitationResent' } }));
      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.virtualClinicInvitationResentError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default resendVirtualClinicInvitationOperation;
