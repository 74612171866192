import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { prop } from 'ramda';

import { TOP_UP_AMOUNT_OPTIONS } from 'constants/vouchers';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import RadioField from 'views/shared/RadioField';
import InputField from 'views/shared/InputField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import SvgIcon from 'views/shared/SvgIcon';

const TopUpWithPagoparModalComponent = ({
  onClose,
  onChange,
  handleSubmit,
  dirty,
  isVisible,
  status,
}) => (
  <Modal wrapClassName="ant-modal-wrap__4" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="patient.balance.topUpWithPagopar.modal.title" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      {prop('base', status) && (
        <Alert
          className="mb-16"
          message={prop('base', status)}
        />
      )}
      <p className="modal-top-up__txt">
        <FormattedMessage id="patient.balance.topUpWithPagopar.modal.text" />
      </p>
      <ul className="'modal-top-up__list">
        <Field
          id="amount"
          name="amount"
          component={RadioField}
          options={TOP_UP_AMOUNT_OPTIONS}
          onChange={onChange}
        />
      </ul>
      {isVisible && (
        <Field
          id="otherAmount"
          name="otherAmount"
          component={InputField}
          label={{ id: 'label.otherAmount' }}
          icon="alert"
          fullWidth
        />
      )}
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        className="w-160"
        text={{ id: 'shared.continue' }}
        overrideKind={[null, 'primary']}
        disabled={!dirty}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

TopUpWithPagoparModalComponent.defaultProps = {
  status: undefined,
};

TopUpWithPagoparModalComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape(),
};

export default TopUpWithPagoparModalComponent;
