import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck, length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import isActivePatientTabIsChildren from 'utils/isActivePatientTabIsChildren';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { setBlankState, setPatientAppointments } from '../actions';
import {
  paginationSelector,
  searchQuerySelector,
  sortParamsSelector,
  statusesSelector,
  patientChildrenIdsSelector,
  patientDetailsActiveTabSelector,
} from '../selectors';
import { fetchPatientAppointmentsEndpoint } from '../endpoints';
import { FETCH_PATIENT_APPOINTMENTS } from '../types';

const fetchPatientAppointmentsOperation = createLogic({
  type: FETCH_PATIENT_APPOINTMENTS,
  latest: true,

  async process({ httpClient, getState, action: { patientId } }, dispatch, done) {
    const state = getState();
    const user = currentUserSelector(state);
    const { endpoint, url } = fetchPatientAppointmentsEndpoint(patientId);
    const searchQuery = searchQuerySelector(state);
    const activeTab = patientDetailsActiveTabSelector(state);
    const patientChildrenIds = patientChildrenIdsSelector(state);

    const params = {
      include: 'clinic-speciality,patient-profile,speciality,user-profile,symptom,transactions',
      page: paginationSelector(state),
      sort: sortParamsSelector(state, 'sort'),
      filter: {
        speciality_or_symptom_name: searchQuery,
        timezone: user.timezone,
        statuses: statusesSelector(state),
        patient_only: !isActivePatientTabIsChildren(activeTab),
        child_profile_ids:
          (isActivePatientTabIsChildren(activeTab) && length(patientChildrenIds))
            ? patientChildrenIds
            : undefined,
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(setBlankState(isBlankState({ name: searchQuery }, data.data)));
      dispatch(setPatientAppointments(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPatientAppointmentsOperation;
