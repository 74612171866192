import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { prop } from 'ramda';

import profileName from 'utils/profileName';
import formatPhoneNumber from 'utils/formatPhoneNumber';

import Modal from 'views/shared/Modal';
import AttachedFile from 'views/shared/AttachedFile';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const PreviewJoinRequestModalComponent = ({
  joinRequest,
  onClose,
  fileSize,
  fileName,
  fileUrl,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="joinRequests.previewModal.title" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="modal-doctor-details">
        <h1 className="modal-doctor-details__title">{profileName(joinRequest)}</h1>
        <dl className="modal-doctor-details__list">
          <dt className="modal-doctor-details__key">
            <FormattedMessage id="shared.phoneNumber" />
          </dt>
          <dd className="modal-doctor-details__value">{formatPhoneNumber(prop('phone', joinRequest))}</dd>
        </dl>
        <dl className="modal-doctor-details__list">
          <dt className="modal-doctor-details__key">
            <FormattedMessage id="shared.email.description" />
          </dt>
          <dd className="modal-doctor-details__value">{prop('email', joinRequest)}</dd>
        </dl>
        <h2 className="modal-doctor-details__subtitle">
          <FormattedMessage id="shared.files" />
        </h2>
        <div className="modal-doctor-details__download">
          <span className="file-uploader mb-8">
            <AttachedFile fileName={fileName} fileSize={fileSize} />
          </span>
          <Button
            text={{ id: 'shared.download' }}
            kind="empty"
            icon="download"
            className="w-100"
            isLink
            isDownload
            href={fileUrl}
          />
        </div>
      </div>
    </div>
  </Modal>
);

PreviewJoinRequestModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  joinRequest: PropTypes.shape().isRequired,
};

export default PreviewJoinRequestModalComponent;
