import { createLogic } from 'redux-logic';

import { ONBOARDING_STATUSES } from 'constants/onboarding';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import updateDataHelper from 'utils/updateDataHelper';
import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { currentUserIdSelector, currentUserClinicIdSelector } from 'state/concepts/session/selectors';
import { submitVirtualClinicEndpoint } from '../endpoints';
import { SUBMIT_ONBOARDING_VIRTUAL_CLINIC_COMPANY } from '../types';

const submitOnboardingVirtualClinicCompanyOperation = createLogic({
  type: SUBMIT_ONBOARDING_VIRTUAL_CLINIC_COMPANY,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const state = getState();
    const currentUserId = currentUserIdSelector(state);
    const clinicId = currentUserClinicIdSelector(state);
    const { url, endpoint } = submitVirtualClinicEndpoint(clinicId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        name: values.name,
        url: values.companyUrl,
        city: values.city,
        phone: formatISOPhoneNumber(values.phone),
        address: values.address,
        description: values.about,
      };

      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'userProfile', currentUserId,
        {
          attributes: {
            ...params,
            onboardingStatus: ONBOARDING_STATUSES.completed,
          },
        },
      );

      dispatch(dataApiSuccess({ endpoint, response }));
      redirect({ href: '/appointments' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitOnboardingVirtualClinicCompanyOperation;
