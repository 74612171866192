import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withFormik } from 'formik';

import yup from 'lib/yupLocalised';

import { MAX_INPUT_LENGTH } from 'constants';

import { handleSubmit } from 'utils/form/handleSubmit';

import { submitVCDJoinRequest } from 'state/concepts/userProfile/virtualClinicDoctors/actions';
import { submitVCDJoinRequestLoadingSelector } from 'state/concepts/userProfile/virtualClinicDoctors/selectors';

import VCDJoinRequestComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class VCDJoinRequest extends React.Component {
  render = () => (
    <VCDJoinRequestComponent
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: submitVCDJoinRequestLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitVCDJoinRequest,
};

export { VCDJoinRequest as VCDJoinRequestContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (() => ({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      cv: '',
    })),
    handleSubmit,
    validationSchema: yup.object().shape({
      firstName: yup.string()
        .max(MAX_INPUT_LENGTH)
        .required(),
      lastName: yup.string()
        .max(MAX_INPUT_LENGTH)
        .required(),
      email: yup.string()
        .isValidEmail()
        .required(),
      phone: yup.string()
        .required(),
      cv: yup.mixed()
        .required()
        .isValidFileFormat()
        .isValidFileSize(),
    }),
  }),
)(VCDJoinRequest);
