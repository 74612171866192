import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';

import { UPDATE_NOTIFICATION_SETTINGS } from '../types';
import { updateNotificationSettingEndpoint } from '../endpoints';
import { userNotificationSettingMetaSelector } from '../selectors';

const updateNotificationSettings = createLogic({
  type: UPDATE_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ httpClient, action: { token, values, form }, getState }, dispatch, done) {
    const { endpoint, url } = updateNotificationSettingEndpoint;
    const state = getState();
    const isUserNotificationSetting = userNotificationSettingMetaSelector(state);

    const params = {
      token,
      email_communication: isUserNotificationSetting
       ? values.isNotificationOn : values.emailCommunication,
      email_appointment: isUserNotificationSetting
       ? values.isNotificationOn : values.emailAppointment,
      email_reminder: isUserNotificationSetting ? values.isNotificationOn : values.emailReminder,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.put(url, params);

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(
        showNotification({
          messageObject: {
            id: 'userUnsubscribeEmail.preferencesHaveBeenSaved',
          },
        }),
      );
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      dispatch(
        showNotification({
          messageObject: {
            id: 'userUnsubscribeEmail.preferencesHaveNotBeenSaved',
          },
        }),
      );
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateNotificationSettings;
