import getDiseases from './getDiseases';
import filterDiseases from './filterDiseases';
import searchDiseases from './searchDiseases';
import getDiseasesNodes from './getDiseasesNodes';
import getCommonDiseases from './getCommonDiseases';
import getCompleteCancelDiseases from './getCompleteCancelDiseases';

export default [
  getDiseases,
  filterDiseases,
  searchDiseases,
  getDiseasesNodes,
  getCommonDiseases,
  getCompleteCancelDiseases,
];
