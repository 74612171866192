import endpoint from 'utils/endpoint';

import {
  voucherBatchesRoute,
  voucherBatchRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchVouchersBatchesEndpoint = endpoint(types.FETCH_VOUCHERS_BATCHES, 'GET', voucherBatchesRoute);
export const fetchVouchersBatchDetailsEndpoint = voucherBatchId => endpoint(types.FETCH_VOUCHERS_BATCHES, 'GET', voucherBatchRoute(voucherBatchId));
export const updateVouchersBatchDetailsEndpoint = voucherBatchId => endpoint(types.UPDATE_VOUCHERS_BATCH_DETAILS, 'PUT', voucherBatchRoute(voucherBatchId));
