import createMessage from './createMessage';
import updateMessage from './updateMessage';
import removeMessage from './removeMessage';
import receiveMessages from './receiveMessages';
import fetchMessages from './fetchMessages';
import markMessagesAsRead from './markMessagesAsRead';
import fetchAttachments from './fetchAttachments';
import removeAttachment from './removeAttachment';
import markAllMessagesAsRead from './markAllMessagesAsRead';
import uploadFile from './uploadFile';

export default [
  createMessage,
  updateMessage,
  removeMessage,
  receiveMessages,
  fetchMessages,
  markMessagesAsRead,
  fetchAttachments,
  removeAttachment,
  markAllMessagesAsRead,
  uploadFile,
];
