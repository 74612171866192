import { combineReducers } from 'redux';
import { union } from 'ramda';

import * as types from './types';

const freeVisitsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_IDS:
      return action.freeVisitsIds;
    default:
      return state;
  }
};

const freeVisitsBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const freeVisitsPagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const freeVisitsSort = (state = { sortKey: 'full_name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

const clinicsListInitialState = [];
const clinicsListIds = (state = clinicsListInitialState, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_CLINICS_LIST_IDS:
      return union(state, action.clinicsListIds);
    case types.RESET_FREE_VISITS_CLINICS_LIST_IDS:
      return clinicsListInitialState;
    default:
      return state;
  }
};

const clinicsInitialState = { name: '' };
const clinicsListFilterParams = (state = clinicsInitialState, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_FREE_VISITS_CLINICS_LIST_FILTER_PARAMS:
      return clinicsInitialState;
    default:
      return state;
  }
};

const patientsListInitialState = [];
const patientsListIds = (state = patientsListInitialState, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_PATIENTS_LIST_IDS:
      return union(state, action.patientsListIds);
    case types.RESET_FREE_VISITS_PATIENTS_LIST_IDS:
      return patientsListInitialState;
    default:
      return state;
  }
};

const patientsInitialState = { name: '' };
const patientsListFilterParams = (state = patientsInitialState, action) => {
  switch (action.type) {
    case types.SET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_FREE_VISITS_PATIENTS_LIST_FILTER_PARAMS:
      return patientsInitialState;
    default:
      return state;
  }
};

export default combineReducers({
  freeVisitsIds,
  freeVisitsBlankState,
  freeVisitsPagination,
  freeVisitsSort,
  clinicsListIds,
  clinicsListFilterParams,
  patientsListIds,
  patientsListFilterParams,
});
