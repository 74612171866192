import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { activateVirtualClinic } from 'state/concepts/userProfile/virtualClinics/actions';
import { activateVirtualClinicLoadingSelector } from 'state/concepts/userProfile/virtualClinics/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ActivateVirtualClinicModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    virtualClinic: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, virtualClinic: { id, clinic } } = this.props;

    onSubmit(id, clinic.id);
  }

  render() {
    const { virtualClinic } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'virtualClinics.clinics.activateModal.title' }}
        bodyText={[{
          id: 'virtualClinics.clinics.activateModal.body',
          values: { clinicName: <span className="f-700">{virtualClinic.clinic.name}</span> },
        }]}
        submitText={{ id: 'shared.activate' }}
      />
    );
  }
}

const mapStateToProps = (state, { virtualClinic: { clinic: { id } } }) => ({
  isLoading: activateVirtualClinicLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: activateVirtualClinic,
};

export { ActivateVirtualClinicModal as ActivateVirtualClinicModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ActivateVirtualClinicModal);
