import endpoint from 'utils/endpoint';

import {
  vouchersRoute,
  voucherRoute,
  vouchersBulkActionsRoute,
  vouchersBulkActionsRemoveRoute,
  vouchersBulkActionsDownloadRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchVouchersEndpoint = endpoint(types.FETCH_VOUCHERS, 'GET', vouchersRoute);
export const fetchVoucherDetailsEndpoint = voucherId => endpoint(types.FETCH_VOUCHER_DETAILS, 'GET', voucherRoute(voucherId));
export const generateVouchersEndpoint = endpoint(types.GENERATE_VOUCHERS, 'POST', vouchersBulkActionsRoute);
export const removeVouchersEndpoint = endpoint(types.REMOVE_VOUCHERS, 'DELETE', vouchersBulkActionsRemoveRoute);
export const downloadVouchersCsvEndpoint = endpoint(types.DOWNLOAD_VOUCHERS_CSV, 'GET', vouchersBulkActionsDownloadRoute);
