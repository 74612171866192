import { createLogic } from 'redux-logic';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { markChatMessagesAsReadEndpoint } from '../endpoints';
import { MARK_MESSAGES_AS_READ } from '../types';

const markMessagesAsReadOperation = createLogic({
  type: MARK_MESSAGES_AS_READ,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const state = getState();
    const { chatId, messageIds } = action;
    const { endpoint, url } = markChatMessagesAsReadEndpoint(chatId);

    try {
      if (!isEmpty(messageIds)) {
        const params = {
          message_ids: messageIds,
        };

        await httpClient.put(url, params);

        const response = updateDataHelper(
          state.data, 'message', messageIds,
          {
            attributes: {
              readAt: DateTime.local().toUTC(),
            },
          },
        );

        dispatch(dataApiSuccess({ response, endpoint }));
      } else {
        dispatch(dataApiSuccess({ endpoint }));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default markMessagesAsReadOperation;
