import React from 'react';
import { withFormik } from 'formik';
import { compose, propOr } from 'ramda';
import { connect } from 'react-redux';

import validationSchema from 'lib/yupLocalised/schemas/commissions';

import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { submitPlatformCommission } from 'state/concepts/userProfile/doctors/actions';

import ChangeCommissionModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class ChangeCommissionModal extends React.Component {
  render = () => (
    <ChangeCommissionModalComponent
      {...this.props}
    />
  );
}

const mapDispatchToProps = {
  onSubmit: submitPlatformCommission,
};

export { ChangeCommissionModal as ChangeCommissionModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ commission }) => ({
      minimum: propOr('', 'minimum', commission),
      maximum: propOr('', 'maximum', commission),
      rate: propOr('', 'rate', commission),
    }),
    validationSchema,
    enableReinitialize: true,
    handleSubmit: handleSubmitWithProps(['commission', 'roleName']),
  }),
)(ChangeCommissionModal);
