import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const validatePatientResetPasswordToken = token => ({
  type: types.VALIDATE_PATIENT_RESET_PASSWORD_TOKEN,
  token,
});

export const setResentPasswordStatus = status => ({
  type: types.SET_RESENT_PASSWORD_STATUS,
  status,
});

export const submitPatientNewPassword = makeFormSubmitAction(types.SUBMIT_PATIENT_NEW_PASSWORD);
export const submitPatientResetPassword = makeFormSubmitAction(types.SUBMIT_PATIENT_RESET_PASSWORD);
