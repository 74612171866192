import { createSelector } from 'reselect';
import { prop, path } from 'ramda';
import { DateTime } from 'luxon';
import buildCollection from 'utils/buildCollection';

import { totalCountSelector, pageCountSelector, loadingSelector, endpointMetaSelector } from 'state/data/selectors';
import { filtersSelector } from 'state/concepts/userProfile/filters/selectors';

import {
  fetchDashboardsEndpoint,
  validateDashboardsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const dashboardsIdsSelector = path(['dashboards', 'dashboardsIds']);
export const isBlankStateSelector = path(['dashboards', 'isBlankState']);
export const paginationSelector = path(['dashboards', 'pagination']);
export const confirmModalVisibleSelector = path(['dashboards', 'confirmModalVisible']);

export const dashboardsTotalCountSelector = state => (
  totalCountSelector(state, fetchDashboardsEndpoint.endpoint)
);

export const dashboardsPageCountSelector = state => (
  pageCountSelector(state, fetchDashboardsEndpoint.endpoint)
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const dashboardsSelector = createSelector(
  dashboardsIdsSelector,
  dataSelector,
  buildCollection('dashboard'),
);

export const dashboardsLoadingSelector = (state) => loadingSelector(
  state,
  fetchDashboardsEndpoint.endpoint,
);

export const validateDashboardsTotalCountSelector = state => {
  const { total } = endpointMetaSelector(state, validateDashboardsEndpoint.endpoint);

  return total ?? 0;
};

export const validateDashboardsLoadingSelector = state => (
  loadingSelector(state, validateDashboardsEndpoint.endpoint)
);

export const filterDateFromISOSelector = createSelector(
  filtersSelector,
  ({ startDate, endDate }) => (startDate && endDate
    ? [DateTime.fromISO(startDate), DateTime.fromISO(endDate)]
    : []
  ),
);
