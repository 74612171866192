import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { downloadPatientsCsvEndpoint } from '../endpoints';
import { DOWNLOAD_PATIENTS_CSV } from '../types';

const downloadPatientsCsvOperation = createLogic({
  type: DOWNLOAD_PATIENTS_CSV,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = downloadPatientsCsvEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.get(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(showNotification({ messageObject: { id: 'notifications.csvDownloadPatientsSuccess' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadPatientsFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadPatientsCsvOperation;
