import endpoint from 'utils/endpoint';

import {
  usersCommissionSettingsRoute,
  virtualClinicsCommissionSettingsRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchDoctorsCommissionsEndpoint = endpoint(types.FETCH_DOCTORS_COMMISSIONS, 'GET', usersCommissionSettingsRoute);
export const fetchVirtualClinicsCommissionsEndpoint = endpoint(types.FETCH_VIRTUAL_CLINICS_COMMISSIONS, 'GET', virtualClinicsCommissionSettingsRoute);
