import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  formattedTime,
  formattedWeekday,
  formattedDuration,
  isRescheduled,
} from 'utils/appointments';

import SvgIcon from 'views/shared/SvgIcon';

const AppointmentCalendarTooltipAppointmentDateInfo = ({ event }) => (
  <div
    className={classNames('modal-appointment__section', {
      'modal-appointment__section--rescheduled': isRescheduled(event),
    })}
  >
    {isRescheduled(event) && (
      <div className="modal-appointment__time modal-appointment__time--rescheduled">
        <div className="modal-appointment__time-range">
          <SvgIcon icon="time" />
          {formattedTime({
            startTime: event.oldStartTime,
            endTime: event.oldEndTime,
          }).toLowerCase()}
        </div>
        <SvgIcon icon="right-arrow" className="mb-4" />
        <div className="modal-appointment__time-range">
          <SvgIcon icon="calendar-o" />
          {formattedWeekday({ startTime: event.oldStartTime })}
        </div>
        <SvgIcon icon="right-arrow" />
      </div>
    )}
    <div className="modal-appointment__time modal-appointment__time--actual">
      <div className="modal-appointment__time-range modal-appointment__time-time">
        {
          !isRescheduled(event) && <SvgIcon icon="time" />
        }
        {formattedTime(event).toLowerCase()}
      </div>
      <p className="modal-appointment__time-range modal-appointment__time-date">
        {
          !isRescheduled(event) && <SvgIcon icon="calendar-o" />
        }
        {formattedWeekday(event)}
      </p>
      <p className="modal-appointment__time-duration">
        {formattedDuration(event)}
      </p>
    </div>
  </div>
);

AppointmentCalendarTooltipAppointmentDateInfo.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default AppointmentCalendarTooltipAppointmentDateInfo;
