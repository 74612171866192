import React from 'react';
import PropTypes from 'prop-types';

import RadioFieldComponent from './component';

class RadioFieldContainer extends React.Component {
  static propTypes = {
    field: PropTypes.shape().isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: null,
  }

  handleChange = (evt) => {
    const {
      field,
      onChange,
    } = this.props;
    field.onChange(evt);

    if (onChange) {
      onChange({ [field.name]: evt.target.value });
    }
  }

  render() {
    return (
      <RadioFieldComponent
        {...this.props}
        onChange={this.handleChange}
      />
    );
  }
}

export default RadioFieldContainer;
