import { createLogic } from 'redux-logic';
import { pick } from 'ramda';

import { ONBOARDING_STATUSES } from 'constants/onboarding';

import requestErrorHandler from 'lib/requestErrorHandler';

import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { submitGeneralInfoEndpoint } from '../endpoints';
import { SUBMIT_ONBOARDING_GENERAL } from '../types';

const submitOnboardingGeneralOperation = createLogic({
  type: SUBMIT_ONBOARDING_GENERAL,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = submitGeneralInfoEndpoint;
    const state = getState();
    const currentUserId = currentUserIdSelector(state);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: formatISOPhoneNumber(values.phoneNumber),
        about: values.about,
        license_number: values.licenseNumber,
      };

      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'userProfile', currentUserId,
        {
          attributes: {
            ...pick(['firstName', 'lastName', 'phoneNumber', 'about', 'licenseNumber'], values),
            onboardingStatus: ONBOARDING_STATUSES.location,
          },
        },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitOnboardingGeneralOperation;
