import {
  isEmpty, pipe, values, flatten, all, not,
} from 'ramda';

const isFiltersPresent = pipe(
  values,
  flatten,
  all(isEmpty),
  not,
);

export default (params, data) => !isFiltersPresent(params) && isEmpty(data);
