import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from 'state/modal/actions';

import FilesPreviewModalComponent from './component';

class FilesPreviewModal extends React.Component {
  static defaultProps = {
    initialFileId: undefined,
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    initialFileId: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    currentSlide: null,
    currentFiles: this.props.files,
  }

  sliderRef = React.createRef();

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  get initialSlideIndex() {
    const { initialFileId } = this.props;
    const { currentFiles } = this.state;

    return currentFiles.findIndex(file => file.id === initialFileId);
  }

  get currentSlide() {
    const { currentSlide, currentFiles } = this.state;

    return currentSlide || currentFiles[this.initialSlideIndex];
  }

  get currentLength() {
    const { currentFiles } = this.state;

    return currentFiles.length - 1;
  }

  get currentIndex() {
    const { currentFiles } = this.state;

    return currentFiles.findIndex(file => file.id === this.currentSlide.id);
  }

  get newFiles() {
    const { currentFiles } = this.state;

    return currentFiles.filter(file => file.id !== this.currentSlide.id);
  }

  get newIndex() {
    return this.currentLength === this.currentIndex ? this.currentIndex - 1 : this.currentIndex;
  }

  handleSlideChange = (_, newIndex) => {
    this.setState((state) => ({
      currentSlide: state.currentFiles[newIndex],
    }));
  }

  handleKeyDown = (event) => {
    const { onClose } = this.props;
    const slider = this.sliderRef.current;

    if (!slider) { return; }

    const keyHandlers = {
      27: onClose,
      37: slider.slickPrev,
      39: slider.slickNext,
    };

    const handler = keyHandlers[event.keyCode];

    if (handler) { handler(); }
  }

  render() {
    return (
      <FilesPreviewModalComponent
        {...this.props}
        {...this.state}
        ref={this.sliderRef}
        onKeyPress={this.handleKeyPress}
        currentSlide={this.currentSlide}
        initialSlide={this.initialSlideIndex}
        onSlideChange={this.handleSlideChange}
      />
    );
  }
}

const mapDispatchToProps = {
  onClose: hideModal,
};

export { FilesPreviewModal as FilesPreviewModalContainer };
export default connect(null, mapDispatchToProps)(FilesPreviewModal);
