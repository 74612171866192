import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const getUsersOwnProfile = () => ({
  type: types.GET_USER_OWN_PROFILE,
});

export const getUserMyself = response => ({
  type: types.GET_USER_MYSELF,
  response,
});

export const updateUserProfile = (values, form) => ({
  type: types.UPDATE_USER_PROFILE,
  values,
  form,
});

export const submitJoinRequest = makeFormSubmitAction(types.SUBMIT_JOIN_REQUEST);
export const deactivateUser = makeFormSubmitAction(types.DEACTIVATE_USER);

export const getUserCommissionSettings = () => ({
  type: types.GET_USER_COMMISSION_SETTINGS,
});
