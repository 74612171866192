import { createSelector } from 'reselect';
import build from 'redux-object';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { loadingSelector, pageCountSelector, totalCountSelector } from 'state/data/selectors';
import { filtersSelector } from 'state/concepts/userProfile/filters/selectors';
import {
  fetchVouchersBatchesEndpoint,
  fetchVouchersBatchDetailsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const vouchersBatchesIdsSelector = path(['vouchersBatches', 'vouchersBatchesIds']);
export const isBlankStateSelector = path(['vouchersBatches', 'isBlankState']);
export const paginationSelector = path(['vouchersBatches', 'pagination']);
export const sortSelector = path(['vouchersBatches', 'sort']);
export const selectedSelector = path(['vouchersBatches', 'selectedVouchers']);

export const vouchersBatchesSelector = createSelector(
  vouchersBatchesIdsSelector,
  dataSelector,
  buildCollection('voucherBatch'),
);

export const vouchersBatchDetailsSelector = createSelector(
  (_, vouchersBatchId) => vouchersBatchId,
  dataSelector,
  (id, data) => (id ? build(data, 'voucherBatch', id) : null),
);

export const isVouchersBatchDetailsSelector = createSelector(
  filtersSelector,
  (filters) => Boolean(prop('voucherBatchId', filters)),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const vouchersBatchesLoadingSelector = state => (
  loadingSelector(state, fetchVouchersBatchesEndpoint.endpoint)
);

export const vouchersBatchDetailsLoadingSelector = (state, id) => (
  loadingSelector(state, fetchVouchersBatchDetailsEndpoint(id).endpoint)
);

export const vouchersBatchesPageCountSelector = state => (
  pageCountSelector(state, fetchVouchersBatchesEndpoint.endpoint)
);

export const vouchersBatchesTotalCountSelector = state => (
  totalCountSelector(state, fetchVouchersBatchesEndpoint.endpoint)
);
