import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import {
  GENDER_OPTIONS,
  CHILDREN_OPTIONS,
  MIN_AGE_VALUE,
  MAX_AGE_VALUE,
} from 'constants/pushNotifications';

import translateOptions from 'utils/translateOptions';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';
import Button from 'views/shared/Button';
import SelectField from 'views/shared/SelectField';
import NumericField from 'views/shared/NumericField';
import TextEditorField from 'views/shared/TextEditorField';
import DropdownSpecialitiesField from 'views/shared/DropdownSpecialitiesField';
import DropdownClinicsField from 'views/shared/DropdownClinicsField';
import DropdownPatientsField from 'views/shared/DropdownPatientsField';

import CreatePushNotificationConfirmModal from '../CreatePushNotificationConfirmModal';

const CreatePushNotificationModalComponent = ({
  intl,
  onClose,
  handleSubmit,
  onBlur,
  onChange,
  recepientsCountForFilters,
  isLoadingSubmitButton,
  confirmModalVisible,
  onConfirmModalClose,
  onConfirmModalSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__8 ant-modal-wrap--invitation"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      {confirmModalVisible && (
        <CreatePushNotificationConfirmModal
          onClose={onConfirmModalClose}
          onSubmit={onConfirmModalSubmit}
          recepientsCount={recepientsCountForFilters}
        />
      )}
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="pushNotifications.modal.title" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <Field
        id="clinicIds"
        name="clinicIds"
        component={DropdownClinicsField}
        onBlur={onBlur}
        label={{ id: 'label.clinic' }}
        optionGroupLabel={{ id: 'label.clinic' }}
        placeholder={{ id: 'pushNotifications.modal.addClinic' }}
        className="multiselect-field multiselect-field--clinicSpecialities mb-16"
        icon="alert"
        showSearch
        mountToElement
        mode="multiple"
        dropdownClassName="multiselect-field__dropdown"
      />
      <Field
        id="patientIds"
        name="patientIds"
        component={DropdownPatientsField}
        onBlur={onBlur}
        label={{ id: 'label.patients' }}
        optionGroupLabel={{ id: 'label.patients' }}
        placeholder={{ id: 'pushNotifications.modal.addPatients' }}
        className="multiselect-field multiselect-field--clinicSpecialities mb-16"
        icon="alert"
        showSearch
        mountToElement
        mode="multiple"
        dropdownClassName="multiselect-field__dropdown"
      />
      <Field
        id="specialityIds"
        name="specialityIds"
        component={DropdownSpecialitiesField}
        onBlur={onBlur}
        label={{ id: 'label.specialities' }}
        placeholder={{ id: 'pushNotifications.modal.addSpecialities' }}
        optionGroupLabel={{ id: 'label.speciality_ids' }}
        className="multiselect-field multiselect-field--clinicSpecialities mb-16"
        icon="alert"
        showSearch
        mountToElement
        mode="multiple"
        dropdownClassName="multiselect-field__dropdown"
      />
      <div className="d-flex justify-content-between mb-24">
        <div className="d-flex">
          <Field
            id="gender"
            name="gender"
            className="w-160 mr-16"
            component={SelectField}
            onBlur={onBlur}
            label={{ id: 'label.gender' }}
            icon="alert"
            options={translateOptions(intl, GENDER_OPTIONS)}
            placeholder={{ id: 'placeholder.selectGender' }}
          />
          <Field
            id="children"
            name="children"
            className="w-160"
            component={SelectField}
            onBlur={onBlur}
            label={{ id: 'label.children' }}
            icon="alert"
            options={translateOptions(intl, CHILDREN_OPTIONS)}
            placeholder={{ id: 'placeholder.selectChildren' }}
          />
        </div>
        <div className="d-flex">
          <Field
            id="ageFrom"
            name="ageFrom"
            className="w-80-strict mr-16"
            component={NumericField}
            onChange={onChange}
            label={{ id: 'label.age.from' }}
            icon="alert"
            placeholder={MIN_AGE_VALUE}
            isInteger
            min={MIN_AGE_VALUE}
            max={MAX_AGE_VALUE}
            step={1}
          />
          <Field
            id="ageTo"
            name="ageTo"
            className="w-80-strict"
            component={NumericField}
            onChange={onChange}
            label={{ id: 'label.age.to' }}
            icon="alert"
            placeholder={MAX_AGE_VALUE}
            isInteger
            min={MIN_AGE_VALUE}
            max={MAX_AGE_VALUE}
            step={1}
          />
        </div>
      </div>
      <Field
        id="message"
        name="message"
        component={TextEditorField}
        label={{ id: 'label.pushNotificationText' }}
        icon="alert"
        toolbar={{ options: ['emoji', 'history'] }}
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <Button
        disabled={isLoadingSubmitButton || !recepientsCountForFilters}
        isLoading={isLoadingSubmitButton}
        text={{ id: 'shared.sendWithCount', values: { count: recepientsCountForFilters } }}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

CreatePushNotificationModalComponent.propTypes = {
  intl: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirmModalClose: PropTypes.func.isRequired,
  onConfirmModalSubmit: PropTypes.func.isRequired,
  recepientsCountForFilters: PropTypes.number.isRequired,
  isLoadingSubmitButton: PropTypes.bool,
  confirmModalVisible: PropTypes.bool.isRequired,
};

CreatePushNotificationModalComponent.defaultProps = {
  isLoadingSubmitButton: true,
};

export default CreatePushNotificationModalComponent;
