import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { getTimezonesEndpoint } from '../endpoints';
import { GET_TIMEZONES } from '../types';

const getTimezonesOperation = createLogic({
  type: GET_TIMEZONES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getTimezonesEndpoint;

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getTimezonesOperation;
