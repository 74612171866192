import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { isCancelled } from 'utils/appointments';

const AppointmentCalendarTooltipCancellationReason = ({ event }) => (
  isCancelled(event) && (
    <div className="modal-appointment__section">
      <div className="">
        <p className="modal-appointment__description-title">
          <FormattedMessage id={`appointments.cancellationReason.${event.cancelledByType}`} />
        </p>
        <p className="modal-appointment__description-txt">
          {event.cancellationReason}
        </p>
      </div>
    </div>
  )
);

AppointmentCalendarTooltipCancellationReason.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default AppointmentCalendarTooltipCancellationReason;
