import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setDoctorProfile } from 'state/concepts/userProfile/doctors/actions';
import { setVirtualClinicDoctorId } from '../actions';
import { fetchVirtualClinicDoctorEndpoint } from '../endpoints';
import { FETCH_VIRTUAL_CLINIC_DOCTOR } from '../types';

const fetchVirtualClinicDoctorOperation = createLogic({
  type: FETCH_VIRTUAL_CLINIC_DOCTOR,
  latest: true,

  async process({ httpClient, action: { vcdId } }, dispatch, done) {
    const { endpoint, url } = fetchVirtualClinicDoctorEndpoint(vcdId);

    const params = {
      include: 'clinic,user-profiles-clinic-specialities,user-profiles-clinic-specialities.clinic_speciality,user-profiles-clinic-specialities.speciality,recurring-availabilities',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setVirtualClinicDoctorId(data.data.id));
      dispatch(setDoctorProfile(data.data.id));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVirtualClinicDoctorOperation;
