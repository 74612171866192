import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { setSpecialitiesListIds, addSpecialitiesListIds } from '../actions';
import {
  specialitiesListStatusesSelector,
  specialitiesListFilterParamsSelector,
  paginationSelector,
} from '../selectors';
import { fetchSpecialitiesEndpoint } from '../endpoints';
import { FETCH_SPECIALITIES_LIST } from '../types';

const fetchSpecialitiesListOperation = createLogic({
  type: FETCH_SPECIALITIES_LIST,
  latest: true,

  async process({ httpClient, getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const { url, endpoint } = fetchSpecialitiesEndpoint;
    const statuses = specialitiesListStatusesSelector(state);
    const { name, excludeSpecialityIds } = specialitiesListFilterParamsSelector(state);
    const { size } = paginationSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size,
        is_cursor: true,
      },
      filter: {
        name,
        statuses,
        exclude_speciality_ids: excludeSpecialityIds,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));

      if (lastId) {
        dispatch(addSpecialitiesListIds(pluck('id', data.data)));
      } else {
        dispatch(setSpecialitiesListIds(pluck('id', data.data)));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchSpecialitiesListOperation;
