const namespace = 'videoConference';

export const JOIN_VIDEO_CONFERENCE = `${namespace}/JOIN_VIDEO_CONFERENCE`;
export const SET_VIDEO_CONFERENCE_ID = `${namespace}/SET_VIDEO_CONFERENCE_ID`;
export const SET_SETTINGS = `${namespace}/SET_SETTINGS`;
export const RESET_SETTINGS = `${namespace}/RESET_SETTINGS`;
export const CHANGE_VIEW = `${namespace}/CHANGE_VIEW`;
export const TOGGLE_CHAT_PANEL_VISIBLE = `${namespace}/TOGGLE_CHAT_PANEL_VISIBLE`;
export const CAN_JOIN = `${namespace}/CAN_JOIN`;
export const SEND_LOGGERS = `${namespace}/SEND_LOGGERS`;
