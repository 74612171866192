import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';
import { isVirtualClinicDoctor } from 'utils/roles';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';

import { submitPlatformCommissionEndpoint, submitClinicCommissionEndpoint } from '../endpoints';
import { SUBMIT_PLATFORM_COMMISSION } from '../types';

const submitPlatformCommissionOperation = createLogic({
  type: SUBMIT_PLATFORM_COMMISSION,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const { commission, roleName, values, form } = action;
    const { clinicId, userProfileId } = commission;
    const { url, endpoint } = isVirtualClinicDoctor(roleName)
     ? submitClinicCommissionEndpoint(userProfileId)
     : submitPlatformCommissionEndpoint(userProfileId);

    dispatch(dataApiRequest({ endpoint }));

    const state = getState();
    const params = {
      rate: values.rate,
      maximum: values.maximum,
      minimum: values.minimum,
    };

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));

      const updateData = isVirtualClinicDoctor(roleName)
      ? { id: userProfileId, type: 'userProfile', key: 'currentClinicCommission' }
      : { id: clinicId, type: 'clinic', key: 'currentPlatformCommission' };

      const responseData = updateDataHelper(
        state.data, updateData.type, updateData.id,
        {
          relationships: {
            [updateData.key]: {
              data: {
                id: data.data.id,
              },
            },
          },
        },
      );

      dispatch(dataApiSuccess({ response: responseData }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.commissionSettingsHaveBeenUpdated' },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPlatformCommissionOperation;
