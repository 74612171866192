import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const userLoginSuccess = currentUserId => ({
  type: types.USER_LOGIN_SUCCESS,
  currentUserId,
});

export const setCurrentUserRole = currentUserRole => ({
  type: types.SET_CURRENT_USER_ROLE,
  currentUserRole,
});

export const setVisibleAuthFlow = flow => ({
  type: types.SET_VISIBLE_AUTH_FLOW,
  flow,
});

export const userLogout = () => ({
  type: types.USER_LOGOUT,
});

export const refreshActivity = () => ({
  type: types.REFRESH_ACTIVITY,
});

export const setUserType = userType => ({
  type: types.SET_USER_TYPE,
  userType,
});

export const patientLogout = () => ({
  type: types.PATIENT_LOGOUT,
});

export const loginByOneTimeToken = (token) => ({
  type: types.PATIENT_LOGIN_BY_ONE_TIME_TOKEN,
  token,
});

export const submitUserLogin = makeFormSubmitAction(types.SUBMIT_USER_LOGIN);
export const submitUserRecoveryCode = makeFormSubmitAction(types.SUBMIT_USER_RECOVERY_CODE);
export const submitUserTwoFactorAuth = makeFormSubmitAction(types.SUBMIT_USER_TWO_FACTOR_AUTH_CODE);
export const submitPatientLogin = makeFormSubmitAction(types.PATIENT_LOGIN);
