import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';

import isPresent from 'utils/isPresent';

import InputMessage from '../InputMessage';

export const Editor = dynamic(() => import('react-draft-wysiwyg').then(mod => mod.Editor), { ssr: false });

const TextEditorFieldComponent = ({
  id,
  label,
  icon,
  editorState,
  toolbar: { options },
  fieldErrors,
  isErrorField,
  isFocused,
  onBlur,
  onFocus,
  onEditorStateChange,
  language,
  intl: { formatMessage },
}) => (
  <div>
    {isPresent(label) && (
      <label
        className="editor__label"
        htmlFor={id}
      >
        <FormattedMessage {...label} />
      </label>
    )}
    <Editor
      editorState={editorState}
      wrapperClassName={classNames('editor__wrapper', {
        'editor__wrapper--focus': isFocused,
        'editor__wrapper--error': isErrorField,
      })}
      editorClassName={classNames('editor__field', {
        'editor__field--focus': isFocused,
        'editor__field--error': isErrorField,
      })}
      toolbarClassName={classNames('editor__toolbar', {
        'editor__toolbar--focus': isFocused,
        'editor__toolbar--error': isErrorField,
      })}
      onBlur={onBlur}
      onFocus={onFocus}
      onEditorStateChange={onEditorStateChange}
      localization={{
        locale: language,
        translations: {
          'components.controls.blocktype.h1': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 1 }),
          'components.controls.blocktype.h2': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 2 }),
          'components.controls.blocktype.h3': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 3 }),
          'components.controls.blocktype.h4': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 4 }),
          'components.controls.blocktype.h5': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 5 }),
          'components.controls.blocktype.h6': formatMessage({ id: 'label.editor.blocktype.heading' }, { count: 6 }),
          'components.controls.blocktype.normal': formatMessage({ id: 'label.editor.blocktype.normal' }),
        },
      }}
      toolbar={{
        options,
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'underline', 'strikethrough'],
          className: 'editor__options--inline',
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
          className: 'editor__options--blocktype',
          dropdownClassName: 'editor__options--dropdown-blocktype',
        },
        fontSize: {
          inDropdown: true,
          options: ['8', '10', '12', '14', '16', '18', '24', '30', '36'],
          className: 'editor__options--fontsize',
        },
        emoji: {
          inDropdown: false,
          className: 'editor__options--emoji',
        },
        history: {
          inDropdown: false,
          className: 'editor__options--history',
        },
      }}
    />
    {isErrorField && (
      <InputMessage
        message={fieldErrors}
        icon={icon}
      />
    )}
  </div>
);

TextEditorFieldComponent.defaultProps = {
  label: null,
  icon: null,
  toolbar: { options: ['inline', 'blockType', 'fontSize', 'emoji', 'history'] },
  fieldErrors: undefined,
  isErrorField: false,
};

TextEditorFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.shape(),
  icon: PropTypes.string,
  toolbar: PropTypes.shape(),
  editorState: PropTypes.shape().isRequired,
  fieldErrors: PropTypes.shape(),
  isErrorField: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onEditorStateChange: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default TextEditorFieldComponent;
