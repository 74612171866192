import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { attachMediaStream, detachMediaStream } from 'lib/webrtcClient/streamHelpers';

import redirect from 'utils/redirect';
import { getDevices } from 'utils/videoConference';

import { showModal as showModalAction } from 'state/modal/actions';
import {
  setSettings as setSettingsAction,
  resetSettings as resetSettingsAction,
  joinVideoConference as joinVideoConferenceAction,
} from 'state/concepts/userProfile/videoConference/actions';
import {
  settingsSelector,
  videoConferenceSelector,
  videoConferenceMetaSelector,
  videoConferenceLoadingSelector,
  canJoinSelector,
} from 'state/concepts/userProfile/videoConference/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';

import JoinConsultationModalComponent from './component';

class JoinConsultationModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    setSettings: PropTypes.func.isRequired,
    resetSettings: PropTypes.func.isRequired,
    settings: PropTypes.shape().isRequired,
    currentUser: PropTypes.shape().isRequired,
    showModal: PropTypes.func.isRequired,
    joinVideoConference: PropTypes.func.isRequired,
    appointmentsPath: PropTypes.string.isRequired,
    appointmentId: PropTypes.string.isRequired,
  };

  state = {
    ...this.props.settings,
  };

  videoElement = React.createRef();

  componentDidMount = async () => {
    const { joinVideoConference, appointmentId, setSettings, settings } = this.props;
    const { audioDevices, videoDevices, speakerDevices } = await getDevices();

    if (!settings.audioDevice && !settings.videoDevice && !settings.speakerDevice) {
      setSettings({
        audioDevice: audioDevices[0],
        videoDevice: videoDevices[0],
        speakerDevice: speakerDevices[0],
      });
    }

    joinVideoConference(appointmentId);

    this.handleVideoOn();
  };

  componentWillUnmount() {
    detachMediaStream(this.videoElement.current);
  }

  handleVideoOn = async () => {
    try {
      const videoDeviceId = path(['settings', 'videoDevice', 'key'], this.props);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined },
      });
      attachMediaStream(this.videoElement.current, stream);
      this.setState({ isVideoEnabled: true });
    } catch (e) {
      this.setState({ isVideoEnabled: false });
    }
  };

  handleVideoOff = () => {
    detachMediaStream(this.videoElement.current);
    this.setState({ isVideoEnabled: false });
  };

  handleToggleVideo = () => {
    const { isVideoEnabled } = this.state;

    if (isVideoEnabled) {
      this.handleVideoOff();
    } else {
      this.handleVideoOn();
    }
  };

  handleToggleAudio = () => {
    this.setState(({ isAudioEnabled }) => ({ isAudioEnabled: !isAudioEnabled }));
  };

  handleSubmit = () => {
    const { setSettings, onClose, appointmentsPath, appointmentId } = this.props;

    setSettings(this.state);
    onClose();

    redirect({ href: `${appointmentsPath}/${appointmentId}/video_conference` });
  };

  handleClose = () => {
    const { onClose, resetSettings } = this.props;

    resetSettings();
    onClose();
  };

  handleChangeSettings = () => {
    const { showModal, setSettings } = this.props;

    setSettings(this.state);
    showModal({
      modalType: 'VIDEO_CONFERENCE_SETTINGS_MODAL',
      modalProps: { onClose: this.handleSettingsClose },
    });
  };

  handleSettingsClose = () => {
    const { showModal, appointmentsPath, appointmentId } = this.props;

    showModal({
      modalType: 'JOIN_CONSULTATION_MODAL',
      modalProps: { appointmentsPath, appointmentId },
    });
  };

  render = () => (
    <JoinConsultationModalComponent
      {...this.props}
      {...this.state}
      ref={this.videoElement}
      onSubmit={this.handleSubmit}
      onChangeSettings={this.handleChangeSettings}
      onToggleAudio={this.handleToggleAudio}
      onToggleVideo={this.handleToggleVideo}
      onClose={this.handleClose}
    />
  )
}

const mapStateToProps = (state, { appointmentId }) => ({
  settings: settingsSelector(state),
  videoConference: videoConferenceSelector(state),
  meta: videoConferenceMetaSelector(state, appointmentId),
  isLoading: videoConferenceLoadingSelector(state, appointmentId),
  currentUser: currentUserSelector(state),
  canJoin: canJoinSelector(state),
});

const mapDispatchToProps = {
  setSettings: setSettingsAction,
  resetSettings: resetSettingsAction,
  showModal: showModalAction,
  joinVideoConference: joinVideoConferenceAction,
};

export { JoinConsultationModal as JoinConsultationModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(JoinConsultationModal);
