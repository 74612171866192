import { createLogic } from 'redux-logic';

import { DOCTORS_STATUSES } from 'constants/doctors';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { activateVirtualClinicDoctorEndpoint } from '../endpoints';
import { ACTIVATE_VIRTUAL_CLINIC_DOCTOR } from '../types';

const activateVirtualClinicDoctorOperation = createLogic({
  type: ACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  latest: true,

  async process({ httpClient, getState, action: { doctorId, vcdId, resetForm } }, dispatch, done) {
    const { url, endpoint } = activateVirtualClinicDoctorEndpoint(vcdId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url);
      const state = getState();
      let response;

      if (isPresent(doctorId)) {
        response = updateDataHelper(
          state.data, 'virtualClinicDoctor', doctorId,
          { attributes: { status: DOCTORS_STATUSES.active } },
        );
      } else {
        response = updateDataHelper(
          state.data, 'userProfile', vcdId,
          { attributes: { active: true } },
        );
      }

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorActivated' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorActivatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
      resetForm();
    }

    done();
  },
});

export default activateVirtualClinicDoctorOperation;
