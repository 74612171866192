import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openConnection as openConnectionAction } from 'state/concepts/userProfile/webSockets/actions';
import {
  isClosedConnectionSelector,
  isOpenedConnectionSelector,
} from 'state/concepts/userProfile/webSockets/selectors';

class WebSocketConnection extends React.Component {
  static propTypes = {
    openConnection: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    isClosed: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { openConnection, isOpened } = this.props;
    if (!isOpened) { openConnection(); }
  }

  componentDidUpdate(prevProps) {
    const { openConnection, isOpened, isClosed } = this.props;

    if (prevProps.isOpened && isClosed) {
      this.openConnectionTimer = setInterval(openConnection, 3000);
    }

    if (prevProps.isClosed && isOpened) {
      clearInterval(this.openConnectionTimer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.openConnectionTimer);
  }

  render = () => null;
}

const mapStateToProps = (state) => ({
  isOpened: isOpenedConnectionSelector(state),
  isClosed: isClosedConnectionSelector(state),
});

const mapDispatchToProps = { openConnection: openConnectionAction };

export { WebSocketConnection as WebSocketConnectionContainer };
export default connect(mapStateToProps, mapDispatchToProps)(WebSocketConnection);
