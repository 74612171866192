import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { is } from 'ramda';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const ErrorModal = ({
  onClose,
  className,
  title,
  error,
  submitText,
}) => (
  <Modal
    onClose={onClose}
    className={classNames('main-modal', className)}
  >
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage {...title} />
        </h2>
        <button
          type="button"
          onClick={onClose}
        >
          <SvgIcon icon="cross" className="main-modal__close" />
        </button>
      </div>
      <div className="main-modal__body pb-0">
        <p>
          {is(Object, error) ? <FormattedMessage {...error} /> : error}
        </p>
      </div>
      <div className="main-modal__footer">
        <Button
          onClick={onClose}
          text={submitText}
        />
      </div>
    </div>
  </Modal>
);

ErrorModal.defaultProps = {
  className: null,
  submitText: { id: 'shared.done' },
};

ErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
  submitText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
};

export default ErrorModal;
