import { combineReducers } from 'redux';
import { DateTime } from 'luxon';
import { union } from 'ramda';

import { firstDateOfMonthISO } from 'utils/dateTime';

import * as types from './types';

const revenueExtraStatisticId = (state = '', action) => {
  switch (action.type) {
    case types.SET_REVENUE_EXTRA_STATISTIC_ID:
      return action.revenueExtraStatisticId;
    default:
      return state;
  }
};

const revenueAppointmentIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_REVENUE_APPOINTMENT_IDS:
      return action.revenueAppointmentIds;
    default:
      return state;
  }
};

const revenueVirtualClinicIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_REVENUE_VIRTUAL_CLINIC_IDS:
      return action.revenueVirtualClinicIds;
    default:
      return state;
  }
};

const revenueVirtualClinicId = (state = null, action) => {
  switch (action.type) {
    case types.SET_REVENUE_VIRTUAL_CLINIC_ID:
      return action.revenueVirtualClinicId;
    default:
      return state;
  }
};

const revenueVirtualClinicDoctorIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS:
      return union(state, action.revenueVirtualClinicDoctorIds);
    case types.RESET_REVENUE_VIRTUAL_CLINIC_DOCTOR_IDS:
      return [];
    default:
      return state;
  }
};

const revenueType = (state = null, action) => {
  switch (action.type) {
    case types.SET_REVENUE_TYPE:
      return action.revenueType;
    default:
      return state;
  }
};

const revenueBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_REVENUE_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const revenuePagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_REVENUE_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const filtersInitialState = {
  startDate: firstDateOfMonthISO(DateTime.local()),
  endDate: DateTime.local().toFormat('y-LL-dd'),
  userProfileId: undefined,
  clinicTypes: [],
  clinicIds: [],
  name: '',
};

const revenueFilters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_REVENUE_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_REVENUE_FILTER_PARAMS:
      return filtersInitialState;
    default:
      return state;
  }
};

const revenueSort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_REVENUE_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  revenueExtraStatisticId,
  revenueAppointmentIds,
  revenueVirtualClinicId,
  revenueVirtualClinicIds,
  revenueVirtualClinicDoctorIds,
  revenueType,
  revenueBlankState,
  revenuePagination,
  revenueFilters,
  revenueSort,
});
