import endpoint from 'utils/endpoint';
import {
  clinicRoute,
  clinicUpdateLogoRoute,
  clinicDestroyLogoRoute,
  usersAccountProfileRoute,
  usersAccountProfilesMyselfRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const getOnboardingUserMyselfEndpoint = endpoint(types.GET_ONBOARDING_USER_MYSELF, 'GET', usersAccountProfilesMyselfRoute);
export const submitGeneralInfoEndpoint = endpoint(types.SUBMIT_ONBOARDING_GENERAL, 'PUT', usersAccountProfileRoute);
export const submitLocationEndpoint = endpoint(types.SUBMIT_ONBOARDING_LOCATION, 'PUT', usersAccountProfileRoute);
export const submitVirtualClinicEndpoint = (clinicId) => endpoint(types.SUBMIT_ONBOARDING_VIRTUAL_CLINIC_COMPANY, 'PUT', clinicRoute(clinicId));
export const uploadOnboardingVirtualClinicLogoEndpoint = (clinicId) => endpoint(types.UPLOAD_ONBOARDING_VIRTUAL_CLINIC_LOGO, 'PUT', clinicUpdateLogoRoute(clinicId));
export const removeOnboardingVirtualClinicLogoEndpoint = (clinicId) => endpoint(types.REMOVE_ONBOARDING_VIRTUAL_CLINIC_LOGO, 'DELETE', clinicDestroyLogoRoute(clinicId));
