import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'formik';
import { prop } from 'ramda';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import SvgIcon from 'views/shared/SvgIcon';
import DoctorsProvider from 'views/shared/DoctorsProvider';

const AssignDoctorsModalComponent = ({
  isLoading,
  dirty,
  onClose,
  handleSubmit,
  status,
  clinicSpeciality,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__8"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="clinicSpecialities.assignDoctors.title" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <p className="ant-modal__body-text">
        <FormattedMessage id="clinicSpecialities.assignDoctors.selectDoctorsToAssignTo" />
      </p>
      <ul className="ant-modal__body-selected-list">
        <li className="ant-modal__body-selected-list-item">
          {prop('name', clinicSpeciality)}
        </li>
      </ul>
      <div>
        <FieldArray
          name="doctors"
          component={DoctorsProvider}
        />
      </div>
    </div>
    <div className="ant-modal__footer d-block">
      {status && status.base && (
        <Alert message={status.base} />
      )}
      <div className="d-flex justify-content-end">
        <Button
          text={{ id: 'shared.cancel' }}
          kind="tertiary"
          onClick={onClose}
        />
        <SubmitFormButton
          text={{ id: 'shared.assign' }}
          onClick={handleSubmit}
          disabled={isLoading || !dirty}
        />
      </div>
    </div>
  </Modal>
);

AssignDoctorsModalComponent.defaultProps = {
  isLoading: undefined,
  status: undefined,
};

AssignDoctorsModalComponent.propTypes = {
  isLoading: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape(),
  clinicSpeciality: PropTypes.shape().isRequired,
};

export default AssignDoctorsModalComponent;
