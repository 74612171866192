import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const validateResetPasswordToken = token => ({
  type: types.VALIDATE_RESET_PASSWORD_TOKEN,
  token,
});

export const setResentPasswordStatus = status => ({
  type: types.SET_RESENT_PASSWORD_STATUS,
  status,
});

export const resetPasswordInAccount = email => ({
  type: types.RESENT_PASSWORD_IN_ACCOUNT,
  email,
});

export const submitNewPassword = makeFormSubmitAction(types.SUBMIT_NEW_PASSWORD);
export const submitResetPassword = makeFormSubmitAction(types.SUBMIT_RESET_PASSWORD);
