import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserClinicIdSelector } from 'state/concepts/session/selectors';
import { uploadOnboardingVirtualClinicLogoEndpoint } from '../endpoints';
import { UPLOAD_ONBOARDING_VIRTUAL_CLINIC_LOGO } from '../types';

const uploadOnboardingVirtualClinicLogoOperation = createLogic({
  type: UPLOAD_ONBOARDING_VIRTUAL_CLINIC_LOGO,
  latest: true,

  async process({ httpClient, getState, action: { data, isImageUrl } }, dispatch, done) {
    const state = getState();
    const clinicId = currentUserClinicIdSelector(state);
    const { url, endpoint } = uploadOnboardingVirtualClinicLogoEndpoint(clinicId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data: responseData } = await httpClient.put(url, data);
      const response = normalize(responseData);

      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'clinicLogoUpdated' : 'clinicLogoAdded'}` },
      }));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'clinicLogoUpdatedError' : 'clinicLogoAddedError'}` },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default uploadOnboardingVirtualClinicLogoOperation;
