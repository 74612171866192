import { createLogic } from 'redux-logic';

import {
  fetchPatientAppointments,
  fetchPatientNotes,
  setCurrentPage,
  setFilterParams,
} from '../actions';
import { SET_PATIENT_FILTER } from '../types';

const filterPatientOperation = createLogic({
  type: SET_PATIENT_FILTER,
  latest: true,

  async process({ action: { filters, patientId, isNotes } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    if (isNotes) {
      dispatch(fetchPatientNotes(patientId));
    } else {
      dispatch(fetchPatientAppointments(patientId));
    }

    done();
  },
});

export default filterPatientOperation;
