import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserIdSelector, currentUserRoleSelector } from 'state/concepts/session/selectors';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { setBlankState, setClinicSpecialities } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import {
  fetchClinicSpecialitiesEndpoint,
  fetchVirtualClinicSpecialitiesEndpoint,
} from '../endpoints';
import { FETCH_CLINIC_SPECIALITIES } from '../types';

const fetchClinicSpecialitiesOperation = createLogic({
  type: FETCH_CLINIC_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const currentUserId = currentUserIdSelector(state);
    const currentUserRole = currentUserRoleSelector(state);
    const { url, endpoint } = isVirtualClinicAdmin(currentUserRole)
      ? fetchVirtualClinicSpecialitiesEndpoint(currentUserId)
      : fetchClinicSpecialitiesEndpoint;

    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: commonFiltersSelector(state),
      include: 'speciality,user-profiles-clinic-specialities.user-profile',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setBlankState(isBlankState(params.filter, data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setClinicSpecialities(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchClinicSpecialitiesOperation;
