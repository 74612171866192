import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataClearRelationship } from 'state/data/actions';
import { currentUserIdSelector } from 'state/concepts/session/selectors';
import { updateTutorialStepCongratulationsEndpoint } from '../endpoints';
import { FINISH_TUTORIAL } from '../types';

const finishTutorialOperation = createLogic({
  type: FINISH_TUTORIAL,
  latest: true,

  async process({ getState, httpClient }, dispatch, done) {
    const userId = currentUserIdSelector(getState());
    const { endpoint, url } = updateTutorialStepCongratulationsEndpoint;

    try {
      await httpClient.put(url);

      dispatch(dataClearRelationship({
        kind: 'userProfile',
        ids: userId,
        relationship: 'userOnboardingStepper',
        isPlural: false,
      }));

      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default finishTutorialOperation;
