import build from 'redux-object';
import { curry, isEmpty } from 'ramda';

export const buildCollectionRecursive = curry((objectType, elements, data) => {
  if (isEmpty(elements)) { return []; }

  return elements.map((element) => {
    if (element.nodes) {
      return {
        ...build(data, objectType, element.id) || [],
        nodes: buildCollectionRecursive(objectType, element.nodes, data),
      };
    }

    return build(data, objectType, element.id) || [];
  });
});

export const updateDiseases = (elements, data, currentNode) => elements.map(element => {
  if (element.nodes) {
    return {
      ...element,
      nodes: updateDiseases(element.nodes, data, currentNode),
    };
  }

  if (element.id === currentNode.id) {
    return {
      ...element,
      nodes: data.data.map(node => ({ id: node.id })),
    };
  }

  return element;
});
