import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import { VIRTUAL_CLINICS_STATUSES } from 'constants/virtualClinics';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { fetchClinicsEndpoint } from '../endpoints';
import { FETCH_PUSH_NOTIFICATION_CLINICS } from '../types';
import { setClinicsListIds } from '../actions';
import { paginationSelector, clinicsListFilterParamsSelector } from '../selectors';

const fetchClinicsOperation = createLogic({
  type: FETCH_PUSH_NOTIFICATION_CLINICS,
  latest: true,

  async process({ httpClient, getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchClinicsEndpoint;
    const { size } = paginationSelector(state);
    const { name, excludeClinicsIds } = clinicsListFilterParamsSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size,
        is_cursor: true,
      },
      filter: {
        name,
        statuses: [VIRTUAL_CLINICS_STATUSES.active],
        exclude_clinic_ids: excludeClinicsIds,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setClinicsListIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchClinicsOperation;
