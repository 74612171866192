import React from 'react';
import PropTypes from 'prop-types';

import ClinicSpecialitiesProviderComponent from './component';

class ClinicSpecialitiesProvider extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }

  addProviderHandler = () => this.props.push({
    clinicSpecialityIds: [],
    price: '',
    freeVisit: false,
  })

  removeProviderHandler = (index) => () => this.props.remove(index)

  render = () => (
    <ClinicSpecialitiesProviderComponent
      {...this.props}
      onAddProvider={this.addProviderHandler}
      onRemoveProvider={this.removeProviderHandler}
    />
  )
}

export default ClinicSpecialitiesProvider;
