import { combineReducers } from 'redux';

import * as types from './types';

const initialState = {
  name: '',
  status: 'all',
};

const commonFilters = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams };
    case types.RESET_FILTERS: return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  commonFilters,
});
