export const AUTH_CODE_LENGTH = 6;

export const AUTH_FLOW = {
  recovery: 'recovery',
  verification: 'verification',
};

export const UNAUTHENTICATED_ROUTS = [
  '/unsubscribe_emails',
  '/doctor',
  '/doctor/error',
];

export const IS_LOGGED_IN = true;
