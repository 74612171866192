import { is, isNil } from 'ramda';

import { DURATION_REGEX } from 'constants';

import { durationToSeconds, valueWithUnitToSeconds } from 'utils/duration';

export function isDurationString() {
  return this.test(
    'isDurationString',
    { id: 'yup.string.isDurationString' },
    (value) => {
      if (!is(String, value)) { return true; }

      return DURATION_REGEX.test(value);
    },
  );
}

export function durationMin(min) {
  return this.test({
    name: 'durationMin',
    message: { id: 'yup.string.durationMin', values: { min } },
    params: { min },
    test: (value) => {
      if (!DURATION_REGEX.test(value)) { return true; }

      return durationToSeconds(value) >= durationToSeconds(min);
    },
  });
}

export function durationMax(max) {
  return this.test({
    name: 'durationMax',
    message: { id: 'yup.string.durationMax', values: { max } },
    params: { max },
    test: (value) => {
      if (!DURATION_REGEX.test(value)) { return true; }

      return durationToSeconds(value) <= durationToSeconds(max);
    },
  });
}

export function durationInUnitMin(unitFieldRef, { min, unit }) {
  return this.test({
    name: 'durationInUnitMin',
    message: { id: 'yup.string.durationInUnitMin', values: { min, unit } },
    params: { unitFieldRef, min, unit },
    test(value) {
      if (isNil(value)) { return true; }

      return valueWithUnitToSeconds(
        this.resolve(unitFieldRef), value,
      ) >= valueWithUnitToSeconds(unit, min);
    },
  });
}

export function durationInUnitMax(unitFieldRef, { max, unit }) {
  return this.test({
    name: 'durationInUnitMax',
    message: { id: 'yup.string.durationInUnitMax', values: { max, unit } },
    params: { unitFieldRef, max, unit },
    test(value) {
      if (isNil(value)) { return true; }

      return valueWithUnitToSeconds(
        this.resolve(unitFieldRef), value,
      ) <= valueWithUnitToSeconds(unit, max);
    },
  });
}

export function secondsPerTimeUnitMin(secondsPerTimeRef, { min, unit }, anytimeFieldRef = null) {
  return this.test({
    name: 'secondsPerTimeUnitMin',
    message: { id: 'yup.string.durationInUnitMin', values: { min, unit } },
    params: { secondsPerTimeRef, min, unit },
    test(value) {
      if (anytimeFieldRef && this.resolve(anytimeFieldRef)) { return true; }

      return valueWithUnitToSeconds(
        value, this.resolve(secondsPerTimeRef),
      ) >= valueWithUnitToSeconds(unit, min);
    },
  });
}

export function secondsPerTimeUnitMax(secondsPerTimeRef, { max, unit }, anytimeFieldRef = null) {
  return this.test({
    name: 'secondsPerTimeUnitMax',
    message: { id: 'yup.string.durationInUnitMax', values: { max, unit } },
    params: { secondsPerTimeRef, max, unit },
    test(value) {
      if (anytimeFieldRef && this.resolve(anytimeFieldRef)) { return true; }

      return valueWithUnitToSeconds(
        value, this.resolve(secondsPerTimeRef),
      ) <= valueWithUnitToSeconds(unit, max);
    },
  });
}
