import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import removePatientCookies from 'utils/removeUserCookies';

import { showNotification } from 'state/notifications/actions';
import { createPatientNewPasswordEndpoint } from '../endpoints';
import { SUBMIT_PATIENT_NEW_PASSWORD } from '../types';

const submitPatientNewPasswordOperation = createLogic({
  type: SUBMIT_PATIENT_NEW_PASSWORD,
  latest: true,

  async process({ httpClient, action: { values, form, token } }, dispatch, done) {
    const { url, endpoint } = createPatientNewPasswordEndpoint;

    try {
      const params = {
        ...values,
        password_confirmation: values.password,
        token,
        redirect_to: '/patient',
      };

      await httpClient.put(url, params);

      removePatientCookies();

      await redirect({ href: '/patient/login' });

      dispatch(showNotification({ messageObject: { id: 'notifications.resetPasswordSuccess' } }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default submitPatientNewPasswordOperation;
