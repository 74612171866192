import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const submitVCDJoinRequest = makeFormSubmitAction(types.SUBMIT_VCD_JOIN_REQUEST);

export const fetchVirtualClinicDoctor = vcdId => ({
  type: types.FETCH_VIRTUAL_CLINIC_DOCTOR,
  vcdId,
});

export const setVirtualClinicDoctorId = vcdId => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTOR_ID,
  vcdId,
});

export const resetVirtualClinicDoctorId = () => ({
  type: types.RESET_VIRTUAL_CLINIC_DOCTOR_ID,
});

export const updateVirtualClinicDoctor = (values, form) => ({
  type: types.UPDATE_VIRTUAL_CLINIC_DOCTOR,
  values,
  form,
});

export const fetchVirtualClinicDoctors = () => ({
  type: types.FETCH_VIRTUAL_CLINIC_DOCTORS,
});

export const addVirtualClinicDoctorIds = ids => ({
  type: types.ADD_VIRTUAL_CLINIC_DOCTOR_IDS,
  ids,
});

export const setVirtualClinicDoctorIds = ids => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTOR_IDS,
  ids,
});

export const resetVirtualClinicDoctorIds = () => ({
  type: types.RESET_VIRTUAL_CLINIC_DOCTOR_IDS,
});

export const setBlankState = isBlankState => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTORS_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTORS_CURRENT_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTORS_SORT,
  sortKey,
});

export const filterVirtualClinicDoctors = filters => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTORS_FILTERS,
  filters,
});

export const deactivateVirtualClinicDoctor = (doctorId, vcdId) => ({
  type: types.DEACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  doctorId,
  vcdId,
});

export const activateVirtualClinicDoctor = (doctorId, vcdId) => ({
  type: types.ACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  doctorId,
  vcdId,
});

export const deactivateVirtualClinicDoctorFromAccountTab = ({ doctorProfile, resetForm }) => ({
  type: types.DEACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  vcdId: doctorProfile.id,
  resetForm,
});

export const activateVirtualClinicDoctorFromAccountTab = ({ doctorProfile, resetForm }) => ({
  type: types.ACTIVATE_VIRTUAL_CLINIC_DOCTOR,
  vcdId: doctorProfile.id,
  resetForm,
});

export const removeVirtualClinicDoctorInvitation = (vcdId, vcdType) => ({
  type: types.REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION,
  vcdId,
  vcdType,
});

export const submitManageSpeciality = makeFormSubmitAction(types.SUBMIT_MANAGE_SPECIALITY);

export const fetchVirtualClinicDoctorList = lastId => ({
  type: types.FETCH_VIRTUAL_CLINIC_DOCTOR_LIST,
  lastId,
});

export const setVirtualClinicDoctorListIds = ids => ({
  type: types.SET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS,
  ids,
});

export const resetVirtualClinicDoctorListIds = () => ({
  type: types.RESET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS,
});
