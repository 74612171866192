import { createLogic } from 'redux-logic';
import { dec } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchVirtualClinics, setCurrentPage } from '../actions';
import { virtualClinicsTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeVirtualClinicInvitationEndpoint } from '../endpoints';
import { REMOVE_VIRTUAL_CLINIC_INVITATION } from '../types';

const removeVirtualClinicInvitationOperation = createLogic({
  type: REMOVE_VIRTUAL_CLINIC_INVITATION,
  latest: true,

  async process({ httpClient, getState, action: { virtualClinicId } }, dispatch, done) {
    const { endpoint, url } = removeVirtualClinicInvitationEndpoint(virtualClinicId);
    const state = getState();
    const totalCount = virtualClinicsTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, 1);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.virtualClinicInvitationCancelled' } }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchVirtualClinics());
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.virtualClinicInvitationCancelledError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeVirtualClinicInvitationOperation;
