import { createLogic } from 'redux-logic';

import {
  resetDoctorListIds,
  resetDoctorListStatuses,
  resetDoctorListFilterParams,
  resetDoctorListClinicSpecialityId,
  resetDoctorsRoles,
} from 'state/concepts/userProfile/doctors/actions';
import { CLINIC_SPECIALITY_ASSIGN_DOCTORS_RESET_STATE } from '../types';

const assignDoctorsResetStateOperation = createLogic({
  type: CLINIC_SPECIALITY_ASSIGN_DOCTORS_RESET_STATE,
  latest: true,

  async process(_, dispatch, done) {
    dispatch(resetDoctorListIds());
    dispatch(resetDoctorListStatuses());
    dispatch(resetDoctorListFilterParams());
    dispatch(resetDoctorListClinicSpecialityId());
    dispatch(resetDoctorsRoles());

    done();
  },
});

export default assignDoctorsResetStateOperation;
