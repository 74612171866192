import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { updateDiseases } from 'utils/diseases';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { getDiseasesEndpoint } from '../endpoints';
import { GET_DISEASES_NODES } from '../types';
import { setDiseasesIds } from '../actions';
import { diseasesIdsSelector } from '../selectors';

const getDiseasesNodesOperation = createLogic({
  type: GET_DISEASES_NODES,
  latest: true,

  async process({ httpClient, getState, action: { node } }, dispatch, done) {
    const state = getState();
    const diseasesIds = diseasesIdsSelector(state);
    const { endpoint, url } = getDiseasesEndpoint;

    const params = {
      code: node.code,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setDiseasesIds(updateDiseases(diseasesIds, data, node)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getDiseasesNodesOperation;
