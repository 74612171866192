import { combineReducers } from 'redux';

import * as types from './types';

const joinRequestIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_JOIN_REQUEST_IDS:
      return action.ids;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_JOIN_REQUESTS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_JOIN_REQUESTS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

export default combineReducers({
  joinRequestIds,
  isBlankState,
  pagination,
});
