import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { fetchSpecialities, setCurrentPage } from '../actions';
import { SET_SPECIALITIES_FILTER } from '../types';

const filterSpecialitiesOperation = createLogic({
  type: SET_SPECIALITIES_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    dispatch(fetchSpecialities());
    done();
  },
});

export default filterSpecialitiesOperation;
