import isPresent from 'utils/isPresent';

// eslint-disable-next-line import/prefer-default-export
export function isDiseasePresent() {
  return this.test(
    'isDiseasePresent',
    { id: 'yup.mixed.required' },
    (value) => (isPresent(value.id) && isPresent(value.value)),
  );
}
