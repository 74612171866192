const namespace = 'virtualClinicDoctors';

export const FETCH_VIRTUAL_CLINIC_DOCTORS = `${namespace}/FETCH_VIRTUAL_CLINIC_DOCTORS`;
export const ADD_VIRTUAL_CLINIC_DOCTOR_IDS = `${namespace}/ADD_VIRTUAL_CLINIC_DOCTOR_IDS`;
export const SET_VIRTUAL_CLINIC_DOCTOR_IDS = `${namespace}/SET_VIRTUAL_CLINIC_DOCTOR_IDS`;
export const RESET_VIRTUAL_CLINIC_DOCTOR_IDS = `${namespace}/RESET_VIRTUAL_CLINIC_DOCTOR_IDS`;
export const SET_VIRTUAL_CLINIC_DOCTORS_BLANK_STATE = `${namespace}/SET_VIRTUAL_CLINIC_DOCTORS_BLANK_STATE`;
export const SET_VIRTUAL_CLINIC_DOCTORS_CURRENT_PAGE = `${namespace}/SET_VIRTUAL_CLINIC_DOCTORS_CURRENT_PAGE`;
export const SET_VIRTUAL_CLINIC_DOCTORS_SORT = `${namespace}/SET_VIRTUAL_CLINIC_DOCTORS_SORT`;
export const SET_VIRTUAL_CLINIC_DOCTORS_FILTERS = `${namespace}/SET_VIRTUAL_CLINIC_DOCTORS_FILTERS`;
export const SUBMIT_VCD_JOIN_REQUEST = `${namespace}/SUBMIT_VCD_JOIN_REQUEST`;
export const DEACTIVATE_VIRTUAL_CLINIC_DOCTOR = `${namespace}/DEACTIVATE_VIRTUAL_CLINIC_DOCTOR`;
export const ACTIVATE_VIRTUAL_CLINIC_DOCTOR = `${namespace}/ACTIVATE_VIRTUAL_CLINIC_DOCTOR`;
export const FETCH_VIRTUAL_CLINIC_DOCTOR = `${namespace}/FETCH_VIRTUAL_CLINIC_DOCTOR`;
export const SET_VIRTUAL_CLINIC_DOCTOR_ID = `${namespace}/SET_VIRTUAL_CLINIC_DOCTOR_ID`;
export const RESET_VIRTUAL_CLINIC_DOCTOR_ID = `${namespace}/RESET_VIRTUAL_CLINIC_DOCTOR_ID`;
export const UPDATE_VIRTUAL_CLINIC_DOCTOR = `${namespace}/UPDATE_VIRTUAL_CLINIC_DOCTOR`;
export const REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION = `${namespace}/REMOVE_VIRTUAL_CLINIC_DOCTOR_INVITATION`;
export const REMOVE_VIRTUAL_CLINIC_DOCTOR_JOIN_REQUEST = `${namespace}/REMOVE_VIRTUAL_CLINIC_DOCTOR_JOIN_REQUEST`;
export const SUBMIT_MANAGE_SPECIALITY = `${namespace}/SUBMIT_MANAGE_SPECIALITY`;
export const FETCH_VIRTUAL_CLINIC_DOCTOR_LIST = `${namespace}/FETCH_VIRTUAL_CLINIC_DOCTOR_LIST`;
export const SET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS = `${namespace}/SET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS`;
export const RESET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS = `${namespace}/RESET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS`;
