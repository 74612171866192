export default {
  mixed: {
    required: { id: 'yup.mixed.required' },
  },
  string: {
    url: { id: 'yup.string.url' },
    email: { id: 'yup.string.email' },
    min: ({ min }) => ({ id: 'yup.string.min', values: { min } }),
    max: ({ max }) => ({ id: 'yup.string.max', values: { max } }),
  },
  number: {
    positive: { id: 'yup.number.positive' },
    integer: { id: 'yup.number.integer' },
    moreThan: { id: 'yup.number.moreThan' },
    min: ({ min }) => ({ id: 'yup.number.min', values: { min } }),
    max: ({ max }) => ({ id: 'yup.number.max', values: { max } }),
  },
  array: {
    min: ({ min }) => ({ id: 'yup.array.min', values: { min } }),
  },
};
