import { createLogic } from 'redux-logic';

import { SET_DOCTOR_LIST_FILTERS } from '../types';
import { fetchDoctorList, setDoctorListFilterParams } from '../actions';

const filterDoctorListOperation = createLogic({
  type: SET_DOCTOR_LIST_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setDoctorListFilterParams(filters));
    dispatch(fetchDoctorList());
    done();
  },
});

export default filterDoctorListOperation;
