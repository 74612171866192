import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { includes, trim, isEmpty } from 'ramda';

import DropdownFieldComponent from './component';

class DropdownField extends React.Component {
  static propTypes = {
    fetchList: PropTypes.func.isRequired,
    filterList: PropTypes.func.isRequired,
    resetListIds: PropTypes.func.isRequired,
    resetListFilterParams: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    optionsIds: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.shape().isRequired,
  }

  static defaultProps = {
    options: [],
    optionsIds: [],
  }

  state = {
    searchQuery: '',
  };

  componentDidMount = () => {
    const { fetchList } = this.props;

    fetchList();
  }

  componentWillUnmount = () => {
    const { resetListIds, resetListFilterParams } = this.props;

    resetListIds();
    resetListFilterParams();
  }

  get isAllSelected() {
    const { options, field: { value } } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => includes(id, [].concat(value)));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterList, resetListIds } = this.props;

    this.setState({ searchQuery });
    resetListIds();
    filterList({ name: trim(searchQuery) });
  }, 250)

  handleFocus = () => {
    const { filterList } = this.props;

    this.setState({ searchQuery: '' });
    filterList({ name: '' });
  }

  render = () => (
    <DropdownFieldComponent
      {...this.props}
      isAllSelected={this.isAllSelected}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

export { DropdownField as DropdownFieldContainer };
export default injectIntl(DropdownField);
