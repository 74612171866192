import { isEmpty } from 'ramda';

import { GENDERS, CHILDREN } from 'constants/dashboards';

export const getClinicIdsValue = value => {
  if (!isEmpty(value)) { return { clinic_ids: value }; }

  return {};
};

export const getPatientIdsValue = value => {
  if (!isEmpty(value)) { return { patient_ids: value }; }

  return {};
};

export const getSpecialityIdsValue = value => {
  if (!isEmpty(value)) { return { speciality_ids: value }; }

  return {};
};

export const getGenderValue = value => {
  if (value !== GENDERS.all) { return { gender: value }; }

  return {};
};

export const getChildrenValue = value => {
  if (value === CHILDREN.yes) { return { children: true }; }
  if (value === CHILDREN.no) { return { children: false }; }

  return {};
};

export const getAgeFromValue = value => {
  if (!isEmpty(value)) { return { age_from: value }; }

  return {};
};

export const getAgeToValue = value => {
  if (!isEmpty(value)) { return { age_to: value }; }

  return {};
};
