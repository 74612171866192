import yup from 'lib/yupLocalised';

import {
  MIN_PERCENTAGE_LENGTH,
  MAX_PERCENTAGE_LENGTH,
  MIN_COMMISSION_CURRENCY_LENGTH,
  MAX_COMMISSION_CURRENCY_LENGTH,
} from 'constants';

export default yup.object().shape({
  rate: yup.number()
    .min(MIN_PERCENTAGE_LENGTH)
    .max(MAX_PERCENTAGE_LENGTH)
    .required()
    .isValidPercent(),
  minimum: yup.number()
    .min(MIN_COMMISSION_CURRENCY_LENGTH)
    .max(MAX_COMMISSION_CURRENCY_LENGTH)
    .required(),
  maximum: yup.number()
    .max(MAX_COMMISSION_CURRENCY_LENGTH)
    .maxCommission(yup.ref('minimum'))
    .required(),
});
