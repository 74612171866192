import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { removeJoinRequest } from 'state/concepts/userProfile/joinRequests/actions';
import { removeJoinRequestLoadingSelector } from 'state/concepts/userProfile/joinRequests/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class RemoveJoinRequestModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    joinRequest: PropTypes.shape().isRequired,
    roleName: PropTypes.string.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, joinRequest: { id }, roleName } = this.props;

    onSubmit(id, roleName);
  }

  render() {
    const { joinRequest } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'joinRequests.removeModal.title' }}
        bodyText={[{
          id: 'joinRequests.removeModal.body',
          values: { name: <span className="f-700">{profileName(joinRequest)}</span> },
        }]}
        submitText={{ id: 'shared.remove' }}
        submitClassName="w-136"
        kind="danger"
      />
    );
  }
}

const mapStateToProps = (state, { joinRequest: { id } }) => ({
  isLoading: removeJoinRequestLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: removeJoinRequest,
};

export { RemoveJoinRequestModal as RemoveJoinRequestModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(RemoveJoinRequestModal);
