import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { handleInputEvent } from 'utils/inputHelpers';
import getPercentOfCommission from 'utils/commission/getPercentOfCommission';

import PercentFieldComponent from './component';

class PercentField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    intl: PropTypes.shape().isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  formatter = value => (value ? `${value}%` : '');

  parser = value => {
    const result = value.replace('%', '');

    return getPercentOfCommission(result);
  };

  handleChange = (value) => {
    const { field, onChange } = this.props;

    onChange(value, field.value);
    handleInputEvent(field, field.onChange)(value || 0);
  };

  render = () => (
    <PercentFieldComponent
      {...this.props}
      formatter={this.formatter}
      parser={this.parser}
      onChange={this.handleChange}
    />
  );
}

export { PercentField as PercentFieldContainer };
export default injectIntl(PercentField);
