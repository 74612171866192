import endpoint from 'utils/endpoint';

import {
  patientsRoute,
  extraFreeVisitRoute,
  extraFreeVisitsRoute,
  extraFreeVisitsClinicsRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchExtraFreeVisitsEndpoint = endpoint(types.FETCH_FREE_VISITS, 'GET', extraFreeVisitsRoute);
export const fetchExtraFreeVisitsClinicsEndpoint = endpoint(types.FETCH_FREE_VISITS_CLINICS, 'GET', extraFreeVisitsClinicsRoute);
export const fetchExtraFreeVisitsPatientsEndpoint = endpoint(types.FETCH_FREE_VISITS_PATIENTS, 'GET', patientsRoute);
export const submitExtraFreeVisitsEndpoint = endpoint(types.SUBMIT_CREATE_FREE_VISITS, 'POST', extraFreeVisitsRoute);
export const submitUpdateExtraFreeVisitsEndpoint = freeVisitId => endpoint(types.SUBMIT_UPDATE_FREE_VISITS, 'PUT', extraFreeVisitRoute(freeVisitId));
