import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const getUserAppointment = appointmentId => ({
  type: types.GET_USER_APPOINTMENT,
  appointmentId,
});

export const updateDraftNote = values => ({
  type: types.UPDATE_DRAFT_NOTE,
  values,
});

export const getDraftNote = () => ({
  type: types.GET_DRAFT_NOTE,
});

export const getNote = noteId => ({
  type: types.GET_NOTE,
  noteId,
});

export const setAppointment = appointmentId => ({
  type: types.SET_APPOINTMENT,
  appointmentId,
});

export const getQuestionnaireResponses = questionnaireResponsesId => ({
  type: types.GET_QUESTIONNAIRE_RESPONSES,
  questionnaireResponsesId,
});

export const setQuestionnaireResponses = questionnaireResponsesId => ({
  type: types.SET_QUESTIONNAIRE_RESPONSES,
  questionnaireResponsesId,
});

export const toggleSymptomDetailsPanel = togglePanel => ({
  type: types.TOGGLE_SYMPTOM_DETAILS_PANEL,
  togglePanel,
});

export const setNotePanelVisible = panelVisible => ({
  type: types.SET_NOTE_PANEL_VISIBLE,
  panelVisible,
});

export const submitAddNote = makeFormSubmitAction(types.SUBMIT_ADD_NOTE);

export const setNotificationCancelConference = show => ({
  type: types.SET_NOTIFICATION_CANCEL_CONFERENCE,
  show,
});
