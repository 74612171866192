import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { setVouchers, setBlankState } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import { fetchVouchersEndpoint } from '../endpoints';
import { FETCH_VOUCHERS } from '../types';

const fetchVouchersOperation = createLogic({
  type: FETCH_VOUCHERS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchVouchersEndpoint;
    const state = getState();
    const {
      name, statuses, voucherBatchId, startDate, endDate, usedAtStartDate, usedAtEndDate,
    } = commonFiltersSelector(state);

    const params = {
      include: 'pharmacist-profile,patient-profile,voucher-batch',
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: {
        search: name,
        statuses,
        voucher_batch_id: voucherBatchId,
        timezone: currentTimezoneSelector(state),
        start_date: startDate,
        end_date: endDate,
        used_at_start_date: usedAtStartDate,
        used_at_end_date: usedAtEndDate,
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setBlankState(isBlankState({ name }, data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setVouchers(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVouchersOperation;
