import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import isBlankState from 'utils/isBlankState';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { paginationSelector } from '../selectors';
import { FETCH_DASHBOARDS } from '../types';
import { fetchDashboardsEndpoint } from '../endpoints';
import { setDashboardsIds, setBlankState } from '../actions';

const fetchDashboardsOperation = createLogic({
  type: FETCH_DASHBOARDS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchDashboardsEndpoint;
    const {
      startDate, endDate,
    } = commonFiltersSelector(state);

    const params = {
      filter: {
        clinic_id: 9995,
        start_date: startDate,
        end_date: endDate,
        limit: 50,
        offset: 0,
      },
      page: paginationSelector(state),
      // sort: 'start_time',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      const blankStateResult = isBlankState(undefined, data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setBlankState(blankStateResult));
      dispatch(setDashboardsIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchDashboardsOperation;
