import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { fileSize } from 'utils/file';

import PreviewJoinRequestModalComponent from './component';

class PreviewJoinRequestModalContainer extends React.Component {
  static propTypes = {
    joinRequest: PropTypes.shape().isRequired,
  }

  get getFileSize() {
    const { joinRequest: { cv } } = this.props;

    return fileSize(cv.size);
  }

  get getFileName() {
    const { joinRequest: { cv } } = this.props;

    return prop('filename', cv);
  }

  get getFileUrl() {
    const { joinRequest: { cv } } = this.props;

    return prop('url', cv);
  }

  render() {
    return (
      <PreviewJoinRequestModalComponent
        {...this.props}
        fileSize={this.getFileSize}
        fileName={this.getFileName}
        fileUrl={this.getFileUrl}
      />
    );
  }
}

export default PreviewJoinRequestModalContainer;
