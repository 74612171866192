import { combineReducers } from 'redux';

import * as types from './types';

const twoFactorAuthPasswordEntered = (state = false, action) => {
  switch (action.type) {
    case types.SET_TWO_FACTOR_AUTH_PASSWORD_ENTERED:
      return action.isPasswordEntered;
    default:
      return state;
  }
};

export default combineReducers({
  twoFactorAuthPasswordEntered,
});
