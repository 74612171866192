import endpoint from 'utils/endpoint';
import {
  patientsRoute,
  clinicsSymptomsRoute,
  usersRoute,
  specialitiesRoute,
} from 'lib/apiRoutes';
import {
  GET_PATIENTS,
  GET_SPECIALITIES,
  GET_SYMPTOMS,
  GET_DOCTORS,
} from './types';

export const getPatientsEndpoint = endpoint(GET_PATIENTS, 'GET', patientsRoute);
export const getSpecialitiesEndpoint = endpoint(GET_SPECIALITIES, 'GET', specialitiesRoute);
export const getSymptomsEndpoint = clinicId => endpoint(GET_SYMPTOMS, 'GET', clinicsSymptomsRoute(clinicId));
export const getDoctorsEndpoint = endpoint(GET_DOCTORS, 'GET', usersRoute);
