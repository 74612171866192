import endpoint from 'utils/endpoint';
import {
  usersAccountAuthorizeTwoFactorAuthenticationRoute,
  usersAccountTwoFactorAuthenticationRoute,
  usersAccountPasswordRoute,
  usersAccountEmailRoute,
  usersNotificationSettingRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const submitSetupTwoFactorAuthEndpoint = endpoint(types.SUBMIT_SETUP_TWO_FACTOR_AUTH, 'POST', usersAccountAuthorizeTwoFactorAuthenticationRoute);
export const submitAccountTwoFactorAuthenticationEndpoint = endpoint(types.SUBMIT_ACCOUNT_TWO_FACTOR_AUTH, 'POST', usersAccountTwoFactorAuthenticationRoute);
export const submitUpdateUserPasswordEndpoint = endpoint(types.SUBMIT_ACCOUNT_TWO_FACTOR_AUTH, 'PUT', usersAccountPasswordRoute);
export const submitUpdateUserEmailEndpoint = endpoint(types.SUBMIT_UPDATE_USER_EMAIL, 'POST', usersAccountEmailRoute);
export const confirmUserEmailEndpoint = endpoint(types.CONFIRM_USER_EMAIL, 'PUT', usersAccountEmailRoute);
export const updateAccountNotificationSetting = endpoint(types.UPDATE_NOTIFICATION_SETTING, 'PUT', usersNotificationSettingRoute);
