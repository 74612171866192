import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { is } from 'ramda';
import { getIn } from 'formik';
import { FormattedMessage } from 'react-intl';

import InputMessage from '../InputMessage';

const TextareaField = ({
  id,
  type,
  disabled,
  label,
  icon,
  field,
  placeholder,
  className,
  inputHeight,
  intl: { formatMessage },
  form: { touched, errors },
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name);
  const fieldErrors = getIn(errors, field.name);

  const wrapperClassNames = classNames(
    'textarea',
    className,
    {
      'main-input--disabled': disabled,
      'textarea--has-message-error': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
      'textarea--input-height': inputHeight,
    },
  );

  return (
    <div className={wrapperClassNames}>
      {
        label && (
          <label
            className="textarea__label"
            htmlFor={id}
          >
            <FormattedMessage {...label} />
          </label>
        )
      }
      <textarea
        className="textarea__field"
        type={type}
        placeholder={is(Object, placeholder) ? formatMessage(placeholder) : placeholder}
        id={id}
        disabled={disabled}
        {...field}
        {...props}
      />
      {
        fieldTouched && fieldErrors && (
          <InputMessage
            message={fieldErrors}
            icon={icon}
          />
        )
      }
    </div>
  );
};

TextareaField.defaultProps = {
  id: null,
  type: 'text',
  disabled: false,
  label: null,
  icon: null,
  placeholder: null,
  className: null,
  inputHeight: null,
};

TextareaField.propTypes = {
  inputHeight: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape().isRequired,
  className: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
};

export default TextareaField;
