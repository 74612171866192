import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { USER_TYPE } from 'constants/users';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess, setUserType } from 'state/concepts/session/actions';
import { getUsersOwnProfileEndpoint } from '../endpoints';
import { GET_USER_OWN_PROFILE } from '../types';

const getUsersOwnProfileOperation = createLogic({
  type: GET_USER_OWN_PROFILE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getUsersOwnProfileEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, {
        params: {
          include: 'user-profiles-clinic_specialities.speciality,user-onboarding-stepper,user-profiles-clinic_specialities.clinic_speciality,notification_setting,clinic',
        },
      });

      const normalizedData = normalize(data);

      dispatch(userLoginSuccess(data.data.id));
      dispatch(setUserType(USER_TYPE.user));
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getUsersOwnProfileOperation;
