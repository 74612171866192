import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import redirect from 'utils/redirect';

import { appointmentIdSelector } from 'state/concepts/userProfile/appointment/selectors';
import { hideModal as hideModalAction } from 'state/modal/actions';

import ConfirmModal from 'views/shared/ConfirmModal';

class LeaveConsultationModal extends React.Component {
  static propTypes = {
    appointmentId: PropTypes.string.isRequired,
    disconnect: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { appointmentId, disconnect, hideModal } = this.props;

    disconnect();
    hideModal();

    redirect({ href: `/appointments/${appointmentId}` });
  };

  render = () => (
    <ConfirmModal
      {...this.props}
      onSubmit={this.handleSubmit}
      title={{ id: 'videoConference.leaveConsultation.title' }}
      bodyText={[
        { id: 'videoConference.leaveConsultation.body' },
      ]}
      submitText={{ id: 'shared.leaveConsultation' }}
      kind="danger"
    />
  )
}

const mapStateToProps = state => ({
  appointmentId: appointmentIdSelector(state),
});

const mapDispatchToProps = {
  hideModal: hideModalAction,
};

export { LeaveConsultationModal as LeaveConsultationModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(LeaveConsultationModal);
