import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import yup from 'lib/yupLocalised';

import { handleSubmit } from 'utils/form/handleSubmit';

import { deactivateVirtualClinic } from 'state/concepts/userProfile/virtualClinics/actions';
import { deactivateVirtualClinicLoadingSelector } from 'state/concepts/userProfile/virtualClinics/selectors';

import DeactivateVirtualClinicModalComponent from './component';

class DeactivateVirtualClinicModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    virtualClinic: PropTypes.shape().isRequired,
    values: PropTypes.shape().isRequired,
  };

  get canSubmit() {
    const { values: { clinicName }, virtualClinic: { clinic } } = this.props;

    return clinicName === clinic.name;
  }

  render() {
    return (
      <DeactivateVirtualClinicModalComponent
        {...this.props}
        canSubmit={this.canSubmit}
      />
    );
  }
}

const mapStateToProps = (state, { virtualClinic: { clinic: { id } } }) => ({
  isLoading: deactivateVirtualClinicLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: deactivateVirtualClinic,
};

export { DeactivateVirtualClinicModal as DeactivateVirtualClinicModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ virtualClinic }) => ({
      clinicName: '',
      virtualClinicId: virtualClinic.id,
      clinicId: virtualClinic.clinic.id,
    }),
    validationSchema: yup.object().shape({
      clinicName: yup.string(),
    }),
    handleSubmit,
  }),
)(DeactivateVirtualClinicModal);
