import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isPlatformOwner } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSearchResults } from 'state/searchResults/actions';
import { clinicIdSelector } from 'state/concepts/userProfile/clinicSpecialities/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { fetchSymptomsEndpoint } from 'state/concepts/userProfile/symptoms/endpoints';
import { getSymptomsEndpoint } from '../endpoints';
import { GET_SYMPTOMS } from '../types';

const getSymptomsOperation = createLogic({
  type: GET_SYMPTOMS,
  latest: true,

  async process({ httpClient, action: { searchQuery }, getState }, dispatch, done) {
    const state = getState();
    const clinicId = clinicIdSelector(state);
    const currentUser = currentUserSelector(state);
    const symptomsEndpoint = isPlatformOwner(currentUser.roleName)
      ? fetchSymptomsEndpoint
      : getSymptomsEndpoint(clinicId);

    const { endpoint, url } = symptomsEndpoint;

    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      filter: {
        name: searchQuery,
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);
      const ids = pluck('id', data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSearchResults({ symptoms: ids }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getSymptomsOperation;
