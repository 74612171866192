import getUsersOwnProfile from './getUserOwnProfile';
import submitJoinRequest from './submitJoinRequest';
import getUserMyself from './getUserMyself';
import deactivateUser from './deactivateUser';
import updateUserProfile from './updateUserProfile';
import getUserCommissionSettings from './getUserCommissionSettings';

export default [
  getUsersOwnProfile,
  submitJoinRequest,
  getUserMyself,
  deactivateUser,
  updateUserProfile,
  getUserCommissionSettings,
];
