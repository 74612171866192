import { combineReducers } from 'redux';

import {
  SET_IS_EXPANDED,
} from './types';

const isExpanded = (state = true, action) => {
  switch (action.type) {
    case SET_IS_EXPANDED:
      return action.isExpanded;
    default:
      return state;
  }
};

export default combineReducers({
  isExpanded,
});
