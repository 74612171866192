import yup from 'lib/yupLocalised';
import {
  MAX_INPUT_CONTENT_LENGTH,
  MAX_INPUT_LENGTH,
} from 'constants';

export default yup.object().shape({
  name: yup.string().max(MAX_INPUT_LENGTH).required(),
  comment: yup.string().max(MAX_INPUT_CONTENT_LENGTH),
});
