import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { getIn } from 'formik';
import { FormattedMessage } from 'react-intl';

import SvgIcon from 'views/shared/SvgIcon';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import InputMessage from '../InputMessage';

const PasswordInputFieldComponent = ({
  id,
  disabled,
  label,
  icon,
  field,
  form,
  visiblePassword,
  checkPasswordStrength,
  strengthData,
  hideStepper,
  className,
  fullWidth,
  visible,
  onVisibilityChange,
  inputType,
  isTouchedWithErrors,
  ...props
}) => {
  const inputClassNames = classNames(
    'input-text',
    className,
    {
      'w-full': fullWidth,
    },
  );

  const wrapperClassNames = classNames(
    'input-text__wrapper',
    {
      'input-text__wrapper--disabled': disabled,
      'main-input--has-message': checkPasswordStrength || isTouchedWithErrors,
      'input-text__wrapper--error': isTouchedWithErrors,
    },
  );

  return (
    <div className={inputClassNames}>
      {label && (
        <label
          className="input-text__label"
          htmlFor={id}
        >
          <FormattedMessage {...label} />
        </label>
      )}
      <div className={wrapperClassNames}>
        <input
          className="input-text__field"
          type={inputType}
          {...{ disabled, id, ...field, ...props }}
        />
        {visiblePassword && (
          // eslint-disable-next-line jsx-a11y/no-redundant-roles
          <button
            aria-label="show password"
            className="input-text__icon"
            type="button"
            role="button"
            onClick={onVisibilityChange}
          >
            <SvgIcon icon={visible ? 'eye-slash-o' : 'eye-o'} />
          </button>
        )}
      </div>

      {isTouchedWithErrors && (
        <InputMessage
          message={getIn(form.errors, field.name)}
          icon={icon}
        />
      )}

      {checkPasswordStrength && !isTouchedWithErrors && (
        <PasswordStrengthMeter
          {...strengthData}
          hideStepper={hideStepper}
        />
      )}
    </div>
  );
};

PasswordInputFieldComponent.defaultProps = {
  id: null,
  visiblePassword: false,
  hideStepper: false,
  disabled: false,
  label: null,
  icon: null,
  className: null,
  fullWidth: false,
  isTouchedWithErrors: false,
};

PasswordInputFieldComponent.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  visiblePassword: PropTypes.bool,
  checkPasswordStrength: PropTypes.bool.isRequired,
  strengthData: PropTypes.shape().isRequired,
  hideStepper: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputType: PropTypes.string.isRequired,
  isTouchedWithErrors: PropTypes.bool,
};

export default PasswordInputFieldComponent;
