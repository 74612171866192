import { createSelector } from 'reselect';
import {
  path, prop, compose, last, propEq, filter, head, propOr,
} from 'ramda';

import { buildCollectionRecursive } from 'utils/diseases';
import buildCollection from 'utils/buildCollection';

import { loadingSelector, endpointMetaSelector } from 'state/data/selectors';

import { getDiseasesEndpoint, searchDiseasesEndpoint } from './endpoints';

const dataSelector = prop('data');

export const diseasesIdsSelector = path(['diseases', 'diseasesIds']);
export const commonDiseasesIdsSelector = path(['diseases', 'commonDiseasesIds']);
export const completeCancelDiseasesIdsSelector = path(['diseases', 'completeCancelDiseasesIds']);

export const diseasesSelector = createSelector(
  diseasesIdsSelector,
  dataSelector,
  buildCollectionRecursive('disease'),
);

export const commonDiseasesSelector = createSelector(
  commonDiseasesIdsSelector,
  dataSelector,
  buildCollection('diseaseGroup'),
);

export const completeCancelDiseasesSelector = createSelector(
  completeCancelDiseasesIdsSelector,
  dataSelector,
  buildCollection('diseaseGroup'),
);

export const cancelDiseasesSelector = createSelector(
  completeCancelDiseasesSelector,
  compose(
    propOr([], 'diseases'),
    head,
    filter(propEq('group', 'Cancel')),
  ),
);

export const completeDiseasesSelector = createSelector(
  completeCancelDiseasesSelector,
  compose(
    propOr([], 'diseases'),
    head,
    filter(propEq('group', 'Complete')),
  ),
);

export const lastDiseaseIdSelector = createSelector(
  diseasesSelector,
  compose(
    prop('id'),
    last,
  ),
);

export const diseasesLoadingSelector = state => (
  loadingSelector(state, getDiseasesEndpoint.endpoint)
);

export const searchDiseasesLoadingSelector = state => (
  loadingSelector(state, searchDiseasesEndpoint.endpoint)
);

export const searchDiseasesMetaSelector = state => (
  endpointMetaSelector(state, searchDiseasesEndpoint.endpoint)
);
