import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from 'views/shared/ConfirmModal';

const CreatePushNotificationConfirmModal = ({
  recepientsCount,
  onClose,
  onSubmit,
  ...rest
}) => (
  <ConfirmModal
    {...rest}
    submitClassName="w-136"
    onSubmit={onSubmit}
    onClose={onClose}
    title={{ id: 'pushNotifications.modal.title' }}
    bodyText={[{
      id: 'pushNotifications.confirmation.text',
      values: { count: <span className="f-700">{recepientsCount}</span> },
    }]}
    submitText={{ id: 'shared.yes' }}
    dismissText={{ id: 'shared.no' }}
  />
);

CreatePushNotificationConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recepientsCount: PropTypes.number.isRequired,
};

export default CreatePushNotificationConfirmModal;
