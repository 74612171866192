import PropTypes from 'prop-types';
import { prop } from 'ramda';

import isPresent from 'utils/isPresent';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';
import Button from 'views/shared/Button';

import Doctor from './Doctor';
import General from './General';
import Plan from './Plan';
import Prescription from './Prescription';
import AuxiliaryStudies from './AuxiliaryStudies';
import DoctorInfo from './DoctorInfo';
import SickNote from './SickNote';

const NoteModalComponent = ({
  note,
  onClose,
  opened,
  onChangeOpened,
  isPresentPrescriptions,
}) => (
  <Modal className="ant-modal-wrap__10" onClose={onClose}>
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        {prop('primaryDiagnoseTitle', note)}
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="modal-view-note">
        <div className="modal-view-note__col">
          <General note={note} />
          <Plan note={note} />
          {isPresent(prop('sickNote', note)) && <SickNote note={note} />}
          {isPresent(prop('auxiliaryStudies', note)) && opened && <AuxiliaryStudies note={note} />}
          {isPresent(prop('prescriptions', note)) && opened && <Prescription note={note} />}
          {isPresentPrescriptions && opened && <DoctorInfo note={note} />}
          {isPresentPrescriptions && (
            <Button
              text={{ id: `note.modal.${!opened ? 'showPrescription' : 'hidePrescription'}` }}
              kind="empty"
              onClick={onChangeOpened}
            />
          )}
        </div>
        <Doctor note={note} />
      </div>
    </div>
  </Modal>
);

NoteModalComponent.propTypes = {
  note: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onChangeOpened: PropTypes.func.isRequired,
  isPresentPrescriptions: PropTypes.bool.isRequired,
};

export default NoteModalComponent;
