import { createLogic } from 'redux-logic';

import { isVirtualClinicAdmin } from 'utils/roles';
import { isRevenueVirtualType } from 'utils/revenue';

import { currentUserSelector } from 'state/concepts/session/selectors';
import { fetchDoctorList } from 'state/concepts/userProfile/doctors/actions';
import { fetchVirtualClinicDoctorList } from 'state/concepts/userProfile/virtualClinicDoctors/actions';

import { revenueTypeSelector } from '../selectors';
import { FETCH_REVENUE_DOCTORS_LIST } from '../types';

const getDoctorsListOperation = createLogic({
  type: FETCH_REVENUE_DOCTORS_LIST,
  latest: true,

  async process({ getState, action: { lastId } }, dispatch, done) {
    const state = getState();
    const revenueType = revenueTypeSelector(state);
    const currentUser = currentUserSelector(state);

    if (isRevenueVirtualType(revenueType) && isVirtualClinicAdmin(currentUser.roleName)) {
      dispatch(fetchVirtualClinicDoctorList(lastId));
    } else {
      dispatch(fetchDoctorList(lastId));
    }

    done();
  },
});

export default getDoctorsListOperation;
