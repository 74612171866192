import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  deactivateSymptoms,
  setSelectedSymptoms as setSelectedSymptomsAction,
} from 'state/concepts/userProfile/symptoms/actions';
import { deactivateSymptomsLoadingSelector } from 'state/concepts/userProfile/symptoms/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class SymptomsBulkDeactivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    symptomIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedSymptoms: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, symptomIds, setSelectedSymptoms } = this.props;

    onSubmit(symptomIds);
    setSelectedSymptoms([]);
  }

  render() {
    const { symptomIds } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'symptoms.bulkDeactivateModal.title' }}
        bodyText={[
          [{
            id: 'symptoms.bulkDeactivateModal.body.start',
          },
          {
            id: 'symptoms.bulkDeactivateModal.body.end',
            values: { count: symptomIds.length },
            isBold: true,
          }],
        ]}
        submitText={{ id: 'shared.deactivate' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: deactivateSymptomsLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: deactivateSymptoms,
  setSelectedSymptoms: setSelectedSymptomsAction,
};

export { SymptomsBulkDeactivateModal as SymptomsBulkDeactivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SymptomsBulkDeactivateModal);
