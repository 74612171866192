import { combineReducers } from 'redux';

import * as types from './types';

const recurringAvailabilityIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_RECURRING_AVAILABILITY:
      return action.recurringAvailabilityIds;
    case types.RESET_RECURRING_AVAILABILITY:
      return [];
    default:
      return state;
  }
};

const recurringAvailabilityReducer = combineReducers({
  recurringAvailabilityIds,
});

export default recurringAvailabilityReducer;
