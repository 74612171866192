import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, getIn } from 'formik';

import {
  MIN_FREE_VISITS_COUNT,
  MAX_FREE_VISITS_COUNT,
  FREE_VISITS_STEP,
} from 'constants/freeVisits';

import {
  isPlatformOwner,
  isVirtualClinicAdmin,
  isPlatformOwnerOrAdmin,
} from 'utils/roles';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import SvgIcon from 'views/shared/SvgIcon';
import NumericField from 'views/shared/NumericField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';

import ClinicsDropdown from './ClinicsDropdown';
import PatientsDropdown from './PatientsDropdown';

const ExtraFreeVisitsModalComponent = ({
  errors,
  currentUser,
  isPatientProfile,
  isLoading,
  dirty,
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <header className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="patients.freeVisits.addFreeVisits" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </header>
    <div className="ant-modal__body">
      {(isVirtualClinicAdmin(currentUser.roleName) && getIn(errors, 'clinicId')) && (
        <Alert
          className="auth__alert"
          message={getIn(errors, 'clinicId')}
        />
      )}
      {isPlatformOwner(currentUser.roleName) && (
        <Field
          id="clinicId"
          name="clinicId"
          component={ClinicsDropdown}
          label={{ id: 'label.clinic' }}
          optionGroupLabel={{ id: 'label.clinic' }}
          placeholder={{ id: 'placeholder.addClinic' }}
          allSelectedDescription="patients.freeVisits.allClinicsAdded"
          className="select__short mb-16"
          icon="alert"
          showSearch
          mountToElement
        />
      )}
      {(isPlatformOwnerOrAdmin(currentUser.roleName) && !isPatientProfile) && (
        <Field
          id="patientProfileId"
          name="patientProfileId"
          component={PatientsDropdown}
          label={{ id: 'label.patients' }}
          optionGroupLabel={{ id: 'label.patients' }}
          placeholder={{ id: 'placeholder.addPatients' }}
          allSelectedDescription="patients.freeVisits.allPatientsAdded"
          className="select__short mb-16"
          icon="alert"
          showSearch
          mountToElement
        />
      )}
      <Field
        id="freeVisitsCount"
        name="freeVisitsCount"
        component={NumericField}
        label={{ id: 'label.freeVisits' }}
        placeholder="0"
        minValue={MIN_FREE_VISITS_COUNT}
        min={MIN_FREE_VISITS_COUNT}
        max={MAX_FREE_VISITS_COUNT}
        step={FREE_VISITS_STEP}
        icon="alert"
        isInteger
        fullWidth
      />
    </div>
    <footer className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.add' }}
        overrideKind={[null, 'primary']}
        onClick={handleSubmit}
        disabled={isLoading || !dirty}
        isLoading={isLoading}
      />
    </footer>
  </Modal>
);

ExtraFreeVisitsModalComponent.defaultProps = {
  errors: undefined,
  isLoading: undefined,
  isPatientProfile: false,
};

ExtraFreeVisitsModalComponent.propTypes = {
  errors: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  isPatientProfile: PropTypes.bool,
  isLoading: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ExtraFreeVisitsModalComponent;
