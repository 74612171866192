import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';

import requestErrorHandler from 'lib/requestErrorHandler';
import { isRevenueVirtualType } from 'utils/revenue';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { revenueFiltersSelector, revenueTypeSelector } from '../selectors';
import { downloadRevenueEndpoint, downloadRevenueVirtualClinicsEndpoint } from '../endpoints';
import { DOWNLOAD_REVENUE } from '../types';

const downloadRevenueOperation = createLogic({
  type: DOWNLOAD_REVENUE,
  latest: true,

  async process({ action: { intlFormatMessage }, httpClient, getState }, dispatch, done) {
    const state = getState();

    const revenueType = revenueTypeSelector(state);
    const { startDate, endDate } = revenueFiltersSelector(state);
    const { endpoint, url } = isRevenueVirtualType(revenueType)
      ? downloadRevenueVirtualClinicsEndpoint
      : downloadRevenueEndpoint;

    const params = {
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params }, { headers: { 'content-type': 'text/csv' } });

      dispatch(dataApiSuccess({ endpoint }));
      fileDownload(
        data,
        intlFormatMessage({ id: 'download.fileName.revenuesForVirtualClinics' }, { startDate, endDate }),
      );
      dispatch(showNotification({
        messageObject: { id: 'notifications.fileHasBeenDownloaded' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadRevenueOperation;
