import React from 'react';
import PropTypes from 'prop-types';

import { isPlatformOwner, isUser } from 'utils/roles';
import { isCancelled } from 'utils/appointments';
import isPresent from 'utils/isPresent';

import TransactionsComponent from './component';

class Transactions extends React.Component {
  static propTypes = {
    event: PropTypes.shape().isRequired,
    currentUser: PropTypes.shape().isRequired,
  };

  get showFreeVisit() {
    const { event, currentUser } = this.props;
    const paidTransactions = event.transactions.filter(({ topupSource }) => topupSource !== 'refund');

    return event.freeVisit && !isPresent(paidTransactions) && isPlatformOwner(currentUser.roleName);
  }

  get showFreeVisitRefundZero() {
    const { event } = this.props;
    const refundTransactions = event.transactions.filter(({ topupSource }) => topupSource === 'refund');

    return event.freeVisit && !isPresent(refundTransactions) && isCancelled(event);
  }

  get showDefaultRefundZero() {
    const { event } = this.props;
    const refundTransactions = event.transactions.filter(({ topupSource }) => topupSource === 'refund');

    return !event.freeVisit && !isPresent(refundTransactions) && isCancelled(event);
  }

  get showTransactionsForOwner() {
    const { currentUser } = this.props;

    return isPlatformOwner(currentUser.roleName);
  }

  get showTransactionsForUser() {
    const { event, currentUser } = this.props;

    return isUser(currentUser.roleName) && isCancelled(event);
  }

  render = () => (
    <TransactionsComponent
      {...this.props}
      showFreeVisitRefundZero={this.showFreeVisitRefundZero}
      showDefaultRefundZero={this.showDefaultRefundZero}
      showTransactionsForOwner={this.showTransactionsForOwner}
      showTransactionsForUser={this.showTransactionsForUser}
      showFreeVisit={this.showFreeVisit}
    />
  )
}

export { Transactions as TransactionsContainer };
export default Transactions;
