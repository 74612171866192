import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const setSortOrder = sortKey => ({
  type: types.SET_TRANSACTIONS_SORT_ORDER,
  sortKey,
});

export const fetchTransactions = patientId => ({
  type: types.FETCH_TRANSACTIONS,
  patientId,
});

export const setTransactionsIds = ids => ({
  type: types.SET_TRANSACTIONS_IDS,
  ids,
});

export const setTransactionsSelectedTab = selectedTab => ({
  type: types.SET_TRANSACTIONS_SELECTED_TAB,
  selectedTab,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_TRANSACTIONS_PAGE,
  pageNumber,
});

export const createTransaction = makeFormSubmitAction(types.CREATE_TRANSACTION);
