import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { removeVirtualClinicDoctorInvitation as removeVirtualClinicDoctorInvitationAction } from 'state/concepts/userProfile/virtualClinicDoctors/actions';
import { removeVirtualClinicDoctorLoadingSelector } from 'state/concepts/userProfile/virtualClinicDoctors/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class VCDRemoveInvitationModal extends React.Component {
  static propTypes = {
    doctor: PropTypes.shape().isRequired,
    removeVirtualClinicDoctorInvitation: PropTypes.func.isRequired,
  };

  handleRemoveVirtualClinicDoctorInvitation = () => {
    const { removeVirtualClinicDoctorInvitation, doctor: { vcdId, vcdType } } = this.props;

    removeVirtualClinicDoctorInvitation(vcdId, vcdType);
  };

  render = () => (
    <ConfirmModal
      {...this.props}
      onSubmit={this.handleRemoveVirtualClinicDoctorInvitation}
      title={{ id: 'doctors.removeInvitations.title' }}
      bodyText={[{
        id: 'doctors.removeInvitations.subTitle',
        values: { email: <span className="f-700">{this.props.doctor.email}</span> },
      }]}
      submitClassName="w-136"
      submitText={{ id: 'shared.remove' }}
      kind="danger"
    />
  );
}

const mapStateToProps = (state, { doctor: { vcdId, vcdType } }) => ({
  isLoading: removeVirtualClinicDoctorLoadingSelector(state, vcdId, vcdType),
});

const mapDispatchToProps = {
  removeVirtualClinicDoctorInvitation: removeVirtualClinicDoctorInvitationAction,
};

export { VCDRemoveInvitationModal as VCDRemoveInvitationModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(VCDRemoveInvitationModal);
