import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const Badge = ({ status, className }) => (
  <span className={classNames('label', className, {
    'label--deactivated': status === 'inactive' || status === 'deactivated' || status === 'finished',
    'label--used': status === 'used',
    'label--warning': status === 'warning',
    'label--invited': status === 'invited',
    'label--confirmed': status === 'confirmed',
    'label--error': status === 'rejected' || status === 'banned' || status === 'refund',
    'label--pending': status === 'pending',
    'label--new': status === 'new',
    'label--children': status === 'children',
    'label--wellness': status === 'wellness',
  })}
  >
    {status && <FormattedMessage id={`status.${status}`} />}
  </span>
);

Badge.defaultProps = {
  status: null,
  className: null,
};

Badge.propTypes = {
  status: PropTypes.oneOf(['warning', 'rejected', 'used', 'pending', 'new', 'accepted', 'active', 'inactive', 'deactivated', 'invited', 'current', 'finished', 'banned', 'paid', 'refund', 'confirmed', 'children', 'wellness', null]),
  className: PropTypes.string,
};

export default Badge;
