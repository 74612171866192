import yup from 'lib/yupLocalised';

import {
  GENDERS,
  CHILDREN,
  MIN_AGE_VALUE,
  MAX_AGE_VALUE,
  MAX_PUSH_NOTIFICATION_LENGTH,
} from 'constants/pushNotifications';

export default yup.object().shape({
  clinicIds: yup.array().of(yup.number().required()),
  patientIds: yup.array().of(yup.number().required()),
  specialityIds: yup.array().of(yup.number().required()),
  gender: yup.string()
    .oneOf([GENDERS.all, GENDERS.male, GENDERS.female, GENDERS.other]),
  children: yup.string()
    .oneOf([CHILDREN.all, CHILDREN.yes, CHILDREN.no]),
  ageFrom: yup.number()
    .min(MIN_AGE_VALUE)
    .max(MAX_AGE_VALUE),
  ageTo: yup.number()
    .min(MIN_AGE_VALUE)
    .max(MAX_AGE_VALUE)
    .maxAgeTo(yup.ref('ageFrom')),
  message: yup.string()
    .max(MAX_PUSH_NOTIFICATION_LENGTH)
    .required(),
});
