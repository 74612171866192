import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { USER_TYPE } from 'constants/users';

import requestErrorHandler from 'lib/requestErrorHandler';

import setUserCookies from 'utils/setUserCookies';
import redirect from 'utils/redirect';

import { dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess } from 'state/concepts/session/actions';
import { usersInvitationsRegistrationEndpoint } from '../endpoints';
import { SIGNUP_FROM_INVITATION } from '../types';

const signupFromInvitationOperation = createLogic({
  type: SIGNUP_FROM_INVITATION,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = usersInvitationsRegistrationEndpoint;

    try {
      const params = {
        token: values.token,
        password: values.password,
        include: 'clinic',
      };

      const { data } = await httpClient.post(url, params);

      const response = normalize(data);
      const currentUser = build(response, 'userProfile')[0];
      const tokens = data.meta.jwt;

      setUserCookies(currentUser, tokens, USER_TYPE.userOnboarding);

      dispatch(userLoginSuccess(currentUser.id));
      dispatch(dataApiSuccess({ response }));

      redirect({ href: '/onboarding' });
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default signupFromInvitationOperation;
