import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { resetAuthenticationEndpoint } from '../endpoints';
import { RESET_AUTHENTICATION } from '../types';

const resetAuthenticationOperation = createLogic({
  type: RESET_AUTHENTICATION,
  latest: true,

  async process({ httpClient, action: { doctorId } }, dispatch, done) {
    const { endpoint, url } = resetAuthenticationEndpoint(doctorId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.put(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.resetAuthentication' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.resetAuthenticationError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default resetAuthenticationOperation;
