import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { path, prop } from 'ramda';

import profileName from 'utils/profileName';

const AuxiliaryStudiesComponent = ({
  note,
}) => (
  <div className="view-note-table">
    <div className="view-note-table__header">
      <div className="view-note-table__col">
        <FormattedMessage id="note.modal.auxiliaryStudies" />
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patient" />
        </span>
        <span className="view-note-table__col-value">
          {profileName(path(['appointment', 'childProfile'], note) || prop('patientProfile', note))}
        </span>
      </div>
      <div className="view-note-table__col">
        <span className="view-note-table__col-key">
          <FormattedMessage id="note.modal.patientCitizenId" />
        </span>
        <span className="view-note-table__col-value">
          {prop('patientCitizenId', note)}
        </span>
      </div>
    </div>
    {prop('auxiliaryStudies', note).map((auxiliaryStudies) => (
      <div className="view-note-table__content" key={prop('id', auxiliaryStudies)}>
        <table className="modal-view-note__diagnose">
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="note.modal.test" />
              </td>
              <td>
                {prop('test', auxiliaryStudies)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="note.modal.comment" />
              </td>
              <td>
                {prop('comment', auxiliaryStudies)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))}
  </div>
);

AuxiliaryStudiesComponent.propTypes = {
  note: PropTypes.shape().isRequired,
};

export default AuxiliaryStudiesComponent;
