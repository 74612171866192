import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'formik';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import InputField from 'views/shared/InputField';
import SvgIcon from 'views/shared/SvgIcon';

const CancelModalComponent = ({
  onClose,
}) => (
  <Modal className="ant-modal-wrap__4" onClose={onClose}>
    <Form className="ant-modal__form">
      <div className="ant-modal__header">
        <h2 className="ant-modal__header-txt">
          <FormattedMessage id="cancelAppointmentModal.cancelAppointment" />
        </h2>
        <button type="button" onClick={onClose}>
          <SvgIcon icon="cross" className="ant-modal__close" />
        </button>
      </div>
      <div className="ant-modal__body">
        <Field
          name="cancellationReason"
          component={InputField}
          fullWidth
          id="cancellationReason"
          label={{ id: 'cancelAppointmentModal.reason' }}
          icon="alert"
        />
      </div>
      <div className="ant-modal__footer ant-modal__footer--right">
        <Button
          className="main-modal__footer-action"
          text={{ id: 'shared.leave' }}
          onClick={onClose}
          kind="tertiary"
        />
        <SubmitFormButton
          text={{ id: 'shared.cancel' }}
          overrideKind={['outline-danger', 'danger']}
        />
      </div>
    </Form>
  </Modal>
);

CancelModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CancelModalComponent;
