import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSearchResults } from 'state/searchResults/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import {
  fetchClinicSpecialitiesEndpoint,
  fetchVirtualClinicSpecialitiesEndpoint,
} from 'state/concepts/userProfile/clinicSpecialities/endpoints';
import { GET_CLINIC_SPECIALITIES } from '../types';

const getClinicSpecialitiesOperation = createLogic({
  type: GET_CLINIC_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState, action: { searchQuery } }, dispatch, done) {
    const state = getState();
    const { id, roleName } = currentUserSelector(state);
    const { url, endpoint } = isVirtualClinicAdmin(roleName)
      ? fetchVirtualClinicSpecialitiesEndpoint(id)
      : fetchClinicSpecialitiesEndpoint;

    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      sort: 'specialities.name',
      filter: {
        name: searchQuery,
      },
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);
      const ids = pluck('id', data.data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSearchResults({ clinicSpecialities: ids }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getClinicSpecialitiesOperation;
