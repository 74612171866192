import { createLogic } from 'redux-logic';

import { isVirtualClinicAdmin } from 'utils/roles';

import { currentUserSelector } from 'state/concepts/session/selectors';
import { resetDoctorListIds } from 'state/concepts/userProfile/doctors/actions';
import { resetVirtualClinicDoctorIds } from 'state/concepts/userProfile/virtualClinicDoctors/actions';

import { RESET_REVENUE_DOCTORS_LIST } from '../types';

const resetRevenueDoctorsListOperation = createLogic({
  type: RESET_REVENUE_DOCTORS_LIST,
  latest: true,

  async process({ getState }, dispatch, done) {
    const state = getState();
    const currentUser = currentUserSelector(state);

    if (isVirtualClinicAdmin(currentUser.roleName)) {
      dispatch(resetVirtualClinicDoctorIds());
    } else {
      dispatch(resetDoctorListIds());
    }

    done();
  },
});

export default resetRevenueDoctorsListOperation;
