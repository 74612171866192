import { pathEq } from 'ramda';

import { ONBOARDING_STATUSES } from 'constants/onboarding';

const isOnboardingCompleted = data => pathEq(
  ['data', 'attributes', 'onboarding-status'],
  ONBOARDING_STATUSES.completed,
  data,
);

export default isOnboardingCompleted;
