import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setMessages, setUnreadIndicatorVisibility } from '../actions';
import { firstUnreadMessageSelector, lastReadMessageSelector } from '../selectors';
import { fetchChatMessageEndpoint } from '../endpoints';
import { FETCH_MESSAGES } from '../types';

const fetchMessageOperation = createLogic({
  type: FETCH_MESSAGES,
  latest: true,

  async process({ action: { chatId, pageParams, isScrollIntoView }, httpClient, getState },
    dispatch, done) {
    const state = getState();
    const { url, endpoint } = fetchChatMessageEndpoint(chatId);

    const params = {
      page: {
        ...pageParams,
      },
      include: 'chat-user,attachment',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setMessages(pluck('id', data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));

      const lastReadMessage = lastReadMessageSelector(state);
      const firstUnreadMessage = firstUnreadMessageSelector(state);

      const messageToScroll = lastReadMessage || firstUnreadMessage;

      if (messageToScroll && isScrollIntoView) {
        const element = document.getElementById(`message-${messageToScroll.id}`);

        dispatch(setUnreadIndicatorVisibility(true));
        element.scrollIntoView();
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchMessageOperation;
