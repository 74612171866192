import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';
import { isPlatformOwnerOrAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { doctorProfileIdSelector } from '../selectors';
import { removeDoctorSignatureEndpoint, removeUserSignatureEndpoint } from '../endpoints';
import { REMOVE_DOCTOR_SIGNATURE } from '../types';

const removeDoctorSignatureOperation = createLogic({
  type: REMOVE_DOCTOR_SIGNATURE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const { id, roleName } = currentUserSelector(state);
    const doctorId = isPlatformOwnerOrAdmin(roleName) ? doctorProfileIdSelector(state) : id;
    const { url, endpoint } = isPlatformOwnerOrAdmin(roleName)
      ? removeDoctorSignatureEndpoint(doctorId)
      : removeUserSignatureEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url);

      const response = updateDataHelper(
        state.data, 'userProfile', doctorId,
        {
          attributes: {
            signatureUrls: null,
          },
        },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({
        messageObject: { id: 'notifications.profileSignatureRemoved' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.profileSignatureRemovedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeDoctorSignatureOperation;
