const namespace = 'doctors';

export const FETCH_DOCTOR_PROFILE = `${namespace}/FETCH_DOCTOR_PROFILE`;
export const SET_DOCTOR_PROFILE_ID = `${namespace}/SET_DOCTOR_PROFILE_ID`;
export const RESET_DOCTOR_PROFILE_ID = `${namespace}/RESET_DOCTOR_PROFILE_ID`;
export const UPDATE_DOCTOR_PROFILE = `${namespace}/UPDATE_DOCTOR_PROFILE`;
export const UPDATE_DOCTOR_ACCOUNT = `${namespace}/UPDATE_DOCTOR_ACCOUNT`;
export const UPLOAD_DOCTOR_AVATAR = `${namespace}/UPLOAD_DOCTOR_AVATAR`;
export const REMOVE_DOCTOR_AVATAR = `${namespace}/REMOVE_DOCTOR_AVATAR`;
export const FETCH_ALL_DOCTORS = `${namespace}/FETCH_ALL_DOCTORS`;
export const FETCH_DOCTORS_LIST = `${namespace}/FETCH_DOCTORS_LIST`;
export const SET_DOCTOR_LIST_IDS = `${namespace}/SET_DOCTOR_LIST_IDS`;
export const SET_DOCTOR_LIST_STATUSES = `${namespace}/SET_DOCTOR_LIST_STATUSES`;
export const RESET_DOCTOR_LIST_STATUSES = `${namespace}/RESET_DOCTOR_LIST_STATUSES`;
export const SET_DOCTOR_LIST_FILTER_PARAMS = `${namespace}/SET_DOCTOR_LIST_FILTER_PARAMS`;
export const RESET_DOCTOR_LIST_FILTER_PARAMS = `${namespace}/RESET_DOCTOR_LIST_FILTER_PARAMS`;
export const SET_DOCTOR_LIST_CLINIC_SPECIALITY_ID = `${namespace}/SET_DOCTOR_LIST_CLINIC_SPECIALITY_ID`;
export const RESET_DOCTOR_LIST_CLINIC_SPECIALITY_ID = `${namespace}/RESET_DOCTOR_LIST_CLINIC_SPECIALITY_ID`;
export const SET_DOCTORS_ROLES = `${namespace}/SET_DOCTORS_ROLES`;
export const RESET_DOCTORS_ROLES = `${namespace}/RESET_DOCTORS_ROLES`;
export const SET_DOCTORS_IDS = `${namespace}/SET_DOCTORS_IDS`;
export const ADD_DOCTORS_TO_IDS_LIST = `${namespace}/ADD_DOCTORS_TO_IDS_LIST`;
export const SET_DOCTORS_BLANK_STATE = `${namespace}/SET_DOCTORS_BLANK_STATE`;
export const SET_DOCTORS_CURRENT_PAGE = `${namespace}/SET_DOCTORS_CURRENT_PAGE`;
export const SET_DOCTORS_FILTERS = `${namespace}/SET_DOCTORS_FILTERS`;
export const INVITE_DOCTORS = `${namespace}/INVITE_DOCTORS`;
export const SET_DOCTORS_SORT = `${namespace}/SET_DOCTORS_SORT`;
export const RESEND_DOCTOR_INVITATION = `${namespace}/RESEND_DOCTOR_INVITATION`;
export const REMOVE_DOCTOR_INVITATION = `${namespace}/REMOVE_DOCTOR_INVITATION`;
export const RESET_AUTHENTICATION = `${namespace}/RESET_AUTHENTICATION`;
export const ACTIVATE_DOCTOR = `${namespace}/ACTIVATE_DOCTOR`;
export const DEACTIVATE_DOCTOR = `${namespace}/DEACTIVATE_DOCTOR`;
export const UPLOAD_DOCTOR_SIGNATURE = `${namespace}/UPLOAD_DOCTOR_SIGNATURE`;
export const REMOVE_DOCTOR_SIGNATURE = `${namespace}/REMOVE_DOCTOR_SIGNATURE`;
export const SET_DOCTOR_LIST_FILTERS = `${namespace}/SET_DOCTOR_LIST_FILTERS`;
export const RESET_DOCTOR_LIST_IDS = `${namespace}/RESET_DOCTOR_LIST_IDS`;
export const SUBMIT_PLATFORM_COMMISSION = `${namespace}/SUBMIT_PLATFORM_COMMISSION`;
export const FETCH_ASSIGNED_DOCTORS = `${namespace}/FETCH_ASSIGNED_DOCTORS`;
export const DOCTOR_ASSIGN_CLINIC_SPECIALITIES_INITIAL_STATE = `${namespace}/DOCTOR_ASSIGN_CLINIC_SPECIALITIES_INITIAL_STATE`;
export const DOCTOR_ASSIGN_CLINIC_SPECIALITIES_RESET_STATE = `${namespace}/DOCTOR_ASSIGN_CLINIC_SPECIALITIES_RESET_STATE`;
