import { combineReducers } from 'redux';

import {
  SET_DOCTOR_PROFILE_ID,
} from './types';

const doctorProfileId = (state = null, action) => {
  switch (action.type) {
    case SET_DOCTOR_PROFILE_ID:
      return action.profileId;
    default:
      return state;
  }
};

export default combineReducers({
  doctorProfileId,
});
