import { createLogic } from 'redux-logic';

import { showModal } from 'state/modal/actions';
import { activateVirtualClinic } from '../actions';
import { UPDATE_VIRTUAL_CLINIC_ACCOUNT } from '../types';

const updateVirtualClinicAccountOperation = createLogic({
  type: UPDATE_VIRTUAL_CLINIC_ACCOUNT,
  latest: true,

  async process({ action: { values, form, clinicId } }, dispatch, done) {
    if (values.active) {
      dispatch(activateVirtualClinic(undefined, clinicId));
    } else {
      dispatch(showModal({
        modalType: 'DEACTIVATE_VIRTUAL_CLINIC_MODAL',
        modalProps: { virtualClinic: { clinic: values.virtualClinic } },
      }));
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateVirtualClinicAccountOperation;
