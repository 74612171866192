import { createLogic } from 'redux-logic';
import {
  filter, propEq, pipe, head, prop,
} from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';
import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { timezonesSelector } from 'state/concepts/timezones/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { UPDATE_USER_PROFILE } from '../types';
import { updateUserProfileEndpoint } from '../endpoints';

const updateUserProfileOperation = createLogic({
  type: UPDATE_USER_PROFILE,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = updateUserProfileEndpoint;
    const state = getState();
    const { id } = currentUserSelector(state);
    const timezones = timezonesSelector(state);
    const formattedTimezone = pipe(
      filter(propEq('value', values.timezone)),
      head,
      prop('label'),
    )(timezones);

    const params = {
      phone_number: formatISOPhoneNumber(values.phoneNumber),
      about: values.about,
      timezone: values.timezone,
      country: values.country,
      city: values.city,
      first_name: values.firstName,
      last_name: values.lastName,
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url, params);

      const attributes = {
        ...values,
        formattedTimezone,
      };

      const response = updateDataHelper(
        state.data, 'userProfile', id,
        { attributes },
      );

      form.setValues(attributes);

      dispatch(showNotification({
        messageObject: { id: 'notifications.userProfileUpdated' },
      }));

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.userProfileUpdatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default updateUserProfileOperation;
