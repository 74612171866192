import { PERCENT_REGEX } from 'constants';

// eslint-disable-next-line import/prefer-default-export
export function isValidPercent() {
  return this.test(
    'isValidPercent',
    { id: 'yup.number.isValidPercent' },
    (value) => typeof value === 'number' && PERCENT_REGEX.test(value.toString()),
  );
}
