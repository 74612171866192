import fetchDoctorProfile from './fetchDoctorProfile';
import updateDoctorProfile from './updateDoctorProfile';
import updateDoctorAccount from './updateDoctorAccount';
import uploadDoctorAvatar from './uploadDoctorAvatar';
import removeDoctorAvatar from './removeDoctorAvatar';
import fetchAllDoctors from './fetchAllDoctors';
import filterDoctors from './filterDoctors';
import inviteDoctors from './inviteDoctors';
import resendDoctorInvitation from './resendDoctorInvitation';
import removeDoctorInvitation from './removeDoctorInvitation';
import resetAuthentication from './resetAuthentication';
import activateDoctor from './activateDoctor';
import deactivateDoctor from './deactivateDoctor';
import uploadDoctorSignature from './uploadDoctorSignature';
import removeDoctorSignature from './removeDoctorSignature';
import fetchDoctorsList from './fetchDoctorsList';
import filterDoctorList from './filterDoctorList';
import submitPlatformCommission from './submitPlatformCommission';
import assignClinicSpecialitiesInitialState from './assignClinicSpecialitiesInitialState';
import assignClinicSpecialitiesResetState from './assignClinicSpecialitiesResetState';

export default [
  fetchDoctorProfile,
  updateDoctorProfile,
  updateDoctorAccount,
  uploadDoctorAvatar,
  removeDoctorAvatar,
  fetchAllDoctors,
  filterDoctors,
  inviteDoctors,
  resendDoctorInvitation,
  removeDoctorInvitation,
  resetAuthentication,
  activateDoctor,
  deactivateDoctor,
  uploadDoctorSignature,
  removeDoctorSignature,
  fetchDoctorsList,
  filterDoctorList,
  submitPlatformCommission,
  assignClinicSpecialitiesInitialState,
  assignClinicSpecialitiesResetState,
];
