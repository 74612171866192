import { createLogic } from 'redux-logic';
import { dec, length } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import shouldSwitchToPreviousPage from 'utils/shouldSwitchToPreviousPage';
import { isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { hideModal } from 'state/modal/actions';
import { fetchVirtualClinicDoctors } from 'state/concepts/userProfile/virtualClinicDoctors/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';

import { fetchClinicSpecialities, removeSelectedClinicSpecialities, setCurrentPage } from '../actions';
import { clinicSpecialitiesTotalCountSelector, paginationSelector, currentPageSelector } from '../selectors';
import { removeClinicSpecialitiesEndpoint, removeVirtualClinicSpecialitiesEndpoint } from '../endpoints';
import { REMOVE_CLINIC_SPECIALITIES } from '../types';

const removeClinicSpecialitiesOperation = createLogic({
  type: REMOVE_CLINIC_SPECIALITIES,
  latest: true,

  async process({ httpClient, getState, action: { clinicSpecialitiesIds } }, dispatch, done) {
    const state = getState();
    const count = length(clinicSpecialitiesIds);
    const totalCount = clinicSpecialitiesTotalCountSelector(state);
    const pagination = paginationSelector(state);
    const currentPage = currentPageSelector(state);
    const shouldSwitchToPrevPage = shouldSwitchToPreviousPage(pagination, totalCount, count);
    const { roleName, clinic } = currentUserSelector(state);
    const { url, endpoint } = isVirtualClinicAdmin(roleName)
      ? removeVirtualClinicSpecialitiesEndpoint(clinic.id)
      : removeClinicSpecialitiesEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.delete(url, { params: { clinic_speciality_ids: clinicSpecialitiesIds } });

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(removeSelectedClinicSpecialities(clinicSpecialitiesIds));
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.clinicSpecialitiesRemoved',
          values: { count },
        },
      }));
      if (shouldSwitchToPrevPage) {
        dispatch(setCurrentPage(dec(currentPage)));
      }
      dispatch(fetchClinicSpecialities());
      if (isVirtualClinicAdmin(roleName)) {
        dispatch(fetchVirtualClinicDoctors());
      }
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: 'notifications.clinicSpecialitiesRemovedError',
          values: { count },
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default removeClinicSpecialitiesOperation;
