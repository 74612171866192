const namespace = 'appointment';

export const GET_USER_APPOINTMENT = `${namespace}/GET_USER_APPOINTMENT`;
export const SET_APPOINTMENT = `${namespace}/SET_APPOINTMENT`;
export const GET_QUESTIONNAIRE_RESPONSES = `${namespace}/GET_QUESTIONNAIRE_RESPONSES`;
export const SET_QUESTIONNAIRE_RESPONSES = `${namespace}/SET_QUESTIONNAIRE_RESPONSES`;
export const TOGGLE_SYMPTOM_DETAILS_PANEL = `${namespace}/TOGGLE_SYMPTOM_DETAILS_PANEL`;
export const SET_NOTE_PANEL_VISIBLE = `${namespace}/SET_NOTE_PANEL_VISIBLE`;
export const SUBMIT_ADD_NOTE = `${namespace}/SUBMIT_ADD_NOTE`;
export const GET_DRAFT_NOTE = `${namespace}/GET_DRAFT_NOTE`;
export const UPDATE_DRAFT_NOTE = `${namespace}/UPDATE_DRAFT_NOTE`;
export const GET_NOTE = `${namespace}/GET_NOTE`;
export const SET_NOTIFICATION_CANCEL_CONFERENCE = `${namespace}/SET_NOTIFICATION_CANCEL_CONFERENCE`;
