import { combineReducers } from 'redux';
import { union } from 'ramda';

import * as types from './types';

const virtualClinicDoctorId = (state = '', action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTOR_ID:
      return action.vcdId;
    case types.RESET_VIRTUAL_CLINIC_DOCTOR_ID:
      return '';
    default:
      return state;
  }
};

const virtualClinicDoctorIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTOR_IDS:
      return action.ids;
    case types.RESET_VIRTUAL_CLINIC_DOCTOR_IDS:
      return [];
    default:
      return state;
  }
};

const virtualClinicDoctorListIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS:
      return union(state, action.ids);
    case types.RESET_VIRTUAL_CLINIC_DOCTOR_LIST_IDS:
      return [];
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTORS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTORS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_DOCTORS_SORT:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  virtualClinicDoctorId,
  virtualClinicDoctorIds,
  virtualClinicDoctorListIds,
  isBlankState,
  pagination,
  sort,
});
