import * as types from './types';

export const joinVideoConference = appointmentId => ({
  type: types.JOIN_VIDEO_CONFERENCE,
  appointmentId,
});

export const setVideoConferenceId = id => ({
  type: types.SET_VIDEO_CONFERENCE_ID,
  id,
});

export const setSettings = settings => ({
  type: types.SET_SETTINGS,
  settings,
});

export const resetSettings = () => ({
  type: types.RESET_SETTINGS,
});

export const changeView = view => ({
  type: types.CHANGE_VIEW,
  view,
});

export const toggleChatPanelVisible = panelVisible => ({
  type: types.TOGGLE_CHAT_PANEL_VISIBLE,
  panelVisible,
});

export const canJoin = isCanJoin => ({
  type: types.CAN_JOIN,
  isCanJoin,
});

export const sendLoggers = loggers => ({
  type: types.SEND_LOGGERS,
  loggers,
});
