import React from 'react';
import { connect } from 'react-redux';

import { submitPushNotificationsLoadingSelector } from 'state/concepts/userProfile/pushNotifications/selectors';

import CreatePushNotificationConfirmModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class CreatePushNotificationConfirmModal extends React.Component {
  render = () => (
    <CreatePushNotificationConfirmModalComponent
      {...this.props}
    />
  )
}

const mapStateToProps = (state) => ({
  isLoading: submitPushNotificationsLoadingSelector(state),
});

export { CreatePushNotificationConfirmModal as CreatePushNotificationConfirmModalContainer };
export default connect(mapStateToProps)(CreatePushNotificationConfirmModal);
