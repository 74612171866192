import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import InputField from 'views/shared/InputField';
import TextareaField from 'views/shared/TextareaField';
import SvgIcon from 'views/shared/SvgIcon';

const EditBatchDetailsModalComponent = ({
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="vouchers.editBatch" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <Field
        name="name"
        component={InputField}
        className="mb-16"
        id="name"
        label={{ id: 'label.batchName' }}
        icon="alert"
        fullWidth
      />
      <Field
        name="comment"
        component={TextareaField}
        id="comment"
        label={{ id: 'label.commentAddress' }}
        icon="alert"
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.save' }}
        onClick={handleSubmit}
        isEditForm
      />
    </div>
  </Modal>
);

EditBatchDetailsModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditBatchDetailsModalComponent;
