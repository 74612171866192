import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isPlatformOwnerOrAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { doctorProfileIdSelector } from '../selectors';
import { uploadDoctorSignatureEndpoint, uploadUserSignatureEndpoint } from '../endpoints';
import { UPLOAD_DOCTOR_SIGNATURE } from '../types';

const uploadDoctorSignatureOperation = createLogic({
  type: UPLOAD_DOCTOR_SIGNATURE,
  latest: true,

  async process({ httpClient, getState, action: { data, isImageUrl } }, dispatch, done) {
    const state = getState();
    const { id, roleName } = currentUserSelector(state);
    const doctorId = isPlatformOwnerOrAdmin(roleName) ? doctorProfileIdSelector(state) : id;
    const { url, endpoint } = isPlatformOwnerOrAdmin(roleName)
      ? uploadDoctorSignatureEndpoint(doctorId)
      : uploadUserSignatureEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data: responseData } = await httpClient.put(url, data);
      const response = normalize(responseData);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'profileSignatureUpdated' : 'profileSignatureAdded'}` },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: `notifications.${isImageUrl ? 'profileSignatureUpdatedError' : 'profileSignatureAddedError'}` },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default uploadDoctorSignatureOperation;
