import * as types from './types';

export const fetchDateAvailability = (startDate, endDate, userId) => ({
  type: types.FETCH_DATE_AVAILABILITY,
  startDate,
  endDate,
  userId,
});

export const updateDateAvailability = (
  availabilities,
  startDate,
  endDate,
  userId,
  isDoctorProfilePage = false,
) => ({
  type: types.UPDATE_DATE_AVAILABILITY,
  availabilities,
  startDate,
  endDate,
  userId,
  isDoctorProfilePage,
});

export const setDateAvailability = dateAvailabilityIds => ({
  type: types.SET_DATE_AVAILABILITY,
  dateAvailabilityIds,
});

export const resetDateAvailability = () => ({
  type: types.RESET_DATE_AVAILABILITY,
});
