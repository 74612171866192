import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import SvgIcon from 'views/shared/SvgIcon';
import Button from 'views/shared/Button';
import PercentField from 'views/shared/PercentField';
import CurrencyField from 'views/shared/CurrencyField';
import SubmitFormButton from 'views/shared/SubmitFormButton';

const ChangeCommissionModalComponent = ({
  status,
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4 ant-modal-wrap--invitation"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="commissions.title.setCommission" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body ant-modal__body--border-bottom">
      <div className="modal-invite">
        {status && status.base && (
          <Alert
            className="mb-16"
            message={status.base}
          />
        )}
        <div className="profile-edit__form-grid">
          <Field
            name="rate"
            component={PercentField}
            id="rate"
            icon="alert"
            label={{ id: 'label.commission' }}
            placeholder="%"
            min={0}
            max={100}
            step={0.1}
          />
        </div>
        <div className="profile-edit__form-grid mb-0">
          <Field
            name="minimum"
            component={CurrencyField}
            id="minimum"
            label={{ id: 'label.minimum' }}
            icon="alert"
            placeholder="₲"
            min={0}
          />
          <Field
            name="maximum"
            component={CurrencyField}
            id="maximum"
            label={{ id: 'label.maximum' }}
            icon="alert"
            placeholder="₲"
            min={0}
          />
        </div>
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.set' }}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

ChangeCommissionModalComponent.defaultProps = {
  status: undefined,
};

ChangeCommissionModalComponent.propTypes = {
  status: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ChangeCommissionModalComponent;
