import {
  TIME_UNITS_DAY,
  TIME_UNITS_HOUR,
  TIME_UNITS_MINUTE,
  TIME_UNITS_MONTH,
} from 'constants/timeUnits';

const timeUnitsNamesEn = {
  [TIME_UNITS_MINUTE]: { singular: 'minute', plural: 'minutes' },
  [TIME_UNITS_HOUR]: { singular: 'hour', plural: 'hours' },
  [TIME_UNITS_DAY]: { singular: 'day', plural: 'days' },
  [TIME_UNITS_MONTH]: { singular: 'month', plural: 'months' },
};

const makeDurationUnitError = (messageStart, key) => {
  const unitsOptions = [TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH].map(
    unit => ` ${unit} {{${key}, plural, one {${timeUnitsNamesEn[unit].singular}} other {${timeUnitsNamesEn[unit].plural}}}}`,
  );
  return `${messageStart} {${key}} {unit, select,${unitsOptions.join(' ')}}`;
};

export default {
  locale: 'en',
  messages: {
    'yup.mixed.required': 'Can\'t be blank',
    'yup.string.url': 'This URL is invalid',
    'yup.string.email': 'Email is invalid',
    'yup.string.min': 'Use a minimum length of {min} characters',
    'yup.string.max': 'Must be equal or less than {max} characters',
    'yup.string.isDurationString': 'Invalid duration format (e.g. 1h 30m)',
    'yup.string.durationMin': 'Must be at least {min} long',
    'yup.string.durationMax': 'Must be equal or less than {max}',
    'yup.string.durationInUnitMax': makeDurationUnitError('Must be equal or less than', 'max'),
    'yup.string.durationInUnitMin': makeDurationUnitError('Must be at least', 'min'),
    'yup.string.currencyFromTo': 'From {min} to {max}',
    'yup.string.voucherCode': 'Must contain {length} characters',
    'yup.string.digitsNumber': '{length} digits number',
    'yup.number.integer': 'Must be an integer',
    'yup.number.positive': 'Must be a positive number',
    'yup.number.typeError': 'Must be numeric characters',
    'yup.number.moreThan': 'Price must be equal or greater than zero',
    'yup.number.min': 'Must be greater than or equal to {min}',
    'yup.number.max': 'Must be less than or equal to {max}',
    'yup.mixed.required.verificationCode': 'You should enter verification code, shown in Google Authenticator app',
    'yup.string.matches.onlyDigits': 'Digits only',
    'yup.notIdenticalPasswords': 'New and old password can’t be identical',
    'yup.string.pleaseAddSpecialityName': 'Please add speciality name',
    'yup.string.pleaseAddConsultationPrice': 'Please add consultation price',
    'yup.string.pleaseAddDoctor': 'Please add doctor',
    'yup.array.min': 'Must be at least {min} items',
    'yup.number.isValidPercent': 'Supported formats 35% or 35.4%',
    'yup.number.maxCommission': 'Maximum commission must be greater than minimum',
    'yup.number.maxAgeTo': 'Must be equal or more than “Age from”',

    'timeUnits.minutes': 'Minute',
    'timeUnits.hours': 'Hour',
    'timeUnits.days': 'Day',
    'timeUnits.weeks': 'Week',
    'timeUnits.months': 'Month',
    'timeUtils.interval.15': 'Every 15 min',
    'timeUtils.interval.30': 'Every 30 min',
    'timeUtils.interval.45': 'Every 45 min',

    'shared.submit': 'Submit',
    'shared.generate': 'Generate',
    'shared.subscribe': 'Subscribe',
    'shared.contactUs': 'Contact us',
    'shared.save': 'Save',
    'shared.send': 'Send',
    'shared.sendWithCount': 'Send {count}',
    'shared.verify': 'Verify',
    'shared.discard': 'Discard',
    'shared.email': 'Email',
    'shared.cancel': 'Cancel',
    'shared.cancelAppointment': 'Cancel appointment',
    'shared.ok': 'Ok',
    'shared.continue': 'Continue',
    'shared.search': 'Search',
    'shared.searchByNameEmailDoc': 'Search by name, email or identification document',
    'shared.password': 'Password',
    'shared.apply': 'Apply',
    'shared.resetAll': 'Reset all',
    'shared.noSearchResult': 'There are no results for your search request.',
    'shared.next': 'Next',
    'shared.back': 'Back',
    'shared.complete': 'Complete',
    'shared.no': 'No',
    'shared.notFound': 'Not Found',
    'shared.noResults': 'No results found',
    'shared.alt.noResults': 'No results',
    'shared.topup': 'Top-up',
    'shared.subtract': 'Subtract',
    'shared.undo': 'Undo',
    'shared.clear': 'Clear',
    'shared.clearAll': 'Clear all',
    'shared.notApplicable': 'Not applicable',
    'shared.today': 'Today',
    'shared.yesterday': 'Yesterday',
    'shared.otherDay': '{date}',
    'shared.formatTimeDays': '{count} {count, plural, one {day} other {days}} ago',
    'shared.formatTimeHours': '{count} {count, plural, one {hour} other {hours}} ago',
    'shared.formatTimeMinutes': '{count} min ago',
    'shared.male': 'Male',
    'shared.female': 'Female',
    'shared.other': 'Other',
    'shared.saveChanges': 'Save changes',

    'shared.alt.logo': 'Clinix Logo',
    'shared.alt.clinicLogo': '{clinicName} logo',
    'shared.alt.downloadAppStore': 'Download App Store',
    'shared.alt.downloadGooglePlay': 'Download Google Play',

    'shared.filters.namespace.doctors': 'doctors',
    'shared.filters.namespace.requests': 'requests',
    'shared.filters.namespace.patients': 'patients',
    'shared.filters.namespace.specialities': 'specialities',
    'shared.filters.namespace.symptoms': 'symptoms',
    'shared.filters.namespace.batches': 'batches',
    'shared.filters.namespace.clinics': 'clinics',
    'shared.filters.defaultLabel': 'All {namespace}',
    'shared.filters.statuses.title': 'By status',
    'shared.filters.statuses.all': 'All',
    'shared.filters.statuses.active': 'Active',
    'shared.filters.statuses.banned': 'Banned',
    'shared.filters.statuses.invited': 'Invited',
    'shared.filters.statuses.inactive': 'Deactivated',
    'shared.filters.statuses.deactivated': 'Deactivated',
    'shared.filters.statuses.confirmed': 'Confirmed',
    'shared.filters.statuses.pending': 'Pending',
    'shared.filters.statuses.new': 'New',
    'shared.filters.statuses.accepted': 'Accepted',
    'shared.filters.statuses.rejected': 'Rejected',
    'shared.filters.statuses.current': 'Current',
    'shared.filters.statuses.finished': 'Finished',
    'shared.filters.appliedFilters': '{count} filters',
    'shared.filters.wellness.title': 'Wellness',
    'shared.filters.wellness.all': 'All',
    'shared.filters.wellness.active': 'Active',
    'shared.filters.wellness.inactive': 'Not subscribed',
    'shared.activate': 'Activate',
    'shared.accept': 'Accept',
    'shared.reject': 'Reject',
    'shared.deactivate': 'Deactivate',
    'shared.active': 'Active',
    'shared.ban': 'Ban',
    'shared.confirm': 'Confirm',
    'shared.edit': 'Edit',
    'shared.copy': 'Copy',
    'shared.assign': 'Assign',
    'shared.create': 'Create',
    'shared.change': 'Change',
    'shared.upload': 'Upload',
    'shared.remove': 'Remove',
    'shared.delete': 'Delete',
    'shared.discardChanges': 'Discard changes?',
    'shared.headsUp': 'Heads up!',
    'shared.cancelText': 'Are you sure you want to discard changes?',
    'shared.update': 'Update',
    'shared.enable': 'Enable',
    'shared.disable': 'Disable',
    'shared.done': 'Done',
    'shared.left': 'Left',
    'shared.right': 'Right',
    'shared.top': 'Top',
    'shared.bottom': 'Bottom',
    'shared.retry': 'Retry',
    'shared.areYouSureYouWantToRemove': 'Are you sure you want to remove {name}?',
    'shared.download': 'Download',
    'shared.downloadCsv': 'Download CSV',
    'shared.downloadAppointmentDetails': 'Download appointment details',
    'shared.attachment': 'Attachment',
    'shared.leave': 'Leave',
    'shared.leaveConsultation': 'Leave consultation',
    'shared.join': 'Join',
    'shared.disabledWhileEditing': 'Disabled while editing.',
    'shared.saveOrDiscard': 'Save or discard to proceed navigation.',
    'shared.youAreOfflineNow': 'You are offline now',
    'shared.hide': 'Hide',
    'shared.show': 'Show',
    'shared.showMore': 'Show more',
    'shared.gotIt': 'Got it',
    'shared.helpCenter': 'Help Center',
    'shared.skip': 'Skip',
    'shared.add': 'Add',
    'shared.finish': 'Finish',
    'shared.free': 'FREE',
    'shared.exit': 'Exit',
    'shared.showAll': 'Show All',
    'shared.hideAll': 'Hide all',
    'shared.cvField.pleaseUploadValidSizeFile': 'Please, upload a file up to 5MB',
    'shared.cvField.nofollowFormat': 'Only PDF, DOC, DOCX, and JPEG are supported',
    'shared.files': 'Files',
    'shared.clinic': 'Clinic',
    'shared.phoneNumber': 'Phone number:',
    'shared.email.description': 'Email:',
    'shared.balance': 'Balance',
    'shared.yes': 'Yes',
    'shared.otherDiagnose': 'Other diagnose',
    'shared.freeVisit': 'Free visit',
    'shared.changeTimeZone': 'Change time zone',
    'shared.changeTimeZoneText': 'Are you sure you want to change the time zone? Your Recurring and Date-specific availability settings will be reset to the default.',
    'shared.invite': 'Invite',
    'shared.sendInvitations': 'Send invitations',
    'shared.resendInvitation': 'Resend invitation',
    'shared.cancelInvitation': 'Cancel invitation',
    'shared.removeInvitation': 'Remove invitation',
    'shared.resetAuthSettings': 'Reset authentication settings',
    'shared.setCommission': 'Set commission',
    'shared.percentage': 'Percentage',
    'shared.minimum': 'Minimum',
    'shared.maximum': 'Maximum',
    'shared.set': 'Set',
    'shared.changeDoctorTimeZoneText': 'Are you sure you want to change the time zone? Doctor\'s Recurring and Date-specific availability settings will be reset to the default.',
    'shared.yourPhoneNumberConnectedWithWhatsApp': 'Your phone number is connected to WhatsApp notifications. All WhatsApp notifications will be sent to the new number.',
    'shared.changePhoneNumber': 'Change phone number',
    'shared.assignToSpeciality': 'Assign to a speciality',
    'shared.assignDoctors': 'Assign doctors',
    'shared.more.small': 'more',
    'shared.less.small': 'less',
    'shared.all': 'All',
    'shared.allOptionsAdded': 'All options added',

    'role.independentDoctor': 'Independent doctor',
    'role.virtualClinicAdmin': 'Virtual clinic admin',
    'role.virtualClinicDoctor': 'Virtual clinic doctor',

    'status.active': 'Active',
    'status.inactive': 'Deactivated',
    'status.deactivated': 'Deactivated',
    'status.enabled': 'Enabled',
    'status.disabled': 'Disabled',
    'status.accepted': 'Accepted',
    'status.rejected': 'Rejected',
    'status.invited': 'Invited',
    'status.confirmed': 'Confirmed',
    'status.pending': 'Pending',
    'status.new': 'New',
    'status.used': 'Used',
    'status.current': 'Current',
    'status.finished': 'Finished',
    'status.banned': 'Banned',
    'status.paid': 'Paid',
    'status.refund': 'Refunded',
    'status.children': 'Children',
    'status.wellness': 'Wellness Patient',

    'filters.clear': 'Clear filters',

    'tooltips.more': '{count} more...',

    'errorPage.generalNotification': 'Sorry, something went wrong. Try reloading the page.',
    'accessErrorPage.title': 'Access forbidden',
    'accessErrorPage.body': 'The page you are trying to access has restricted access.',
    'accessErrorPage.button': 'Appointments',
    'clientErrorPage.title': 'Oops!',
    'clientErrorPage.body': 'It seems we have lost this page but we don’t want to lose you.',
    'clientErrorPage.button': 'Login',
    'serverErrorPage.title': 'Server Error',
    'serverErrorPage.body': 'Sorry..It’s not you, it’s us. Please try again later',

    'pageTitle.userInvitationSignup': 'Invitation',
    'pageTitle.onBoarding': 'OnBoarding',
    'pageTitle.joinRequest': 'Join Request',
    'pageTitle.login': 'Login',
    'pageTitle.resetPasswordConfirm': 'Reset password confirm',
    'pageTitle.resetPasswordRequest': 'Reset password request',
    'pageTitle.resetPasswordLinkExpired': 'Reset password link expired',
    'pageTitle.accountSettings': 'Account Settings',
    'pageTitle.accountAuth': 'Account Auth',
    'pageTitle.userProfile': 'User Profile',
    'pageTitle.editUserProfile': 'Edit User Profile',
    'pageTitle.clinicSpecialities': 'Clinic Specialities',
    'pageTitle.createClinicSpeciality': 'Create Clinic Speciality',
    'pageTitle.createClinicSpecialityAssignDoctors': 'Create Clinic Speciality - Assign Doctors',
    'pageTitle.editClinicSpeciality': 'Edit Clinic Speciality',
    'pageTitle.editClinicSpecialityDoctors': 'Edit Clinic Speciality - Assign Doctors',
    'pageTitle.specialities': 'Specialities',
    'pageTitle.createSpeciality': 'Create Speciality',
    'pageTitle.editSpeciality': 'Edit Speciality',
    'pageTitle.regularAvailability': 'Regular Availability',
    'pageTitle.dateAvailability': 'Date-Specific Availability',
    'pageTitle.appointments': 'Appointments',
    'pageTitle.patients': 'Patients',
    'pageTitle.patientAppointments': 'Patient appointments',
    'pageTitle.joinRequests': 'Join Requests',
    'pageTitle.appointmentDetails': 'Appointment details',
    'pageTitle.doctorProfile': 'Doctor Profile',
    'pageTitle.virtualClinicDoctorProfile': 'Virtual clinic doctor profile',
    'pageTitle.virtualClinicAdminProfile': 'Virtual clinic admin profile',
    'pageTitle.editVirtualClinicAdminProfile': 'Edit virtual clinic admin profile',
    'pageTitle.editDoctorProfile': 'Edit Doctor Profile',
    'pageTitle.doctors': 'Doctors',
    'pageTitle.balance': 'Balance',
    'pageTitle.symptoms': 'Symptoms',
    'pageTitle.revenue': 'Revenue',
    'pageTitle.revenue.independentDoctor': 'Independent doctors revenue',
    'pageTitle.revenue.virtualClinicsRevenue': 'Virtual clinics revenue',
    'pageTitle.createSymptom': 'Create Symptom',
    'pageTitle.vouchers': 'Vouchers',
    'pageTitle.voucherDetails': 'Voucher Details',
    'pageTitle.batchDetails': 'Batch Details',
    'pageTitle.editSymptom': 'Edit Symptom',
    'pageTitle.patientAccount': 'Account',
    'pageTitle.patientProcessingTopup': 'Wait processing your payment',
    'pageTitle.enterVoucherCode': 'Enter voucher code',
    'pageTitle.videoConference': 'Video conference',
    'pageTitle.editPatientProfile': 'Edit Patient Profile',
    'pageTitle.editChildrenProfile': 'Edit Children Profile',
    'pageTitle.downloadApp': 'Download App',
    'pageTitle.virtualClinics': 'Virtual Clinics',
    'pageTitle.clinicProfile': 'Clinic Profile',
    'pageTitle.independentDoctorCommissions': 'Independent doctors commissions',
    'pageTitle.virtualClinicCommissions': 'Virtual clinics commission',
    'pageTitle.virtualClinicDoctors': 'Virtual clinic doctors',
    'pageTitle.virtualClinicDoctorsCommissions': 'Virtual clinic doctors commissions',
    'pageTitle.pushNotifications': 'Push Notifications',
    'pageTitle.freeVisits': 'Free visits',
    'pageTitle.dashboards': 'Dashboards',

    'users.doctorInvitationSignup.inviteTokenStale': 'This invite link is no longer active',
    'users.doctorInvitationSignup.independentDoctor': 'Sign Up as Individual Doctor',
    'users.doctorInvitationSignup.virtualClinicAdmin': 'Sign Up as Virtual Clinic Admin',
    'users.doctorInvitationSignup.virtualClinicDoctor': 'Sign Up as Virtual Clinic Doctor',

    'users.signUp.userJoinRequest.titleJoinRequest': 'Join Request',
    'users.signUp.userJoinRequest.generalInformation': 'General information',
    'users.signUp.userJoinRequest.successJoinRequest': 'Thank you! Your request has been sent to Clinix',

    'resetPassword.strength.low': 'You can use uppercase characters, numbers and symbols to make your password stronger',
    'resetPassword.strength.strong': 'Your password is strong enough',

    'login.email': 'Email',
    'login.password': 'Password',
    'login.forgotPassword': 'Forgot password?',
    'login.privacyPolicy': 'Privacy Policy',
    'login.terms': 'Terms',
    'login.privacyPolicyAndTerms': 'By logging in, you agree to our {terms} and that you have read our {privacyPolicy}',
    'login.verificationFlow.title': 'Two-factor authentication',
    'login.verificationFlow.viewCodeOnYourDevice': 'Open the two-factor authentication app on your device to view your 6-digit verification code and verify your identity.',
    'login.verificationFlow.havingProblems': 'Having problems?',
    'login.verificationFlow.enterRecoveryCode': 'Enter a two-factor recovery code',
    'login.loginYourAccount': 'Login to your account',

    'login.recoveryFlow.title': 'Two-factor recovery',
    'login.recoveryFlow.recoveryCodesIfYouLostMobile': 'You can enter one of your recovery codes in case you lost access to your mobile device.',
    'login.recoveryFlow.cantFindVerificationCode': 'Can`t find your verification code?',
    'login.recoveryFlow.callUsAt': 'Call us at: {phoneNumber}',

    'label.firstName': 'First Name',
    'label.lastName': 'Last Name',
    'label.idNumber': 'ID Number',
    'label.email': 'Email',
    'label.role': 'Role',
    'label.phoneNumber': 'Phone number',
    'label.cv': 'Attach CV',
    'label.about': 'About',
    'label.aboutMe': 'About me',
    'label.aboutVirtualClinic': 'About',
    'label.licenseNumber': 'License number',
    'label.signature': 'Signature',
    'label.authCode': 'Authentication code',
    'label.recoveryCode': 'Recovery code',
    'label.currentPassword': 'Current password',
    'label.newPassword': 'New password',
    'label.timezone': 'Timezone',
    'label.country': 'Country',
    'label.city': 'City',
    'label.speciality': 'Speciality',
    'label.symptom': 'Symptom',
    'label.symptoms': 'Symptoms',
    'label.specialityName': 'Speciality name',
    'label.duration': 'Consultation duration',
    'label.acceptFreeVisits': 'Accept free visits',
    'label.bufferBefore': 'Buffer before',
    'label.bufferAfter': 'Buffer after',
    'label.minLeadTime': 'Min lead time',
    'label.maxFutureTime': 'Max future time',
    'label.anytime': 'Anytime',
    'label.active': 'Activated',
    'label.inactive': 'Deactivated',
    'label.pricePerSession': 'Price per session',
    'label.symptomName': 'Symptom name',
    'label.description': 'Description',
    'label.question': 'Question',
    'label.type': 'Type',
    'label.specialities': 'Specialities',
    'label.numberOfVouchers': 'Number of vouchers',
    'label.value': 'Value',
    'label.batchName': 'Batch name',
    'label.commentAddress': 'Comment / Address',
    'label.amount': 'Amount',
    'label.chiefComplaint': 'Chief complaint',
    'label.subjective': 'Subjective',
    'label.objective': 'Objective',
    'label.assessment': 'Assessment',
    'label.primaryDiagnose': 'Primary diagnose',
    'label.secondaryDiagnose': 'Secondary diagnose',
    'label.drug': 'Drug',
    'label.commercialName': 'Commercial name (optional)',
    'label.dose': 'Dose',
    'label.frequency': 'Frequency',
    'label.quantity': 'Quantity',
    'label.comment': 'Comment',
    'label.test': 'Test',
    'label.voucherCode': 'Voucher code',
    'label.other': 'Other',
    'label.otherAmount': 'Amount of money',
    'label.virtualClinic': 'Virtual clinic',
    'label.companyUrlOptional': 'Company URL (optional)',
    'label.cityOptional': 'City (optional)',
    'label.addressOptional': 'Address (optional)',
    'label.consultationFee': 'Consultation fee {sign}',
    'label.percentageSign': 'Percentage {sign}',
    'label.secretNote': 'These notes will only be visible to other doctors',
    'label.emailNotification': 'Email notification',
    'label.whatsappNotification': 'WhatsApp notification',
    'label.commission': 'Commission',
    'label.minimum': 'Minimum',
    'label.maximum': 'Maximum',
    'label.minimumSign': 'Minimum {sign}',
    'label.maximumSign': 'Maximum {sign}',
    'label.doctors': 'Doctors',
    'label.gender': 'Gender',
    'label.children': 'Children',
    'label.age.from': 'Age (from)',
    'label.age.to': 'Age (to)',
    'label.pushNotificationText': 'Push notification text',
    'label.clinic': 'Clinic',
    'label.patients': 'Patients',
    'label.freeVisits': 'Free visits',
    'label.wellness': 'Wellness patient',

    'label.sign.percent': '(%)',
    'label.sign.currencyGs': '(Gs)',

    'label.editor.blocktype.heading': 'Heading {count}',
    'label.editor.blocktype.normal': 'Normal',

    'placeholder.about': 'Tell about yourself',
    'placeholder.aboutVirtualClinic': 'Tell about your clinic',
    'placeholder.companyUrl': 'company-url',
    'placeholder.licenseNumber': 'License number',
    'placeholder.specialities': 'Add specialities',
    'placeholder.symptoms': 'Add symptoms',
    'placeholder.doctors': 'Add doctors',
    'placeholder.selectTimezone': 'Select timezone',
    'placeholder.selectCountry': 'Select country',
    'placeholder.speciality': 'Select speciality',
    'placeholder.duration': 'e.g. 30m, 1h 30m',
    'placeholder.anytime': 'Anytime',
    'placeholder.percent': '%',
    'placeholder.pricePerSession': '₲',
    'placeholder.selectPrimaryDiagnose': 'Select primary diagnose',
    'placeholder.selectSecondaryDiagnose': 'Select secondary diagnose',
    'placeholder.selectGender': 'Select gender',
    'placeholder.selectChildren': 'Select children',
    'placeholder.addClinic': 'Add clinic',
    'placeholder.addPatients': 'Add patients',

    'pagination.first': 'First',
    'pagination.last': 'Last',

    'notePrescription.tablet': 'Tablet',
    'notePrescription.capsule': 'Capsule',
    'notePrescription.ampule': 'Ampule',
    'notePrescription.application': 'Application',
    'notePrescription.spoonful_small': 'Spoonful (small)',
    'notePrescription.spray': 'Spray',
    'notePrescription.suppository': 'Suppository',
    'notePrescription.dropsgrams': 'Dropsgrams',
    'notePrescription.prefilled_syringe': 'Prefilled syringe',
    'notePrescription.vaginal_ovule': 'Vaginal ovule',
    'notePrescription.milligram': 'Mg',
    'notePrescription.millilitre': 'Ml',
    'notePrescription.microgram': 'μg',
    'notePrescription.gram': 'G',
    'notePrescription.dose': 'Dose',
    'notePrescription.puff': 'Puff',
    'notePrescription.times': '{count} {count, plural, one {time} other {times}}',
    'notePrescription.once': 'Once',
    'notePrescription.daily': 'Daily',
    'notePrescription.weekly': 'Weekly',
    'notePrescription.monthly': 'Monthly',
    'notePrescription.yearly': 'Yearly',
    'notePrescription.box': 'Box',
    'notePrescription.jar': 'Jar',
    'notePrescription.bottle': 'Bottle',
    'notePrescription.tube': 'Tube',
    'notePrescription.spray_bottle': 'Spray bottle',

    'twoFactorAuth.recoveryTitle': 'Recovery codes',
    'twoFactorAuth.scanTitle': 'Scan this QR code with your app',
    'twoFactorAuth.recoveryCodeAreUsedAccess': 'Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.',
    'twoFactorAuth.scanImageAboveOnYourPhone': 'Scan the image above with the two-factor authentication app on your phone. Download  Google authenticator - {appStore} or {playMarket}',
    'twoFactorAuth.appStore': 'App store',
    'twoFactorAuth.playMarket': 'Play market',
    'twoFactorAuth.copied': 'My recovery codes are copied!',
    'twoFactorAuth.pleaseSaveRecoveryCodes': 'Please, save your recovery codes',
    'twoFactorAuth.afterScanningDisplaySixDigits': 'After scanning the QR code image, the app will display a six-digit code that you can enter below.',
    'twoFactorAuth.label.enterTheCode': 'Enter the code',
    'twoFactorAuth.putCodesInSaveSpot': 'Put these in a safe spot. If you lose your device and don’t have the recovery codes you will lose access to your account.',

    'onboarding.userAccount.title': 'Help us set up your account',
    'onboarding.userAccount.generalInfoTitle': 'General information',
    'onboarding.userAccount.locationTitle': 'Location',
    'onboarding.userAccount.companyProfileTitle': 'Company profile',
    'onboarding.userAccount.general.pleaseProvideValidPhoneNumber': 'Please, provide your valid phone number so that we can contact you. We will not show your phone number to the patients',
    'onboarding.userAccount.helpInformation': 'Your company URL can only contain lowercase letters, numbers, dots, colons, forward slashes and dashes',

    'resetPassword.title': 'Reset your password',
    'resetPassword.info': 'Please enter the email address you used when creating your account and we will send you a recovery link.',
    'resetPassword.submit': 'Send recovery link',
    'resetPassword.backToLogin': 'Back to Login',
    'resetPassword.resendLink': 'Re-send recovery link',
    'resetPassword.checkEmail': 'Check your Email',
    'resetPassword.sendRecoveryLink': 'We sent a recovery link to your email address:',
    'resetPassword.requestNewLink': ' Didn\'t receive the email? Check your junk mail or request another one.',
    'resetPassword.newPassword': 'New password',
    'resetPassword.resetPasswordButton': 'Reset password',
    'resetPassword.setNewPassword': 'You need to set a new password for your account.',
    'resetPassword.createNewPassword': 'Create new password',
    'resetPassword.linkExpired.title': 'Reset password link has expired',
    'resetPassword.linkExpired.button': 'Log in',
    'resetPassword.modal.body': 'Are you sure you want to reset your password? You will be logged out, and reset password instructions will be sent to your email',

    'notifications.resetPasswordSuccess': 'Your password has been updated',
    'notifications.resentRecoveryLink': 'The recovery link has been resent',
    'notifications.resetPasswordInstructions': 'Reset password instructions were sent to your email',
    'notifications.successSubmitAccountTwoFactorAuth': 'New device has been connected',
    'notifications.accountDeactivated': 'Account has been deactivated',
    'notifications.yourEmailHasBeenChanged': 'Your email has been changed',
    'notifications.emailHasBeenConfirmed': 'Your new email address has been confirmed',
    'notifications.invalidLink': 'Link is invalid',
    'notifications.clinicSpecialitiesActivated': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been activated',
    'notifications.clinicSpecialitiesDeactivated': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been deactivated',
    'notifications.clinicSpecialitiesRemoved': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been removed',
    'notifications.clinicSpecialitiesRemovedError': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} not been removed',
    'notifications.clinicSpecialityAdded': 'Speciality has been added',
    'notifications.clinicSpecialityUpdated': 'Speciality has been updated',
    'notifications.clinicSpecialityAssignDoctors': '{count} {count, plural, one {doctor} other {doctors}} {count, plural, one {has} other {have}} been assigned',
    'notifications.userProfileUpdated': 'Your profile has been updated',
    'notifications.userProfileUpdatedError': 'Your profile has not been updated',
    'notifications.regularAvailabilityUpdated': 'Regular availability has been updated',
    'notifications.dateAvailabilityUpdated': 'Date-Specific availability has been updated',
    'notifications.appointmentCancelled': 'Event has been cancelled',
    'notifications.joinRequestAccepted': 'Request has been accepted',
    'notifications.joinRequestRejected': 'Request has been rejected',
    'notifications.joinRequestRemoved': 'Request has been removed',
    'notifications.profileImageAdded': 'Profile image has been added',
    'notifications.profileImageUpdated': 'Profile image has been updated',
    'notifications.profileImageRemoved': 'Profile image has been removed',
    'notifications.profileImageAddedError': 'Profile image has not been added',
    'notifications.profileImageUpdatedError': 'Profile image has not been updated',
    'notifications.profileImageRemovedError': 'Profile image has not been removed',
    'notifications.doctorsProfileUpdated': 'Doctor`s profile has been updated',
    'notifications.doctorsProfileUpdatedError': 'Doctor`s profile has not been updated',
    'notifications.doctorsInvited': 'Independent {count, plural, one {doctor} other {doctors}} {count, plural, one {has} other {have}} been invited',
    'notifications.doctorsInvitationResent': 'Invitation has been resent',
    'notifications.doctorsInvitationResentError': 'Invitation has not been resent',
    'notifications.resetAuthentication': 'Your authentication settings have been successfully reset',
    'notifications.resetAuthenticationError': 'Couldn\'t reset your authentication settings. Please check the highlighted fields',
    'notifications.doctorsInvitationRemove': 'Invitation has been removed',
    'notifications.doctorsInvitationRemoveError': 'Invitation has not been removed',
    'notifications.doctorsInvitationRemoveAcceptedUserError': 'This doctor is already a member of Clinix',
    'notifications.doctorActivated': 'Account has been activated',
    'notifications.doctorActivatedError': 'Account has not been activated',
    'notifications.doctorDeactivated': 'Account has been deactivated',
    'notifications.doctorDeactivatedError': 'Account has not been deactivated',
    'notifications.doctorAssignClinicSpecialities': '{count} {count, plural, one {speciality} other {specialities}} {count, plural, one {has} other {have}} been assigned',
    'notifications.yourSymptomImageUpdated': 'Your symptom image has been updated',
    'notifications.yourSymptomImageRemoved': 'Your symptom image has been removed',
    'notifications.yourSymptomImageUpdatedError': 'Your symptom image not been updated',
    'notifications.yourSymptomImageRemovedError': 'Your symptom image not been removed',
    'notifications.symptomsActivated': '{count, plural, one {Symptom} other {Symptoms}} {count, plural, one {has} other {have}} been activated',
    'notifications.symptomsActivatedError': '{count, plural, one {Symptom} other {Symptoms}} {count, plural, one {has} other {have}} not been activated',
    'notifications.symptomsDeactivated': '{count, plural, one {Symptom} other {Symptoms}} {count, plural, one {has} other {have}} been deactivated',
    'notifications.symptomsDeactivatedError': '{count, plural, one {Symptom} other {Symptoms}} {count, plural, one {has} other {have}} not been deactivated',
    'notifications.patientActivated': 'Patient has been activated',
    'notifications.patientActivatedError': 'Patient has not been activated',
    'notifications.patientDeactivated': 'Patient has been deactivated',
    'notifications.patientDeactivatedError': 'Patient has not been deactivated',
    'notifications.patientBanned': 'Patient has been banned',
    'notifications.patientBannedError': 'Patient has not been banned',
    'notifications.copyToClipboard': 'Copied to clipboard',
    'notifications.specialitiesDeleted': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been deleted',
    'notifications.specialitiesDeletedError': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} not been deleted',
    'notifications.specialitiesActivated': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been activated',
    'notifications.specialitiesActivatedError': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} not been activated',
    'notifications.specialitiesDeactivated': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} been deactivated',
    'notifications.specialitiesDeactivatedError': '{count, plural, one {Speciality} other {Specialities}} {count, plural, one {has} other {have}} not been deactivated',
    'notifications.specialityCreated': 'Speciality has been created',
    'notifications.specialityUpdated': 'Speciality has been updated',
    'notifications.addedNewSymptom': 'New symptom has been added',
    'notifications.vouchersDeleted': '{count, plural, one {Voucher} other {Vouchers}} {count, plural, one {has} other {have}} been deleted',
    'notifications.vouchersDeletedError': '{count, plural, one {Voucher} other {Vouchers}} {count, plural, one {has} other {have}} not been deleted',
    'notifications.fileHasBeenDownloaded': 'The file has been downloaded',
    'notifications.csvDownloadFailed': 'Csv download failed',
    'notifications.fileHasBeenDeleted': 'File has been deleted',
    'notifications.fileHasBeenDeletedError': 'File has not been deleted',
    'notifications.vouchersGenerated': '{count, plural, one {Voucher} other {Vouchers}} {count, plural, one {has} other {have}} been generated',
    'notifications.batchUpdated': 'Batch has been updated',
    'notifications.symptomDetailsUpdated': 'Symptom details has been updated',
    'notifications.questionnaireUpdated': 'Questionnaire has been updated',
    'notifications.specialitiesUpdated': 'Specialities has been updated',
    'notifications.topupSuccessfully': 'You have successfully topped-up patient balance by {amount}',
    'notifications.subtractSuccessfully': 'You have successfully subtracted {amount} from patient`s account \n',
    'notifications.addNoteSuccessfully': 'New note has been created',
    'notifications.updateNoteSuccessfully': 'Note has been updated',
    'notifications.diagnoseAlreadyAdded': 'Diagnose already added',
    'notifications.pleaseLoginToConfirmYourEmail': 'Please login to confirm your email',
    'notifications.patientProfileUpdated': 'Patient profile has been updated',
    'notifications.yourCurrentSessionHasBeenExpired': 'Your current session has been expired',
    'notifications.virtualClinicAdminInvited': 'Virtual clinic {count, plural, one {admin has} other {admins have}} been invited',
    'notifications.virtualClinicAdminInvitedError': 'Virtual clinic {count, plural, one {admin has} other {admins have}} not been invited',
    'notifications.virtualClinicInvitationResent': 'Invitation has been resent',
    'notifications.virtualClinicInvitationResentError': 'Invitation has not been resent',
    'notifications.virtualClinicInvitationCancelled': 'Invitation has been cancelled',
    'notifications.virtualClinicInvitationCancelledError': 'Invitation has not been cancelled',
    'notifications.virtualClinicDeactivated': 'Virtual clinic has been deactivated',
    'notifications.virtualClinicDeactivatedError': 'Virtual clinic has not been deactivated',
    'notifications.virtualClinicActivated': 'Virtual clinic has been activated',
    'notifications.virtualClinicActivatedError': 'Virtual clinic has not been activated',
    'notifications.virtualClinicResetAuthentication': 'Authentication setting has been reset',
    'notifications.virtualClinicResetAuthenticationError': 'Authentication setting has not been reset',
    'notifications.virtualClinicProfileHasBeenUpdated': 'Virtual clinic profile has been updated',
    'notifications.virtualClinicActivatedExtraFreeVisits': 'Extra free Visits have been activated',
    'notifications.virtualClinicActivatedExtraFreeVisitsError': 'Extra free Visits have not been activated',
    'notifications.virtualClinicDeactivatedExtraFreeVisits': 'Extra free Visits have been deactivated',
    'notifications.virtualClinicDeactivatedExtraFreeVisitsError': 'Extra free Visits have not been deactivated',
    'notifications.commissionSettingsHaveBeenUpdated': 'Commission settings have been updated',
    'notifications.preferencesUpdated': 'Your preferences has been updated',
    'notifications.preferencesError': 'Your preferences has not been updated',
    'notifications.csvDownloadPatientsSuccess': 'The data is uploading, you will receive an email when your data is ready',
    'notifications.csvDownloadPatientsFailed': 'Something went wrong while exporting CSV file',
    'notifications.linkCopied': 'Link copied to clipboard',
    'notifications.clinicLogoAdded': 'Clinic logo has been added',
    'notifications.clinicLogoUpdated': 'Clinic logo has been updated',
    'notifications.clinicLogoRemoved': 'Clinic logo has been removed',
    'notifications.clinicLogoAddedError': 'Clinic logo has been added',
    'notifications.clinicLogoUpdatedError': 'Clinic logo has not been updated',
    'notifications.clinicLogoRemovedError': 'Clinic logo has not been removed',
    'notifications.profileSignatureAdded': 'Profile signature has been added',
    'notifications.profileSignatureUpdated': 'Profile signature has been updated',
    'notifications.profileSignatureRemoved': 'Profile signature has been removed',
    'notifications.profileSignatureAddedError': 'Profile signature has not been added',
    'notifications.profileSignatureUpdatedError': 'Profile signature has not been updated',
    'notifications.profileSignatureRemovedError': 'Profile signature has not been removed',
    'notifications.pushNotificationHasBeenSend': 'Push notification has been send',
    'notifications.freeVisitsHasBeenUpdated': 'Free visits has been updated',
    'notifications.freeVisitsHasBeenUpdatedError': 'Free visits has not been updated',

    'userSidebar.appointments': 'Appointments',
    'userSidebar.availability': 'Availability',
    'userSidebar.regularAvailability': 'Regular Availability',
    'userSidebar.dateSpecificAvailability': 'Date-Specific Availability',
    'userSidebar.clinicSpecialities': 'Clinic Specialities',
    'userSidebar.specialities': 'Specialities',
    'userSidebar.patients': 'Patients',
    'userSidebar.independentDoctors': 'Independent Doctors',
    'userSidebar.independentDoctors.requests': 'Requests',
    'userSidebar.independentDoctors.commission': 'Commission',
    'userSidebar.independentDoctors.doctors': 'Doctors',
    'userSidebar.symptoms': 'Symptoms',
    'userSidebar.vouchers': 'Vouchers',
    'userSidebar.revenue': 'Revenue',
    'userSidebar.revenue.independentDoctors': 'Independent Doctors',
    'userSidebar.revenue.virtualClinics': 'Virtual Clinics',
    'userSidebar.revenue.total': 'Total',
    'userSidebar.virtualClinics': 'Virtual Clinics',
    'userSidebar.virtualClinics.clinics': 'Clinics',
    'userSidebar.virtualClinics.doctors': 'Doctors',
    'userSidebar.virtualClinics.requests': 'Requests',
    'userSidebar.virtualClinics.commission': 'Commission',
    'userSidebar.clinicProfile': 'Clinic Profile',
    'userSidebar.doctorsAndAvailability': 'Doctors & Availability',
    'userSidebar.doctorsAndAvailability.doctors': 'Doctors',
    'userSidebar.doctorsAndAvailability.recurringAvailability': 'Recurring Availability',
    'userSidebar.doctorsAndAvailability.dateSpecificAvailability': 'Date-Specific Availability',
    'userSidebar.doctorsAndAvailability.commission': 'Commission',
    'userSidebar.pushNotifications': 'Push Notifications',
    'userSidebar.dashboards': 'Dashboard',

    'userAccountWidget.accountTitle': 'Account',
    'userAccountWidget.myProfile': 'My Profile',
    'userAccountWidget.accountSettings': 'Account Settings',
    'userAccountWidget.logout': 'Log out',

    'accountSettings.setupNewTwoFactorAuth': 'Set up a new 2FA application',
    'accountSettings.setupNewTwoFactorAuthDescription': '{setup}{breakingLine}{recoveryCodes}',
    'accountSettings.youCanSetupTwoFactorAuthWhenLostDevice': 'You can set up a 2FA application on another device, but you won\'t be able to use the 2FA codes from your previous device. Important! We will create a new set of recovery codes, so make sure to save them in a secure place.',
    'accountSettings.youNeedUpdateRecoveryCodes': 'If you need to update your recovery codes, please, set up a new 2FA application on your device and delete the old one.',
    'accountSettings.setupNewTwoFactorAuthBtn': 'Set up new two-factor authentication app',

    'accountSettings.twoFactorAuth.title': 'Set up new two-factor authentication app',
    'accountSettings.twoFactorAuth.bodyText': 'Please enter your password to proceed.',
    'accountSettings.backToView': 'Back to view',

    'accountSettings.password': 'Password',
    'accountSettings.changePassword': 'Change password',
    'accountSettings.feelFreeToUpdateYourPassword': 'Feel free to update your password so your account stays secure.',
    'accountSettings.forgotPassword': 'Forgot password?',

    'accountSettings.updateEmail': 'Update email',
    'accountSettings.yourCurrentEmail': 'Your current email: ',
    'accountSettings.label.newEmail': 'New email',
    'accountSettings.label.password': 'Current Password (if changing email)',
    'accountSettings.newEmail': 'We\'ve sent you an email to confirm your new address: {newEmail}.',
    'accountSettings.newEmailConfirmed': 'We will continue to use your old address: {email}, until you confirm the change.',
    'accountSettings.emailConfirmed': 'Email confirmed',

    'accountSettings.deactivateAccount.title': 'Account status',
    'accountSettings.deactivateAccount.description': 'Deactivated account will not be visible for patients. Active appointments need to be done or cancelled.{breakingLine}NOTE: This change will take effect immediately. An administrator of your workspace will need to re-enable your account if you would like to rejoin this workspace. All your data will be reactivated.',
    'accountSettings.deactivateAccount.button': 'Deactivate my account',

    'accountSettings.notificationCapture': 'Receive email notifications and reminders about your appointments',
    'accountSettings.whatsAppNotificationCapture': 'Receive WhatsApp notifications and reminders about your appointments',

    'deactivateAccountModal.title': 'Deactivate account',
    'deactivateAccountModal.areYouSure': 'Are you sure you want to deactivate your account?{breakingLine}Please, enter your password to deactivate your account.',
    'deactivateAccountModal.label.password': 'Password',
    'deactivateAccountModal.button': 'Yes, deactivate my account',

    'userProfile.location': 'Location',
    'userProfile.timezone': 'Timezone: {timezone}',
    'userProfile.country': 'Country: {country}',
    'userProfile.city': 'City: {city}',
    'userProfile.address': 'Address: {address}',
    'userProfile.about': 'About',
    'userProfile.aboutMe': 'About me',
    'userProfile.generalInformation': 'General information',
    'userProfile.signature': 'Signature',
    'userProfile.signatureWithColon': 'Signature:',
    'userProfile.licenseNumber': 'License number',
    'userProfile.phoneNumber': 'Phone number',
    'userProfile.freeVisits': 'Free visits',
    'userProfile.clinic': 'Clinic',
    'userProfile.myRevenue': 'My revenue',
    'userProfile.revenue': 'Revenue',
    'userProfile.platformCommission': 'Platform commission',
    'userProfile.platformCommissionRate': 'Platform commission rate',
    'userProfile.virtualClinicCommissionRate': 'Virtual clinic commission rate',
    'userProfile.clinicSpeciality': 'Clinic specialities',
    'userProfile.status': 'Status',
    'userProfile.pricePerSession': 'Price per session',
    'userProfile.pleaseAddClinicSpeciality': 'Please, add a clinic speciality to consult patients',
    'userProfile.PleaseProvideYourValidPhoneNumber': 'Please, provide your valid phone number so that we can contact you. We will not show your phone number to the patients',
    'userProfile.recurringAvailability': 'Recurring availability',
    'userProfile.table.day': 'Day',
    'userProfile.table.workingHours': 'Working hours',
    'userProfile.profileLink': 'Profile link:',
    'userProfile.copyLink': 'Copy link',

    'calculator.title': 'Calculator',
    'calculator.clinixGets': 'Clinix gets',
    'calculator.virtualClinicGets': 'Virtual clinic gets',
    'calculator.youGet': 'You get',
    'calculator.doctorGet': 'Doctor gets',

    'revenue.title': 'Revenue',
    'revenue.totalRevenue': 'Total revenue',
    'revenue.extraStats': 'Extra stats',
    'revenue.statistics': 'Statistics',
    'revenue.totalEarned': 'Total Earned',
    'revenue.totalAppointments': 'Total Appointments',
    'revenue.totalHours': 'Total Hours',
    'revenue.doctorRevenue': 'Doctor revenue',
    'revenue.doctorsRevenue': 'Doctors Revenue',
    'revenue.platformRevenue': 'Platform Revenue',
    'revenue.totalConsultationsRevenue': 'Total Consultations Revenue',
    'revenue.virtualClinicRevenue': 'Virtual Clinic Revenue',
    'revenue.clinics': 'Clinics',
    'revenue.appointments': 'Appointments',
    'revenue.appointmentDetails': 'Appointment details',
    'revenue.speciality': 'Speciality',
    'revenue.price': 'Price',
    'revenue.dateAndTime': 'Date & Time',
    'revenue.doctor': 'Doctor',
    'revenue.completed': 'Completed',
    'revenue.revenueAppointments.empty': 'There are no completed appointments yet.',
    'revenue.extraStats.vouchers': 'Vouchers',
    'revenue.extraStats.vouchersSold': 'Vouchers Sold',
    'revenue.extraStats.vouchersRevenue': 'Vouchers Revenue',
    'revenue.extraStats.pagopar': 'Pagopar',
    'revenue.extraStats.pagoparTransactions': 'Pagopar Transactions',
    'revenue.extraStats.pagoparRevenue': 'Pagopar Revenue',
    'revenue.extraStats.pagoparCcTransactions': 'Pagopar CC Transactions',
    'revenue.extraStats.pagoparCcRevenue': 'Pagopar CC Revenue',
    'revenue.extraStats.total': 'Total',
    'revenue.extraStats.totalTransactionsRevenue': 'Total Transactions Revenue',
    'revenue.selectDoctor': 'Select doctor',

    'revenue.independentDoctor.title': 'Independent doctors revenue',
    'revenue.virtualClinics.title': 'Virtual clinics revenue',
    'revenue.virtualClinics.clinic.title': 'Virtual clinics revenue - {clinicName}',
    'revenue.virtualClinics.clinic.backLink': 'List of clinics',

    'clinicSpecialities.title': 'Clinic Specialities',
    'clinicSpecialities.empty': 'There are no specialities yet.',
    'clinicSpecialities.table.specialities': 'Speciality',
    'clinicSpecialities.table.doctor': 'Doctor',
    'clinicSpecialities.table.status': 'Status',
    'clinicSpecialities.addSpeciality': 'Add speciality',
    'clinicSpecialities.addSpeciality.modal.body': 'You can add only two clinic specialities that match your qualification.',
    'clinicSpecialities.activate.modal.title': 'Activate speciality',
    'clinicSpecialities.activate.modal.body': 'Are you sure you want to activate {name}?',
    'clinicSpecialities.deactivate.modal.title': 'Deactivate speciality',
    'clinicSpecialities.deactivate.modal.body': 'Are you sure you want to deactivate {name}?',
    'clinicSpecialities.deactivate.modal.activeBookings': 'Active appointments will remain but patients will not be able to book appointments for this clinic speciality any more.',
    'clinicSpecialities.remove.modal.body': 'Are you sure you want to remove the {name}?',
    'clinicSpecialities.remove.modal.activeBookings': 'Active appointments will remain but patients will not be able to book this clinic speciality.',
    'clinicSpecialities.remove.modal.title': 'Remove speciality',
    'clinicSpecialities.selectedCount': '{count, number} {count, plural, one {speciality is} other {specialities are}} selected',
    'clinicSpecialities.bulkActivate.modal.title': 'Activate specialities',
    'clinicSpecialities.bulkActivate.modal.body.start': 'Are you sure you want to activate ',
    'clinicSpecialities.bulkActivate.modal.body.boldText': '{count, number} {count, plural, one {clinic speciality} other {clinic specialities}}',
    'clinicSpecialities.bulkActivate.modal.body.final': '?',
    'clinicSpecialities.bulkDeactivate.modal.title': 'Deactivate specialities',
    'clinicSpecialities.bulkDeactivate.modal.body.start': 'Are you sure you want to deactivate ',
    'clinicSpecialities.bulkDeactivate.modal.body.boldText': '{count, number} {count, plural, one {clinic speciality} other {clinic specialities}}',
    'clinicSpecialities.bulkDeactivate.modal.body.final': '?',
    'clinicSpecialities.bulkDeactivate.modal.activeBookings': 'Active appointments will remain but patients will not be able to book appointments for these clinic specialities.',
    'clinicSpecialities.bulkRemove.modal.title': 'Remove specialities',
    'clinicSpecialities.bulkRemove.modal.body.start': 'Are you sure you want to remove ',
    'clinicSpecialities.bulkRemove.modal.body.boldText': '{count, number} {count, plural, one {clinic speciality} other {clinic specialities}}',
    'clinicSpecialities.bulkRemove.modal.body.final': '?',
    'clinicSpecialities.bulkRemove.modal.allChildSpecialities': 'All child specialities will be also removed.',
    'clinicSpecialities.bulkRemove.modal.activeBookings': 'Active appointments will remain but patients will not be able to book these clinic specialities.',
    'clinicSpecialities.assignDoctors.title': 'Assign doctors',
    'clinicSpecialities.assignDoctors.selectDoctorsToAssignTo': 'Select doctors to assign to:',
    'clinicSpecialities.assignDoctors.selectDoctorsToAssignToOptional': 'Select doctors to assign (optional)',
    'clinicSpecialities.assignDoctors.addDoctorsWithAnotherPrice': 'Add doctors with another price',
    'clinicSpecialities.assignDoctors.allDoctorsAdded': 'All doctors added',
    'clinicSpecialities.tab.details': 'Details',
    'clinicSpecialities.tab.doctors': 'Doctors',

    'clinicSpeciality.listOfSpecialities': 'List of specialities',
    'clinicSpeciality.backToEditSpeciality': 'Back to edit speciality',
    'clinicSpeciality.newClinicSpeciality': 'New clinic speciality',
    'clinicSpeciality.editClinicSpeciality': 'Edit speciality - {name}',
    'clinicSpeciality.details': 'Details',
    'clinicSpeciality.assignDoctors': 'Assign doctors',
    'clinicSpeciality.selectSymptoms': 'Select the symptoms you\'d like to consult patients in',
    'clinicSpeciality.maximumFutureTime': 'Maximum future time',
    'clinicSpeciality.minimumLeadTime': 'Minimum lead time',
    'clinicSpeciality.setupMinimumLeadTime': 'Set up minimum lead time for appointment time',
    'clinicSpeciality.advancedSettings': 'Advanced settings',
    'clinicSpeciality.acceptFreeVisitsToRank': 'Accept free visits to rank higher during the booking. This feature is optional, so it\'s up to you to manage it.',
    'clinicSpeciality.consultationDuration': 'Consultation duration and buffer after',
    'clinicSpeciality.theSumOfConsultationDuration': 'The sum of the consultation duration and the buffer after must be a multiple of 20. (Example: if the consultation lasts 15 minutes, the buffer after has to be 5 minutes which gives a total of 20 minutes)',
    'clinicSpeciality.setTimeForYourself': 'Set time for yourself to prepare before consultation or make necessary notes after it is completed.',
    'clinicSpeciality.setupFutureAppointmentTime': 'Set up maximum future time for appointment time',
    'clinicSpeciality.setupCancellationRule': 'Set up cancellation rule',
    'clinicSpeciality.setupRescheduleRule': 'Set up reschedule rule',
    'clinicSpeciality.specialityStatus': 'Speciality status',
    'clinicSpeciality.activeSpeciality': 'Active speciality will be visible for patients. You can deactive speciality anytime to hide it for patients.',

    'specialities.title': 'Specialities',
    'specialities.addSpeciality': 'Create speciality',
    'specialities.table.speciality': 'Speciality',
    'specialities.table.relateTo': 'Relate to:',
    'specialities.table.status': 'Status',
    'specialities.selectedCount': '{count, number} {count, plural, one {speciality is} other {specialities are}} selected',
    'specialities.empty': 'There are no specialities yet.',

    'specialities.removeModal.title': 'Remove speciality',
    'specialities.removeModal.body': 'Are you sure you want to remove {name}?',
    'specialities.bulkRemoveModal.title': 'Remove specialities',
    'specialities.bulkRemoveModal.body.start': 'Are you sure you want to remove ',
    'specialities.bulkRemoveModal.body.end': '{count, number} {count, plural, one {speciality} other {specialities}}?',

    'specialities.activateModal.title': 'Activate speciality',
    'specialities.activateModal.body': 'Are you sure you want to activate the {name}?',
    'specialities.bulkActivateModal.title': 'Activate specialities',
    'specialities.bulkActivateModal.body.start': 'Are you sure you want to activate ',
    'specialities.bulkActivateModal.body.end': '{count, number} {count, plural, one {speciality} other {specialities}}?',

    'specialities.deactivateModal.title': 'Deactivate speciality',
    'specialities.deactivateModal.body': 'Are you sure you want to deactivate the {name}?',
    'specialities.bulkDeactivateModal.title': 'Deactivate specialities',
    'specialities.bulkDeactivateModal.body.start': 'Are you sure you want to deactivate ',
    'specialities.bulkDeactivateModal.body.end': '{count, number} {count, plural, one {speciality} other {specialities}}?',
    'specialities.modal.body.activeAppointments': 'Active appointments will remain but patients will no longer be able to book this speciality.',

    'speciality.listOfSpecialities': 'List of specialities',
    'speciality.editSpeciality': 'Edit speciality - {name}',
    'speciality.newSpeciality': 'New speciality',
    'speciality.details': 'Details',
    'speciality.specialityStatus': 'Speciality status',
    'speciality.activeSpeciality': 'Active speciality will be visible for patients. You can deactive speciality anytime to hide it for patients.',
    'speciality.symptoms': 'Symptoms',
    'speciality.childrenSpecialty': 'Children specialty',
    'speciality.childrenSpecialtyDescription': 'The active specialty will be visible first in the list for patients looking for a doctor for a child. You can deactivate specialty anytime to hide it for patients.',
    'speciality.allSpecialitiesAdded': 'All specialities added',

    'calendar.sunday': 'Sunday',
    'calendar.monday': 'Monday',
    'calendar.tuesday': 'Tuesday',
    'calendar.wednesday': 'Wednesday',
    'calendar.thursday': 'Thursday',
    'calendar.friday': 'Friday',
    'calendar.saturday': 'Saturday',
    'calendar.selectDateRange': 'Select date range',
    'calendar.createdAt': 'Created at:',
    'calendar.usedAt': 'Used at:',
    'calendar.shortDayNames.sunday': 'Sun',
    'calendar.shortDayNames.monday': 'Mon',
    'calendar.shortDayNames.tuesday': 'Tue',
    'calendar.shortDayNames.wednesday': 'Wed',
    'calendar.shortDayNames.thursday': 'Thu',
    'calendar.shortDayNames.friday': 'Fri',
    'calendar.shortDayNames.saturday': 'Sat',

    'weekPicker.week': 'Week',
    'weekPicker.currentWeek': 'Current week',

    'recurringAvailability.regularAvailability': 'Regular Availability',
    'recurringAvailability.info.addAvailability': 'Click and drag to add or remove weekly regular availability.',
    'recurringAvailability.info.markAvailability': 'Blue blocks mark your permanent availability for all weeks.',
    'recurringAvailability.info.changeAvailability': 'To change your availability for specific days only, you can use Date-specific availability.',

    'dateAvailability.dateSpecificAvailability': 'Date-Specific Availability',
    'dateAvailability.info.addException': 'Date-specific availability helps to add exceptions in your regular schedule.',
    'dateAvailability.info.addSpecificTime': 'You can add specific time or make changes in your regular availability.',
    'dateAvailability.info.changeSchedule': 'To change your regular schedule on the regular availability screen.',

    'appointment.filters.statuses': '{count, plural, one {Status: {value}} other {Status}}',
    'appointment.filters.statuses.scheduled': 'Scheduled',
    'appointment.filters.statuses.cancelled': 'Cancelled',
    'appointment.filters.statuses.rescheduled': 'Rescheduled',
    'appointment.filters.statuses.completed': 'Completed',
    'appointment.filters.statuses.waiting_for_note': 'Add note',
    'appointment.filters.statuses.in_progress': 'In progress',
    'appointment.filters.statuses.all': 'All',
    'appointment.button.showDetails': 'Show details',

    'symptomDetails.title': 'Symptom details',
    'symptomDetails.tab.questionnaire': 'Questionnaire',
    'symptomDetails.tab.files': 'Files',
    'symptomDetails.commentForTheDoctor': 'Comment for the doctor:',

    'note.addNote': 'Add note',
    'note.completeNote': 'Complete note',
    'note.editNote': 'Edit note',
    'note.editNoteWithTime': 'Edit note {time}',
    'note.newNote': 'New note',
    'note.viewNote': 'View note',
    'note.addSecondaryDiagnose': 'Add secondary diagnose',
    'note.addMedicine': 'Add medicine',
    'note.addAuxiliaryStudy': 'Add auxiliary study',
    'note.tab.general': 'General',
    'note.tab.plan': 'Plan',
    'note.tab.prescription': 'Prescription',
    'note.tab.auxiliaryStudies': 'Auxiliary studies',
    'note.tab.secretNote': 'Secret note',
    'note.tab.sickNote': 'Sick note',
    'note.attention.title': 'Attention!',
    'note.attention.useFollowingCodes': 'Please, use the following codes if you were unable to provide a quality consultation and you can’t add a full note due to a problem on your or platform’s side:',
    'note.attention.refundCost': 'The application of these codes will cancel the appointment and refund the cost to the patient.',
    'note.attention.applyingCodes': 'If the problem was on the patient’s side, consider applying one of these codes:',
    'note.attention.notApplicable': 'If you apply one of these codes, all the required fields will be automatically filled with ‘Not applicable’, but you’ll be able to edit any of them.',
    'note.commonDiagnoses': 'Common diagnoses for:',
    'note.diagnose': 'Diagnose',
    'note.topDiagnoses': 'Top diagnoses',
    'note.modal.chiefComplaint': 'Chief complaint:',
    'note.modal.subjective': 'Subjective:',
    'note.modal.objective': 'Objective:',
    'note.modal.assessment': 'Assessment:',
    'note.modal.primaryDiagnose': 'Primary diagnose:',
    'note.modal.secondaryDiagnose': 'Secondary diagnose {count}:',
    'note.modal.secretNote': 'Secret note',
    'note.modal.plan': 'Plan',
    'note.modal.sickNote': 'Sick note',
    'note.modal.patient': 'Patient:',
    'note.modal.date': 'Date:',
    'note.modal.patientCitizenId': 'ID:',
    'note.modal.doctor': 'Doctor',
    'note.modal.label.doctor': 'Doctor:',
    'note.modal.label.licenseNumber': 'License number:',
    'note.modal.label.signature': 'Signature:',
    'note.modal.showPrescription': 'Show prescription',
    'note.modal.hidePrescription': 'Hide prescription',
    'note.modal.prescription': 'Prescription',
    'note.modal.auxiliaryStudies': 'Auxiliary studies',
    'note.modal.idNumber': 'ID number:',
    'note.modal.drug': 'Drug:',
    'note.modal.commercialName': 'Commercial name:',
    'note.modal.dose': 'Dose:',
    'note.modal.frequency': 'Frequency:',
    'note.modal.quantity': 'Quantity:',
    'note.modal.comment': 'Comment:',
    'note.modal.test': 'Test:',
    'note.modal.headsUp.body': 'If you apply this {code} primary code, the system will cancel the appointment and refund its cost to the patient. Are you sure you want to continue?',

    'filters.patients': 'Patient',
    'filters.specialities': 'Speciality',
    'filters.symptoms': 'Symptom',
    'filters.doctors': 'Doctor',

    'appointments.title': 'Appointments',
    'appointments.status.scheduled': 'Scheduled',
    'appointments.status.rescheduled': 'Rescheduled',
    'appointments.status.cancelled': 'Cancelled',
    'appointments.status.completed': 'Completed',
    'appointments.status.all': 'Completed',
    'appointments.status.waiting_for_note': 'Add note',
    'appointments.status.in_progress': 'In progress',

    'appointments.toolbar.currentDay': 'Current day',
    'appointments.toolbar.currentMonth': 'Current month',
    'appointments.cancellationReason.PatientProfile': 'Cancellation reason (patient)',
    'appointments.cancellationReason.UserProfile': 'Cancellation reason (doctor)',
    'appointments.refunded': 'Refunded',
    'appointments.doctor': 'Doctor',
    'appointments.patient': 'Patient',
    'appointments.appointmentForChild': 'Appointment for child',
    'appointments.viewMeetingPage': 'View consultation page',
    'appointments.cancelAppointment': 'Cancel appointment',
    'appointments.empty': 'There are no appointments yet.',
    'appointments.amount': 'Amount',
    'appointments.viewDetails': 'View details',

    'cancelAppointmentModal.cancelAppointment': 'Cancel appointment',
    'cancelAppointmentModal.reason': 'Cancellation Reason',

    'appointment.consultationStartsIn': 'Consultation starts in',
    'appointment.startTime': 'Start time',
    'appointment.speciality': 'Speciality',
    'appointment.timezone': 'Timezone',
    'appointment.price': 'Price',
    'appointment.perSession': '/ session',
    'appointment.patient': 'Patient',
    'appointment.availableInfo': 'Your video consultation will be available 15 min before booked time',
    'appointment.joinMeeting': 'Join meeting',
    'appointment.started': 'Started',
    'appointment.ended': 'Ended',
    'appointment.cancelled': 'Cancelled',
    'appointment.addToGoogleCalendar': 'Add to Google Calendar',
    'appointment.addToOutlook': 'Add to Outlook / Apple Calendar',

    'patients.title': 'Patients',
    'patients.exportAsCSV': 'Export as CSV',
    'patients.empty': 'There are no patients yet.',
    'patients.empty.newPatients': 'New patients will be added automatically when they book an appointment.',
    'patients.tab.patients': 'Patients',
    'patients.tab.freeVisits': 'Free visits',
    'patients.table.patients': 'Patients',
    'patients.table.upcomingAppointments': 'Upcoming appointments',
    'patients.table.allAppointments': 'All appointments',
    'patients.table.wellness': 'Wellness',
    'patients.table.createdAt': 'Joined at',
    'patients.table.confirmedAt': 'Confirmed at',
    'patients.table.status': 'Status',
    'patients.status.active': 'Active',
    'patients.status.deactivated': 'Deactivated',
    'patients.activateModal.title': 'Activate patient',
    'patients.activateModal.body': 'Are you sure you want to activate {name}?',
    'patients.deactivateModal.title': 'Deactivate patient',
    'patients.banModal.title': 'Ban patient',
    'patients.deactivateModal.body': 'Are you sure you want to deactivate {name}?',
    'patients.banModal.body': 'Are you sure you want to ban {name}? All upcoming appointments that this patient has will be cancelled.',

    'patients.freeVisits.addFreeVisits': 'Add free visits',
    'patients.freeVisits.editFreeVisits': 'Edit free visits',
    'patients.freeVisits.table.patients': 'Patients',
    'patients.freeVisits.table.clinic': 'Clinic',
    'patients.freeVisits.table.freeVisits': 'Free visits',
    'patients.freeVisits.table.status': 'Status',
    'patients.freeVisits.empty': 'There are no free visits yet.',
    'patients.freeVisits.allClinicsAdded': 'All clinics added',
    'patients.freeVisits.allPatientsAdded': 'All patients added',

    'patient.genderAndAge': '{gender}, {age} years old',
    'patient.profile.tab.patientProfile': 'Patient profile',
    'patient.profile.tab.healthProfile': 'Health profile',
    'patient.profile.tab.children': 'Children',
    'patient.profile.label.idNumber': 'ID number: ',
    'patient.profile.label.dateOfBirth': 'Date of birth: ',
    'patient.profile.label.phoneNumber': 'Phone number: ',
    'patient.profile.label.email': 'Email: ',
    'patient.profile.label.city': 'City: ',
    'patient.profile.label.country': 'Country: ',
    'patient.profile.label.height': 'Height: ',
    'patient.profile.label.weight': 'Weight: ',
    'patient.profile.label.chronicIllnesses': 'Chronic Illnesses: ',
    'patient.profile.label.allergies': 'Allergies: ',
    'patient.profile.label.nicotineHabits': 'Nicotine habits: ',
    'patient.profile.label.gender': 'Gender: ',
    'patient.profile.height': '{height} cm',
    'patient.profile.weight': '{weight} kg',
    'patient.profile.smoker': 'Smoker',
    'patient.profile.nonSmoker': 'Non-smoker',
    'patient.tab.upcomingAppointments': 'Upcoming appointments',
    'patient.tab.archivedAppointments': 'Archived appointments',
    'patient.tab.pastAppointments': 'Past appointments',
    'patient.tab.patientChart': 'Patient chart',
    'patient.tab.appointments': 'Appointments',
    'patient.tab.freeVisits': 'Free visits',
    'patient.freeVisits.addFreeVisits': 'Add free visits',
    'patient.freeVisits.table.clinic': 'Clinic',
    'patient.freeVisits.table.freeVisits': 'Free visits',
    'patient.freeVisits.empty': 'There are no free visits yet.',
    'patient.table.symptom': 'Symptom',
    'patient.table.speciality': 'Speciality',
    'patient.table.price': 'Price',
    'patient.table.dateTime': 'Date & Time',
    'patient.table.date': 'Date',
    'patient.table.diagnoses': 'Diagnoses',
    'patient.table.icd10': 'ICD-10',
    'patient.appointments.empty': 'There are no appointments yet.',
    'patient.notes.empty': 'There are no patient charts yet.',
    'patient.backToMeetingPage': 'Back to meeting page',
    'patient.balance.tab.balanceTopUp': 'Top-up transactions',
    'patient.balance.tab.appointmentPayment': 'Appointment Payment',
    'patient.balance.patientBalance': 'Balance - {name}',
    'patient.balance.table.amount': 'Amount',
    'patient.balance.table.date': 'Date',
    'patient.balance.table.source': 'Source',
    'patient.balance.table.status': 'Status',
    'patient.balance.table.id': 'ID',
    'patient.balance.table.appointment': 'Appointment',
    'patient.balance.table.balanceBefore': 'Balance before',
    'patient.balance.table.balanceAfter': 'Balance after',
    'patient.balance.createTransaction': 'Create transaction',
    'patient.balance.topupBalance': 'Top-up balance',
    'patient.balance.subtractBalance': 'Subtract balance',
    'patient.balance.balanceTopup': 'Balance top-up',
    'patient.balance.balanceSubtract': 'Balance subtraction',
    'patient.balance.currentBalance': 'Current balance',
    'patient.balance.balanceTopUp.empty': 'No balance top-up transactions yet',
    'patient.balance.appointmentPayment.empty': 'No appointment payments yet',
    'patient.balance.freeConsultationLeft': 'Free consultation left',
    'patient.balance.topUpWithVoucher': 'Redeem voucher code',
    'patient.balance.topUpWithPagopar': 'Top up balance',
    'patient.balance.tooltip.weAcceptAllMayorPayment': 'We accept all mayor payment methods like credit cards, AquíPago, Tigo Money and many more.',
    'patient.balance.topUpWithPagopar.modal.title': 'Enter top-up amount',
    'patient.balance.topUpWithPagopar.modal.text': 'Please, specify the amount of money you want to add to your account balance.',
    'patient.balance.transactions': 'Transactions',
    'patient.balance.waitProcessingYourPayment': 'Please wait while we are processing your payment',
    'patient.balance.backToMyBalance': 'Back to My Balance',
    'patient.balance.topupBalanceInfo': 'If you wish to get a factura, send an email to {email} and provide razón social, R.U.C. and the name of the account that was topped up.',

    'patientProfile.backLink': 'Back to profile',
    'patientProfile.title': ' - Edit Profile',
    'patientProfile.generalInformation': 'General information',
    'patientProfile.firstName': 'First name',
    'patientProfile.lastName': 'Last name',
    'patientProfile.email': 'Email',

    'doctorProfile.backLink.listOfIndependentDoctors': 'List of independent doctors',
    'doctorProfile.backLink.listOfVirtualClinicDoctors': 'List of virtual clinic doctors',
    'doctorProfile.backLink.listOfDoctors': 'List of doctors',
    'doctorProfile.tab.profile': 'Profile',
    'doctorProfile.tab.account': 'Account',
    'doctorProfile.tab.recurringAvailability': 'Recurring availability',
    'doctorProfile.tab.dateSpecificAvailability': 'Date-specific availability',
    'doctorProfile.tab.clinicSpecialities': 'Clinic specialities',
    'doctorProfile.generalInformation': 'General information',
    'doctorProfile.phoneNumberInfo': 'Please, provide your valid phone number so that we can contact you. We will not show your phone number to the patients',
    'doctorProfile.location': 'Location',
    'doctorProfile.backToView': 'Back to view',
    'doctorProfile.doctorStatus': 'Doctor status',
    'doctorProfile.activeDoctor': 'Active doctors will be visible for patients. You can deactivate doctor anytime to hide it for patients. Deactivated doctors will not have access to the speciality.',
    'doctorProfile.doYouWantToRemove': 'Do you want to remove your profile image? You can add it later.',
    'doctorProfile.doYouWantToRemoveSignature': 'Do you want to delete your signature? You can add it later.',

    'independentDoctors.request.headerTitle': 'Independent doctors requests',
    'independentDoctors.doctors.headerTitle': 'Independent doctors',
    'independentDoctors.doctors.empty': 'There are no doctors yet.',
    'independentDoctors.doctors.table.nameTitle': 'Name',
    'independentDoctors.doctors.table.specialitiesTitle': 'Specialities',
    'independentDoctors.doctors.table.statusTitle': 'Status',
    'independentDoctors.doctors.invitations.errors.alreadyMember': 'Oops! This user is already a member',
    'independentDoctors.doctors.removeInvitations.title': 'Remove independent doctor invitation',
    'independentDoctors.doctors.removeInvitations.subTitle': 'Do you want to remove the invitation for {email}?',
    'independentDoctors.commissions.headerTitle': 'Independent doctors commission',
    'independentDoctors.commissions.empty': 'There are no doctors commission yet.',

    'doctors.headerTitle': 'Doctors',
    'doctors.headerTitle.virtualClinicsDoctors': 'Virtual clinics doctors',
    'doctors.table.title.name': 'Name',
    'doctors.table.title.clinic': 'Clinic',
    'doctors.table.title.specialities': 'Specialities',
    'doctors.table.title.status': 'Status',
    'doctors.empty': 'There are no doctors yet.',
    'doctors.activateModal.title': 'Activate doctor account',
    'doctors.activateModal.body': 'Are you sure you want to activate {name}?',
    'doctors.deactivateModal.title': 'Deactivate doctor account',
    'doctors.deactivateModal.body': 'Are you sure you want to deactivate {name}?',
    'doctors.deactivateModal.warning': 'All the upcoming appointments will be cancelled.',
    'doctors.removeInvitations.title': 'Remove doctor invitation',
    'doctors.removeInvitations.subTitle': 'Do you want to remove the invitation for {email}?',
    'doctors.resetAuthSettings.title': 'Reset authentication settings',
    'doctors.resetAuthSettings.subTitle': 'Are you sure you want to reset the authentication settings for {name}?',
    'doctors.assignClinicSpecialities.modal.title': 'Assign clinic specialities',
    'doctors.assignClinicSpecialities.modal.selectSpecialityToAssignTo': 'Select speciality to assign to:',
    'doctors.assignClinicSpecialities.modal.addSpecialitiesWithAnotherPrice': 'Add specialities with another price',
    'doctors.assignClinicSpecialities.modal.allSpecialitiesAdded': 'All specialities added',
    'doctors.assignClinicSpecialities.modal.maximumAvailableSpecialitiesForDoctor': 'The specialities you\'re trying to assign to this doctor already assigned to maximum available specialities the doctor (2 (two) speciality)',

    'symptoms.title': 'Symptoms',
    'symptoms.addSymptom': 'Add symptom',
    'symptoms.selectedCount': '{count, number} {count, plural, one {symptom is} other {symptoms are}} selected',
    'symptoms.table.symptom': 'Symptom',
    'symptoms.table.speciality': 'Speciality',
    'symptoms.table.status': 'Status',
    'symptoms.empty': 'There are no symptoms yet.',

    'symptoms.removeModal.title': 'Remove symptom',
    'symptoms.removeModal.body': 'Are you sure you want to remove {name}?',
    'symptoms.bulkRemoveModal.title': 'Remove symptoms',
    'symptoms.bulkRemoveModal.body.start': 'Are you sure you want to remove ',
    'symptoms.bulkRemoveModal.body.end': '{count, number} {count, plural, one {symptom} other {symptoms}}?',

    'symptoms.activateModal.title': 'Activate symptom',
    'symptoms.activateModal.body': 'Are you sure you want to activate the {name}?',
    'symptoms.bulkActivateModal.title': 'Activate symptoms',
    'symptoms.bulkActivateModal.body.start': 'Are you sure you want to activate ',
    'symptoms.bulkActivateModal.body.end': '{count, number} {count, plural, one {symptom} other {symptoms}}?',

    'symptoms.deactivateModal.title': 'Deactivate symptom',
    'symptoms.deactivateModal.body': 'Are you sure you want to deactivate the {name}?',
    'symptoms.bulkDeactivateModal.title': 'Deactivate symptoms',
    'symptoms.bulkDeactivateModal.body.start': 'Are you sure you want to deactivate ',
    'symptoms.bulkDeactivateModal.body.end': '{count, number} {count, plural, one {symptom} other {symptoms}}?',

    'symptom.create.newSymptom': 'New symptom',
    'symptom.create.details': 'Details',
    'symptom.create.upload': 'Upload image',
    'symptom.create.doYouWantToRemove': 'Do you want to remove your symptom image? You can add it later.',
    'symptom.create.back': 'List of symptoms',
    'symptom.create.backToSymptomDetails': 'Back to Symptom details',
    'symptom.create.questionnaire': 'Questionnaire',
    'symptom.create.questionnaire.addQuestion': 'Add question',
    'symptom.create.questionnaire.required': 'Required',
    'symptom.create.questionnaire.optionIndex': 'Option {index}',
    'symptom.create.questionnaire.mustContainQuestions': 'Questionnaire must contain at least 3 questions.',
    'symptom.create.addOption': 'Add option',
    'symptom.create.pleaseAddQuestion': 'Please, add question',
    'symptom.create.label.radio': 'Radio group',
    'symptom.create.label.checkbox': 'Checkboxes',
    'symptom.create.label.yesNo': '"Yes" / "No" choice',
    'symptom.create.questionnaire.removeModal.title': 'Remove question',
    'symptom.create.questionnaire.removeModal.body': 'Are you sure you want to remove this question?',
    'symptom.create.questionnaire.errorCountOfQuestions': 'Add at least 3 questions to the questionnaire',
    'symptom.create.cancelModal.title': 'Cancel new symptom',
    'symptom.create.cancelModal.body': 'Are you sure you want to cancel creating a new symptom? All data will be lost.',
    'symptom.create.assignSpecialities': 'Assign Specialities',
    'symptom.create.backToQuestionnaire': 'Back to Symptom questionnaire',
    'symptom.create.assignSpeciality.errorAssociatedWithSpeciality': 'Must be associated at least with 1 speciality',
    'symptom.create.assignSpeciality.selectSpecialitiesAssign': 'Select specialities to assign',
    'symptom.tab.details': 'Details',
    'symptom.tab.questionnaire': 'Questionnaire',
    'symptom.tab.specialities': 'Specialities',
    'symptom.editSymptom': 'Edit symptom - {name}',
    'symptom.symptomStatus': 'Symptom status',
    'symptom.activeSymptom': 'Active symptom will be visible for patients. You can deactive symptom anytime to hide it for patients.',
    'symptom.optionGroup': 'Symptom',
    'symptom.allSymptomsAdded': 'All symptoms added',

    'tutorial.skip': 'Skip',
    'tutorial.finish': 'Finish tutorial',
    'tutorial.helpCenter': 'Help Center',
    'tutorial.availability.title': 'Complete your working hours',
    'tutorial.availability.description': 'Click and drag pointer to highlight a range of time slots when you are going to work. This time will be available to patients for new bookings.{breakingLine}You can also add or remove some specific slots on the {link} page.',
    'tutorial.availability.this': 'Date-Specific Availability',
    'tutorial.clinicSpecialities.title': 'Add a new speciality',
    'tutorial.clinicSpecialities.description': 'Add new specialities and manage them {link}.',
    'tutorial.clinicSpecialities.this': 'on this page',
    'tutorial.inviteVirtualClinicDoctor.title': 'Invite Virtual Clinic Doctors',
    'tutorial.inviteVirtualClinicDoctor.description': 'Invite and assign doctors who will provide consultations in your clinic {link}.',
    'tutorial.inviteVirtualClinicDoctor.this': 'on this page',
    'tutorial.congratulations.title': 'Congratulations!',
    'tutorial.congratulations.description': 'Now you are ready to gain new patients.',

    'chat.title': 'Chat',
    'chat.today': 'Today',
    'chat.yesterday': 'Yesterday',
    'chat.backToAppointments': 'Back to appointments',
    'chat.inputPlaceholder': 'Type your message here',
    'chat.editingMessage': 'Editing message',
    'chat.deleteMessage.modal.title': 'Delete message',
    'chat.removeMessage.modal.body': 'Are you sure you want to delete this message for you and {name}?',
    'chat.tag.edited': 'Edited',
    'chat.notImplemented': 'Not implemented',
    'chat.attachments.tab.files': 'Files',
    'chat.attachments.tab.recordings': 'Recordings',
    'chat.attachments.noFilesYet': 'There are no files yet.',
    'chat.attachments.noRecordingsYet': 'There are no recordings yet.',
    'chat.attachments.allFiles': 'All Files ({count})',
    'chat.attachments.remove.modal.title': 'Delete file',
    'chat.attachments.remove.modal.body': 'Are you sure you want to delete this file?',
    'chat.attachments.remove.modal.text': 'This file will no longer be available',
    'chat.fileUploadError': 'File upload error',
    'chat.fileSizeCantBeBigger': 'File size can\'t be bigger than {size}',
    'chat.selectOtherFile': 'Select other file',
    'chat.rescheduleRequest.selectNewTime': 'Select new time',
    'chat.rescheduleRequest.declineRequest': 'Decline request',
    'chat.declineRescheduleRequest.modal.title': 'Decline reschedule request',
    'chat.declineRescheduleRequest.modal.body': 'Are you sure you want to decline reschedule request?',
    'chat.declineRescheduleRequest.modal.submit': 'Decline request',
    'chat.rescheduleRequest.status.declined': 'Declined',
    'chat.rescheduleRequest.status.accepted': 'Accepted',
    'chat.enable.modal.title': 'Enable chat',
    'chat.enable.modal.body': 'Are you sure you want to enable this chat? Patient will be able to send messages & files.',
    'chat.enable.modal.button': 'Enable chat',
    'chat.disable.modal.title': 'Disable chat',
    'chat.disable.modal.body': 'Are you sure you want to disable this chat? You can enable it later.',
    'chat.disable.modal.label': 'Message for patient (optional)',
    'chat.disable.modal.button': 'Disable chat',
    'chat.patient.disable.modal.title': 'Patient\'s chat is disabled',
    'chat.patient.disable.modal.body.p1': 'Ability of chatting has been disabled for the patient. The patient will not be able to reply you.',
    'chat.patient.disable.modal.body.p2': 'Do you want to continue sending message?',
    'chat.patient.disable.modal.button.justSendMessage': 'Just send message',
    'chat.patient.disable.modal.button.sendMessageAndEnableChat': 'Send message and enable chat',
    'chat.newMessages': '{count} new {count, plural, one {message} other {messages}}',
    'chat.since': 'since {time} on {date}th',
    'chat.markAsRead': 'Mark as read',
    'chat.recordIsReady.button': 'Watch recording',
    'chat.shareWithExpert.button': 'Share with Expert',
    'chat.shareWithPatient.button': 'Share with patient',

    'chat.message.appointment.note.title': 'Complete appointment',
    'chat.message.appointment.note.text.p1': 'Add a note to complete the appointment.',
    'chat.message.appointment.note.text.p2': 'Please, use the following codes if you were unable to provide a quality consultation and you can’t add a full note due to a problem on your or platform’s side:',
    'chat.message.appointment.note.list1.item1': 'Z53.9 Procedure and treatment not performed for unspecified reason',
    'chat.message.appointment.note.list1.item2': 'Z53.09 Procedure and treatment not carried out because of other contraindication',
    'chat.message.appointment.note.list1.item3': 'Z53.2 Procedure and treatment not carried out because of patient\'s decision for other and unspecified reasons',
    'chat.message.appointment.note.text.p3': 'The application of these codes will cancel the appointment and refund the cost to the patient.',
    'chat.message.appointment.note.text.p4': 'If the problem was on the patient’s side, consider applying one of these codes:',
    'chat.message.appointment.note.list2.item1': 'Z53.8 Procedure and treatment not carried out for other reasons',
    'chat.message.appointment.note.list2.item2': 'Z53.21 Procedure and treatment not carried out because the patient leaves before being seen by the healthcare professional',
    'chat.message.appointment.note.text.p5': 'Please, make sure to add the note with a proper code within 24 hours, otherwise the appointment will be cancelled automatically.',

    'uploadImage.changeImage': 'Change Image',
    'uploadImage.editImage': 'Edit image',
    'uploadImage.selectImage': 'Select other image',
    'uploadImage.uploadError': 'Image upload error',
    'uploadImage.formatSizeError': 'Please use JPG or PNG image smaller than 10MB',
    'uploadImage.notice': 'JPG or PNG. Max size of 10MB',
    'uploadImage.deleteImage': 'Delete image',
    'uploadImage.uploadLogo': 'Upload logo',
    'uploadImage.doYouWantToRemove': 'Do you want to remove company logo? You can add it later.',
    'uploadImage.doYouWantToRemoveClinicLogo': 'Do you want to remove the logo of the virtual clinic {clinicName}?',
    'uploadImage.uploadPhoto': 'Upload photo',
    'uploadImage.doYouWantToRemoveProfileImage': 'Do you want to remove the profile image of {userName}? You can add it later.',
    'uploadImage.addSignature': 'Add signature',
    'uploadImage.uploadSignature': 'Upload signature',
    'uploadImage.createSignature': 'Create signature',
    'uploadImage.doYouWantToRemoveProfileSignature': 'Do you want to remove the profile signature of {userName}? You can add it later.',

    'vouchers.title': 'Vouchers',
    'vouchers.tab.inactive': 'Inactive',
    'vouchers.tab.used': 'Used',
    'vouchers.tab.batches': 'Batches',
    'vouchers.generate': 'Generate',
    'vouchers.generateVouchers': 'Generate vouchers',
    'vouchers.editBatch': 'Edit batch',
    'vouchers.selectedCount': '{count, number} {count, plural, one {voucher is} other {vouchers are}} selected',
    'vouchers.status.success': 'Success',
    'vouchers.status.failure': 'Failed',
    'vouchers.status.pending': 'Pending',
    'vouchers.table.voucherId': 'Voucher ID',
    'vouchers.table.createdAt': 'Created at',
    'vouchers.table.usedAt': 'Used at',
    'vouchers.table.batch': 'Batch',
    'vouchers.table.status': 'Status',
    'vouchers.table.usedBy': 'Used by',
    'vouchers.table.value': 'Value',
    'vouchers.table.usedByPatient': 'Used by patient',
    'vouchers.table.voucherCode': 'Voucher code',
    'vouchers.table.batchName': 'Batch name',
    'vouchers.table.inactive': 'Inactive',
    'vouchers.table.used': 'Used',
    'vouchers.table.total': 'Total',
    'vouchers.empty': 'There are no vouchers yet.',
    'vouchers.batchesEmpty': 'There are no batches yet.',
    'vouchers.typeVoucherId': 'Type voucher ID',
    'vouchers.typeBatchName': 'Type batch name',
    'vouchers.listOfVouchers': 'List of vouchers',
    'vouchers.listOfBatches': 'List of batches',
    'vouchers.comment': 'Comment: ',

    'vouchers.rowsCount.title': '{count, number} {count, plural, one {voucher} other {vouchers}}',
    'vouchers.rowsCount.batchesTitle': '{count, number} {count, plural, one {batch} other {batches}}',

    'vouchers.bulkRemoveModal.title': 'Remove vouchers',
    'vouchers.bulkRemoveModal.body.start': 'Are you sure you want to remove ',
    'vouchers.bulkRemoveModal.body.end': '{count, number} {count, plural, one {voucher} other {vouchers}}?',

    'vouchers.enterCodeFromYourVoucher': 'Enter code from your voucher',
    'vouchers.button.topUp': 'Top up',
    'voucher.congrats': 'Congrats!',
    'voucher.congrats.description': 'You’ve activated a {amount} voucher, you will get this amount on your account.',
    'voucher.failure': 'Oops...something went wrong!',
    'voucher.failure.description': 'The voucher is no longer valid.',

    'notificationPanel.title': 'Notifications',
    'notificationPanel.markAsRead': 'Mark as read',
    'notificationPanel.markAsUnread': 'Mark as unread',
    'notificationPanel.empty': 'There are no notifications yet.',
    'notificationPanel.empty.alt': 'No notifications',
    'notificationPanel.notificationRemoved': 'Notification removed',
    'notificationPanel.undo': 'Undo',
    'notificationPanel.dateTime': '{date} at {time}',
    'notificationPanel.hours': '{count, number} {count, plural, one {hour} other {hours}}',
    'notificationPanel.minutes': '{count, number} {count, plural, one {minute} other {minutes}}',
    'notificationPanel.appointment.newAppointmentForDoctor': 'You have a new appointment for {symptomName} {specialityName} on {startTime}.',
    'notificationPanel.appointment.patientCancelAppointmentForDoctor': '{patientFullName} cancelled appointment for {symptomName} {specialityName} on {startTime}.',
    'notificationPanel.appointment.patientCancelAppointmentWithoutRefund': '{patientFullName} cancelled appointment for {symptomName} {specialityName} on {startTime}. You will get paid for this appointment as the cancellation time has passed.',
    'notificationPanel.appointment.doctorMissedAppointment': 'You missed the appointment for {symptomName} {specialityName} with {patientFullName} at {startTime}. The cost has been refunded to the patient.',
    'notificationPanel.appointment.doctorCancelledAppointment': 'You cancelled the appointment for {symptomName} {specialityName} with {patientFullName} at {startTime}. The cost has been refunded to the patient.',
    'notificationPanel.appointment.cancelledAppointmentDueToPatientBan': 'Your appointment for {symptomName} {specialityName} has been cancelled as the patient {patientFullName} was banned.',
    'notificationPanel.appointment.completedAppointmentDueToPatientBan': 'Your appointment for {symptomName} {specialityName} has been completed as the patient {patientFullName} was banned. You will get fully paid as the appointment didn\'t match your cancellation rule.',
    'notificationPanel.appointment.patientRescheduledAppointment': '{patientFullName} has made a reschedule for the appointment {symptomName} {specialityName} from {startTime}.',
    'notificationPanel.appointment.createNoteAfterAppointment': 'Fill out a note after the appointment for {symptomName} {specialityName} with {patientFullName}.',
    'notificationPanel.appointment.doctorFailedToAddNote': 'You didn\'t add a note to the appointment for {symptomName} {specialityName} with {patientFullName} at {startTime} - {endTime}. The appointment has been cancelled and its cost has been refunded to the patient.',
    'notificationPanel.reminder.reminderForDoctor': 'Your appointment for {symptomName} {specialityName} with {patientFullName} starts in {time}.',
    'notificationPanel.communication.patientCreateMessage': 'You have a new message from the patient in the appointment for {symptomName} {specialityName} at {startTime}.',
    'notificationPanel.communication.patientAssignAttachment': 'You have a new file uploaded by the patient in the appointment for {symptomName} {specialityName} at {startTime}.',
    'notificationPanel.system.specialityAssigned': '{specialityName} has been assigned to you.',
    'notificationPanel.system.specialityUnassigned': '{specialityName} has been unassigned from you.',
    'notificationPanel.system.specialityRemoved': '{specialityName} has been unassigned from you.',
    'notificationPanel.system.clinicSpecialityRemoved': '{specialityName} has been removed from your virtual clinic.',
    'notificationPanel.system.clinicSpecialityDeactivated': '{specialityName} has been deactivated in your virtual clinic.',
    'notificationPanel.system.clinicSpecialityActivated': '{specialityName} has been activated in your virtual clinic.',
    'notificationPanel.system.specialityDeactivatedPlatform': '{specialityName} has been deactivated. Your clinic speciality {specialityName} is no longer available for future appointments.',
    'notificationPanel.system.specialityActivatedPlatform': '{specialityName} has been activated. You can add it to your clinic speciality if it matches your quallification.',
    'notificationPanel.system.specialityAddedPlatform': '{specialityName} has been activated. You can add it to your clinic speciality if it matches your qualification.',
    'notificationPanel.system.specialityDeletedPlatform': '{specialityName} has been deleted from the platform. You can no longer use it for the clinic specialities.',
    'notificationPanel.notificationMessage.notImplemented': 'Not implemented',

    'videoConference.joinConsultation': 'Join consultation',
    'videoConference.joinError': 'Your computer clock is wrong',
    'videoConference.settings': 'Settings',
    'videoConference.waitingParticipant': 'Waiting for the participant to join',
    'videoConference.settingsModal.camera': 'Camera',
    'videoConference.settingsModal.microphone': 'Microphone',
    'videoConference.settingsModal.speakers': 'Speakers',
    'videoConference.settingsModal.defaultSpeaker': 'Default speaker',
    'videoConference.leaveConsultation.title': 'Leave video consultation',
    'videoConference.leaveConsultation.body': 'Are you sure you want to leave this consultation?',
    'videoConference.lostConnection': 'Internet connection has been lost. Trying to connect…',
    'videoConference.turnOnMicrophoneToSpeak': 'Turn on microphone to speak',
    'videoConference.modal.exit.title': 'Video conference ended.',
    'videoConference.modal.exit.body': 'The video conference has been terminated automatically as the patient left the meeting.',

    'download.pleaseOpenLinkOnPhoneTabletContinue': 'Please, open this link on your phone or tablet, to continue',
    'download.fileName.revenueVirtualClinicAppointments': 'Appointments for {userProfileId, select, 0 {virtual clinic {clinicName}} other {{userName} {licenseNumber, select, 0 {} other {CI {licenseNumber}}} at virtual clinic {clinicName}}} for {startDate} - {endDate}.csv',
    'download.fileName.revenuesForVirtualClinics': 'Revenues for virtual clinics for {startDate} - {endDate}.csv',
    'download.fileName.revenuesForVirtualClinic': 'Revenues for virtual clinic {clinicName} for {startDate} - {endDate}.csv',
    'download.fileName.revenueForIndependentDoctors': 'Revenues for independent doctors for {startDate} - {endDate}.csv',
    'download.fileName.revenueAppointmentsForIndividualDoctors': 'Appointments for individual doctors for {startDate} - {endDate}.csv',
    'download.fileName.revenueAppointmentsForIndividualDoctor': 'Appointments for individual doctor {userName} {licenseNumber, select, 0 {} other {CI {licenseNumber}}} for {startDate} - {endDate}.csv',
    'download.fileName.appointmentsForPeriod': 'Appointments for {startDate} - {endDate}.csv',

    'virtualClinics.clinics.title': 'Virtual clinics',
    'virtualClinics.clinics.empty': 'There are no virtual clinics yet.',
    'virtualClinics.clinics.table.nameTitle': 'Name',
    'virtualClinics.clinics.table.freeVisits': 'Free visits',
    'virtualClinics.clinics.table.doctorsTitle': 'Doctors',
    'virtualClinics.clinics.table.adminTitle': 'Admin',
    'virtualClinics.clinics.table.statusTitle': 'Status',
    'virtualClinics.clinics.removeInvitations.modal.title': 'Cancel invitation?',
    'virtualClinics.clinics.removeInvitations.modal.body': 'Are you sure you want to cancel invitation?',
    'virtualClinics.clinics.deactivateModal.title': 'Deactivate virtual clinic',
    'virtualClinics.clinics.deactivateModal.body': 'Are you sure you want to deactivate {clinicName} clinic? All active doctors from this clinic will be deactivated and their appointments will be canceled.',
    'virtualClinics.clinics.deactivateModal.warning': 'Unexpected bad things will happen if you don’t read this!',
    'virtualClinics.clinics.deactivateModal.label': 'Type clinic name to confirm deactivating',
    'virtualClinics.clinics.activateModal.title': 'Activate virtual clinic',
    'virtualClinics.clinics.activateModal.body': 'Are you sure you want to activate {clinicName}?',
    'virtualClinics.clinics.resetAuthSettings.title': 'Reset authentication settings',
    'virtualClinics.clinics.resetAuthSettings.body': 'Are you sure you want to reset the authentication settings for {clinicName}?',
    'virtualClinics.clinics.profile.backLink': 'List of clinics',
    'virtualClinics.clinics.profile.backToView': 'Back to view',
    'virtualClinics.clinics.tab.profile': 'Profile',
    'virtualClinics.clinics.tab.account': 'Account',
    'virtualClinics.clinics.tab.freeVisits': 'Free visits',
    'virtualClinics.clinics.profile.virtualClinicStatus': 'Virtual clinic status',
    'virtualClinics.clinics.profile.virtualClinicActive': 'Active virtual clinics will be visible for patients. You can deactivate virtual clinic anytime to hide it for patients.',
    'virtualClinics.clinics.profile.freeVisits': 'Free visits',
    'virtualClinics.clinics.profile.freeVisitsActive': 'Active free visits will be visible for clinic admins.',

    'virtualClinics.requests.headerTitle': 'Virtual clinic doctors requests',

    'virtualClinics.commissions.headerTitle': 'Virtual clinics commission',
    'virtualClinics.commissions.empty': 'There are no virtual clinics commission yet.',

    'virtualClinicDoctors.commissions.headerTitle': 'Virtual clinic doctors commission',
    'virtualClinicDoctors.commissions.empty': 'There are no virtual clinic doctors commission yet.',

    'joinRequests.table.nameTitle': 'Name',
    'joinRequests.table.dateTitle': 'Date',
    'joinRequests.table.statusTitle': 'Status',
    'joinRequests.table.clinicTitle': 'Clinic',
    'joinRequests.empty': 'There are no requests yet.',
    'joinRequests.acceptModal.title': 'Accept doctor request',
    'joinRequests.acceptModal.body': 'Are you sure you want to accept request from {name}?',
    'joinRequests.rejectModal.title': 'Reject doctor request',
    'joinRequests.rejectModal.body': 'Do you want to reject request from {name}?',
    'joinRequests.removeModal.title': 'Remove doctor request',
    'joinRequests.removeModal.body': 'Do you want to remove request from {name}?',
    'joinRequests.previewModal.title': 'Doctor details',

    'commissions.table.name': 'Name',
    'commissions.table.commission': 'Commission',
    'commissions.table.minimum': 'Minimum',
    'commissions.table.maximum': 'Maximum',
    'commissions.table.status': 'Status',
    'commissions.title.setCommission': 'Set commission',

    'invitations.modal.title': 'Invite {roleName}',
    'invitations.modal.subTitle': 'Enter their email addresses, set roles and we will send them invitations',
    'invitations.modal.duplicateEmails': 'You\'ve entered one email several times',
    'invitations.modal.inviteOneMoreUsers': 'Invite one more {roleName}',

    'vcd.joinRequest.inviteDoctor': 'Invite doctor',
    'vcd.joinRequest.invitationHasBeenSent': 'Invitation has been sent',

    'userEmailConfirm.expiredLink': 'Expired link',
    'userEmailConfirm.pageTitle': 'Clinix | Reliable medical practitioners in your phone - Confirm email',
    'userEmailConfirm.emailSuccessfullyConfirmed.title': 'Email successfully confirmed',
    'userEmailConfirm.youHaveSuccessfullyConfirmEmail': 'You have successfully confirmed your email address.',
    'userEmailConfirm.linkHasBeenExpired.title': 'The link has been expired',
    'userEmailConfirm.youHaveErrorConfirmEmail': 'To continue registration, request a new confirmation email.',
    'userEmailConfirm.alt.confirmEmail': 'Confirm email',

    'userUnsubscribeEmail.preferencesHaveBeenSaved': 'Your preferences have been saved',
    'userUnsubscribeEmail.preferencesHaveNotBeenSaved': 'Oops! Something went wrong. Please try again',

    'userUnsubscribeEmail.pageTitle': 'Clinix | Reliable medical practitioners in your phone - Unsubscribe',
    'userUnsubscribeEmail.selectWhichEmailReceive': 'Select which emails you would like to receive:',
    'userUnsubscribeEmail.emailCommunication': 'Email communication',
    'userUnsubscribeEmail.receiveEmailNotificationAboutNewChatFiles': 'Receive email notifications about new chat messages or files.',
    'userUnsubscribeEmail.emailAppointment': 'Email appointment',
    'userUnsubscribeEmail.receiveEmailNotificationAboutNewAppointments': 'Receive email notifications about your appointments.',
    'userUnsubscribeEmail.emailRemainders': 'Email reminders',
    'userUnsubscribeEmail.receiveEmailRemainders': 'Receive email appointments reminders.',
    'userUnsubscribeEmail.emailNotifications': 'Email notifications',
    'userUnsubscribeEmail.receiveEmailNotificationsAndRemainders': 'Receive email notifications and reminders about your appointments.',

    'bookWithDoctorLink.title': 'Book an appointment with this doctor',
    'bookWithDoctorLink.youCanBook': 'You can book the appointment with {fullName} through the Clinix app',
    'bookWithDoctorLink.error.title': 'Reliable medical {breakingLine} practitioners in your phone',
    'bookWithDoctorLink.error.chooseTime': 'Choose a time and a doctor suitable for you. Get a complete treatment plan with medicine prescriptions without having to wait in line.',
    'bookWithDoctorLink.error.promo': 'Download the app and get a {free} doctor’s appointment.',
    'bookWithDoctorLink.error.free': 'FREE',

    'pushNotifications.title': 'Push Notifications',
    'pushNotifications.empty': 'There are no Push Notifications yet.',
    'pushNotifications.table.id': 'ID',
    'pushNotifications.table.createdAt': 'Created at',
    'pushNotifications.table.patients': 'Patients',
    'pushNotifications.table.message': 'Message',
    'pushNotifications.modal.title': 'Create Push Notification',
    'pushNotifications.modal.addSpecialities': 'Add specialities',
    'pushNotifications.modal.addClinic': 'Add clinic',
    'pushNotifications.modal.addPatients': 'Add patients',
    'pushNotifications.allPatientsAdded': 'All patients added',
    'pushNotifications.allClinicsAdded': 'All clinics added',
    'pushNotifications.confirmation.text': 'Are you sure that you want to send push notification to {count} users?',

    'dashboards.title': 'Dashboard',
  },
};
