import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import formatCurrency from 'utils/formatCurrency';

import { dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchPatient } from 'state/concepts/userProfile/patient/actions';
import { fetchTransactions } from '../actions';
import { createTransactionEndpoint } from '../endpoints';
import { CREATE_TRANSACTION } from '../types';

const createTransactionOperation = createLogic({
  type: CREATE_TRANSACTION,
  latest: true,

  async process({ httpClient, action: { isSubtract, values, form } }, dispatch, done) {
    const { url, endpoint } = createTransactionEndpoint;
    const { patientId, amount } = values;
    const params = {
      amount: isSubtract ? `-${amount}` : amount,
      patient_id: patientId,
    };

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: `notifications.${isSubtract ? 'subtractSuccessfully' : 'topupSuccessfully'}`,
          values: { amount: formatCurrency(amount) },
        },
      }));
      dispatch(fetchPatient(patientId));
      dispatch(fetchTransactions(patientId));
      dispatch(dataApiSuccess({ response }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default createTransactionOperation;
