import endpoint from 'utils/endpoint';

import {
  chatMessagesMessageRoute,
  chatMessagesRoute,
  chatsAttachmentRoute,
  chatsAttachmentsRoute,
  chatsMessagesReadRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchChatMessageEndpoint = chatId => (
  endpoint(types.FETCH_MESSAGES, 'GET', chatMessagesRoute(chatId))
);

export const createChatMessageEndpoint = chatId => (
  endpoint(types.CREATE_MESSAGE, 'POST', chatMessagesRoute(chatId))
);

export const markChatMessagesAsReadEndpoint = chatId => (
  endpoint(types.MARK_MESSAGES_AS_READ, 'PUT', chatsMessagesReadRoute(chatId))
);

export const updateChatMessageEndpoint = messageId => (
  endpoint(types.UPDATE_MESSAGE, 'PUT', chatMessagesMessageRoute(messageId))
);

export const removeChatMessageEndpoint = messageId => (
  endpoint(types.REMOVE_MESSAGE, 'DELETE', chatMessagesMessageRoute(messageId))
);

export const fetchChatAttachmentsEndpoint = chatId => (
  endpoint(types.FETCH_ATTACHMENTS, 'GET', chatsAttachmentsRoute(chatId))
);

export const removeChatsAttachmentsEndpoint = (chatId, attachmentId) => (
  endpoint(types.REMOVE_ATTACHMENT, 'DELETE', chatsAttachmentRoute(chatId, attachmentId))
);
