import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { parseInt } from 'lodash';
import { replace } from 'ramda';

import { handleInputEvent } from 'utils/inputHelpers';
import getNumbers from 'utils/numbers/getNumbers';

import CurrencyFieldComponent from './component';

class CurrencyField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    intl: PropTypes.shape().isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  formatter = value => (parseInt(value) >= 0 ? `₲${replace(/\B(?=(\d{3})+(?!\d))/g, '.', parseInt(value).toString())}` : '');

  parser = value => getNumbers(value);

  handleChange = (value) => {
    const { field, onChange } = this.props;

    onChange(value, field.value);
    handleInputEvent(field, field.onChange)(value || 0);
  };

  render = () => (
    <CurrencyFieldComponent
      {...this.props}
      formatter={this.formatter}
      parser={this.parser}
      onChange={this.handleChange}
    />
  );
}

export { CurrencyField as CurrencyFieldContainer };
export default injectIntl(CurrencyField);
