import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { deactivateDoctorEndpoint } from '../endpoints';
import { DEACTIVATE_DOCTOR } from '../types';

const deactivateDoctorOperation = createLogic({
  type: DEACTIVATE_DOCTOR,
  latest: true,

  async process({ httpClient, getState, action: { doctorId } }, dispatch, done) {
    const { url, endpoint } = deactivateDoctorEndpoint(doctorId);

    try {
      dispatch(dataApiRequest({ endpoint }));
      await httpClient.put(url);
      const state = getState();

      const response = updateDataHelper(
        state.data, 'userProfile', doctorId,
        { attributes: { active: false } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorDeactivated' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.doctorDeactivatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default deactivateDoctorOperation;
