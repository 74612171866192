import nodeCookie from 'cookie';
import browserCookie from 'component-cookie';

export default (ctx, name, options = { path: '/' }) => {
  if (ctx && ctx.isServer) {
    if (ctx.res.finished) { return; }
    const setCookieHeader = ctx.res.getHeader('Set-Cookie') || [];
    ctx.res.setHeader('Set-Cookie', [
      ...setCookieHeader,
      nodeCookie.serialize(name, '', { expires: new Date(), ...options }),
    ]);
  } else {
    browserCookie(name, null, options);
  }
};
