import { createLogic } from 'redux-logic';

import { fetchCommissions, resetCommissionIds, setCurrentPage } from '../actions';
import { SET_COMMISSIONS_INITIAL_STATE } from '../types';

const initialStateCommissionsOperation = createLogic({
  type: SET_COMMISSIONS_INITIAL_STATE,
  latest: true,

  async process({ action: { roleName } }, dispatch, done) {
    dispatch(resetCommissionIds());
    dispatch(setCurrentPage(1));
    dispatch(fetchCommissions(roleName));

    done();
  },
});

export default initialStateCommissionsOperation;
