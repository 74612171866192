import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withFormik } from 'formik';

import validationSchema from 'lib/yupLocalised/schemas/freeVisits';

import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { submitCreateFreeVisits } from 'state/concepts/userProfile/freeVisits/actions';
import { submitCreateFreeVisitsLoadingSelector } from 'state/concepts/userProfile/freeVisits/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';

import ExtraFreeVisitsModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class ExtraFreeVisitsModal extends React.Component {
  render = () => (
    <ExtraFreeVisitsModalComponent
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: submitCreateFreeVisitsLoadingSelector(state),
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitCreateFreeVisits,
};

export { ExtraFreeVisitsModal as ExtraFreeVisitsModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (({ clinicId, patientId }) => ({
      clinicId: clinicId || undefined,
      patientProfileId: patientId || undefined,
      freeVisitsCount: '',
    })),
    handleSubmit: handleSubmitWithProps(['isPatientProfile']),
    validationSchema,
  }),
)(ExtraFreeVisitsModal);
