import { DateTime, Interval, FixedOffsetZone } from 'luxon';
import { ceil } from 'lodash';
import {
  pipe, head, keys, curry, __, filter, map,
} from 'ramda';

export const secondsToTimeFromMidnight = seconds => DateTime.fromSeconds(seconds, { zone: 'utc' }).toFormat('h:mm a').toLowerCase();

export const timeFromISO = ISOString => DateTime.fromISO(ISOString).toFormat('t').toLowerCase();

export const isDaysEquals = (date, otherDate) => (
  date.year === otherDate.year && date.month === otherDate.month && date.day === otherDate.day
);

export const isMonthsEquals = (date, otherDate) => (
  date.year === otherDate.year && date.month === otherDate.month
);

export const fullDateFromISO = ISOString => DateTime.fromISO(ISOString).toFormat('t, MMMM d, yyyy');

export const lastDateOfMonthISO = date => date.endOf('month').toISODate();

export const firstDateOfMonthISO = date => date.startOf('month').toISODate();

export const dateToISO = date => date.toISODate();

export const dateHour = date => DateTime.fromISO(date, { setZone: true }).hour;

export const haveSameMonth = (date, otherDate) => date.hasSame(otherDate, 'month');

export const formattedTime = date => DateTime.fromISO(date, { setZone: true }).toFormat('t').toLowerCase();

export const formattedShortDate = date => DateTime.fromISO(date).toFormat('DDD');

export const formattedDate = date => `${formattedShortDate(date)}, ${formattedTime(date)}`;

export const formattedWithShortMonthDate = date => DateTime.fromISO(date).toFormat('DD');

export const formattedWithShortMonthAndTimeDate = date => `${formattedWithShortMonthDate(date)}, ${formattedTime(date)}`;

export const nearestSlotsDate = pipe(
  keys,
  head,
  curry(DateTime.fromISO)(__, { setZone: true }),
);

export const formattedCardExpiry = date => DateTime.fromISO(date).toFormat('MM/yy');

export const checkCardExpired = date => (
  parseInt(DateTime.fromISO(date).diffNow('months').values.months, 10) <= 0
);

export const formatDay = (day) => {
  const date = DateTime.fromISO(day);

  if (isDaysEquals(date, DateTime.local())) {
    return { id: 'shared.today' };
  }
  if (isDaysEquals(date, DateTime.local().minus({ days: 1 }))) {
    return { id: 'shared.yesterday' };
  }
  if (date.hasSame(DateTime.local(), 'year')) {
    return { id: 'shared.otherDay', values: { date: date.toFormat('EEEE, MMM d') } };
  }
  return { id: 'shared.otherDay', values: { date: date.toFormat('EEEE, MMM d, yyyy') } };
};

export const formatTime = (startTime) => {
  const time = Interval.fromDateTimes(DateTime.fromISO(startTime), DateTime.local()).toDuration(['days', 'hours', 'minutes']).toObject();
  const { days, hours, minutes } = time;

  if (days) {
    return { id: 'shared.formatTimeDays', values: { count: days } };
  }
  if (hours) {
    return { id: 'shared.formatTimeHours', values: { count: hours } };
  }
  return { id: 'shared.formatTimeMinutes', values: { count: ceil(minutes) } };
};

export const formatShortDistance = (time, otherTime) => (
  time.diff(otherTime).toFormat('h:mm:ss')
);

export const formattedTimeZoned = (ISOString, timezoneOffset) => DateTime.fromISO(ISOString, { zone: FixedOffsetZone.parseSpecifier(`UTC${timezoneOffset}`) }).toFormat('t').toLowerCase();

export const formattedShortDateZoned = (ISOString, timezoneOffset) => DateTime.fromISO(ISOString, { zone: FixedOffsetZone.parseSpecifier(`UTC${timezoneOffset}`) }).toFormat('DDD');

export const formattedAvailabilities = (weekday, availabilities) => pipe(
  filter(({ dayOfWeek }) => dayOfWeek === weekday),
  map(({ start, duration }) => `${secondsToTimeFromMidnight(start)} - ${secondsToTimeFromMidnight(start + duration)}`),
)(availabilities);
