import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputNumber } from 'antd';
import { is } from 'ramda';
import { getIn } from 'formik';
import { FormattedMessage } from 'react-intl';

import InputMessage from '../InputMessage';

const PercentField = ({
  id,
  disabled,
  label,
  icon,
  small,
  className,
  placeholder,
  autoComplete,
  intl,
  formatter,
  parser,
  step,
  field,
  onChange,
  form: { touched, errors, status },
  fullWidth,
  disableFormatter,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name);
  const fieldErrors = getIn(errors, field.name);
  const fieldStatus = getIn(status, field.name);

  const wrapperClassNames = classNames(
    className, 'stepper',
    'main-input input-numeric',
    {
      'main-input--disabled': disabled,
      'main-input--has-message': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
      'main-input--has-message-alert': fieldStatus,
      'main-input--sm': small,
      'input-numeric--full-width': fullWidth,
    },
  );

  return (
    <div className={wrapperClassNames}>
      {
        label && (
          <label
            className="stepper__label"
            htmlFor={id}
          >
            <FormattedMessage {...label} />
          </label>
        )
      }
      <div className="stepper__wrap">
        <InputNumber
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          id={id}
          disabled={disabled}
          className="w-160"
          {...field}
          {...props}
          onChange={onChange}
          formatter={disableFormatter ? null : formatter}
          parser={parser}
          autoComplete={autoComplete}
          step={step}
        />
      </div>
      {
        fieldTouched && fieldErrors && (
          <InputMessage
            message={fieldErrors}
            icon={icon}
          />
        )
      }
    </div>
  );
};

PercentField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  small: false,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  step: 1,
  fullWidth: false,
  formatter: null,
  disableFormatter: false,
};

PercentField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  icon: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  formatter: PropTypes.func,
  parser: PropTypes.func.isRequired,
  step: PropTypes.number,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disableFormatter: PropTypes.bool,
};

export default PercentField;
