import React from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const SignatureModal = ({
  onClose,
  onClick,
  onSignatureUndo,
  onSignatureClear,
  onSignatureSave,
  signatureRef,
  hasSignature,
}) => (
  <Modal
    onClose={onClose}
    className="ant-modal"
    wrapClassName="ant-modal-wrap__8"
  >
    <div className="ant-modal__header">
      <h2 className="ant-modal__header-txt">
        <FormattedMessage id="uploadImage.addSignature" />
      </h2>
      <button
        type="button"
        onClick={onClose}
        className="ant-modal__close"
      >
        <SvgIcon icon="cross" />
      </button>
    </div>
    <div className="ant-modal__body ant-modal__body--canvas">
      <SignaturePad
        ref={signatureRef}
        canvasProps={{
          className: 'w-full',
          onClick,
        }}
      />
    </div>
    <div className="ant-modal__footer ant-modal__footer--between">
      <div>
        <Button
          kind="tertiary"
          onClick={onClose}
          text={{ id: 'shared.cancel' }}
        />
        <Button
          kind="tertiary"
          onClick={onSignatureUndo}
          text={{ id: 'shared.undo' }}
        />
        <Button
          kind="danger"
          onClick={onSignatureClear}
          text={{ id: 'shared.clear' }}
        />
      </div>
      <div>
        <Button
          disabled={!hasSignature}
          onClick={onSignatureSave}
          text={{ id: 'shared.save' }}
        />
      </div>
    </div>
  </Modal>
);

SignatureModal.defaultProps = {
  hasSignature: false,
};

SignatureModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSignatureUndo: PropTypes.func.isRequired,
  onSignatureClear: PropTypes.func.isRequired,
  onSignatureSave: PropTypes.func.isRequired,
  signatureRef: PropTypes.shape().isRequired,
  hasSignature: PropTypes.bool,
};

export default SignatureModal;
