import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';

import { loggerVideoConnectionEndpoint } from '../endpoints';
import { SEND_LOGGERS } from '../types';

const loggerVideoConnectionOperation = createLogic({
  type: SEND_LOGGERS,
  latest: true,

  async process({ httpClient, action: { loggers } }, dispatch, done) {
    const { endpoint, url } = loggerVideoConnectionEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    const params = {
      logs: Object.keys(loggers).map(key => `${key}: ${loggers[key]}`),
    };

    try {
      await httpClient.post(url, params);

      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default loggerVideoConnectionOperation;
