const namespace = 'specialities';

export const FETCH_SPECIALITIES = `${namespace}/FETCH_SPECIALITIES`;
export const SET_SPECIALITIES = `${namespace}/SET_SPECIALITIES`;
export const SET_SPECIALITIES_PAGE = `${namespace}/SET_SPECIALITIES_PAGE`;
export const SET_SPECIALITIES_SORT_ORDER = `${namespace}/SET_SPECIALITIES_SORT_ORDER`;
export const SET_SPECIALITIES_BLANK_STATE = `${namespace}/SET_SPECIALITIES_BLANK_STATE`;
export const SELECT_SPECIALITY = `${namespace}/SELECT_SPECIALITY`;
export const SET_SPECIALITIES_SELECTED = `${namespace}/SET_SPECIALITIES_SELECTED`;
export const SET_SPECIALITIES_FILTER = `${namespace}/SET_SPECIALITIES_FILTER`;
export const ACTIVATE_SPECIALITIES = `${namespace}/ACTIVATE_SPECIALITIES`;
export const DEACTIVATE_SPECIALITIES = `${namespace}/DEACTIVATE_SPECIALITIES`;
export const REMOVE_SPECIALITIES = `${namespace}/REMOVE_SPECIALITIES`;
export const GET_SPECIALITY = `${namespace}/GET_SPECIALITY`;
export const GET_SPECIALITY_SYMPTOMS = `${namespace}/GET_SPECIALITY_SYMPTOMS`;
export const SET_SPECIALITY_SYMPTOMS_IDS = `${namespace}/SET_SPECIALITY_SYMPTOMS_IDS`;
export const RESET_SPECIALITY_SYMPTOMS_IDS = `${namespace}/RESET_SPECIALITY_SYMPTOMS_IDS`;
export const CREATE_SPECIALITY = `${namespace}/CREATE_SPECIALITY`;
export const UPDATE_SPECIALITY = `${namespace}/UPDATE_SPECIALITY`;

export const FETCH_SPECIALITIES_LIST = `${namespace}/FETCH_SPECIALITIES_LIST`;
export const SET_SPECIALITIES_LIST_FILTERS = `${namespace}/SET_SPECIALITIES_LIST_FILTERS`;
export const SET_SPECIALITIES_LIST_IDS = `${namespace}/SET_SPECIALITIES_LIST_IDS`;
export const ADD_SPECIALITIES_LIST_IDS = `${namespace}/ADD_SPECIALITIES_LIST_IDS`;
export const RESET_SPECIALITIES_LIST_IDS = `${namespace}/RESET_SPECIALITIES_LIST_IDS`;
export const SET_SPECIALITIES_LIST_STATUSES = `${namespace}/SET_SPECIALITIES_LIST_STATUSES`;
export const RESET_SPECIALITIES_LIST_STATUSES = `${namespace}/RESET_SPECIALITIES_LIST_STATUSES`;
export const SET_SPECIALITIES_LIST_FILTER_PARAMS = `${namespace}/SET_SPECIALITIES_LIST_FILTER_PARAMS`;
export const RESET_SPECIALITIES_LIST_FILTER_PARAMS = `${namespace}/RESET_SPECIALITIES_LIST_FILTER_PARAMS`;
