import getOnboardingUserMyself from './getOnboardingUserMyself';
import updateOnboardingStep from './updateOnboardingStep';
import submitOnboardingGeneral from './submitOnboardingGeneral';
import submitOnboardingLocation from './submitOnboardingLocation';
import submitOnboardingVirtualClinicGeneral from './submitOnboardingVirtualClinicGeneral';
import submitOnboardingVirtualClinicCompany from './submitOnboardingVirtualClinicCompany';
import uploadOnboardingVirtualClinicLogo from './uploadOnboardingVirtualClinicLogo';
import removeOnboardingVirtualClinicLogo from './removeOnboardingVirtualClinicLogo';

export default [
  getOnboardingUserMyself,
  updateOnboardingStep,
  submitOnboardingGeneral,
  submitOnboardingLocation,
  submitOnboardingVirtualClinicGeneral,
  submitOnboardingVirtualClinicCompany,
  uploadOnboardingVirtualClinicLogo,
  removeOnboardingVirtualClinicLogo,
];
