import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { CONFIRM_USER_EMAIL } from '../types';
import { confirmUserEmailEndpoint } from '../endpoints';

const confirmUserEmailOperation = createLogic({
  type: CONFIRM_USER_EMAIL,
  latest: true,

  async process({ httpClient, getState, action: { token } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = confirmUserEmailEndpoint;
    const { id, newEmail } = currentUserSelector(state);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const response = updateDataHelper(
        state.data, 'userProfile', id,
        {
          attributes: {
            email: newEmail,
            newEmail: null,
          },
        },
      );

      await httpClient.put(url, { token });

      dispatch(dataApiSuccess({ response }));
      dispatch(showNotification({ messageObject: { id: 'notifications.emailHasBeenConfirmed' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.invalidLink' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, onlySendErrorToSentry: true });
    }

    redirect({ href: '/account_settings' });
    done();
  },
});

export default confirmUserEmailOperation;
