import getPatients from './getPatients';
import getSpecialities from './getSpecialities';
import getClinicSpecialities from './getClinicSpecialities';
import getSymptoms from './getSymptoms';
import getDoctors from './getDoctors';

export default [
  getPatients,
  getSpecialities,
  getClinicSpecialities,
  getSymptoms,
  getDoctors,
];
