import { DateTime } from 'luxon';
import {
  compose,
  filter,
  groupBy,
  identity,
  includes,
  prop,
  propEq,
  sortBy,
  split,
  trim,
  values,
  without,
  path,
} from 'ramda';

import { SYSTEM_CHAT_USER, SYSTEM_MESSAGES } from 'constants/chat';
import { isDaysEquals } from 'utils/dateTime';

export const formatDay = (day, formatMessage) => {
  const date = DateTime.fromISO(day);

  if (isDaysEquals(date, DateTime.local())) {
    return formatMessage({ id: 'chat.today' });
  }
  if (isDaysEquals(date, DateTime.local().minus({ days: 1 }))) {
    return formatMessage({ id: 'chat.yesterday' });
  }
  if (date.hasSame(DateTime.local(), 'year')) {
    return date.toFormat('EEEE, MMM d');
  }
  return date.toFormat('EEEE, MMM d, yyyy');
};

export const groupMessagesByDate = compose(
  groupBy(({ createdAt }) => DateTime.fromISO(createdAt).toISODate()),
  sortBy(compose(DateTime.fromISO, prop('createdAt'))),
  filter(identity),
);

export const splitMessage = compose(
  split('\n'),
  trim,
);

export const isMessageEdited = ({ createdAt, updatedAt }) => createdAt !== updatedAt;
export const isAppointmentCreatedMessage = propEq('type', SYSTEM_MESSAGES.appointmentCreated);
export const isSystemMessage = meta => includes(meta.type, values(SYSTEM_MESSAGES));
export const isSystemInfoMessage = meta => includes(meta.type, values(without([
  SYSTEM_MESSAGES.clinix,
  SYSTEM_MESSAGES.appointmentCreated,
], SYSTEM_MESSAGES)));

export const chatUser = message => (
  message.chatUser || SYSTEM_CHAT_USER
);

export const isUnreadMessage = (message, currentChatUserId) => {
  if (!prop('chatUser', message) || !currentChatUserId) {
    return false;
  }

  return !message.readAt && message.chatUser.id !== currentChatUserId.toString();
};

export const isCurrentUsersMessage = (message, currentUser) => {
  const messageChatUserId = path(['chatUser', 'id'], message);
  const currentChatUserId = prop('chatUserId', currentUser).toString();

  return messageChatUserId === currentChatUserId;
};
