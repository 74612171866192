import { createSelector } from 'reselect';
import build from 'redux-object';
import {
  path, prop, propOr, isEmpty, pipe, map, without,
} from 'ramda';

const dataSelector = prop('data');

export const currentUserIdSelector = path(['session', 'currentUserId']);
export const currentUserRoleSelector = path(['session', 'currentUserRole']);
export const visibleAuthFlowSelector = path(['session', 'visibleAuthFlow']);
export const userTypeSelector = path(['session', 'userType']);

export const currentUserSelector = createSelector(
  currentUserIdSelector,
  userTypeSelector,
  dataSelector,
  (id, userType, data) => (data[userType] && data[userType][id] ? build(data, userType, id) : null),
);

export const currentUserProfilesClinicSpecialitySelector = createSelector(
  currentUserSelector,
  propOr([], 'userProfilesClinicSpecialities'),
);

export const currentTimezoneSelector = createSelector(
  currentUserSelector,
  prop('timezone'),
);

export const reset2FaSelector = createSelector(
  currentUserSelector,
  prop('reset2Fa'),
);

export const currentUserTutorialSelector = createSelector(
  currentUserSelector,
  prop('userOnboardingStepper'),
);

export const currentUserClinicSelector = createSelector(
  currentUserSelector,
  prop('clinic'),
);

export const currentUserClinicIdSelector = createSelector(
  currentUserClinicSelector,
  prop('id'),
);

export const currentUserClinicSpecialitiesSelector = createSelector(
  currentUserClinicSelector,
  propOr([], 'clinicSpecialities'),
);

export const currentUserSpecialityIdsSelector = createSelector(
  currentUserClinicSpecialitiesSelector,
  clinicSpecialities => (
    !isEmpty(clinicSpecialities)
      ? pipe(
          map(({ speciality }) => prop('id', speciality)),
          without([undefined, null]),
        )(clinicSpecialities)
      : []
  ),
);
