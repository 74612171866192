import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import isPresent from 'utils/isPresent';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentTimezoneSelector } from 'state/concepts/session/selectors';
import { setRevenueAppointmentIds, setRevenueBlankState } from '../actions';
import { revenuePaginationSelector, revenueFiltersSelector } from '../selectors';
import { fetchRevenueEndpoint } from '../endpoints';
import { FETCH_REVENUE } from '../types';

const fetchRevenueOperation = createLogic({
  type: FETCH_REVENUE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchRevenueEndpoint;

    const state = getState();
    const {
      startDate, endDate, userProfileId, clinicTypes, clinicIds,
    } = revenueFiltersSelector(state);
    const userProfileIds = isPresent(userProfileId) ? { user_profile_ids: [userProfileId] } : {};
    const clinicTypesParam = isPresent(clinicTypes) ? { clinic_types: clinicTypes } : {};
    const clinicIdsParam = isPresent(clinicIds) ? { clinic_ids: clinicIds } : {};

    const params = {
      page: revenuePaginationSelector(state),
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone: currentTimezoneSelector(state),
        ...userProfileIds,
        ...clinicTypesParam,
        ...clinicIdsParam,
      },
      include: 'clinic-speciality,patient-profile,speciality,symptom,user-profile,user-profile.clinic',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRevenueBlankState(isBlankState(params.filter, data.data)));
      dispatch(setRevenueAppointmentIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRevenueOperation;
