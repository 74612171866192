import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { removeDoctorInvitation as removeDoctorInvitationAction } from 'state/concepts/userProfile/doctors/actions';
import { removeDoctorInvitationLoadingSelector } from 'state/concepts/userProfile/doctors/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class RemoveInvitationModal extends React.Component {
  static propTypes = {
    doctor: PropTypes.shape().isRequired,
    removeDoctorInvitation: PropTypes.func.isRequired,
  };

  handleRemoveDoctorInvitation = () => {
    const { doctor: { id }, removeDoctorInvitation } = this.props;

    removeDoctorInvitation(id);
  };

  render = () => (
    <ConfirmModal
      {...this.props}
      onSubmit={this.handleRemoveDoctorInvitation}
      title={{ id: 'independentDoctors.doctors.removeInvitations.title' }}
      bodyText={[{
        id: 'independentDoctors.doctors.removeInvitations.subTitle',
        values: { email: <span className="f-700">{this.props.doctor.email}</span> },
      }]}
      submitClassName="w-136"
      submitText={{ id: 'shared.remove' }}
      kind="danger"
    />
  );
}

const mapStateToProps = (state, { doctor: { id } }) => ({
  isLoading: removeDoctorInvitationLoadingSelector(state, id),
});

const mapDispatchToProps = {
  removeDoctorInvitation: removeDoctorInvitationAction,
};

export { RemoveInvitationModal as RemoveInvitationModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(RemoveInvitationModal);
