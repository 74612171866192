import normalize from 'json-api-normalizer';
import { createLogic } from 'redux-logic';
import { pathOr } from 'ramda';

import { VOUCHER_CODE_STATUS } from 'constants/vouchers';

import requestErrorHandler from 'lib/requestErrorHandler';

import formatJsonApiErrors from 'utils/formatJsonApiErrors';
import { formattedVoucherCodeToParams } from 'utils/vouchers';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setVoucherCodeStatus } from '../actions';
import { submitPatientsAccountVoucherTopupEndpoint } from '../endpoints';
import { SUBMIT_PATIENT_ACCOUNT_VOUCHER_TOPUP } from '../types';

const submitPatientAccountVoucherTopupOperation = createLogic({
  type: SUBMIT_PATIENT_ACCOUNT_VOUCHER_TOPUP,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = submitPatientsAccountVoucherTopupEndpoint;

    const params = {
      code: formattedVoucherCodeToParams(values.code),
      include: 'patient_profile.patient_balance',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setVoucherCodeStatus({
        status: VOUCHER_CODE_STATUS.success,
        balance: data.data.attributes.balance,
      }));
    } catch (error) {
      const errors = pathOr(null, ['response', 'data', 'errors'], error);
      const formattedErrors = formatJsonApiErrors(errors);

      if (formattedErrors.voucherAlreadyUsed) {
        dispatch(setVoucherCodeStatus({
          status: VOUCHER_CODE_STATUS.failure,
          balance: 0,
        }));
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitPatientAccountVoucherTopupOperation;
