import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  compose, includes, trim, isEmpty, without,
} from 'ramda';

import {
  filterPatientsList as filterPatientsListAction,
  fetchPatientsList as fetchPatientsListAction,
  resetPatientsListIds as resetPatientsListIdsAction,
  resetPatientsListFilterParams as resetPatientsListFilterParamsAction,
} from 'state/concepts/userProfile/pushNotifications/actions';
import {
  patientsListMetaSelector,
  patientsListLoadingSelector,
  patientsListForFilterSelector,
  patientsIdsSelector,
} from 'state/concepts/userProfile/pushNotifications/selectors';

import DropdownPatientsFieldComponent from './component';

class DropdownPatientsField extends React.Component {
  static propTypes = {
    filterPatientsList: PropTypes.func.isRequired,
    resetPatientsListIds: PropTypes.func.isRequired,
    resetPatientsListFilterParams: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    patients: PropTypes.arrayOf(PropTypes.shape()),
    optionsIds: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.shape().isRequired,
  }

  static defaultProps = {
    options: [],
    optionsIds: [],
    patients: [],
  }

  state = {
    searchQuery: '',
  };

  componentDidMount = () => {
    const { filterPatientsList } = this.props;

    filterPatientsList({ excludePatientsIds: this.excludedPatientsIds });
  }

  componentWillUnmount = () => {
    const { resetPatientsListIds, resetPatientsListFilterParams } = this.props;

    resetPatientsListIds();
    resetPatientsListFilterParams();
  }

  get excludedPatientsIds() {
    const { optionsIds, field: { value } } = this.props;

    return without([value], optionsIds);
  }

  get isAllPatientsSelected() {
    const { options, field: { value } } = this.props;
    const { searchQuery } = this.state;

    return isEmpty(searchQuery) && options.every(({ id }) => includes(id, [].concat(value)));
  }

  handleSearch = debounce((searchQuery) => {
    const { filterPatientsList, resetPatientsListIds } = this.props;

    this.setState({ searchQuery });
    resetPatientsListIds();
    filterPatientsList({
      name: trim(searchQuery),
      excludePatientsIds: this.excludedPatientsIds,
    });
  }, 250)

  handleFocus = () => {
    const { filterPatientsList } = this.props;

    this.setState({ searchQuery: '' });
    filterPatientsList({
      name: '',
      excludePatientsIds: this.excludedPatientsIds,
    });
  }

  render = () => (
    <DropdownPatientsFieldComponent
      {...this.props}
      isAllPatientsSelected={this.isAllPatientsSelected}
      onSearch={this.handleSearch}
      onFocus={this.handleFocus}
    />
  )
}

const mapStateToProps = state => ({
  meta: patientsListMetaSelector(state),
  isLoading: patientsListLoadingSelector(state),
  options: patientsListForFilterSelector(state),
  optionsIds: patientsIdsSelector(state),
});

const mapDispatchToProps = {
  fetchPatientsList: fetchPatientsListAction,
  filterPatientsList: filterPatientsListAction,
  resetPatientsListIds: resetPatientsListIdsAction,
  resetPatientsListFilterParams: resetPatientsListFilterParamsAction,
};

export { DropdownPatientsField as DropdownPatientsFieldContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DropdownPatientsField);
