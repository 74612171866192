import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setDateAvailability } from '../actions';
import { fetchDateAvailabilityEndpoint } from '../endpoints';
import { FETCH_DATE_AVAILABILITY } from '../types';

const fetchDateAvailabilityOperation = createLogic({
  type: FETCH_DATE_AVAILABILITY,
  latest: true,

  async process({ action: { startDate, endDate, userId }, httpClient }, dispatch, done) {
    const { endpoint, url } = fetchDateAvailabilityEndpoint(userId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const params = {
        filter: {
          start_date: startDate,
          end_date: endDate,
        },
      };
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setDateAvailability(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }
    done();
  },
});

export default fetchDateAvailabilityOperation;
