import { path } from 'ramda';

import { loadingSelector } from 'state/data/selectors';
import { validatePatientResetPasswordTokenEndpoint } from './endpoints';

export const isResentPasswordSelector = path(['patientResetPassword', 'isResentPassword']);

export const validatePatientResetPasswordLoadingSelector = (state) => (
  loadingSelector(state, validatePatientResetPasswordTokenEndpoint.endpoint)
);
