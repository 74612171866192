import React from 'react';
import { withFormik } from 'formik';
import { compose, prop } from 'ramda';
import { connect } from 'react-redux';

import validationSchema from 'lib/yupLocalised/schemas/createTransaction';

import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { createTransaction } from 'state/concepts/transactions/actions';

import TransactionModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class TransactionModal extends React.Component {
  render = () => (
    <TransactionModalComponent
      {...this.props}
    />
  );
}

const mapDispatchToProps = {
  onSubmit: createTransaction,
};

export { TransactionModal as TransactionModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ patient }) => ({
      amount: '',
      patientId: prop('id', patient),
    }),
    validationSchema,
    handleSubmit: handleSubmitWithProps(['isSubtract']),
  }),
)(TransactionModal);
