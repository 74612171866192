import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';

const FileUploadErrorModal = React.forwardRef(({
  maxSize,
  onClose,
  onSubmit,
  onUpload,
}, fileInputRef) => (
  <Modal
    onClose={onClose}
    className="ant-modal"
    wrapClassName="ant-modal-wrap__4"
  >
    <div className="ant-modal__header">
      <h2 className="ant-modal__header-txt">
        <FormattedMessage id="chat.fileUploadError" />
      </h2>
      <button
        type="button"
        onClick={onClose}
      >
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="modal-message modal-message--centered">
        <SvgIcon icon="alert-o" className="modal-message__icon mb-24" />
        <p className="modal-message__txt">
          <FormattedMessage
            id="chat.fileSizeCantBeBigger"
            values={{ size: maxSize }}
          />
        </p>
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <div>
        <Button
          kind="flat"
          className="mr-16"
          onClick={onClose}
          text={{ id: 'shared.cancel' }}
        />
        <Button
          onClick={onSubmit}
          text={{ id: 'chat.selectOtherFile' }}
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={onUpload}
          className="chat-input__attach-input"
        />
      </div>
    </div>
  </Modal>
));

FileUploadErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  maxSize: PropTypes.string.isRequired,
};

export default FileUploadErrorModal;
