import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';

import Modal from 'views/shared/Modal';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import CurrencyField from 'views/shared/CurrencyField';
import SvgIcon from 'views/shared/SvgIcon';

const TransactionModalComponent = ({
  onClose,
  handleSubmit,
  isSubtract,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__4"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        {isSubtract
          ? <FormattedMessage id="patient.balance.balanceSubtract" />
          : <FormattedMessage id="patient.balance.balanceTopup" />
        }
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="d-flex">
        <Field
          name="amount"
          component={CurrencyField}
          className="mb-16"
          id="value"
          label={{ id: 'label.amount' }}
          placeholder={{ id: 'placeholder.pricePerSession' }}
          icon="alert"
          min={0}
          fullWidth
        />
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={isSubtract ? { id: 'shared.subtract' } : { id: 'shared.topup' }}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

TransactionModalComponent.defaultProps = {
  isSubtract: false,
};

TransactionModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubtract: PropTypes.bool,
};

export default TransactionModalComponent;
