import { createSelector } from 'reselect';
import {
compose, map, values, prop, path, pipe, filter, propEq, head,
} from 'ramda';

import findTimezone from 'utils/findTimezone';
import determineTimezone from 'utils/determineTimezone';

import { currentUserSelector } from 'state/concepts/session/selectors';

const dataSelector = prop('data');
export const timezoneSelector = path(['timezones', 'timezone']);

export const timezonesDataSelector = createSelector(
  dataSelector,
  compose(
    values,
    prop('timezone'),
  ),
);

export const timezonesSelector = createSelector(
  timezonesDataSelector,
  map(({ id, attributes: { name } }) => ({ key: id, value: id, label: name })),
);

export const browserTimezoneSelector = createSelector(
  timezonesDataSelector,
  compose(
    prop('id'),
    findTimezone,
  ),
);

export const selectedTimezoneSelector = createSelector(
  timezoneSelector,
  currentUserSelector,
  (timezone, currentUser) => timezone || prop('formattedTimezone', currentUser),
);

export const formattedTimezoneSelector = (timezone) => createSelector(
  timezonesSelector,
  timezones => pipe(filter(propEq('value', timezone)), head, prop('label'))(timezones),
);

export const deviceTimezoneSelector = createSelector(
  (_, timezone) => timezone,
  timezonesDataSelector,
  (timezone, data) => determineTimezone(data, timezone),
);
