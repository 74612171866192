import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setDoctorProfile } from '../actions';
import { fetchDoctorProfileEndpoint } from '../endpoints';
import { FETCH_DOCTOR_PROFILE } from '../types';

const fetchDoctorProfileOperation = createLogic({
  type: FETCH_DOCTOR_PROFILE,
  latest: true,

  async process({ httpClient, action: { doctorId } }, dispatch, done) {
    const { endpoint, url } = fetchDoctorProfileEndpoint(doctorId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, {
        params: {
          include: 'user-profiles-clinic-specialities.clinic_speciality',
        },
      });
      const normalizedData = normalize(data);

      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
      dispatch(setDoctorProfile(data.data.id));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchDoctorProfileOperation;
