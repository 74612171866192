import fetchCommissions from './fetchCommissions';
import fetchDoctorsCommissions from './fetchDoctorsCommissions';
import fetchVirtualClinicsCommissions from './fetchVirtualClinicsCommissions';
import filterCommissions from './filterCommissions';
import initialStateCommissions from './initialStateCommissions';

export default [
  fetchCommissions,
  fetchDoctorsCommissions,
  fetchVirtualClinicsCommissions,
  filterCommissions,
  initialStateCommissions,
];
