import { combineReducers } from 'redux';

import { VIEWS } from 'constants/videoConference';

import * as types from './types';

const videoConferenceId = (state = '', action) => {
  switch (action.type) {
    case types.SET_VIDEO_CONFERENCE_ID:
      return action.id;
    default:
      return state;
  }
};

const settingsInitialState = {
  audioDevice: undefined,
  videoDevice: undefined,
  isVideoEnabled: true,
  isAudioEnabled: true,
  isScreenShared: false,
};

const settings = (state = settingsInitialState, action) => {
  switch (action.type) {
    case types.SET_SETTINGS:
      return { ...state, ...action.settings };
    case types.RESET_SETTINGS:
      return settingsInitialState;
    default:
      return state;
  }
};

const view = (state = VIEWS.collocutor, action) => {
  switch (action.type) {
    case types.CHANGE_VIEW:
      return action.view;
    default:
      return state;
  }
};

const chatPanelVisible = (state = false, action) => {
  switch (action.type) {
    case types.TOGGLE_CHAT_PANEL_VISIBLE:
      return action.panelVisible;
    default:
      return state;
  }
};

const canJoin = (state = false, action) => {
  switch (action.type) {
    case types.CAN_JOIN:
      return action.isCanJoin;
    default:
      return state;
  }
};

export default combineReducers({
  videoConferenceId,
  settings,
  view,
  chatPanelVisible,
  canJoin,
});
