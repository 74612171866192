import * as types from './types';

export const setSearchResults = searchParams => ({
  type: types.SET_SEARCH_RESULTS,
  searchParams,
});

export const resetSearchResults = resetPath => ({
  type: types.RESET_SEARCH_RESULTS,
  resetPath,
});
