import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'ramda';

import profileName from 'utils/profileName';

const AppointmentCalendarTooltipPatientDetailsComponent = ({
  patientProfile,
  childProfile,
  onRedirectToPatientProfile,
}) => (
  <div className="modal-appointment__relation">
    <div className="modal-appointment__relation-item">
      <div className="modal-appointment__relation-title">
        <FormattedMessage id="appointments.patient" />
      </div>
      <div className="modal-appointment__relation-patient-name">
        <a className="pointer" role="button" onClick={onRedirectToPatientProfile}>
          {profileName(patientProfile)}
        </a>
      </div>
      <div className="modal-appointment__relation-patient-mail">
        {patientProfile.email}
      </div>
    </div>
    {!isNil(childProfile) && (
      <div className="modal-appointment__relation-item">
        <div className="modal-appointment__relation-title">
          <FormattedMessage id="appointments.appointmentForChild" />
        </div>
        <div className="modal-appointment__relation-child-name">
          {profileName(childProfile)}
        </div>
      </div>
    )}
  </div>
);

AppointmentCalendarTooltipPatientDetailsComponent.defaultProps = {
  childProfile: null,
};

AppointmentCalendarTooltipPatientDetailsComponent.propTypes = {
  patientProfile: PropTypes.shape().isRequired,
  childProfile: PropTypes.shape(),
  onRedirectToPatientProfile: PropTypes.func.isRequired,
};

export default AppointmentCalendarTooltipPatientDetailsComponent;
