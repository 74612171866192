import { createSelector } from 'reselect';
import { map, path, prop, toString } from 'ramda';

import profileName from 'utils/profileName';

import { makeSelectSearchResults } from 'state/searchResults/selectors';
import { currentUserProfilesClinicSpecialitySelector } from 'state/concepts/session/selectors';

export const filtersSelector = path(['filters', 'commonFilters']);

export const patientsSelector = createSelector(
  makeSelectSearchResults('patients', 'patientProfile'),
  map(patient => ({
    key: toString(patient.id),
    value: patient.id,
    label: profileName(patient),
  })),
);

export const symptomsSelector = createSelector(
  makeSelectSearchResults('symptoms', 'symptom'),
  map(symptom => ({
    key: toString(symptom.id),
    value: symptom.id,
    label: symptom.name,
  })),
);

export const doctorsSelector = createSelector(
  makeSelectSearchResults('doctors', 'userProfile'),
  map(doctor => ({
    key: toString(doctor.id),
    value: doctor.id,
    label: profileName(doctor),
  })),
);

export const clinicSpecialitiesSelector = createSelector(
  makeSelectSearchResults('clinicSpecialities', 'clinicSpeciality'),
  map(clinicSpeciality => ({
    key: toString(clinicSpeciality.id),
    value: clinicSpeciality.id,
    label: clinicSpeciality.name,
  })),
);

export const currentUserClinicSpecialitiesSelector = createSelector(
  currentUserProfilesClinicSpecialitySelector,
  map(({ clinicSpeciality }) => ({
    key: toString(clinicSpeciality.id),
    value: clinicSpeciality.id,
    label: clinicSpeciality.name,
  })),
);

export const specialitiesSelector = createSelector(
  makeSelectSearchResults('specialities', 'speciality'),
  map(speciality => ({
    key: toString(speciality.id),
    value: speciality.id,
    label: speciality.name,
  })),
);

export const commonFiltersSelector = createSelector(
  filtersSelector,
  ({ name, status, wellness, ...rest }) => ({
    name,
    statuses: status === 'all' ? [] : [status],
    wellness: wellness === 'all' ? null : wellness,
    ...rest,
  }),
);

export const searchQuerySelector = createSelector(
  filtersSelector,
  prop('name'),
);
