import { createLogic } from 'redux-logic';

import {
  resetClinicSpecialitiesListIds,
  resetClinicSpecialitiesListStatuses,
  resetClinicSpecialitiesListFilterParams,
} from 'state/concepts/userProfile/clinicSpecialities/actions';
import { DOCTOR_ASSIGN_CLINIC_SPECIALITIES_RESET_STATE } from '../types';

const assignClinicSpecialitiesResetStateOperation = createLogic({
  type: DOCTOR_ASSIGN_CLINIC_SPECIALITIES_RESET_STATE,
  latest: true,

  async process(_, dispatch, done) {
    dispatch(resetClinicSpecialitiesListIds());
    dispatch(resetClinicSpecialitiesListStatuses());
    dispatch(resetClinicSpecialitiesListFilterParams());

    done();
  },
});

export default assignClinicSpecialitiesResetStateOperation;
