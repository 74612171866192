// ---User---
export const usersRoute = '/users';
export const userRoute = userId => `/users/${userId}`;
export const userActivateRoute = userId => `/users/${userId}/activate`;
export const userDeactivateRoute = userId => `/users/${userId}/deactivate`;
export const userAvatarRoute = userId => `/users/${userId}/avatar`;
export const userSignatureRoute = userId => `/users/${userId}/signature`;
export const userInvitationsRoute = userId => `/users/${userId}/invitations`;
export const userInvitationsResendRoute = userId => `/users/${userId}/invitations/resend`;
export const usersAccountProfileRoute = '/users/account/profile';
export const usersAccountProfileSignatureRoute = '/users/account/profile/signature';
export const usersAccountProfilesMyselfRoute = '/users/account/profiles/myself';
export const usersJoinRequestsRoute = 'users/join_requests';
export const usersJoinRequestRoute = id => `/users/join_requests/${id}`;
export const usersJoinRequestApproveRoute = id => `users/join_requests/${id}/approve`;
export const usersJoinRequestRejectRoute = id => `users/join_requests/${id}/reject`;
export const usersDeactivationRoute = userId => `/users/${userId}/deactivation`;
export const usersRecurringAvailabilityRoute = userId => `/users/${userId}/recurring_availability`;
export const usersDateAvailabilityRoute = userId => `/users/${userId}/date_availability`;
export const usersOnboardingStepperAvailabilityRoute = '/users/user_onboarding_stepper/availability';
export const usersOnboardingStepperClinicSpecialitiesRoute = '/users/user_onboarding_stepper/clinic_specialities';
export const usersOnboardingStepperInviteVirtualClinicDoctorRoute = '/users/user_onboarding_stepper/invite_virtual_clinic_doctor';
export const usersOnboardingStepperCongratulationsRoute = '/users/user_onboarding_stepper/congratulations';
export const usersInvitationsStatusRoute = '/users/invitations/status';
export const usersInvitationsRoute = '/users/invitations';
export const usersResetAuthenticationRoute = id => `users/${id}/reset_authentication`;
export const userAccountProfileRefreshActivityRoute = '/users/account/profile/refresh_activity';
export const userAccountProfileCommissionSettingsRoute = '/users/account/profile/commission_settings';
export const usersCommissionSettingsRoute = '/users/commission_settings';
export const usersPlatformCommissionRoute = id => `/users/${id}/platform_commission`;
export const usersClinicCommissionRoute = id => `/users/${id}/clinic_commission`;
export const doctorProfileRoute = slug => `/users/doctors/${slug}`;

// ---Password---
export const passwordStrengthCheckRoute = '/password/strength_check';

// ---Session---
export const usersSessionRoute = '/users/session';
export const usersSessionRefreshRoute = '/users/session/refresh';
export const usersTwoFactorAuthenticationRoute = 'users/two_factor_authentication';
export const usersTwoFactorAuthenticationRecoveryRoute = 'users/two_factor_authentication/recovery';

// ---Timezones---
export const timezonesRoute = '/timezones';

// ---Sign Up---
export const usersInvitationsRecoveryCodesRoute = '/users/invitations/recovery_codes';
export const usersInvitationsRecoveryCodesDownloadRoute = 'users/invitations/recovery_codes/download';
export const usersInvitationsTwoFactorAuthenticationRoute = 'users/invitations/two_factor_authentication';
export const usersInvitationsRegistrationRoute = '/users/invitations/registration';

// ---Reset password---
export const usersResetPasswordRoute = '/users/reset_password';

// ---Account---
export const usersAccountAuthorizeTwoFactorAuthenticationRoute = 'users/account/authorize/two_factor_authentication';
export const usersAccountRecoveryCodesRoute = '/users/account/recovery_codes';
export const usersAccountRecoveryCodesDownloadRoute = '/users/account/recovery_codes/download';
export const usersAccountTwoFactorAuthenticationRoute = 'users/account/two_factor_authentication';
export const usersAccountPasswordRoute = 'users/account/password';
export const usersAccountEmailRoute = '/users/account/email';
export const usersNotificationSettingRoute = '/users/notification_setting';

// ---Clinic specialities---
export const clinicSpecialitiesRoute = '/clinic_specialities';
export const clinicSpecialityRoute = specialityId => `/clinic_specialities/${specialityId}`;
export const clinicSpecialitiesBulkActionsActivateRoute = '/clinic_specialities/bulk_actions/activate';
export const clinicSpecialitiesBulkActionsDeactivateRoute = '/clinic_specialities/bulk_actions/deactivate';
export const clinicSpecialitiesBulkActionsRemoveRoute = '/clinic_specialities/bulk_actions/remove';
export const virtualClinicsClinicSpecialitiesBulkActionsActivateRoute = clinicId => `/clinics/${clinicId}/clinic_specialities/bulk_actions/activate`;
export const virtualClinicsClinicSpecialitiesBulkActionsDeactivateRoute = clinicId => `/clinics/${clinicId}/clinic_specialities/bulk_actions/deactivate`;
export const virtualClinicsClinicSpecialitiesBulkActionsRemoveRoute = clinicId => `/clinics/${clinicId}/clinic_specialities/bulk_actions/remove`;

// ---Appointments---
export const appointmentsRoute = '/appointments';
export const appointmentRoute = appointmentId => `/appointments/${appointmentId}`;
export const appointmentNotesRoute = appointmentId => `/appointments/${appointmentId}/notes`;
export const appointmentNoteRoute = noteId => `/appointments/notes/${noteId}`;
export const appointmentNoteDraftRoute = appointmentId => `/appointments/${appointmentId}/note_draft`;
export const appointmentCancelRoute = appointmentId => `/appointments/${appointmentId}/cancel`;
export const downloadAppointmentsRoute = '/appointments/download';

// ---Questionnaire---
export const questionnaireResponsesRoute = questionnaireResponsesId => `/questionnaire_responses/${questionnaireResponsesId}`;

// ---Patients---
export const patientsRoute = '/patients';
export const patientsDownloadCsvRoute = '/patients/download_csv';
export const patientsAccountProfileRoute = '/patients/account/profile';
export const patientsAccountVoucherTopupRoute = '/patients/account/voucher_topup';
export const patientsAccountPagoparTopupsPrepareRoute = '/patients/account/pagopar_topups/prepare';
export const patientRoute = patientId => `/patients/${patientId}`;
export const patientChildProfileRoute = patientId => `/po_child_profiles/${patientId}`;
export const patientsActivateRoute = patientId => `/patients/${patientId}/unban`;
export const patientsBanRoute = patientId => `patients/${patientId}/ban`;
export const patientsAppointmentsRoute = patientId => `/patients/${patientId}/appointments`;
export const patientNotesRoute = patientId => `/patients/${patientId}/notes`;
export const patientsAccountConfirmationRoute = '/patients/account/confirmation';

// ---Patients session---
export const patientsSessionRoute = '/patients/session';
export const patientsSessionCreateByOneTimeTokenRoute = '/patients/session/create_by_one_time_token';
export const patientsSessionRefreshRoute = '/patients/session/refresh';

// ---Patients reset password---
export const patientsResetPasswordRoute = '/patients/reset_password';

// ---Symptoms---
export const symptomsRoute = '/symptoms';
export const symptomRoute = symptomId => `/symptoms/${symptomId}`;
export const symptomImageRoute = symptomId => `/symptoms/${symptomId}/image`;
export const symptomsBulkActionsActivateRoute = '/symptoms/bulk_actions/activate';
export const symptomsBulkActionsDeactivateRoute = '/symptoms/bulk_actions/deactivate';
export const clinicsSymptomsRoute = clinicId => `/clinics/${clinicId}/symptoms`;
export const symptomQuestionnaireRoute = symptomId => `/symptoms/${symptomId}/questionnaire`;
export const symptomCompleteRoute = symptomId => `/symptoms/${symptomId}/complete`;
export const symptomSpecialitiesRoute = symptomId => `/symptoms/${symptomId}/specialities`;

// ---Web sockets---
export const webSocketsAuthenticateRoute = '/web_sockets/authenticate';

// ---Chats---
export const chatMessagesRoute = chatId => `/chats/${chatId}/messages`;
export const chatMessagesMessageRoute = messageId => `/chats/messages/${messageId}`;
export const chatsMessagesReadRoute = chatId => `/chats/${chatId}/messages/read`;
export const chatsAttachmentsRoute = chatId => `/chats/${chatId}/attachments`;
export const chatsAttachmentRoute = (chatId, attachmentId) => `/chats/${chatId}/attachments/${attachmentId}`;

// ---Transactions---
export const transactionsRoute = '/transactions';

// ---Specialities---
export const specialitiesRoute = '/specialities';
export const specialityRoute = specialityId => `/specialities/${specialityId}`;
export const specialitySymptomsRoute = specialityId => `/specialities/${specialityId}/symptoms`;
export const specialitiesBulkActionsActivateRoute = '/specialities/bulk_actions/activate';
export const specialitiesBulkActionsDeactivateRoute = '/specialities/bulk_actions/deactivate';
export const specialitiesBulkActionsRemoveRoute = '/specialities/bulk_actions/remove';

// ---Vouchers---
export const vouchersRoute = '/vouchers';
export const voucherRoute = voucherId => `/vouchers/${voucherId}`;
export const voucherBatchesRoute = '/voucher_batches';
export const voucherBatchRoute = voucherBatchId => `/voucher_batches/${voucherBatchId}`;
export const vouchersBulkActionsRoute = '/vouchers/bulk_actions';
export const vouchersBulkActionsRemoveRoute = '/vouchers/bulk_actions/remove';
export const vouchersBulkActionsDownloadRoute = '/vouchers/bulk_actions/download';

// ---Revenue---
export const revenueRoute = '/revenue';
export const revenueExtraStatisticRoute = '/revenue/extra_statistic';
export const revenueDownloadRoute = '/revenue/download';
export const revenueIndependentDoctorsAppointmentsDownloadRoute = '/revenue/independent_doctors/appointments/download';
export const revenueIndependentDoctorsUsersDownloadRoute = '/revenue/independent_doctors/users/download';
export const revenueIndependentDoctorsUsersAppointmentsDownloadRoute = userId => `/revenue/independent_doctors/users/${userId}/appointments/download`;
export const revenueVirtualClinicsRoute = '/revenue/virtual_clinics';
export const revenueVirtualClinicsUsersRoute = clinicId => `/revenue/virtual_clinics/${clinicId}/users/preview`;
export const revenueVirtualClinicsDownloadRoute = '/revenue/virtual_clinics/download';
export const revenueVirtualClinicsUsersDownloadRoute = clinicId => `/revenue/virtual_clinics/${clinicId}/users/download`;
export const revenueVirtualClinicsAppointmentsDownloadRoute = clinicId => `/revenue/virtual_clinics/${clinicId}/appointments/download`;
export const revenueVirtualClinicsUsersAppointmentsDownloadRoute = userId => `/revenue/virtual_clinics/users/${userId}/appointments/download`;

// ---Diseases---
export const diseasesRoute = '/diseases';
export const diseasesSearchRoute = '/diseases/search';
export const commonDiseasesRoute = '/diseases/common_diseases';
export const completeCancelDiseasesRoute = '/diseases/complete_cancel_diseases';

// ---Notes---
export const noteRoute = noteId => `/notes/${noteId}`;

// ---Notifications---
export const notificationsRoute = '/notifications';
export const notificationRoute = notificationId => `/notifications/${notificationId}`;
export const notificationsReadRoute = notificationId => `/notifications/${notificationId}/read`;
export const notificationsUnreadRoute = notificationId => `/notifications/${notificationId}/unread`;
export const notificationsBulkActionsReadRoute = '/notifications/read';
export const notificationSettingRoute = '/notification_setting';

// ---Video conference---
export const joinVideoConferenceRoute = appointmentId => `/appointments/${appointmentId}/video_conference/join`;

// ---Virtual clinics---
export const clinicRoute = clinicId => `/clinics/${clinicId}`;
export const clinicUpdateLogoRoute = clinicId => `/clinics/${clinicId}/update_logo`;
export const clinicDestroyLogoRoute = clinicId => `/clinics/${clinicId}/destroy_logo`;
export const clinicAcceptExtraFreeVisitsRoute = clinicId => `/clinics/${clinicId}/accept_extra_free_visits`;
export const virtualClinicUsersRoute = '/virtual_clinics/users';
export const virtualClinicUserRoute = vcdId => `/virtual_clinics/users/${vcdId}`;
export const virtualClinicUsersInvitationsRoute = vcdId => `/virtual_clinics/users/${vcdId}/invitations`;
export const virtualClinicsUserInvitationsResendRoute = id => `/virtual_clinics/users/${id}/invitations/resend`;
export const virtualClinicsDeactivateRoute = clinicId => `/virtual_clinics/${clinicId}/deactivate`;
export const virtualClinicsActivateRoute = clinicId => `/virtual_clinics/${clinicId}/activate`;
export const virtualClinicsCommissionSettingsRoute = '/virtual_clinics/commission_settings';
export const virtualClinicsClinicSpecialitiesRoute = clinicId => `/virtual_clinics/${clinicId}/clinic_specialities`;
export const virtualClinicsClinicSpecialityRoute = (clinicId, clinicSpecialityId) => `/virtual_clinics/${clinicId}/clinic_specialities/${clinicSpecialityId}`;
export const virtualClinicsClinicSpecialitiesProvidersRoute = clinicSpecialityId => `/virtual_clinics/clinic_specialities/providers/${clinicSpecialityId}`;
export const virtualClinicsSpecialitiesManageRoute = '/virtual_clinics/providers/clinic_specialities/manage';

// ---Virtual clinics (patient)---
export const virtualClinicPatientProfilesAppointmentsRoute = patientId => `/virtual_clinics/patient_profiles/${patientId}/appointments`;

// ---VCD---
export const virtualClinicDoctorsJoinRequestRoute = '/virtual_clinic_doctors/join_requests';

// ---Loggers---
export const frontendLogsRoute = '/front_end_logs';

// ---Push notifications---
export const pushNotificationsRoute = '/push_notifications';
export const pushNotificationClinicsRoute = '/push_notifications/clinics';
export const validatePushNotificationsRoute = '/push_notifications/validate';

// ---Extra free visits---
export const extraFreeVisitsRoute = '/extra_free_visits';
export const extraFreeVisitRoute = freeVisitId => `/extra_free_visits/${freeVisitId}`;
export const extraFreeVisitsClinicsRoute = '/extra_free_visits/clinics';

// ---Dashboard---
export const dashboardsRoute = '/dashboards';
export const validateDashboardsRoute = '/dashboards/validate';
