import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchNotifications = (pageParams = {}) => ({
  type: types.FETCH_NOTIFICATIONS,
  pageParams,
});

export const receiveNotification = notification => ({
  type: types.RECEIVE_NOTIFICATION,
  notification,
});

export const setNotificationIds = notificationIds => ({
  type: types.SET_NOTIFICATION_IDS,
  notificationIds,
});

export const setUnreadNotificationsCount = unreadCount => ({
  type: types.SET_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
});

export const incUnreadNotificationsCount = unreadCount => ({
  type: types.INC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
});

export const decUnreadNotificationsCount = unreadCount => ({
  type: types.DEC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
});

export const setNotificationPanelStatus = status => ({
  type: types.SET_NOTIFICATION_PANEL_STATUS,
  status,
});

export const markNotificationAsRead = notificationId => ({
  type: types.MARK_NOTIFICATION_AS_READ,
  notificationId,
});

export const markNotificationAsUnread = notificationId => ({
  type: types.MARK_NOTIFICATION_AS_UNREAD,
  notificationId,
});

export const markNotificationsAsRead = () => ({
  type: types.MARK_NOTIFICATIONS_AS_READ,
});

export const removeNotification = notificationId => ({
  type: types.REMOVE_NOTIFICATION,
  notificationId,
});

export const removeNotificationFromPanel = notificationId => ({
  type: types.REMOVE_NOTIFICATION_FROM_PANEL,
  notificationId,
});

export const undoRemovingNotificationFromPanel = notificationId => ({
  type: types.UNDO_REMOVING_NOTIFICATION_FROM_PANEL,
  notificationId,
});

export const fetchNotificationSettings = (token) => ({
  type: types.FETCH_NOTIFICATION_SETTINGS,
  token,
});

export const updateNotificationSettings = makeFormSubmitAction(types.UPDATE_NOTIFICATION_SETTINGS);
