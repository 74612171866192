import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { activateVirtualClinicEndpoint } from '../endpoints';
import { ACTIVATE_VIRTUAL_CLINIC } from '../types';

const activateVirtualClinicOperation = createLogic({
  type: ACTIVATE_VIRTUAL_CLINIC,
  latest: true,

  async process({ httpClient, getState, action: { virtualClinicId, clinicId } }, dispatch, done) {
    const { url, endpoint } = activateVirtualClinicEndpoint(clinicId);

    try {
      dispatch(dataApiRequest({ endpoint }));
      await httpClient.put(url);
      const state = getState();

      const response = updateDataHelper(
        state.data,
        virtualClinicId ? 'userProfile' : 'clinic',
        virtualClinicId || clinicId,
        { attributes: { active: true } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.virtualClinicActivated' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.virtualClinicActivatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default activateVirtualClinicOperation;
