import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'formik';

import profileName from 'utils/profileName';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';
import SvgIcon from 'views/shared/SvgIcon';
import ClinicSpecialitiesProvider from 'views/shared/ClinicSpecialitiesProvider';

const VCDAssignClinicSpecialitiesModalComponent = ({
  canSubmit,
  onClose,
  handleSubmit,
  doctor,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__8"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage id="doctors.assignClinicSpecialities.modal.title" />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      {!canSubmit && (
        <Alert message={{ id: 'doctors.assignClinicSpecialities.modal.maximumAvailableSpecialitiesForDoctor' }} />
      )}
      <p className="ant-modal__body-text">
        <FormattedMessage id="doctors.assignClinicSpecialities.modal.selectSpecialityToAssignTo" />
      </p>
      <ul className="ant-modal__body-selected-list">
        <li className="ant-modal__body-selected-list-item">
          {profileName(doctor)}
        </li>
      </ul>
      <div>
        <FieldArray
          name="clinicSpecialities"
          component={ClinicSpecialitiesProvider}
        />
      </div>
    </div>
    <div className="ant-modal__footer d-block">
      <div className="d-flex justify-content-end">
        <Button
          text={{ id: 'shared.cancel' }}
          kind="tertiary"
          onClick={onClose}
        />
        <SubmitFormButton
          text={{ id: 'shared.assign' }}
          onClick={handleSubmit}
          disabled={!canSubmit}
        />
      </div>
    </div>
  </Modal>
);

VCDAssignClinicSpecialitiesModalComponent.defaultProps = {
  canSubmit: false,
};

VCDAssignClinicSpecialitiesModalComponent.propTypes = {
  canSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doctor: PropTypes.shape().isRequired,
};

export default VCDAssignClinicSpecialitiesModalComponent;
