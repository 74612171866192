import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose, pluck, flatten } from 'ramda';

import { MAX_DOCTOR_SPECIALITIES } from 'constants/doctors';

import validationSchema from 'lib/yupLocalised/schemas/virtualClinicDoctorAssignClinicSpecialitiesProvider';

import { handleSubmit } from 'utils/form/handleSubmit';
import { getClinicSpecialities } from 'utils/clinicSpecialities/getClinicSpecialities';

import {
  doctorAssignClinicSpecialitiesInitialState as doctorAssignClinicSpecialitiesInitialStateAction,
  doctorAssignClinicSpecialitiesResetState as doctorAssignClinicSpecialitiesResetStateAction,
} from 'state/concepts/userProfile/doctors/actions';
import { submitManageSpeciality } from 'state/concepts/userProfile/virtualClinicDoctors/actions';

import VCDAssignClinicSpecialitiesModalComponent from './component';

class VCDAssignClinicSpecialitiesModal extends React.Component {
  static propTypes = {
    doctorAssignClinicSpecialitiesInitialState: PropTypes.func.isRequired,
    doctorAssignClinicSpecialitiesResetState: PropTypes.func.isRequired,
    doctor: PropTypes.shape().isRequired,
    values: PropTypes.shape().isRequired,
  }

  get canSubmit() {
    const { values: { clinicSpecialities } } = this.props;

    return flatten(pluck('clinicSpecialityIds', clinicSpecialities)).length <= MAX_DOCTOR_SPECIALITIES;
  }

  componentDidMount() {
    const { doctor, doctorAssignClinicSpecialitiesInitialState } = this.props;

    doctorAssignClinicSpecialitiesInitialState(doctor);
  }

  componentWillUnmount() {
    const { doctorAssignClinicSpecialitiesResetState } = this.props;

    doctorAssignClinicSpecialitiesResetState();
  }

  render() {
    return (
      <VCDAssignClinicSpecialitiesModalComponent
        {...this.props}
        canSubmit={this.canSubmit}
      />
    );
  }
}

const mapDispatchToProps = {
  onSubmit: submitManageSpeciality,
  doctorAssignClinicSpecialitiesInitialState: doctorAssignClinicSpecialitiesInitialStateAction,
  doctorAssignClinicSpecialitiesResetState: doctorAssignClinicSpecialitiesResetStateAction,
};

export { VCDAssignClinicSpecialitiesModal as VCDAssignClinicSpecialitiesModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ doctor }) => ({
      userId: doctor.vcdId,
      clinicSpecialities: getClinicSpecialities(doctor),
    }),
    validationSchema,
    handleSubmit,
  }),
)(VCDAssignClinicSpecialitiesModal);
