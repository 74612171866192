import { createLogic } from 'redux-logic';

import { isDoctor } from 'utils/roles';

import { fetchDoctorsCommissions, fetchVirtualClinicsCommissions } from '../actions';
import { FETCH_COMMISSIONS } from '../types';

const fetchCommissionsOperation = createLogic({
  type: FETCH_COMMISSIONS,
  latest: true,

  async process({ action: { roleName } }, dispatch, done) {
    if (isDoctor(roleName)) {
      dispatch(fetchDoctorsCommissions(roleName));
    } else {
      dispatch(fetchVirtualClinicsCommissions());
    }

    done();
  },
});

export default fetchCommissionsOperation;
