import endpoint from 'utils/endpoint';

import {
  usersResetPasswordRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const validateResetPasswordTokenEndpoint = endpoint(types.VALIDATE_RESET_PASSWORD_TOKEN, 'GET', usersResetPasswordRoute);
export const submitResetPasswordEndpoint = endpoint(types.SUBMIT_RESET_PASSWORD, 'POST', usersResetPasswordRoute);
export const createNewPasswordEndpoint = endpoint(types.SUBMIT_NEW_PASSWORD, 'PUT', usersResetPasswordRoute);
