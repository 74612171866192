import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import isOnboardingCompleted from 'utils/users/isOnboardingCompleted';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { userLoginSuccess } from 'state/concepts/session/actions';
import { getUserMyselfEndpoint } from '../endpoints';
import { GET_USER_MYSELF } from '../types';

const getUserMyselfOperation = createLogic({
  type: GET_USER_MYSELF,
  latest: true,

  async process({ httpClient, action: { response } }, dispatch, done) {
    const { endpoint, url } = getUserMyselfEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);

      const normalizedData = normalize(data);
      if (!isOnboardingCompleted(data)) {
        redirect({ href: '/onboarding', response });
      }
      dispatch(userLoginSuccess(data.data.id));
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getUserMyselfOperation;
