import endpoint from 'utils/endpoint';

import {
  notificationsRoute,
  notificationRoute,
  notificationsReadRoute,
  notificationsUnreadRoute,
  notificationsBulkActionsReadRoute,
  notificationSettingRoute,
} from 'lib/apiRoutes';

import * as types from './types';

export const fetchNotificationsEndpoint = endpoint(types.FETCH_NOTIFICATIONS, 'GET', notificationsRoute);
export const markNotificationAsReadEndpoint = notificationId => (
  endpoint(types.MARK_NOTIFICATION_AS_READ, 'POST', notificationsReadRoute(notificationId))
);
export const markNotificationAsUnreadEndpoint = notificationId => (
  endpoint(types.MARK_NOTIFICATION_AS_UNREAD, 'POST', notificationsUnreadRoute(notificationId))
);
export const markNotificationsAsReadEndpoint = endpoint(types.MARK_NOTIFICATIONS_AS_READ, 'POST', notificationsBulkActionsReadRoute);
export const removeNotificationEndpoint = notificationId => endpoint(types.REMOVE_NOTIFICATION, 'DELETE', notificationRoute(notificationId));
export const fetchNotificationSettingEndpoint = endpoint(types.FETCH_NOTIFICATION_SETTINGS, 'GET', notificationSettingRoute);
export const updateNotificationSettingEndpoint = endpoint(types.UPDATE_NOTIFICATION_SETTINGS, 'PUT', notificationSettingRoute);
