import { createLogic } from 'redux-logic';
import cookies from 'component-cookie';

import redirect from 'utils/redirect';
import removeUserCookies from 'utils/removeUserCookies';

import { userLogoutEndpoint } from '../endpoints';
import { USER_LOGOUT } from '../types';

const userLogoutOperation = createLogic({
  type: USER_LOGOUT,
  latest: true,

  async process({ rootHttpClient }, dispatch, done) {
    const { url } = userLogoutEndpoint;
    const refreshToken = JSON.parse(cookies('tokens')).refresh;

    try {
      await rootHttpClient.delete(url, { headers: { 'X-Refresh-Token': refreshToken } });

      removeUserCookies();
    // eslint-disable-next-line no-empty
    } catch {}

    redirect({ href: '/login' });

    done();
  },
});

export default userLogoutOperation;
