export const GENDERS = {
  all: 'all',
  male: 'male',
  female: 'female',
  other: 'other',
};
export const CHILDREN = {
  all: 'all',
  yes: 'yes',
  no: 'no',
};

export const MAX_DISPLAYED_MESSAGE_LENGTH = 58;
export const MAX_PUSH_NOTIFICATION_LENGTH = 255;
export const MIN_AGE_VALUE = 0;
export const MAX_AGE_VALUE = 150;
export const GENDER_OPTIONS = [
  { label: { id: 'shared.all' }, value: GENDERS.all, key: GENDERS.all },
  { label: { id: 'shared.male' }, value: GENDERS.male, key: GENDERS.male },
  { label: { id: 'shared.female' }, value: GENDERS.female, key: GENDERS.female },
  { label: { id: 'shared.other' }, value: GENDERS.other, key: GENDERS.other },
];
export const CHILDREN_OPTIONS = [
  { label: { id: 'shared.all' }, value: CHILDREN.all, key: GENDERS.all },
  { label: { id: 'shared.yes' }, value: CHILDREN.yes, key: CHILDREN.yes },
  { label: { id: 'shared.no' }, value: CHILDREN.no, key: CHILDREN.no },
];
