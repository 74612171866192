import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { isEmpty, prop } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import redirect from 'utils/redirect';
import updateDataHelper from 'utils/updateDataHelper';
import hasNoteCodeForCancelAppointment from 'utils/hasNoteCodeForCancelAppointment';
import { parseValuesFormToParams } from 'utils/note';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import {
  setNotePanelVisible,
  setNotificationCancelConference,
} from 'state/concepts/userProfile/appointment/actions';
import {
  appointmentSelector,
  notePanelVisibleSelector,
} from 'state/concepts/userProfile/appointment/selectors';
import {
  submitAddNoteEndpoint,
  updateNoteEndpoint,
} from 'state/concepts/userProfile/appointment/endpoints';
import { SUBMIT_ADD_NOTE } from 'state/concepts/userProfile/appointment/types';

const submitAddNoteOperation = createLogic({
  type: SUBMIT_ADD_NOTE,
  latest: true,

  async process(
    { action: { values, form, isVideoConference, ...rest }, getState, httpClient },
    dispatch,
    done,
  ) {
    const state = getState();
    const {
      id: appointmentId,
      patientProfile: { id: patientProfileId },
      note,
    } = appointmentSelector(state);
    const notePanelVisible = notePanelVisibleSelector(state);
    const currentEndpoint = isPresent(note)
      ? updateNoteEndpoint(prop('id', note))
      : submitAddNoteEndpoint(appointmentId);

    const { url, endpoint } = currentEndpoint;

    const params = {
      chief_compliant: values.chiefCompliant,
      subjective: values.subjective,
      objective: values.objective,
      assessment: values.assessment,
      primary_disease_id: values.primaryDisease.id,
      disease_ids: values.diseases.map(({ id }) => (!isEmpty(id) ? id : null)),
      plan: values.plan,
      prescriptions: parseValuesFormToParams(values.prescriptions),
      auxiliary_studies: values.auxiliaryStudies,
      secret_note: values.secretNote,
      appointment_id: appointmentId,
      patient_profile_id: patientProfileId,
      sick_note: values.sickNote,
      include:
        'patient-profile,appointment,prescriptions,primary_disease,diseases,auxiliary_studies',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      if (isPresent(note)) {
        const { data } = await httpClient.put(url, params);
        const normalizeData = normalize(data);

        dispatch(setNotePanelVisible(!notePanelVisible));
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.updateNoteSuccessfully' },
          }),
        );

        dispatch(dataApiSuccess({ response: normalizeData }));
      } else {
        const { data } = await httpClient.post(url, params);
        const normalizeData = normalize(data);

        dispatch(dataApiSuccess({ response: normalizeData, endpoint }));

        const relationships = {
          note: {
            data: {
              id: data.data.id,
              type: 'note',
            },
          },
        };

        const response = updateDataHelper(state.data, 'appointment', appointmentId, {
          relationships,
        });

        dispatch(dataApiSuccess({ response }));

        dispatch(setNotePanelVisible(!notePanelVisible));
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.addNoteSuccessfully' },
          }),
        );
      }

      if (hasNoteCodeForCancelAppointment(rest.note) && isVideoConference) {
        dispatch(setNotificationCancelConference(true));
        redirect({ href: `/appointments/${appointmentId}` });
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitAddNoteOperation;
