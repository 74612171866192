import { DateTime } from 'luxon';
import {
 propEq, path, prop, includes,
} from 'ramda';

import { MILLISECONDS_PER_SECOND, SECONDS_PER_MINUTE } from 'constants/timeUnits.js';
import { APPOINTMENT_STATUSES } from 'constants/appointments';

export const isCancelled = propEq('status', APPOINTMENT_STATUSES.cancelled);
export const isCompleted = propEq('status', APPOINTMENT_STATUSES.completed);
export const isScheduled = propEq('status', APPOINTMENT_STATUSES.scheduled);
export const isRescheduled = propEq('status', APPOINTMENT_STATUSES.rescheduled);
export const isWaitingForNote = propEq('status', APPOINTMENT_STATUSES.waiting_for_note);
export const isInProgress = propEq('status', APPOINTMENT_STATUSES.in_progress);
export const isPending = ({ status }) => (
  includes(status, [
    APPOINTMENT_STATUSES.rescheduled,
    APPOINTMENT_STATUSES.rescheduleRequested,
  ])
);

export const isRescheduleReason = ({ status }) => (
  includes(status, [
    APPOINTMENT_STATUSES.cancelled,
    APPOINTMENT_STATUSES.completed,
  ])
);
export const isCancellationReason = ({ status }) => (
  includes(status, [
    APPOINTMENT_STATUSES.cancelled,
    APPOINTMENT_STATUSES.completed,
  ])
);

export const statusBadge = prop('status');
export const showBadge = ({ status }) => (
  !includes(status, [
    APPOINTMENT_STATUSES.scheduled,
    APPOINTMENT_STATUSES.completed,
  ])
);

export const formattedStartTime = ({ startTime }) => DateTime.fromISO(startTime).toFormat('t');
export const formattedEndTime = ({ endTime }) => DateTime.fromISO(endTime).toFormat('t');
export const formattedTime = event => `${formattedStartTime(event)} - ${formattedEndTime(event)}`;
export const formattedWeekday = ({ startTime }) => DateTime.fromISO(startTime).toFormat('DDDD');
export const formattedDateTime = event => `${formattedTime(event)}, ${formattedWeekday(event)}`;
export const formattedShortDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('EEE, DDD');
export const formattedShortestDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('EEE, DD');
export const formattedShortestDateWithTime = (time) => DateTime.fromISO(time).toFormat('EEE, MMM M, t');
export const formattedShortestDateTime = event => `${formattedTime(event)}, ${formattedShortestDate(event)}`;

export const formattedTimeWithDuration = (newStartTime, { startTime, endTime }) => (
  DateTime.fromISO(newStartTime).plus(DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime), ['hours', 'minutes']))
);
export const duration = ({ startTime, endTime }) => (
  DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime))
);
export const formattedDuration = event => (
  duration(event).toFormat('h\'h\' m\'m\'')
);
export const isLessThan = (event, minutes) => (
  duration(event).milliseconds / MILLISECONDS_PER_SECOND / SECONDS_PER_MINUTE < minutes
);
export const getColor = path(['speciality', 'color']);
