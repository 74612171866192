import { createSelector } from 'reselect';
import build from 'redux-object';
import {
  path, prop, intersection, equals, any, propEq, map, compose,
} from 'ramda';

import buildCollection from 'utils/buildCollection';

import { endpointMetaSelector, totalCountSelector, pageCountSelector, loadingSelector } from 'state/data/selectors';

import {
  fetchSpecialitiesEndpoint,
  activateSpecialitiesEndpoint,
  deactivateSpecialitiesEndpoint,
  removeSpecialitiesEndpoint,
  getSpecialitySymptomsEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['specialities', 'isBlankState']);
export const specialityIdsSelector = path(['specialities', 'specialityIds']);
export const specialitySymptomsIdsSelector = path(['specialities', 'symptomsIds']);
export const paginationSelector = path(['specialities', 'pagination']);
export const sortSelector = path(['specialities', 'sort']);
export const selectedSelector = path(['specialities', 'selectedSpecialities']);
export const specialitiesListIdsSelector = path(['specialities', 'specialitiesListIds']);
export const specialitiesListStatusesSelector = path(['specialities', 'specialitiesListStatuses']);
export const specialitiesListFilterParamsSelector = path(['specialities', 'specialitiesListFilterParams']);

export const specialitiesSelector = createSelector(
  specialityIdsSelector,
  dataSelector,
  buildCollection('speciality'),
);

export const specialitySelector = createSelector(
  (_, specialityId) => specialityId,
  dataSelector,
  (specialityId, data) => build(data, 'speciality', specialityId),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const isSelectedSelector = createSelector(
  selectedSelector,
  (_, specialityId) => specialityId,
  (selectedSpecialities, specialityId) => selectedSpecialities.includes(specialityId),
);

const selectedOnPageSelector = createSelector(
  selectedSelector,
  specialityIdsSelector,
  intersection,
);

export const isAllSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  specialityIdsSelector,
  (selectedOnPage, specialityIds) => equals(selectedOnPage.length, specialityIds.length),
);

export const hasSelectedOnPageSelector = createSelector(
  selectedOnPageSelector,
  selectedOnPage => selectedOnPage.length > 0,
);

export const selectedSpecialitiesSelector = createSelector(
  selectedSelector,
  dataSelector,
  buildCollection('speciality'),
);

export const isSpecificSpecialitiesSelected = createSelector(
  selectedSpecialitiesSelector,
  (_, key) => key,
  (specialities, key) => any(propEq('status', key), specialities),
);

export const specialitySymptomsForFilterSelector = createSelector(
  specialitySymptomsIdsSelector,
  dataSelector,
  compose(
    map(({ id, name }) => ({ id, key: id, value: id, label: name })),
    buildCollection('symptom'),
  ),
);

export const specialitiesTotalCountSelector = state => (
  totalCountSelector(state, fetchSpecialitiesEndpoint.endpoint)
);

export const specialitiesPageCountSelector = state => (
  pageCountSelector(state, fetchSpecialitiesEndpoint.endpoint)
);

export const specialitiesLoadingSelector = state => (
  loadingSelector(state, fetchSpecialitiesEndpoint.endpoint)
);

export const activateSpecialitiesLoadingSelector = state => (
  loadingSelector(state, activateSpecialitiesEndpoint.endpoint)
);

export const deactivateSpecialitiesLoadingSelector = state => (
  loadingSelector(state, deactivateSpecialitiesEndpoint.endpoint)
);

export const removeSpecialitiesLoadingSelector = state => (
  loadingSelector(state, removeSpecialitiesEndpoint.endpoint)
);

export const specialitySymptomsLoadingSelector = (state, specialityId) => (
  loadingSelector(state, getSpecialitySymptomsEndpoint(specialityId).endpoint)
);

export const specialitiesListSelector = createSelector(
  specialitiesListIdsSelector,
  dataSelector,
  buildCollection('speciality'),
);

export const specialitiesListForFilterSelector = createSelector(
  specialitiesListSelector,
  map(({ id, name }) => ({ id, key: id, value: id, label: name })),
);

export const excludeSpecialitiesListIdsSelector = createSelector(
  specialitiesListFilterParamsSelector,
  (({ excludeSpecialityIds }) => excludeSpecialityIds),
);

export const specialitiesListLoadingSelector = state => (
  loadingSelector(state, fetchSpecialitiesEndpoint.endpoint)
);

export const specialitiesListMetaSelector = state => (
  endpointMetaSelector(state, fetchSpecialitiesEndpoint.endpoint)
);
