import { prop, propOr } from 'ramda';

export const parseData = data => prop('message', JSON.parse(data));

export const parseChannel = (data) => {
  const parsedData = JSON.parse(data);
  const identifier = propOr('{}', 'identifier', parsedData);

  return prop('channel', JSON.parse(identifier));
};

export const toCommand = ({ command, channel, action, params }) => JSON.stringify({
  command,
  data: JSON.stringify({ action }),
  identifier: JSON.stringify({ channel, ...params }),
});
