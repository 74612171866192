import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { setBlankState, setJoinRequestIds } from '../actions';
import { paginationSelector } from '../selectors';
import { fetchJoinRequestsEndpoint } from '../endpoints';
import { FETCH_JOIN_REQUESTS } from '../types';

const fetchJoinRequestsOperation = createLogic({
  type: FETCH_JOIN_REQUESTS,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const { endpoint, url } = fetchJoinRequestsEndpoint;
    const state = getState();
    const params = {
      include: 'cv,clinic',
      page: paginationSelector(state),
      filter: {
        ...commonFiltersSelector(state),
        roles: [action.roleName],
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });

      const response = normalize(data, { endpoint });
      dispatch(setBlankState(isBlankState(params.filter, data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setJoinRequestIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchJoinRequestsOperation;
