import { createLogic } from 'redux-logic';
import { propEq } from 'ramda';

import { TUTORIAL_STEPS } from 'constants/userTutorial';

import requestErrorHandler from 'lib/requestErrorHandler';

import isPresent from 'utils/isPresent';
import formatISOPhoneNumber from 'utils/formatISOPhoneNumber';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { skipStep } from 'state/concepts/userProfile/tutorial/actions';
import { currentUserTutorialSelector } from 'state/concepts/session/selectors';
import { updateTutorialStepInviteVirtualClinicDoctorEndpoint } from 'state/concepts/userProfile/tutorial/endpoints';

import { fetchVirtualClinicDoctors, setCurrentPage } from '../actions';
import { submitVirtualClinicDoctorJoinRequestEndpoint } from '../endpoints';
import { SUBMIT_VCD_JOIN_REQUEST } from '../types';

const submitVCDJoinRequestOperation = createLogic({
  type: SUBMIT_VCD_JOIN_REQUEST,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const tutorial = currentUserTutorialSelector(state);
    const params = new FormData();
    params.append('first_name', values.firstName);
    params.append('last_name', values.lastName);
    params.append('email', values.email);
    params.append('phone', formatISOPhoneNumber(values.phone));
    params.append('cv', values.cv);

    const { url, endpoint } = submitVirtualClinicDoctorJoinRequestEndpoint;

    dispatch(dataApiRequest({ endpoint }));

    try {
      await httpClient.post(url, params);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'vcd.joinRequest.invitationHasBeenSent' } }));
      dispatch(setCurrentPage(1));
      dispatch(fetchVirtualClinicDoctors());
      if (
        isPresent(tutorial)
        && propEq(TUTORIAL_STEPS.clinicSpecialities, true, tutorial)
        && propEq(TUTORIAL_STEPS.inviteVirtualClinicDoctor, false, tutorial)
      ) {
        dispatch(skipStep(
          TUTORIAL_STEPS.inviteVirtualClinicDoctor,
          updateTutorialStepInviteVirtualClinicDoctorEndpoint,
        ));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitVCDJoinRequestOperation;
