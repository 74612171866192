import { prop, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import build from 'redux-object';

const dataSelector = prop('data');
const searchResultsSelector = prop('searchResults');

// eslint-disable-next-line import/prefer-default-export
export const makeSelectSearchResults = (kind, path) => createSelector(
  searchResultsSelector,
  dataSelector,
  (searchResults, data) => (
    searchResults[kind] && !isEmpty(searchResults[kind]) && path
      ? build(data, path, searchResults[kind])
      : []
  ),
);
