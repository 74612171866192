import React from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from 'constants';

import yup from 'lib/yupLocalised';
import { handleSubmitWithProps } from 'utils/form/handleSubmit';

import { deactivateUser } from 'state/concepts/userProfile/users/actions';
import { deactivateUserLoadingSelector } from 'state/concepts/userProfile/users/selectors';

import DeactivateAccountModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class DeactivateAccountModal extends React.Component {
  render = () => (
    <DeactivateAccountModalComponent
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: deactivateUserLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: deactivateUser,
};

export { DeactivateAccountModal as DeactivateAccountModalContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({ password: '' }),
    validationSchema: yup.object().shape({
      password: yup.string().min(MIN_PASSWORD_LENGTH).max(MAX_PASSWORD_LENGTH).required(),
    }),
    handleSubmit: handleSubmitWithProps(['userId']),
  }),
)(DeactivateAccountModal);
