import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { getSpecialityEndpoint } from '../endpoints';
import { GET_SPECIALITY } from '../types';

const getSpecialityOperation = createLogic({
  type: GET_SPECIALITY,
  latest: true,

  async process({ httpClient, action: { specialityId } }, dispatch, done) {
    const { url, endpoint } = getSpecialityEndpoint(specialityId);

    const params = {
      include: 'symptoms',
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getSpecialityOperation;
