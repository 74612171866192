import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setSpecialitySymptomsIds } from '../actions';
import { getSpecialitySymptomsEndpoint } from '../endpoints';
import { GET_SPECIALITY_SYMPTOMS } from '../types';

const getSpecialitySymptomsOperation = createLogic({
  type: GET_SPECIALITY_SYMPTOMS,
  latest: true,

  async process({ httpClient, action: { specialityId } }, dispatch, done) {
    const { url, endpoint } = getSpecialitySymptomsEndpoint(specialityId);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setSpecialitySymptomsIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default getSpecialitySymptomsOperation;
