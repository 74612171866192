import { createSelector } from 'reselect';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { totalCountSelector, loadingSelector, pageCountSelector } from 'state/data/selectors';
import {
  fetchJoinRequestsEndpoint,
  acceptJoinRequestEndpoint,
  rejectJoinRequestEndpoint,
  removeJoinRequestEndpoint,
} from './endpoints';

const dataSelector = prop('data');

export const joinRequestIdsSelector = path(['joinRequests', 'joinRequestIds']);
export const isBlankStateSelector = path(['joinRequests', 'isBlankState']);
export const paginationSelector = path(['joinRequests', 'pagination']);

export const joinRequestsSelector = createSelector(
  joinRequestIdsSelector,
  dataSelector,
  buildCollection('joinRequest'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const joinRequestsTotalCountSelector = state => (
  totalCountSelector(state, fetchJoinRequestsEndpoint.endpoint)
);

export const joinRequestsLoadingSelector = state => (
  loadingSelector(state, fetchJoinRequestsEndpoint.endpoint)
);

export const joinRequestsPageCountSelector = state => (
  pageCountSelector(state, fetchJoinRequestsEndpoint.endpoint)
);

export const acceptJoinRequestLoadingSelector = (state, id) => (
  loadingSelector(state, acceptJoinRequestEndpoint(id).endpoint)
);

export const rejectJoinRequestLoadingSelector = (state, id) => (
  loadingSelector(state, rejectJoinRequestEndpoint(id).endpoint)
);

export const removeJoinRequestLoadingSelector = (state, id) => (
  loadingSelector(state, removeJoinRequestEndpoint(id).endpoint)
);
