import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { hideModal } from 'state/modal/actions';
import { cancelAppointmentEndpoint } from '../endpoints';
import { fetchAppointments } from '../actions';
import { CANCEL_APPOINTMENTS } from '../types';

const cancelAppointmentOperation = createLogic({
  type: CANCEL_APPOINTMENTS,
  latest: true,

  async process({
    httpClient,
    action: {
      notificationShown,
      values,
      form,
    },
  }, dispatch, done) {
    const { endpoint, url } = cancelAppointmentEndpoint(values.id);

    const params = {
      cancellation_reason: values.cancellationReason,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));

      dispatch(hideModal());
      dispatch(fetchAppointments());
      if (notificationShown) {
        dispatch(showNotification({
          messageObject: { id: 'notifications.appointmentCancelled' },
        }));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
      form.setSubmitting(false);
    }

    done();
  },
});

export default cancelAppointmentOperation;
