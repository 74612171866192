import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';
import isActivePatientTabIsChildren from 'utils/isActivePatientTabIsChildren';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setBlankState, setPatientNotes } from '../actions';
import {
  paginationSelector,
  searchQuerySelector,
  sortParamsSelector,
  patientDetailsActiveTabSelector,
} from '../selectors';
import { fetchPatientNotesEndpoint } from '../endpoints';
import { FETCH_PATIENT_NOTES } from '../types';

const fetchPatientNotesOperation = createLogic({
  type: FETCH_PATIENT_NOTES,
  latest: true,

  async process({ httpClient, getState, action: { patientId } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = fetchPatientNotesEndpoint(patientId);
    const activeTab = patientDetailsActiveTabSelector(state);
    const searchQuery = searchQuerySelector(state);

    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state, 'sortNotes'),
      filter: {
        primary_diagnose_title_or_code: searchQuery,
        children: isActivePatientTabIsChildren(activeTab),
      },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }));
      dispatch(setBlankState(isBlankState({ name: searchQuery }, data.data)));
      dispatch(setPatientNotes(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchPatientNotesOperation;
