export const APPOINTMENT_STATUSES = {
  scheduled: 'scheduled',
  cancelled: 'cancelled',
  rescheduled: 'rescheduled',
  completed: 'completed',
  in_progress: 'in_progress',
  waiting_for_note: 'waiting_for_note',
};

export const APPOINTMENT_STATUSES_FILTERS = [
  { value: APPOINTMENT_STATUSES.scheduled, label: { id: 'appointment.filters.statuses.scheduled' } },
  { value: APPOINTMENT_STATUSES.rescheduled, label: { id: 'appointment.filters.statuses.rescheduled' } },
  { value: APPOINTMENT_STATUSES.completed, label: { id: 'appointment.filters.statuses.completed' } },
  { value: APPOINTMENT_STATUSES.cancelled, label: { id: 'appointment.filters.statuses.cancelled' } },
  { value: APPOINTMENT_STATUSES.waiting_for_note, label: { id: 'appointment.filters.statuses.waiting_for_note' } },
  { value: APPOINTMENT_STATUSES.in_progress, label: { id: 'appointment.filters.statuses.in_progress' } },
];

export const ACTIVE_APPOINTMENTS = [
  APPOINTMENT_STATUSES.scheduled,
  APPOINTMENT_STATUSES.rescheduled,
  APPOINTMENT_STATUSES.in_progress,
  APPOINTMENT_STATUSES.waiting_for_note,
];

export const ARCHIVED_APPOINTMENTS = [
  APPOINTMENT_STATUSES.cancelled,
  APPOINTMENT_STATUSES.completed,
];

export const AGENDA_LENGTH = 30;

export const APPOINTMENT_CALENDAR_STEP = 20;

export const APPOINTMENT_CALENDAR_TIME_SLOTS = 3;
