import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'formik';

import Modal from 'views/shared/Modal';
import SvgIcon from 'views/shared/SvgIcon';
import InputField from 'views/shared/InputField';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';

const DeactivateVirtualClinicModalComponent = ({
  virtualClinic,
  isLoading,
  canSubmit,
  onClose,
  handleSubmit,
}) => (
  <Modal className="ant-modal-wrap__4 manage-account__modal-warning" onClose={onClose}>
    <Form className="ant-modal__form">
      <div className="ant-modal__header">
        <h1 className="ant-modal__header-txt">
          <FormattedMessage id="virtualClinics.clinics.deactivateModal.title" />
        </h1>
        <button type="button" onClick={onClose}>
          <SvgIcon icon="cross" className="ant-modal__close" />
        </button>
      </div>
      <div className="ant-modal__body">
        <span className="manage-account__modal-warning-message">
          <FormattedMessage id="virtualClinics.clinics.deactivateModal.warning" />
        </span>
        <p className="manage-account__modal-txt mb-24">
          <FormattedMessage
            id="virtualClinics.clinics.deactivateModal.body"
            values={{ clinicName: <span className="f-700">{virtualClinic.clinic.name}</span> }}
          />
        </p>
        <Field
          id="clinicName"
          name="clinicName"
          component={InputField}
          label={{ id: 'virtualClinics.clinics.deactivateModal.label' }}
          fullWidth
        />
      </div>
      <div className="ant-modal__footer ant-modal__footer--right">
        <Button
          text={{ id: 'shared.cancel' }}
          onClick={onClose}
          kind="tertiary"
        />
        <SubmitFormButton
          text={{ id: 'shared.deactivate' }}
          overrideKind={['danger']}
          onClick={handleSubmit}
          disabled={isLoading || !canSubmit}
        />
      </div>
    </Form>
  </Modal>
);

DeactivateVirtualClinicModalComponent.defaultProps = {
  isLoading: undefined,
  canSubmit: false,
};

DeactivateVirtualClinicModalComponent.propTypes = {
  virtualClinic: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
  canSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default DeactivateVirtualClinicModalComponent;
