import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  isCancelled,
  isCompleted,
  isRescheduled,
  isInProgress,
  isWaitingForNote,
  statusBadge,
} from 'utils/appointments';

const AppointmentCalendarTooltipStatusBadge = ({ event, className }) => (
  <span
    className={classNames('label', className, {
      'label--error': isCancelled(event),
      'label--completed': isCompleted(event),
      'label--warning': isRescheduled(event),
      'label--new': isInProgress(event) || isWaitingForNote(event),
    })}
  >
    <FormattedMessage id={`appointments.status.${statusBadge(event)}`} />
  </span>
);

AppointmentCalendarTooltipStatusBadge.propTypes = {
  event: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

AppointmentCalendarTooltipStatusBadge.defaultProps = {
  className: undefined,
};

export default AppointmentCalendarTooltipStatusBadge;
