import endpoint from 'utils/endpoint';
import {
  usersRoute,
  userRoute,
  userAvatarRoute,
  userSignatureRoute,
  userActivateRoute,
  userDeactivateRoute,
  userInvitationsRoute,
  userInvitationsResendRoute,
  usersResetAuthenticationRoute,
  usersAccountProfileSignatureRoute,
  usersInvitationsRoute,
  usersPlatformCommissionRoute,
  usersClinicCommissionRoute,
  virtualClinicsUserInvitationsResendRoute,
} from 'lib/apiRoutes';
import * as types from './types';

export const fetchDoctorProfileEndpoint = doctorId => endpoint(types.FETCH_DOCTOR_PROFILE, 'GET', userRoute(doctorId));
export const updateDoctorProfileEndpoint = doctorId => endpoint(types.UPDATE_DOCTOR_PROFILE, 'PUT', userRoute(doctorId));
export const uploadDoctorAvatarEndpoint = doctorId => endpoint(types.UPLOAD_DOCTOR_AVATAR, 'PUT', userAvatarRoute(doctorId));
export const removeDoctorAvatarEndpoint = doctorId => endpoint(types.REMOVE_DOCTOR_AVATAR, 'DELETE', userAvatarRoute(doctorId));
export const fetchAllDoctorsEndpoint = endpoint(types.FETCH_ALL_DOCTORS, 'GET', usersRoute);
export const resendDoctorInvitationEndpoint = doctorId => endpoint(types.RESEND_DOCTOR_INVITATION, 'POST', userInvitationsResendRoute(doctorId));
export const resendVirtualDoctorInvitationEndpoint = doctorId => endpoint(types.RESEND_DOCTOR_INVITATION, 'POST', virtualClinicsUserInvitationsResendRoute(doctorId));
export const removeDoctorInvitationEndpoint = doctorId => endpoint(types.REMOVE_DOCTOR_INVITATION, 'DELETE', userInvitationsRoute(doctorId));
export const resetAuthenticationEndpoint = doctorId => endpoint(types.RESET_AUTHENTICATION, 'PUT', usersResetAuthenticationRoute(doctorId));
export const activateDoctorEndpoint = doctorId => endpoint(types.ACTIVATE_DOCTOR, 'PUT', userActivateRoute(doctorId));
export const deactivateDoctorEndpoint = doctorId => endpoint(types.DEACTIVATE_DOCTOR, 'PUT', userDeactivateRoute(doctorId));
export const uploadDoctorSignatureEndpoint = doctorId => endpoint(types.UPLOAD_DOCTOR_SIGNATURE, 'PUT', userSignatureRoute(doctorId));
export const removeDoctorSignatureEndpoint = doctorId => endpoint(types.REMOVE_DOCTOR_SIGNATURE, 'DELETE', userSignatureRoute(doctorId));
export const submitPlatformCommissionEndpoint = doctorId => endpoint(types.SUBMIT_PLATFORM_COMMISSION, 'POST', usersPlatformCommissionRoute(doctorId));
export const submitClinicCommissionEndpoint = doctorId => endpoint(types.SUBMIT_PLATFORM_COMMISSION, 'POST', usersClinicCommissionRoute(doctorId));
export const uploadUserSignatureEndpoint = endpoint(types.UPLOAD_DOCTOR_SIGNATURE, 'PUT', usersAccountProfileSignatureRoute);
export const removeUserSignatureEndpoint = endpoint(types.REMOVE_DOCTOR_SIGNATURE, 'DELETE', usersAccountProfileSignatureRoute);
export const usersInvitationsEndpoint = endpoint(types.INVITE_DOCTORS, 'POST', usersInvitationsRoute);
