import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { is } from 'ramda';

import SvgIcon from 'views/shared/SvgIcon';

const SearchListComponent = ({
  onChange, onClear, value, className, intl, placeholder,
}) => (
  <div className={classNames('input-search', className)}>
    {/* TODO: add to span class for loading - main-spinner-search icon-spinner in-blue-600 */}
    <SvgIcon icon="search" className="input-search__icon" />
    <input
      className="input-search__field"
      placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
      onChange={onChange}
      value={value}
    />
    {value && (
      <button
        type="button"
        onClick={onClear}
      >
        <SvgIcon className="input-search__icon-cross" icon="cross" />
      </button>
    )}
  </div>
);

SearchListComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  className: PropTypes.string,
  intl: PropTypes.shape().isRequired,
};

SearchListComponent.defaultProps = {
  className: '',
  placeholder: { id: 'shared.search' },
};

export default SearchListComponent;
