import React from 'react';
import { connect } from 'react-redux';

import {
  filterPatientsList as filterPatientsListAction,
  fetchPatientsList as fetchPatientsListAction,
  resetPatientsListIds as resetPatientsListIdsAction,
  resetPatientsListFilterParams as resetPatientsListFilterParamsAction,
} from 'state/concepts/userProfile/freeVisits/actions';
import {
  patientsListMetaSelector,
  patientsListLoadingSelector,
  patientsListForFilterSelector,
  patientsListIdsSelector,
} from 'state/concepts/userProfile/freeVisits/selectors';

import DropdownField from 'views/shared/DropdownField';

// eslint-disable-next-line react/prefer-stateless-function
class PatientsDropdown extends React.Component {
  render = () => (
    <DropdownField
      {...this.props}
    />
  );
}

const mapStateToProps = state => ({
  meta: patientsListMetaSelector(state),
  isLoading: patientsListLoadingSelector(state),
  options: patientsListForFilterSelector(state),
  optionsIds: patientsListIdsSelector(state),
});

const mapDispatchToProps = {
  fetchList: fetchPatientsListAction,
  filterList: filterPatientsListAction,
  resetListIds: resetPatientsListIdsAction,
  resetListFilterParams: resetPatientsListFilterParamsAction,
};

export { PatientsDropdown as PatientsDropdownContainer };
export default connect(mapStateToProps, mapDispatchToProps)(PatientsDropdown);
