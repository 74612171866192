const namespace = 'vouchersBatches';

export const FETCH_VOUCHERS_BATCHES = `${namespace}/FETCH_VOUCHERS_BATCHES`;
export const FETCH_VOUCHERS_BATCH_DETAILS = `${namespace}/FETCH_VOUCHERS_BATCH_DETAILS`;
export const SET_VOUCHERS_BATCHES = `${namespace}/SET_VOUCHERS_BATCHES`;
export const SET_VOUCHERS_BATCHES_BLANK_STATE = `${namespace}/SET_VOUCHERS_BATCHES_BLANK_STATE`;
export const SET_VOUCHERS_BATCHES_SORT_ORDER = `${namespace}/SET_VOUCHERS_BATCHES_SORT_ORDER`;
export const SET_VOUCHERS_BATCHES_PAGE = `${namespace}/SET_VOUCHERS_BATCHES_PAGE`;
export const SET_VOUCHERS_BATCHES_FILTER = `${namespace}/SET_VOUCHERS_BATCHES_FILTER`;
export const UPDATE_VOUCHERS_BATCH_DETAILS = `${namespace}/UPDATE_VOUCHERS_BATCH_DETAILS`;
