import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';

import { submitChildrenProfileEndpoint } from '../endpoints';
import { SUBMIT_CHILDREN_PROFILE } from '../types';

const submitChildrenProfileOperation = createLogic({
  type: SUBMIT_CHILDREN_PROFILE,
  latest: true,

  async process({ httpClient, action: { patientId, values, form } }, dispatch, done) {
    const { url, endpoint } = submitChildrenProfileEndpoint(patientId);
    const params = {
      include: 'child_profiles',
      child_profiles: values.childProfiles.map(item => ({
        first_name: item.firstName,
        last_name: item.lastName,
        citizen_id: item.citizenId,
      })),
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, params);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(showNotification({
        messageObject: { id: 'notifications.patientProfileUpdated' },
      }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitChildrenProfileOperation;
