import { combineReducers } from 'redux';

import * as types from './types';

const isResentPassword = (state = false, action) => {
  switch (action.type) {
    case types.SET_RESENT_PASSWORD_STATUS:
      return action.status;
    default:
      return state;
  }
};

export default combineReducers({
  isResentPassword,
});
