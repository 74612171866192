import { CLINIC_SPECIALITIES_CANCELLATION_OPTIONS } from 'constants/clinicSpecialities';

import { durationToSeconds, valueWithUnitToSeconds } from 'utils/duration';

const evaluateCancellationOrRescheduleOption = (isAnytime) => (
  isAnytime
    ? CLINIC_SPECIALITIES_CANCELLATION_OPTIONS.anytime
    : CLINIC_SPECIALITIES_CANCELLATION_OPTIONS.upTo
);

const evaluateCancellationOrRescheduleDuration = (option, unit, value) => (
  option === CLINIC_SPECIALITIES_CANCELLATION_OPTIONS.upTo
    ? valueWithUnitToSeconds(unit, value)
    : undefined
);

const parseValuesFormToParams = (values) => {
  const cancellableOption = evaluateCancellationOrRescheduleOption(
    values.cancellableAnytime,
  );
  const reschedulableOption = evaluateCancellationOrRescheduleOption(
    values.reschedulableAnytime,
  );

  return {
    duration: durationToSeconds(values.duration),
    buffer_after: durationToSeconds(values.bufferAfter),
    min_lead_time: valueWithUnitToSeconds(values.minLeadTimeUnit, values.minLeadTime),
    max_future_time: valueWithUnitToSeconds(values.maxFutureTimeUnit, values.maxFutureTime),
    cancellable_option: cancellableOption,
    cancellable_until: evaluateCancellationOrRescheduleDuration(
      cancellableOption, values.cancellableUntilUnit, values.cancellableUntil,
    ),
    reschedulable_option: reschedulableOption,
    reschedulable_until: evaluateCancellationOrRescheduleDuration(
      reschedulableOption, values.reschedulableUntilUnit, values.reschedulableUntil,
    ),
  };
};

export default parseValuesFormToParams;
