import ConfirmModal from 'views/shared/ConfirmModal';
import ErrorModal from 'views/shared/ErrorModal';
import DiscardModal from 'views/userProfile/modals/DiscardModal';
import ConfirmSetupTwoFactorAuthModal from 'views/shared/ConfirmSetupTwoFactorAuthModal';
import TopUpWithPagoparModal from 'views/patientProfile/Account/TopUpWithPagoparModal';
import DeactivateAccountModal from 'views/userProfile/AccountSettings/DeactivateAccount/DeactivateAccountModal';
import ClinicSpecialitiesActivateModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/ActivateModal';
import ClinicSpecialitiesDeactivateModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/DeactivateModal';
import ClinicSpecialitiesRemoveModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/RemoveModal';
import ClinicSpecialitiesBulkRemoveModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/BulkRemoveModal';
import ClinicSpecialitiesBulkActivateModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/BulkActivateModal';
import ClinicSpecialitiesBulkDeactivateModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/BulkDeactivateModal';
import ClinicSpecialitiesAssignDoctorsModal from 'views/userProfile/clinicSpecialities/ClinicSpecialities/AssignDoctorsModal';
import CancelModal from 'views/userProfile/Appointments/CancelModal';
import ActivatePatientModal from 'views/userProfile/patients/PatientsList/ActivateModal';
import BanModal from 'views/userProfile/patients/PatientsList/BanModal';
import PatientAppointmentModal from 'views/userProfile/Patient/AppointmentModal';
import ExtraFreeVisitsModal from 'views/userProfile/modals/ExtraFreeVisitsModal';
import EditExtraFreeVisitsModal from 'views/userProfile/modals/EditExtraFreeVisitsModal';
import AcceptJoinRequestModal from 'views/userProfile/modals/AcceptJoinRequestModal';
import RejectJoinRequestModal from 'views/userProfile/modals/RejectJoinRequestModal';
import RemoveJoinRequestModal from 'views/userProfile/modals/RemoveJoinRequestModal';
import PreviewJoinRequestModal from 'views/userProfile/modals/PreviewJoinRequestModal';
import FileUploadErrorModal from 'views/shared/FileUploadErrorModal';
import FilesPreviewModal from 'views/shared/FilesPreviewModal';
import UploadImageCropModal from 'views/shared/UploadImage/UploadImageCropModal';
import UploadImageErrorModal from 'views/shared/UploadImage/UploadImageErrorModal';
import UploadImageRemoveModal from 'views/shared/UploadImage/UploadImageRemoveModal';
import InvitationModal from 'views/shared/InvitationModal';
import ResetAuthSettingsDoctorModal from 'views/userProfile/modals/ResetAuthSettingsDoctorModal';
import RemoveInvitationModal from 'views/userProfile/independentDoctors/Doctors/DoctorsList/RemoveInvitationModal';
import ActivateDoctorModal from 'views/userProfile/modals/ActivateDoctorModal';
import DeactivateDoctorModal from 'views/userProfile/modals/DeactivateDoctorModal';
import ActivateSymptomModal from 'views/userProfile/Symptoms/List/ActivateModal';
import ActivateSymptomsBulkModal from 'views/userProfile/Symptoms/List/BulkActivateModal';
import DeactivateSymptomModal from 'views/userProfile/Symptoms/List/DeactivateModal';
import DeactivateSymptomsBulkModal from 'views/userProfile/Symptoms/List/BulkDeactivateModal';
import ActivateSpecialityModal from 'views/userProfile/specialities/Specialities/List/ActivateModal';
import ActivateSpecialitiesBulkModal from 'views/userProfile/specialities/Specialities/List/BulkActivateModal';
import DeactivateSpecialityModal from 'views/userProfile/specialities/Specialities/List/DeactivateModal';
import DeactivateSpecialitiesBulkModal from 'views/userProfile/specialities/Specialities/List/BulkDeactivateModal';
import RemoveSpecialityModal from 'views/userProfile/specialities/Specialities/List/RemoveModal';
import RemoveSpecialitiesBulkModal from 'views/userProfile/specialities/Specialities/List/BulkRemoveModal';
import RemoveVouchersBulkModal from 'views/userProfile/vouchers/VouchersList/BulkRemoveModal';
import RemoveChatMessageModal from 'views/userProfile/Chat/MessagesList/Message/RemoveModal';
import RemoveFileModal from 'views/shared/RemoveFileModal';
import GenerateVouchersModal from 'views/userProfile/vouchers/GenerateVouchersModal';
import EditBatchDetailsModal from 'views/userProfile/vouchers/EditBatchDetailsModal';
import TransactionModal from 'views/userProfile/Patient/Balances/TransactionModal';
import DiseasesModal from 'views/layouts/sidebars/ConsultationSidebar/NotePanel/Form/General/DiseasesModal';
import NoteModal from 'views/userProfile/Patient/NoteModal';
import JoinConsultationModal from 'views/userProfile/VideoConference/JoinConsultationModal';
import LeaveConsultationModal from 'views/userProfile/VideoConference/LeaveConsultationModal';
import VideoConferenceSettingsModal from 'views/userProfile/VideoConference/SettingsModal';
import SignatureModal from 'views/shared/Signature/SignatureModal';
import ChangeCommissionModal from 'views/userProfile/modules/Commissions/ChangeCommissionModal';
import DoctorRevenueModal from 'views/userProfile/modules/Revenue/DoctorRevenueModal';
import VCDJoinRequestModal from 'views/userProfile/doctorsAndAvailability/doctors/VCDoctorsList/VCDJoinRequestModal';
import VCDDeactivateModal from 'views/userProfile/doctorsAndAvailability/doctors/VCDoctorsList/VCDDeactivateModal';
import VCDActivateModal from 'views/userProfile/doctorsAndAvailability/doctors/VCDoctorsList/VCDActivateModal';
import VCDRemoveInvitationModal from 'views/userProfile/doctorsAndAvailability/doctors/VCDoctorsList/VCDRemoveInvitationModal';
import VCDAssignClinicSpecialitiesModal from 'views/userProfile/doctorsAndAvailability/doctors/VCDoctorsList/VCDAssignClinicSpecialitiesModal';
import DeactivateVirtualClinicModal from 'views/userProfile/virtualClinics/clinics/VCAdminsList/DeactivateVirtualClinicModal';
import ActivateVirtualClinicModal from 'views/userProfile/virtualClinics/clinics/VCAdminsList/ActivateVirtualClinicModal';
import ResetAuthSettingsVirtualClinicModal from 'views/userProfile/virtualClinics/clinics/VCAdminsList/ResetAuthSettingsVirtualClinicModal';
import ForgotPasswordModal from 'views/userProfile/modals/ForgotPasswordModal';
import NoteHeadsUpModal from 'views/userProfile/VideoConference/NoteHeadsUpModal';
import ExitConferenceModal from 'views/userProfile/VideoConference/ExitConferenceModal';
import CreatePushNotificationModal from 'views/userProfile/pushNotifications/CreatePushNotificationModal';

const MODAL_COMPONENTS = {
  CONFIRM_MODAL: ConfirmModal,
  ERROR_MODAL: ErrorModal,
  DISCARD_MODAL: DiscardModal,
  SETUP_TWO_FACTOR_AUTH_MODAL: ConfirmSetupTwoFactorAuthModal,
  DEACTIVATE_ACCOUNT_MODAL: DeactivateAccountModal,
  CLINIC_SPECIALITIES_ACTIVATE_MODAL: ClinicSpecialitiesActivateModal,
  CLINIC_SPECIALITIES_DEACTIVATE_MODAL: ClinicSpecialitiesDeactivateModal,
  CLINIC_SPECIALITIES_REMOVE_MODAL: ClinicSpecialitiesRemoveModal,
  CLINIC_SPECIALITIES_BULK_REMOVE_MODAL: ClinicSpecialitiesBulkRemoveModal,
  CLINIC_SPECIALITIES_BULK_ACTIVATE_MODAL: ClinicSpecialitiesBulkActivateModal,
  CLINIC_SPECIALITIES_BULK_DEACTIVATE_MODAL: ClinicSpecialitiesBulkDeactivateModal,
  CLINIC_SPECIALITIES_ASSIGN_DOCTORS_MODAL: ClinicSpecialitiesAssignDoctorsModal,
  CANCEL_APPOINTMENT_MODAL: CancelModal,
  PATIENT_APPOINTMENT_MODAL: PatientAppointmentModal,
  PATIENT_ACTIVATE_MODAL: ActivatePatientModal,
  PATIENT_BAN_MODAL: BanModal,
  EXTRA_FREE_VISITS_MODAL: ExtraFreeVisitsModal,
  EDIT_EXTRA_FREE_VISITS_MODAL: EditExtraFreeVisitsModal,
  ACCEPT_JOIN_REQUEST_MODAL: AcceptJoinRequestModal,
  REJECT_JOIN_REQUEST_MODAL: RejectJoinRequestModal,
  REMOVE_JOIN_REQUEST_MODAL: RemoveJoinRequestModal,
  PREVIEW_JOIN_REQUEST_MODAL: PreviewJoinRequestModal,
  FILE_UPLOAD_ERROR: FileUploadErrorModal,
  FILES_PREVIEW_MODAL: FilesPreviewModal,
  UPLOAD_IMAGE_CROP_MODAL: UploadImageCropModal,
  UPLOAD_IMAGE_ERROR_MODAL: UploadImageErrorModal,
  UPLOAD_IMAGE_REMOVE_MODAL: UploadImageRemoveModal,
  INVITATION_MODAL: InvitationModal,
  REMOVE_INVITATION_MODAL: RemoveInvitationModal,
  ACTIVATE_DOCTOR_MODAL: ActivateDoctorModal,
  DEACTIVATE_DOCTOR_MODAL: DeactivateDoctorModal,
  SYMPTOM_ACTIVATE_MODAL: ActivateSymptomModal,
  SYMPTOM_BULK_ACTIVATE_MODAL: ActivateSymptomsBulkModal,
  SYMPTOM_DEACTIVATE_MODAL: DeactivateSymptomModal,
  SYMPTOM_BULK_DEACTIVATE_MODAL: DeactivateSymptomsBulkModal,
  RESET_AUTH_SETTINGS_DOCTOR_MODAL: ResetAuthSettingsDoctorModal,
  SPECIALITY_ACTIVATE_MODAL: ActivateSpecialityModal,
  SPECIALITIES_BULK_ACTIVATE_MODAL: ActivateSpecialitiesBulkModal,
  SPECIALITY_DEACTIVATE_MODAL: DeactivateSpecialityModal,
  SPECIALITIES_BULK_DEACTIVATE_MODAL: DeactivateSpecialitiesBulkModal,
  SPECIALITY_REMOVE_MODAL: RemoveSpecialityModal,
  SPECIALITIES_BULK_REMOVE_MODAL: RemoveSpecialitiesBulkModal,
  VOUCHER_BULK_REMOVE_MODAL: RemoveVouchersBulkModal,
  REMOVE_CHAT_MESSAGE_MODAL: RemoveChatMessageModal,
  REMOVE_FILE_MODAL: RemoveFileModal,
  GENERATE_VOUCHERS_MODAL: GenerateVouchersModal,
  EDIT_BATCH_DETAILS_MODAL: EditBatchDetailsModal,
  TRANSACTION_MODAL: TransactionModal,
  TOP_UP_WITH_PAGOPAR_MODAL: TopUpWithPagoparModal,
  DISEASES_MODAL: DiseasesModal,
  NOTE_MODAL: NoteModal,
  JOIN_CONSULTATION_MODAL: JoinConsultationModal,
  LEAVE_CONSULTATION_MODAL: LeaveConsultationModal,
  VIDEO_CONFERENCE_SETTINGS_MODAL: VideoConferenceSettingsModal,
  SIGNATURE_MODAL: SignatureModal,
  CHANGE_COMMISSION_MODAL: ChangeCommissionModal,
  DOCTOR_REVENUE_MODAL: DoctorRevenueModal,
  VCD_JOIN_REQUEST_MODAL: VCDJoinRequestModal,
  VCD_DEACTIVATE_MODAL: VCDDeactivateModal,
  VCD_ACTIVATE_MODAL: VCDActivateModal,
  VCD_REMOVE_INVITATION_MODAL: VCDRemoveInvitationModal,
  VCD_ASSIGN_CLINIC_SPECIALITIES: VCDAssignClinicSpecialitiesModal,
  DEACTIVATE_VIRTUAL_CLINIC_MODAL: DeactivateVirtualClinicModal,
  ACTIVATE_VIRTUAL_CLINIC_MODAL: ActivateVirtualClinicModal,
  RESET_AUTH_SETTINGS_VIRTUAL_CLINIC_MODAL: ResetAuthSettingsVirtualClinicModal,
  FORGOT_PASSWORD_MODAL: ForgotPasswordModal,
  NOTE_HEADS_UP_MODAL: NoteHeadsUpModal,
  EXIT_CONFERENCE_MODAL: ExitConferenceModal,
  CREATE_PUSH_NOTIFICATION_MODAL: CreatePushNotificationModal,
};

export default MODAL_COMPONENTS;
