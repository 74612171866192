import PropTypes from 'prop-types';
import classNames from 'classnames';

import SvgIcon from 'views/shared/SvgIcon';

const ToggleButton = ({
  className,
  isActive,
  onClick,
  kind,
  icon,
  visible,
}) => {
  let iconType;
  if (icon === 'video' && isActive) {
    iconType = 'video';
  } else if (icon === 'video' && !isActive) {
    iconType = 'video-off';
  } else if (icon === 'microphone' && isActive) {
    iconType = 'microphone';
  } else if (icon === 'microphone' && !isActive) {
    iconType = 'microphone-off';
  } else if (icon === 'screen-share' && isActive) {
    iconType = 'screen-share';
  } else if (icon === 'screen-share' && !isActive) {
    iconType = 'screen-share-off';
  } else {
    iconType = icon;
  }

  return (
    <button
      onClick={onClick}
      className={classNames(className, 'video-btns__btn', {
        'd-none': !visible,
        'video-btns__btn--secondary': kind === 'secondary',
        'video-btns__btn--negative': iconType === 'call-end' || iconType === 'screen-share-off' || iconType === 'microphone-off' || iconType === 'video-off',
      })}
    >
      <SvgIcon icon={iconType} />
    </button>
  );
};

ToggleButton.defaultProps = {
  className: '',
  kind: '',
  icon: '',
  isActive: false,
  visible: true,
  onClick: undefined,
};

ToggleButton.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ToggleButton;
