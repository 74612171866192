import { combineReducers } from 'redux';

import * as types from './types';

const virtualClinicProfileId = (state = '', action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_PROFILE_ID:
      return action.userId;
    case types.RESET_VIRTUAL_CLINIC_PROFILE_ID:
      return '';
    default:
      return state;
  }
};

const virtualClinicIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINICS_IDS:
      return action.ids;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINICS_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINICS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'virtual_clinic_name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINICS_SORT:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  virtualClinicProfileId,
  virtualClinicIds,
  isBlankState,
  pagination,
  sort,
});
