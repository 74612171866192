import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';
import { isPlatformOwner } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { deviceTimezoneSelector } from 'state/concepts/timezones/selectors';
import { currentUserSelector } from 'state/concepts/session/selectors';

import { setTransactionsIds } from '../actions';
import { sortParamsSelector, transactionsSelectedTabSelector, paginationSelector } from '../selectors';
import { fetchTransactionsEndpoint } from '../endpoints';
import { FETCH_TRANSACTIONS } from '../types';

const fetchTransactionsOperation = createLogic({
  type: FETCH_TRANSACTIONS,
  latest: true,

  async process({ httpClient, getState, action: { patientId } }, dispatch, done) {
    const state = getState();
    const transactionsSelectedTab = transactionsSelectedTabSelector(state);
    const currentUser = currentUserSelector(state);
    const timezone = deviceTimezoneSelector(
      state, Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const { endpoint, url } = fetchTransactionsEndpoint;

    const params = {
      include: 'appointment,appointment.speciality,appointment.userProfile,appointment.symptom',
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: {
        patient_profile_ids: [patientId],
        actions: [transactionsSelectedTab],
      },
      timezone: isPlatformOwner(currentUser.roleName) ? null : timezone.id,
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setTransactionsIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchTransactionsOperation;
