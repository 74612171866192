import { createLogic } from 'redux-logic';

import {
  fetchSpecialitiesList,
  setSpecialitiesListFilterParams,
} from '../actions';
import { SET_SPECIALITIES_LIST_FILTERS } from '../types';

const filterSpecialitiesListOperation = createLogic({
  type: SET_SPECIALITIES_LIST_FILTERS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setSpecialitiesListFilterParams(filters));
    dispatch(fetchSpecialitiesList());
    done();
  },
});

export default filterSpecialitiesListOperation;
