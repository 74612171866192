import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { removeMessage } from 'state/concepts/userProfile/chat/actions';
import { removeMessageLoadingSelector } from 'state/concepts/userProfile/chat/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class ChatMessageRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.shape().isRequired,
    otherProfile: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, message: { id } } = this.props;
    onSubmit(id);
  }

  render() {
    const { otherProfile } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        title={{ id: 'chat.deleteMessage.modal.title' }}
        bodyText={[{
          id: 'chat.removeMessage.modal.body',
          values: { name: <span className="f-700">{profileName(otherProfile)}</span> },
        }]}
        submitText={{ id: 'shared.delete' }}
        onSubmit={this.handleSubmit}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = (state, { messageId }) => ({
  isLoading: removeMessageLoadingSelector(state, messageId),
});

const mapDispatchToProps = {
  onSubmit: removeMessage,
};

export { ChatMessageRemoveModal as ChatMessageRemoveModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageRemoveModal);
