import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { setFreeVisitsCurrentPage, fetchFreeVisits } from '../actions';
import { SET_FREE_VISITS_FILTER } from '../types';

const filterFreeVisitsOperation = createLogic({
  type: SET_FREE_VISITS_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setFreeVisitsCurrentPage(1));
    dispatch(fetchFreeVisits());
    done();
  },
});

export default filterFreeVisitsOperation;
