import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  removeSpecialities,
  setSelectedSpecialities as setSelectedSpecialitiesAction,
} from 'state/concepts/userProfile/specialities/actions';
import { removeSpecialitiesLoadingSelector } from 'state/concepts/userProfile/specialities/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class SpecialitiesBulkRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    specialityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedSpecialities: PropTypes.func.isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, specialityIds, setSelectedSpecialities } = this.props;

    onSubmit(specialityIds);
    setSelectedSpecialities([]);
  }

  render() {
    const { specialityIds } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'specialities.bulkRemoveModal.title' }}
        bodyText={[
          [{
            id: 'specialities.bulkRemoveModal.body.start',
          },
          {
            id: 'specialities.bulkRemoveModal.body.end',
            values: { count: specialityIds.length },
            isBold: true,
          }],
          {
            id: 'specialities.modal.body.activeAppointments',
          },
        ]}
        submitText={{ id: 'shared.remove' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: removeSpecialitiesLoadingSelector(state),
});

const mapDispatchToProps = {
  onSubmit: removeSpecialities,
  setSelectedSpecialities: setSelectedSpecialitiesAction,
};

export { SpecialitiesBulkRemoveModal as SpecialitiesBulkRemoveModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SpecialitiesBulkRemoveModal);
