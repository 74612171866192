import {
  equals, pipe, replace, match, join, length, toLower,
} from 'ramda';

import { VOUCHER_STATUSES } from 'constants/vouchers';

export const isVoucherInactive = equals(VOUCHER_STATUSES.inactive);
export const isVoucherUsed = equals(VOUCHER_STATUSES.used);

export const formattedVoucherCode = pipe(
  replace(/[^a-zA-Z0-9]/g, ''),
  match(/.{1,4}/g),
  join(' '),
  (value) => value.substr(0, 19),
);

export const formattedVoucherCodeToParams = pipe(
  replace(/ /g, '-'),
  toLower,
);

export const voucherCodeLength = pipe(
  replace(/ /g, ''),
  length,
);
