import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { FormattedMessage } from 'react-intl';
import { camelCase, lowerCase } from 'lodash';

import Modal from 'views/shared/Modal';
import Alert from 'views/shared/Alert';
import SvgIcon from 'views/shared/SvgIcon';
import Button from 'views/shared/Button';
import SubmitFormButton from 'views/shared/SubmitFormButton';

import InvitationInputFields from './InvitationInputFields';

const InvitationModalComponent = ({
  intl,
  roleName,
  status,
  onClose,
  handleSubmit,
}) => (
  <Modal
    className="ant-modal"
    wrapClassName="ant-modal-wrap ant-modal-wrap__8 ant-modal-wrap--invitation"
    onClose={onClose}
  >
    <div className="ant-modal__header">
      <h1 className="ant-modal__header-txt">
        <FormattedMessage
          id="invitations.modal.title"
          values={{ roleName: lowerCase(intl.formatMessage({ id: `role.${camelCase(roleName)}` })) }}
        />
      </h1>
      <button onClick={onClose}>
        <SvgIcon icon="cross" className="ant-modal__close" />
      </button>
    </div>
    <div className="ant-modal__body">
      <div className="modal-invite">
        <p className="modal-invite__txt">
          <FormattedMessage id="invitations.modal.subTitle" />
        </p>
        {status && status.invitations && (
          <Alert
            className="mb-16"
            message={{ id: 'invitations.modal.duplicateEmails' }}
            type="warning"
          />
        )}
        {status && status.base && (
          <Alert
            className="mb-16"
            message={status.base}
          />
        )}
        <FieldArray name="invitations">
          {(arrayHelpers) => (
            <InvitationInputFields
              roleName={roleName}
              {...arrayHelpers}
            />
          )}
        </FieldArray>
      </div>
    </div>
    <div className="ant-modal__footer ant-modal__footer--right">
      <Button
        text={{ id: 'shared.cancel' }}
        kind="tertiary"
        onClick={onClose}
      />
      <SubmitFormButton
        text={{ id: 'shared.sendInvitations' }}
        onClick={handleSubmit}
      />
    </div>
  </Modal>
);

InvitationModalComponent.defaultProps = {
  status: undefined,
};

InvitationModalComponent.propTypes = {
  intl: PropTypes.shape().isRequired,
  roleName: PropTypes.string.isRequired,
  status: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default InvitationModalComponent;
