import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { banPatient } from 'state/concepts/userProfile/patient/actions';
import { banPatientLoadingSelector } from 'state/concepts/userProfile/patient/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class PatientsListBanModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    patient: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, patient: { id } } = this.props;

    onSubmit(id);
  }

  render() {
    const { patient } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'patients.banModal.title' }}
        bodyText={[{
          id: 'patients.banModal.body',
          values: { name: <span className="f-700">{profileName(patient)}</span> },
        }]}
        submitText={{ id: 'shared.ban' }}
        kind="danger"
      />
    );
  }
}

const mapStateToProps = (state, { patient: { id } }) => ({
  isLoading: banPatientLoadingSelector(state, id),
});

const mapDispatchToProps = {
  onSubmit: banPatient,
};

export { PatientsListBanModal as PatientsListBanModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(PatientsListBanModal);
