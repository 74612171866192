import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { isPlatformOwner, isVirtualClinicAdmin } from 'utils/roles';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';

import { setDoctorListIds, addDoctorsToIdsList } from '../actions';
import {
  doctorListStatusesSelector,
  doctorListFilterParamsSelector,
  doctorListClinicSpecialityIdSelector,
  paginationSelector,
  rolesSelector,
  sortParamsSelector,
} from '../selectors';
import { FETCH_DOCTORS_LIST } from '../types';
import { fetchAllDoctorsEndpoint } from '../endpoints';
import { revenueVirtualClinicIdSelector } from '../../revenue/selectors';

const fetchDoctorsList = createLogic({
  type: FETCH_DOCTORS_LIST,
  latest: true,

  async process({ httpClient, action: { lastId }, getState }, dispatch, done) {
    const { endpoint, url } = fetchAllDoctorsEndpoint;
    const state = getState();
    const { size } = paginationSelector(state);
    const roles = rolesSelector(state);
    const sort = sortParamsSelector(state);
    const statuses = doctorListStatusesSelector(state);
    const { name, excludeUsers } = doctorListFilterParamsSelector(state);
    const { roleName, clinic } = currentUserSelector(state);
    const clinicSpecialityId = doctorListClinicSpecialityIdSelector(state) || null;
    const clinicIdsVCA = isVirtualClinicAdmin(roleName) ? [clinic.id] : null;
    const clinicIdsPO = isPlatformOwner(roleName) ? revenueVirtualClinicIdSelector(state) : null;
    const clinicIds = clinicIdsVCA || (clinicIdsPO ? [clinicIdsPO] : null);
    const { name: commonFilterName } = commonFiltersSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size,
        is_cursor: true,
      },
      filter: {
        statuses,
        roles,
        name: name || commonFilterName,
        clinic_speciality_id: clinicSpecialityId,
        clinic_ids: clinicIds,
        exclude_users: excludeUsers,
      },
      sort,
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));

      if (lastId) {
        dispatch(addDoctorsToIdsList(pluck('id', data.data)));
      } else {
        dispatch(setDoctorListIds(pluck('id', data.data)));
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchDoctorsList;
