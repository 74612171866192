import fetchSpecialities from './fetchSpecialities';
import filterSpecialities from './filterSpecialities';
import activateSpecialities from './activateSpecialities';
import deactivateSpecialities from './deactivateSpecialities';
import removeSpecialities from './removeSpecialities';
import submitCreateSpeciality from './submitCreateSpeciality';
import submitUpdateSpeciality from './submitUpdateSpeciality';
import getSpeciality from './getSpeciality';
import getSpecialitySymptoms from './getSpecialitySymptoms';
import fetchSpecialitiesList from './fetchSpecialitiesList';
import filterSpecialitiesList from './filterSpecialitiesList';

export default [
  fetchSpecialities,
  filterSpecialities,
  activateSpecialities,
  deactivateSpecialities,
  removeSpecialities,
  submitCreateSpeciality,
  submitUpdateSpeciality,
  getSpeciality,
  getSpecialitySymptoms,
  fetchSpecialitiesList,
  filterSpecialitiesList,
];
