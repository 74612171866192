import buildHttpClient from 'lib/httpClient';
import { passwordStrengthCheckRoute } from 'lib/apiRoutes';

import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from 'constants';

export default async (password, isTouchedWithErrors) => {
  const httpClient = buildHttpClient();

  if (password.length === 0) {
    return {
      hint: {
        id: 'yup.string.min',
        values: { min: MIN_PASSWORD_LENGTH },
      },
      icon: 'info',
    };
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return {
      hint: {
        id: 'yup.string.min',
        values: { min: MIN_PASSWORD_LENGTH },
      },
      scaleStep: 'one',
      icon: isTouchedWithErrors ? 'alert' : 'info',
    };
  }

  if (password.length > MAX_PASSWORD_LENGTH) {
    return {
      hint: {
        id: 'yup.string.max',
        values: { max: MAX_PASSWORD_LENGTH },
      },
      icon: isTouchedWithErrors ? 'alert' : 'info',
    };
  }

  const { data } = await httpClient.post(passwordStrengthCheckRoute, { password });
  const { meta: { score } } = data;

  if (score < 3) {
    return {
      hint: { id: 'resetPassword.strength.low' },
      scaleStep: 'two',
      icon: 'info',
    };
  }

  return {
    hint: { id: 'resetPassword.strength.strong' },
    scaleStep: 'three',
    icon: 'tick',
  };
};
