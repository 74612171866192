import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchPatient } from 'state/concepts/userProfile/patient/actions';
import { fetchFreeVisits } from 'state/concepts/userProfile/freeVisits/actions';
import { submitExtraFreeVisitsEndpoint } from '../endpoints';
import { SUBMIT_CREATE_FREE_VISITS } from '../types';

const submitCreateFreeVisitsOperation = createLogic({
  type: SUBMIT_CREATE_FREE_VISITS,
  latest: true,

  async process({ httpClient, action: { values, form, isPatientProfile } }, dispatch, done) {
    const { url, endpoint } = submitExtraFreeVisitsEndpoint;
    const params = {
      clinic_id: values.clinicId,
      patient_profile_id: values.patientProfileId,
      free_visits_count: values.freeVisitsCount,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());
      dispatch(showNotification({ messageObject: { id: 'notifications.freeVisitsHasBeenUpdated' } }));
      if (isPatientProfile) {
        dispatch(fetchPatient(values.patientProfileId));
      } else {
        dispatch(fetchFreeVisits());
      }
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.freeVisitsHasBeenUpdatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint, form });
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitCreateFreeVisitsOperation;
