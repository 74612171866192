import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { updateVirtualClinicExtraFreeVisitsEndpoint } from '../endpoints';
import { UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS } from '../types';

const updateVirtualClinicExtraFreeVisitsOperation = createLogic({
  type: UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS,
  latest: true,

  async process({ httpClient, getState, action: { clinicId, values } }, dispatch, done) {
    const { url, endpoint } = updateVirtualClinicExtraFreeVisitsEndpoint(clinicId);
    const { acceptExtraFreeVisits } = values;

    const params = {
      accept_extra_free_visits: acceptExtraFreeVisits,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));
      await httpClient.put(url, params);
      const state = getState();

      const response = updateDataHelper(
        state.data, 'clinic', clinicId,
        { attributes: { acceptExtraFreeVisits } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: {
          id: acceptExtraFreeVisits
            ? 'notifications.virtualClinicActivatedExtraFreeVisits'
            : 'notifications.virtualClinicDeactivatedExtraFreeVisits',
         },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: {
          id: acceptExtraFreeVisits
            ? 'notifications.virtualClinicActivatedExtraFreeVisitsError'
            : 'notifications.virtualClinicDeactivatedExtraFreeVisitsError',
        },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default updateVirtualClinicExtraFreeVisitsOperation;
