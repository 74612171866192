import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { USER_TYPE } from 'constants/users';
import { IS_LOGGED_IN } from 'constants/auth';

import requestErrorHandler from 'lib/requestErrorHandler';

import redirect from 'utils/redirect';
import setUserCookies from 'utils/setUserCookies';
import removeUserCookies from 'utils/removeUserCookies';
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { userLoginSuccess, setVisibleAuthFlow } from '../actions';
import { submitTwoFactorAuthEndpoint } from '../endpoints';
import { SUBMIT_USER_TWO_FACTOR_AUTH_CODE } from '../types';

const submitUserTwoFactorAuthOperation = createLogic({
  type: SUBMIT_USER_TWO_FACTOR_AUTH_CODE,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { endpoint, url } = submitTwoFactorAuthEndpoint;

    const params = {
      verification_code: values.verificationCode,
    };

    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.post(url, params);

      const response = normalize(data);
      const currentUser = build(response, 'userProfile')[0];
      const tokens = data.meta.jwt;

      setUserCookies(currentUser, tokens, USER_TYPE.user, IS_LOGGED_IN);

      dispatch(userLoginSuccess(currentUser.id));
      dispatch(dataApiSuccess({ response, endpoint }));
      redirect({ href: '/appointments', reload: true });
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        removeUserCookies();
        dispatch(setVisibleAuthFlow(null));
        dispatch(showNotification({
          messageObject: {
            id: 'notifications.yourCurrentSessionHasBeenExpired',
          },
          kind: 'error',
        }));
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form });
      }
    }

    form.setSubmitting(false);
    done();
  },
});

export default submitUserTwoFactorAuthOperation;
