import { path } from 'ramda';

import { loadingSelector } from 'state/data/selectors';
import { validateResetPasswordTokenEndpoint, submitResetPasswordEndpoint } from './endpoints';

export const isResentPasswordSelector = path(['resetPassword', 'isResentPassword']);

export const validateResetPasswordLoadingSelector = (state) => (
  loadingSelector(state, validateResetPasswordTokenEndpoint.endpoint)
);

export const submitResetPasswordLoadingSelector = (state) => (
  loadingSelector(state, submitResetPasswordEndpoint.endpoint)
);
