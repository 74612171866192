import yup from 'lib/yupLocalised';

import {
  MIN_CLINIC_SPECIALITY_PRICE_LENGTH,
  MAX_CLINIC_SPECIALITY_PRICE_LENGTH,
} from 'constants';

export default yup.object().shape({
  userId: yup.number().required(),
  clinicSpecialities: yup.array().of(
    yup.object().shape({
      clinicSpecialityIds: yup.array().min(1, { id: 'yup.string.pleaseAddSpecialityName' }).required(),
      price: yup.number().min(MIN_CLINIC_SPECIALITY_PRICE_LENGTH).max(MAX_CLINIC_SPECIALITY_PRICE_LENGTH).required({ id: 'yup.string.pleaseAddConsultationPrice' }),
      freeVisit: yup.boolean().required(),
    }),
  ),
});
