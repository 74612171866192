import { createSelector } from 'reselect';
import build from 'redux-object';
import { path, prop, propOr } from 'ramda';

import { loadingSelector } from 'state/data/selectors';
import { getAppointmentEndpoint, getQuestionnaireResponsesEndpoint, submitAddNoteEndpoint } from './endpoints';

const dataSelector = prop('data');

export const appointmentIdSelector = path(['appointment', 'appointmentId']);
export const questionnaireResponsesIdSelector = path(['appointment', 'questionnaireResponsesId']);
export const symptomDetailsPanelVisibleSelector = path(['appointment', 'symptomDetailsPanelVisible']);
export const notePanelVisibleSelector = path(['appointment', 'notePanelVisible']);
export const notificationCancelConferenceSelector = path(['appointment', 'notificationCancelConference']);

export const appointmentSelector = createSelector(
  appointmentIdSelector,
  dataSelector,
  (id, data) => (id ? build(data, 'appointment', id) : null),
);

export const userAppointmentLoadingSelector = (state, appointmentId) => (
  loadingSelector(state, getAppointmentEndpoint(appointmentId).endpoint)
);

export const questionnaireResponsesSelector = createSelector(
  questionnaireResponsesIdSelector,
  dataSelector,
  (id, data) => (id ? build(data, 'questionnaireResponse', id) : null),
);

export const questionnaireResponsesLoadingSelector = (state, questionnaireResponsesId) => (
  loadingSelector(state, getQuestionnaireResponsesEndpoint(questionnaireResponsesId).endpoint)
);

export const draftNoteSelector = createSelector(
  appointmentSelector,
  propOr(null, 'noteDraft'),
);

export const noteSelector = createSelector(
  (_, noteId) => noteId,
  dataSelector,
  (id, data) => (id ? build(data, 'note', id) : null),
);

export const presentNoteSelector = createSelector(
  noteSelector,
  draftNoteSelector,
  (note, draftNote) => (note || draftNote || null),
);

export const submitAddNoteLoadingSelector = (state, appointmentId) => (
  loadingSelector(state, submitAddNoteEndpoint(appointmentId).endpoint)
);
