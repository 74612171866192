import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setRecurringAvailability } from '../actions';
import { fetchRecurringAvailabilityEndpoint } from '../endpoints';
import { FETCH_RECURRING_AVAILABILITY } from '../types';

const fetchRecurringAvailabilityOperation = createLogic({
  type: FETCH_RECURRING_AVAILABILITY,
  latest: true,

  async process({ httpClient, action: { userId } }, dispatch, done) {
    const { endpoint, url } = fetchRecurringAvailabilityEndpoint(userId);
    dispatch(dataApiRequest({ endpoint }));

    try {
      const { data } = await httpClient.get(url);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setRecurringAvailability(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchRecurringAvailabilityOperation;
