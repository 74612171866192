import cookies from 'next-cookies';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import browserCookie from 'component-cookie';
import {
  keys,
  prop,
  pipe,
  replace,
  split,
  fromPairs,
  map,
} from 'ramda';

import { SENTRY_DSN, RELEASE_TAG } from 'constants';

const captureException = (err, ctx) => {
  Sentry.configureScope(scope => {
    if (prop('message', err)) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force de-duplication by error message if it is present
      scope.setFingerprint([prop('message', err)]);
    }

    if (prop('statusCode', err)) {
      scope.setExtra('statusCode', prop('statusCode', err));
    }

    if (ctx) {
      const { req, errorInfo } = ctx;
      const { currentUserId } = cookies(ctx);

      scope.setTag('ssr', true);
      scope.setExtra('url', req.url);
      scope.setExtra('method', req.method);
      scope.setExtra('headers', req.headers);
      scope.setExtra('params', req.params);
      scope.setExtra('query', req.query);
      scope.setUser({ id: currentUserId });

      keys(errorInfo).forEach(key => scope.setExtra(key, prop(key, errorInfo)));
    } else if (typeof window !== 'undefined') {
      const currentUserId = browserCookie('currentUserId');
      const { pathname, search } = window.location;
      const query = pipe(
        replace('?', ''),
        split('&'),
        map((i) => split('=')(i)),
        fromPairs(),
      )(search);

      scope.setTag('ssr', false);
      scope.setExtra('query', query);
      scope.setExtra('pathname', pathname);
      scope.setUser({ id: currentUserId });
    }
  });

  return Sentry.captureException(err);
};

const sentryInit = () => {
  const sentryOptions = {
    dsn: SENTRY_DSN,
    release: RELEASE_TAG,
  };

  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production') {
    // Don't actually send the errors to Sentry in development env
    sentryOptions.beforeSend = () => null;

    // Trigger DevTools debugger instead of using console.log
    sentryOptions.integrations = [new Integrations.BrowserTracing()];
  }

  Sentry.init(sentryOptions);
};

export { Sentry, sentryInit, captureException };
