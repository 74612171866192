import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import profileName from 'utils/profileName';

import { deactivateVirtualClinicDoctor } from 'state/concepts/userProfile/virtualClinicDoctors/actions';
import { deactivateVirtualClinicDoctorLoadingSelector } from 'state/concepts/userProfile/virtualClinicDoctors/selectors';

import ConfirmModal from 'views/shared/ConfirmModal';

class VCDDeactivateModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    doctor: PropTypes.shape().isRequired,
  };

  handleSubmit = () => {
    const { onSubmit, doctor: { id, vcdId } } = this.props;

    onSubmit(id, vcdId);
  }

  render() {
    const { doctor } = this.props;

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: 'doctors.deactivateModal.title' }}
        bodyText={[
          {
            id: 'doctors.deactivateModal.body',
            values: { name: <span className="f-700">{profileName(doctor)}</span> },
          },
          {
            id: 'doctors.deactivateModal.warning',
          },
        ]}
        submitText={{ id: 'shared.deactivate' }}
      />
    );
  }
}

const mapStateToProps = (state, { doctor: { vcdId } }) => ({
  isLoading: deactivateVirtualClinicDoctorLoadingSelector(state, vcdId),
});

const mapDispatchToProps = {
  onSubmit: deactivateVirtualClinicDoctor,
};

export { VCDDeactivateModal as VCDDeactivateModalContainer };
export default connect(mapStateToProps, mapDispatchToProps)(VCDDeactivateModal);
