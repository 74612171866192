import {
  TIME_UNITS_DAY,
  TIME_UNITS_HOUR,
  TIME_UNITS_MINUTE,
  TIME_UNITS_MONTH,
} from 'constants/timeUnits';

const timeUnitsNamesEs = {
  [TIME_UNITS_MINUTE]: { singular: 'minuto', plural: 'minutos' },
  [TIME_UNITS_HOUR]: { singular: 'hora', plural: 'horas' },
  [TIME_UNITS_DAY]: { singular: 'día', plural: 'días' },
  [TIME_UNITS_MONTH]: { singular: 'mes', plural: 'meses' },
};

const makeDurationUnitError = (messageStart, key) => {
  const unitsOptions = [TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH].map(
    unit => ` ${unit} {{${key}, plural, one {${timeUnitsNamesEs[unit].singular}} other {${timeUnitsNamesEs[unit].plural}}}}`,
  );
  return `${messageStart} {${key}} {unit, select,${unitsOptions.join(' ')}}`;
};

export default {
  locale: 'es',
  messages: {
    'yup.mixed.required': 'No puede estar en blanco',
    'yup.string.url': 'Esta URL no es válida',
    'yup.string.email': 'Coreo electrónico inavalido',
    'yup.string.min': 'Utilice una longitud mínima de {min} caracteres',
    'yup.string.max': 'Debe ser menor o igual a {max} caracteres',
    'yup.string.isDurationString': 'Formato de duración no válido (ej. 1h 30m)',
    'yup.string.durationMin': 'Debe tener al menos {min} de duración',
    'yup.string.durationMax': 'Debe ser menor o igual a {max}',
    'yup.string.durationInUnitMax': makeDurationUnitError('Debe ser menor o igual a', 'max'),
    'yup.string.durationInUnitMin': makeDurationUnitError('Debe durar al menos', 'min'),
    'yup.string.currencyFromTo': 'Desde {min} hasta {max}',
    'yup.number.integer': 'Debe ser un entero',
    'yup.number.positive': 'Debe ser un número positivo',
    'yup.number.typeError': 'Deben ser caracteres numéricos',
    'yup.number.moreThan': 'El precio debe ser igual o mayor que cero',
    'yup.number.min': 'Debe ser mayor o igual a {min}',
    'yup.number.max': 'Debe ser menora o igual a {max}',
    'yup.mixed.required.verificationCode': 'Debe ingresar el código de verificación que se muestra en la aplicación Google Authenticator',
    'yup.string.matches.onlyDigits': 'Sólo dígitos',
    'yup.notIdenticalPasswords': 'La contraseña nueva y la antigua no pueden ser idénticas',
    'yup.string.pleaseAddSpecialityName': 'Agregue el nombre de la especialidad',
    'yup.string.pleaseAddConsultationPrice': 'Por favor agregue precio de consulta',
    'yup.string.voucherCode': 'Debe contener {length} caracteres',
    'yup.string.digitsNumber': '{length} número de dígitos',
    'yup.array.min': 'Debe tener al menos {min} elementos',
    'yup.string.pleaseAddDoctor': 'Agregar un profesional',
    'yup.number.isValidPercent': 'Formatos admitidos 35% o 35,4%',
    'yup.number.maxCommission': 'La comisión máxima debe ser mayor que la mínima',
    'yup.number.maxAgeTo': 'Debe ser mayor que la “Edad (desde)”',

    'timeUnits.minutes': 'Minuto',
    'timeUnits.hours': 'Hora',
    'timeUnits.days': 'Dia',
    'timeUnits.weeks': 'Semana',
    'timeUnits.months': 'Mes',
    'timeUtils.interval.15': 'Cada 15 min',
    'timeUtils.interval.30': 'Cada 30 min',
    'timeUtils.interval.45': 'Cada 45 min',

    'shared.submit': 'Enviar',
    'shared.generate': 'Generar',
    'shared.subscribe': 'Suscribir',
    'shared.contactUs': 'Contáctenos',
    'shared.save': 'Guardar',
    'shared.send': 'Enviar',
    'shared.sendWithCount': 'Enviar {count}',
    'shared.verify': 'Verificar',
    'shared.discard': 'Descartar',
    'shared.email': 'Correo electrónico',
    'shared.cancel': 'Cancelar',
    'shared.cancelAppointment': 'Cancelar consulta',
    'shared.ok': 'Ok',
    'shared.continue': 'Continuar',
    'shared.search': 'Buscar',
    'shared.searchByNameEmailDoc': 'Buscar por nombre, email o documento de identidad',
    'shared.password': 'Contraseña',
    'shared.apply': 'Aplicar',
    'shared.resetAll': 'Resetear todo',
    'shared.noSearchResult': 'No hay resultados para su solicitud de búsqueda.',
    'shared.next': 'Siguiente',
    'shared.back': 'Atrás',
    'shared.complete': 'Completado',
    'shared.no': 'No',
    'shared.notFound': 'No se encontró',
    'shared.noResults': 'No se han encontrado resultados',
    'shared.alt.noResults': 'No hay resultados',
    'shared.topup': 'Cargar Saldo',
    'shared.subtract': 'Sustraer',
    'shared.undo': 'Deshacer',
    'shared.clear': 'Limpiar',
    'shared.clearAll': 'Limpiar todo',
    'shared.notApplicable': 'No aplica',
    'shared.today': 'Hoy',
    'shared.yesterday': 'Ayer',
    'shared.otherDay': '{date}',
    'shared.formatTimeDays': '{count} {count, plural, one {día} other {días}} atrás',
    'shared.formatTimeHours': '{count} {count, plural, one {hora} other {horas}} atrás',
    'shared.formatTimeMinutes': '{count} min atrás',
    'shared.male': 'Masculino',
    'shared.female': 'Femenino',
    'shared.other': 'Otro',
    'shared.saveChanges': 'Guardar cambios',

    'shared.alt.logo': 'Logo de Clinix',
    'shared.alt.clinicLogo': 'Logo de {clinicName}',
    'shared.alt.downloadAppStore': 'Descargar App Store',
    'shared.alt.downloadGooglePlay': 'Descargar Google Play',

    'shared.filters.namespace.doctors': 'Doctor',
    'shared.filters.namespace.requests': 'Peticiones',
    'shared.filters.namespace.patients': 'Paciente',
    'shared.filters.namespace.specialities': 'Especialidades',
    'shared.filters.namespace.symptoms': 'Síntomas',
    'shared.filters.namespace.batches': 'Lotes',
    'shared.filters.namespace.clinics': 'Clínicas',
    'shared.filters.defaultLabel': 'Todos',
    'shared.filters.statuses.title': 'Por estado',
    'shared.filters.statuses.all': 'Todo',
    'shared.filters.statuses.active': 'Activo',
    'shared.filters.statuses.banned': 'Baneado',
    'shared.filters.statuses.invited': 'Invitado',
    'shared.filters.statuses.inactive': 'Desactivado',
    'shared.filters.statuses.deactivated': 'Desactivado',
    'shared.filters.statuses.confirmed': 'Confirmado',
    'shared.filters.statuses.pending': 'Pendiente',
    'shared.filters.statuses.new': 'Nuevo',
    'shared.filters.statuses.accepted': 'Aceptado',
    'shared.filters.statuses.rejected': 'Rechazado',
    'shared.filters.statuses.current': 'Actual',
    'shared.filters.statuses.finished': 'Finalizado',
    'shared.filters.appliedFilters': '{count} filtros',
    'shared.filters.wellness.title': 'Wellness',
    'shared.filters.wellness.all': 'Todo',
    'shared.filters.wellness.active': 'Activo',
    'shared.filters.wellness.inactive': 'No Suscripto',
    'shared.activate': 'Activar',
    'shared.accept': 'Aceptar',
    'shared.reject': 'Rechazar',
    'shared.deactivate': 'Desactivar',
    'shared.active': 'Activo',
    'shared.ban': 'Ban',
    'shared.confirm': 'Confirmar',
    'shared.edit': 'Editar',
    'shared.copy': 'Copiar',
    'shared.assign': 'Asignar',
    'shared.create': 'Crear',
    'shared.change': 'Cambiar',
    'shared.upload': 'Subir',
    'shared.remove': 'Remover',
    'shared.delete': 'Borrar',
    'shared.discardChanges': '¿Descartar los cambios?',
    'shared.headsUp': 'Atención!',
    'shared.cancelText': '¿Estás seguro de que quieres descartar los cambios?',
    'shared.update': 'Actualizar',
    'shared.enable': 'Habilitar',
    'shared.disable': 'Disabilitar',
    'shared.done': 'Hecho',
    'shared.left': 'Izquierda',
    'shared.right': 'Derecha',
    'shared.top': 'Arriba',
    'shared.bottom': 'Abajo',
    'shared.retry': 'Intentar nuevamente',
    'shared.areYouSureYouWantToRemove': '¿Estás seguro de que quieres eliminar a {name}?',
    'shared.download': 'Descargar',
    'shared.downloadCsv': 'Descargar Archivos CSV',
    'shared.downloadAppointmentDetails': 'Descargar detalles de la cita',
    'shared.attachment': 'Archivo Adjunto',
    'shared.leave': 'Abandonar',
    'shared.leaveConsultation': 'Salir de la consulta',
    'shared.join': 'Unirse',
    'shared.disabledWhileEditing': 'Desactivado durante la edición.',
    'shared.saveOrDiscard': 'Guardar o descartar para continuar con la navegación.',
    'shared.youAreOfflineNow': 'Estás desconectado ahora',
    'shared.hide': 'Esconder',
    'shared.show': 'Mostrar',
    'shared.showMore': 'Mostrar más',
    'shared.gotIt': 'Entendido',
    'shared.helpCenter': 'Centro de ayuda',
    'shared.skip': 'Saltar',
    'shared.add': 'Agregar',
    'shared.finish': 'Finalizar',
    'shared.free': 'Gratis',
    'shared.exit': 'Salir',
    'shared.showAll': 'Mostrar todo',
    'shared.hideAll': 'Esconder todo',
    'shared.cvField.pleaseUploadValidSizeFile': 'Porfavor, subir un archivo hasta 5MB',
    'shared.cvField.nofollowFormat': 'Solamente son soportados PDF, DOC, DOCX, y JPEG',
    'shared.files': 'Archivos',
    'shared.clinic': 'Clínica',
    'shared.phoneNumber': 'Número de Teléfono:',
    'shared.email.description': 'Correo electrónico:',
    'shared.balance': 'Balance',
    'shared.yes': 'Si',
    'shared.otherDiagnose': 'Otro diagnósticos',
    'shared.freeVisit': 'Visita Gratuita',
    'shared.changeTimeZone': 'Cambiar Zona Horaria',
    'shared.changeTimeZoneText': '¿Está seguro de que desea cambiar la zona horaria? La configuración de su disponibilidad periódica y específica de la fecha se restablecerá a la predeterminada.',
    'shared.invite': 'Invitar',
    'shared.sendInvitations': 'Enviar invitación',
    'shared.resendInvitation': 'Reenviar invitación',
    'shared.cancelInvitation': 'Cancelar invitación',
    'shared.removeInvitation': 'Remover invitación',
    'shared.resetAuthSettings': 'Restablecer la configuración de autenticación',
    'shared.setCommission': 'Establecer comisión',
    'shared.percentage': 'Porcentaje',
    'shared.minimum': 'Mínimo',
    'shared.maximum': 'Máximo',
    'shared.set': 'Establecer',
    'shared.changeDoctorTimeZoneText': '¿Seguro que quieres cambiar la zona horaria? La configuración de disponibilidad periódica y específica de la fecha del médico se restablecerá a los valores predeterminados.',
    'shared.yourPhoneNumberConnectedWithWhatsApp': 'Su número de teléfono está conectado a las notificaciones por WhatsApp. Todas las notificaciones de WhatsApp le llegaran a este número.',
    'shared.changePhoneNumber': 'Cambiar número de teléfono',
    'shared.assignToSpeciality': 'Asignar a una especialidad',
    'shared.assignDoctors': 'Agregar profesionales',
    'shared.more.small': 'más',
    'shared.less.small': 'menos',
    'shared.all': 'Todo',
    'shared.allOptionsAdded': 'Todas las opciones añadidas',

    'role.independentDoctor': 'Profesional Independiente',
    'role.virtualClinicAdmin': 'Administrador de clínica virtual',
    'role.virtualClinicDoctor': 'Profesional de la clínica virtual',

    'status.active': 'Activo',
    'status.inactive': 'Desactivado',
    'status.deactivated': 'Desactivado',
    'status.enabled': 'Activado',
    'status.disabled': 'Desactivado',
    'status.accepted': 'Acceptado',
    'status.rejected': 'Rechazado',
    'status.invited': 'Invitado',
    'status.confirmed': 'Confirmado',
    'status.pending': 'Pendiente',
    'status.new': 'Nuevo',
    'status.used': 'Usado',
    'status.current': 'Actual',
    'status.finished': 'Finalizado',
    'status.banned': 'Baneado',
    'status.paid': 'Pagado',
    'status.refund': 'Reembolsado',
    'status.children': 'Hijo',
    'status.wellness': 'Paciente Wellness',

    'filters.clear': 'Limpiar filtros',

    'tooltips.more': '{count} más...',

    'errorPage.generalNotification': 'Perdón, algo salió mal. Intente volver a cargar la página.',
    'accessErrorPage.title': 'Acceso Prohibido',
    'accessErrorPage.body': 'La página a la que está intentando acceder tiene acceso restringido.',
    'accessErrorPage.button': 'Citas',
    'clientErrorPage.title': 'Oops!',
    'clientErrorPage.body': 'Parece que hemos perdido esta página, pero no queremos perderte.',
    'clientErrorPage.button': 'Consultas',
    'serverErrorPage.title': 'Error del Servidor',
    'serverErrorPage.body': 'Lo siento ... no eres tú, somos nosotros. Por favor, inténtelo de nuevo más tarde',

    'pageTitle.userInvitationSignup': 'Invitación',
    'pageTitle.onBoarding': 'Inducción',
    'pageTitle.joinRequest': 'Solicitud para unirse',
    'pageTitle.login': 'Conectarse',
    'pageTitle.resetPasswordConfirm': 'Confirmar restablecimiento de contraseña',
    'pageTitle.resetPasswordRequest': 'Solicitud para restablecer la contraseña',
    'pageTitle.resetPasswordLinkExpired': 'El enlace de restablecimiento de contraseña caducó',
    'pageTitle.accountSettings': 'Configuración de cuenta',
    'pageTitle.accountAuth': 'Autenticación de cuenta',
    'pageTitle.userProfile': 'Perfíl del Usuario',
    'pageTitle.editUserProfile': 'Editar Perfíl del Usuario',
    'pageTitle.clinicSpecialities': 'Especialidades de la Clínica',
    'pageTitle.createClinicSpeciality': 'Crear una Especialidad de la Clínica',
    'pageTitle.createClinicSpecialityAssignDoctors': 'Crear una Especialidad de la Clínica - Agregar Profesionales',
    'pageTitle.editClinicSpeciality': 'Editar Especialidades de la Clínica',
    'pageTitle.editClinicSpecialityDoctors': 'Editar Especialidades de la Clínica - Agregar Profesionales',
    'pageTitle.specialities': 'Especialidades',
    'pageTitle.createSpeciality': 'Crear Especialidades',
    'pageTitle.editSpeciality': 'Editar Especialidades',
    'pageTitle.regularAvailability': 'Disponibilidad Regular',
    'pageTitle.dateAvailability': 'Disponibilidad específica por fecha',
    'pageTitle.appointments': 'Citas',
    'pageTitle.patients': 'Pacientes',
    'pageTitle.patientAppointments': 'Citas de pacientes',
    'pageTitle.joinRequests': 'Solicitudes para unirse',
    'pageTitle.appointmentDetails': 'Detalle de Citas',
    'pageTitle.doctorProfile': 'Perfil del Profesional',
    'pageTitle.virtualClinicDoctorProfile': 'Perfil del profesional de la clínica virtual',
    'pageTitle.virtualClinicAdminProfile': 'Perfil del administrador de la clínica virtual',
    'pageTitle.editVirtualClinicAdminProfile': 'Editar el perfil de administrador de la clínica virtual',
    'pageTitle.editDoctorProfile': 'Editar perfil del profesional',
    'pageTitle.doctors': 'Profesional',
    'pageTitle.balance': 'Balance',
    'pageTitle.symptoms': 'Síntomas',
    'pageTitle.revenue': 'Ingresos',
    'pageTitle.revenue.independentDoctor': 'Ingresos de profesionales independientes',
    'pageTitle.revenue.virtualClinicsRevenue': 'Ingresos de clínicas virtuales',
    'pageTitle.createSymptom': 'Crear Síntomas',
    'pageTitle.vouchers': 'Tarjeta de Saldo',
    'pageTitle.voucherDetails': 'Detalles de las tarjertas de saldo',
    'pageTitle.batchDetails': 'Detalles de lote',
    'pageTitle.editSymptom': 'Editar Síntomas',
    'pageTitle.patientAccount': 'Cuenta',
    'pageTitle.patientProcessingTopup': 'Espere mientras procesamos su pago',
    'pageTitle.enterVoucherCode': 'Ingrese el código de la tarjeta de saldo',
    'pageTitle.videoConference': 'Video conferencia',
    'pageTitle.editPatientProfile': 'Editar perfil del Paciente',
    'pageTitle.editChildrenProfile': 'Editar perfil de su Hijo',
    'pageTitle.downloadApp': 'Descargar App',
    'pageTitle.virtualClinics': 'Clínicas Virtuales',
    'pageTitle.clinicProfile': 'Perfíl de la Clínica',
    'pageTitle.independentDoctorCommissions': 'Comisiones de profesionales independientes',
    'pageTitle.virtualClinicCommissions': 'Comisión de clínicas virtuales',
    'pageTitle.virtualClinicDoctors': 'Profesionales de la clínica virtual',
    'pageTitle.virtualClinicDoctorsCommissions': 'Comisiones de profesionales de clínicas virtuales',
    'pageTitle.pushNotifications': 'Notificaciones',
    'pageTitle.freeVisits': 'Visitas gratuitas',
    'pageTitle.dashboards': 'Dashboards',

    'users.doctorInvitationSignup.inviteTokenStale': 'Este enlace de invitación ya no está activo',
    'users.doctorInvitationSignup.independentDoctor': 'Registrarse como Profesional individual',
    'users.doctorInvitationSignup.virtualClinicAdmin': 'Regístrese como administrador de la clínica virtual',
    'users.doctorInvitationSignup.virtualClinicDoctor': 'Regístrese como profesional de la clínica virtual',

    'users.signUp.userJoinRequest.titleJoinRequest': 'Solicitud para unirse',
    'users.signUp.userJoinRequest.generalInformation': 'Información General',
    'users.signUp.userJoinRequest.successJoinRequest': '¡Gracias! Su solicitud ha sido enviada a Clinix',

    'resetPassword.strength.low': 'Puede utilizar caracteres en mayúscula, números y símbolos para fortalecer su contraseña',
    'resetPassword.strength.strong': 'Tu contraseña es lo suficientemente segura',

    'login.email': 'Correo electrónico',
    'login.password': 'Contraseña',
    'login.forgotPassword': '¿Olvidaste tu contraseña?',
    'login.privacyPolicy': 'Política de Privacidad',
    'login.terms': 'Terminos',
    'login.privacyPolicyAndTerms': 'Al iniciar sesión, usted acepta nuestro {terms} y que has leído la {privacyPolicy}',
    'login.verificationFlow.title': 'Autenticación de dos factores',
    'login.verificationFlow.viewCodeOnYourDevice': 'Abra la aplicación de autenticación de dos factores en su dispositivo para ver su código de verificación de 6 dígitos y verificar su identidad.',
    'login.verificationFlow.havingProblems': '¿Esta teniendo problemas?',
    'login.verificationFlow.enterRecoveryCode': 'Ingrese un código de recuperación de dos factores',
    'login.loginYourAccount': 'Ingrese a su cuenta',

    'login.recoveryFlow.title': 'Recuperación del código de autenticacón de dos factores',
    'login.recoveryFlow.recoveryCodesIfYouLostMobile': 'Puede ingresar uno de sus códigos de recuperación en caso de que pierda el acceso a su dispositivo móvil.',
    'login.recoveryFlow.cantFindVerificationCode': '¿No puede encontrar su código de verificación?',
    'login.recoveryFlow.callUsAt': 'Llamá al número: {phoneNumber}',

    'label.firstName': 'Nombre',
    'label.lastName': 'Apellido',
    'label.idNumber': 'Número de documento',
    'label.email': 'Correo electrónico',
    'label.role': 'Rol',
    'label.phoneNumber': 'Número de teléfono',
    'label.cv': 'Adjunta CV',
    'label.about': 'Acerca',
    'label.aboutMe': 'Acerca de mí',
    'label.aboutVirtualClinic': 'Acerca de clínica',
    'label.licenseNumber': 'Número de Registro',
    'label.signature': 'Firma',
    'label.authCode': 'Código de autenticación',
    'label.recoveryCode': 'Código de recuperación',
    'label.currentPassword': 'Contraseña actual',
    'label.newPassword': 'Contraseña nueva',
    'label.timezone': 'Zona horaria',
    'label.country': 'País',
    'label.city': 'Ciudad',
    'label.speciality': 'Especialidad',
    'label.symptom': 'Síntoma',
    'label.symptoms': 'Síntomas',
    'label.specialityName': 'Nombre de especialidad',
    'label.duration': 'Duración de la consulta',
    'label.acceptFreeVisits': 'Aceptar visitas gratuitas',
    'label.bufferBefore': 'Búfer antes',
    'label.bufferAfter': 'Búfer después',
    'label.minLeadTime': 'Tiempo de ejecución mínimo',
    'label.maxFutureTime': 'Máximo tiempo futuro',
    'label.anytime': 'En cualquier momento',
    'label.active': 'Activado',
    'label.inactive': 'Desactivado',
    'label.pricePerSession': 'Precio por sesión',
    'label.symptomName': 'Nombre del síntoma',
    'label.description': 'Descripción',
    'label.question': 'Pregunta',
    'label.type': 'Tipo',
    'label.specialities': 'Especialidades',
    'label.numberOfVouchers': 'Número de Terjetas de Saldo',
    'label.value': 'Valor',
    'label.batchName': 'Nombre del lote',
    'label.commentAddress': 'Comentario / Dirección',
    'label.amount': 'Cantidad',
    'label.chiefComplaint': 'Motivo de Consulta',
    'label.subjective': 'Subjetivo',
    'label.objective': 'Objetivo',
    'label.assessment': 'Evaluación',
    'label.primaryDiagnose': 'Diagnóstico Principal',
    'label.secondaryDiagnose': 'Diagnóstico Secundario',
    'label.drug': 'Droga',
    'label.commercialName': 'Nombre comercial (opcional)',
    'label.dose': 'Dosis',
    'label.frequency': 'Frecuencia',
    'label.quantity': 'Cantidad',
    'label.comment': 'Comentario',
    'label.test': 'Examen',
    'label.voucherCode': 'Código de tarjeta de saldo',
    'label.other': 'Otros',
    'label.otherAmount': 'Cantidad de dinero',
    'label.virtualClinic': 'Clínica virtual',
    'label.companyUrlOptional': 'URL de la Empresa (opcional)',
    'label.cityOptional': 'Ciudad (opcional)',
    'label.addressOptional': 'Dirección (opcional)',
    'label.consultationFee': 'Precio de la consulta {sign}',
    'label.percentageSign': 'Porcentaje {sign}',
    'label.secretNote': 'Estas notas solo serán visibles para otros médicos',
    'label.emailNotification': 'Notificaciones por e-mail',
    'label.whatsappNotification': 'Notificaciones por WhatsApp',
    'label.commission': 'Commisión',
    'label.minimum': 'Mínimo',
    'label.maximum': 'Máximo',
    'label.minimumSign': 'Mínimo {sign}',
    'label.maximumSign': 'Máximo {sign}',
    'label.doctors': 'Profesionales',
    'label.gender': 'Género',
    'label.children': 'Hijo',
    'label.age.from': 'Edad (desde)',
    'label.age.to': 'Edad (hasta)',
    'label.pushNotificationText': 'Texto de la notificación Push',
    'label.clinic': 'Clínica',
    'label.patients': 'Pacientes',
    'label.freeVisits': 'Visitas gratuitas',
    'label.wellness': 'Paciente wellness',

    'label.sign.percent': '(%)',
    'label.sign.currencyGs': '(Gs)',

    'label.editor.blocktype.heading': 'Encabezado {count}',
    'label.editor.blocktype.normal': 'Normal',

    'placeholder.about': 'Acerca de tí',
    'placeholder.aboutVirtualClinic': 'Cuéntanos sobre tu clínica',
    'placeholder.companyUrl': 'empresa-url',
    'placeholder.licenseNumber': 'Número de Registro',
    'placeholder.specialities': 'Agregar especialidades',
    'placeholder.symptoms': 'Agregar Síntomas',
    'placeholder.doctors': 'Agregar profesionales',
    'placeholder.selectTimezone': 'Seleccionar Zona horaria',
    'placeholder.selectCountry': 'Seleccionar País',
    'placeholder.speciality': 'Seleccionar Especialidad',
    'placeholder.duration': 'ej. 30m, 1h 30m',
    'placeholder.anytime': 'En cualaquier momento',
    'placeholder.percent': '%',
    'placeholder.pricePerSession': '₲',
    'placeholder.selectPrimaryDiagnose': 'Seleccionar diagnóstico principal',
    'placeholder.selectSecondaryDiagnose': 'Seleccioanr diagnóstico secundario',
    'placeholder.selectGender': 'Seleccione género',
    'placeholder.selectChildren': 'Seleccionar niños',
    'placeholder.addClinic': 'Agregar clínica',
    'placeholder.addPatients': 'Agregar pacientes',

    'pagination.first': 'Primero',
    'pagination.last': 'Último',

    'notePrescription.tablet': 'Comprimido',
    'notePrescription.capsule': 'Cápsula',
    'notePrescription.ampule': 'Ampolla',
    'notePrescription.application': 'Aplicación',
    'notePrescription.spoonful_small': 'Cucharada',
    'notePrescription.spray': 'Spray',
    'notePrescription.suppository': 'Supositorio',
    'notePrescription.dropsgrams': 'Gotas',
    'notePrescription.prefilled_syringe': 'Jeringa Prellenada',
    'notePrescription.vaginal_ovule': 'Ovulo Vaginal',
    'notePrescription.milligram': 'mg',
    'notePrescription.millilitre': 'ml',
    'notePrescription.microgram': 'μg',
    'notePrescription.gram': 'UI',
    'notePrescription.dose': 'Dosis',
    'notePrescription.puff': 'Puff',
    'notePrescription.times': '{count} {count, plural, one {vez} other {veces}}',
    'notePrescription.once': 'Única vez',
    'notePrescription.daily': 'Diariamente',
    'notePrescription.weekly': 'Semanalmente',
    'notePrescription.monthly': 'Mensualmente',
    'notePrescription.yearly': 'Anualmente',
    'notePrescription.box': 'Caja',
    'notePrescription.jar': 'Frasco',
    'notePrescription.bottle': 'Botella',
    'notePrescription.tube': 'Tubo',
    'notePrescription.spray_bottle': 'Inhalador',

    'twoFactorAuth.recoveryTitle': 'Código de Recuperación',
    'twoFactorAuth.scanTitle': 'Escanee este código QR con su aplicación',
    'twoFactorAuth.recoveryCodeAreUsedAccess': 'Los códigos de recuperación se utilizan para acceder a su cuenta en caso de que no pueda recibir códigos de autenticación de dos factores.',
    'twoFactorAuth.scanImageAboveOnYourPhone': 'Escanee la imagen de arriba con la aplicación de autenticación de dos factores en su teléfono. Descarga el autenticador de Google - {appStore} o {playMarket}',
    'twoFactorAuth.appStore': 'App store',
    'twoFactorAuth.playMarket': 'Google Play',
    'twoFactorAuth.copied': 'Mis códigos de recuperación están copiados!',
    'twoFactorAuth.pleaseSaveRecoveryCodes': 'Por favor, guarde sus códigos de recuperación',
    'twoFactorAuth.afterScanningDisplaySixDigits': 'Después de escanear la imagen del código QR, la aplicación mostrará un código de seis dígitos que puede ingresar a continuación.',
    'twoFactorAuth.label.enterTheCode': 'Ingrese el código',
    'twoFactorAuth.putCodesInSaveSpot': 'Guardalos en un lugar seguro. Si pierde su dispositivo y no tiene los códigos de recuperación, perderá el acceso a su cuenta.',

    'onboarding.userAccount.title': 'Ayúdanos a configurar tu cuenta',
    'onboarding.userAccount.generalInfoTitle': 'Información General',
    'onboarding.userAccount.locationTitle': 'Localización',
    'onboarding.userAccount.companyProfileTitle': 'Perfil de la Empresa',
    'onboarding.userAccount.general.pleaseProvideValidPhoneNumber': 'Por favor, proporcione un número de teléfono válido para que podamos comunicarnos con usted. No mostraremos su número de teléfono a los pacientes.',
    'onboarding.userAccount.helpInformation': 'La URL de su empresa solo puede contener letras minúsculas, números, puntos, dos puntos, barras diagonales y guiones.',

    'resetPassword.title': 'Resetear contraseña',
    'resetPassword.info': 'Ingrese la dirección de correo electrónico que utilizó al crear su cuenta y le enviaremos un enlace de recuperación.',
    'resetPassword.submit': 'Enviar enlace de recuperación',
    'resetPassword.backToLogin': 'Atrás para iniciar sesión',
    'resetPassword.resendLink': 'Reenviar enlace de recuperación',
    'resetPassword.checkEmail': 'Consultar su correo electrónico',
    'resetPassword.sendRecoveryLink': 'Enviamos un enlace de recuperación a su dirección de correo electrónico:',
    'resetPassword.requestNewLink': '¿No recibiste el correo electrónico? Revise su correo no deseado o solicite otro.',
    'resetPassword.newPassword': 'Nueva contraseña',
    'resetPassword.resetPasswordButton': 'Resetear contraseña',
    'resetPassword.setNewPassword': 'Necesita establecer una nueva contraseña para su cuenta.',
    'resetPassword.createNewPassword': 'Crear nueva contraseña',
    'resetPassword.linkExpired.title': 'El enlace para restablecer la contraseña ha caducado.',
    'resetPassword.linkExpired.button': 'Ingresar',
    'resetPassword.modal.body': '¿Está seguro de que desea restablecer su contraseña? Se cerrará la sesión y se le enviarán instrucciones para restablecer la contraseña a su correo electrónico',

    'notifications.resetPasswordSuccess': 'Su contraseña ha sido actualizada',
    'notifications.resentRecoveryLink': 'El enlace de recuperación ha sido reenviado.',
    'notifications.resetPasswordInstructions': 'Se enviaron instrucciones para restablecer la contraseña a su correo electrónico',
    'notifications.successSubmitAccountTwoFactorAuth': 'Se ha conectado un nuevo dispositivo',
    'notifications.accountDeactivated': 'La cuenta ha sido desactivada',
    'notifications.yourEmailHasBeenChanged': 'Se ha cambiado tu correo electrónico',
    'notifications.emailHasBeenConfirmed': 'Su nueva dirección de correo electrónico ha sido confirmada.',
    'notifications.invalidLink': 'Enlace inválido',
    'notifications.clinicSpecialitiesActivated': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido activada} other {han sido activadas}}',
    'notifications.clinicSpecialitiesDeactivated': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido desactivada} other {han sido desactivadas}}',
    'notifications.clinicSpecialitiesRemoved': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido removida} other {han sido removidas}}',
    'notifications.clinicSpecialitiesRemovedError': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {no ha sido removida} other {no han sido removidas}}',
    'notifications.clinicSpecialityAdded': 'La Especialidad ha sido agregada',
    'notifications.clinicSpecialityUpdated': 'La Especialidad ha sido actualizada',
    'notifications.clinicSpecialityAssignDoctors': '{count} {count, plural, one {profesional} other {profesionales}} {count, plural, one {ha sido asignado} other {han sido asignados}}',
    'notifications.userProfileUpdated': 'Su Perfíl ha sido actualizado',
    'notifications.userProfileUpdatedError': 'Su Perfíl no ha sido actualizado',
    'notifications.regularAvailabilityUpdated': 'Se ha actualizado la disponibilidad regular',
    'notifications.dateAvailabilityUpdated': 'Se actualizó la disponibilidad específica por fecha',
    'notifications.appointmentCancelled': 'El evento ha sido cancelado',
    'notifications.joinRequestAccepted': 'La solicitud ha sido aceptada',
    'notifications.joinRequestRejected': 'La solicitud ha sido rechazada',
    'notifications.joinRequestRemoved': 'La solicitud ha sido removida',
    'notifications.profileImageAdded': 'La imagen de perfil ha sido añadido',
    'notifications.profileImageUpdated': 'La imagen de perfil ha sido actualizada',
    'notifications.profileImageRemoved': 'La imagen de perfil ha sido removida',
    'notifications.profileImageAddedError': 'La imagen de perfil no ha sido añadido',
    'notifications.profileImageUpdatedError': 'La imagen de perfil no ha sido actualizada',
    'notifications.profileImageRemovedError': 'La imagen de perfil no ha sido removida',
    'notifications.doctorsProfileUpdated': 'El perfil del médico se ha actualizado',
    'notifications.doctorsProfileUpdatedError': 'El perfil del médico no se ha actualizado',
    'notifications.doctorsInvited': '{count, plural, one {Profesional independiente} other {Profesionales independientes}} {count, plural, one {ha sido invitado} other {han sido invitados}}',
    'notifications.doctorsInvitationResent': 'La invitación ha sido reenviada',
    'notifications.doctorsInvitationResentError': 'La invitación no ha sido reenviada',
    'notifications.resetAuthentication': 'Su configuración de autenticación se ha restablecido correctamente',
    'notifications.resetAuthenticationError': 'No se pudo restablecer la configuración de autenticación. Por favor revise los campos resaltados',
    'notifications.doctorsInvitationRemove': 'La invitación ha sido removida',
    'notifications.doctorsInvitationRemoveError': 'La invitación no ha sido removida',
    'notifications.doctorsInvitationRemoveAcceptedUserError': 'Este médico ya es miembro de Clinix',
    'notifications.doctorActivated': 'La cuenta ha sido activada',
    'notifications.doctorActivatedError': 'La cuenta no ha sido activada',
    'notifications.doctorDeactivated': 'La cuenta ha sido desactivada',
    'notifications.doctorDeactivatedError': 'La cuenta no ha sido desactivada',
    'notifications.doctorAssignClinicSpecialities': '{count} {count, plural, one {especialidad} other {especialidades}} {count, plural, one {ha sido asignado} other {han sido asignados}}',
    'notifications.yourSymptomImageUpdated': 'La imagen del síntoma se ha actualizado',
    'notifications.yourSymptomImageRemoved': 'La imagen del síntoma se ha removido',
    'notifications.yourSymptomImageUpdatedError': 'La imagen del síntoma no se ha actualizado',
    'notifications.yourSymptomImageRemovedError': 'La imagen del síntoma no se ha removido',
    'notifications.symptomsActivated': '{count, plural, one {Síntoma} other {Síntomas}} {count, plural, one {ha sido activado} other {han sido activados}}',
    'notifications.symptomsActivatedError': '{count, plural, one {Síntoma} other {Síntomas}} {count, plural, one {no ha sido activado} other {no han sido activados}}',
    'notifications.symptomsDeactivated': '{count, plural, one {Síntoma} other {Síntomas}} {count, plural, one {ha sido desactivado} other {han sido desactivados}}',
    'notifications.symptomsDeactivatedError': '{count, plural, one {Síntoma} other {Síntomas}} {count, plural, one {no ha sido desactivado} other {no han sido desactivados}}',
    'notifications.patientActivated': 'El paciente ha sido activado',
    'notifications.patientActivatedError': 'El paciente no ha sido activado',
    'notifications.patientDeactivated': 'El paciente ha sido desactivado',
    'notifications.patientDeactivatedError': 'El paciente no ha sido desactivado',
    'notifications.patientBanned': 'El paciente ha suspendido',
    'notifications.patientBannedError': 'El paciente ha sido suspendido',
    'notifications.copyToClipboard': 'Copiado al portapapeles',
    'notifications.specialitiesDeleted': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido eliminada} other {han sido eliminadas}}',
    'notifications.specialitiesDeletedError': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {no ha sido eliminada} other {no han sido eliminadas}}',
    'notifications.specialitiesActivated': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido activada} other {han sido activadas}}',
    'notifications.specialitiesActivatedError': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {no ha sido activada} other {no han sido activadas}}',
    'notifications.specialitiesDeactivated': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {ha sido desactivada} other {han sido desactivadas}}',
    'notifications.specialitiesDeactivatedError': '{count, plural, one {Especialidad} other {Especialidades}} {count, plural, one {no ha sido desactivada} other {no han sido desactivadas}}',
    'notifications.specialityCreated': 'La Especialidad ha sido creada',
    'notifications.specialityUpdated': 'La Especialidad ha sido actualizada',
    'notifications.addedNewSymptom': 'Un Síntoma nuevo ha sido agregado',
    'notifications.vouchersDeleted': '{count, plural, one {Tarjeta de saldo} other {Tarjetas de saldo}} {count, plural, one {ha sido eliminada} other {han sido eliminadas}}',
    'notifications.vouchersDeletedError': '{count, plural, one {Tarjeta de saldo} other {Tarjetas de saldo}} {count, plural, one {no ha sido eliminada} other {no han sido eliminadas}}',
    'notifications.fileHasBeenDownloaded': 'El archivo ha sido descargado',
    'notifications.csvDownloadFailed': 'Los archivos CVS no fueron descargados',
    'notifications.fileHasBeenDeleted': 'El archivo ha sido eliminado',
    'notifications.fileHasBeenDeletedError': 'El archivo no ha sido eliminado',
    'notifications.vouchersGenerated': '{count, plural, one {Tarjeta de saldo} other {Tarjetas de saldo}} {count, plural, one {ha sido generada} other {han sido generadas}}',
    'notifications.batchUpdated': 'EL lote ha sido actualizado',
    'notifications.symptomDetailsUpdated': 'Los detalles del síntomas se han actualizado',
    'notifications.questionnaireUpdated': 'Se actualizó el cuestionario',
    'notifications.specialitiesUpdated': 'Se han actualizado las especialidades',
    'notifications.topupSuccessfully': 'Ha cargado con éxito este monto de saldo: {amount}',
    'notifications.subtractSuccessfully': 'Has restado con éxito {amount} de la cuenta del paciente \n',
    'notifications.addNoteSuccessfully': 'Se ha creado una nueva nota',
    'notifications.updateNoteSuccessfully': 'La nota se ha actualizado',
    'notifications.diagnoseAlreadyAdded': 'Diagnóstico ya agregado',
    'notifications.pleaseLoginToConfirmYourEmail': 'Inicie sesión para confirmar su correo electrónico',
    'notifications.patientProfileUpdated': 'El perfil del paciente se ha actualizado',
    'notifications.yourCurrentSessionHasBeenExpired': 'Tu sesión actual ha caducado',
    'notifications.virtualClinicAdminInvited': 'Se ha invitado al {count, plural, one {administrador} other {administradores}} de la clínica virtual',
    'notifications.virtualClinicAdminInvitedError': 'No Se ha invitado al {count, plural, one {administrador} other {administradores}} de la clínica virtual',
    'notifications.virtualClinicInvitationResent': 'La invitación ha sido reenviada',
    'notifications.virtualClinicInvitationResentError': 'La invitación no ha sido reenviada',
    'notifications.virtualClinicInvitationCancelled': 'La invitación ha sido cancelado',
    'notifications.virtualClinicInvitationCancelledError': 'La invitación no ha sido cancelado',
    'notifications.virtualClinicDeactivated': 'La clínica virtual ha sido desactivada',
    'notifications.virtualClinicDeactivatedError': 'La clínica virtual ha sido desactivada',
    'notifications.virtualClinicActivated': 'La clínica virtual ha sido activada',
    'notifications.virtualClinicActivatedError': 'No se ha activado la clínica virtual',
    'notifications.virtualClinicResetAuthentication': 'Se restableció la configuración de autenticación',
    'notifications.virtualClinicResetAuthenticationError': 'La configuración de autenticación no se ha restablecido',
    'notifications.virtualClinicProfileHasBeenUpdated': 'Se ha actualizado el perfil de la clínica virtual',
    'notifications.virtualClinicActivatedExtraFreeVisits': 'Se han activado visitas extra gratis',
    'notifications.virtualClinicActivatedExtraFreeVisitsError': 'No se han activado las visitas gratuitas adicionales',
    'notifications.virtualClinicDeactivatedExtraFreeVisits': 'Se han desactivado las visitas gratuitas adicionales',
    'notifications.virtualClinicDeactivatedExtraFreeVisitsError': 'No se han desactivado las Visitas extra gratis',
    'notifications.commissionSettingsHaveBeenUpdated': 'La configuración de la comisión se ha actualizado',
    'notifications.preferencesUpdated': 'Tus preferencias se han actualizado',
    'notifications.preferencesError': 'Tus preferencias no se han actualizado',
    'notifications.csvDownloadPatientsSuccess': 'Los datos se están cargando, recibirá un correo electrónico cuando sus datos estén listos',
    'notifications.csvDownloadPatientsFailed': 'Se produjo un error al exportar el archivo CSV',
    'notifications.linkCopied': 'Enlace copiado al portapapeles',
    'notifications.clinicLogoAdded': 'El logotipo de la clínica se ha añadido',
    'notifications.clinicLogoUpdated': 'El logotipo de la clínica se ha actualizado',
    'notifications.clinicLogoRemoved': 'El logotipo de la clínica se ha removido',
    'notifications.clinicLogoAddedError': 'El logotipo de la clínica no se ha añadido',
    'notifications.clinicLogoUpdatedError': 'El logotipo de la clínica no se ha actualizado',
    'notifications.clinicLogoRemovedError': 'El logotipo de la clínica no se ha removido',
    'notifications.profileSignatureAdded': 'La firma del perfil se ha añadido',
    'notifications.profileSignatureUpdated': 'La firma del perfil se ha actualizado',
    'notifications.profileSignatureRemoved': 'La firma del perfil se ha removido',
    'notifications.profileSignatureAddedError': 'La firma del perfil no se ha añadido',
    'notifications.profileSignatureUpdatedError': 'La firma del perfil no se ha actualizado',
    'notifications.profileSignatureRemovedError': 'La firma del perfil no se ha removido',
    'notifications.pushNotificationHasBeenSend': 'La notificación push fue enviada',
    'notifications.freeVisitsHasBeenUpdated': 'Se han actualizado las visitas gratuitas',
    'notifications.freeVisitsHasBeenUpdatedError': 'Las visitas gratuitas no se han actualizado',

    'userSidebar.appointments': 'Consultas',
    'userSidebar.availability': 'Disponibilidad',
    'userSidebar.regularAvailability': 'Disponibilidad regular',
    'userSidebar.dateSpecificAvailability': 'Disponibilidad específica por fecha',
    'userSidebar.clinicSpecialities': 'Especialidades Clínicas',
    'userSidebar.specialities': 'Especialidades',
    'userSidebar.patients': 'Pacientes',
    'userSidebar.independentDoctors': 'Profesionales Independientes',
    'userSidebar.independentDoctors.requests': 'Solicitudes',
    'userSidebar.independentDoctors.commission': 'Commisión',
    'userSidebar.independentDoctors.doctors': 'Profesionales',
    'userSidebar.symptoms': 'Síntomas',
    'userSidebar.vouchers': 'Tarjetas de Saldo',
    'userSidebar.revenue': 'Ingresos',
    'userSidebar.revenue.independentDoctors': 'Profesionales Independientes',
    'userSidebar.revenue.virtualClinics': 'Clínicas Virtuales',
    'userSidebar.revenue.total': 'Total',
    'userSidebar.virtualClinics': 'Clínicas Virtuales',
    'userSidebar.virtualClinics.clinics': 'Clínicas',
    'userSidebar.virtualClinics.doctors': 'Profesionales',
    'userSidebar.virtualClinics.requests': 'Solicitudes',
    'userSidebar.virtualClinics.commission': 'Commisión',
    'userSidebar.clinicProfile': 'Perfil de la Clínica',
    'userSidebar.doctorsAndAvailability': 'Profesionales y Disponibilidad',
    'userSidebar.doctorsAndAvailability.doctors': 'Profesionales',
    'userSidebar.doctorsAndAvailability.recurringAvailability': 'Disponibilidad recurrente',
    'userSidebar.doctorsAndAvailability.dateSpecificAvailability': 'Disponibilidad específica por fecha',
    'userSidebar.doctorsAndAvailability.commission': 'Commisión',
    'userSidebar.pushNotifications': 'Notificaciones Push',
    'userSidebar.dashboards': 'Dashboard',

    'userAccountWidget.accountTitle': 'Cuenta',
    'userAccountWidget.myProfile': 'Mi Perfil',
    'userAccountWidget.accountSettings': 'Configuración de Cuenta',
    'userAccountWidget.logout': 'Cerrar Cesión',

    'accountSettings.setupNewTwoFactorAuth': 'Establecer la app para autenticación de dos factores',
    'accountSettings.setupNewTwoFactorAuthDescription': '{setup}{breakingLine}{recoveryCodes}',
    'accountSettings.youCanSetupTwoFactorAuthWhenLostDevice': 'Puede configurar la app para autenticación de dos factores en otro dispositivo, pero no podrá usar los códigos de su dispositivo anterior. ¡Importante! Crearemos un nuevo conjunto de códigos de recuperación, así que asegúrese de guardarlos en un lugar seguro.',
    'accountSettings.youNeedUpdateRecoveryCodes': 'Si necesita actualizar sus códigos de recuperación, configure una nueva app para autenticación de dos factores en su dispositivo y elimine la anterior.',
    'accountSettings.setupNewTwoFactorAuthBtn': 'Configurar una nueva app de autenticación de dos factores',

    'accountSettings.twoFactorAuth.title': 'Configurar una nueva app de autenticación de dos factores',
    'accountSettings.twoFactorAuth.bodyText': 'Ingrese su contraseña para continuar.',
    'accountSettings.backToView': 'Volver a la vista',

    'accountSettings.password': 'Password',
    'accountSettings.changePassword': 'Cambiar contraseña',
    'accountSettings.feelFreeToUpdateYourPassword': 'No dude en actualizar su contraseña para que su cuenta se mantenga segura.',
    'accountSettings.forgotPassword': '¿Olvidó su contraseña?',

    'accountSettings.updateEmail': 'Actualizar correo electrónico',
    'accountSettings.yourCurrentEmail': 'Tu correo electrónico actual: ',
    'accountSettings.label.newEmail': 'Nuevo correo electrónico',
    'accountSettings.label.password': 'Contraseña actual (si cambia de correo electrónico)',
    'accountSettings.newEmail': 'Le hemos enviado un correo electrónico para confirmar su nueva dirección: {newEmail}.',
    'accountSettings.newEmailConfirmed': 'Continuaremos usando su antigua dirección: {email}, hasta que confirmes el cambio.',
    'accountSettings.emailConfirmed': 'Correo electrónico confirmado',

    'accountSettings.deactivateAccount.title': 'Estado de la cuenta',
    'accountSettings.deactivateAccount.description': 'La cuenta desactivada no será visible para los pacientes. Las citas activas deben realizarse o cancelarse.{breakingLine}NOTA:Este cambio entrará en vigor inmediatamente. Un administrador de su espacio de trabajo deberá volver a habilitar su cuenta si desea volver a unirse a este espacio de trabajo. Todos tus datos serán reactivados.',
    'accountSettings.deactivateAccount.button': 'Desactivar mi cuenta',

    'accountSettings.notificationCapture': 'Recibe notificaciones por e-mail y recordatorios sobre tus consultas.',
    'accountSettings.whatsAppNotificationCapture': 'Recibe notificaciones por WhatsApp y recordatorios sobre tus consultas.',

    'deactivateAccountModal.title': 'Desactivar Cuentat',
    'deactivateAccountModal.areYouSure': '¿Esta seguro de que quiere desactivar su cuenta?{breakingLine}Ingrese su contraseña para desactivar su cuenta.',
    'deactivateAccountModal.label.password': 'Contraseña',
    'deactivateAccountModal.button': 'Si, desactivar mi cuenta',

    'userProfile.location': 'Localización',
    'userProfile.timezone': 'Zona Horaria: {timezone}',
    'userProfile.country': 'País: {country}',
    'userProfile.city': 'Ciudad: {city}',
    'userProfile.address': 'Dirección: {address}',
    'userProfile.about': 'Acerca',
    'userProfile.aboutMe': 'Acerca de mi',
    'userProfile.generalInformation': 'Información General',
    'userProfile.signature': 'Firma',
    'userProfile.signatureWithColon': 'Firma:',
    'userProfile.licenseNumber': 'Número de Registro',
    'userProfile.phoneNumber': 'Número de teléfono',
    'userProfile.freeVisits': 'Visitas gratuitas',
    'userProfile.clinic': 'Clínica',
    'userProfile.myRevenue': 'Mis Ingresos',
    'userProfile.revenue': 'Ingresos',
    'userProfile.platformCommission': 'Comisión de plataforma',
    'userProfile.platformCommissionRate': 'Tasa de comisión de la plataforma',
    'userProfile.virtualClinicCommissionRate': 'Tasa de comisión de la clínica virtual',
    'userProfile.clinicSpeciality': 'Especialidades Clínicas',
    'userProfile.status': 'Estado',
    'userProfile.pricePerSession': 'Precio por sesión',
    'userProfile.pleaseAddClinicSpeciality': 'Por favor, agregue una especialidad clínica para consultar a los pacientes.',
    'userProfile.PleaseProvideYourValidPhoneNumber': 'Por favor, proporcione un número de teléfono válido para que podamos comunicarnos con usted. No mostraremos su número de teléfono a los pacientes.',
    'userProfile.recurringAvailability': 'Disponibilidad recurrente',
    'userProfile.table.day': 'Dia',
    'userProfile.table.workingHours': 'Horas Laborales',
    'userProfile.profileLink': 'Enlace de perfil:',
    'userProfile.copyLink': 'Copiar link',

    'calculator.title': 'Calculadora',
    'calculator.clinixGets': 'Clinix obtiene',
    'calculator.virtualClinicGets': 'La Clínica virtual obtiene',
    'calculator.youGet': 'Usted obtiene',
    'calculator.doctorGet': 'Profesional obtiene',

    'revenue.title': 'Ingresos',
    'revenue.totalRevenue': 'Ingresos totales',
    'revenue.extraStats': 'Estadísticas extra',
    'revenue.statistics': 'Estadísticas',
    'revenue.totalEarned': 'Ganancia total',
    'revenue.totalAppointments': 'Total de Consultas',
    'revenue.totalHours': 'Total de Horas',
    'revenue.doctorRevenue': 'Ingresos de Profesional',
    'revenue.doctorsRevenue': 'Ingresos de Profesionales',
    'revenue.platformRevenue': 'Ingresos de la Plataforma',
    'revenue.totalConsultationsRevenue': 'Ingresos totales de consultas',
    'revenue.virtualClinicRevenue': 'Ingresos de la clínica virtual',
    'revenue.clinics': 'Clínicas',
    'revenue.appointments': 'Consultas',
    'revenue.appointmentDetails': 'Detalle de la consulta',
    'revenue.speciality': 'Especialidad',
    'revenue.price': 'Precio',
    'revenue.dateAndTime': 'Fecha & Hora',
    'revenue.doctor': 'Profesional',
    'revenue.completed': 'Completado',
    'revenue.revenueAppointments.empty': 'Aún no hay consultas completadas.',
    'revenue.extraStats.vouchers': 'Tarjeta de Saldo',
    'revenue.extraStats.vouchersSold': 'Tarjeta de Saldo vendida',
    'revenue.extraStats.vouchersRevenue': 'Ingresos por Tarjeta de Saldo',
    'revenue.extraStats.pagopar': 'Pagopar',
    'revenue.extraStats.pagoparTransactions': 'Pagopar Transacciones',
    'revenue.extraStats.pagoparRevenue': 'Pagopar Ingresos',
    'revenue.extraStats.pagoparCcTransactions': 'Pagopar TC Transacciones',
    'revenue.extraStats.pagoparCcRevenue': 'Pagopar TC Ingresos',
    'revenue.extraStats.total': 'Total',
    'revenue.extraStats.totalTransactionsRevenue': 'Ingresos totales de transacciones',
    'revenue.selectDoctor': 'Seleccionar Profesional',

    'revenue.independentDoctor.title': 'Ingresos de profesionales independientes',
    'revenue.virtualClinics.title': 'Ingresos de clínicas virtuales',
    'revenue.virtualClinics.clinic.title': 'Ingresos de clínicas virtuales - {clinicName}',
    'revenue.virtualClinics.clinic.backLink': 'Listado de clínicas',

    'clinicSpecialities.title': 'Especialidades Clínicas',
    'clinicSpecialities.empty': 'Aún no hay especialidades.',
    'clinicSpecialities.table.specialities': 'Especialidades',
    'clinicSpecialities.table.doctor': 'Profesional',
    'clinicSpecialities.table.status': 'Estado',
    'clinicSpecialities.addSpeciality': 'Agregar Especialidad',
    'clinicSpecialities.addSpeciality.modal.body': 'Puede agregar solo dos especialidades clínicas que coincidan con su calificación.',
    'clinicSpecialities.activate.modal.title': 'Activar Especialidad',
    'clinicSpecialities.activate.modal.body': '¿Esta seguro de que quiere activar {name}?',
    'clinicSpecialities.deactivate.modal.title': 'Desactivar Especialidad',
    'clinicSpecialities.deactivate.modal.body': '¿Esta seguro de que quiere desactivar {name}?',
    'clinicSpecialities.deactivate.modal.activeBookings': 'Las citas activas se mantendrán, pero los pacientes ya no podrán reservar consultas para esta especialidad clínica.',
    'clinicSpecialities.remove.modal.body': '¿Estás seguro de que quieres eliminar {name}?',
    'clinicSpecialities.remove.modal.activeBookings': 'Las citas activas permanecerán pero los pacientes no podrán reservar consultas para esta especialidad clínica.',
    'clinicSpecialities.remove.modal.title': 'Remover Especialidad',
    'clinicSpecialities.selectedCount': '{count, number} {count, plural, one {Especialidad seleccionada} other {Especialidades seleccionadas}}',
    'clinicSpecialities.bulkActivate.modal.title': 'Activar Especialidades',
    'clinicSpecialities.bulkActivate.modal.body.start': '¿Esta seguro de que quiere activar ',
    'clinicSpecialities.bulkActivate.modal.body.boldText': '{count, number} {count, plural, one {Especialidad} other {Especialidades}}',
    'clinicSpecialities.bulkActivate.modal.body.final': '?',
    'clinicSpecialities.bulkDeactivate.modal.title': 'Desactivar Especialidad',
    'clinicSpecialities.bulkDeactivate.modal.body.start': '¿Está seguro de que quiere desactivar ',
    'clinicSpecialities.bulkDeactivate.modal.body.boldText': '{count, number} {count, plural, one {Especialidad clínica} other {Especialidades clínicas}}',
    'clinicSpecialities.bulkDeactivate.modal.body.final': '?',
    'clinicSpecialities.bulkDeactivate.modal.activeBookings': 'Las citas activas permanecerán, pero los pacientes no podrán reservar citas para estas especialidades clínicas.',
    'clinicSpecialities.bulkRemove.modal.title': 'Remover Especialidad',
    'clinicSpecialities.bulkRemove.modal.body.start': '¿Estás seguro de que quieres eliminar ',
    'clinicSpecialities.bulkRemove.modal.body.boldText': '{count, number} {count, plural, one {Especialidad clínica} other {Especialidades clínicas}}',
    'clinicSpecialities.bulkRemove.modal.body.final': '?',
    'clinicSpecialities.bulkRemove.modal.allChildSpecialities': 'También se eliminarán todas las especialidades infantiles.',
    'clinicSpecialities.bulkRemove.modal.activeBookings': 'Las citas activas se mantendrán, pero los pacientes no podrán reservar citas para estas especialidades clínicas.',
    'clinicSpecialities.assignDoctors.title': 'Agregar profesionales',
    'clinicSpecialities.assignDoctors.selectDoctorsToAssignTo': 'Seleccionar profesionales para asignar a:',
    'clinicSpecialities.assignDoctors.selectDoctorsToAssignToOptional': 'Seleccionar profesionales para asignar (opcional)',
    'clinicSpecialities.assignDoctors.addDoctorsWithAnotherPrice': 'Agregar profesionales con otro precio',
    'clinicSpecialities.assignDoctors.allDoctorsAdded': 'Todos los profesionales añadidos',
    'clinicSpecialities.tab.details': 'Detalles',
    'clinicSpecialities.tab.doctors': 'Profesionales',

    'clinicSpeciality.listOfSpecialities': 'Lista de Especialidades',
    'clinicSpeciality.backToEditSpeciality': 'Volver a editar especialidad',
    'clinicSpeciality.newClinicSpeciality': 'Nueva Especialidad clínica',
    'clinicSpeciality.editClinicSpeciality': 'Editar Especialidad - {name}',
    'clinicSpeciality.details': 'Detalles',
    'clinicSpeciality.assignDoctors': 'Agregar profesionales',
    'clinicSpeciality.selectSymptoms': 'Seleccione los síntomas en los que le gustaría ser consultado por los pacientes',
    'clinicSpeciality.maximumFutureTime': 'Tiempo futuro máximo',
    'clinicSpeciality.minimumLeadTime': 'Plazo de entrega mínimo',
    'clinicSpeciality.setupMinimumLeadTime': 'Establezca un plazo de entrega mínimo para la hora de la cita',
    'clinicSpeciality.advancedSettings': 'Configuración Avanzada',
    'clinicSpeciality.acceptFreeVisitsToRank': 'Acepte visitas gratuitas para obtener una calificación más alta. Esta función es opcional, por lo que depende de usted administrarla.',
    'clinicSpeciality.consultationDuration': 'Duración de la consulta y búfer despues',
    'clinicSpeciality.theSumOfConsultationDuration': 'La suma de la duración de la consulta y el búfer despues debe ser múltiplo de 20. (Ejemplo: si la consulta dura 15 minutos, el búfer despues debe ser de 5 minutos lo que da un total de 20 minutos)',
    'clinicSpeciality.setTimeForYourself': 'Establezca tiempo para prepararse antes de la consulta o tome las notas necesarias después de que se complete.',
    'clinicSpeciality.setupFutureAppointmentTime': 'Configurar el tiempo futuro máximo para la hora de la cita',
    'clinicSpeciality.setupCancellationRule': 'Configurar regla de cancelación',
    'clinicSpeciality.setupRescheduleRule': 'Configurar regla de reagendamiento',
    'clinicSpeciality.specialityStatus': 'Estado de Especialidad',
    'clinicSpeciality.activeSpeciality': 'La especialidad activa será visible para los pacientes. Puede desactivar la especialidad en cualquier momento para ocultarla a los pacientes.',

    'specialities.title': 'Especialidades',
    'specialities.addSpeciality': 'Crear Especialidades',
    'specialities.table.speciality': 'Especialidad',
    'specialities.table.relateTo': 'Relacionado a:',
    'specialities.table.status': 'Estado',
    'specialities.selectedCount': '{count, number} {count, plural, one {Especialidad seleccionada} other {Especialidades seleccionadas}}',
    'specialities.empty': 'No hay ninguna Especialidad aún.',

    'specialities.removeModal.title': 'Remover Especialidad',
    'specialities.removeModal.body': '¿Está seguro de que quiere remover {name}?',
    'specialities.bulkRemoveModal.title': 'Remover Especialidad',
    'specialities.bulkRemoveModal.body.start': '¿Está seguro de que quiere remover ',
    'specialities.bulkRemoveModal.body.end': '{count, number} {count, plural, one {Especialidad} other {Especialidades}}?',

    'specialities.activateModal.title': 'Activar Especialidad',
    'specialities.activateModal.body': '¿Está seguro de que quiere activar {name}?',
    'specialities.bulkActivateModal.title': 'Activar Especialidad',
    'specialities.bulkActivateModal.body.start': '¿Está seguro de que quiere activar la Especialidad ',
    'specialities.bulkActivateModal.body.end': '{count, number} {count, plural, one {Especialidad} other {Especialidades}}?',

    'specialities.deactivateModal.title': 'Desactivar Especialidad',
    'specialities.deactivateModal.body': '¿Está seguro de que quiere desactivar {name}?',
    'specialities.bulkDeactivateModal.title': 'Desactivar Especialidades',
    'specialities.bulkDeactivateModal.body.start': '¿Esta seguro de que quiere desactivar ',
    'specialities.bulkDeactivateModal.body.end': '{count, number} {count, plural, one {Especialidad} other {Especialidades}}?',
    'specialities.modal.body.activeAppointments': 'Las citas activas permanecerán pero los pacientes ya no podrán reservar nuevas consultas de esta especialidad.',

    'speciality.listOfSpecialities': 'Lista de Especialidades',
    'speciality.editSpeciality': 'Editar Especialidad - {name}',
    'speciality.newSpeciality': 'Nueva Especialidad',
    'speciality.details': 'Detalles',
    'speciality.specialityStatus': 'Estado de las Especialidades',
    'speciality.activeSpeciality': 'La especialidad activa será visible para los pacientes. Puede desactivar la especialidad en cualquier momento para ocultarla a los pacientes.',
    'speciality.symptoms': 'Síntomas',
    'speciality.childrenSpecialty': 'Especialidad infantil',
    'speciality.childrenSpecialtyDescription': 'La especialidad activada será visible primero en la lista para los pacientes que buscan un médico para su hijo. Puede desactivar la especialidad en cualquier momento para ocultarla a los pacientes.',
    'speciality.allSpecialitiesAdded': 'Todas las especialidades agregadas',

    'calendar.sunday': 'Domingo',
    'calendar.monday': 'Lunes',
    'calendar.tuesday': 'Martes',
    'calendar.wednesday': 'Miercoles',
    'calendar.thursday': 'Jueves',
    'calendar.friday': 'Viernes',
    'calendar.saturday': 'Sábado',
    'calendar.selectDateRange': 'Seleccionar rango de fechas',
    'calendar.createdAt': 'Creado en:',
    'calendar.usedAt': 'Usado en:',
    'calendar.shortDayNames.sunday': 'Dom',
    'calendar.shortDayNames.monday': 'Lun',
    'calendar.shortDayNames.tuesday': 'Mar',
    'calendar.shortDayNames.wednesday': 'Mie',
    'calendar.shortDayNames.thursday': 'Jue',
    'calendar.shortDayNames.friday': 'Vie',
    'calendar.shortDayNames.saturday': 'Sab',

    'weekPicker.week': 'Semana',
    'weekPicker.currentWeek': 'Semana actual',

    'recurringAvailability.regularAvailability': 'Disponibilidad Regular',
    'recurringAvailability.info.addAvailability': 'Haga clic y arrastre para agregar o eliminar la disponibilidad regular semanal.',
    'recurringAvailability.info.markAvailability': 'Los bloques azules marcan su disponibilidad permanente para todas las semanas.',
    'recurringAvailability.info.changeAvailability': 'Para cambiar su disponibilidad solo para días específicos, puede usar Disponibilidad específica por fecha.',

    'dateAvailability.dateSpecificAvailability': 'Disponibilidad específica por fecha',
    'dateAvailability.info.addException': 'La disponibilidad de fechas específicas ayuda a agregar excepciones en su horario regular.',
    'dateAvailability.info.addSpecificTime': 'Puede agregar una hora específica o realizar cambios en su disponibilidad habitual.',
    'dateAvailability.info.changeSchedule': 'Para cambiar su horario regular en la pantalla de disponibilidad regular.',

    'appointment.filters.statuses': '{count, plural, one {Estado: {value}} other {Estados}}',
    'appointment.filters.statuses.scheduled': 'Agendado',
    'appointment.filters.statuses.cancelled': 'Cancelado',
    'appointment.filters.statuses.rescheduled': 'Reagendado',
    'appointment.filters.statuses.completed': 'Completado',
    'appointment.filters.statuses.waiting_for_note': 'Agregue una nota',
    'appointment.filters.statuses.in_progress': 'En progreso',
    'appointment.filters.statuses.all': 'Todo',
    'appointment.button.showDetails': 'Ver cuestionario',

    'symptomDetails.title': 'Detalle de los Síntomas',
    'symptomDetails.tab.questionnaire': 'Cuestionario',
    'symptomDetails.tab.files': 'Archivos',
    'symptomDetails.commentForTheDoctor': 'Comentario para el Profesional:',

    'note.addNote': 'Agregar una nota',
    'note.completeNote': 'Completar nota',
    'note.editNote': 'Editar nota',
    'note.editNoteWithTime': 'Editar nota {time}',
    'note.newNote': 'Nueva nota',
    'note.viewNote': 'Ver nota',
    'note.addSecondaryDiagnose': 'Agregar diagnóstico secundario',
    'note.addMedicine': 'Agregar medicamento',
    'note.addAuxiliaryStudy': 'Agregar estudio auxiliares',
    'note.tab.general': 'General',
    'note.tab.plan': 'Plan',
    'note.tab.prescription': 'Receta',
    'note.tab.auxiliaryStudies': 'Estudios auxiliares',
    'note.tab.secretNote': 'Nota secreta',
    'note.tab.sickNote': 'Certificado',
    'note.attention.title': 'Atención!',
    'note.attention.useFollowingCodes': 'Use los siguientes códigos si no pudo brindar una consulta de calidad y no puede agregar una nota completa debido a un problema del lado suyo o de la plataforma:',
    'note.attention.refundCost': 'La aplicación de estos códigos cancelará la consulta y reembolsará el costo al paciente.',
    'note.attention.applyingCodes': 'Si el problema fue del lado del paciente, considere aplicar uno de estos códigos:',
    'note.attention.notApplicable': 'Si aplica uno de estos códigos, todos los campos obligatorios se completarán automáticamente con "No aplicable", pero podrá editar cualquiera de ellos.',
    'note.commonDiagnoses': 'Diagnósticos comunes para:',
    'note.diagnose': 'Diagnósticos',
    'note.topDiagnoses': 'Diagnósticos comunes',
    'note.modal.chiefComplaint': 'Motivo de Consulta:',
    'note.modal.subjective': 'Subjetivo:',
    'note.modal.objective': 'Objetivo:',
    'note.modal.assessment': 'Evaluación:',
    'note.modal.primaryDiagnose': 'Diagnóstico Principal:',
    'note.modal.secondaryDiagnose': 'Diagnóstico Secundario {count}:',
    'note.modal.secretNote': 'Nota secreta',
    'note.modal.plan': 'Plan',
    'note.modal.sickNote': 'Certificado',
    'note.modal.patient': 'Paciente:',
    'note.modal.date': 'Fecha:',
    'note.modal.patientCitizenId': 'ID:',
    'note.modal.doctor': 'Profesional',
    'note.modal.label.doctor': 'Profesional:',
    'note.modal.label.licenseNumber': 'Número de Registro:',
    'note.modal.label.signature': 'Firma:',
    'note.modal.showPrescription': 'Mostrar Recetas',
    'note.modal.hidePrescription': 'Esconder Recetas',
    'note.modal.prescription': 'Recetas',
    'note.modal.auxiliaryStudies': 'Estudios auxiliares',
    'note.modal.idNumber': 'Número de Documetno de Identidad:',
    'note.modal.drug': 'Droga:',
    'note.modal.commercialName': 'Nombre comercial:',
    'note.modal.dose': 'Dosis:',
    'note.modal.frequency': 'Frecuencia:',
    'note.modal.quantity': 'Cantidad:',
    'note.modal.comment': 'Comentario:',
    'note.modal.test': 'Examen:',
    'note.modal.headsUp.body': 'Si aplica este diagnostico primario {code}, el sistema cancelará la cita y reembolsará su costo al paciente. Estás seguro de que quieres continuar?',

    'filters.patients': 'Paciente',
    'filters.specialities': 'Especialidad',
    'filters.symptoms': 'Síntoma',
    'filters.doctors': 'Profesional',

    'appointments.title': 'Consultas',
    'appointments.status.scheduled': 'Agendada',
    'appointments.status.rescheduled': 'Agendada',
    'appointments.status.cancelled': 'Cancelada',
    'appointments.status.completed': 'Completada',
    'appointments.status.all': 'Completo',
    'appointments.status.waiting_for_note': 'Agregar nota',
    'appointments.status.in_progress': 'En progreso',

    'appointments.toolbar.currentDay': 'Día actual',
    'appointments.toolbar.currentMonth': 'Mes actual',
    'appointments.cancellationReason.PatientProfile': 'Motivo de cancelación (paciente)',
    'appointments.cancellationReason.UserProfile': 'Motivo de cancelación (profesional)',
    'appointments.refunded': 'Reembolsado',
    'appointments.doctor': 'Profesional',
    'appointments.patient': 'Paciente',
    'appointments.appointmentForChild': 'Cita para el niño',
    'appointments.viewMeetingPage': 'Ver página de consulta',
    'appointments.cancelAppointment': 'Cancelar consulta',
    'appointments.empty': 'No hay consultas aún.',
    'appointments.amount': 'Cantidad',
    'appointments.viewDetails': 'Ver detalles',

    'cancelAppointmentModal.cancelAppointment': 'Cancelar consulta',
    'cancelAppointmentModal.reason': 'Motivo de cancelación',

    'appointment.consultationStartsIn': 'La consulta empezará en',
    'appointment.startTime': 'Tiempo de inicio',
    'appointment.speciality': 'Especialidad',
    'appointment.timezone': 'Zona horaria',
    'appointment.price': 'Precio',
    'appointment.perSession': '/ sesion',
    'appointment.patient': 'Paciente',
    'appointment.availableInfo': 'Su consulta de video estará disponible 15 minutos antes de la hora reservada',
    'appointment.joinMeeting': 'Unirse a la reunión',
    'appointment.started': 'Empezando',
    'appointment.ended': 'Finalizada',
    'appointment.cancelled': 'Cancelada',
    'appointment.addToGoogleCalendar': 'Agregar al calendario de Google',
    'appointment.addToOutlook': 'Agregar al calendario Outlook / Apple',

    'patients.title': 'Pacientes',
    'patients.exportAsCSV': 'Exportar como CSV',
    'patients.empty': 'Aún no hay pacientes.',
    'patients.empty.newPatients': 'Los nuevos pacientes se agregarán automáticamente cuando reserven una consulta.',
    'patients.tab.patients': 'Pacientes',
    'patients.tab.freeVisits': 'Visitas gratuitas',
    'patients.table.patients': 'Pacientes',
    'patients.table.upcomingAppointments': 'Próximas consultas',
    'patients.table.allAppointments': 'Todas las consultas',
    'patients.table.wellness': 'Wellness',
    'patients.table.createdAt': 'Creado',
    'patients.table.confirmedAt': 'Confirmado',
    'patients.table.status': 'Estado',
    'patients.status.active': 'Activo',
    'patients.status.deactivated': 'Desactivado',
    'patients.activateModal.title': 'Pciente activo',
    'patients.activateModal.body': 'Estás seguro de que quieres activar a {name}?',
    'patients.deactivateModal.title': 'Desactivar paciente',
    'patients.banModal.title': 'Suspender paciente',
    'patients.deactivateModal.body': 'Estás seguro de que quieres desactivar a {name}?',
    'patients.banModal.body': '¿Estás seguro de que quieres suspender a {name}? Se cancelarán todas las próximas consultas que tenga este paciente.',

    'patients.freeVisits.addFreeVisits': 'Añadir visitas gratuitas',
    'patients.freeVisits.editFreeVisits': 'Editar visitas gratuitas',
    'patients.freeVisits.table.patients': 'Pacientes',
    'patients.freeVisits.table.clinic': 'Clínica',
    'patients.freeVisits.table.freeVisits': 'Visitas gratuitas',
    'patients.freeVisits.table.status': 'Estado',
    'patients.freeVisits.empty': 'Aún no hay visitas gratuitas.',
    'patients.freeVisits.allClinicsAdded': 'Todas las clínicas añadidas',
    'patients.freeVisits.allPatientsAdded': 'Todos los pacientes añadidos',

    'patient.genderAndAge': '{gender}, {age} años',
    'patient.profile.tab.patientProfile': 'Perfíl del Paciente',
    'patient.profile.tab.healthProfile': 'Perfíl de Salud',
    'patient.profile.tab.children': 'Hijo',
    'patient.profile.label.idNumber': 'Documento: ',
    'patient.profile.label.dateOfBirth': 'Fecha de nacimiento: ',
    'patient.profile.label.phoneNumber': 'Teléfono: ',
    'patient.profile.label.email': 'Correo: ',
    'patient.profile.label.city': 'Ciudad: ',
    'patient.profile.label.country': 'País: ',
    'patient.profile.label.height': 'Altura: ',
    'patient.profile.label.weight': 'Peso: ',
    'patient.profile.label.chronicIllnesses': 'Enfermedades Crónicas: ',
    'patient.profile.label.allergies': 'Alergais: ',
    'patient.profile.label.nicotineHabits': 'Hábito nicotínico: ',
    'patient.profile.label.gender': 'Género: ',
    'patient.profile.height': '{height} cm',
    'patient.profile.weight': '{weight} kg',
    'patient.profile.smoker': 'Fumador',
    'patient.profile.nonSmoker': 'No fumador',
    'patient.tab.upcomingAppointments': 'Próximas consultas',
    'patient.tab.archivedAppointments': 'Consultas Archivadas',
    'patient.tab.pastAppointments': 'Consultas pasadas',
    'patient.tab.patientChart': 'Historia clínica del paciente',
    'patient.tab.appointments': 'Consultas',
    'patient.tab.freeVisits': 'Visitas gratuitas',
    'patient.freeVisits.addFreeVisits': 'Añadir visitas gratuitas',
    'patient.freeVisits.table.clinic': 'Clínica',
    'patient.freeVisits.table.freeVisits': 'Visitas gratuitas',
    'patient.freeVisits.empty': 'Aún no hay visitas gratuitas.',
    'patient.table.symptom': 'Síntomas',
    'patient.table.speciality': 'Especialidad',
    'patient.table.price': 'Precio',
    'patient.table.dateTime': 'Fecha & Hora',
    'patient.table.date': 'Fecha',
    'patient.table.diagnoses': 'Diagnóstico',
    'patient.table.icd10': 'CIE-10',
    'patient.appointments.empty': 'No hay citas aún.',
    'patient.notes.empty': 'Aún no hay historias clínicas de pacientes.',
    'patient.backToMeetingPage': 'Volver a la página de reunión',
    'patient.balance.tab.balanceTopUp': 'Transacciones de recarga',
    'patient.balance.tab.appointmentPayment': 'Pago de consultas',
    'patient.balance.patientBalance': 'Balance - {name}',
    'patient.balance.table.amount': 'Monto',
    'patient.balance.table.date': 'Fecha',
    'patient.balance.table.source': 'Fuente',
    'patient.balance.table.status': 'Estado',
    'patient.balance.table.id': 'ID',
    'patient.balance.table.appointment': 'Consultas',
    'patient.balance.table.balanceBefore': 'Balance antes',
    'patient.balance.table.balanceAfter': 'Balance después',
    'patient.balance.createTransaction': 'Crear transacción',
    'patient.balance.topupBalance': 'Cargar saldo',
    'patient.balance.subtractBalance': 'Substraer saldo',
    'patient.balance.balanceTopup': 'Carga de saldo',
    'patient.balance.balanceSubtract': 'Substracción de saldo',
    'patient.balance.currentBalance': 'Balance actual',
    'patient.balance.balanceTopUp.empty': 'Aún no hay transacciones de recarga de saldo',
    'patient.balance.appointmentPayment.empty': 'Aún no hay pago por consultas',
    'patient.balance.freeConsultationLeft': 'Consulta gratuita restante',
    'patient.balance.topUpWithVoucher': 'Usar el código de tu voucher',
    'patient.balance.topUpWithPagopar': 'Cargar saldo',
    'patient.balance.tooltip.weAcceptAllMayorPayment': 'Aceptamos todos los principales métodos de pago como tarjetas de crédito, AquíPago, Tigo Money, Billetera Personal y muchos más.',
    'patient.balance.topUpWithPagopar.modal.title': 'Ingrese el monto de recarga',
    'patient.balance.topUpWithPagopar.modal.text': 'Por favor, especifique la cantidad de dinero que desea agregar al saldo de su cuenta.',
    'patient.balance.transactions': 'Transacciones',
    'patient.balance.waitProcessingYourPayment': 'Espere mientras procesamos su pago',
    'patient.balance.backToMyBalance': 'Volver a mi saldo',
    'patient.balance.topupBalanceInfo': 'Si desea obtener una factura, envíe un e-mail a {email} con sus datos de facturación (Razón Social, R.U.C.) y el nombre de la cuenta a la que cargo el saldo.',

    'patientProfile.backLink': 'Volver al Perfil',
    'patientProfile.title': ' - Editar Perfil',
    'patientProfile.generalInformation': 'Información General',
    'patientProfile.firstName': 'Nombre',
    'patientProfile.lastName': 'Apellido',
    'patientProfile.email': 'Correo electrónico',

    'doctorProfile.backLink.listOfIndependentDoctors': 'Lista de Profesionales independientes',
    'doctorProfile.backLink.listOfVirtualClinicDoctors': 'Lista de profesionales de la clínica virtual',
    'doctorProfile.backLink.listOfDoctors': 'Lista de profesionales',
    'doctorProfile.tab.profile': 'Perfil',
    'doctorProfile.tab.account': 'Cuenta',
    'doctorProfile.tab.recurringAvailability': 'Disponibilidad recurrente',
    'doctorProfile.tab.dateSpecificAvailability': 'Disponibilidad específica de la fecha',
    'doctorProfile.tab.clinicSpecialities': 'Especialidades de la Clínica',
    'doctorProfile.generalInformation': 'Información General',
    'doctorProfile.phoneNumberInfo': 'Por favor, proporcione un número de teléfono válido para que podamos comunicarnos con usted. No mostraremos su número de teléfono a los pacientes.',
    'doctorProfile.location': 'Localización',
    'doctorProfile.backToView': 'Volver a la vista',
    'doctorProfile.doctorStatus': 'Estado del Profesional',
    'doctorProfile.activeDoctor': 'Los profesionales activos serán visibles para los pacientes. Puede desactivar al profesional en cualquier momento para ocultarlo de los pacientes. Los profesionales desactivados no tendrán acceso a la especialidad.',
    'doctorProfile.doYouWantToRemove': '¿Quieres eliminar tu imagen de perfil? Puedes agregarla más tarde.',
    'doctorProfile.doYouWantToRemoveSignature': '¿Quieres borrar tu firma? Puedes agregarla más tarde.',

    'independentDoctors.request.headerTitle': 'Solicitud de Profesionales Independientes',
    'independentDoctors.doctors.headerTitle': 'Profesionales Independientes',
    'independentDoctors.doctors.empty': 'No hay profesionales aún.',
    'independentDoctors.doctors.table.nameTitle': 'Nombre',
    'independentDoctors.doctors.table.specialitiesTitle': 'Especialidades',
    'independentDoctors.doctors.table.statusTitle': 'Estado',
    'independentDoctors.doctors.invitations.errors.alreadyMember': 'Oops! Este usuario ya es miembro',
    'independentDoctors.doctors.removeInvitations.title': 'Remover solicitud del profesional independiente',
    'independentDoctors.doctors.removeInvitations.subTitle': '¿Quiere remover la invitación para {email}?',
    'independentDoctors.commissions.headerTitle': 'Comisión de profesionales independientes',
    'independentDoctors.commissions.empty': 'Todavía no hay comisión de profesionales.',

    'doctors.headerTitle': 'Profesionales',
    'doctors.headerTitle.virtualClinicsDoctors': 'Profesionales de la Clínica virtual',
    'doctors.table.title.name': 'Nombre',
    'doctors.table.title.clinic': 'Clínica',
    'doctors.table.title.specialities': 'Especialidades',
    'doctors.table.title.status': 'Estado',
    'doctors.empty': 'No hay profesionales aún.',
    'doctors.activateModal.title': 'Activar cuenta del profesional',
    'doctors.activateModal.body': '¿Estás seguro de que quieres activar a {name}?',
    'doctors.deactivateModal.title': 'Desactivar cuenta del Profesional',
    'doctors.deactivateModal.body': '¿Estás seguro de que quieres desactivar a cuenta de {name}?',
    'doctors.deactivateModal.warning': 'Todas las próximas consultas serán canceladas.',
    'doctors.removeInvitations.title': 'Remover solicitud del profesional',
    'doctors.removeInvitations.subTitle': '¿Quiere remover la invitación para {email}?',
    'doctors.resetAuthSettings.title': 'Restablecer la configuración de autenticación',
    'doctors.resetAuthSettings.subTitle': '¿Esta seguro de que quiere restablecer la configuración de autenticación para {name}?',
    'doctors.assignClinicSpecialities.modal.title': 'Asignar especialidades',
    'doctors.assignClinicSpecialities.modal.selectSpecialityToAssignTo': 'Seleccione la especialidad para asignar a:',
    'doctors.assignClinicSpecialities.modal.addSpecialitiesWithAnotherPrice': 'Añade especialidades con otro precio',
    'doctors.assignClinicSpecialities.modal.allSpecialitiesAdded': 'Todas las especialidades agregadas',
    'doctors.assignClinicSpecialities.modal.maximumAvailableSpecialitiesForDoctor': 'Las especialidad que está intentando asignar a este profesional ya está asignada al máximo de especialidades disponibles del profesional (2 (dos) especialidades)',

    'symptoms.title': 'Síntomas',
    'symptoms.addSymptom': 'Agregar Síntomas',
    'symptoms.selectedCount': '{count, number} {count, plural, one {síntoma seleccionado} other {síntomas seleccionados}} ',
    'symptoms.table.symptom': 'Síntoma',
    'symptoms.table.speciality': 'Especialidad',
    'symptoms.table.status': 'Estado',
    'symptoms.empty': 'No hay Síntomas aún.',

    'symptoms.removeModal.title': 'Remover Síntoma',
    'symptoms.removeModal.body': '¿Estás seguro de que quieres remover {name}?',
    'symptoms.bulkRemoveModal.title': 'Remover Síntomas',
    'symptoms.bulkRemoveModal.body.start': '¿Estás seguo de que quieres remover ',
    'symptoms.bulkRemoveModal.body.end': '{count, number} {count, plural, one {síntoma} other {síntomas}}?',

    'symptoms.activateModal.title': 'Activar Síntoma',
    'symptoms.activateModal.body': '¿Estás seguro de que quieres activar {name}?',
    'symptoms.bulkActivateModal.title': 'Activar síntomas',
    'symptoms.bulkActivateModal.body.start': '¿Estás seguro de que quieres activar ',
    'symptoms.bulkActivateModal.body.end': '{count, number} {count, plural, one {síntoma} other {síntomas}}?',

    'symptoms.deactivateModal.title': 'Desactivar Síntoma',
    'symptoms.deactivateModal.body': '¿Esatás seguro de que quieres desactivar {name}?',
    'symptoms.bulkDeactivateModal.title': 'Desactivar Síntomas',
    'symptoms.bulkDeactivateModal.body.start': '¿Estás seguro de que quieres desactivar ',
    'symptoms.bulkDeactivateModal.body.end': '{count, number} {count, plural, one {síntoma} other {síntomas}}?',

    'symptom.create.newSymptom': 'Nuevo Síntoma',
    'symptom.create.details': 'Detalles',
    'symptom.create.upload': 'Subir imagen',
    'symptom.create.doYouWantToRemove': '¿Quiere eliminar la imagen del síntoma? Puedes agregarla más tarde.',
    'symptom.create.back': 'Lista de síntomas',
    'symptom.create.backToSymptomDetails': 'Volver a detalles de los síntomas',
    'symptom.create.questionnaire': 'Cuestionario',
    'symptom.create.questionnaire.addQuestion': 'Agregar Pregunta',
    'symptom.create.questionnaire.required': 'Requerido',
    'symptom.create.questionnaire.optionIndex': 'Opción {index}',
    'symptom.create.questionnaire.mustContainQuestions': 'El cuestionario debe contener al menos 3 preguntas.',
    'symptom.create.addOption': 'Agregar opción',
    'symptom.create.pleaseAddQuestion': 'Porfavor agregue una pregunta',
    'symptom.create.label.radio': 'Botones de radio',
    'symptom.create.label.checkbox': 'Casillas de verificación',
    'symptom.create.label.yesNo': 'Opcion "Sí" / "No" ',
    'symptom.create.questionnaire.removeModal.title': 'Remover pregunta',
    'symptom.create.questionnaire.removeModal.body': '¿Esás seguro de que quieres remover esta pregunta?',
    'symptom.create.questionnaire.errorCountOfQuestions': 'Agregue al menos 3 preguntas al cuestionario',
    'symptom.create.cancelModal.title': 'Cancelar nuevo síntoma',
    'symptom.create.cancelModal.body': '¿Está seguro de que desea cancelar la creación del nuevo síntoma? Se perderán todos los datos.',
    'symptom.create.assignSpecialities': 'Asignar especialidades',
    'symptom.create.backToQuestionnaire': 'Volver al cuestionario de síntomas',
    'symptom.create.assignSpeciality.errorAssociatedWithSpeciality': 'Debe estar asociado al menos con 1 especialidad',
    'symptom.create.assignSpeciality.selectSpecialitiesAssign': 'Seleccionar especialidades para asignar',
    'symptom.tab.details': 'Detalles',
    'symptom.tab.questionnaire': 'Cuestionario',
    'symptom.tab.specialities': 'Especialidades',
    'symptom.editSymptom': 'Editar Síntoma - {name}',
    'symptom.symptomStatus': 'Estado del Síntoma',
    'symptom.activeSymptom': 'El síntoma activo será visible para los pacientes. Puede desactivar el síntoma en cualquier momento para ocultarlo de los pacientes.',
    'symptom.allSymptomsAdded': 'Todos los síntomas añadidos',
    'symptom.optionGroup': 'Síntoma',

    'tutorial.skip': 'Saltar',
    'tutorial.finish': 'Finalizar tutorial',
    'tutorial.helpCenter': 'Centro de ayuda',
    'tutorial.availability.title': 'Completa tu horario laboral',
    'tutorial.availability.description': 'Haga clic y arrastre el puntero para resaltar un rango de intervalos de tiempo en le que va a trabajar. Esta vez estará disponible para los pacientes para nuevas reservas.{breakingLine}También puede agregar o eliminar algunos epacios específicos aquí: {link}.',
    'tutorial.availability.this': 'Disponibilidad específica por fecha',
    'tutorial.clinicSpecialities.title': 'Agregue nueva Especialidad',
    'tutorial.clinicSpecialities.description': 'Agrega nuevas especialidades y gestionalas {link}.',
    'tutorial.clinicSpecialities.this': 'en esta página',
    'tutorial.inviteVirtualClinicDoctor.title': 'Invitar a médicos de la clínica virtual',
    'tutorial.inviteVirtualClinicDoctor.description': 'Invita y asigna médicos que brindarán consultas en tu clínica {link}.',
    'tutorial.inviteVirtualClinicDoctor.this': 'en esta página',
    'tutorial.congratulations.title': 'Felicitaciones!',
    'tutorial.congratulations.description': 'Ahora estás listo para ganarte nuevos pacientes',

    'chat.title': 'Chat',
    'chat.today': 'Hoy',
    'chat.yesterday': 'Ayer',
    'chat.backToAppointments': 'Volver a las consultas',
    'chat.inputPlaceholder': 'Escriba su mensaje aquí',
    'chat.editingMessage': 'Editar mensaje',
    'chat.deleteMessage.modal.title': 'Elimianr mensaje',
    'chat.removeMessage.modal.body': '¿Está seguro de que desea eliminar este mensaje para usted y para {name}?',
    'chat.tag.edited': 'Editado',
    'chat.notImplemented': 'No implementado',
    'chat.attachments.tab.files': 'Archivos',
    'chat.attachments.tab.recordings': 'Filmaciones',
    'chat.attachments.noFilesYet': 'Aún no hay archivos.',
    'chat.attachments.noRecordingsYet': 'Aún no hay filmaciones.',
    'chat.attachments.allFiles': 'Todos los archivos ({count})',
    'chat.attachments.remove.modal.title': 'Eliminar archivo',
    'chat.attachments.remove.modal.body': '¿Estás seguro de quieres remover este archivo?',
    'chat.attachments.remove.modal.text': 'Esta archivo ya no estará disponible',
    'chat.fileUploadError': 'Error de carga de archivo',
    'chat.fileSizeCantBeBigger': 'El tamaño del archivo no puede ser mayor que {size}',
    'chat.selectOtherFile': 'Seleccione otro archivo',
    'chat.rescheduleRequest.selectNewTime': 'Seleccione un nuevo horario',
    'chat.rescheduleRequest.declineRequest': 'Rechazar solicitud',
    'chat.declineRescheduleRequest.modal.title': 'Rechazar solicitud de reagendamiento',
    'chat.declineRescheduleRequest.modal.body': '¿Estás seguro de que deseas rechazar la solicitud de reagendamiento?',
    'chat.declineRescheduleRequest.modal.submit': 'Rechazar solicitud',
    'chat.rescheduleRequest.status.declined': 'Rechazada',
    'chat.rescheduleRequest.status.accepted': 'Aceptada',
    'chat.enable.modal.title': 'Activar chat',
    'chat.enable.modal.body': '¿Está seguro de que desea activar el chat? El paciente podrá enviar mensajes y archivos.',
    'chat.enable.modal.button': 'Activar chat',
    'chat.disable.modal.title': 'Desactivar chat',
    'chat.disable.modal.body': '¿Estás seguro de que deseas desactivar este chat? Puede habilitarlo más tarde.',
    'chat.disable.modal.label': 'Mensaje para el paciente (opcional)',
    'chat.disable.modal.button': 'Desactivar chat',
    'chat.patient.disable.modal.title': 'El chat del paciente está desactivado',
    'chat.patient.disable.modal.body.p1': 'La capacidad de chatear se ha desactivado para el paciente. El paciente no podrá responderle',
    'chat.patient.disable.modal.body.p2': '¿Quieres seguir enviando mensajes?',
    'chat.patient.disable.modal.button.justSendMessage': 'Solo envía un mensaje',
    'chat.patient.disable.modal.button.sendMessageAndEnableChat': 'Enviar mensaje y habilitar el chat',
    'chat.newMessages': '{count} {count, plural, one {mensaje nuevo} other {mesajes nuevos}}',
    'chat.since': 'desde las {time} el {date}',
    'chat.markAsRead': 'Marcar como leído',
    'chat.recordIsReady.button': 'Ver filmación',
    'chat.shareWithExpert.button': 'Compartir con el Profesional',
    'chat.shareWithPatient.button': 'Compartir con el Paciente',

    'chat.message.appointment.note.title': 'Completar Consulta',
    'chat.message.appointment.note.text.p1': 'Agrega una nota para completar la consulta.',
    'chat.message.appointment.note.text.p2': 'Por favor, use los siguientes códigos si no pudo brindar una consulta de calidad y no puede agregar una nota debido a un problema de su lado o de la plataforma:',
    'chat.message.appointment.note.list1.item1': 'Z53.9 Procedimiento y tratamiento no realizados por motivo no especificado',
    'chat.message.appointment.note.list1.item2': 'Z53.09 Procedimiento y tratamiento no realizados por otras contraindicaciones',
    'chat.message.appointment.note.list1.item3': 'Z53.2 El procedimiento y el tratamiento no se llevaron a cabo debido a la decisión del paciente por otras razones no especificadas.',
    'chat.message.appointment.note.text.p3': 'La aplicación de estos códigos cancelará la consulta y reembolsará el costo al paciente.',
    'chat.message.appointment.note.text.p4': 'Si el problema fue del lado del paciente, considere aplicar uno de estos códigos:',
    'chat.message.appointment.note.list2.item1': 'Z53.8 Procedimiento y tratamiento no realizado por otros motivos',
    'chat.message.appointment.note.list2.item2': 'Z53.21 Procedimiento y tratamiento no realizado porque el paciente se marcha antes de ser atendido por el profesional sanitario',
    'chat.message.appointment.note.text.p5': 'Por favor, asegúrese de agregar la nota con un código adecuado dentro de las 24 horas, de lo contrario la consulta se cancelará automáticamente.',

    'uploadImage.changeImage': 'Cambiar Imagen',
    'uploadImage.editImage': 'Editar Imagen',
    'uploadImage.selectImage': 'Seleccionar otra imagen',
    'uploadImage.uploadError': 'Error de carga de imagen',
    'uploadImage.formatSizeError': 'Utilice una imagen JPG o PNG de menos de 10 MB',
    'uploadImage.notice': 'JPG o PNG. Máximo tamaño 10MB',
    'uploadImage.deleteImage': 'Borrar Imagen',
    'uploadImage.uploadLogo': 'Subir logo',
    'uploadImage.doYouWantToRemove': '¿Quieres eliminar el logotipo de la empresa? Puedes agregarlo más tarde.',
    'uploadImage.doYouWantToRemoveClinicLogo': '¿Quiere eliminar el logo de la clínica virtual {clinicName}?',
    'uploadImage.uploadPhoto': 'Subir Foto',
    'uploadImage.doYouWantToRemoveProfileImage': '¿Quieres eliminar la imagen de perfil de {userName}? Puedes agregarlo más tarde.',
    'uploadImage.addSignature': 'Agregar firma',
    'uploadImage.uploadSignature': 'Subir firma',
    'uploadImage.createSignature': 'Crear firma',
    'uploadImage.doYouWantToRemoveProfileSignature': '¿Quieres eliminar la firma del perfil de {userName}? Puedes agregarlo más tarde.',

    'vouchers.title': 'Tarjetas de Saldo',
    'vouchers.tab.inactive': 'Inactivas',
    'vouchers.tab.used': 'Usadas',
    'vouchers.tab.batches': 'Lotes',
    'vouchers.generate': 'Generar',
    'vouchers.generateVouchers': 'Generar Tarjetas de Saldo',
    'vouchers.editBatch': 'Editar Lote',
    'vouchers.selectedCount': '{count, number} {count, plural, one {tarjeta de saldo seleccionada} other {tarjetas de saldo seleccionadas}}',
    'vouchers.status.success': 'Éxito',
    'vouchers.status.failure': 'Fallido',
    'vouchers.status.pending': 'Pendiente',
    'vouchers.table.voucherId': 'ID de Tarjeta de Saldo',
    'vouchers.table.createdAt': 'Creado en',
    'vouchers.table.usedAt': 'Usada en',
    'vouchers.table.batch': 'Lote',
    'vouchers.table.status': 'Estado',
    'vouchers.table.usedBy': 'Usad por',
    'vouchers.table.value': 'Valor',
    'vouchers.table.usedByPatient': 'Usada por el paciente',
    'vouchers.table.voucherCode': 'Código de Tarjeta de Saldo',
    'vouchers.table.batchName': 'Nombre del Lote',
    'vouchers.table.inactive': 'Inactivo',
    'vouchers.table.used': 'Usada',
    'vouchers.table.total': 'Total',
    'vouchers.empty': 'Aún no hay Tarjetas de Saldo',
    'vouchers.batchesEmpty': 'Aún no hay Lotes.',
    'vouchers.typeVoucherId': 'Escriba el ID de la Tarjeta de Saldo',
    'vouchers.typeBatchName': 'Escriba el nombre del Lote',
    'vouchers.listOfVouchers': 'Lista de Tarjetas de Saldo',
    'vouchers.listOfBatches': 'Lista de Lotes',
    'vouchers.comment': 'Comentario: ',

    'vouchers.rowsCount.title': '{count, number} {count, plural, one {terjeta de saldo} other {tarjetas de saldo}}',
    'vouchers.rowsCount.batchesTitle': '{count, number} {count, plural, one {lote} other {lotes}}',

    'vouchers.bulkRemoveModal.title': 'Remover Tarjeta de Saldo',
    'vouchers.bulkRemoveModal.body.start': '¿Estás seguro de que quieres remover la ',
    'vouchers.bulkRemoveModal.body.end': '{count, number} {count, plural, one {tarjeta de saldo} other {tarjetas de saldo}}?',

    'vouchers.enterCodeFromYourVoucher': 'Ingresa el código de tu Tarjeta de Saldo',
    'vouchers.button.topUp': 'Cargar Saldo',
    'voucher.congrats': 'Felicitaciones!',
    'voucher.congrats.description': 'Has activado una  tarjeta de saldo de {amount}, este monto será acreditado a tu cuenta.',
    'voucher.failure': 'Oops!',
    'voucher.failure.description': 'La Tarjeta de Saldo ya sido utilizada.',

    'notificationPanel.title': 'Notificaciones',
    'notificationPanel.markAsRead': 'Marcar como leído',
    'notificationPanel.markAsUnread': 'Marcar como no leído',
    'notificationPanel.empty': 'Aún no hay notificaciones.',
    'notificationPanel.empty.alt': 'No hay notificaciones',
    'notificationPanel.notificationRemoved': 'Notificación eliminada',
    'notificationPanel.undo': 'Deshacer',
    'notificationPanel.dateTime': '{date} a las {time}',
    'notificationPanel.hours': '{count, number} {count, plural, one {hora} other {horas}}',
    'notificationPanel.minutes': '{count, number} {count, plural, one {minuto} other {minutos}}',
    'notificationPanel.appointment.newAppointmentForDoctor': 'Tiene una nueva cita para {symptomName} {specialityName} a las {startTime}.',
    'notificationPanel.appointment.patientCancelAppointmentForDoctor': '{patientFullName} ha cancelado la cita para {symptomName} {specialityName} a las {startTime}.',
    'notificationPanel.appointment.patientCancelAppointmentWithoutRefund': '{patientFullName} ha cancelado la cita para {symptomName} {specialityName} a las {startTime}. Se le pagará por esta consulta ya que el tiempo de cancelación ha pasado',
    'notificationPanel.appointment.doctorMissedAppointment': 'Perdiste la cita para {symptomName} {specialityName} con {patientFullName} a las {startTime}. El costo ha sido reembolsado al paciente.',
    'notificationPanel.appointment.doctorCancelledAppointment': 'Cancelaste la cita para {symptomName} {specialityName} con {patientFullName} a las {startTime}. El costo ha sido reembolsado al paciente.',
    'notificationPanel.appointment.cancelledAppointmentDueToPatientBan': 'Tu cita para {symptomName} {specialityName} ha sido cancelado porque el paciente {patientFullName} fue suspendido.',
    'notificationPanel.appointment.completedAppointmentDueToPatientBan': 'Tu cita para {symptomName} {specialityName} se ha completado ya que el paciente {patientFullName} fue suspendido. Se le pagará en su totalidad ya que la cita no coincidió con su regla de cancelación.',
    'notificationPanel.appointment.patientRescheduledAppointment': '{patientFullName} ha hecho un reagendamiento para la cita {symptomName} {specialityName} a las {startTime}.',
    'notificationPanel.appointment.createNoteAfterAppointment': 'Complete una nota después de la consulta para {symptomName} {specialityName} con {patientFullName}.',
    'notificationPanel.appointment.doctorFailedToAddNote': 'No agregaste una nota a la consulta {symptomName} {specialityName} con {patientFullName} a las {startTime} - {endTime}. La cita ha sido cancelada y su coste ha sido reembolsado al paciente.',
    'notificationPanel.reminder.reminderForDoctor': 'Tu consulta para {symptomName} {specialityName} con {patientFullName} empezará en {time}.',
    'notificationPanel.communication.patientCreateMessage': 'Tiene un nuevo mensaje del paciente en la consulta {symptomName} {specialityName} a las {startTime}.',
    'notificationPanel.communication.patientAssignAttachment': 'Tiene un nuevo archivo subido por el paciente en la consulta {symptomName} {specialityName} a las {startTime}.',
    'notificationPanel.system.specialityAssigned': '{specialityName} se le ha asignado.',
    'notificationPanel.system.specialityUnassigned': '{specialityName} no se le ha asignado.',
    'notificationPanel.system.specialityRemoved': '{specialityName} no se le ha asignado.',
    'notificationPanel.system.clinicSpecialityRemoved': '{specialityName} ha sido eliminada de su clínica virtual.',
    'notificationPanel.system.clinicSpecialityDeactivated': '{specialityName} ha sido desactivada en su clínica virtual.',
    'notificationPanel.system.clinicSpecialityActivated': '{specialityName} se ha activado en su clínica virtual.',
    'notificationPanel.system.specialityDeactivatedPlatform': '{specialityName} se ha desactivado. La especialidad de su clínica {specialityName} ya no está disponible para futuras consultas.',
    'notificationPanel.system.specialityActivatedPlatform': '{specialityName} ha sido activada. Puede agregarla a su especialidad clínica si coincide con su calificación.',
    'notificationPanel.system.specialityAddedPlatform': '{specialityName} ha sido activada. Puede agregarla a su especialidad clínica si coincide con su calificación.',
    'notificationPanel.system.specialityDeletedPlatform': '{specialityName} ha sido eliminada de la plataforma. Ya no se puede utilizar para las especialidades de la clínica.',
    'notificationPanel.notificationMessage.notImplemented': 'No se ha implementado',

    'videoConference.joinConsultation': 'Unirse a la Consulta',
    'videoConference.joinError': 'El reloj de tu computadora está mal configurado',
    'videoConference.settings': 'Ajustes',
    'videoConference.waitingParticipant': 'Esperando a que el participante se una',
    'videoConference.settingsModal.camera': 'Cámara',
    'videoConference.settingsModal.microphone': 'Micrófono',
    'videoConference.settingsModal.speakers': 'Parlantes',
    'videoConference.settingsModal.defaultSpeaker': 'Parlantes predeterminados',
    'videoConference.leaveConsultation.title': 'Salir de la consulta',
    'videoConference.leaveConsultation.body': '¿Estás seguro de que quieres dejar esta consulta?',
    'videoConference.lostConnection': 'Se perdió la conexión a Internet. Intentando conectar…',
    'videoConference.turnOnMicrophoneToSpeak': 'Enciende el micrófono para hablar',
    'videoConference.modal.exit.title': 'La videollamada ha finalizado',
    'videoConference.modal.exit.body': 'La videollamada finalizó automáticamente cuando el paciente abandonó la reunión.',

    'download.pleaseOpenLinkOnPhoneTabletContinue': 'Por favor, abra este enlace en su teléfono o tableta para continuar.',
    'download.fileName.revenueVirtualClinicAppointments': 'Citas de {userProfileId, select, 0 {la clínica virtual {clinicName}} other {{userName} {licenseNumber, select, 0 {} other {CI {licenseNumber}}} en la clínica virtual {clinicName}}} de {startDate} - {endDate}.csv',
    'download.fileName.revenuesForVirtualClinics': 'Ingresos de las clínica virtuales de {startDate} - {endDate}.csv',
    'download.fileName.revenuesForVirtualClinic': 'Ingresos de la clínica virtual {clinicName} de {startDate} - {endDate}.csv',
    'download.fileName.revenueForIndependentDoctors': 'Ingresos para médicos independientes de {startDate} - {endDate}.csv',
    'download.fileName.revenueAppointmentsForIndividualDoctors': 'Citas para doctores independientes de {startDate} - {endDate}.csv',
    'download.fileName.revenueAppointmentsForIndividualDoctor': 'Citas para el doctor independiente {userName} {licenseNumber, select, 0 {} other {CI {licenseNumber}}} de {startDate} - {endDate}.csv',
    'download.fileName.appointmentsForPeriod': 'Citas de {startDate} - {endDate}.csv',

    'virtualClinics.clinics.title': 'Clínicas Virtuales',
    'virtualClinics.clinics.empty': 'Todavía no hay clínicas virtuales.',
    'virtualClinics.clinics.table.nameTitle': 'Nombre',
    'virtualClinics.clinics.table.freeVisits': 'Visitas gratuitas',
    'virtualClinics.clinics.table.doctorsTitle': 'Profesionales',
    'virtualClinics.clinics.table.adminTitle': 'Administrador',
    'virtualClinics.clinics.table.statusTitle': 'Estado',
    'virtualClinics.clinics.removeInvitations.modal.title': '¿Cancelar invitación?',
    'virtualClinics.clinics.removeInvitations.modal.body': '¿Estás seguro de que quieres cancelar invitación?',
    'virtualClinics.clinics.deactivateModal.title': 'Desactivar clínica virtual',
    'virtualClinics.clinics.deactivateModal.body': '¿Estás seguro de que quieres desactivar {clinicName}? Todos los médicos activos de esta clínica serán desactivados y sus citas serán canceladas.',
    'virtualClinics.clinics.deactivateModal.warning': 'Sucederán cosas inesperadas si no lee esto!',
    'virtualClinics.clinics.deactivateModal.label': 'Escriba el nombre de la clínica para confirmar la desactivación',
    'virtualClinics.clinics.activateModal.title': 'Activar clínica virtual',
    'virtualClinics.clinics.activateModal.body': '¿Estás seguro de que quieres activar {clinicName}?',
    'virtualClinics.clinics.resetAuthSettings.title': 'Restablecer la configuración de autenticación',
    'virtualClinics.clinics.resetAuthSettings.body': '¿Está seguro de que desea restablecer la configuración de autenticación para {clinicName}?',
    'virtualClinics.clinics.profile.backLink': 'Listado de Clínicas',
    'virtualClinics.clinics.profile.backToView': 'Volver a la vista',
    'virtualClinics.clinics.tab.profile': 'Perfil',
    'virtualClinics.clinics.tab.account': 'Cuenta',
    'virtualClinics.clinics.tab.freeVisits': 'Visitas gratuitas',
    'virtualClinics.clinics.profile.virtualClinicStatus': 'Estado de Clínicas Virtuales',
    'virtualClinics.clinics.profile.virtualClinicActive': 'Las clínicas virtuales activas serán visibles para los pacientes. Puede desactivar la clínica virtual en cualquier momento para ocultarla a los pacientes.',
    'virtualClinics.clinics.profile.freeVisits': 'Visitas gratuitas',
    'virtualClinics.clinics.profile.freeVisitsActive': 'Las visitas gratuitas activas serán visibles para los administradores de la clínica.',

    'virtualClinics.requests.headerTitle': 'Solicitudes de profesionales de la clínica virtual',

    'virtualClinics.commissions.headerTitle': 'Comisión de clínicas virtuales',
    'virtualClinics.commissions.empty': 'Todavía no hay comisión de clínicas virtuales.',

    'virtualClinicDoctors.commissions.headerTitle': 'Comisión de profesionaels de la clínica virtual',
    'virtualClinicDoctors.commissions.empty': 'Todavía no hay comisión de profesionales de la clínica virtual.',

    'joinRequests.table.nameTitle': 'Nombre',
    'joinRequests.table.dateTitle': 'Fecha',
    'joinRequests.table.statusTitle': 'Estado',
    'joinRequests.table.clinicTitle': 'Clínica',
    'joinRequests.empty': 'No hay solicitudes aún',
    'joinRequests.acceptModal.title': 'Aceptar solicitud del profesional',
    'joinRequests.acceptModal.body': '¿Estás seguro de que quieres aceptar la solicitud de {name}?',
    'joinRequests.rejectModal.title': 'Rechazar la solicitud',
    'joinRequests.rejectModal.body': '¿Quieres rechazar la solicitud de {name}?',
    'joinRequests.removeModal.title': 'Remover solicitud del profesional',
    'joinRequests.removeModal.body': '¿Quieres remover la solicitud de {name}?',
    'joinRequests.previewModal.title': 'Detalle del Profesional',

    'commissions.table.name': 'Nombre',
    'commissions.table.commission': 'Commisión',
    'commissions.table.minimum': 'Mínimo',
    'commissions.table.maximum': 'Máximo',
    'commissions.table.status': 'Estado',
    'commissions.title.setCommission': 'Establecer comisión',

    'invitations.modal.title': 'Invitar {roleName}',
    'invitations.modal.subTitle': 'Ingrese sus direcciones de correo electrónico, establezca roles y les enviaremos invitaciones',
    'invitations.modal.duplicateEmails': 'Has introducido un mismo correo electrónico varias veces',
    'invitations.modal.inviteOneMoreUsers': 'Invitar a uno o más {roleName}',

    'vcd.joinRequest.inviteDoctor': 'Invitar profesional',
    'vcd.joinRequest.invitationHasBeenSent': 'La invitación ha sido enviada',

    'userEmailConfirm.expiredLink': 'Enlace expirado.',
    'userEmailConfirm.pageTitle': 'Clinix | Profesionales médicos de confianza en tu celular - confirmar e-mail',
    'userEmailConfirm.emailSuccessfullyConfirmed.title': 'E-mail confirmado con éxito',
    'userEmailConfirm.youHaveSuccessfullyConfirmEmail': 'Has confirmado tu dirección de e-mail con éxito.',
    'userEmailConfirm.linkHasBeenExpired.title': 'El enlace ha expirado.',
    'userEmailConfirm.youHaveErrorConfirmEmail': 'Para continuar con el registro, solicite un nuevo e-mail de confirmación.',
    'userEmailConfirm.alt.confirmEmail': 'Confirmar e-mail',

    'userUnsubscribeEmail.preferencesHaveBeenSaved': 'Tus preferencias han sido guardadas',
    'userUnsubscribeEmail.preferencesHaveNotBeenSaved': '¡UPS! Algo salió mal. Inténtalo de nuevo',

    'userUnsubscribeEmail.pageTitle': 'Clinix | Médicos al alcance de la palma de tus manos - Cancelar suscripción',
    'userUnsubscribeEmail.selectWhichEmailReceive': 'Seleccione los correos electrónicos que le gustaría recibir:',
    'userUnsubscribeEmail.emailCommunication': 'Comunicación por correo electrónico ',
    'userUnsubscribeEmail.receiveEmailNotificationAboutNewChatFiles': 'Reciba notificaciones por correo electrónico sobre nuevos mensajes o archivos en el chat.',
    'userUnsubscribeEmail.emailAppointment': 'Cita por correo electrónico',
    'userUnsubscribeEmail.receiveEmailNotificationAboutNewAppointments': 'Reciba notificaciones por correo electrónico sobre sus citas.',
    'userUnsubscribeEmail.emailRemainders': 'Recordatorios por correo electrónico',
    'userUnsubscribeEmail.receiveEmailRemainders': 'Reciba recordatorios de citas por correo electrónico.',
    'userUnsubscribeEmail.emailNotifications': 'Notificaciones por e-mail.',
    'userUnsubscribeEmail.receiveEmailNotificationsAndRemainders': 'Recibe notificaciones por e-mail y recordatorios sobre tus consultas.',

    'bookWithDoctorLink.title': 'Agenda una consulta con este doctor',
    'bookWithDoctorLink.youCanBook': 'Puede agendar la consulta con {fullName} desde la app de Clinix',
    'bookWithDoctorLink.error.title': 'Profesionales médicos {breakingLine} de confianza en su celular',
    'bookWithDoctorLink.error.chooseTime': 'Elija un médico y el horario adecuados para usted. Obtenga un plan de tratamiento completo con recetas de medicamentos sin tener que hacer cola.',
    'bookWithDoctorLink.error.promo': 'Descargue la aplicación y obtenga una consulta médica {free}.',
    'bookWithDoctorLink.error.free': 'GRATUITA',

    'pushNotifications.title': 'Notificaciones',
    'pushNotifications.empty': 'Todavía no hay notificaciones',
    'pushNotifications.table.id': 'ID',
    'pushNotifications.table.createdAt': 'Creado en',
    'pushNotifications.table.patients': 'Pacientes',
    'pushNotifications.table.message': 'Mensaje',
    'pushNotifications.modal.title': 'Crear notificación Push',
    'pushNotifications.modal.addSpecialities': 'Agregar especialidades',
    'pushNotifications.modal.addClinic': 'Agregar clínica',
    'pushNotifications.modal.addPatients': 'Agregar pacientes',
    'pushNotifications.allPatientsAdded': 'Todos los pacientes agregados',
    'pushNotifications.allClinicsAdded': 'Todas las clínicas agregadas',
    'pushNotifications.confirmation.text': 'Esta seguro de que quiere enviar notificación push a {count} usuarios?',

    'dashboards.title': 'Dashboard',
    'dashboards.table.id': 'ID',
    'dashboards.table.email': 'Email',
    'dashboards.table.date': 'Fecha',
    'dashboards.table.appointmentsAv': 'Consultas Disponibles',
    'dashboards.table.appointmentsUsed': 'Consultas Usadas',
  },
};
