import {
  curry,
  defaultTo,
  filter,
  flatten,
  ifElse,
  is,
  isNil,
  pipe,
  toPairs,
  whereEq,
} from 'ramda';
import { DateTime, Duration } from 'luxon';

import { DURATION_REGEX } from 'constants';
import {
  MINUTES_PER_HOUR,
  TIME_UNITS_DAY,
  TIME_UNITS_HOUR,
  TIME_UNITS_MINUTE,
  TIME_UNITS_MONTH,
} from 'constants/timeUnits';

const SECONDS_PER_DAY = 86400;
const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_MONTH = 2592000;

const SECONDS_PER_TIME_UNIT = {
  [TIME_UNITS_MINUTE]: SECONDS_PER_MINUTE,
  [TIME_UNITS_HOUR]: SECONDS_PER_HOUR,
  [TIME_UNITS_DAY]: SECONDS_PER_DAY,
  [TIME_UNITS_MONTH]: SECONDS_PER_MONTH,
};

const OVERLAP_HOUR = 1;

export const createZeroDuration = () => ({ hours: 0, minutes: 0 });

export const parseDuration = (durationString) => {
  const match = DURATION_REGEX.exec(durationString.trim());
  if (!match) { return null; }

  const [hours, minutes, hoursOnly, minutesOnly] = match.slice(1, 5);

  return {
    hours: parseInt(hoursOnly || hours || 0, 10),
    minutes: parseInt(minutesOnly || minutes || 0, 10),
  };
};

export const formatDuration = (
  durationObject,
  { omitZeroes } = { omitZeroes: true },
) => {
  const hideHours = omitZeroes && durationObject.hours === 0;
  const hideMinutes = omitZeroes && durationObject.minutes === 0;

  const hours = hideHours ? '' : `${durationObject.hours}h`;
  const minutes = hideMinutes ? '' : `${durationObject.minutes}m`;
  const separator = hideHours || hideMinutes ? '' : ' ';

  return `${hours}${separator}${minutes}`;
};

export const durationToFormatString = (duration) => {
  const hours = Math.floor(duration / SECONDS_PER_HOUR);
  const minutes = Math.floor((duration % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);

  return formatDuration({ hours, minutes });
};

export const addMinutes = curry((amount, duration) => {
  const result = { ...duration };
  result.minutes += amount;
  if (result.minutes >= MINUTES_PER_HOUR) {
    result.hours += Math.trunc(result.minutes / MINUTES_PER_HOUR);
    result.minutes %= MINUTES_PER_HOUR;
  }
  return result;
});

export const subtractMinutes = curry((amount, duration) => {
  const result = { ...duration };

  result.minutes -= amount;
  if (result.minutes < 0) {
    const hoursDelta = -Math.trunc(result.minutes / MINUTES_PER_HOUR) + OVERLAP_HOUR;
    result.hours -= hoursDelta;
    result.minutes += MINUTES_PER_HOUR * hoursDelta;
  }

  if ((result.minutes < 0 || result.hours < 0) || whereEq(createZeroDuration(), result)) {
    return { ...duration };
  }

  return result;
});

export const durationToSeconds = (durationOrString) => {
  const duration = is(String, durationOrString)
    ? parseDuration(durationOrString)
    : durationOrString;

  const minutes = duration.hours * MINUTES_PER_HOUR + duration.minutes;
  return minutes * SECONDS_PER_MINUTE;
};

export const makeDurationChangeHandler = curry((action, params, duration) => pipe(
  parseDuration,
  ifElse(
    isNil,
    pipe(defaultTo(createZeroDuration()), addMinutes(params)),
    action(params),
  ),
  formatDuration,
)(duration));

export const incrementDurationHandler = makeDurationChangeHandler(addMinutes);
export const decrementDurationHandler = makeDurationChangeHandler(subtractMinutes);

export const valueWithUnitToSeconds = (unit, value) => value * SECONDS_PER_TIME_UNIT[unit];

export const secondsToValueWithUnits = (seconds) => {
  const units = Duration.fromObject({ seconds }).shiftTo('minutes', 'hours', 'days', 'months').toObject();
  const [unit = 'minutes', value = 0] = pipe(
    filter(item => item !== 0),
    toPairs,
    flatten,
  )(units);

  return { value, unit };
};

export const formatSlotDuration = seconds => `${Duration.fromObject({ seconds }).toFormat('h\'h\' m\'m\'')}/w`;

export const formatDurationDifference = (seconds) => {
  const duration = Duration.fromObject({ seconds: Math.abs(seconds) }).toFormat('h\'h\' m\'m\'');
  const sign = seconds >= 0 ? '+' : '-';

  return `${sign}${duration}`;
};

export const formatDurationDistance = seconds => (
  Duration.fromObject({ seconds }).toFormat('hh:mm:ss')
);

export const formatDistance = (time, otherTime) => (
  time.diff(otherTime).toFormat("d'd' h'h' m'm' s's'").replace('0d ', '')
);

export const daysFromNowToDate = (date) => DateTime.fromISO(date).diff(DateTime.local()).toFormat('d');

export const yearsFromNowToDate = date => DateTime.local().diff(DateTime.fromISO(date)).toFormat('y');
