import { isNil } from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export function maxCommission(minimumRef) {
  return this.test({
    name: 'maxCommission',
    message: { id: 'yup.number.maxCommission' },
    params: { minimumRef },
    test(value) {
      return isNil(value) || value > this.resolve(minimumRef);
    },
  });
}
