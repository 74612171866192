import fetchVouchers from './fetchVouchers';
import fetchVoucherDetails from './fetchVoucherDetails';
import filterVouchers from './filterVouchers';
import removeVouchers from './removeVouchers';
import downloadVouchersCsv from './downloadVouchersCsv';
import generateVouchers from './generateVouchers';

export default [
  fetchVouchers,
  fetchVoucherDetails,
  filterVouchers,
  removeVouchers,
  downloadVouchersCsv,
  generateVouchers,
];
