import { createLogic } from 'redux-logic';

import isPresent from 'utils/isPresent';
import { isRevenueVirtualType } from 'utils/revenue';

import {
  fetchRevenue,
  fetchRevenueVirtualClinics,
  setRevenueCurrentPage,
  setRevenueFilterParams,
} from '../actions';
import { revenueTypeSelector, revenueVirtualClinicIdSelector } from '../selectors';
import { SET_REVENUE_FILTERS } from '../types';

const filterRevenueOperation = createLogic({
  type: SET_REVENUE_FILTERS,
  latest: true,

  async process({ action: { filters }, getState }, dispatch, done) {
    const state = getState();
    const revenueType = revenueTypeSelector(state);
    const clinicId = revenueVirtualClinicIdSelector(state);

    dispatch(setRevenueFilterParams(filters));
    dispatch(setRevenueCurrentPage(1));

    if (isRevenueVirtualType(revenueType) && !isPresent(clinicId)) {
      dispatch(fetchRevenueVirtualClinics());
    } else {
      dispatch(fetchRevenue());
    }

    done();
  },
});

export default filterRevenueOperation;
