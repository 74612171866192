import { combineReducers } from 'redux';

import * as types from './types';

const vouchersBatchesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_BATCHES:
      return action.vouchersBatchesIds;
    default:
      return state;
  }
};

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_BATCHES_BLANK_STATE:
      return action.isBlankState;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_BATCHES_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'created_at', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS_BATCHES_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  vouchersBatchesIds,
  isBlankState,
  pagination,
  sort,
});
