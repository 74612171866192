import { createLogic } from 'redux-logic';
import fileDownload from 'js-file-download';

import { isPlatformOwner } from 'utils/roles';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showNotification } from 'state/notifications/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';

import { filtersSelector, selectedTimezoneSelector, datesFilterSelector } from '../selectors';
import { downloadAppointmentsCsvEndpoint } from '../endpoints';
import { DOWNLOAD_APPOINTMENTS_CSV } from '../types';

const downloadAppointmentsCsvOperation = createLogic({
  type: DOWNLOAD_APPOINTMENTS_CSV,
  latest: true,

  async process({ action: { intlFormatMessage }, httpClient, getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = downloadAppointmentsCsvEndpoint;
    const { startDate, endDate } = datesFilterSelector(state);
    const currentUser = currentUserSelector(state);
    const timezone = selectedTimezoneSelector(state);
    const {
      statuses,
      patients,
      specialities,
      symptoms,
      doctors,
      name,
    } = filtersSelector(state);

    dispatch(dataApiRequest({ endpoint }));

    try {
      const specialityIds = isPlatformOwner(currentUser.roleName)
      ? { speciality_ids: specialities }
      : { clinic_speciality_ids: specialities };

      const params = {
        filter: {
          ...specialityIds,
          start_date: startDate.toISODate(),
          end_date: endDate.toISODate(),
          timezone,
          user_profile_ids: doctors,
          patient_profile_ids: patients,
          symptom_ids: symptoms,
          statuses,
          speciality_or_symptom_name: name,
        },
        sort: 'start_time',
      };

      const { data } = await httpClient.get(url, { params });
      const fileName = intlFormatMessage({ id: 'download.fileName.appointmentsForPeriod' }, { startDate: startDate.toISODate(), endDate: endDate.toISODate() });

      fileDownload(data, fileName);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(showNotification({ messageObject: { id: 'notifications.fileHasBeenDownloaded' } }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.csvDownloadFailed' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default downloadAppointmentsCsvOperation;
