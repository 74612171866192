import React from 'react';
import PropTypes from 'prop-types';

import redirect from 'utils/redirect';

import AppointmentCalendarTooltipPatientDetailsComponent from './component';

class AppointmentCalendarTooltipPatientDetails extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    patientProfile: PropTypes.shape().isRequired,
  }

  handleRedirectToPatientProfile = () => {
    const { onClose, patientProfile } = this.props;

    onClose();
    redirect({
      href: `/patients/${patientProfile.id}/profile`,
      query: { prevPath: '/appointments' },
    });
  }

  render = () => (
    <AppointmentCalendarTooltipPatientDetailsComponent
      {...this.props}
      onRedirectToPatientProfile={this.handleRedirectToPatientProfile}
    />
  );
}

export default AppointmentCalendarTooltipPatientDetails;
