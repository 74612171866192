import { createLogic } from 'redux-logic';

import { PATIENT_STATUSES } from 'constants/patient';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import { showNotification } from 'state/notifications/actions';
import { fetchPatients } from 'state/concepts/userProfile/patients/actions';
import { activatePatientEndpoint } from '../endpoints';
import { ACTIVATE_PATIENT } from '../types';

const activatePatientOperation = createLogic({
  type: ACTIVATE_PATIENT,
  latest: true,

  async process({ httpClient, getState, action: { patientId } }, dispatch, done) {
    const { url, endpoint } = activatePatientEndpoint(patientId);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url);
      const state = getState();

      const response = updateDataHelper(
        state.data, 'patientProfile', patientId,
        { attributes: { status: PATIENT_STATUSES.active } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(fetchPatients());
      dispatch(hideModal());
      dispatch(showNotification({
        messageObject: { id: 'notifications.patientActivated' },
      }));
    } catch (error) {
      dispatch(showNotification({
        messageObject: { id: 'notifications.patientActivatedError' },
        kind: 'error',
      }));
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default activatePatientOperation;
