import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { currentUserSelector } from 'state/concepts/session/selectors';

import { setClinicSpecialitiesListIds } from '../actions';
import {
  clinicSpecialitiesListStatusesSelector,
  clinicSpecialitiesListFilterParamsSelector,
  paginationSelector,
} from '../selectors';
import { FETCH_CLINIC_SPECIALITIES_LIST } from '../types';
import { fetchVirtualClinicSpecialitiesEndpoint } from '../endpoints';

const fetchClinicSpecialitiesListOperation = createLogic({
  type: FETCH_CLINIC_SPECIALITIES_LIST,
  latest: true,

  async process({ httpClient, action: { lastId }, getState }, dispatch, done) {
    const state = getState();
    const { id } = currentUserSelector(state);
    const { url, endpoint } = fetchVirtualClinicSpecialitiesEndpoint(id);
    const { size } = paginationSelector(state);
    const statuses = clinicSpecialitiesListStatusesSelector(state);
    const { name, excludeClinicSpecialities } = clinicSpecialitiesListFilterParamsSelector(state);

    const params = {
      page: {
        after: lastId || null,
        size,
        is_cursor: true,
      },
      filter: {
        statuses,
        name,
        exclude_clinic_specialities: excludeClinicSpecialities,
      },
      sort: 'specialities.name',
      include: 'speciality,user-profiles-clinic-specialities.user-profile',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setClinicSpecialitiesListIds(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchClinicSpecialitiesListOperation;
