import { combineReducers } from 'redux';

import * as types from './types';

const patientAppointmentsIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS:
      return action.patientAppointmentsIds;
    default:
      return state;
  }
};

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS_PAGE:
      return { ...state, number: action.pageNumber };
    default:
      return state;
  }
};

const sort = (state = { sortKey: 'start_time', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_VIRTUAL_CLINIC_PATIENT_APPOINTMENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' };
    default:
      return state;
  }
};

export default combineReducers({
  patientAppointmentsIds,
  pagination,
  sort,
});
