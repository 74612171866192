import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import progressCallback from 'utils/progressCallback';
import isRequestCancelled from 'utils/isRequestCancelled';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { markAllMessagesAsRead, setUploadProgress } from '../actions';
import { createChatMessageEndpoint } from '../endpoints';
import { CANCEL_UPLOAD_FILE, UPLOAD_FILE } from '../types';

const uploadFileOperation = createLogic({
  type: UPLOAD_FILE,
  latest: true,
  warnTimeout: 0,

  async process({ action: { chatId, file }, httpClient, action$ }, dispatch, done) {
    const { url, endpoint } = createChatMessageEndpoint(chatId);

    const params = new FormData();
    params.append('file', file);

    const uploadFileRequestController = new AbortController();

    action$.subscribe((newAction) => {
      if (newAction.type === CANCEL_UPLOAD_FILE) {
        uploadFileRequestController.abort();
      }
    });

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.post(url, params, {
        onUploadProgress: progressCallback((progress) => dispatch(setUploadProgress(progress))),
        signal: uploadFileRequestController.signal,
      });

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(markAllMessagesAsRead(chatId));
    } catch (error) {
      if (!isRequestCancelled(error)) {
        requestErrorHandler({ error, dispatch, endpoint });
      }
    }

    dispatch(setUploadProgress(null));
    done();
  },
});

export default uploadFileOperation;
