import endpoint from 'utils/endpoint';

import {
  symptomsRoute,
  symptomsBulkActionsActivateRoute,
  symptomsBulkActionsDeactivateRoute,
  symptomRoute,
  symptomImageRoute,
  symptomQuestionnaireRoute,
  symptomCompleteRoute,
  symptomSpecialitiesRoute,
} from 'lib/apiRoutes';

import {
  FETCH_SYMPTOMS,
  ACTIVATE_SYMPTOMS,
  DEACTIVATE_SYMPTOMS,
  CREATE_SYMPTOM_DETAILS,
  UPDATE_SYMPTOM_DETAILS,
  UPLOAD_SYMPTOM_IMAGE,
  REMOVE_SYMPTOM_IMAGE,
  SUBMIT_SYMPTOM_QUESTIONNAIRE,
  GET_SYMPTOM_QUESTIONNAIRE,
  SUBMIT_NEW_SYMPTOM,
  GET_SYMPTOM_DETAILS,
  GET_SYMPTOM_SPECIALITIES,
} from './types';

export const fetchSymptomsEndpoint = endpoint(FETCH_SYMPTOMS, 'GET', symptomsRoute);
export const activateSymptomsEndpoint = endpoint(ACTIVATE_SYMPTOMS, 'PUT', symptomsBulkActionsActivateRoute);
export const deactivateSymptomsEndpoint = endpoint(DEACTIVATE_SYMPTOMS, 'PUT', symptomsBulkActionsDeactivateRoute);
export const createSymptomDetailsEndpoint = endpoint(CREATE_SYMPTOM_DETAILS, 'POST', symptomsRoute);
export const updateSymptomDetailsEndpoint = symptomId => endpoint(UPDATE_SYMPTOM_DETAILS, 'PUT', symptomRoute(symptomId));
export const getSymptomDetailsEndpoint = symptomId => endpoint(GET_SYMPTOM_DETAILS, 'GET', symptomRoute(symptomId));
export const uploadSymptomImageEndpoint = symptomId => endpoint(UPLOAD_SYMPTOM_IMAGE, 'PUT', symptomImageRoute(symptomId));
export const removeSymptomImageEndpoint = symptomId => endpoint(REMOVE_SYMPTOM_IMAGE, 'DELETE', symptomImageRoute(symptomId));
export const createSymptomQuestionnaireEndpoint = symptomId => endpoint(SUBMIT_SYMPTOM_QUESTIONNAIRE, 'PUT', symptomQuestionnaireRoute(symptomId));
export const getSymptomQuestionnaireEndpoint = symptomId => endpoint(GET_SYMPTOM_QUESTIONNAIRE, 'GET', symptomQuestionnaireRoute(symptomId));
export const submitNewSymptomEndpoint = symptomId => endpoint(SUBMIT_NEW_SYMPTOM, 'PUT', symptomCompleteRoute(symptomId));
export const getSymptomSpecialitiesEndpoint = symptomId => endpoint(GET_SYMPTOM_SPECIALITIES, 'GET', symptomSpecialitiesRoute(symptomId));
