const namespace = 'virtualClinics';

export const FETCH_VIRTUAL_CLINICS = `${namespace}/FETCH_VIRTUAL_CLINICS`;
export const SET_VIRTUAL_CLINICS_IDS = `${namespace}/SET_VIRTUAL_CLINICS_IDS`;
export const SET_VIRTUAL_CLINICS_BLANK_STATE = `${namespace}/SET_VIRTUAL_CLINICS_BLANK_STATE`;
export const SET_VIRTUAL_CLINICS_CURRENT_PAGE = `${namespace}/SET_VIRTUAL_CLINICS_CURRENT_PAGE`;
export const SET_VIRTUAL_CLINICS_SORT = `${namespace}/SET_VIRTUAL_CLINICS_SORT`;
export const SET_VIRTUAL_CLINICS_FILTER = `${namespace}/SET_VIRTUAL_CLINICS_FILTER`;
export const INVITE_VIRTUAL_CLINICS = `${namespace}/INVITE_VIRTUAL_CLINICS`;
export const RESEND_VIRTUAL_CLINIC_INVITATION = `${namespace}/RESEND_VIRTUAL_CLINIC_INVITATION`;
export const REMOVE_VIRTUAL_CLINIC_INVITATION = `${namespace}/REMOVE_VIRTUAL_CLINIC_INVITATION`;
export const DEACTIVATE_VIRTUAL_CLINIC = `${namespace}/DEACTIVATE_VIRTUAL_CLINIC`;
export const ACTIVATE_VIRTUAL_CLINIC = `${namespace}/ACTIVATE_VIRTUAL_CLINIC`;
export const RESET_AUTHENTICATION_VIRTUAL_CLINIC = `${namespace}/RESET_AUTHENTICATION_VIRTUAL_CLINIC`;
export const FETCH_VIRTUAL_CLINIC = `${namespace}/FETCH_VIRTUAL_CLINIC`;
export const SET_VIRTUAL_CLINIC_PROFILE_ID = `${namespace}/SET_VIRTUAL_CLINIC_PROFILE_ID`;
export const RESET_VIRTUAL_CLINIC_PROFILE_ID = `${namespace}/RESET_VIRTUAL_CLINIC_PROFILE_ID`;
export const SUBMIT_VIRTUAL_CLINIC_PROFILE = `${namespace}/SUBMIT_VIRTUAL_CLINIC_PROFILE`;
export const UPLOAD_VIRTUAL_CLINIC_LOGO = `${namespace}/UPLOAD_VIRTUAL_CLINIC_LOGO`;
export const REMOVE_VIRTUAL_CLINIC_LOGO = `${namespace}/REMOVE_VIRTUAL_CLINIC_LOGO`;
export const UPDATE_VIRTUAL_CLINIC_ACCOUNT = `${namespace}/UPDATE_VIRTUAL_CLINIC_ACCOUNT`;
export const UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS = `${namespace}/UPDATE_VIRTUAL_CLINIC_EXTRA_FREE_VISITS`;
