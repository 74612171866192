import { createSelector } from 'reselect';
import { equals, path } from 'ramda';

import { WS_STATUSES } from 'constants/webSockets';

export const connectionStatusSelector = path(['webSockets', 'status']);
export const subscriptionChannelsSelector = path(['webSockets', 'channels']);

export const isOpenedConnectionSelector = createSelector(
  connectionStatusSelector,
  equals(WS_STATUSES.opened),
);

export const isClosedConnectionSelector = createSelector(
  connectionStatusSelector,
  equals(WS_STATUSES.closed),
);
