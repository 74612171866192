import * as types from './types';

export const fetchRecurringAvailability = userId => ({
  type: types.FETCH_RECURRING_AVAILABILITY,
  userId,
});

export const updateRecurringAvailability = (
  availabilities,
  userId,
  isDoctorProfilePage = false,
) => ({
  type: types.UPDATE_RECURRING_AVAILABILITY,
  availabilities,
  userId,
  isDoctorProfilePage,
});

export const setRecurringAvailability = recurringAvailabilityIds => ({
  type: types.SET_RECURRING_AVAILABILITY,
  recurringAvailabilityIds,
});

export const resetRecurringAvailability = () => ({
  type: types.RESET_RECURRING_AVAILABILITY,
});
