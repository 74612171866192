import fetchClinicSpecialities from './fetchClinicSpecialities';
import filterClinicSpecialities from './filterClinicSpecialities';
import activateClinicSpecialities from './activateClinicSpecialities';
import deactivateClinicSpecialities from './deactivateClinicSpecialities';
import removeClinicSpecialities from './removeClinicSpecialities';
import fetchAllSpecialities from './fetchAllSpecialities';
import submitClinicSpeciality from './submitClinicSpeciality';
import submitVirtualClinicSpeciality from './submitVirtualClinicSpeciality';
import showClinicSpecialityDetails from './showClinicSpecialityDetails';
import updateClinicSpeciality from './updateClinicSpeciality';
import updateVirtualClinicSpeciality from './updateVirtualClinicSpeciality';
import submitVirtualClinicSpecialityProviders from './submitVirtualClinicSpecialityProviders';
import assignDoctorsInitialState from './assignDoctorsInitialState';
import assignDoctorsResetState from './assignDoctorsResetState';
import fetchClinicSpecialitiesList from './fetchClinicSpecialitiesList';
import filterClinicSpecialitiesList from './filterClinicSpecialitiesList';

export default [
  fetchClinicSpecialities,
  filterClinicSpecialities,
  activateClinicSpecialities,
  deactivateClinicSpecialities,
  removeClinicSpecialities,
  fetchAllSpecialities,
  submitClinicSpeciality,
  submitVirtualClinicSpeciality,
  showClinicSpecialityDetails,
  updateClinicSpeciality,
  updateVirtualClinicSpeciality,
  submitVirtualClinicSpecialityProviders,
  assignDoctorsInitialState,
  assignDoctorsResetState,
  fetchClinicSpecialitiesList,
  filterClinicSpecialitiesList,
];
