import { createLogic } from 'redux-logic';
import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import requestErrorHandler from 'lib/requestErrorHandler';

import isBlankState from 'utils/isBlankState';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { commonFiltersSelector } from 'state/concepts/userProfile/filters/selectors';
import { setVouchersBatches, setBlankState } from '../actions';
import { paginationSelector, sortParamsSelector } from '../selectors';
import { fetchVouchersBatchesEndpoint } from '../endpoints';
import { FETCH_VOUCHERS_BATCHES } from '../types';

const fetchVouchersBatchesOperation = createLogic({
  type: FETCH_VOUCHERS_BATCHES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchVouchersBatchesEndpoint;
    const state = getState();
    const { name, statuses } = commonFiltersSelector(state);
    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: { name, statuses },
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data, { endpoint });

      dispatch(setBlankState(isBlankState(params.filter, data.data)));
      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setVouchersBatches(pluck('id', data.data)));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default fetchVouchersBatchesOperation;
