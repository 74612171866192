import { map, groupWith, pluck } from 'ramda';

export const comparePriceAndFreeVisit = (current, next) => (
  current.price === next.price && current.acceptFreeVisits === next.acceptFreeVisits
);

export const getClinicSpecialities = (doctor) => {
  const clinicSpeciality = [{
    clinicSpecialityIds: [],
    freeVisit: false,
    price: '',
  }];

  if (doctor) {
      const specialities = groupWith(
        comparePriceAndFreeVisit,
        doctor.userProfilesClinicSpecialities,
      );

      const specialitiesModified = map(
        specialitiesGroup => map(
          speciality => ({ ...speciality, id: speciality.clinicSpeciality.id }),
          specialitiesGroup,
        ),
        specialities,
      );

      const clinicSpecialities = map(specialitiesGroup => ({
        clinicSpecialityIds: pluck('id', specialitiesGroup),
        price: +pluck('price', specialitiesGroup)[0],
        freeVisit: pluck('acceptFreeVisits', specialitiesGroup)[0],
      }), specialitiesModified);

      return clinicSpecialities.length ? clinicSpecialities : clinicSpeciality;
  }

  return clinicSpeciality;
};
