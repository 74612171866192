import React from 'react';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import withHttpClient from 'lib/withHttpClient';
import validationSchema from 'lib/yupLocalised/schemas/doctorsInvitation';

import validateInvitation from 'utils/invitations/validateInvitation';
import { handleSubmit } from 'utils/form/handleSubmit';

import InvitationModalComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class InvitationModal extends React.Component {
  render = () => (
    <InvitationModalComponent
      {...this.props}
    />
  );
}

export { InvitationModal as InvitationModalContainer };
export default compose(
  withHttpClient,
  withFormik({
    mapPropsToValues: ({ roleName }) => ({
      invitations: [{
        email: '',
        role: roleName,
        key: Math.random(),
      }],
    }),
    validate: validateInvitation,
    validationSchema,
    handleSubmit,
    validateOnBlur: false,
  }),
)(injectIntl(InvitationModal));
