import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { incUnreadNotificationsCount } from '../actions';
import { unreadNotificationsCountSelector } from '../selectors';
import { MARK_NOTIFICATION_AS_UNREAD } from '../types';
import { markNotificationAsUnreadEndpoint } from '../endpoints';

const markNotificationAsUnreadOperation = createLogic({
  type: MARK_NOTIFICATION_AS_UNREAD,
  latest: true,

  async process({ httpClient, getState, action: { notificationId } }, dispatch, done) {
    const { endpoint, url } = markNotificationAsUnreadEndpoint(notificationId);
    const state = getState();
    const unreadCount = unreadNotificationsCountSelector(state);

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url);

      const response = updateDataHelper(
        state.data, 'notification', notificationId,
        { attributes: { readAt: null } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(incUnreadNotificationsCount(unreadCount));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default markNotificationAsUnreadOperation;
