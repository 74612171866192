import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'antd';
import { isEmpty } from 'ramda';

import SelectField from 'views/shared/SelectField';
import LoadMoreLoader from 'views/shared/LoadMoreLoader';
import DropdownNotFoundContent from 'views/shared/DropdownNotFoundContent';

const DropdownFieldComponent = ({
  isLoading,
  isAllSelected,
  allSelectedDescription,
  field,
  options,
  onSearch,
  onFocus,
  meta,
  intl,
  fetchList,
  ...rest
}) => (
  <SelectField
    {...rest}
    field={field}
    onSearch={onSearch}
    onFocus={onFocus}
  >
    {isEmpty(options) ? [] : options.map(({ key, value, label }) => (
      <Select.Option
        label={label}
        title={label}
        value={value}
        key={key}
        className={classNames({ 'd-none': [].concat(field.value).includes(key) })}
      >
        {label}
      </Select.Option>
    ))}
    <Select.Option value="#" disabled>
      <LoadMoreLoader
        isLoading={isLoading}
        meta={meta}
        options={options}
        loadMore={fetchList}
      />
    </Select.Option>
    {isAllSelected && (
      <Select.Option value="" disabled>
        <DropdownNotFoundContent
          isLoading={isLoading}
          isAllOptionsSelected={isAllSelected}
          description={allSelectedDescription}
        />
      </Select.Option>
    )}
  </SelectField>
);

DropdownFieldComponent.defaultProps = {
  isLoading: undefined,
  meta: {},
  allSelectedDescription: 'shared.allOptionsAdded',
};

DropdownFieldComponent.propTypes = {
  isLoading: PropTypes.bool,
  isAllSelected: PropTypes.bool.isRequired,
  allSelectedDescription: PropTypes.string,
  field: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFocus: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  meta: PropTypes.shape({ hasMore: PropTypes.bool }),
  intl: PropTypes.shape().isRequired,
};

export default DropdownFieldComponent;
