import { createSelector } from 'reselect';
import { path, prop } from 'ramda';

import buildCollection from 'utils/buildCollection';

import { totalCountSelector, pageCountSelector, loadingSelector } from 'state/data/selectors';
import { fetchPatientsEndpoint, downloadPatientsCsvEndpoint } from './endpoints';

const dataSelector = prop('data');

export const isBlankStateSelector = path(['patients', 'isBlankState']);
export const patientsIdsSelector = path(['patients', 'patientIds']);
export const paginationSelector = path(['patients', 'pagination']);
export const sortSelector = path(['patients', 'sort']);

export const patientsSelector = createSelector(
  patientsIdsSelector,
  dataSelector,
  buildCollection('patientProfile'),
);

export const currentPageSelector = createSelector(
  paginationSelector,
  prop('number'),
);

export const sortParamsSelector = createSelector(
  sortSelector,
  ({ sortKey, direction }) => (direction === 'asc' ? sortKey : `-${sortKey}`),
);

export const patientsTotalCountSelector = state => (
  totalCountSelector(state, fetchPatientsEndpoint.endpoint)
);

export const patientsPageCountSelector = state => (
  pageCountSelector(state, fetchPatientsEndpoint.endpoint)
);

export const patientsLoadingSelector = state => (
  loadingSelector(state, fetchPatientsEndpoint.endpoint)
);

export const downloadPatientsCsvLoadingSelector = state => (
  loadingSelector(state, downloadPatientsCsvEndpoint.endpoint)
);
