import * as types from './types';

export const fetchJoinRequests = (roleName) => ({
  type: types.FETCH_JOIN_REQUESTS,
  roleName,
});

export const setBlankState = isBlankState => ({
  type: types.SET_JOIN_REQUESTS_BLANK_STATE,
  isBlankState,
});

export const setJoinRequestIds = ids => ({
  type: types.SET_JOIN_REQUEST_IDS,
  ids,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_JOIN_REQUESTS_CURRENT_PAGE,
  pageNumber,
});

export const acceptJoinRequest = id => ({
  type: types.ACCEPT_JOIN_REQUEST,
  id,
});

export const rejectJoinRequest = id => ({
  type: types.REJECT_JOIN_REQUEST,
  id,
});

export const removeJoinRequest = (id, roleName) => ({
  type: types.REMOVE_JOIN_REQUEST,
  id,
  roleName,
});

export const filterJoinRequestsParams = (filters, roleName) => ({
  type: types.SET_JOIN_REQUESTS_FILTERS,
  filters,
  roleName,
});
