import React from 'react';

import DiseasesModalComponent from './component';

class DiseasesModal extends React.Component {
  state = {
    isOtherDiagnosesVisible: false,
  };

  handleChangeOtherDiagnosesVisible = () => {
    this.setState(prevState => ({
      isOtherDiagnosesVisible: !prevState.isOtherDiagnosesVisible,
    }));
  };

  render = () => (
    <DiseasesModalComponent
      {...this.props}
      {...this.state}
      onChangeOtherDiagnosesVisible={this.handleChangeOtherDiagnosesVisible}
    />
  );
}

export { DiseasesModal as DiseasesModalContainer };
export default DiseasesModal;
