import { createLogic } from 'redux-logic';

import { setFilterParams } from 'state/concepts/userProfile/filters/actions';
import { fetchDashboards, setCurrentPage } from '../actions';
import { SET_AVAILABILITIES_FILTER } from '../types';

const filterAvailabilitiesOperation = createLogic({
  type: SET_AVAILABILITIES_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters));
    dispatch(setCurrentPage(1));
    dispatch(fetchDashboards());
    done();
  },
});

export default filterAvailabilitiesOperation;
