import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';

import * as types from './types';

export const fetchVouchersBatches = () => ({
  type: types.FETCH_VOUCHERS_BATCHES,
});

export const setVouchersBatches = vouchersBatchesIds => ({
  type: types.SET_VOUCHERS_BATCHES,
  vouchersBatchesIds,
});

export const fetchVouchersBatchDetails = vouchersBatchId => ({
  type: types.FETCH_VOUCHERS_BATCH_DETAILS,
  vouchersBatchId,
});

export const setBlankState = isBlankState => ({
  type: types.SET_VOUCHERS_BATCHES_BLANK_STATE,
  isBlankState,
});

export const setCurrentPage = pageNumber => ({
  type: types.SET_VOUCHERS_BATCHES_PAGE,
  pageNumber,
});

export const setSortOrder = sortKey => ({
  type: types.SET_VOUCHERS_BATCHES_SORT_ORDER,
  sortKey,
});

export const filterVouchersBatches = filters => ({
  type: types.SET_VOUCHERS_BATCHES_FILTER,
  filters,
});

export const updateVouchersBatchDetails = makeFormSubmitAction(types.UPDATE_VOUCHERS_BATCH_DETAILS);
