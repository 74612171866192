import getUserAppointment from './getUserAppointment';
import getQuestionnaireResponses from './getQuestionnaireResponses';
import submitAddNote from './submitAddNote';
import getDraftNote from './getDraftNote';
import updateDraftNote from './updateDraftNote';
import getNote from './getNote';

export default [
  getUserAppointment,
  getQuestionnaireResponses,
  submitAddNote,
  getDraftNote,
  updateDraftNote,
  getNote,
];
