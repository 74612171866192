import { createLogic } from 'redux-logic';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiSuccess } from 'state/data/actions';
import { currentUserTutorialSelector } from 'state/concepts/session/selectors';
import { SKIP_STEP } from '../types';

const skipStepOperation = createLogic({
  type: SKIP_STEP,
  latest: true,

  async process({ httpClient, getState, action: { step, stepEndpoint } }, dispatch, done) {
    const state = getState();
    const { id } = currentUserTutorialSelector(state);
    const { endpoint, url } = stepEndpoint;

    try {
      await httpClient.put(url);

      const response = updateDataHelper(
        state.data, 'userOnboardingStepper', id,
        { attributes: { [step]: true } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default skipStepOperation;
