import React from 'react';
import PropTypes from 'prop-types';

import { getRectangleCrop, getSquareCrop } from 'utils/getImageCrop';

import UploadImageCropModalComponent from './component';

// eslint-disable-next-line no-unused-expressions
import('canvas-toBlob');

export const INITIAL_STATE = {
  croppedSrc: null,
  crop: {},
};

class UploadImageCropModal extends React.Component {
  state = INITIAL_STATE;

  static defaultProps = {
    isRectangle: false,
  };

  static propTypes = {
    isRectangle: PropTypes.bool,
    isImageUrl: PropTypes.bool.isRequired,
    uploadAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    mimeType: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  };

  handleImageLoaded = (image) => {
    const { isRectangle } = this.props;
    const crop = isRectangle ? getRectangleCrop(image) : getSquareCrop(image);

    this.imageRef = image;
    this.setState({ crop });
    this.handleCropComplete(crop);
    return false;
  };

  handleCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg = (image, crop) => {
    const { mimeType, fileName } = this.props;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((res) => {
        const blob = res;
        blob.name = fileName;
        resolve(blob);
      }, mimeType);
    });
  };

  handleImageSave = () => {
    const { croppedSrc } = this.state;
    const { uploadAction, onClose, isImageUrl } = this.props;

    this.setState(INITIAL_STATE);
    uploadAction(croppedSrc, isImageUrl);
    onClose();
  };

  handleCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedSrc = await this.getCroppedImg(this.imageRef, crop);

      this.setState({ croppedSrc });
    }
  };

  render = () => (
    <UploadImageCropModalComponent
      {...this.props}
      {...this.state}
      onImageLoaded={this.handleImageLoaded}
      onCropComplete={this.handleCropComplete}
      onCropChange={this.handleCropChange}
      onImageSave={this.handleImageSave}
    />
  );
}

export default UploadImageCropModal;
