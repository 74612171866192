import PropTypes from 'prop-types';
import classNames from 'classnames';

import SvgIcon from 'views/shared/SvgIcon';

const SpinnerComponent = ({ isAbsolute, isBig }) => (
  <div className={classNames('main-spinner-wrap', { 'main-spinner-wrap--absolute': isAbsolute })}>
    <SvgIcon icon="spinner" className={classNames('main-spinner icon icon-spinner in-blue-600', { 'font-48': isBig })} />
  </div>
);

SpinnerComponent.defaultProps = {
  isAbsolute: false,
  isBig: false,
};

SpinnerComponent.propTypes = {
  isAbsolute: PropTypes.bool,
  isBig: PropTypes.bool,
};

export default SpinnerComponent;
