import { combineReducers } from 'redux';

import * as types from './types';

const initialState = {
  status: null,
  balance: 0,
};

const voucherCodeStatus = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VOUCHER_CODE_STATUS:
      return action.voucherCodeStatus;
    case types.RESET_VOUCHER_CODE_STATUS:
        return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  voucherCodeStatus,
});
