import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';

const Modal = ({
  children,
  visible,
  footer,
  closable,
  onClose,
  className,
  ...props
}) => (
  <AntdModal
    visible={visible}
    footer={footer}
    closable={closable}
    onCancel={onClose}
    wrapClassName={className}
    {...props}
  >
    {children}
  </AntdModal>
);

Modal.defaultProps = {
  visible: true,
  footer: null,
  closable: false,
  onClose: undefined,
  className: null,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.node,
  ]).isRequired,
  visible: PropTypes.bool,
  footer: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Modal;
