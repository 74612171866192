import { createLogic } from 'redux-logic';
import { DateTime } from 'luxon';

import requestErrorHandler from 'lib/requestErrorHandler';

import updateDataHelper from 'utils/updateDataHelper';

import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { setUnreadNotificationsCount } from '../actions';
import { notificationIdsSelector } from '../selectors';
import { MARK_NOTIFICATIONS_AS_READ } from '../types';
import { markNotificationsAsReadEndpoint } from '../endpoints';

const markNotificationsAsReadOperation = createLogic({
  type: MARK_NOTIFICATIONS_AS_READ,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = markNotificationsAsReadEndpoint;
    const state = getState();
    const notificationIds = notificationIdsSelector(state);
    const params = {
      type: 'notification_panel',
    };

    dispatch(dataApiRequest({ endpoint }));
    try {
      await httpClient.put(url, params);

      const response = updateDataHelper(
        state.data, 'notification', notificationIds,
        { attributes: { readAt: DateTime.utc() } },
      );

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setUnreadNotificationsCount(0));
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint });
    }

    done();
  },
});

export default markNotificationsAsReadOperation;
