import * as types from './types';

export const getPatients = searchQuery => ({
  type: types.GET_PATIENTS,
  searchQuery,
});

export const getExtraFreeVisits = searchQuery => ({
  type: types.GET_EXTRA_FREE_VISITS,
  searchQuery,
});

export const getSymptoms = searchQuery => ({
  type: types.GET_SYMPTOMS,
  searchQuery,
});

export const getSpecialities = searchQuery => ({
  type: types.GET_SPECIALITIES,
  searchQuery,
});

export const getClinicSpecialities = searchQuery => ({
  type: types.GET_CLINIC_SPECIALITIES,
  searchQuery,
});

export const getDoctors = searchQuery => ({
  type: types.GET_DOCTORS,
  searchQuery,
});

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
});

export const resetFiltersParams = () => ({
  type: types.RESET_FILTERS,
});
