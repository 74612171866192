const namespace = 'notifications';

export const FETCH_NOTIFICATIONS = `${namespace}/FETCH_NOTIFICATIONS`;
export const RECEIVE_NOTIFICATION = `${namespace}/RECEIVE_NOTIFICATION`;
export const SET_NOTIFICATION_IDS = `${namespace}/SET_NOTIFICATION_IDS`;
export const SET_NOTIFICATION_PANEL_STATUS = `${namespace}/SET_NOTIFICATION_PANEL_STATUS`;
export const SET_UNREAD_NOTIFICATIONS_COUNT = `${namespace}/SET_UNREAD_NOTIFICATIONS_COUNT`;
export const INC_UNREAD_NOTIFICATIONS_COUNT = `${namespace}/INC_UNREAD_NOTIFICATIONS_COUNT`;
export const DEC_UNREAD_NOTIFICATIONS_COUNT = `${namespace}/DEC_UNREAD_NOTIFICATIONS_COUNT`;
export const MARK_NOTIFICATION_AS_READ = `${namespace}/MARK_NOTIFICATION_AS_READ`;
export const MARK_NOTIFICATION_AS_UNREAD = `${namespace}/MARK_NOTIFICATION_AS_UNREAD`;
export const MARK_NOTIFICATIONS_AS_READ = `${namespace}/MARK_NOTIFICATIONS_AS_READ`;
export const REMOVE_NOTIFICATION = `${namespace}/REMOVE_NOTIFICATION`;
export const REMOVE_NOTIFICATION_FROM_PANEL = `${namespace}/REMOVE_NOTIFICATION_FROM_PANEL`;
export const UNDO_REMOVING_NOTIFICATION_FROM_PANEL = `${namespace}/UNDO_REMOVING_NOTIFICATION_FROM_PANEL`;
export const FETCH_NOTIFICATION_SETTINGS = `${namespace}/FETCH_NOTIFICATION_SETTINGS`;
export const UPDATE_NOTIFICATION_SETTINGS = `${namespace}/UPDATE_NOTIFICATION_SETTINGS`;
