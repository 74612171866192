import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withFormik } from 'formik';

import { OPTION_OTHER } from 'constants/vouchers';

import validationSchema from 'lib/yupLocalised/schemas/pagoparAmount';

import { handleSubmit } from 'utils/form/handleSubmit';

import { submitPatientAccountPagoparTopup } from 'state/concepts/patientProfile/account/actions';

import TopUpWithPagoparModalComponent from './component';

class TopUpWithPagoparModal extends React.Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      otherAmount: PropTypes.string.isRequired,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }

  get isVisible() {
    const { values: { amount } } = this.props;

    return amount === OPTION_OTHER;
  }

  handleChange = (value) => {
    const { setFieldValue } = this.props;

    if (value.amount !== OPTION_OTHER) {
      setFieldValue('otherAmount', '');
    }
  }

  render = () => (
    <TopUpWithPagoparModalComponent
      {...this.props}
      isVisible={this.isVisible}
      onChange={this.handleChange}
    />
  )
}

const mapDispatchToProps = {
  onSubmit: submitPatientAccountPagoparTopup,
};

export { TopUpWithPagoparModal as TopUpWithPagoparModalContainer };
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      amount: '',
      otherAmount: '',
    }),
    validationSchema,
    enableReinitialize: true,
    handleSubmit,
  }),
)(TopUpWithPagoparModal);
